import React,{useRef} from 'react';
import Store from '../../Common/Store';
import TAPICall from './TAPICall';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import QcViewHOC from './QcViewHOC';
import AnswerViewHOC from './AnswerViewHOC';
import ReportModalHOC from './ReportModalHOC';
import CSLLoader from '../Common/CSLLoader';
import FCKPrintPreviewHOC from './FCKPrintPreviewHOC';
import AssignFileCheckHOC from './AssignFileCheckHOC';
import EventBus from '../../Common/EventBus.js';
import Utils from "../../Common/Utils";

const ModalViewBack = styled.div`
    display: block;
    box-sizing: border-box;
    height: 2342px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: -10%;
    top: -180px;
    z-index: 1000;
    opacity: 0.8;
`;
const ModalView = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 80%;
    left: 1%;
    top:-104px;
`;

const ModalView_contain = styled.div`
       position: absolute;
        left: 10%;
        top: 100px;
        width: 100%;
        height: 1186px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;

class FilecheckerHOC extends React.Component {
    state = {
        ready: false,
        view_count: 0,
        modal_view: null,
        page_view: "manager_index",
        show_cards: true,
        task: null,
        quality_assurance_enabled: false,
        show_completed: false,
        show_completed_qa: false,
        show_completed_folloup: false,
        pending_tasks: null,
        qa_pending_tasks: null,
        qa_completed_tasks: null,
        pending_followup_tasks: null,
        completed_followup_tasks: null,
        completed_tasks: null,
        stats: null,
        alertParam: null,
        report_task_json: null,
        report_task: null,
        show_spinner: false,
        comp_fc_autofail_result: [],
        pending_filters: {
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},
            reviewee: {name: 'reviewee', 'nickname': 'Reviewee', type: 'multi', options: {},selections: {}, size: 2},
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
            due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        completed_filters: {
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},
            reviewee: {name: 'reviewee', 'nickname': 'Reviewee', type: 'multi', options: {},selections: {}, size: 2},
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
            completed_date: {name: 'completed_date', 'nickname': 'Completed Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        pending_qa_filters: {
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
            due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        completed_qa_filters: {
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
            completed_date: {name: 'completed_date', 'nickname': 'Completed Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        pending_followup_filters: {
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
            due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        completed_followup_filters: {
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
            completed_date: {name: 'completed_date', 'nickname': 'Completed Date', type: 'date_range', options: {},selections: {}, size: 2}
        }
    };
    // constructor(props) {
	// 	super(props);
    //     this.myRef = React.createRef();
	// }
	
	componentDidMount = () => {
	console.log("At comp index");
		let is_admin = Store.getStoreData('is_admin');
		console.log("openmodal::FCHOC props", this.props);
		let task_id = this.props.task_id;
		//this.props.task_id ? parseInt(this.props.task_id) : 0;
		
		Store.updateStore('fc_index', task_id);
		console.log("task_id:", task_id);
		// console.log("is_admin", is_admin);
		let cur_view = null;
		let page_view = null;
		let is_register = false
		if (is_admin) {
			cur_view = 'csl_index';
		} else {

			let role = Store.getStoreData('role');
			// console.log("ROLE:", role);
			switch (role) {
				case 'qa_manager': cur_view = 'qa_manager_index'; break;
				case 'senior_manager': cur_view = 'senior_manager_index'; break;
				case 'admin_manager': cur_view = 'admin_manager_index'; break;
				case 'manager': cur_view = 'manager_index'; break;
				case 'view_only_manager': cur_view = 'view_only_manager_index'; break;
				case 'file_checker': cur_view = 'fc_index'; break;
				case 'qa_reviewer': cur_view = 'qa_reviewer_index'; break;
				case 'no_access': cur_view = 'no_access'; break;
				default: cur_view = 'team_index';
			}
			if (Store.getStoreData("parent_has_group_companies") === true && Store.getStoreData("gc_companies").length === 1 && role !== "admin_manager") {
				cur_view = 'no_access';
			}
			// if(task_id > 0){
			// 	cur_view = 'register_index'
			// 	is_register = true;
			// }; 
		}
		// if(task_id > 0) {
		// 	cur_view = "answerview"
		// }
		console.log("task_id cur_view:", cur_view);
		Store.updateStore('cur_view', cur_view);
		
		 if (this.props.task_id !== null && this.props.task_id !== 0) {
            const postData = {command: "get_task", task_id: this.props.task_id};
            console.log("postData:", postData);
            
            let api = new TAPICall();
            api.command(postData, this.singleTask);
        }
		// Store.updateStore('is_register', is_register);
		// if(task_id === 39310){
		   
		//     page_view = "answerview";
		// }else{
		    if(this.props.cur_lane){
                if(this.props.cur_lane === "COMP_FCK_COMPLETE") {
                     document.documentElement.scrollTop = 0;
                    // Store.updateStore('report', JSON.parse(result.task_json).object_data);
                    let postData = { command: "get_task", task_id: task_id};
                    let api = new TAPICall();
                    api.command(postData, this.openReportModal);
        		    page_view = "view_report_modal";
                }else if(this.props.cur_lane === "COMP_FCK_QC_ASSIGNMENT" || this.props.cur_lane === "COMP_FCK_QC_COMPLETE") {
                    page_view = "qcview";
                }else if (this.props.cur_lane === "COMP_FCK_FOLLOWUP_ASSIGNMENT") {
                    page_view = "view_followup_modal";
                }else{
                    
                    if(task_id > 0){
                       page_view = "answerview";
                    }
                    else{
                       page_view = "assignview";
                    }
                }
            }
		//}
		 
        //page_view = "view_report_modal";
        EventBus.registerEvent('followup_saved_from_parent', 'FilecheckerHOC', this.showResult_parent)
		this.setState({ cur_view: cur_view, task_id ,page_view: page_view });
        
        //this.executeScroll();
	}
    singleTask = (result) => {
        this.setState({task: result});
    }
	refreshState = () => {
		this.props.refreshCallback();
	}
    //executeScroll = () => this.myRef.current.scrollIntoView()
// 	refreshThisState = (view) => {
// 	    alert(1);
// 		this.setState({ page_view: view });
// 	}

	setBreadcrumbLastText = (text) => {
	    
		this.setState({ breadcrumbLastText: text });
	}
	closeModal_event = () =>{
		this.setState({page_view: "manager_index"});
	}

	closeModal = (event) => {
        event.preventDefault();
        Store.updateStore("fc_index", null);
        window.location.assign("/register");
        // if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
        //     window.location.assign(document.referrer);
        // }
        this.setState({page_view: "manager_index"});
    }
    showPage = (page) => {
        console.log('propsdata:: showPage:: ',page);
        this.setState({page_view : page});
        //alert(this.state.page_view);
    }
    // showResult = (task_id) => (event) => {
    //     event.preventDefault();
    //     // let tasks = Store.getStoreData('completed_fctasks');
    //     // let result = tasks[index];
    //     console.log('Show result');
    //     document.documentElement.scrollTop = 0;
    //     // Store.updateStore('report', JSON.parse(result.task_json).object_data);
    //     let postData = { command: "get_task", task_id: task_id};
    //     let api = new TAPICall();
    //     api.command(postData, this.openReportModal);
    // }

    showResult_parent = (task_id)  => {
        this.setState({'show_loader':true})
        // alert('at showResult_parent')
        document.documentElement.scrollTop = 0;
        // Store.updateStore('report', JSON.parse(result.task_json).object_data);
        let postData = { command: "get_task", task_id: task_id };
        let api = new TAPICall();
        api.command(postData, this.openReportModal);
      };
     openReportModal = (result) => {
        console.log('openmodal::result',result)
        Store.updateStore('report_task_json', JSON.parse(result.result.task.parenttask.task_json))
        Store.updateStore('report_task', result.result.task)
        let comp_fc_autofail_result = []
        comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
        this.setState({report_task_json: JSON.parse(result.result.task.parenttask.task_json), comp_fc_autofail_result, report_task: result.result.task, show_loader: false,});
    }
    closeReportmodal = () => {
        //this.setState({modal_view: null});
        this.setState({page_view: "manager_index"});
    }
	refreshThisState = (view) => {
	    console.log(view);
	    //alert(1);
		this.setState({ page_view: view });
	}
	
	changeCurView = (view) => {
        this.view = view;
        this.from_change_cur_view = true;
        // console.log("task_id view:", this.view);
        // if (Store.getStoreData('fc_index') !== null && Store.getStoreData('fc_index') !== 0) {
        //     const api = new TAPICall();
        //     const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
        //     api.command(postData, this.singleTask);
        // } else {
            this.setState({page_view: view});
       // }
    }
    
    render() {
        // console.log("page view::",this.state.page_view)
        // console.log("HOCstate::",this.state)
        //closeModal
        // console.log('MYPROPS:: report_task:: ',this.state.report_task);
        // console.log('MYPROPS:: report_task_json:: ',this.state.report_task_json);
    
        if (this.state.show_loader === true) {
          return (
            <div>
              <CSLLoader />
            </div>
          );
        }   
        return (
            
             <ModalView_contain>
           
            <ModalViewBack></ModalViewBack>
            {/*  ref={ this.myRef } */}
             <ModalView>
            <div>
              {
                            (() => {
                            if(this.state.page_view === "qcview"){
                            
                                return  <QcViewHOC closeModal= {this.props.closeModal} task_id={this.props.task_id} changeCurView={this.changeCurView} parentview={this.state.cur_view} 
                                showPage={this.showPage} />;
                                
                            }else if (this.state.page_view === "review"){
                            
                                return (<AnswerViewHOC closeModal= {this.props.closeModal}  readonly={true} changeCurView={this.changeCurView} parentview={this.state.cur_view} 
                                setBreadcrumbLastText={this.props.setBreadcrumbLastText} editcomp={false} showPage={this.showPage} />); 
                                
                            }else if (this.state.page_view === "view_report_modal"){
                            
                                if(this.state.report_task_json && this.state.report_task){
                                    {/* return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}>
                                    <InactiveOverlay style={{height: "2384px"}} /><ReportModalHOC closeModal= {this.props.closeModal} report_task_json={this.state.report_task_json} 
                                    closeModal={this.closeReportmodal} report_task={this.state.report_task} 
                                openPrintView={this.refreshThisState} /></div>;*/}
                                return <ReportModalHOC closeModal= {this.props.closeModal} report_task_json={this.state.report_task_json} 
                                    report_task={this.state.report_task} comp_fc_autofail_result={this.state.comp_fc_autofail_result} 
                                    openPrintView={this.refreshThisState} />
                                }else{
                                    return (<div><CSLLoader /></div>);
                                }
                               
                                
                            }else if (this.state.page_view === "pdfprintview"){
                            
                                if(this.state.report_task_json && this.state.report_task){
                                     return <FCKPrintPreviewHOC changeCurView={this.refreshThisState} report_task={this.state.report_task} report_task_json={this.state.report_task_json} />;
                                }else{
                                    return (<div><CSLLoader /></div>);
                                }
                               
                                
                            }else if (this.state.page_view === "answerview"){
                            
                                return (<AnswerViewHOC  closeModal= {this.props.closeModal} task_id={this.props.task_id} changeCurView={this.changeCurView} parentview={this.state.cur_view} 
                                setBreadcrumbLastText={this.props.setBreadcrumbLastText} editcomp={false} showPage={this.showPage} />); 
                                
                            }else if (this.state.page_view === "assignview"){
                            
                                return (<AssignFileCheckHOC closeModal= {this.props.closeModal} task={this.state.task} changeCurView={this.changeCurView} parentview={this.state.cur_view} />); 
                                
                            }
                            })()
                        }
           
              {/* <ul>
                <li>{this.props.task_id}</li>
                 <li>{this.props.company_id}</li>
                  <li>{this.props.cur_lane}</li>
            </ul> */}
          
            </div>
              </ModalView>
            </ModalView_contain>
        );
    }
}

export default FilecheckerHOC;