import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {ic_fiber_manual_record} from 'react-icons-kit/md/ic_fiber_manual_record';

class NonScoreResult extends React.Component
{
	state = {data: []};

	componentDidMount()
	{
		this.setState({data: this.props.data});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {
			this.setState({data: this.props.data});
		}
	}
	render()
	{
		return (
			<div style={{backgroundColor: "#ffffff", padding: "0px 10px 10px 10px"}}>
				{
					this.state.data.map((item) => {
						return (
							<div key={item.label} style={{width: "100%", marginTop: "5px"}}>
								<div style={{color: item.color, width: "10%", float: "left", boxSizing: "border-box"}}>
									<Icon icon={ic_fiber_manual_record}/>
								</div>
								<div style={{width: "80%", float: "left", boxSizing: "border-box", paddingTop: "2px"}}>
									{item.label}
								</div>
								<div style={{color: item.color, fontWeight: "600", width: "10%", float: "left", boxSizing: "border-box", paddingTop: "2px"}}>
									{item.count}
								</div>
								<div style={{clear: "both"}}></div>
							</div>
						);
					})
				}
			</div>
		);
	}
}

export default NonScoreResult;