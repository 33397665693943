import React from 'react';
import styled from 'styled-components';
import BulkHistoryTable from '../Tables/BulkHistoryTable';
import { AiOutlineSync } from "react-icons/ai";
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import moment from 'moment';

const HR = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: 1px;
    background-color: #d9d7d7;
    margin-top: 30px;
`;
const HistoryHeader = styled.div`
    color: #232424;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
`;
const Resync = styled.div`
    cursor: pointer;
    text-align: right;
    font-size: 18px;
    margin-top: 19px;
`;
const SectionLeft90 = styled.div`
    box-sizing: border-box;
    width: 90%;
    float: left;
`;
const SectionRight10 = styled.div`
    box-sizing: border-box;
    width: 10%;
    float: right;
`;
const Clearfix = styled.div`
    clear: both;
`;

class BulkHistory extends React.Component {
    
    state = {bulk_uploads: []};
    
    componentDidMount() {
        const api = new APICall();
        const postData = {command: "list_bulk"};
        api.command(postData, this.afterResync);
    }
    
    resync = (event) => {
        event.preventDefault();
        const api = new APICall();
        const postData = {command: "list_bulk"};
        api.command(postData, this.afterResync);
    }
    
    afterResync = (result) => {
        // console.log("afterResync result:", result);
        this.setState({bulk_uploads: result.result});
    }
    
    processData = () => {
        const users = Store.getStoreData("users");
        // console.log(users);
        let ret = {data: [], columns: []};
        ret.columns = [
            {Header: 'File Name', accessor: 'file_name', minWidth: 80, headerStyle: {textAlign: 'left'}},
            {Header: 'Uploaded By', accessor: 'uploaded_by', minWidth: 80, headerStyle: {textAlign: 'left'}},
            {Header: 'Items', accessor: 'item_count', minWidth: 40, headerStyle: {textAlign: 'left'}},
            {Header: 'Status', accessor: 'status', minWidth: 80, headerStyle: {textAlign: 'left'}},
            {Header: 'Uploaded Date', accessor: 'uploaded_date', minWidth: 80, headerStyle: {textAlign: 'left'}},
            {Header: '', headerStyle: {textAlign: 'left'}, width: 80, Cell: this.renderCell}
        ];
        for (let item of this.state.bulk_uploads) {
            const status = JSON.parse(item.status).status;
            const uploaded_by = item.uploaded_by.toString() in users ? users[item.uploaded_by.toString()].ContactName : "Inactive User"
            ret.data.push({id: item.id, file_name: item.file_name, uploaded_by: uploaded_by, item_count: item.items_count, status: status, uploaded_date: moment.unix(item.created_at).format('DD/MM/YYYY')});
        }
        return ret;
    }
    
    renderCell = (cellInfo) => {
        return (
            <button style={{cursor: "pointer"}} onClick={this.getData(cellInfo.original.id)}>View</button>    
        )
    }
    
    getData = (spreadsheet_id) => (event) => {
        event.preventDefault();
        console.log(spreadsheet_id);
        const api = new APICall();
        const postData = {command: "get_bulk", spreadsheet_id: spreadsheet_id};
        api.command(postData, this.goNext);
        // this.props.goNext();
    }
    
    goNext = (result) => {
        // console.log(result);
        const items_json = JSON.parse(result.result.items_json);
        this.props.goNext({headers: items_json.headers, data: items_json.data, file_name: items_json.file_name, readonly: true})
    }
    
    render () {
        return (
            <div>
                <HR></HR>
                <SectionLeft90>
                    <HistoryHeader>Upload History</HistoryHeader>
                </SectionLeft90>
                <SectionRight10>
                    <Resync title="Resync" onClick={this.resync}><AiOutlineSync /></Resync>
                </SectionRight10>
                <Clearfix></Clearfix>
                <div style={{marginTop: "30px"}}>
                    <BulkHistoryTable processData={this.processData} tableRows="5" />
                </div>
            </div>
        )
    }
}

export default BulkHistory;