		let data = {table_headers: [], data: []};
        data.module = {id: 'filechecker', display: 'Auditor Summary'}
		data.table_headers = [
            {"accessor": "reviewee", "nickname": "Reviewee", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "role", "nickname": "Role", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "gc", "nickname": "Group companies", type: 'alpha', width: 3,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "last_reviewed", "nickname": "Last Reviewed", type: 'date', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "reviewed", "nickname": "Reviewed", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}}
        ];

        data.sortby = {
            fields: [
                {"accessor": "reviewee", "nickname": "Reviewee", type: 'alpha', width: 2},
                {"accessor": "role", "nickname": "Role", type: 'alpha', width: 2},
                {"accessor": "last_reviewed", "nickname": "Last Reviewed", type: 'date', width: 2},
                {"accessor": "reviewed", "nickname": "Review count", type: 'alpha', width: 2},
                {"accessor": "lc_month", "nickname": "Last Completed month", type: 'month_year', width: 2}
            ],
            selected: {"accessor": "reviewee", "order": "Ascending"}
        };
        data.groupby = {
            fields: [
                {"accessor": "reviewee", "nickname": "Reviewee", type: 'alpha', width: 2},
                {"accessor": "role", "nickname": "Role", type: 'alpha', width: 2},
                {"accessor": "last_reviewed", "nickname": "Last Reviewed", type: 'date', width: 2},
                {"accessor": "reviewed", "nickname": "Review count", type: 'alpha', width: 2},
                {"accessor": "lc_month", "nickname": "Last Completed month", type: 'month_year', width: 2}
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        };

module.exports = data