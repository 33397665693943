import React from 'react';
import styled from 'styled-components';

const InfoBar = styled.div`
	color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 300;
    padding: 5px;
`
const Info = styled.div`
    padding: 5px 0px 5px 5px;
    background-color: #f7f7f7;
`

class MGRScoreInfo extends React.Component
{
	render() {
		return (
				<div>
					{
						(() => {
							switch(this.props.actualOutcome) {
								case 'Pass' : return (<Info><InfoBar><b> Pass -</b> Please define the scoring parameter for a 'successful' response</InfoBar></Info>); break;
								case 'Fail' : return (<Info><InfoBar><b> Fail -</b> Please define the scoring parameter for an 'unsuccessful' response</InfoBar></Info>); break;
								case 'Indeterminate' : return (<Info><InfoBar><b> Other</b></InfoBar></Info>); break;
							}
						})()
					}
				</div>
			)
	}
}

export default MGRScoreInfo;