import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {ic_fiber_manual_record} from 'react-icons-kit/md/ic_fiber_manual_record';
import FollowupTasks from './FollowupTasks';
import ChildTask from './ChildTask';
import Store from '../../Common/Store';
import { saveAs } from 'file-saver';
import APICall from '../../Common/APICall';

const OverviewHeader = styled.div`
	background-color: #ffffff;
	padding: 20px;
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const OverviewBody = styled.div`
	padding: 30px 20px;
	margin-top: 2px;
	background-color: #ffffff;
`;
const AnswerTextArea = styled.textarea`
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 100%;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;

const AssessHeader = styled.div`
	background-color: #ECF0F1;
	padding: 10px;
	border: 1px solid #8aa2a8;
	border-radius: 5px;
`;
const AssessStatus = styled.div`
	background-color: #ffffff;
	padding: 10px;
	border: 1px solid #ffffff;
	border-radius: 5px;
	margin-top: 15px;
`;
const Section50 = styled.div`
	float: left;
	box-sizing: border-box;
	width: 50%;
	padding-top: 10px;
`;
const AssessHr = styled.div`
	height: 1px;
	width: 100%;
	background-color: #c8c9ca;
	margin-top: 15px;
`;
const AssessTextArea = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 5px;
    margin-top: 10px;
    height: 140px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    font-family: 'Montserrat',sans-serif;
`;
const AssessResult = styled.select`
	margin-top: 10px;
	height: 35px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    background-color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
`;

class Assess extends React.Component
{
	state = {
		ready: false,
		non_score_result: [],
		non_score_assessment: null,
		review_json: null,
		total_questions: 0,
		questions_answered: 0,
		na_questions: 0,
		payload: null,
		task_json: null,
		task_id: null,
		cur_files_json: null,
		binFiles: [],
		curBinFiles: []
	};

	componentDidMount()
	{
		console.log("non_score_result:", this.props.non_score_result);
		console.log("non_score_assessment:", this.props.non_score_assessment);
		console.log("review_json:", this.props.review_json);
		console.log("payload:", this.props.payload);
		console.log("props:", this.props);
		const data = this.calculateData(this.props.review_json);
		this.setState({
			total_questions: data.total_questions,
			questions_answered: data.questions_answered,
			na_questions: data.na_questions,
			non_score_result: this.props.non_score_result,
			non_score_assessment: this.props.non_score_assessment,
			review_json: this.props.review_json,
			payload: this.props.payload,
			task_id: this.props.task_id,
			ready: true
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			console.log("non_score_result:", this.props.non_score_result);
			console.log("non_score_assessment:", this.props.non_score_assessment);
			console.log("review_json:", this.props.review_json);
			const data = this.calculateData(this.props.review_json);
			this.setState({
				total_questions: data.total_questions,
				questions_answered: data.questions_answered,
				na_questions: data.na_questions,
				non_score_result: this.props.non_score_result,
				non_score_assessment: this.props.non_score_assessment,
				review_json: this.props.review_json,
				payload: this.props.payload,
				task_id: this.props.task_id,
				ready: true
			});
		}
	}

	calculateData = (review_json) => {
		let total_questions = 0;
		let questions_answered = 0;
		let na_questions = 0;
		review_json.sections.forEach((section) => {
			section.questions.forEach((question) => {
				total_questions = total_questions + 1;
				na_questions = question.included ? na_questions : (na_questions + 1);
				questions_answered = question.answer.text === "" ? questions_answered : (questions_answered + 1);
			});
		});
		return ({total_questions: total_questions, na_questions: na_questions, questions_answered: questions_answered});
	}

	handleComments = (event) => {
		event.preventDefault();
		let non_score_assessment = JSON.parse(JSON.stringify(this.state.non_score_assessment));
		non_score_assessment.reviewer_comments = event.target.value;
		this.setState({non_score_assessment});
		this.props.handleNonScoreAssessment(non_score_assessment);
	}

	handleResult = (event) => {
		event.preventDefault();
		let non_score_assessment = JSON.parse(JSON.stringify(this.state.non_score_assessment));
		non_score_assessment.result = event.target.value;
		this.props.handleNonScoreAssessment(non_score_assessment);
	}

	arrangeFollowupTasks = () => {
		const postData = {command: "get_task", task_id: this.props.task_id};
        let api = new APICall();
        api.command(postData, this.processPostData);
	}

	processPostData = (result) => {
		console.log("processPostData");
		let {task_json, review_json, payload, task_id, cur_files_json, curBinFiles} = this.state;
		task_json = this.props.task_json;
		review_json = this.props.review_json;
		payload = this.props.payload;
		// console.log("payload", this.props.payload);
		// console.log("task_json", this.props.task_json);
		task_id = this.props.task_id;
		if('bin_files' in task_json.object_data) {
			curBinFiles = task_json.object_data.bin_files;
			cur_files_json = curBinFiles.length === 0 ? null : true;
		}
		let childtask = this.props.payload.child_tasks;
        let contacts = this.props.payload.contacts;
        childtask.forEach((task) => {
            contacts.forEach((item) => {
                if(item.ID === task.cur_assigned_to) {
                    task.assigned_to_name = item.ContactName;
                }
            })
        })
        payload['childtask'] = childtask;
		// console.log("payload", this.props.payload);
		this.setState({task_json, review_json, payload, task_id, cur_files_json,curBinFiles});
	}

	filesLoaded = (files) => {
        let me = Store.getStoreData('loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
            }
        }
        // console.log('files in Modal', files);
        this.setState({binFiles: files});
	} 
	
	handleDownload = (index) => {
        let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }
	modifyAdminConfirmationText = (event) => {
		event.preventDefault();
		this.props.modifyAdminConfirmationText(event.target.value);
	}
	render()
	{
		console.log("payload:", this.props.payload);
		if (this.state.ready === false) {
			return <div>Loading...</div>
		}
		return (
			<div>
				<AssessHeader>
					<div style={{fontWeight: "600"}}>Finalise your Filecheck Review</div>
					<div style={{paddingTop: "5px"}}>Now all areas of the file check have been reviewed, please add any required comments and provide an overall result for this review.</div>
				</AssessHeader>
				<AssessStatus>
					<div style={{fontWeight: "600"}}>Results</div>
					<Section50 style={{paddingRight: "10px"}}>
						<div style={{float: "left", fontWeight: "600"}}>Questions Answered</div>
						<div style={{float: "right"}}>
							{this.state.questions_answered}/{this.state.total_questions - this.state.na_questions}
						</div>
						<div style={{clear: "both"}}></div>
						{
							this.state.non_score_result.map((item) => {
								return (
									<div key={item.label} style={{width: "100%", marginTop: "5px"}}>
										<div style={{color: item.color, width: "10%", float: "left", boxSizing: "border-box"}}>
											<Icon icon={ic_fiber_manual_record}/>
										</div>
										<div style={{width: "80%", float: "left", boxSizing: "border-box", paddingTop: "2px"}}>
											{item.label}
										</div>
										<div style={{color: item.color, fontWeight: "600", width: "10%", float: "right", boxSizing: "border-box", paddingTop: "2px", textAlign: "right"}}>
											{item.count}
										</div>
										<div style={{clear: "both"}}></div>
									</div>
								);
							})
						}
					</Section50>
					<Section50 style={{paddingLeft: "10px"}}>
						<div>
							<div style={{float: "left", fontWeight: "600"}}>N/A Questions</div>
							<div style={{float: "right"}}>
								{this.state.na_questions}/{this.state.total_questions}
							</div>
							<div style={{clear: "both"}}></div>
						</div>
						<div style={{marginTop: "10px"}}>
							<div style={{float: "left", fontWeight: "600"}}>Follow up Actions</div>
							<div style={{float: "right"}}>
								{
									(() => {
										if (this.state.payload !== null) {
											let ft_count = "00";
											const aft_count = this.state.payload.childtask.length;
											const sft_count = aft_count.toString();
											ft_count = sft_count.length < 2 ? "0"+sft_count : sft_count;
											return ft_count;
										}
									})()
								}
							</div>
							<div style={{clear: "both"}}></div>
						</div>
						<div style={{marginTop: "10px"}}>
							<div style={{float: "left", fontWeight: "600"}}>Recomended Result</div>
							{
								(() => {
									let color = "#000000";
									if (this.state.non_score_assessment !== null) {
										color = "color" in this.state.non_score_assessment ? this.state.non_score_assessment.color : "#000000";
									}
									return (
										<div style={{float: "right", color: color}}>
											{this.state.non_score_assessment === null ? "-" : this.state.non_score_assessment.result}
										</div>
									);
								})()
							}
							<div style={{clear: "both"}}></div>
						</div>
					</Section50>
					<div style={{clear: "both"}}></div>
				</AssessStatus>
				<AssessHr></AssessHr>
				{/* <FollowupTasks payload={this.state.payload} addSubTask={this.props.addSubTask} show_subtask_modal={this.props.show_subtask_modal} /> */}
				<ChildTask
					childtask={this.state.payload.childtask}
					tableRows="10"
					contacts={this.state.payload.contacts}
					arrangeFollowupTasks={this.arrangeFollowupTasks}
					parent_task_id={this.state.task_id}
					parent_company_id={this.state.payload.company_id}
					reloadChildTasks={this.props.reloadChildTasks}
					reviewer={this.state.payload.reviewer}
					readonly={this.props.readonly}
					addFutureFollowup={this.props.addFutureFollowup}
					info={"reviewee_acknowledgement" in this.props.task_json.object_data.headerinfo ? this.props.task_json.object_data.headerinfo.reviewee_acknowledgement : null}
				/>
				<div style={{paddingTop: "15px"}}>
					<div style={{fontWeight: "600"}}>Reviewer Comments</div>
					<AssessTextArea disabled={this.props.readonly} value={this.state.non_score_assessment === null ? "" : this.state.non_score_assessment.reviewer_comments} onChange={this.handleComments} />
				</div>
				
				
				{
                    (() => {
						//console.log("ASSES CURLANE::",this.props.cur_lane)
						if(this.props.cur_lane === 'COMP_FCK_COMPLETE'){
						
						return (
							<div>
								<div style={{fontWeight: "600"}}>Edit Confirmation Text</div>
								
								<AssessTextArea disabled={this.props.readonly} value={this.props.admin_confirmation_text.replace(/'+/g,"'")} onChange={this.modifyAdminConfirmationText} style={{marginTop: "5px"}} rows="6" />
								
							</div>
						)
						}
					})()
				}
				<div style={{paddingTop: "15px"}}>
					<div style={{fontWeight: "600"}}>Result</div>
					<AssessResult disabled={this.props.readonly} value={this.state.non_score_assessment === null || this.state.non_score_assessment.result === "-" ? "Select" : this.state.non_score_assessment.result} onChange={this.handleResult}>
						<option value="-">Select</option>
						{
							this.state.non_score_result.map((item) => {
								return (<option key={item.label} value={item.label}>{item.label}</option>);
							})
						}
					</AssessResult>
				</div>
			</div>
		);
	}
}

export default Assess;
