import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import CompanyChanger from './CompanyChanger';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import * as jsPDF from 'jspdf'
import Iframe from 'react-iframe'

const marginX = 13;
const marginY = 20;
const maxX = 210 - (2 * marginX);
const maxY = 297 - (2 * marginY);
const footer_height = 15;
const para_gap = 9;
const line_height = 12;
const paraIndent = 25;
const paraGap = 35;
const columnLeftFirst = 18;
const columnLeftSecond = 90;
const columnLeftThird = 150;
const columnLeftFourth = 198;
const lineHeight = 6;
const pageBottom = 255;
const pageRight = 185;

class FileAuditData extends React.Component
{
	componentDidMount() {
      //this.props.fileAuditData();
	}
	componentDidUpdate(prevProps) {   
	   // if (prevProps !== this.props) {
	   //  this.props.fileAuditData();
	   // }
	}

	render()
	{
		return (<div></div>);
	}
}

export default FileAuditData;