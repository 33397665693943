import React from 'react';
import styled from 'styled-components';
import Store from '../../../../Common/Store';
import Utils from '../../../../Common/Utils.js';
import {IoIosTrash} from 'react-icons/io';

const AddResponseBtn = styled.button`
	width: 100%;
	padding: 10px 0px;
	background-color: #37ADA8;
	border: 1px solid #37ADA8;
	border-radius: 4px;
	cursor: pointer;
	color: #ffffff;
`;
const ResponseInput = styled.input`
	box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: 'Montserrat',sans-serif;
`;
const ResponseDropdown = styled.select`
	box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
`;
const ResponseLabel = styled.div`
    font-weight: 600;
    margin-bottom: 10px;
`;

class DropdownQuestion extends React.Component
{
	state = {question: null};

	componentDidMount()
	{
		this.setState({question: this.props.question, sections: this.props.sections});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({question: this.props.question, sections: this.props.sections});
		}
	}

	addDropdownResponse = (event) => {
		event.preventDefault();
		let {sections} = this.state;
		console.log("sections", sections);
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (question.id === this.state.question.id) {
					question.preset_answers.push({id: Utils.genKey(4), value: "", weight: 0});
				}
			})
		})
		this.props.modifySections(sections);
	}

	changeDropWeight = (pa_id) => (event) => {
		event.preventDefault();
		let {sections} = this.state;
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (question.id === this.state.question.id) {
					question.preset_answers.forEach((pa) => {
						if (pa.id === pa_id) {
							pa.weight = parseInt(event.target.value);
						}
					})
				}
			})
		})
		this.props.modifySections(sections);
	}

	changeDropAnswer = (pa_id) => (event) => {
		event.preventDefault();
		let {sections} = this.state;
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (question.id === this.state.question.id) {
					question.preset_answers.forEach((pa) => {
						if (pa.id === pa_id) {
							pa.value = event.target.value;
						}
					})
				}
			})
		})
		this.props.modifySections(sections);
	}

	deleteDropdownQuestion_old = (question_id) => (event) => {
        event.preventDefault();
        const r = window.confirm("Are you sure you want to delete? This action cannot be undone");
        if(r == true){
        	alert(question_id)
            // let {sections} = this.state;
            // let rindex = 0;
            // sections[parseInt(this.state.section_index)].questions.forEach((question, index) => {
            //     if (question.id === question_id) {
            //         rindex = index;
            //     }
            // })
            // sections[parseInt(this.state.section_index)].questions.splice(rindex, 1);
            // this.props.modifySections(sections);
            return false;
        }
    }

    deleteDropdownQuestion = (pa_id) => (event) => {
    	event.preventDefault();
    	const r = window.confirm("Are you sure you want to delete? This action cannot be undone");
        if(r === true){
	    	let sections = JSON.parse(JSON.stringify(this.state.sections));
	    	let newpas = [];
	    	sections.forEach((section) => {
				section.questions.forEach((question) => {
					if (question.id === this.state.question.id) {
						question.preset_answers.forEach((pa) => {
							if (pa.id !== pa_id) {
								newpas.push(pa);
							}
						})
						question.preset_answers = newpas;
					}
				})
			})
			this.props.modifySections(sections);
		}
    }

	render()
	{
		if (this.state.question === null) {
			return (<div>Loading</div>);
		}
		return (
			<div style={{marginTop: "15px"}}>
				{
					(() => {
						if (this.state.question.auto_scoring.enabled) {
							return (
								this.state.question.preset_answers.map((pa) => {
									return (
										<div key={pa.id}>
											<div style={{float: "left", width: "67%", padding: "10px"}}>
												<ResponseLabel>Response</ResponseLabel>
												<ResponseInput type="text" value={pa.value} onChange={this.changeDropAnswer(pa.id)} />
												<div style={{clear: "both"}}></div>
											</div>
											{/*<div className="addsectionplus" onClick={this.deleteDropdownQuestion(pa.id)}><IoIosTrash /></div>*/}
											<div style={{float: "left", width: "21%", padding: "10px"}}>
												<ResponseLabel>Weight</ResponseLabel>
												<ResponseDropdown value={pa.weight} onChange={this.changeDropWeight(pa.id)}>
													{
														[0,1,2,3,4,5,6,7,8,9,10].map((opt) => {
															return (<option key={opt} value={opt}>{opt}</option>);
														})
													}
												</ResponseDropdown>
											</div>
											<div style={{float: "right", fontSize: "30px", paddingTop: "40px", cursor: "pointer"}} onClick={this.deleteDropdownQuestion(pa.id)}><IoIosTrash /></div>
											<div style={{clear: "both"}}></div>
										</div>
									);
								})
							);
						} else {
							return (
								<div>
									{
										this.state.question.preset_answers.map((pa) => {
											return (
												<div key={pa.id} style={{marginBottom: "10px"}}>
													<ResponseLabel>Response</ResponseLabel>
													<ResponseInput type="text" value={pa.value} onChange={this.changeDropAnswer(pa.id)} style={{float: "left", width: "92%"}} />
													<div style={{float: "right", fontSize: "30px", paddingTop: "5px", cursor: "pointer"}} onClick={this.deleteDropdownQuestion(pa.id)}><IoIosTrash /></div>
													<div style={{clear: "both"}}></div>
												</div>
											);
										})
									}
								</div>
							);
						}
					})()
				}
				<AddResponseBtn onClick={this.addDropdownResponse}>Add Response</AddResponseBtn>
			</div>
		);
	}
}

export default DropdownQuestion;