import React from 'react';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosTrash} from 'react-icons/io';
import { Icon } from 'react-icons-kit';
import {androidRemove} from 'react-icons-kit/ionicons/androidRemove';
import styled from 'styled-components';
import CslTypeAhead from './CslTypeAhead';
import CslDropDown from './CslDropDown';
import CslDatepicker from './CslDatepicker';

const FilterTopBar = styled.div`
	background-color: #ffffff;
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding: 15px 20px;
`;
const FilterText = styled.div`
	float: left;
`;
const FilterChevron = styled.div`
	float: right;
	font-size: 16px;
	cursor: pointer;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	padding: 15px 20px;
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const Section45 = styled.div`
	float: left;
	width: 45%;
	box-sizing: border-box;
`;
const Section40 = styled.div`
	float: left;
	width: 40%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	box-sizing: border-box;
`;
const Section10 = styled.div`
	float: left;
	width: 10%;
	text-align: center;
	box-sizing: border-box;
	padding-top: 10px;
`;
const FieldLabel = styled.div`
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	padding-bottom: 15px;
`;
const FilterClearBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	float: left;
	padding: 12px 13%;
`;
const FilterApplyBtn = styled.button`
	background-color: #4FB2AF;
	border: 1px solid #4FB2AF;
	border-radius: 4px;
	color: #ffffff;
	float: right;
	padding: 12px 18%;
`;

class AdvancedFilter extends React.Component
{

	state = {show_filter: true, contacts: null, review_types: null};

	componentDidMount()
	{
		// console.log("contacts:", this.props.contacts);
		// console.log("review_types:", this.props.review_types);
		let contacts = [];
		Object.entries(this.props.contacts).forEach(([key, value]) => {
			contacts.push({id: key, label: value});
		})
		let review_types = [];
		this.props.review_types.forEach((item) => {
			review_types.push({id: item.id, label: item.nick_name});
		})
		this.setState({contacts, review_types});
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			// console.log("contacts:", this.props.contacts);
			let contacts = [];
			Object.entries(this.props.contacts).forEach(([key, value]) => {
				contacts.push({id: key, label: value});
			})
			let review_types = [];
			this.props.review_types.forEach((item) => {
				review_types.push({id: item.id, label: item.nick_name});
			})
			this.setState({contacts, review_types});
		}
	}

	toggleFilter = (event) => {
		event.preventDefault();
		let show_filter = this.state.show_filter ? false : true;
		this.setState({show_filter});
	}

	render()
	{
		return (
			<div>
				<FilterTopBar>
					<FilterText>Advanced Filters</FilterText>
					<FilterChevron onClick={this.toggleFilter}>
					{
						(() => {
							if (this.state.show_filter) {
								return <IoIosArrowUp />
							} else {
								return <IoIosArrowDown />
							}
						})()
					}
					</FilterChevron>
					<div style={{clear: "both"}}></div>
				</FilterTopBar>
				{
					(() => {
						if (this.state.show_filter) {
							return (
								<FilterBody>
									<Section40>
										<FieldLabel>Reviewer</FieldLabel>
										{
											(() => {
												if (this.state.contacts !== null) {
													return <CslTypeAhead data={this.state.contacts}/>
												}
											})()
										}
									</Section40>
									<Section40>
										<FieldLabel>Reviewee</FieldLabel>
										{
											(() => {
												if (this.state.contacts !== null) {
													return <CslTypeAhead data={this.state.contacts}/>
												}
											})()
										}
									</Section40>
									<Section20>
										<FieldLabel>Result</FieldLabel>
										<CslDropDown />
									</Section20>
									<div style={{clear: "both"}}></div>
									<Section40>
										<FieldLabel style={{marginTop: "20px"}}>Completion Date</FieldLabel>
										<Section45><CslDatepicker /></Section45>
										<Section10><Icon icon={androidRemove} /></Section10>
										<Section45><CslDatepicker /></Section45>
										<div style={{clear: "both"}}></div>
									</Section40>
									<Section40>
										<FieldLabel style={{marginTop: "20px"}}>File Review Type</FieldLabel>
										{
											(() => {
												if (this.state.review_types !== null) {
													return <CslTypeAhead data={this.state.review_types}/>
												}
											})()
										}
									</Section40>
									<Section20>
										<div style={{paddingTop: "50px"}}>
											<FilterClearBtn>Clear</FilterClearBtn>
											<FilterApplyBtn>Apply</FilterApplyBtn>
											<div style={{clear: "both"}}></div>
										</div>
									</Section20>
									<div style={{clear: "both"}}></div>
								</FilterBody>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default AdvancedFilter;