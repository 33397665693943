import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Store from '../../Common/Store.js'
//import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext'

class HtmlEditor extends React.Component
{
    handleDChange = (data) => {
        //console.log(data);
        //let {sections} = this.props.sections;
        this.props.onChange(data);
        // let decode_guidance = btoa(window.encodeURIComponent(data))
        // this.props.onChange(decode_guidance);
    }
    render()
    {
        let content = this.props.content;
        return (
            <div>
                <CKEditor
                    editor={ ClassicEditor }
                    data={content}
                    config={{ removePlugins: ['Table', 'BlockQuote', 'ImageUpload', 'Indent', 'MediaEmbed']}}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        
                        const data = editor.getData();
                        //console.log("rawdata::", viewToPlainText(editor))
                        //console.log("props:", this.props);
                        if('content_type' in this.props){
                        if(this.props.content_type === 'guidance_note'){
                            if (this.props.sectionID === "0") {
                                //Store.updateStore('summary', data);
                            } else {
                                // let sections = JSON.parse(JSON.stringify(Store.getStoreData('sections')));
                                {/*
                                let sections = this.props.sections;
                                console.log("store section::", sections);
                                sections.forEach((section) => {
                                    if (this.props.sectionID === section.id) {
                                        let questions = section.questions;
                                        questions.forEach((question) => {
                                            if (this.props.questionID === question.id) {
                                                // console.log('OLD GUIDANCE::',question.guidance_note)
                                                // console.log('NEW GUIDANCE::',data)
                                                question.guidance_note = data
                                            }
                                        })
                                        // console.log("store section content:", section.content);
                                        //section.content = data;
                                    }
                                })
                            */}
                                // Store.updateStore('sections', sections);
                                // console.log("summary", Store.getStoreData('summary'));
                                //console.log("sections", sections);
                                this.handleDChange(data)
                            }
                            //Store.updateStore('nature_of_conflict', data);
                        }else if(this.props.content_type === 'assignment_comments'){
                            //console.log("COMMENTS::",data)
                            let obj = {
                                "entity": null,
                                "name": "assignment_comments",
                                "value": data
                            }
                            this.handleDChange(obj)
                        }
                    }
                       
                        // console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                    } }
                />
            </div>
        );
    }
}
export default HtmlEditor;