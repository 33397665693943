import React from 'react';
import {IoIosAddCircle} from 'react-icons/io';
import './MGRQuestions.css';
import Sections from './Questions/Sections';
import Utils from '../../../Common/Utils.js';

class MGRQuestionsSection extends React.Component {
    state = { ready: false, sections: [], allowGuidancevalue: false, allowNaquestion: false };
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.setState({ sections: this.props.sections , allowGuidancevalue: this.props.allowGuidancevalue, allowNaquestion: this.props.allowNaquestion,allowNasection: this.props.allowNasection, ready: true});
        console.log("props", this.props.sections, this.props.allowGuidancevalue)
    }

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps) this.setState({ sections : this.props.sections, allowGuidancevalue: this.props.allowGuidancevalue,allowNaquestion: this.props.allowNaquestion,allowNasection: this.props.allowNasection })
    }

    addSection = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        const section = {
            id: Utils.genKey(6),
            name: "Untitled section",
            type: "label",
            collapsed: true,
            questions: [
                {   
                    id: Utils.genKey(6),
                    collapsed: true,
                    question: "",
                    type: [
                        { value: "Memo", selected: false },
                        { value: "Boolean", selected: true },
                        { value: "Alpha", selected: false },
                        { value: "Numeric", selected: false },
                        { value: "Dropdown", selected: false },
                    ],                        
                    weight: [
                        { value: "0", selected: false },
                        { value: "1", selected: true },
                        { value: "2", selected: false },
                        { value: "3", selected: false },
                        { value: "4", selected: false },
                        { value: "5", selected: false },
                        { value: "6", selected: false },
                        { value: "7", selected: false },
                        { value: "8", selected: false },
                        { value: "9", selected: false },
                        { value: "10", selected: false }
                    ],
                    autofail: [
                        { value: "Yes", selected: false },
                        { value: "No", selected: true }
                    ],
                    naallowed: false,
                    comments: { enabled: false, required: false },
                    auto_scoring: { enabled: true, yes: 6, no: 0, hide_score: false },
                    guidance_note: { enabled: this.state.allowGuidancevalue ? true : false, text: "" },
                    preset_answers: [],
                    answer: { text: "", comment: "" },                        
                }
            ]
        }
        sections.push(section);
        this.props.modifySections(sections);
    }

    toggleQuestion = (index, idx, show) => {
        let { sections } = this.state;
        console.log("section:", sections[parseInt(index)]);
        sections[parseInt(index)].questions[parseInt(idx)].collapsed = show;
        this.setState({ sections });        
    }
    
    addQuestionBtnClick = (index) => {

        //event.preventDefault();
        let {sections} = this.state;
        const question =            
                {   
                    id: Utils.genKey(6),
                    collapsed: true,
                    question: "",
                    type: [
                        { value: "Memo", selected: false },
                        { value: "Boolean", selected: true },
                        { value: "Alpha", selected: false },
                        { value: "Numeric", selected: false },
                        { value: "Dropdown", selected: false },
                    ],                        
                    weight: [
                        { value: "0", selected: true },
                        { value: "1", selected: false },
                        { value: "2", selected: false },
                        { value: "3", selected: false },
                        { value: "4", selected: false },
                        { value: "5", selected: false },
                        { value: "6", selected: false },
                        { value: "7", selected: false },
                        { value: "8", selected: false },
                        { value: "9", selected: false },
                        { value: "10", selected: false }
                    ],
                    autofail: [
                        { value: "Yes", selected: true },
                        { value: "No", selected: false }
                    ],
                    naallowed: false,
                    comments: { enabled: false, required: false },
                    auto_scoring: { enabled: true, yes: 0, no: 0 },
                    guidance_note: { enabled: true, text: "" },
                    preset_answers: [],
                    answer: { text: "", comment: "" },                        
                }
            // const newid = sections[parseInt(index)].questions[sections[parseInt(index)].questions.length - 1].id + 1;
        sections[parseInt(index)].questions.push(question);
        //sections.push(section);
        this.setState({sections});

    }    
    toggleSection = (index, show) => {
        //event.preventDefault();
        console.log(index);
        let { sections } = this.state;
        console.log("section:", sections[parseInt(index)]);
        sections[parseInt(index)].collapsed = show;
        this.setState({ sections });
        // this.setState({sections: this.state.sections[index].collapsed})
    } 

    render() {
        //console.log("section:",this.props.sections);
        if (!this.state.ready) {
            return (<div>Loading...</div>);
        }
        //console.log("this is section", this.state.sections)
        return ( 
            <div>
                <div className="questiontabheader">Questions</div>
                <div className="questionsoutercontainer">
                    {
                        this.state.sections.map((section, index) => {
                            //console.log("section::",section)
                            return(
                                <Sections
                                    key={index}
                                    sections={this.state.sections}
                                    item={section}
                                    index={index}
                                    scoring={this.props.scoring}
                                    toggleSection={this.toggleSection}
                                    changeDisplay={this.props.changeDisplay}
                                    changeSectionName={this.props.changeSectionName}
                                    toggleQuestion={this.toggleQuestion}
                                    addQuestionBtnClick={this.addQuestionBtnClick}
                                    allowGuidancevalue={this.state.allowGuidancevalue}
                                    allowNaquestion={this.state.allowNaquestion}
                                    allowNasection={this.state.allowNasection}
                                    modifyQuestion={this.props.modifyQuestion}
                                    modifySections={this.props.modifySections}
                                    sectionID = {section.id}
                                />
                            );
                        })
                    }
                    <div className="sectionpanel">
                        <div className="sectionpanelheader addsectionbtn">
                            <div className="headerleft">Add Section</div>
                            <div className="headerright addsectionright">
                                <div className="addsectionplus" onClick={this.addSection}><IoIosAddCircle /></div>                                
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MGRQuestionsSection;