import React from 'react';
import styled from 'styled-components';
import Utils from '../../../Common/Utils';

const InfoBar = styled.div`
	color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 300;
    margin-top: 10px;
    padding-left: 10px;
`;
const Info = styled.div`
    padding: 5px 0px 5px 5px;
    background-color: #f7f7f7;
    cursor: pointer;
`;
const ResultBtn = styled.button`
	cursor: pointer;
    background-color: #37ADA7;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    padding: 10px;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    margin-top: 10px;
`;

class MGRAddResultItem extends React.Component
{
	state = { resultItem: null, resultItemList: null, index: null, maxIndex: null }

	componentDidMount() {
		this.setState({ resultItem : this.props.resultItem, resultItemList : this.props.resultItemList, index:this.props.index, maxIndex:this.props.maxIndex })
	}
	componentDidUpdate(prevProps) {
		if(this.props !== prevProps) this.setState({ resultItem : this.props.resultItem, resultItemList : this.props.resultItemList, index:this.props.index, maxIndex:this.props.maxIndex })
	}

	addResultItem = (event) => {
		event.preventDefault()
		let newId = Utils.genKey(4)
		let index = this.state.index
		let newItem = JSON.parse(JSON.stringify(this.state.resultItem))
		let newBound = Math.floor((newItem.lbound + newItem.ubound) / 2)
		let resultItemList = this.state.resultItemList
		resultItemList[index].ubound = newBound
		newItem.lbound = newBound
		newItem.id = newId
		newItem.termname = 'Indeterminate'
		newItem.actual_outcome = 'Indeterminate'
		newItem.can_be_deleted = true;
		resultItemList.splice(index+1,0,newItem)
		console.log('resultItemList', resultItemList)
		this.props.modifyResultItemList(resultItemList)
	}

	render() {
		return (<ResultBtn onClick={this.addResultItem}>Add Result Option</ResultBtn>);
	}
}

export default MGRAddResultItem;