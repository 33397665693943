import React from 'react';
import {FaRegCheckSquare, FaRegSquare} from "react-icons/fa";
import styled from 'styled-components';

const ReportSelect = styled.select`
 	margin:10px;
    display: inline-block;
    box-sizing: border-box;
    height: 35px;
    width: 96%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
`;
const ReportLabel = styled.label`
    color: #222222;
    font-weight: 600;
    margin : 10px;
    font-size: 14px;
    margin-right:29%;
`;
const Containar = styled.div`
	width:33%;
	display:inline-block
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
class ConfigFilter extends React.Component
{
	state = {configData: null};

	componentDidMount()
	{
		var config_arr = [];
		var configData = this.props.configData;
		if(configData.length >0){
		 	for(let i= 0 ; i<configData.length; i++){
		 		this.state[Object.keys(configData[i])[0]]= "0";
		 	}
		 }
		this.setState({configData: this.props.configData});	
	}

	componentDidUpdate(prevProps)
	{
		var config_arr = [];
		if (prevProps !== this.props) {
		    var configData = this.props.configData;
		    if(configData.length >0){
			 	for(let i= 0 ; i<configData.length; i++){
			 		this.state[Object.keys(configData[i])[0]]= "0";
			 	}
			}
			if(this.props.user_selection != null){
				for(let i= 0 ; i<configData.length; i++){
			 		this.state[Object.keys(configData[i])[0]]= this.props.user_selection[Object.keys(configData[i])[0]];
			 	}
			}
			this.setState({configData: this.props.configData});
		}
	}

	handleChange = (event) => {
	    event.preventDefault();
	    var state = JSON.parse(JSON.stringify(this.state));
	    delete state.configData
	    state[[event.target.name]] = event.target.value;
	    this.props.changeConfigs(state)
	    this.setState({[event.target.name]: event.target.value});
	}
	render()
	{
		if (this.state.configData === null ) {
			return (<div>Loading...</div>);
		}
		var config = this.state.configData;
	    var configList = config.map(function(config){
             return (
             		<Containar>
             	    <div style={{marginTop:"15px"}}><ReportLabel>{Object.keys(config)}</ReportLabel></div>
                 	<div><ReportSelect onChange={this.handleChange} style={{marginLeft:"2%"}} name={Object.keys(config)} value={this.state[Object.keys(config)]}>
                 	{
					    (() => {
					         if(Object.values(config).length !== 0) {
					            let tag_arr = [];
					            tag_arr.push(<option key={0} value={0}>{'All'}</option>);
					            for(let item of Object.values(config)[0]){ 
					                tag_arr.push(<option key={item.id} value={item.id}>{item.name}</option>);
					            }
					            return tag_arr;
					         }
					    })()
					}
					</ReportSelect>
					</div>
					</Containar>);
        },this);
		return(
			   <div>
			   	{
			        (() => {
			            if(this.props.configDisabled) {
			            	return  <div style={{border:"1px solid #F2F2F2", borderRadius:"4px", pointerEvents: "none", backgroundColor: "#ffffff", opacity: "0.3"}}>{ configList }</div>
			            }else{
			            	return  <div style={{border:"1px solid #F2F2F2", borderRadius:"4px"}}>{ configList }</div>
			            }

			        })()
			    }
			   </div>
			)
	}
}

export default ConfigFilter;