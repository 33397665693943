import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import DatePicker from 'react-date-picker';

const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const FCKCommentDiv = styled.div`
    padding-left: 20px;
    padding-right: 8px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
`;
const ScoreContainer = styled.div`
    background-color: #ecf0f1;    
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
    padding: 15px;
`;
const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;
const ScoreBodyContainer = styled.div`
    padding: 15px;
`;

class AddFTModal extends React.Component
{
	state = {
		payload: null,
		task_data: {
			followup_sub: "",
			followup_note: "",
			assigned_to: "0"
		},
		due_by: new Date()
	};

	componentDidMount()
	{
		let review_list = [];
        let contact_list = Store.getStoreData('reviewee_list');
        let gc_companies = Store.getStoreData('gc_list');
        const gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company_id = parseInt(this.props.payload.company_id) < 100000 ? this.props.payload.company_id : (parseInt(this.props.payload.company_id)%100000);
        let com_users = [];
        gc_companies.forEach((gc_comp) => {
            if(parseInt(gc_comp.id) === parseInt(gc_company_id)) {
                com_users.push(gc_comp)
            } 
        });
        for(let gc in com_users[0].users){
            for(let item of contact_list){
                if(parseInt(item.ID) === parseInt(gc)){
                    review_list.push({"ID" : gc, "ContactName": item.ContactName })
                }
            }
        }
        let task_data = JSON.parse(JSON.stringify(this.state.task_data));
        task_data.assigned_to = review_list[0].ID;
        console.log("Payload", this.props.payload);
        this.setState({ review_list, task_data, payload: this.props.payload});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {
			let review_list = [];
	        let contact_list = Store.getStoreData('reviewee_list');
	        let gc_companies = Store.getStoreData('gc_list');
            let gc_company_id = parseInt(this.props.payload.company_id) < 100000 ? this.props.payload.company_id : (parseInt(this.props.payload.company_id)%100000);
            let com_users = [];
            gc_companies.forEach((gc_comp) => {
                if(parseInt(gc_comp.id) === parseInt(gc_company_id)) {
                    com_users.push(gc_comp)
                } 
            });
            for(let gc in com_users[0].users){
                for(let item of contact_list){
                    if(parseInt(item.ID) === parseInt(gc)){
                        review_list.push({"ID" : gc, "ContactName": item.ContactName })
                    }
                }
            }
	        let task_data = JSON.parse(JSON.stringify(this.state.task_data));
	        task_data.assigned_to = review_list[0].ID;
	        this.setState({ review_list, task_data, payload: this.props.payload});
		}
	}

	closeFollowupTaskModal = (event) => {
		event.preventDefault();
		this.props.closeFollowupTaskModal();
	}

	handleChange = (event) => {
		let task_data = JSON.parse(JSON.stringify(this.state.task_data));
		task_data[event.target.name] = event.target.value;
		this.setState({task_data});
	}

 	onDateChangeDueBy = (date) => {
        this.setState({due_by: date});
    }

    addSubTask = (event) => {
    	event.preventDefault();
    	let task_data = JSON.parse(JSON.stringify(this.state.task_data));
    	task_data.due_by = this.state.due_by;
    	this.props.addSubTask(task_data);
    }

	render()
	{
		if (this.state.payload === null) {
			return (<div></div>);
		}
		return (
			<ScoreContainer>
                <ScoreHeaderContainer>Add a follow-up task to this file check<HeaderButton onClick={this.closeFollowupTaskModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
                <ScoreBodyContainer>
                    <MRModalLabel>Subject</MRModalLabel>
                    <MRModalInput style={{width: "99%"}} type="text" name="followup_sub" onChange={this.handleChange} value={this.state.task_data.followup_sub}/>
                    <FCKCommentDiv style={{padding: "0px", marginTop: "10px"}}>
                        <MRModalLabel>Note</MRModalLabel>
                        <FCKTextarea rows="6" style={{width: "99%"}} name="followup_note" value={this.state.task_data.followup_note} onChange={this.handleChange} />
                    </FCKCommentDiv>
                    <MRModalLabel>Assign To</MRModalLabel>
                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.task_data.assigned_to}>
                        {
                            (() => {
                                if(this.state.review_list.length !== 0) {
                                    let reviewees = [];
                                    console.log("his.state.reviewee_id", this.state.reviewee_id);
                                    for(let item of this.state.review_list){
                                        reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                        
                                    }
                                    return reviewees;
                                }

                            })()
                        }
                    </MRModalSelect>
                    <MRModalLabel>Due By</MRModalLabel>
                    <CSLDateCover>
                        <DatePicker
                            onChange={this.onDateChangeDueBy}
                            clearIcon={null}
                            calendarIcon={null}
                            locale={"en-GB"}
                            value={this.state.due_by}
                        />
                    </CSLDateCover>
                </ScoreBodyContainer>
                <div style={{height: '60px', width: '100%', paddingRight: "12px"}}>
                    <MRModalNextBtn onClick={this.addSubTask}>Add Task</MRModalNextBtn>
                    <MRModalCancelBtn onClick={this.closeFollowupTaskModal}>Close</MRModalCancelBtn>
                </div>
            </ScoreContainer>
		);
	}
}

export default AddFTModal;