import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {chevronDown} from 'react-icons-kit/ionicons/chevronDown';
import {chevronUp} from 'react-icons-kit/ionicons/chevronUp';

const DropContainer = styled.div`
	min-height: 40px;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 8px 10px;
	cursor: pointer;
`;
const Section70 = styled.div`
	float: left;
	width: 70%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	box-sizing: border-box;
`;
const Section15 = styled.div`
	float: left;
	width: 15%;
	box-sizing: border-box;
`;
const ColorBox = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 50%;
`;
const OptionsContainer = styled.div`
	box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    border: 1px solid #DBDBDB;
    z-index: 1;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`;
const SingleOption = styled.div`
	box-sizing: border-box;
	padding: 8px 10px;
	border-bottom: 1px solid #DBDBDB;
	cursor: pointer;
	&:hover {
		background-color: #DBDBDB;
		border-bottom: 1px solid #ffffff;
	}
`;

class CslRoundDropDown extends React.Component
{
	state = {
		data: [
			{id: 1, label: "All", color: "#ffffff"},
			{id: 2, label: "Fail", color: "#ff0000"},
			{id: 3, label: "Indeterminate", color: "#ffb366"},
			{id: 4, label: "Pass", color: "#008000"}
		],
		selected: {id: 1, label: "All", color: "#ffffff"},
		show_options: false
	};

	componentDidMount() {
	    document.addEventListener('mousedown', this.handleClickOutside);
	    let data = JSON.parse(JSON.stringify(this.props.options));
	    data.push(this.props.selected.label === "Select" ? JSON.parse(JSON.stringify(this.props.selected)) : {label: "Select", color: "#ffffff"});
	    // data.push(JSON.parse(JSON.stringify(this.props.selected)));
	    this.setState({data: data, selected: JSON.parse(JSON.stringify(this.props.selected))});
	}

	componentWillUnmount() {
	    document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			let data = JSON.parse(JSON.stringify(this.props.options));
		    data.push(this.props.selected.label === "Select" ? JSON.parse(JSON.stringify(this.props.selected)) : {label: "Select", color: "#ffffff"});
		    this.setState({data: data, selected: JSON.parse(JSON.stringify(this.props.selected)), show_options: false});
		}
	}

	setWrapperRef = (node) => {
	    this.wrapperRef = node;
	}

	handleClickOutside = (event) => {
		if (this.state.show_options && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			// alert('You clicked outside of me!');
			this.setState({show_options: false});
		}
	}

	toggleOptions = (event) => {
		event.preventDefault();
		const show_options = this.state.show_options ? false : true;
		this.setState({show_options});
	}

	makeSelected = (data) => (event) => {
		event.preventDefault();
		this.props.handleNonScore(this.props.question_id, data);
		// this.setState({selected: data, show_options: false});
	}

	render()
	{
		return (
			<div style={{position: "relative", width: "35%"}} ref={this.setWrapperRef}>
				<DropContainer onClick={this.toggleOptions}>
					<Section15>
						<ColorBox style={{backgroundColor: this.state.selected.color}}></ColorBox>
					</Section15>
					<Section70 style={{paddingTop: "3px"}}>{this.state.selected.label}</Section70>
					<Section15 style={{textAlign: "right", paddingTop: "3px"}}>
						<Icon icon={this.state.show_options ? chevronUp : chevronDown} />
					</Section15>
					<div style={{clear: "both"}}></div>
				</DropContainer>
				{
					(() => {
						if (this.state.show_options) {
							return (
								<OptionsContainer>
									{
										this.state.data.map((item) => {
											return (
												<SingleOption key={item.label} onClick={this.makeSelected(item)}>
													<Section15>
														<ColorBox style={{backgroundColor: item.color}}></ColorBox>
													</Section15>
													<Section70 style={{paddingTop: "3px", width: "85px"}}>{item.label}</Section70>
													<div style={{clear: "both"}}></div>
												</SingleOption>
											);
										})
									}
								</OptionsContainer>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default CslRoundDropDown;