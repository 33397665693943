import React from 'react';
import styled from 'styled-components';
import MGRScoringItemList from './MGRScoringItemList';
import NonScoringSection from '../../Partials/NonScoringSection';

const MRModalLabel = styled.label`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalLabelBig = styled.label`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    font-size: 18px;
`;
const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 60px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;

const RadiolevelOn = styled.label`
    color: white;
    background-color: #37ADA7;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 60px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;

class MGRScoring extends React.Component {
    state = {
        scoring: null
    };

    componentDidMount() {
        console.log('reviewConfig in MGRScoring DidMount', this.props.scoring)
        this.setState({ scoring : this.props.scoring })
    }

    handleChange = (evt) => {
        evt.preventDefault();
        this.setState({ [evt.target.name]: evt.target.value });
    }

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps) this.setState({ scoring : this.props.scoring })
    }

    handleRadioChange = (evt) => {
        evt.preventDefault();
        var allowScoring = evt.target.id === 'yes' ? true : false
        this.props.modifyAllowScoring(allowScoring)
    }

    render() {
        
        if(this.state.scoring === null)return <div>Loading...</div>
        return (
        <div style={{padding: "22px 16px", marginBottom: "5px"}}>
            <div style={{paddingBottom: "20px", marginBottom: "3px"}}>
                <MRModalLabelBig>Scoring</MRModalLabelBig>
            </div>
            <div style={{height: "6px", backgroundColor : "#F7F7F7", width: "calc(100% + 32px)", marginLeft: "-16px"}}></div>
            <div>
                <MRModalLabel>Allow Scoring</MRModalLabel>
                <div>
                    <div className="switch" style={{ margin: '0px 0px 5px 60px' }}>
                        {
                            (() => {
                                if(this.state.scoring.scoring_enabled) return <div><RadiolevelOn>Yes</RadiolevelOn><Radiolevel id='no' onClick={this.handleRadioChange}>No</Radiolevel></div>
                                else return <div><Radiolevel id='yes' onClick={this.handleRadioChange}>Yes</Radiolevel><RadiolevelOn>No</RadiolevelOn></div>
                            })()
                        }
                    </div>
                </div>
                <div style={{ clear: "both" }}></div>
                {
                    (() => {
                        if (this.state.scoring.scoring_enabled) {
                            return (<MGRScoringItemList 
                                        scoring={this.state.scoring} 
                                        modifyResultItem={this.props.modifyResultItem} 
                                        modifyResultItemList={this.props.modifyResultItemList}
                                    />);
                        } else {
                            return (<NonScoringSection
                                        scoring={this.state.scoring}
                                        modifyNonScoring={this.props.modifyNonScoring}
                                    />);
                        }
                    })()
                }
            </div>
        </div>
        );
    }



}


export default MGRScoring;