import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import ReportModal from '../MGR/ReportModal';
import FollowupModal from '../Partials/FollowupModal';
import CSLLoader from '../Common/CSLLoader';
import CSLPageloader from '../Common/CSLPageloader';
import ClipLoader from "react-spinners/ClipLoader";
import moment from 'moment';
import EventBus from '../../Common/EventBus.js';
import Utils from "../../Common/Utils";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;


const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;

const DummyOverlay = styled.div`
    display: table;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1000;
    opacity: 0.6;
`;

class TeamIndex extends React.Component
{
    state = {
        ready: false,
        view_count: 0,
        modal_view: null,
        show_spinner: false,
        completed_tasks: null,
        followup_tasks: null,
        show_completed: false,
        show_spinner: false,     
        comp_fc_autofail_result: [],
        completed_filters: {
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},
            reviewee: {name: 'reviewee', 'nickname': 'Reviewee', type: 'multi', options: {},selections: {}, size: 2},
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
            completed_date: {name: 'completed_date', 'nickname': 'Completed Date', type: 'date_range', options: {},selections: {}, size: 2}
        }
    };

    constructor(props) {
        // this.modal_view = null;
        super(props);
    }

    componentDidMount()
    {
        const api = new APICall();
        const payload = {command: "list_tasks"};
        if(Store.getStoreData('fc_index') === 0){
            this.setState({show_spinner: true});
            api.command(payload, this.listTasks);
        }

        EventBus.registerEvent('followup_saved_from_parent', 'TeamIndex', this.showResult_parent)
        if (Store.getStoreData('fc_index') !== null && Store.getStoreData('fc_index') !== 0) {
            const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
            api.command(postData, this.openMyReportModal);
        }
    }

    listTasks_old = (result) => {
        // console.log(result);
        let parent_tasks = [];
        let followup_tasks = [];
        result.result.forEach((task) => {
            if (task.task_type === "COMP_FCK") {
                const task_json = JSON.parse(task.task_json);
                if ("version" in task_json.object_data.headerinfo.review_json) {
                    parent_tasks.push(task);
                }
            } else {
                followup_tasks.push(task);
            }
        })
        Store.updateStore('followup_tasks', followup_tasks);
        let my_completed_fctasks = [];
        for (let i=0; i<parent_tasks.length; i++) {
            const task_json = JSON.parse(parent_tasks[i].task_json);
            if (parent_tasks[i].cur_lane === "COMP_FCK_COMPLETE" || parent_tasks[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT" || parent_tasks[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
                if (parseInt(task_json.object_data.headerinfo.reviewee_id) === Store.getStoreData('loggedin_user_info').ID) {
                    my_completed_fctasks.push(parent_tasks[i]);
                }
            }
        }
        Store.updateStore('my_completed_fctasks', my_completed_fctasks);
        this.setState({ready: true});
    }

    listTasks = (result) => {
        console.log(result);
        let completedTasks = result.result?.completedTasks ? result.result.completedTasks : []
        let completedFilters = result.result?.completedFilters ? result.result.completedFilters : JSON.parse(JSON.stringify(this.state.completed_filters))
        this.processFilteredCompletedTasks(completedTasks)
        this.processFilterOptions(completedFilters)
        this.setState({
            followup_tasks: result.result?.followupTasks ? result.result.followupTasks : [],
            ready: true
        });        
    }

    componentDidUpdate(prevProps)
    {
        console.log("componentDidUpdate");
        if (this.props !== prevProps) {
            console.log("fc_index", Store.getStoreData('fc_index'));
            console.log("pending_fctasks", Store.getStoreData('pending_fctasks'));
            if (Store.getStoreData('fc_index') !== null && Store.getStoreData('fc_index') !== 0) {
                const api = new APICall();
                const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
                api.command(postData, this.openMyReportModal);
            }
        }
    }

    refreshState = (modal_view) => {
        this.setState({view_count: this.state.view_count + 1, modal_view: modal_view});
    }
    closeReportmodal = () => {
        if (document.referrer.includes("/register") || document.referrer.includes("/tmview") || document.referrer === "http://localhost:3000/" ) {
            window.location.assign(document.referrer);
        } else {
            this.setState({modal_view: null});
        }
    }

    showMyResult = (task_id) => (event) => {
        event.preventDefault();
        let tasks = Store.getStoreData('my_completed_fctasks');
        // let result = tasks[index];
        // console.log("single_task:", JSON.stringify(result));
        document.documentElement.scrollTop = 0;
        // Store.updateStore('report', JSON.parse(result.task_json).object_data);
        let postData = { command: "get_task", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openMyReportModal);
    }

    showResult_parent = (task_id)  => {
        this.setState({'show_spinner': true})
        document.documentElement.scrollTop = 0;
        // Store.updateStore('report', JSON.parse(result.task_json).object_data);
        let postData = { command: "get_task", task_id: task_id };
        let api = new APICall();
        api.command(postData, this.openMyReportModal);
      };
    openMyReportModal = (result) => {
        console.log("result:", result);
        if (result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE") {
            Store.updateStore('followup_task', {parenttask: result.result.task.original_parenttask, subtask: result.result.task.parenttask, childtask: result.result.task.childtask});
            // this.setState({modal_view: 'view_followup_modal'});
            // this.view = "manager_index";
            this.setState({modal_view: "view_followup_modal", ready: true});
            // this.modal_view = "view_followup_modal";
        } else {
            Store.updateStore('report_task_json', JSON.parse(result.result.task.parenttask.task_json))
            Store.updateStore('report_task', result.result.task)
            let comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
            this.setState({modal_view: 'view_my_report_modal', ready: true,show_spinner:false, comp_fc_autofail_result});
        }        
    }

    processMyCompleteTasksData = () => {
        let pending_tasks = Store.getStoreData('my_completed_fctasks');
        let contacts = Store.getStoreData('users');
        const has_gc = Store.getStoreData('parent_has_group_companies');
        let ret = {data: [], columns: []};
        let i = 0;
        if (has_gc) {
            ret.columns =[  {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'File Reference', accessor: 'file_ref', minWidth: 160, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'Customer Name', accessor: 'customer_name', minWidth: 160, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'Company', accessor: 'company', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'Reviewee', accessor: 'reviewee', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'Reviewer', accessor: 'reviewer', minWidth: 120, headerStyle: {textAlign: 'left'}},
                            {Header: 'Completed Date', accessor: 'completed_date', minWidth: 120, headerStyle: {textAlign: 'left'}},
                            {'Header' : 'Action', Cell: row => (
                               <div>
                                   <MRButton style={{width: '150px'}} onClick={this.showMyResult(row.original.id)}>Show Report</MRButton>
                               </div>
                            ), width: 210, headerStyle: {textAlign: 'left'}}];
        } else {
            ret.columns =[  {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'File Reference', accessor: 'file_ref', minWidth: 160, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'Customer Name', accessor: 'customer_name', minWidth: 160, headerStyle: {textAlign: 'left'}},
                            {Header: 'Reviewee', accessor: 'reviewee', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
                            {Header: 'Reviewer', accessor: 'reviewer', minWidth: 120, headerStyle: {textAlign: 'left'}},
                            {Header: 'Completed Date', accessor: 'completed_date', minWidth: 120, headerStyle: {textAlign: 'left'}},
                            {'Header' : 'Action', Cell: row => (
                               <div>
                                   <MRButton style={{width: '150px'}} onClick={this.showMyResult(row.original.id)}>Show Report</MRButton>
                               </div>
                            ), width: 210, headerStyle: {textAlign: 'left'}}];
        }
        if (this.state.completed_tasks !== null) {
            for(let d of this.state.completed_tasks) {
                i++;
                let related_individual = "N/A";
                let confirmed_by = "N/A";
                let confirmed_date = "N/A";
                let confirmation_text = "N/A";
                if (d[13] !== 0) {
                    related_individual = d[13].toString() in contacts ? contacts[d[13].toString()].ContactName : "Inactive User";
                }
                if (d[14] !== 0) {
                    confirmed_by = d[14].toString() in contacts ? contacts[d[14].toString()].ContactName : "Inactive User";
                }
                if (d[15] !== 0) {
                    confirmed_date = moment.unix(d[15]).format("DD/MM/YYYY");
                }
                if (d[16] !== "") {
                    confirmation_text = d[16];
                }
                let elem = {
                    'index' : i,
                    'file_ref' : d[5].replace(/'+/g,"'"),
                    'id': d[0],
                    'customer_name': d[1].replace(/'+/g,"'"),
                    'company' : d[2],
                    'reviewee' : d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "Inactive User",
                    'reviewer' : d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
                    'related_individual': related_individual,
                    'confirmed_by': confirmed_by,
                    'confirmed_date': confirmed_date,
                    'completed_date': moment.unix(d[9]).format("DD/MM/YYYY"),
                    'confirmation_text': confirmation_text
                };
                ret.data.push(elem);
            }
        }
        ret.export_columns = {
            file_ref: "File Reference",
            customer_name: "Customer",
            reviewer: "Reviewer",
            reviewee: "Reviewee",
            related_individual: "Related Individual",
            confirmed_by: "File Edited By",
            confirmed_date: "File Edited Date",
            confirmation_text: "File Edit Comments"
        };
        if (has_gc === true) {
            ret.export_columns.company = "Company";
        }
        return ret;
    }

    processFollowupTasksData = () => {
        //let followup_tasks = Store.getStoreData('followup_tasks') !== null ? Store.getStoreData('followup_tasks').sort((a,b) => new Date(a.due_date) - new Date(b.due_date)) : null;
        let contacts = Store.getStoreData('users');
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
                        {Header: 'Task', accessor: 'task', minWidth: 240, headerStyle: {textAlign: 'left'}},
                        {Header: 'Company Name', accessor: 'company_name', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Customer Name', accessor: 'customer_name', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Status', accessor: 'status', width: 120, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 120, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                                return 0;
                            }
                            let adue = a.split("/");
                            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                        }}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton style={{width: '150px'}} onClick={this.showFollowUp(row.original.id)} >View</MRButton>
                           </div>
                        ), headerStyle:{textAlign: 'left'}}];
        if (this.state.followup_tasks !== null) {            
            for(let d of this.state.followup_tasks) {
                //console.log("Sourav",d)
                //let task_json = JSON.parse(d.task_json);
                //if (task_json.task_data.cur_assigned_to.toString() === Store.getStoreData('loggedin_user_info').ID.toString()) {
                    i++;
                    // let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
                    // const c_date = new Date(due_date_str);
                    // let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
                    // let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
                    // let year = c_date.getFullYear().toString();
                    // const due_date = day+"/"+month+"/"+year;
                    // // const due_date = `${year}/${month}/${day}`;
                    // let ch_date = new Date(year, month - 1, day);
                    // ch_date.setDate(ch_date.getDate() + 1);
                    let today = moment().unix();
                    let text = (d[1]+86400) > today ? 'Pending' : 'Overdue';
                    let color = (d[1]+86400) > today ? '#2E8B57' : '#FF0000';
                    text = d[4] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
                    color = d[4] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;
                    //const assigned_to_name = d.cur_assigned_to === 0 ? task_json.task_data.cur_assigned_to.toString() in contacts ? contacts[task_json.task_data.cur_assigned_to.toString()].ContactName : "Inactive User" : d.cur_assigned_to.toString() in contacts ? contacts[d.cur_assigned_to.toString()].ContactName : "Inactive User";
                    const assigned_to_name = d[2].toString() in contacts ? contacts[d[2].toString()].ContactName : "Inactive User";
                    let task = assigned_to_name +" has been assigned a task titled "+ d[3];
                    let elem = {'task' : task,
                                'company_name': d[6],
                                'assigned_to' : assigned_to_name,
                                'status' : {text: text, color: color},
                                'status_text': text,
                                'due_date' : moment.unix(d[1]).format('DD/MM/YYYY'),
                                'id' : d[0],
                                'customer_name': d[5],
                                'task_response': d[9] !== "" ? d[9].replace(/,/g, " ").replace(/\n/g, " ") : '',
                                'complete_date': d[10] !== null ? moment.unix(d[10]).format('DD/MM/YYYY') : ''
                        };
                    ret.data.push(elem);
                //}
            }
            ret.data = ret.data.sort((a,b) => {
                if (a.due_date === b.due_date) {
                  return 0;
                }
                let adue = a.due_date.split("/");
                const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
                let  bbdue = b.due_date.split("/");
                const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                return aReverse < bReverse ? 1 : -1;
            });
        }
        
        ret.export_columns = {task: "Task", company_name: "Company Name", assigned_to: "Assigned To", customer_name: 'Customer Name', status_text: "Status", due_date: "Due Date", task_response: "Task Response",complete_date: "Completed Date"};
        return ret;
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        console.log("task_id", task_id)
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
    }

    openFollowupModal = (result) => {
        console.log("inside openReportModal:", result.result.task);
        Store.updateStore('followup_task', result.result.task);
        this.setState({modal_view: 'view_followup_modal'});
    }

    openPdfPrintView = () => {
        //this.setState({page_view: 'pdfprintview'});
         this.props.changeCurView('pdfprintview')
    }

    clearCompletedAdvancedFilter = () => {
        console.log(this.state.completed_filters);
        let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
        Object.keys(completed_filters).forEach(key => {
            if (completed_filters[key].type === "date_range") {
                completed_filters[key].selections.from_date = moment().format('DD/MM/YYYY')
                completed_filters[key].selections.to_date = moment().format('DD/MM/YYYY')
            } else {
                completed_filters[key].selections = {};
            }
        })
        this.setState({completed_filters});
    }

    switchRadio = (event) => {
        event.preventDefault();
        console.log(this.state.show_completed);
        if (this.state.show_completed === true) {
            let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
            Object.keys(completed_filters).forEach(key => {
                completed_filters[key].selections = {};
            })
            this.setState({
                show_completed: false,
                completed_tasks: null,
                completed_filters
            });
        } else {
            this.setState({show_spinner: true});
            const postData = {command: "get_complete_filter_options"};
            let api = new APICall();
            api.command(postData, this.processFilterOptions);
        }
    }

    processFilterOptions = (result) => {
        console.log(result);
        let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
        Object.keys(completed_filters).forEach(key => {
            completed_filters[key]['options'] = result[key];
            if (key === "completed_date") {
                // const td = new Date();
                // let dd = td.getDate();
                // let mm = td.getMonth()+1;
                // let yyyy = td.getFullYear();
                // dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
                // mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
                // const td_str = `${dd}/${mm}/${yyyy}`;
                // console.log(td_str);

                const td_start = new Date(new Date().setDate(new Date().getDate() - 90));
                const td_end = new Date();
                let dd = td_start.getDate();
                let mm = td_start.getMonth() + 1;
                let yyyy = td_start.getFullYear();
                dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
                mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
                const td_str = `${dd}/${mm}/${yyyy}`;
                console.log("td_str",td_str);
                let dd1 = td_end.getDate();
                let mm1 = td_end.getMonth() + 1;
                let yyyy1 = td_end.getFullYear();
                dd1 = dd1 < 10 ? `0${dd1.toString()}` : dd1.toString();
                mm1 = mm1 < 10 ? `0${mm1.toString()}` : mm1.toString();
                const td_str_e = `${dd1}/${mm1}/${yyyy1}`;
                // td.setUTCHours(0,0,0,0);
                completed_filters.completed_date.selections.from_date = td_str;
                completed_filters.completed_date.selections.to_date = td_str_e;
            }
        })
        this.setState({
            show_completed: true,
            completed_filters,
            show_spinner: false
        });
    }

    applyFilter = (filters) => {
        console.log(filters);
        this.setState({show_spinner: true});
        const postData = {command: "get_filtered_completed_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredCompletedTasks);
    }

    processFilteredCompletedTasks = (result) => {
        console.log(result);
        this.setState({completed_tasks: result, show_spinner: false});
    }

    render()
    {
        if(Store.getStoreData('fc_index') === 0){
            if(Store.getStoreData('filechecker-list_tasks') === null) {
              return <div><CSLPageloader /></div>
            }
        }
        if (this.state.ready === false) {
            return <div><CSLLoader /></div>
        }
        if (this.state.show_spinner === true) {
            return <div><CSLLoader /></div>
        }
        return (
            <div>
                {
                    (() => {
                        if (this.state.show_spinner === true) {
                            return (
                                <div>
                                    <DummyOverlay>
                                        <div style={{display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                                            <ClipLoader
                                                size={150}
                                                color={"#123abc"}
                                                loading={true}
                                            />
                                        </div>
                                    </DummyOverlay>
                                </div>
                            );
                        }
                    })()
                }
                <div style={{clear: "both"}}>&nbsp;</div>
                <CSLTable
                    add={false}
                    export={true}
                    custom_fields={true}
                    export_file_name={"Completed_File_Reviews"}
                    processData={this.processMyCompleteTasksData}
                    tableRows="10"
                    headerText={'Completed File Reviews'}
                    refreshCallback={this.refreshState}
                    toggle_text="Show Completed File Reviews"
                    toggleCallback={this.switchRadio}
                    toggle_state={this.state.show_completed}
                    filters={this.state.completed_filters} 
                    applyFilter={this.applyFilter} 
                    clearAdvancedFilter={this.clearCompletedAdvancedFilter}
                />
                <div style={{clear: "both"}}>&nbsp;</div>
                <CSLTable
                    add={false}
                    export={true}
                    export_file_name={"followup_tasks"}
                    processData={this.processFollowupTasksData}
                    tableRows="10"
                    headerText={'My Followup Tasks'}
                    refreshCallback={this.refreshState}
                />
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                            switch(this.state.modal_view) {
                                case 'view_my_report_modal': return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay style={{height: "2384px"}} /><ReportModal closeModal={this.closeReportmodal} openPrintView={this.openPdfPrintView} comp_fc_autofail_result = {this.state.comp_fc_autofail_result} /></div>; break;
                                case 'view_followup_modal': return <div style={{position:'absolute', left:'10%', width:'100%', height:'1186px', top: '78vh'}}><InactiveOverlay /><FollowupModal closeModal={this.closeReportmodal} /></div>; break;
                            }
                        }
                    })()
                }
            </div>
        );
    }
}
export default TeamIndex;
