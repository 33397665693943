import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import CSLHeader from './Common/CSLHeader';
import TaskHeader from './Common/TaskHeader';
import WelcomeBanner from './Common/WelcomeBanner';
import MGRIndex from './MGR/MGRIndex';
import FCIndex from './FC/FCIndex';
import QAIndex from './QA/QAIndex';
import QAMGRIndex from './QA/QAMGRIndex';
import CSLIndex from './CSL/CSLIndex';
import RegisterIndex from './Register/RegisterIndex';
import AssignView from './MGR/AssignView';
import AnswerView from './Common/AnswerView';
import QcView from './Common/QcView';
import TeamIndex from './TEAM/TeamIndex';
import ModuleConfigHOC from './Moduleconfig/ModuleConfigHOC';
import * as Style from './Common/StyledComponents';
import FCKPrintPreview from './FCKPrintPreview';
//import FCKPrintPreviewFollouwp from './FCKPrintPreviewFollouwp';
import ReferrerHeader from './ReferrerHeader';
import DefaultHeader from './DefaultHeader';
import AlertBox from './Common/AlertBox';
import CSLLoader from './Common/CSLLoader'
import Reload from '../Common/Reload.js';

class Index extends React.Component {
	//state = {authuser: null, OverlayHeight: null, OverlayForCSLModal: null, FCKOverlayHeight: null, ContactName: null, OverlayForModal: null, CheckModalOverlay: null, MGRAddFileCheckModal: null, ReportModal: null, rid: 0};
	state = { view_count: 0, cur_view: null, breadcrumbLastText: null, task_id: 0 }

	constructor(props) {
		super(props);
	}

	componentDidMount = () => {

		// console.log("props:", this.props);
		let task_id = this.props.match ? parseInt(this.props.match.params.taskId) : 0;

		Store.updateStore('fc_index', task_id);
		console.log("task_id:", task_id);
		// console.log("is_admin", is_admin);
		let cur_view = null;
		let is_register = false


		let role = Store.getStoreData('role');
		// console.log("ROLE:", role);
		switch (role) {
			case 'qa_manager': cur_view = 'qa_manager_index'; break;
			case 'senior_manager': cur_view = 'senior_manager_index'; break;
			case 'admin_manager': cur_view = 'admin_manager_index'; break;
			case 'manager': cur_view = 'manager_index'; break;
			case 'view_only_manager': cur_view = 'view_only_manager_index'; break;
			case 'file_checker': cur_view = 'fc_index'; break;
			case 'qa_reviewer': cur_view = 'qa_reviewer_index'; break;
			case 'no_access': cur_view = 'no_access'; break;
			default: cur_view = 'team_index';
		}

		if (Store.getStoreData("parent_has_group_companies") === true && Store.getStoreData("gc_companies").length === 1 && role !== "admin_manager") {
			cur_view = 'no_access';
		}
		// if(task_id > 0){
		// 	cur_view = 'register_index'
		// 	is_register = true;
		// };

		// if(task_id > 0) {
		// 	cur_view = "answerview"
		// }
		Store.updateStore('cur_view', cur_view);
		// Store.updateStore('is_register', is_register);
		this.setState({ cur_view: cur_view, task_id });
	}

	refreshState = () => {
		this.props.refreshCallback();
	}

	refreshThisState = (view) => {
		this.setState({ cur_view: view });
	}

	setBreadcrumbLastText = (text) => {
		this.setState({ breadcrumbLastText: text });
	}
	alertHandler = (result, stack) => {
		// this.setState({alert_param: null})
		//window.location = '/select/ServiceBasedLanding';
		Reload.ReloadPage();
	}

	render() {
		// console.log("INDEX TOP ", window.scrollY)
		let task_id = this.props.match ? this.props.match.params.taskId : 0;
		let alert_param = {
			title: 'No Access', message: 'You are not authorised to access this module. If you think this is an error, please contact your administrator.', ok_text: 'Ok', confirm: false,
			// let alert_param = {title: 'No Access', message: 'Access to this module is restricted, please contact your system administrator.', ok_text: 'Ok', confirm: false,
			alertHandler: this.alertHandler, stack: { id: 0 }
		}
		if (Store.getStoreData('role') !== "no_access" && Store.getStoreData("parent_has_group_companies") === true && Store.getStoreData("gc_companies").length === 1) {
			alert_param = {
				title: 'No Access', message: 'Sorry. You are not authorised to access this module as you are not associated with any group companies. Please ask your system administrator to associate you with one or more group companies.', ok_text: 'Ok', confirm: false,
				alertHandler: this.alertHandler, stack: { id: 0 }
			}
		}
		// console.log('task_id', this.state.cur_view);
		return (
			<div style={{ backgroundColor: "#f2f2f3", position: "relative" }}>
				{
					(() => {
						const page_url = window.location.href;
						// console.log("page_url", page_url);
						// console.log("document.referrer", document.referrer);
						if (this.state.cur_view !== 'csl_index' && this.state.cur_view !== null) {
							// if (document.referrer === "http://localhost:3000/" || document.referrer.includes("/tmview")) {
							if (task_id > 0) {
								return (<ReferrerHeader />);
							} else {
								return (<DefaultHeader />);
							}
						}
					})()
				}
				{
					(() => {
						if (this.state.cur_view !== 'csl_index' && this.state.cur_view !== null) {
							return (<WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('loggedin_user_info').ContactName} />);
						}
					})()
				}
				{
					(() => {
						switch (this.state.cur_view) {
							case 'admin_manager_index':
							case 'view_only_manager_index':
							case 'manager_index':
							case 'senior_manager_index': return <MGRIndex refreshCallback={this.refreshState} show_task={this.state.task_id === 0 ? false : true} changeCurView={this.refreshThisState} setBreadcrumbLastText={this.setBreadcrumbLastText} />; break;
							case 'qa_manager_index': return <QAMGRIndex refreshCallback={this.refreshState} show_task={this.state.task_id === 0 ? false : true} changeCurView={this.refreshThisState} setBreadcrumbLastText={this.setBreadcrumbLastText} />; break;
							case 'fc_index': return <FCIndex refreshCallback={this.refreshState} show_task={this.state.task_id === 0 ? false : true} changeCurView={this.refreshThisState} setBreadcrumbLastText={this.setBreadcrumbLastText} />; break;
							case 'qa_reviewer_index': return <QAIndex changeCurView={this.refreshThisState} show_task={this.state.task_id === 0 ? false : true} setBreadcrumbLastText={this.setBreadcrumbLastText} />; break;
							case 'team_index': return <TeamIndex refreshCallback={this.refreshState} changeCurView={this.refreshThisState} show_task={this.state.task_id === 0 ? false : true} />; break;
							case 'csl_index': return <CSLIndex refreshCallback={this.refreshState} />; break;
							case 'register_index': return <RegisterIndex refreshParentState={this.refreshThisState} taskId={task_id} />; break;
							case 'assignview': return <AssignView changeCurView={this.refreshThisState} />; break;
							case 'answerview': return <AnswerView changeCurView={this.refreshThisState} parentview="manager_index" />; break;
							case 'qcview': return <QcView changeCurView={this.refreshThisState} parentview="qa_reviewer_index" />; break;
							case 'module_config': return <ModuleConfigHOC revertview="admin_manager_index" closeView={this.refreshThisState} />; break;
							case 'pdfprintview': return <FCKPrintPreview changeCurView={this.refreshThisState} />; break;
							//case 'pdfprintview_followup': return <FCKPrintPreviewFollouwp changeCurView={this.refreshThisState} />; break;
							case 'no_access': return <AlertBox alertParam={alert_param} />; break;
							default: return <Style.NoPermission>Sorry, you do not have permission to view this page. </Style.NoPermission>;
						}
					})()
				}
				{/* <CSLLoader /> */}
			</div>
		);
	}
}

export default Index;
