import React from "react";
import Store from "../../Common/Store";
import APICall from "../../Common/APICall";
import styled from "styled-components";
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import { IoIosArrowForward, IoIosLock } from "react-icons/io";
import AssignSection from "../Partials/AssignSection";
import FlaggingSection from "../Partials/FlaggingSection";
import CSLLoader from "../Common/CSLLoader";
import AlertBox from "../Common/AlertBox";
import moment from "moment";


const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const ModalViewBack = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1178px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: -10%;
    top: -5px;
    z-index: 1000;
    opacity: 0.8;
`;
const ModalView = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 80%;
    left: 1%;
`;

const ModalView_contain = styled.div`
       position: absolute;
        left: 10%;
        top: 50px;
        width: 100%;
        height: 1186px;
`;
const ReviewBodyContainer = styled.div`
  padding-top: 25px;
  width: 100%;
`;
const ReviewLeftContainer = styled.div`
  float: left;
  padding-right: 20px;
  width: 20%;
  box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
  float: left;
  padding-left: 20px;
  width: 80%;
  box-sizing: border-box;
`;
const ReviewTab = styled.div`
  background-color: #ffffff;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding: 10px 20px;
  cursor: pointer;
`;
const OverviewFooter = styled.div`
  padding: 15px 0px;
  margin-top: 20px;
  text-align: right;
`;
const WhiteBtn = styled.div`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding: 15px 30px;
  cursor: pointer;
`;
const GreenBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 4px;
  color: #ffffff;
  padding: 15px 30px;
  cursor: pointer;
`;
const PillBox = styled.div`
  background-color: rgb(235, 235, 235);
  border: 1px solid rgb(223, 223, 223);
  text-align: center;
  display: inline-block;
  padding: 10px 18px;
`;
const PillIcon = styled.div`
  display: inline-block;
`;
const PillText = styled.div`
  display: inline-block;
  padding-left: 25px;
`;

class AssignFileCheckHOC extends React.Component {
  state = {
    cur_view: "assign",
    assign_data: null,
    flagging_data: null,
    task_id: 0,
    button_diasabled: false,
    current_action: null,
    show_spinner: false,
    show_confirm: false,
    postData: null,
    alertParam: null,
    gotoview: null,
  };

  componentDidMount() {
    let assign_data = {};
    const today = new Date();
    // const f_today = moment(today).format('DD/MM/YYYY');
    console.log("f_today:", today);
    assign_data.sale_date = today;
    assign_data.due_date = today;
    assign_data.date = today;
    assign_data.file_reference = "";
    assign_data.customer_name = "";
    assign_data.assignment_comments = "";
    const gc_assoc = Store.getStoreData("gc_assoc");
    const module_config = Store.getStoreData("module_config");
    const gc_companies = Store.getStoreData("gc_companies");
    let my_active_reviews = Store.getStoreData("my_active_reviews");
    const admin_managers = Store.getStoreData("admin_managers");
    console.log("gc_assoc:", gc_assoc);
    console.log("gc_companies:", gc_companies);
    console.log("module_config:", module_config);
    console.log("admin_managers:", admin_managers);
    let task = null;
    let task_json = null;
    let task_id = 0;
    let my_companies = [];
    for (let i = 0; i < gc_companies.length; i++) {
      if (Store.getStoreData("parent_has_group_companies") === true) {
        if (gc_companies[i].is_gc === true && gc_companies[i].is_active === 1) my_companies.push({ id: gc_companies[i].id, name: gc_companies[i].company_name });
      } else {
        my_companies.push({ id: gc_companies[i].id, name: gc_companies[i].company_name });
      }
    }
    if (my_companies.length > 1) {
      my_companies.sort((a, b) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : b.name.trim().toLowerCase() > a.name.trim().toLowerCase() ? -1 : 0));
    }
    assign_data.my_companies = my_companies;
    // assign_data.cur_gc_id = my_companies[0].id;
    assign_data.cur_gc_id = "0";
    assign_data.reviewee_acknowledgement = module_config.general.reviewee_acknowledgement.enabled;
    if (this.props.task !== null) {
      task = this.props.task.result.task.parenttask;
      task_json = JSON.parse(this.props.task.result.task.parenttask.task_json);
      assign_data.cur_gc_id = parseInt(task.company_id) < 100000 ? task.company_id : parseInt(task.company_id) % 100000;
      assign_data.customer_name = task_json.object_data.headerinfo.customer_name;
      assign_data.cur_review_id = task_json.object_data.headerinfo.cur_review_id;
      assign_data.cur_reviewer_id = task_json.object_data.headerinfo.reviewer_id;
      assign_data.cur_reviewee_id = task_json.object_data.headerinfo.reviewee_id;
      assign_data.reviewee_designation = task_json.object_data.headerinfo.reviewee_designation;
      assign_data.file_reference = task_json.object_data.headerinfo.file_reference;
      assign_data.sale_date = new Date(task_json.task_data.sale_date);
      assign_data.due_date = new Date(task_json.task_data.due_date);
      assign_data.custom_terminology_fields = "custom_terminology_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.custom_terminology_fields : [];
      assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
      task_id = task.id;
      assign_data.assignment_comments = "assignment_comments" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.assignment_comments : "";
      if ("bin_files" in task_json.object_data) {
        assign_data.curBinFiles = task_json.object_data.bin_files;
        assign_data.cur_files_json = assign_data.curBinFiles.length === 0 ? null : true;
      }
      assign_data.reviewee_acknowledgement = "reviewee_acknowledgement" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.reviewee_acknowledgement : false;
    }
    let reviewers = [];
    let reviewees = [];
    if (this.props.task !== null) {
      Object.keys(gc_assoc[assign_data.cur_gc_id.toString()].users).forEach((key) => {
        if (
          gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].role === "admin_manager" ||
          gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].role === "senior_manager" ||
          gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].role === "manager" ||
          gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].role === "file_checker"
        ) {
          reviewers.push({ id: key.toString(), name: gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].ContactName });
        }
        if (gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].role !== "no_access") {
          reviewees.push({ id: key.toString(), name: gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].ContactName });
        }
      });
      for (let key in admin_managers) {
        const reviewer_exist = reviewers.find((user) => parseInt(user.id) === parseInt(key));
        if (reviewer_exist === undefined) reviewers.push({ id: key.toString(), name: admin_managers[key.toString()].ContactName });
        const reviewee_exist = reviewees.find((user) => parseInt(user.id) === parseInt(key));
        if (reviewee_exist === undefined) reviewees.push({ id: key.toString(), name: admin_managers[key.toString()].ContactName });
      }
      if (reviewers.length > 1) {
        reviewers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
      }
      if (reviewees.length > 1) {
        reviewees.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
      }
    }
    assign_data.reviewers = reviewers;
    assign_data.reviewees = reviewees;
    // assign_data.cur_reviewer_id = this.props.task === null ? reviewers[0].id : assign_data.cur_reviewer_id;
    assign_data.cur_reviewer_id = this.props.task === null ? 0 : assign_data.cur_reviewer_id;
    if (this.props.task === null && Store.getStoreData("loggedin_user_info").role === "file_checker") assign_data.cur_reviewer_id = Store.getStoreData("loggedin_user_info").ID;
    if (this.props.task === null) {
      assign_data.cur_reviewee_id = 0;
      for (let item of reviewees) {
        if (item.id !== assign_data.cur_reviewer_id) {
          assign_data.cur_reviewee_id = item.id;
          break;
        }
      }
    }
    // assign_data.cur_reviewee_id = reviewers.length > 1 ? reviewers[1].id : reviewers[0].id;
    let calculated_gc_id = parseInt(assign_data.cur_gc_id) === Store.getStoreData("parent_company").id ? parseInt(assign_data.cur_gc_id) : parseInt(Store.getStoreData("parent_company").id) * 100000 + parseInt(assign_data.cur_gc_id);
    let my_reviews = [];
    my_active_reviews.forEach((item) => {
      if (module_config.general.show_parent_company === true) {
        if (item.company_id === calculated_gc_id || item.company_id === Store.getStoreData("parent_company").id) {
          my_reviews.push({ id: item.id, name: item.nick_name });
        }
      } else {
        if (item.company_id === calculated_gc_id) {
          my_reviews.push({ id: item.id, name: item.nick_name });
        }
      }
    });
    if (my_reviews.length > 1) {
      my_reviews.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
    }
    assign_data.my_reviews = my_reviews;
    // assign_data.cur_review_id = this.props.task === null ? my_reviews[0].id : assign_data.cur_review_id;
    assign_data.cur_review_id = this.props.task === null ? 0 : assign_data.cur_review_id;
    let reviewee_designation_required = false;
    let custom_terminology_fields = [];
    let additional_fields = [];
    let reviewee_designation = "";
    if (Object.keys(module_config).length > 0) {
      reviewee_designation_required = module_config.general.reviewee_designation_required;
      // send_mail_config =  module_config.general.email_remainder_enable;
      // flagging_fc_enabled =  module_config.general.flagging_fc_enabled;
      if (module_config.custom_fields.custom_terminology_fields.length !== 0 && module_config.custom_fields.enabled) {
        module_config.custom_fields.custom_terminology_fields.forEach((item) => {
          let copy_item = JSON.parse(JSON.stringify(item));
          copy_item.value = item.type === "Calendar" ? new Date() : "";
          copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
          if (item.type === "Dropdown" && item.other === true) {
            copy_item.other_value = "";
          }
          if (item.name === "reviewee_designation" && item.type === "Dropdown") {
            // reviewee_designation = item.dropdown_options[0];
            console.log("options ", item.dropdown_options);
            reviewee_designation = "Select";
          }
          custom_terminology_fields.push(copy_item);
        });
      }
      if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
        module_config.custom_fields.additional_fields.forEach((item) => {
          let copy_item = JSON.parse(JSON.stringify(item));
          copy_item.value = item.type === "Calendar" ? new Date() : "";
          // copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
          if (item.type === "Dropdown") {
            copy_item.dropdown_options = JSON.parse(JSON.stringify(item.dropdown_options));
            copy_item.dropdown_options.sort();
          }
          if (item.type === "Dropdown" && item.other === true) {
            copy_item.other_value = "";
          }
          additional_fields.push(copy_item);
        });
      }
    }
    assign_data.custom_terminology_fields = this.props.task === null ? custom_terminology_fields : assign_data.custom_terminology_fields;
    assign_data.additional_fields = this.props.task === null ? additional_fields : assign_data.additional_fields;
    assign_data.reviewee_designation = this.props.task === null ? reviewee_designation : assign_data.reviewee_designation;
    assign_data.reviewee_designation_required = reviewee_designation_required;
    assign_data.binFiles = [];
    assign_data.curBinFiles = this.props.task === null ? [] : assign_data.curBinFiles;
    assign_data.cur_files_json = this.props.task === null ? null : assign_data.cur_files_json;
    // console.log("assign_data:", assign_data);
    let selected_users = [];
    let unselected_users = [];
    if (this.props.task !== null) {
      selected_users = "flagged_users" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.flagged_users : selected_users;
      for (let key in gc_assoc[assign_data.cur_gc_id.toString()].users) {
        let exist = selected_users.find((su) => parseInt(su.id) === parseInt(key));
        if (exist === undefined) {
          unselected_users.push({
            id: gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].ID,
            name: gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].ContactName,
            email: gc_assoc[assign_data.cur_gc_id.toString()].users[key.toString()].email,
          });
        }
      }
    }
    const flagging_data = { selected_users, unselected_users };
    this.setState({ assign_data, flagging_data, task_id });
  }

  handleChangeCurGc = (company_id) => {
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    const my_active_reviews = Store.getStoreData("my_active_reviews");
    const gc_assoc = Store.getStoreData("gc_assoc");
    const module_config = Store.getStoreData("module_config");
    const admin_managers = Store.getStoreData("admin_managers");
    let reviewers = [];
    let reviewees = [];
    Object.keys(gc_assoc[company_id.toString()].users).forEach((key) => {
      if (
        gc_assoc[company_id.toString()].users[key.toString()].role === "admin_manager" ||
        gc_assoc[company_id.toString()].users[key.toString()].role === "senior_manager" ||
        gc_assoc[company_id.toString()].users[key.toString()].role === "manager" ||
        gc_assoc[company_id.toString()].users[key.toString()].role === "file_checker"
      ) {
        reviewers.push({ id: key.toString(), name: gc_assoc[company_id.toString()].users[key.toString()].ContactName });
      }
      if (gc_assoc[company_id.toString()].users[key.toString()].role !== "no_access") {
        reviewees.push({ id: key.toString(), name: gc_assoc[company_id.toString()].users[key.toString()].ContactName });
      }
    });
    for (let key in admin_managers) {
      const reviewer_exist = reviewers.find((user) => parseInt(user.id) === parseInt(key));
      if (reviewer_exist === undefined) reviewers.push({ id: key.toString(), name: admin_managers[key.toString()].ContactName });
      const reviewee_exist = reviewees.find((user) => parseInt(user.id) === parseInt(key));
      if (reviewee_exist === undefined) reviewees.push({ id: key.toString(), name: admin_managers[key.toString()].ContactName });
    }
    if (reviewers.length > 1) {
      reviewers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
    }
    if (reviewees.length > 1) {
      reviewees.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
    }
    assign_data.reviewers = reviewers;
    assign_data.reviewees = reviewees;
    assign_data.cur_reviewer_id = Store.getStoreData("loggedin_user_info").role === "file_checker" ? Store.getStoreData("loggedin_user_info").ID : reviewers[0].id;
    for (let item of reviewees) {
      if (item.id !== assign_data.cur_reviewer_id) {
        assign_data.cur_reviewee_id = item.id;
        break;
      }
    }
    let calculated_gc_id = parseInt(company_id) === Store.getStoreData("parent_company").id ? parseInt(company_id) : parseInt(Store.getStoreData("parent_company").id) * 100000 + parseInt(company_id);
    let my_reviews = [];
    my_active_reviews.forEach((item) => {
      if (module_config.general.show_parent_company === true) {
        if (item.company_id === calculated_gc_id || item.company_id === Store.getStoreData("parent_company").id) {
          my_reviews.push({ id: item.id, name: item.nick_name });
        }
      } else {
        if (item.company_id === calculated_gc_id) {
          my_reviews.push({ id: item.id, name: item.nick_name });
        }
      }
    });
    if (my_reviews.length > 1) {
      my_reviews.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0));
    }
    assign_data.my_reviews = my_reviews;
    assign_data.cur_gc_id = parseInt(company_id);
    let selected_users = [];
    let unselected_users = [];
    // selected_users = "flagged_users" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.flagged_users : selected_users;
    for (let key in gc_assoc[company_id.toString()].users) {
      let exist = selected_users.find((su) => parseInt(su.id) === parseInt(key));
      if (exist === undefined) {
        unselected_users.push({
          id: gc_assoc[company_id.toString()].users[key.toString()].ID,
          name: gc_assoc[company_id.toString()].users[key.toString()].ContactName,
          email: gc_assoc[company_id.toString()].users[key.toString()].email,
        });
      }
    }
    const flagging_data = { selected_users, unselected_users };
    this.setState({ assign_data, flagging_data });
  };

  modifyAssignData = (obj) => {
    this.setState({ assign_data: obj });
  };

  filesLoaded = (files) => {
    let me = Store.getStoreData("loggedin_user_info");
    for (let i = 0; i < files.length; i++) {
      if (!("user_id" in files[i])) {
        files[i].user_id = me.ID;
        files[i].upload_date = Date.now();
      }
    }
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data.binFiles = files;
    assign_data.due_date = new Date(assign_data.due_date);
    assign_data.sale_date = new Date(assign_data.sale_date);
    this.setState({ assign_data });
  };

  changeSection = (view) => (event) => {
    event.preventDefault();
    if (view === "complete") {
      Store.updateStore("fc_index", this.state.task_id);
     // this.props.changeCurView("answerview_modal");
    } else {
      this.setState({ cur_view: view });
    }
     this.props.changeCurView(view === "complete" ? "answerview" : "assignview");
  };

  addUser = (user_id) => {
    let flagging_data = JSON.parse(JSON.stringify(this.state.flagging_data));
    const user_to_be_added = flagging_data.unselected_users.find((user) => user.id === user_id);
    flagging_data.selected_users.push(user_to_be_added);
    let new_unselected_users = [];
    for (let user of flagging_data.unselected_users) {
      if (user.id !== user_id) new_unselected_users.push(user);
    }
    flagging_data.unselected_users = new_unselected_users;
    this.setState({ flagging_data });
  };

  removeUser = (user_id) => {
    let flagging_data = JSON.parse(JSON.stringify(this.state.flagging_data));
    const user_to_be_added = flagging_data.selected_users.find((user) => user.id === user_id);
    flagging_data.unselected_users.push(user_to_be_added);
    let new_selected_users = [];
    for (let user of flagging_data.selected_users) {
      if (user.id !== user_id) new_selected_users.push(user);
    }
    flagging_data.selected_users = new_selected_users;
    this.setState({ flagging_data });
  };

  closeView = (event) => {
    //window.location.assign("/register");
    this.props.closeModal();
  };

  handleChange = (name, value) => {
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data[name] = value;
    console.log("mod assign_data:", assign_data);
    this.setState({ assign_data });
  };

  modifyCustomAndAdditionalFieldChange = (assign_data) => {
    // let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    // assignment.assign_data = assign_data;
    // assignment.assign_data.due_date = new Date(assignment.assign_data.due_date);
    // assignment.assign_data.sale_date = new Date(assignment.assign_data.sale_date);
    this.setState({ assign_data: assign_data });
  };

  assignFCKJob = (view) => (event) => {
    this.setState({ show_spinner: true });
    event.preventDefault();
    // await this.halt(2000);
    setTimeout(() => {
      console.log("button pressed");
      console.log(view);
      console.log(this.state.assign_data);
      const module_config = Store.getStoreData("module_config");
      const gc_assoc = Store.getStoreData("gc_assoc");
      if (module_config.custom_fields.enabled) {
        let fields = [];
        module_config.custom_fields.custom_terminology_fields.forEach((item) => {
          if (item.name === "sale_date") {
            if (item.required === true && this.state.assign_data.sale_date === "") {
              fields.push(item.nickname);
            }
          }
          if (item.name === "reviewee_designation") {
            if (item.required === true && this.state.assign_data.reviewee_designation === "") {
              fields.push(item.nickname);
            }
          }
        });
        if (module_config.custom_fields.additional_fields.length !== 0) {
          this.state.assign_data.additional_fields.forEach((item) => {
            if (item.required === true && item.value === "") {
              fields.push(item.nickname);
            }
          });
        }
        if (fields.length !== 0) {
          let err_msg = "";
          fields.forEach((item) => {
            err_msg = err_msg + item + ", ";
          });
          err_msg = err_msg.substring(0, err_msg.length - 2);
          if (fields.length === 1) {
            err_msg = err_msg + " field is mandatory";
          } else {
            err_msg = err_msg + " fields are mandatory";
          }
          this.setState({ show_spinner: false });
          alert(err_msg);
          return false;
        }
      }
      if (parseInt(this.state.assign_data.cur_gc_id) === 0) {
        this.setState({ show_spinner: false });
        alert("Please select a group company");
        return false;
      }
      if (parseInt(this.state.assign_data.cur_review_id) === 0) {
        this.setState({ show_spinner: false });
        alert("Please select a review");
        return false;
      }
      if (parseInt(this.state.assign_data.cur_reviewer_id) === 0) {
        this.setState({ show_spinner: false });
        alert("Please select a reviewer");
        return false;
      }
      if (parseInt(this.state.assign_data.cur_reviewee_id) === 0) {
        this.setState({ show_spinner: false });
        alert("Please select a reviewee");
        return false;
      }
      // return false;
      if (module_config.custom_fields.enabled === false) {
        if (module_config.general.reviewee_designation_required && this.state.assign_data.reviewee_designation === "") {
          this.setState({ show_spinner: false });
          alert("Reviewee Designation field must not be empty");
          return false;
        }
      }
      if (this.state.assign_data.customer_name === "") {
        alert("Customer Name field must not be empty");
        return false;
      }
      if (this.state.assign_data.file_reference != "") {
        const cur_review = Store.getStoreData("my_active_reviews").find((item) => {
          return item.id === parseInt(this.state.assign_data.cur_review_id);
        });
        let prop_task_json = null;
        if (this.props.task !== null) {
          prop_task_json = JSON.parse(this.props.task.result.task.parenttask.task_json);
          // console.log("review_json", prop_task_json);
        }
        // return false;
        let gr_company_id = parseInt(this.state.assign_data.cur_gc_id);
        let is_gc = gc_assoc[this.state.assign_data.cur_gc_id.toString()].is_gc;
        let headerinfo = {
          cur_review_id: parseInt(this.state.assign_data.cur_review_id),
          customer_name: this.state.assign_data.customer_name,
          date: this.state.assign_data.date,
          file_reference: this.state.assign_data.file_reference,
          reviewee_designation: this.state.assign_data.reviewee_designation,
          reviewee_acknowledgement: this.state.assign_data.reviewee_acknowledgement,
          reviewer_id: parseInt(this.state.assign_data.cur_reviewer_id),
          reviewee_id: parseInt(this.state.assign_data.cur_reviewee_id),
          assignment_comments: this.state.assign_data.assignment_comments,
          custom_terminology_fields: this.state.assign_data.custom_terminology_fields,
          additional_fields: this.state.assign_data.additional_fields,
          flagged_users: this.state.flagging_data.selected_users,
          review_json: prop_task_json !== null ? prop_task_json.object_data.headerinfo.review_json : JSON.parse(cur_review.review_json),
        };
        let dateobj = new Date(this.state.assign_data.due_date);
        let curmonth = parseInt(dateobj.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? dateobj.getFullYear() + "/" + currmonth + "/" : dateobj.getFullYear() + "/0" + currmonth + "/";
        dueDateStr += dateobj.getDate() < 10 ? "0" + dateobj.getDate() : dateobj.getDate();

        dateobj = new Date(this.state.assign_data.sale_date);
        curmonth = parseInt(dateobj.getMonth());
        currmonth = curmonth + 1;
        var saleDateStr = currmonth >= 10 ? dateobj.getFullYear() + "/" + currmonth + "/" : dateobj.getFullYear() + "/0" + currmonth + "/";
        saleDateStr += dateobj.getDate() < 10 ? "0" + dateobj.getDate() : dateobj.getDate();

        let scorecolor = null;
        let scorename = null;
        let obtainedscore = 0;
        let totalscore = 0;
        let actual_outcome = "";
        let task_json = {
          task_data: {
            module_id: "filechecker",
            last_action: "COMP_FCK_CREATE",
            actiondate: Date.now(),
            company_id: 0,
            contact_id: 0,
            system_id: "1001",
            cur_lane: "COMP_FCK_ASSIGNMENT",
            cur_assigned_to: parseInt(this.state.assign_data.cur_reviewer_id),
            task_type: "COMP_FCK",
            parent_task_id: 0,
            due_date: dueDateStr,
            sale_date: saleDateStr,
            gr_company_id: gr_company_id,
            is_gc: is_gc,
          },
          object_data: {
            headerinfo: headerinfo,
            bin_files: [...this.state.assign_data.binFiles, ...this.state.assign_data.curBinFiles],
            result: {
              comment: "",
              ragratingcolor: scorecolor,
              ragratingname: scorename,
              scoresobtained: obtainedscore,
              totalscore: totalscore,
              outcome: actual_outcome,
            },
          },
          action_data: [
            {
              action: "COMP_FCK_CREATE",
              actiondate: Date.now(),
              assigned_to: parseInt(this.state.assign_data.cur_reviewer_id),
              performed_by: Store.getStoreData("loggedin_user_info").ID,
              cur_lane: "COMP_FCK_ASSIGNMENT",
            },
          ],
        };

        let postData = { command: "create_filecheck_task", task_json: task_json, task_id: this.state.task_id, sent_email: true };
        if (parseInt(Store.getStoreData("loggedin_user_info").ID) === parseInt(this.state.assign_data.cur_reviewer_id) && module_config.general.self_notification.selected_option === "Never") {
          postData.sent_email = false;
        }
        console.log("postData:", postData);
        if (this.state.task_id > 0) {
          postData.command = "update_filecheck_task";
          postData.task_json["task_data"].last_action = "COMP_FCK_UPDATE";
          postData.task_json.action_data = { action: "COMP_FCK_UPDATE", performed_by: Store.getStoreData("loggedin_user_info").ID, actiondate: Date.now(), assigned_to: parseInt(this.state.assign_data.cur_reviewer_id), cur_lane: "COMP_FCK_ASSIGNMENT" };
        }
        // console.log("postdata", postData);
        // return false;
        // this.current_action = view;
        if (parseInt(Store.getStoreData("loggedin_user_info").ID) === parseInt(this.state.assign_data.cur_reviewer_id) && module_config.general.self_notification.selected_option === "Ask") {
          this.setState({ show_spinner: false });
          let alert_param = {
            title: "Email Notification?",
            message: "You have assigned this File Check to yourself. Would you like an email notification as a reminder?",
            ok_text: "Yes, please",
            cancel_text: "No, thanks",
            confirm: true,
            alertHandler: this.alertHandler,
            stack: { id: 0 },
          };
          this.setState({ show_confirm: true, postData, alert_param, gotoview: view });
        } else {
          let api = new APICall();
          this.setState({ button_diasabled: true, current_action: view });
          api.command(postData, this.reloadData);
        }
      } else {
        this.setState({ show_spinner: false });
        alert("Please complete all mandatory fields");
      }
    }, 2000);
  };

  alertHandler = (status, stack) => {
    console.log(status);
    console.log(stack);
    let postData = JSON.parse(JSON.stringify(this.state.postData));
    postData.sent_email = status;
    let api = new APICall();
    this.setState({ button_diasabled: true, current_action: this.state.gotoview, show_confirm: false });
    api.command(postData, this.reloadData);
  };

  reloadData = (result) => {
    if (result) {
      this.setState({ button_diasabled: false });
    }
    if (this.state.current_action === "complete") {
      // console.log("result=========>", this.state.current_action);
      // this.props.completeFilechecck(result.task_id);
      Store.updateStore("fc_index", this.state.task_id === 0 ? result.task_id : this.state.task_id);
      //this.props.changeCurView("answerview_modal");
      this.props.changeCurView("answerview");
    } else {
      // if (document.referrer === "http://localhost:3002" || document.referrer.includes("filechecker") || document.referrer.includes("moduleconfig") || document.referrer.includes("config") || document.referrer.includes("/select/ServiceBasedLanding")) {
      //     window.location.reload(true);
      // } else {
      //     window.location.assign(document.referrer);
      // }
      this.props.closeModal();
      // if (document.referrer.includes("/register") || document.referrer.includes("/tmview") || document.referrer === "http://localhost:3000/") {
      //   window.location.assign(document.referrer);
      // } else {
      //   window.location.assign(Store.getStoreData("is_debug") ? "/" : process.env.REACT_APP_API_URL + "mstop/filechecker");
      // }
    }
  };

  render() {
    if (this.state.assign_data === null) {
      return <div>Loading...</div>;
    }
    return (
      <ModalView_contain>

            <ModalViewBack></ModalViewBack>
             <ModalView>
      <div>
        {(() => {
          if (this.state.show_spinner === true)
            return (
              <div>
                <CSLLoader />
              </div>
            );
        })()}
        {(() => {
          if (this.state.show_confirm === true) return <AlertBox alertParam={this.state.alert_param} />;
        })()}
        <div style={{ float: "left", paddingTop: "10px" }}>
          <PillBox style={{ backgroundColor: "#0f2b4b", border: "1px solid #0f2b4b" }}>
            <PillText style={{ paddingLeft: "0px", color: "#ffffff" }}>Assign</PillText>
          </PillBox>
          {(() => {
            if (this.state.task_id === 0) {
              return (
                <PillBox>
                  <PillIcon style={{ opacity: "0.2" }}>
                    <IoIosLock />
                  </PillIcon>
                  <PillText style={{ opacity: "0.2" }}>Complete</PillText>
                </PillBox>
              );
            } else {
              return (
                <PillBox style={{ cursor: "pointer", backgroundColor: "#a3c7f1" }} onClick={this.changeSection("complete")}>
                  <PillIcon style={{ opacity: "0.2" }}></PillIcon>
                  <PillText style={{ paddingLeft: "0px", color: "#282828" }}>Complete</PillText>
                </PillBox>
              );
            }
          })()}
          <PillBox>
            <PillIcon style={{ opacity: "0.2" }}>
              <IoIosLock />
            </PillIcon>
            <PillText style={{ opacity: "0.2" }}>Review</PillText>
          </PillBox>
          <PillBox>
            <PillIcon style={{ opacity: "0.2" }}>
              <IoIosLock />
            </PillIcon>
            <PillText style={{ opacity: "0.2" }}>Quality Assurance</PillText>
          </PillBox>
        </div>
        <CloseBtn onClick={this.closeView}><FaTimes /></CloseBtn>
        <div style={{ clear: "both" }}></div>
        <ReviewBodyContainer>
          <ReviewLeftContainer>
            {(() => {
              if (this.state.cur_view === "assign") {
                return (
                  <ReviewTab>
                    <div style={{ float: "left", fontWeight: "600" }}>Assign</div>
                    <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                      <IoIosArrowForward />
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </ReviewTab>
                );
              } else {
                return (
                  <ReviewTab onClick={this.changeSection("assign")}>
                    <div style={{ float: "left" }}>Assign</div>
                    <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                      <IoIosArrowForward />
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </ReviewTab>
                );
              }
            })()}
            {(() => {
              // console.log("this.state.assignment.flagging_fc_enabled", this.state.assignment)
              if (Store.getStoreData("module_config").general.flagging_fc_enabled === true && (Store.getStoreData("role") === "manager" || Store.getStoreData("role") === "admin_manager")) {
                if (this.state.cur_view === "flagging") {
                  return (
                    <ReviewTab id="flagging" style={{ marginTop: "2px" }}>
                      <div style={{ float: "left", fontWeight: "600" }}>Flagging</div>
                      <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                        <IoIosArrowForward />
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </ReviewTab>
                  );
                } else {
                  return (
                    <ReviewTab id="flagging" style={{ marginTop: "2px" }} onClick={this.changeSection("flagging")}>
                      <div style={{ float: "left" }}>Flagging</div>
                      <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                        <IoIosArrowForward />
                      </div>
                      <div style={{ clear: "both" }}></div>
                    </ReviewTab>
                  );
                }
              }
            })()}
          </ReviewLeftContainer>
          <ReviewRightContainer style={{ width: "60%" }}>
            {(() => {
              if (this.state.cur_view === "assign") {
                return (
                  <AssignSection
                    assign_data={this.state.assign_data}
                    closeModal={this.closeView}
                    handleChangeCurGc={this.handleChangeCurGc}
                    modifyAssignData={this.modifyAssignData}
                    handleChange={this.handleChange}
                    onSaleDateChange={this.onSaleDateChange}
                    onDateChange={this.onDateChange}
                    filesLoaded={this.filesLoaded}
                    task_id={this.state.task_id}
                    modifyCustomAndAdditionalFieldChange={this.modifyCustomAndAdditionalFieldChange}
                  />
                );
              } else {
                return <FlaggingSection flagging={this.state.flagging_data} addUser={this.addUser} removeUser={this.removeUser} />;
              }
            })()}
            <OverviewFooter>
              <WhiteBtn id="assign-fck-cancel-btn" onClick={this.closeView}>
                Cancel
              </WhiteBtn>
              <GreenBtn id="assign-fck-submit-btn" style={{ marginLeft: "15px" }} disabled={this.state.button_diasabled} onClick={this.assignFCKJob("list")}>
                Save and Close
              </GreenBtn>
              <GreenBtn id="assign-fck-cfc-btn" style={{ marginLeft: "15px" }} disabled={this.state.button_diasabled} onClick={this.assignFCKJob("complete")}>
                Continue to File Check
              </GreenBtn>
            </OverviewFooter>
          </ReviewRightContainer>
        </ReviewBodyContainer>
      </div>
            </ModalView>
            </ModalView_contain>
    );
  }
}

export default AssignFileCheckHOC;
