import React from 'react';
import styled from 'styled-components';
import {FaSquare, FaCheckSquare, FaTrashAlt} from 'react-icons/fa';
import Store from '../Common/Store';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 80%;
    left: 10%;
    top: 30px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddResultConfigButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    padding-left: 20px;
    font-weight: 600;
`;
const MultisetLayoutContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    margin-bottom: 5px;
`;
const TermDiv = styled.div`
    display: inline-block;
    box-sizing: border-box;
    width: 25%;
    height: auto;
    float: left;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const MRModalTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;

class FCKQuestion extends React.Component
{
    render() {
        let elm = this.props.question;
        console.log('elm', elm.na_allowed)
        return (
                <MultisetLayoutContainer key={elm.id}>
                    <TermDiv style={{width: "10%"}}>
                        {/* <MRModalLabel>{this.props.index + 1}</MRModalLabel> */}
                        <label>{this.props.index + 1}</label>
                        <TermInputDiv>
                            <MRModalSelect name="type" onChange={this.props.handleQChange(elm.id)}>
                                {
                                    elm.type.map((opt, index) => {
                                        if (opt.selected) {
                                            return (<option key={index} value={opt.value} selected>{opt.value}</option>);
                                        } else {
                                            return (<option key={index} value={opt.value}>{opt.value}</option>);
                                        }
                                    })
                                    
                                }
                            </MRModalSelect>
                        </TermInputDiv>
                    </TermDiv>
                    <TermDiv style={{paddingLeft: "10px", width: "40%"}}>
                        <label>Question</label>
                        <TermInputDiv>
                            <MRModalTextarea rows="3" value={elm.question} name="question" onChange={this.props.handleQChange(elm.id)} />
                        </TermInputDiv>
                    </TermDiv>
                    {
                        (() => {
                            if (this.props.showScores) {
                                return (
                                    <TermDiv  style={{paddingLeft: "10px", width: "10%"}}>
                                        <label>Weighting</label>
                                        <TermInputDiv>
                                            {
                                                (() => {
                                                    if (this.props.showScores) {
                                                        return (
                                                            <MRModalSelect name="weight" onChange={this.props.handleQChange(elm.id)}>
                                                                {
                                                                    elm.weight.map((wt, index) => {
                                                                        if (wt.selected) {
                                                                            return (<option key={index} value={wt.value} selected>{wt.value}</option>);
                                                                        } else {
                                                                            return (<option key={index} value={wt.value}>{wt.value}</option>);
                                                                        }
                                                                    })
                                                                }
                                                            </MRModalSelect>
                                                        );
                                                    }
                                                })()
                                            }
                                        </TermInputDiv>
                                    </TermDiv>
                                );
                            }
                        })()
                    }
                    <TermDiv  style={{paddingLeft: "10px", width: "10%"}}>
                        <label>Auto Fail</label>
                        <TermInputDiv>
                            <MRModalSelect name="autofail" onChange={this.props.handleQChange(elm.id)}>
                                {
                                    elm.autofail.map((af, index) => {
                                        if (af.selected) {
                                            return (<option key={index} value={af.value} selected>{af.value}</option>);
                                        } else {
                                            return (<option key={index} value={af.value}>{af.value}</option>);
                                        }
                                    })
                                }
                            </MRModalSelect>
                        </TermInputDiv>
                    </TermDiv>
                    <TermDiv  style={{paddingLeft: "10px", width: "10%"}}>
                        <label>Allow N/A</label>
                        <TermInputDiv>
                        <MRModalSelect name="naallowed" onChange={this.props.handleQChange(elm.id)}>
                                {
                                    elm.naallowed.map((na, index) => {
                                        if (na.selected) {
                                            return (<option key={index} value={na.value} selected>{na.value}</option>);
                                        } else {
                                            return (<option key={index} value={na.value}>{na.value}</option>);
                                        }
                                    })
                                }
                        </MRModalSelect>
                        </TermInputDiv>
                    </TermDiv>
                    <TermDiv  style={{paddingLeft: "10px", width: "10%"}}>
                        <label>Mandatory</label>
                        <TermInputDiv>
                            {
                                (() => {
                                    if (elm.mandatory === "false") {
                                        return (
                                            <div onClick={this.props.handleMandatoryCheck(elm.id)} style={{cursor: "pointer", fontSize: "20px"}}><FaSquare /></div>
                                        );
                                    } else {
                                        return (
                                            <div onClick={this.props.handleMandatoryCheck(elm.id)} style={{cursor: "pointer", fontSize: "20px"}}><FaCheckSquare /></div>
                                        );
                                    }
                                })()
                            }
                        </TermInputDiv>
                    </TermDiv>
                    <TermDiv  style={{paddingLeft: "5px", width: "10%"}}>
                        <TermInputDiv style={{cursor: "pointer", paddingTop: "27px", fontSize: "27px", marginTop: "22px"}} onClick={this.props.removeQuestion(elm.id)}><FaTrashAlt /></TermInputDiv>
                    </TermDiv>
                    <div style={{clear: "both"}}></div>
                </MultisetLayoutContainer>
            )
    }
}

export default FCKQuestion
