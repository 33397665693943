import React from 'react';
import styled from 'styled-components';
import {FaPlusCircle} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import {IoIosTrash} from 'react-icons/io';
import AddFTaskModal from './AddFTaskModal';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
import moment from 'moment';

const OverviewInfoHeader = styled.div`
	padding: 12px 15px;
	background-color: #04ada8;
	border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const InfoBar = styled.div`
    font-size: 11px;
    font-style: italic;
    text-align: center;
    font-weight: 600;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #04ADA8;
    border-left: 1px solid #d7dddf;
    border-right: 1px solid #d7dddf;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const DelButton = styled.div`
    font-size: 24px;
    cursor: pointer;
`;

class FutureFollowups extends React.Component {

	state = {
		show_modal: false,
		followup_data: {id: "", subject: "", note: "", assigned_to: 0, due_by: new Date(), attachments: []},
		ft_users: []
	};



	showFutureFollowupTaskModal = (task_id) => (event) => {
		let followup_data = JSON.parse(JSON.stringify(this.state.followup_data));
		event.preventDefault();
		let ft_users = [];
		if (task_id === 0) {
			const gc_assoc = Store.getStoreData('gc_assoc');
			const admin_managers = Store.getStoreData("admin_managers");
	        let gc_company_id = parseInt(this.props.parent_company_id) < 100000 ? this.props.parent_company_id : (parseInt(this.props.parent_company_id)%100000);
	        for (let key in gc_assoc[gc_company_id.toString()].users) {
	        	if (gc_assoc[gc_company_id.toString()].users[key.toString()].ContactName !== "Inactive User") {
	        		ft_users.push(gc_assoc[gc_company_id.toString()].users[key.toString()]);
	        	}
	        }
	        for (let key in admin_managers) {
	            const exist = ft_users.find(rl => rl.ID.toString() === key.toString());
	            if (exist === undefined) {
	                ft_users.push({ID: key, ContactName: admin_managers[key.toString()].name});
	            }
	        }
	        if (ft_users.length > 1) {
	            ft_users.sort((a,b) => (a.ContactName.toLowerCase() > b.ContactName.toLowerCase()) ? 1 : ((b.ContactName.toLowerCase() > a.ContactName.toLowerCase()) ? -1 : 0));
	        }
	        const task_reviewer = ft_users.find(rl => rl.ID === this.props.reviewer.ID);
	        let assigned_to = task_reviewer === undefined ? ft_users[0].ID : task_reviewer.ID;
	        followup_data.assigned_to = assigned_to;
	        followup_data.id = Utils.genKey(20);
	        followup_data.subject = "";
	        followup_data.note = "";
	        followup_data.attachments = [];
	        followup_data.due_by = new Date();
		}
		this.setState({show_modal: true, followup_data, ft_users});
	}

	closeFollowupTaskModal = () => {
		this.setState({show_modal: false});
		// console.log("props in close:", this.props);
	}

	editFutureFollowUp = (task_id) => (event) => {
		event.preventDefault();
		// console.log(task_id);
		let future_followups = JSON.parse(JSON.stringify(this.props.future_followups));
		let followup_data = future_followups.find(ft => ft.id === task_id);
		// console.log("followup_data:", followup_data);
		// followup_data.due_by = new Date(followup_data.due_by * 1000);
		followup_data.due_by = moment.unix(followup_data.due_by).format('YYYY/MM/DD');
		let ft_users = [];
		const gc_assoc = Store.getStoreData('gc_assoc');
		const admin_managers = Store.getStoreData("admin_managers");
        let gc_company_id = parseInt(this.props.parent_company_id) < 100000 ? this.props.parent_company_id : (parseInt(this.props.parent_company_id)%100000);
        for (let key in gc_assoc[gc_company_id.toString()].users) {
        	if (gc_assoc[gc_company_id.toString()].users[key.toString()].ContactName !== "Inactive User") {
        		ft_users.push(gc_assoc[gc_company_id.toString()].users[key.toString()]);
        	}
        }
        for (let key in admin_managers) {
            const exist = ft_users.find(rl => rl.ID.toString() === key.toString());
            if (exist === undefined) {
                ft_users.push({ID: key, ContactName: admin_managers[key.toString()].name});
            }
        }
        if (ft_users.length > 1) {
            ft_users.sort((a,b) => (a.ContactName.toLowerCase() > b.ContactName.toLowerCase()) ? 1 : ((b.ContactName.toLowerCase() > a.ContactName.toLowerCase()) ? -1 : 0));
        }
        // console.log("followup_data:", followup_data);
		this.setState({show_modal: true, followup_data, ft_users});
	}

	removeFutureFollouUp = (task_id) => (event) => {
		event.preventDefault();
		// console.log(task_id);
		this.props.removeFutureFollowup(task_id);
	}

	processFutureFollowupData = () => {
		const users = Store.getStoreData("users");
        let ret = {data: [], columns: []};
        ret.columns =[
            {Header: '#', accessor: 'index', minWidth: 10, headerStyle: {textAlign: 'left'}},
            {Header: 'Title', accessor: 'subject', minWidth: 100, headerStyle: {textAlign: 'left'}},
            {Header: 'Assigned to', accessor: 'assigned_to', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{display: "contents"}}><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
            {Header: 'Action', minWidth: 100, filterable: false, headerStyle: {textAlign: 'left'}, Cell: row => (
                <div style={{display: row.original.type === "new" ? "block" : "none"}}>
                    <MRButton style={{width: '150px', float: "left"}} onClick={this.editFutureFollowUp(row.original.id)}>View</MRButton>
                    <DelButton onClick={this.removeFutureFollouUp(row.original.id)}><IoIosTrash /></DelButton>
                    <div style={{clear: "both"}}></div>
                </div>
            )}
        ];
        let i = 0;
        // console.log("this.props.future_followups:", this.props.future_followups);
        let today = moment().set('hour', 23).set('minute', 59).set('second', 59).unix();
        for (let d of this.props.future_followups) {
        	i++;
			d.due_by = moment.unix(d.due_by).set('hour', 23).set('minute', 59).set('second', 59).unix();
            let text = d.due_by >= today ? 'Pending' : 'Overdue';
            let color = d.due_by >= today ? '#2E8B57' : '#FF0000';

            let elem = {
                'id' : d.id,
                'index' : i,
                'subject': d.subject,
                'assigned_to' : users[d.assigned_to.toString()].ContactName,
                'status' : {text: text, color: color},
                'type' : "new"
            };
            ret.data.push(elem);
        }
        // console.log("inside");
        if (this.props.childtask.length !== 0) {
        	// console.log("condition satisfied");
        	for (let ct of this.props.childtask) {
        		i++;
        		const task_json = JSON.parse(ct.task_json);
        		let due_unix = moment(ct.due_date).set('hour', 23).set('minute', 59).set('second', 59).unix();
        		let text = due_unix > today ? 'Pending' : 'Overdue';
	            let color = due_unix > today ? '#2E8B57' : '#FF0000';
	            text = ct.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Completed" : text;
	            color = ct.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2E8B57" : color;
	            let elem = {
	                'id' : ct.id,
	                'index' : i,
	                'subject': task_json.object_data.subject,
	                'assigned_to' : users[ct.cur_assigned_to.toString()].ContactName,
	                'status' : {text: text, color: color},
	                'type' : "old"
	            };
	            ret.data.push(elem);
        	}
        }
        return ret;
	}

	render () {
		console.log("childtask:", this.props.childtask);
		// console.log("future_followups:", this.props.future_followups);
		// console.log("followup_data:", this.state.followup_data);
		return (
			<div>
				<OverviewInfoHeader>
	                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Tasks associated with this file check</div>
	                {
	                    (() => {
	                        let readonly = this.props.readonly === undefined ? false : this.props.readonly;
	                        if (readonly === false) {
	                            return (
	                                <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showFutureFollowupTaskModal(0)}><FaPlusCircle /></div>
	                            );
	                        }
	                    })()
	                }
	                <div style={{clear: "both"}}></div>
                </OverviewInfoHeader>
                {
                	this.state.show_modal === true
                	&&
                	<AddFTaskModal
                		followup_data={this.state.followup_data}
                		ft_users={this.state.ft_users}
                		closeFollowupTaskModal={this.closeFollowupTaskModal}
                		addFutureFollowup={this.props.addFutureFollowup}
                	/>
                }
                {
                    (() => {
                        if ("info" in this.props) {
                            if (this.props.info !== null && this.props.info === true) {
                                return (
                                    <InfoBar>An automated follow-up task will be sent to the reviewee for them to acknowledge they have read and understood the results of this File Review</InfoBar>
                                );
                            }
                        }
                    })()
                }
                {
                	(() => {
                		if (this.props.childtask.length === 0 && this.props.future_followups.length === 0) {
                			return (
                				<OverviewInfoBody>
			                        <div style={{textAlign: "center",fontStyle: "italic", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>There are no tasks associated with this file check.</div>
			                    </OverviewInfoBody>
                			);
                		} else {
                			return (
                				<CSLTable
			                        export={false}
			                        export_file_name={""}
			                        add={false}
			                        processData={this.processFutureFollowupData}
			                        tableRows="10"
			                        headerText={''}
			                        refreshCallback={this.refreshState}
			                    />
                			);
                		}
                	})()
                }
                {/*{
                	(this.props.future_followups.length !== 0 || this.props.childtask.length !== 0) &&
                	<CSLTable
                        export={false}
                        export_file_name={""}
                        add={false}
                        processData={this.processFutureFollowupData}
                        tableRows="10"
                        headerText={''}
                        refreshCallback={this.refreshState}
                    />
                }*/}
			</div>
		);
	}
}

export default FutureFollowups;