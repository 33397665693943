import React from "react";
import moment from "moment";
import styled from "styled-components";
import Store from "../../../Common/Store.js";
import DatePicker from "react-date-picker";
import { FaCalendarAlt } from "react-icons/fa";

const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 40%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #282828;
  background-color: #ffffff;
  height: 25px;
  padding-top: 2px;
  padding-left: 2px;
  float: left;
  position: relative;
`;

const start_day = moment().subtract(30, "days").format("YYYY/MM/DD");

class DateSelect extends React.Component {
  state = { items: {}, from_date: new Date(), to_date: new Date() };

  setComponentState = () => {
    let items = JSON.parse(JSON.stringify(this.props.items));
    let init = Store.getStoreData("init_filters");
    // console.log('items this.props', this.props)
    // if(this.props.selections === {}) {
    // 	this.setState({items: items, from_date: new Date(), to_date: new Date()});
    // } else {
    // 	this.setState({items: items});
    // }
    let f_dt_obj = new Date();
    let t_dt_obj = new Date();
    if (Object.keys(this.props.selectedItems).length !== 0) {
      let fdt_arr = this.props.selectedItems.from_date.split("/");
      const f_from_date = `${fdt_arr[2]}-${fdt_arr[1]}-${fdt_arr[0]}`;
      f_dt_obj = new Date(f_from_date);
      const tdt_arr = this.props.selectedItems.to_date.split("/");
      const f_to_date = `${tdt_arr[2]}-${tdt_arr[1]}-${tdt_arr[0]}`;
      t_dt_obj = new Date(f_to_date);
    }
    this.setState({ items: items, from_date: f_dt_obj, to_date: t_dt_obj });
  };
  componentDidMount() {
    this.setComponentState();
  }

  componentDidUpdate(prevprops) {
    if (this.props !== prevprops) {
      this.setComponentState();
    }
  }

  handleFromDateChange = (date) => {
    if (!moment.isDate(date)) {
      return;
    }
    let date_range = {};

    let date_str = moment(date).format("DD/MM/YYYY");
    console.log("applyAdvancedFilterDate, date_str", date_str);
    date_range["from_date"] = date_str;
    date_range["to_date"] = moment(this.state.to_date).format("DD/MM/YYYY");
    let stack = "stack" in this.props ? this.props.stack : null;
    this.props.selectCallback(date_range, stack);
    this.setState({ from_date: date });
  };

  handleToDateChange = (date) => {
    if (!moment.isDate(date)) {
      return;
    }
    let date_range = {};
    date_range["from_date"] = moment(this.state.from_date).format("DD/MM/YYYY");
    let date_str = moment(date).format("DD/MM/YYYY");
    date_range["to_date"] = date_str;
    let stack = "stack" in this.props ? this.props.stack : null;
    this.props.selectCallback(date_range, stack);
    this.setState({ to_date: date });
  };

  render() {
    if (this.state.data === null) {
      return <div>Loading...</div>;
    }

    console.log("DateSelect state", this.state);
    return (
      <div style={{ marginTop: "8px" }}>
        <CSLDateCover>
          <DatePicker
            onChange={(date) => this.handleFromDateChange(date)}
            clearIcon={null}
            disableCalendar={this.state.disableCalendar}
            calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
            locale={"en-GB"}
            value={this.state.from_date}
          />
        </CSLDateCover>
        <div style={{ float: "left", paddingTop: 17, marginLeft: 10, marginRight: 10 }}>To</div>
        <CSLDateCover>
          <DatePicker onChange={this.handleToDateChange} clearIcon={null} calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />} locale={"en-GB"} value={this.state.to_date} />
        </CSLDateCover>
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

export default DateSelect;
