import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import CompanyChanger from './CompanyChanger';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import * as jsPDF from 'jspdf'
import Iframe from 'react-iframe'

const marginX = 13;
const marginY = 20;
const maxX = 210 - (2 * marginX);
const maxY = 297 - (2 * marginY);
const footer_height = 15;
const para_gap = 9;
const line_height = 12;
const paraIndent = 25;
const paraGap = 35;
const columnLeftFirst = 18;
const columnLeftSecond = 90;
const columnLeftThird = 150;
const columnLeftFourth = 198;
const lineHeight = 6;
const pageBottom = 255;
const pageRight = 185;

class FeeEarnarAnalysis extends React.Component
{
	state = {faPDF : null, pdfURL: null, pageWidth: maxX, pageHeight: maxY, ready: false};

	
	processDate = (date) => {
	    let date_str = date.split('T')[0]
	    let date_parts = date_str.split('-')
	    return `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`
	}
	drawHeader = () =>{
		var actionArea = maxX;
		var leftOffset = (actionArea - this.getTextWidth(Store.getStoreData('parent_company').company_name)) / 2;	
        this.faPDF.setFontSize(30);
		this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		this.faPDF.text(Store.getStoreData('parent_company').company_name, leftOffset -15, this.current_top);
		this.current_top += 30;
		this.faPDF.setFontSize(20);
		leftOffset = (actionArea - this.getTextWidth('Fee Earner Report')) / 2;
		this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Fee Earner Report",  marginX +leftOffset , this.current_top);
		this.current_top += 30;
	}
	getTextWidth = (text) => {
		var textWidth = this.faPDF.getTextWidth(text)
		var wordGap = this.faPDF.getTextWidth(' ')
		return (parseInt(textWidth) + parseInt(wordGap));
	}
	drawOverview = () =>{
		var loggedInConatct = Store.getStoreData('loggedin_user_info').ContactName
		this.faPDF.setFontSize(14);
		this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Overview :", this.currentRight , this.current_top);
		this.current_top += line_height;
        //this.currentRight += this.getTextWidth('Overview :');
		this.faPDF.setFontSize(13);
		this.faPDF.setTextColor('#444444');
		this.faPDF.setFontStyle('normal');
		this.faPDF.text(" This Fee Earner Report was run by ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth(' This Fee Earner Report was run by ');
		this.faPDF.text(loggedInConatct + ' ', this.currentRight , this.current_top);
        this.currentRight += this.getTextWidth(loggedInConatct + ' ');
        this.faPDF.text('at '+ this.findDateAndTime('').time + ' on ' + this.findDateAndTime('').date, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.currentRight = marginX;
		this.faPDF.text("The following filters were applied", this.currentRight , this.current_top);
		this.current_top += line_height;
		this.currentRight = marginX + paraIndent;
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Date filters applied : ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('Date filters applied : ');
		this.faPDF.setFontStyle('normal');
		this.faPDF.text("Start Date ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('Start Date ');
		this.faPDF.text(this.props.FeeData.startDate + ', ', this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth(this.props.FeeData.startDate + ', ');
		this.faPDF.text("End Date ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('End Date ');
		this.faPDF.text(this.props.FeeData.endDate, this.currentRight , this.current_top);
        var reviewer_name = this.props.FeeData.reviewer_name == undefined ? 'All' : this.props.FeeData.reviewer_name;
        var question_type = this.props.FeeData.question_type == 0 ? 'All' : this.props.FeeData.question_type;
		this.current_top += line_height;
		this.currentRight = marginX + paraIndent;
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Filters applied : ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('Filters applied : ');
		this.faPDF.setFontStyle('normal');
		this.faPDF.text("Division : " + this.props.FeeData.division, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.faPDF.text("Reviewer : " + reviewer_name, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.faPDF.text("Question Set : " + question_type, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.currentRight = marginX;
		var totalFileAuditCount = 0;
		let fee_earner_data = this.props.FeeData.feeEarnerData;
        for(let i = 0 ; i < fee_earner_data.length; i++){
        	totalFileAuditCount += parseInt(fee_earner_data[i].total_audit_count);
        }
		this.faPDF.text(totalFileAuditCount.toString() + " File Reviews were applicable to the report at run time", this.currentRight , this.current_top);
	}
	footerSection = () =>{
		var footerTop = (297-marginY);
		this.faPDF.line(marginX, footerTop, (210 - marginX), footerTop);
		this.faPDF.setLineWidth(0.5); 
		this.faPDF.setDrawColor('#444444');
		footerTop += line_height-2;
		this.faPDF.setFontSize(12);
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Fee Earner Report", 88 , footerTop);
	}
	processPageFooters = () => {
        var totalPages = this.faPDF.internal.getNumberOfPages();
        for (var i = 1; i <= totalPages; i++) {
            this.faPDF.setPage(i);
            this.faPDF.setFontStyle('normal')
            this.faPDF.text("© Compliancy Services Ltd", this.faPDF.internal.pageSize.getWidth() - 195, this.faPDF.internal.pageSize.getHeight() - 10);
            this.faPDF.text("Page " + i + " of " + totalPages, this.faPDF.internal.pageSize.getWidth() - 40, this.faPDF.internal.pageSize.getHeight() - 10);
        }
    }
    processFeeEarnerDetails =() =>{
    	var fee_earner_data = this.props.FeeData.feeEarnerData;
        console.log("FeeData", fee_earner_data.length);
        this.faPDF.addPage();
	    this.footerSection();
	    this.currentRight = marginX;
	    this.current_top = marginY;
	    this.faPDF.setTextColor('#2E466F')
	    this.faPDF.text("Fee Earner Detail",this.currentRight , this.current_top);
	    this.current_top += line_height;
	    this.currentRight = marginX;
	    this.current_top += lineHeight;
        
        if(fee_earner_data.length >0 ){
        		this.faPDF.setFillColor('#B4C6E7');
			    this.faPDF.rect(this.currentRight, this.current_top, pageRight, 5.5 * lineHeight, 'FD');
			    this.faPDF.setDrawColor('#686868');
			    this.faPDF.setLineWidth(0.55);
        	    this.faPDF.line(columnLeftSecond, this.current_top, columnLeftSecond, this.current_top + 5.5 * lineHeight, "F");
        	    this.faPDF.line(columnLeftThird, this.current_top, columnLeftThird, this.current_top + 5.5 * lineHeight, "F");
        	    this.faPDF.setTextColor('#444444');
        	    this.faPDF.setFontSize(11);
        	    this.faPDF.text('Fee Earner Name', columnLeftFirst, this.current_top + lineHeight *5);
        	    this.faPDF.text('Designation', columnLeftSecond + 2, this.current_top + lineHeight * 5);
        	    this.faPDF.text('File Audit Form', columnLeftThird + 7, this.current_top + lineHeight - 2);
        	    this.current_top += lineHeight;
        	    this.faPDF.line(columnLeftThird, this.current_top, columnLeftFourth, this.current_top);
        	    this.faPDF.line(columnLeftThird +25, this.current_top, columnLeftThird +25, this.current_top + 4.5 * lineHeight, "F");
        	    this.current_top += lineHeight;
        	    this.faPDF.text('Corrective', columnLeftThird +2, this.current_top);
        	    this.faPDF.text('Total File', columnLeftThird +27, this.current_top);
        	    this.current_top += lineHeight;
        	    this.faPDF.text('Actions', columnLeftThird +2, this.current_top);
        	    this.faPDF.text('Audits', columnLeftThird +27, this.current_top);
        	    this.current_top += lineHeight;
        	    this.faPDF.text('Required', columnLeftThird +2, this.current_top);
        	    this.current_top += lineHeight;
        	    this.faPDF.text('Count', columnLeftThird +2, this.current_top);
        	    this.current_top += lineHeight -3;
        	    var totalCorrectiveActionCount = 0;
        	    var totalFileAuditCount = 0;

        	    for(let i = 0 ; i < fee_earner_data.length; i++){
        	    	if (this.current_top >= 260) {
        				this.faPDF.addPage();
        			    this.footerSection();
        			    this.currentRight = marginX;
        			    this.current_top = marginY;
        			    this.faPDF.setFillColor('#FFFFFF');
        			    this.faPDF.setDrawColor('#686868');
        			    this.faPDF.rect(this.currentRight, this.current_top, pageRight, lineHeight, 'FD');
        			    this.faPDF.line(columnLeftSecond, this.current_top, columnLeftSecond, this.current_top + lineHeight, "F");
        			    this.faPDF.line(columnLeftThird, this.current_top, columnLeftThird, this.current_top + lineHeight, "F");
        			    this.faPDF.line(columnLeftThird+25, this.current_top, columnLeftThird+25, this.current_top + lineHeight, "F");
        			    
        			    this.faPDF.text(fee_earner_data[i].fee_earner_name === undefined ? '' : fee_earner_data[i].fee_earner_name, columnLeftFirst, this.current_top +4);
        			    this.faPDF.text(fee_earner_data[i].designation === undefined ? '' : fee_earner_data[i].designation.toString(), columnLeftSecond +2, this.current_top +4);
        			    this.faPDF.text(fee_earner_data[i].child_count === undefined ? '' : fee_earner_data[i].child_count.toString(), columnLeftThird +23 - parseInt(this.faPDF.getTextWidth(fee_earner_data[i].child_count.toString())), this.current_top +4);
        			    this.faPDF.text(fee_earner_data[i].total_audit_count === undefined ? '' : fee_earner_data[i].total_audit_count.toString(), columnLeftFourth - 2 - parseInt(this.faPDF.getTextWidth(fee_earner_data[i].total_audit_count.toString())), this.current_top +4);
        			    this.current_top += lineHeight;
        			    totalCorrectiveActionCount += parseInt(fee_earner_data[i].child_count);
        			    totalFileAuditCount += parseInt(fee_earner_data[i].total_audit_count);
        	    	}else{
        	    		this.faPDF.setFillColor('#FFFFFF');
        	    		this.faPDF.setDrawColor('#686868');
        	    		this.faPDF.rect(this.currentRight, this.current_top, pageRight, lineHeight, 'FD');
        	    		this.faPDF.line(columnLeftSecond, this.current_top, columnLeftSecond, this.current_top + lineHeight, "F");
        	    		this.faPDF.line(columnLeftThird, this.current_top, columnLeftThird, this.current_top + lineHeight, "F");
        	    		this.faPDF.line(columnLeftThird+25, this.current_top, columnLeftThird+25, this.current_top + lineHeight, "F");
        	    		
        	    		this.faPDF.text(fee_earner_data[i].fee_earner_name === undefined ? '' : fee_earner_data[i].fee_earner_name, columnLeftFirst, this.current_top +4);
        	    		this.faPDF.text(fee_earner_data[i].designation === undefined ? '' : fee_earner_data[i].designation.toString(), columnLeftSecond +2, this.current_top +4);
        	    		this.faPDF.text(fee_earner_data[i].child_count === undefined ? '' : fee_earner_data[i].child_count.toString(), columnLeftThird +23 - parseInt(this.faPDF.getTextWidth(fee_earner_data[i].child_count.toString())), this.current_top +4);
        	    		this.faPDF.text(fee_earner_data[i].total_audit_count === undefined ? '' : fee_earner_data[i].total_audit_count.toString(), columnLeftFourth - 2 - parseInt(this.faPDF.getTextWidth(fee_earner_data[i].total_audit_count.toString())), this.current_top +4);
        	    		this.current_top += lineHeight;
        	    		totalCorrectiveActionCount += parseInt(fee_earner_data[i].child_count);
        	    		totalFileAuditCount += parseInt(fee_earner_data[i].total_audit_count);
        	    	}
        	    	
        	    }
        	    this.faPDF.setFillColor('#B4C6E7');
        	    this.faPDF.rect(this.currentRight, this.current_top, pageRight, 1.2 * lineHeight, 'FD');
        	    this.faPDF.setDrawColor('#686868');
        	    this.faPDF.setLineWidth(0.55);
        	    this.faPDF.line(columnLeftSecond, this.current_top, columnLeftSecond, this.current_top +  1.2 * lineHeight, "F");
        	    this.faPDF.line(columnLeftThird, this.current_top, columnLeftThird, this.current_top + 1.2 * lineHeight, "F");
        	    this.faPDF.line(columnLeftThird+25, this.current_top, columnLeftThird+25, this.current_top + 1.2 * lineHeight, "F");
        	    this.current_top += lineHeight;
        	    this.faPDF.text("Totals", columnLeftSecond + 45 , this.current_top);
        	    this.faPDF.text(totalCorrectiveActionCount.toString(), (columnLeftThird +23)-parseInt(this.faPDF.getTextWidth(totalCorrectiveActionCount.toString())), this.current_top);
        	    this.faPDF.text(totalFileAuditCount.toString(), columnLeftFourth -2 - parseInt(this.getTextWidth(totalFileAuditCount.toString())) , this.current_top);
        }else{
        	this.faPDF.setTextColor('#636161');
			this.faPDF.setFontSize(11);
        	this.faPDF.text('No File Checks qualified for this report', this.currentRight +57, this.current_top);
        }
    	

    }
	formatAMPM = (hours, minutes) =>{
	  var ampm = hours >= 12 ? 'pm' : 'am';
	  hours = hours % 12;
	  hours = hours ? hours : 12;
	  //minutes = minutes < 10 ? '0'+minutes : minutes;
	  var strTime = hours + ':' + minutes + ' ' + ampm;
	  return strTime;
	}

	findDateAndTime = (input_date) =>{
		    var DateTime = {date : '', time : ''}
		    let d = new Date();
		    let date = '';
		    let day = '';
		    let monthIndex = '';
		    let year = '';
		    if(input_date != ''){
		    	d = new Date(input_date);
		        day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
		        monthIndex = d.getMonth() < 10 ? `0${d.getMonth().toString()}`: d.getMonth().toString();
		        year = d.getFullYear().toString();
	    		date = day + '/' + (parseInt(monthIndex)+1) + '/' + year;
	    		return date;
		    }
		    day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
		    monthIndex = d.getMonth();
		    year = d.getFullYear().toString();
		    var monthNames = [
		        "January", "February", "March","April", "May", "June", "July",
		        "August", "September", "October","November", "December"
		      ];

		   	date = day + ' ' + monthNames[monthIndex] + ' ' + year;
		    let hours = d.getHours() < 10 ? `0${d.getHours().toString()}` : d.getHours().toString();
		    let minutes = d.getMinutes() < 10 ? `0${d.getMinutes().toString()}` : d.getMinutes().toString();
		    let time = `${hours}:${minutes}`;
		    DateTime.date = date;
		    DateTime.time = this.formatAMPM(hours,minutes);
		    return DateTime;
	}
    generatePDF = () =>{
        this.current_top = marginY + paraGap;
        this.page_right_start = marginX;
        this.currentPageNumber = 0;
        this.currentRight = marginX;
        this.faPDF = new jsPDF({
	                                orientation: 'p',
	                                unit: 'mm',
	                                format: 'a4',
	                                putOnlyUsedFonts: true
                                });
        this.drawHeader();
        this.drawOverview();
        this.footerSection();
        this.processFeeEarnerDetails();
      	this.faPDF.setFontSize(14)
        this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		//this.faPDF.text("End of Report",  80 , 260);
		if((this.current_top + 2 * line_height) > 260){
			this.faPDF.addPage();
	        this.footerSection();
	        this.current_top = marginY;
	        this.faPDF.text("End of Report",  80 , marginY +2 * line_height);
		}else{
			this.faPDF.text("End of Report",  80 , this.current_top + 2 * line_height);
		}
        this.processPageFooters();
        var blob = this.faPDF.output('blob')
        var binaryData = [];
        binaryData.push(blob);
        var pdfurl = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
        this.setState({pdfURL:pdfurl, faPDF : this.faPDF, ready: true});
    }
	componentDidMount() {
      this.generatePDF();  
	}
	componentDidUpdate(prevProps) {   
	   if (prevProps !== this.props) {
	    this.generatePDF();   
	   }
	 }
	render()
	{
		if(this.faPDF === null) {
		    return (<div>Loading...</div>);
		} else {
		    if (this.state.ready) {
		        return (<div>
		            <Iframe url={this.state.pdfURL} width="100%" height="900px" type="application/pdf" />
		        </div>);
		    } else {
		        return (<div>Loading...</div>);
		    }
		}
	}
}

export default FeeEarnarAnalysis;