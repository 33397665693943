import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import { FaTimes } from 'react-icons/fa';
import CustomDropzone from '../Common/CustomDropzone.js';
import Store from '../../Common/Store';
import moment from 'moment';

const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const FCKCommentDiv = styled.div`
    padding-left: 20px;
    padding-right: 8px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
const ScoreContainer = styled.div`
    background-color: #ecf0f1;    
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
    padding: 15px;
`
const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`
const ScoreBodyContainer = styled.div`
    padding: 15px;
`;

class AddFTaskModal extends React.Component {

	state = {followup_id: "", followup_sub: "", followup_note: "", assigned_to: 0, due_by: new Date(), attachments: []};

	componentDidMount() {
        // console.log("AddFTaskModal mounted");
		this.setState({
			followup_id: this.props.followup_data.id,
            followup_sub: this.props.followup_data.subject,
			followup_note: this.props.followup_data.note,
			assigned_to: this.props.followup_data.assigned_to,
			attachments: JSON.parse(JSON.stringify(this.props.followup_data.attachments)),
			due_by: new Date(this.props.followup_data.due_by)
		});
	}

    changeFollowupSubject = (event) => {
        event.preventDefault();
        this.setState({followup_sub: event.target.value});
    }

    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({followup_note: event.target.value});
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({assigned_to: parseInt(event.target.value)});
    }

    onDateChangeDueBy = (date) => {
        this.setState({due_by: date});
    }

    addAttachment = (files) => {
        let attachments = JSON.parse(JSON.stringify(this.state.attachments));
        files.forEach(file => {
            delete file.bin_file;
            attachments.push(file);
        })
        this.setState({attachments});
    }

    removeAttachment = (file_id) => (event) => {
        event.preventDefault();
        let new_attachments = [];
        for (let file of this.state.attachments) {
            if (file.uid !== file_id) new_attachments.push(file);
        }
        this.setState({attachments: new_attachments});
    }

    addSubTask = (event) => {
        event.preventDefault();
        const due_unix = moment(this.state.due_by).unix();
        const obj = {
            id: this.state.followup_id,
            subject: this.state.followup_sub,
            note: this.state.followup_note,
            assigned_to: this.state.assigned_to,
            attachments: this.state.attachments,
            due_by: due_unix
        }
        // console.log("obj")
        this.props.addFutureFollowup(obj);
        this.props.closeFollowupTaskModal();
    }

	render () {
        // console.log("props in AddFTaskModal:", this.props);
        // console.log("state in AddFTaskModal:", this.state);
		return (
			<ScoreContainer>
                <ScoreHeaderContainer>Add a follow-up task to this file check<HeaderButton onClick={this.props.closeFollowupTaskModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
                <ScoreBodyContainer>
                    <MRModalLabel>Subject</MRModalLabel>
                    <MRModalInput style={{width: "99%"}} type="text" name="followup_sub" onChange={this.handleChange} value={this.state.followup_sub} onChange={this.changeFollowupSubject}/>
                    <FCKCommentDiv style={{padding: "0px", marginTop: "10px"}}>
                        <MRModalLabel>Note</MRModalLabel>
                        <FCKTextarea rows="6" style={{width: "99%"}} value={this.state.followup_note} onChange={this.changeFollowupNote} />
                    </FCKCommentDiv>
                    <MRModalLabel>Assign To</MRModalLabel>
                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to}>
                        {
                            (() => {
                                if(this.props.ft_users.length !== 0) {
                                    let reviewees = [];
                                    for(let item of this.props.ft_users){
                                        reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                        
                                    }
                                    return reviewees;
                                }

                            })()
                        }
                    </MRModalSelect>
                    <MRModalLabel>Due By</MRModalLabel>
                    <CSLDateCover id="followdate">
                        <DatePicker
                                onChange={this.onDateChangeDueBy}
                                clearIcon={null}
                                calendarIcon={null}
                                locale={"en-GB"}
                                value={this.state.due_by}
                                />
                    </CSLDateCover>
                    <CustomDropzone attachments={this.state.attachments} addAttachment={this.addAttachment} removeAttachment={this.removeAttachment} followup_id={this.state.followup_id} />
                </ScoreBodyContainer>
                <div style={{height: '60px', width: '100%', paddingRight: "12px"}}>
                    <MRModalNextBtn onClick={this.addSubTask}>Add Task</MRModalNextBtn>
                    <MRModalCancelBtn onClick={this.props.closeFollowupTaskModal}>Close</MRModalCancelBtn>
                </div>
            </ScoreContainer>
		);
	}
}

export default AddFTaskModal;