import React from 'react';
import Store from '../../Common/Store';
import styled from 'styled-components';
import * as Style from '../Common/StyledComponents';
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
// import ChildTask from './ChildTask';
import FutureFollowups from './FutureFollowups';
import Dropzone from '../Common/Dropzone.js';
import {FiDownload} from 'react-icons/fi';
import { saveAs } from 'file-saver';
import APICall from '../../Common/APICall';
import moment from 'moment';
import Utils from '../../Common/Utils.js';

const OverviewHeader = styled.div`
	background-color: #ffffff;
	padding: 20px;
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const OverviewBody = styled.div`
	padding: 30px 20px;
	margin-top: 2px;
	background-color: #ffffff;
`;
const OverviewDescription = styled.div`
	text-align: justify;
	color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const OverviewInfoHeader = styled.div`
	padding: 15px;
	background-color: #1e3e62;
	border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ecf1f2;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 30%;
    padding-top: 7px;
    font-weight: 600;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;
const InfoDiv = styled.div`
	float: left;
	width: 25%;
	box-sizing: border-box;
	margin-top: 35px;
`;

class AnswerOverview extends React.Component
{
	state = {task_json: null, review_json: null, payload: null, task_id: null, cur_files_json: null, binFiles: [], curBinFiles: [], show_status: true,
		     show_attachments: true, show_assignment_comments: true,main_autofail_result:[]};

	componentDidMount()
	{
		// console.log("processPostData");
		let {task_json, review_json, payload, task_id, cur_files_json, curBinFiles} = this.state;
		task_json = this.props.task_json;
		review_json = this.props.review_json;
		payload = this.props.payload;
		console.log("payload", this.props);
		// console.log("task_json", this.props.task_json);
		task_id = this.props.task_id;
		if('bin_files' in task_json.object_data) {
			curBinFiles = task_json.object_data.bin_files;
			cur_files_json = curBinFiles.length === 0 ? null : true;
		}
		let childtask = this.props.payload.childtask;
        let contacts = this.props.payload.contacts;
        childtask.forEach((task) => {
            contacts.forEach((item) => {
                if(item.ID === task.cur_assigned_to) {
                    task.assigned_to_name = item.ContactName;
                }
            })
        })
        payload['childtask'] = childtask;
		// console.log("payload", this.props.payload);
		let task = {
			cur_lane : this.props.task_json.task_data.cur_lane,
			task_json : JSON.stringify(task_json)
		}
		 let main_autofail_result = []
	     main_autofail_result = Utils.getAutofailQuestions(task)
	      console.log('main_autofail_result_task::',task_json)
	      console.log('main_autofail_result::',main_autofail_result)
		this.setState({task_json, review_json, payload, task_id, cur_files_json,curBinFiles,main_autofail_result});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let {payload} = this.state;
			let childtask = this.props.payload.childtask;
			let contacts = this.props.payload.contacts;
			childtask.forEach((task) => {
				contacts.forEach((item) => {
					if(item.ID === task.cur_assigned_to) {
						task.assigned_to_name = item.ContactName;
					}
				})
			})
			payload['childtask'] = childtask;
			this.setState({payload, task_json: this.props.task_json});
			// const postData = {command: "get_task", task_id: this.props.task_id};
	        // let api = new APICall();
	        // api.command(postData, this.processPostData);
			// let {task_json, review_json, payload, task_id, cur_files_json, curBinFiles} = this.state;
			// task_json = this.props.task_json;
			// review_json = this.props.review_json;
			// payload = this.props.payload;
			// task_id = this.props.task_id;
			// if('bin_files' in task_json.object_data) {
			// 	curBinFiles = task_json.object_data.bin_files;
			// 	cur_files_json = curBinFiles.length === 0 ? null : true;
			// }
			// this.setState({task_json, review_json, payload, task_id, cur_files_json, curBinFiles});
		}
	}

	arrangeFollowupTasks = () => {
		const postData = {command: "get_task", task_id: this.props.task_id};
        let api = new APICall();
        api.command(postData, this.processPostData);
	}

	processPostData = (result) => {
		console.log("processPostData");
		let {task_json, review_json, payload, task_id, cur_files_json, curBinFiles} = this.state;
		task_json = this.props.task_json;
		review_json = this.props.review_json;
		payload = this.props.payload;
		// console.log("payload", this.props.payload);
		// console.log("task_json", this.props.task_json);
		task_id = this.props.task_id;
		if('bin_files' in task_json.object_data) {
			curBinFiles = task_json.object_data.bin_files;
			cur_files_json = curBinFiles.length === 0 ? null : true;
		}
		let childtask = this.props.payload.child_tasks;
        let contacts = this.props.payload.contacts;
        childtask.forEach((task) => {
            contacts.forEach((item) => {
                if(item.ID === task.cur_assigned_to) {
                    task.assigned_to_name = item.ContactName;
                }
            })
        })
        payload['childtask'] = childtask;
		// console.log("payload", this.props.payload);
		this.setState({task_json, review_json, payload, task_id, cur_files_json,curBinFiles});
	}

	filesLoaded = (files) => {
        let me = Store.getStoreData('loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
            }
        }
        // console.log('files in Modal', files);
        this.setState({binFiles: files});
	}

	handleDownload = (index) => {
        let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    togglePanel = (panel) => (event) => {
    	event.preventDefault();
    	let flag = this.state[panel];
    	flag = flag ? false : true;
    	this.setState({[panel]: flag});
    }


	render()
	{
		if (this.state.review_json === null) {
			return (
				<div>Loading...</div>
			);
		}
		console.log("task_json::", this.state);
		let assigned_date = null;
		let review_date = "--";
		for (let item of this.state.task_json.action_data) {
			if (item.cur_lane === "COMP_FCK_ASSIGNMENT") {
				assigned_date = item.actiondate;
			}
			if (item.cur_lane === "COMP_FCK_COMPLETE") {
				review_date = item.actiondate;
			}
		}
		assigned_date = Math.floor(assigned_date / 1000);
		assigned_date = moment.unix(assigned_date).format('DD/MM/YYYY');
		if (review_date !== "--") {
			review_date = Math.floor(review_date / 1000);
			review_date = moment.unix(review_date).format('DD/MM/YYYY');
		}
        let m_custom_fields = Store.getStoreData('module_config').custom_fields;
	    let autofail_list = [];
	    autofail_list = this.state.main_autofail_result;
	    let msg_text = "";
	    if (autofail_list.length > 0) {
	      let q_series = "";
	      for (let i = 0; i < autofail_list.length; i++) {
	        if (i < autofail_list.length - 1) {
	          q_series += autofail_list[i] + ", ";
	        } else {
	          q_series = q_series.replace(/,\s*$/, "");
	          q_series += " and " + autofail_list[i];
	        }
	      }
	      if (autofail_list.length === 1) {
	        msg_text = "Question  " + autofail_list[0] + " has triggered this review to automatically fail.";
	      } else {
	        msg_text = "Questions   " + q_series + " have triggered this review to automatically fail.";
	      }
	    }

		return (
			<div>
				<OverviewHeader>{this.state.review_json.general.name.replace(/'+/g,"'")}  - Review</OverviewHeader>
				<OverviewBody>
					<OverviewDescription>{this.state.review_json.general.description.replace(/'+/g,"'")}</OverviewDescription>
					<div style={{paddingTop: "20px"}}>
						<OverviewInfoHeader>
							<div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>{this.state.payload.status}</div>
							<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.togglePanel("show_status")}>
								{
									(() => {
										if (this.state.show_status) {
											return <IoIosArrowUp />
										} else {
											return <IoIosArrowDown />
										}
									})()
								}
							</div>
							<div style={{clear: "both"}}></div>
						</OverviewInfoHeader>
						{
							(() => {
								let is_sale_date_active = true;
								let sale_date_label = "Sale Date";
								if ("custom_terminology_fields" in this.state.task_json.object_data.headerinfo) {
									if (this.state.task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
										const sale_obj = this.state.task_json.object_data.headerinfo.custom_terminology_fields.find(item => item.name === "sale_date");
										if (sale_obj !== undefined) {
                                            const m_s_obj = m_custom_fields.custom_terminology_fields.find(item => item.name === sale_obj.name);
											if (sale_obj.nickname !== "") sale_date_label = m_s_obj !== undefined ? m_s_obj.nickname : sale_obj.nickname;
											is_sale_date_active = "is_active" in sale_obj ? sale_obj.is_active : true;
										}
									}
								}
								if (this.state.show_status) {
									return (
										<OverviewInfoBody>
											<div>
												<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Reviewer</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.reviewer.ContactName}</span></InfoDiv>
												<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Reviewee</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.reviewee.ContactName}</span></InfoDiv>
												<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Status</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.status}</span></InfoDiv>
												<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Customer Name</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.company_name.replace(/'+/g,"'")}</span></InfoDiv>
												<div style={{clear: "both"}}></div>
											</div>
											<div>
												{
												  (() => {
												  	if(is_sale_date_active){
												  		return(<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{sale_date_label}</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.sale_date}</span></InfoDiv>)
												  	}
												  })()
												}

												{/* <div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Assigned Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.assigned_date}</span></div> */}
												<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Assigned Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{assigned_date}</span></InfoDiv>
												<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Due Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.due_date}</span></InfoDiv>
												{/* <div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Review Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>--</span></div> */}
												<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Review Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{review_date}</span></InfoDiv>
												<div style={{clear: "both"}}></div>
												{
													(() => {
														let is_reviewee_designation_active = true;
														let reviewee_designation_label = "Reviewee Designation";
														if ("custom_terminology_fields" in this.state.task_json.object_data.headerinfo) {
															if (this.state.task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
																const designation_obj = this.state.task_json.object_data.headerinfo.custom_terminology_fields.find(item => item.name === "reviewee_designation");
																console.log("designation_obj::",designation_obj)
																console.log("designation_obj::m_custom_fields",m_custom_fields)
																if (designation_obj !== undefined) {
																	is_reviewee_designation_active = "is_active" in designation_obj ? designation_obj.is_active : true;
                                                                    const m_d_obj = m_custom_fields.custom_terminology_fields.find(item => item.name === designation_obj.name);
																	if (designation_obj.nickname !== "") reviewee_designation_label = m_d_obj !== undefined ? m_d_obj.nickname : designation_obj.nickname;
																}
															}
														}
														let additional_fields = {};
														if ("additional_fields" in this.state.task_json.object_data.headerinfo) {
															if (this.state.task_json.object_data.headerinfo.additional_fields.length !== 0) {
																for (let item of this.state.task_json.object_data.headerinfo.additional_fields) {
																	if (item.type === "Dropdown" && item.is_active) {
																		if (item.value !== "" || item.other_value !== "") {
																			const value = item.value === "other" ? item.other_value : item.value;
                                                                            let m_field = m_custom_fields.additional_fields.find(rec => rec.name === item.name);
                                                                            additional_fields[item.name] = {label: m_field !== undefined ? m_field.nickname : item.nickname, value: value};
																		}
																	} else {
                                                                        let m_field = m_custom_fields.additional_fields.find(rec => rec.name === item.name);
                                                                        if (item.value !== "" && item.is_active) additional_fields[item.name] = {label: m_field !== undefined ? m_field.nickname : item.nickname, value: item.value};
																	}
																}
															}
														}
														let addls = [];
														if(is_reviewee_designation_active){
															addls.push(<InfoDiv key={reviewee_designation_label}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{reviewee_designation_label}</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.task_json.object_data.headerinfo.reviewee_designation}</span></InfoDiv>);
														}

														if (Object.keys(additional_fields).length !== 0) {
															for (let key in additional_fields) {
																addls.push(<InfoDiv key={key}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{additional_fields[key].label}</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{additional_fields[key].value}</span></InfoDiv>)
															}
														}
														return (<div>{addls}<div style={{clear: "both"}}></div></div>)
													})()
												}
												{
													"related_individual" in this.state.payload === true &&
													<>
														<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Related Individual</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.related_individual.ContactName}</span></InfoDiv>
														<div style={{clear: "both"}}></div>
													</>
												}
											</div>
						                    <InfoDiv style={{ width: "100%"}}>
						                      <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>{msg_text}</span>
						                    </InfoDiv>
						                    <div style={{ clear: "both" }}></div>
										</OverviewInfoBody>
									);
								} else {
									return (<div></div>);
								}
							})()
						}
					</div>
					<div style={{paddingTop: "20px"}}>
						<OverviewInfoHeader>
							<div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Attachment</div>
							<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.togglePanel("show_attachments")}>
								{
									(() => {
										if (this.state.show_attachments) {
											return <IoIosArrowUp />
										} else {
											return <IoIosArrowDown />
										}
									})()
								}
							</div>
							<div style={{clear: "both"}}></div>
						</OverviewInfoHeader>
						{
							(() => {
								if (this.state.show_attachments) {
									return (
										<OverviewInfoBody>
											<ModalNameLabelDiv>File Assignment - Attachment</ModalNameLabelDiv>
											<div style={{width: "100%", boxSizing: "border-box"}}>
									{
									    (() => {
									        if(this.state.cur_files_json !== null) {
									            return(
									                <div>
									                {
									                    this.state.curBinFiles.map((f, index) => {
									                        console.log("f", f);
                                                            const file_parts = f.name.split(".");
                                                            let ext = "PDF";
                                                            switch(file_parts[file_parts.length - 1]) {
                                                              case 'jpeg':
                                                              case 'jpg' : ext = 'JPG';break;
                                                              case 'png' : ext = 'PNG'; break;
                                                              case 'docx' : ext = 'DOCX'; break;
                                                              case 'doc' : ext = 'DOC'; break;
                                                              case 'msg' : ext = 'MSG'; break;
                                                              case 'txt' : ext = 'TXT'; break;
                                                              case 'ppt' : ext = 'PPT'; break;
                                                              case 'pptx' : ext = 'PPTX'; break;
                                                              case 'xls' : ext = 'XLS'; break;
                                                              case 'xlsx' : ext = 'XLS'; break;
                                                              default: ext = 'PDF'; break;
                                                            }
                                                            console.log('ext', ext);
                                                            let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
                                                            file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
                                                            const file_icon = `/dropzoneImages/${ext}.png`;
                                                            return (
                                                              <div style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
                                                                <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                                                                    <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                                                      <img src={file_icon} height="100px" width="auto" />
                                                                      {/*<Style.FileTypeContainer>{ext}</Style.FileTypeContainer>*/}
                                                                    </div>
                                                                    <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                                                      <div><strong title={f.name} style={{color: '#000000'}}>{f.name.length > 22 ? f.name.substring(0, 22) : f.name}</strong><br /><br /></div>
                                                                      <div style={{cursor: "pointer"}} onClick={() => this.handleDownload(index)}><FiDownload /> Download</div>
                                                                    </div>
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>
                                                              </div>
                                                            );

									                    })
									                }
                                                    <div style={{clear: "both"}}></div>
									                </div>
									            )
									        } else {
									            return <div>No existing attachments.</div>
									        }
									    })()

									}
									</div>
										</OverviewInfoBody>
									);
								} else {
									return (<div></div>);
								}
							})()
						}
					</div>
					<div style={{paddingTop: "20px"}}>
						<OverviewInfoHeader>
							<div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Comments</div>
							<div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.togglePanel("show_assignment_comments")}>
								{
									(() => {
										if (this.state.show_assignment_comments) {
											return <IoIosArrowUp />
										} else {
											return <IoIosArrowDown />
										}
									})()
								}
							</div>
							<div style={{clear: "both"}}></div>
						</OverviewInfoHeader>
						{
							(() => {
								if (this.state.show_assignment_comments) {
									return (
										<OverviewInfoBody>
											{
												(() => {
													if ("assignment_comments" in this.state.task_json.object_data.headerinfo) {
														let assignment_comments = this.state.task_json.object_data.headerinfo.assignment_comments;
                                                        if(Utils.isBase64(assignment_comments)){
                                                            assignment_comments = window.decodeURIComponent(atob(assignment_comments));
                                                        }
														return (<div dangerouslySetInnerHTML={{__html: assignment_comments}}></div>);
													}
												})()
											}
										</OverviewInfoBody>
									);
								} else {
									return (<div></div>);
								}
							})()
						}
					</div>
					{/*{
						(() => {
							if (this.props.review_json.scoring.scoring_enabled) {
								return (
									<div style={{paddingTop: "20px"}}>
										<ChildTask
											childtask={this.state.payload.childtask}
											tableRows="10"
											contacts={this.state.payload.contacts}
											arrangeFollowupTasks={this.arrangeFollowupTasks}
											parent_task_id={this.state.task_id}
											parent_company_id={this.state.payload.company_id}
											reloadChildTasks={this.props.reloadChildTasks}
											reviewer={this.state.payload.reviewer}
											readonly={this.props.readonly}
											info={"reviewee_acknowledgement" in this.state.task_json.object_data.headerinfo ? this.state.task_json.object_data.headerinfo.reviewee_acknowledgement : null}
											future_followups={this.state.task_json.object_data.future_followups}
											addFutureFollowup={this.props.addFutureFollowup}
										/>
									</div>
								);
							}
						})()
					}*/}
					{
						this.props.review_json.scoring.scoring_enabled === true
						&&
						<div style={{paddingTop: "20px"}}>
							<FutureFollowups
								childtask={this.state.payload.childtask}
								tableRows="10"
								contacts={this.state.payload.contacts}
								arrangeFollowupTasks={this.arrangeFollowupTasks}
								parent_task_id={this.state.task_id}
								parent_company_id={this.state.payload.company_id}
								reloadChildTasks={this.props.reloadChildTasks}
								reviewer={this.state.payload.reviewer}
								readonly={this.props.readonly}
								info={"reviewee_acknowledgement" in this.state.task_json.object_data.headerinfo ? this.state.task_json.object_data.headerinfo.reviewee_acknowledgement : null}
								future_followups={this.state.task_json.object_data.future_followups}
								addFutureFollowup={this.props.addFutureFollowup}
								removeFutureFollowup={this.props.removeFutureFollowup}
							/>
						</div>
					}
				</OverviewBody>
			</div>
		);
	}
}

export default AnswerOverview;
