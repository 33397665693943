import React from "react";
import styled from "styled-components";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import ChildTask from "./ChildTask";
import FutureFollowups from "./FutureFollowups";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import moment from "moment";
import Store from "../../Common/Store.js";
import Utils from '../../Common/Utils.js';

const OverviewHeader = styled.div`
  background-color: #ffffff;
  padding: 20px;
  font-weight: 600;
`;
const OverviewBody = styled.div`
  padding: 30px 20px;
  margin-top: 2px;
  background-color: #ffffff;
`;
const OverviewDescription = styled.div`
  text-align: justify;
`;
const OverviewInfoHeader = styled.div`
  padding: 15px;
  background-color: #1e3e62;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
const OverviewInfoBody = styled.div`
  padding: 15px;
  background-color: #ecf1f2;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
const ModalNameLabelDiv = styled.div`
  height: auto;
  width: 30%;
  padding-top: 7px;
  font-weight: 600;
  color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const InfoDiv = styled.div`
  float: left;
  width: 25%;
  box-sizing: border-box;
  margin-top: 35px;
`;

class AnswerOverviewQa extends React.Component {
  state = { task_json: null, review_json: null, payload: null, task_id: null, cur_files_json: null, curBinFiles: [], show_attachments: true ,qc_autofail_result:[]};

  componentDidMount() {
    let { task_json, review_json, payload, task_id, cur_files_json, curBinFiles } = this.state;
    task_json = this.props.task_json;
    review_json = this.props.review_json;
    payload = this.props.payload;
    task_id = this.props.task_id;

    if ("bin_files" in task_json.object_data) {
      curBinFiles = task_json.object_data.bin_files;
      cur_files_json = curBinFiles.length === 0 ? null : true;
    }

    let qc_autofail_result = 'qc_autofail_result' in this.props ? this.props.qc_autofail_result : []
    this.setState({ task_json, review_json, payload, task_id, cur_files_json, curBinFiles, qc_autofail_result });
    console.log("Payload:", payload);
    console.log("task_json:", task_json);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let { task_json, review_json, payload, task_id } = this.state;
      task_json = this.props.task_json;
      review_json = this.props.review_json;
      payload = this.props.payload;
      task_id = this.props.task_id;
      this.setState({ task_json, review_json, payload, task_id });
    }
  }

  togglePanel = (panel) => (event) => {
    event.preventDefault();
    let flag = this.state[panel];
    flag = flag ? false : true;
    this.setState({ [panel]: flag });
  };

  handleDownload = (index) => {
    let cur_file = this.state.curBinFiles[index];
    let dnld = cur_file.bin_file.replace(/ /g, "+");
    // console.log("binary file before download:", dnld);
    var binaryString = window.atob(dnld);
    // console.log('binaryString', binaryString);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, cur_file.name);
  };

  render() {
    if (this.state.review_json === null) {
      return <div>Loading...</div>;
    }

    let review_date = null;
    for (let item of this.state.task_json.action_data) {
      if (item.cur_lane === "COMP_FCK_ASSIGNMENT") {
        review_date = item.actiondate;
      }
    }
    review_date = Math.floor(review_date / 1000);
    review_date = moment.unix(review_date).format("DD/MM/YYYY");
    const m_custom_fields = Store.getStoreData("module_config").custom_fields;
    let sale_date_label = "Sale Date";
    let is_sale_date_active = true;
    if ("custom_terminology_fields" in this.state.task_json.object_data.headerinfo) {
      if (this.state.task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
        const sale_obj = this.state.task_json.object_data.headerinfo.custom_terminology_fields.find((item) => item.name === "sale_date");
        if (sale_obj !== undefined) {
          const m_s_obj = m_custom_fields.custom_terminology_fields.find((item) => item.name === sale_obj.name);
          if (sale_obj.nickname !== "") sale_date_label = m_s_obj !== undefined ? m_s_obj.nickname : sale_obj.nickname;
          is_sale_date_active = "is_active" in sale_obj ? sale_obj.is_active : true;
        }
      }
    }
    let reviewee_designation_label = "Reviewee Designation";
    let is_reviewee_designation_active = true;
    if ("custom_terminology_fields" in this.state.task_json.object_data.headerinfo) {
      if (this.state.task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
        const designation_obj = this.state.task_json.object_data.headerinfo.custom_terminology_fields.find((item) => item.name === "reviewee_designation");
        if (designation_obj !== undefined) {
          const m_d_obj = m_custom_fields.custom_terminology_fields.find((item) => item.name === designation_obj.name);
          if (designation_obj.nickname !== "") reviewee_designation_label = m_d_obj !== undefined ? m_d_obj.nickname : designation_obj.nickname;
          is_reviewee_designation_active = "is_active" in designation_obj ? designation_obj.is_active : true;
        }
      }
    }
    let additional_fields = {};
    if ("additional_fields" in this.state.task_json.object_data.headerinfo) {
      if (this.state.task_json.object_data.headerinfo.additional_fields.length !== 0) {
        for (let item of this.state.task_json.object_data.headerinfo.additional_fields) {
          if (item.type === "Dropdown") {
            if (item.value !== "" || item.other_value !== "") {
              const value = item.value === "other" ? item.other_value : item.value;
              let m_field = m_custom_fields.additional_fields.find((rec) => rec.name === item.name);
              additional_fields[item.name] = { label: m_field !== undefined ? m_field.nickname : item.nickname, value: value };
            }
          } else {
            let m_field = m_custom_fields.additional_fields.find((rec) => rec.name === item.name);
            if (item.value !== "") additional_fields[item.name] = { label: m_field !== undefined ? m_field.nickname : item.nickname, value: item.value };
          }
        }
      }
    }

    // let autofail_list = [];
    // autofail_list = this.state.qc_autofail_result;
    // let msg_text = "";
    // if (autofail_list.length > 0) {
    //   let q_series = "";
    //   for (let i = 0; i < autofail_list.length; i++) {
    //     if (i < autofail_list.length - 1) {
    //       q_series += autofail_list[i] + ", ";
    //     } else {
    //       q_series = q_series.replace(/,\s*$/, "");
    //       q_series += " and " + autofail_list[i];
    //     }
    //   }
    //   if (autofail_list.length === 1) {
    //     msg_text = "Question  " + autofail_list[0] + " has triggered this review to automatically fail.";
    //   } else {
    //     msg_text = "Questions   " + q_series + " have triggered this review to automatically fail.";
    //   }
    // }
    return (
      <div>
        <OverviewHeader>{this.state.review_json.general.name} - Review</OverviewHeader>
        <OverviewBody>
          <OverviewDescription>{this.state.review_json.general.description}</OverviewDescription>
          <div style={{ paddingTop: "40px" }}>
            <OverviewInfoHeader>
              <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>
                {(() => {
                  if (this.state.review_json.scoring.scoring_enabled) {
                    return this.state.payload.status;
                  } else {
                    return "Result: " + this.state.task_json.object_data.non_score_assessment.result;
                  }
                })()}
              </div>
              <div style={{ float: "right", color: "#ffffff", fontSize: "17px" }}>
                <IoIosArrowUp />
              </div>
              <div style={{ clear: "both" }}></div>
            </OverviewInfoHeader>
            <OverviewInfoBody>
              <div>
                <InfoDiv>
                  <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>Reviewer</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.reviewer.ContactName}</span>
                </InfoDiv>
                <InfoDiv>
                  <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>Reviewee</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.reviewee.ContactName}</span>
                </InfoDiv>

                <InfoDiv>
                  <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>QA Reviewer</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.qa_reviewer.ContactName}</span>
                </InfoDiv>
                <InfoDiv>
                  <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>QA Assigned Date</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.qa_assigned_date}</span>
                </InfoDiv>

                <InfoDiv>
                  <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>QA Due Date</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.due_date}</span>
                </InfoDiv>
                <InfoDiv>
                  <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>Company</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.company_name}</span>
                </InfoDiv>
                {
                  (() => {
                    if(is_sale_date_active){
                      return(
                        <InfoDiv>
                          <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>{sale_date_label}</span>&nbsp;&nbsp;&nbsp;
                          <span style={{ color: `${process.env.DEFAULT_TEXT_COLOR}` }}>{this.state.payload.sale_date}</span>
                        </InfoDiv>
                      )
                    }
                  })()
                }
                {(() => {
                  if (this.state.review_json.scoring.scoring_enabled) {
                    return (
                      <InfoDiv>
                        <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>Score</span>&nbsp;&nbsp;<span style={{ color: "#399390" }}>{this.state.payload.score}</span>
                      </InfoDiv>
                    );
                  } else {
                    return (
                      <InfoDiv>
                        <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>Label</span>&nbsp;&nbsp;
                        <span style={{ color: "#399390" }}>{this.state.task_json.object_data.non_score_assessment.result}</span>
                      </InfoDiv>
                    );
                  }
                })()}
                {(() => {
                  if (this.props.readonly === true) {
                    return (
                      <InfoDiv>
                        <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>QA Reviewer</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.qa_reviewer.ContactName}</span>
                      </InfoDiv>
                    );
                  }
                })()}
                <div style={{ clear: "both" }}></div>
                {
                  "related_individual" in this.state.payload === true &&
                  <>
                    <InfoDiv>
                      <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>Related Individual</span>&nbsp;&nbsp;<span style={{ color: "#464646" }}>{this.state.payload.related_individual.ContactName}</span>
                    </InfoDiv>
                    <div style={{ clear: "both" }}></div>
                  </>
                }
              </div>
              <div style={{ marginTop: "35px" }}>
                <div style={{ display: "inline-block" }}>
                  <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>Comments</span>&nbsp;&nbsp;&nbsp;
                  <span style={{ color: "#464646" }}>{this.state.review_json.scoring.scoring_enabled ? this.state.payload.comment : this.state.task_json.object_data.non_score_assessment.reviewer_comments}</span>
                </div>
              </div>
              <div>
                {(() => {
                  let addls = [];
                  if(is_reviewee_designation_active){
                    addls.push(
                    <InfoDiv key={reviewee_designation_label}>
                      <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>{reviewee_designation_label}</span>&nbsp;&nbsp;&nbsp;
                      <span style={{ color: `${process.env.DEFAULT_TEXT_COLOR}` }}>{this.state.task_json.object_data.headerinfo.reviewee_designation}</span>
                    </InfoDiv>
                  );
                  }
                  if (Object.keys(additional_fields).length !== 0) {
                    for (let key in additional_fields) {
                      addls.push(
                        <InfoDiv key={key}>
                          <span style={{ fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}` }}>{additional_fields[key].label}</span>&nbsp;&nbsp;&nbsp;
                          <span style={{ color: `${process.env.DEFAULT_TEXT_COLOR}` }}>{additional_fields[key].value}</span>
                        </InfoDiv>
                      );
                    }
                  }
                  return (
                    <div>
                      {addls}
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                })()}
                <div style={{ clear: "both" }}></div>
              </div>
            </OverviewInfoBody>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <OverviewInfoHeader>
              <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>Attachment</div>
              <div style={{ float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer" }} onClick={this.togglePanel("show_attachments")}>
                {(() => {
                  if (this.state.show_attachments) {
                    return <IoIosArrowUp />;
                  } else {
                    return <IoIosArrowDown />;
                  }
                })()}
              </div>
              <div style={{ clear: "both" }}></div>
            </OverviewInfoHeader>
            {(() => {
              if (this.state.show_attachments) {
                return (
                  <OverviewInfoBody>
                    <ModalNameLabelDiv>File Assignment - Attachment</ModalNameLabelDiv>
                    <div style={{ width: "100%", boxSizing: "border-box" }}>
                      {(() => {
                        if (this.state.cur_files_json !== null) {
                          return (
                            <div>
                              {this.state.curBinFiles.map((f, index) => {
                                console.log("f", f);
                                const file_parts = f.name.split(".");
                                let ext = "PDF";
                                switch (file_parts[file_parts.length - 1]) {
                                  case "jpeg":
                                  case "jpg":
                                    ext = "JPG";
                                    break;
                                  case "png":
                                    ext = "PNG";
                                    break;
                                  case "docx":
                                    ext = "DOCX";
                                    break;
                                  case "doc":
                                    ext = "DOC";
                                    break;
                                  case "msg":
                                    ext = "MSG";
                                    break;
                                  case "txt":
                                    ext = "TXT";
                                    break;
                                  case "ppt":
                                    ext = "PPT";
                                    break;
                                  case "pptx":
                                    ext = "PPTX";
                                    break;
                                  case "xls":
                                    ext = "XLS";
                                    break;
                                  case "xlsx":
                                    ext = "XLS";
                                    break;
                                  default:
                                    ext = "PDF";
                                    break;
                                }
                                console.log("ext", ext);
                                let file_icon_var = ext === "DOCX" ? "DOC" : ext;
                                file_icon_var = ext === "XLSX" ? "XLS" : ext;
                                const file_icon = `/dropzoneImages/${ext}.png`;
                                return (
                                  <div style={{ width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index % 2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px" }}>
                                    <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                                      <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                                        <img src={file_icon} height="100px" width="auto" />
                                        {/*<Style.FileTypeContainer>{ext}</Style.FileTypeContainer>*/}
                                      </div>
                                      <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                                        <div>
                                          <strong title={f.name} style={{ color: "#000000" }}>
                                            {f.name.length > 22 ? f.name.substring(0, 22) : f.name}
                                          </strong>
                                          <br />
                                          <br />
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(index)}>
                                          <FiDownload /> Download
                                        </div>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                    </div>
                                  </div>
                                );
                              })}
                              <div style={{ clear: "both" }}></div>
                            </div>
                          );
                        } else {
                          return <div>No existing attachments.</div>;
                        }
                      })()}
                    </div>
                  </OverviewInfoBody>
                );
              } else {
                return <div></div>;
              }
            })()}
          </div>
          <div style={{ paddingTop: "40px" }}>
            {/*<ChildTask readonly={this.props.readonly} childtask={this.state.payload.child_tasks} contacts={this.state.payload.contacts} parent_task_id={this.state.task_id} reloadChildTasks={this.props.reloadChildTasks} parent_company_id={this.state.payload.company_id} reviewer={this.state.payload.reviewer}/>*/}
            <FutureFollowups
              childtask={this.state.payload.child_tasks}
              tableRows="10"
              contacts={this.state.payload.contacts}
              arrangeFollowupTasks={this.arrangeFollowupTasks}
              parent_task_id={this.state.task_id}
              parent_company_id={this.state.payload.company_id}
              reloadChildTasks={this.props.reloadChildTasks}
              reviewer={this.state.payload.reviewer}
              readonly={this.props.readonly}
              info={"reviewee_acknowledgement" in this.state.task_json.object_data.headerinfo ? this.state.task_json.object_data.headerinfo.reviewee_acknowledgement : null}
              future_followups={this.state.task_json.object_data.future_followups}
              addFutureFollowup={this.props.addFutureFollowup}
              removeFutureFollowup={this.props.removeFutureFollowup}
            />
          </div>
        </OverviewBody>
      </div>
    );
  }
}

export default AnswerOverviewQa;
