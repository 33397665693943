import React from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import { IoIosCheckmark } from 'react-icons/io';

const RAGContainer = styled.div`
	width: 100%;
	display: block;
	padding: 10px 0px;
`;
const Floatingdiv = styled.div`
	float: left;
`;
const RAGHeading = styled.div`
	color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const SelectedRAGContainer = styled.div`
	width: 30px;
	height: 30px;
	border: 1px solid #a7a7a7;
	border-radius: 4px;
	padding: 1px;
`;
const SelectedRAGColor = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 4px;
`;
const VerticalRule = styled.div`
	width: 2px;
	height: 56px;
	background-color: #a7a7a7;
`;
const ColorPaletteContainer = styled.div`
	width: 100%;
	margin-top: 10px;
`;
const ColorDiv = styled.div`
	width: 30px;
	height: 30px;
	margin-left: 5px;
	float: left;
	border: 1px solid #a7a7a7;
	border-radius: 4px;
	cursor: pointer;
`;

class MGRScoreRAG extends React.Component
{
	state = {
		resultItem: null,
		resultItemList: null,
		colorpalette: ["#ff0000", "#0ec50e", "#37ada7", "#1e3e62", "#f7af00", "#f2f2f3", "#929292", "#86B039"]
	}

	componentDidMount() {
		console.log("resultItem:", this.props.resultItem);
		this.setState({ resultItem : this.props.resultItem })
	}

	componentDidUpdate(prevProps) {
		// console.log("updatedresult", this.props.resultItem);
		if (this.props !== prevProps) {
			this.setState({ resultItem : this.props.resultItem });
		}
	}

	handleColorChange = (color) => (event) => {
		event.preventDefault();
		let {resultItem} = this.state;
		resultItem.color = color;
		this.props.modifyResultItem(resultItem);
	}

	render() {
		if(this.state.resultItem === null) return <div>Loading...</div>

		return (
				<RAGContainer>
					<RAGHeading><span style={{fontWeight: "600"}}>RAG -</span>Select a colour to represent this score</RAGHeading>
					<Floatingdiv style={{width: "10%"}}>
						<div style={{color: `${process.env.DEFAULT_TEXT_COLOR}`, marginTop: "20px"}}>Selected</div>
						<SelectedRAGContainer style={{marginTop: "10px"}}>
							<SelectedRAGColor style={{backgroundColor: this.state.resultItem.color}}>
								<div style={{float: "right"}}><IoIosCheckmark /></div>
								<div style={{clear: "both"}}></div>
							</SelectedRAGColor>
						</SelectedRAGContainer>
					</Floatingdiv>
					<Floatingdiv style={{width: "3%"}}>
						<VerticalRule style={{marginTop: "24px"}} />
					</Floatingdiv>
					<Floatingdiv style={{width: "85%"}}>
						<div style={{color: `${process.env.DEFAULT_TEXT_COLOR}`, marginTop: "20px"}}>Colour Palette</div>
						<ColorPaletteContainer>
							{
								this.state.colorpalette.map((item, index) => {
									if (index === 0) {
										return (<ColorDiv key={index} style={{marginLeft: "0px", backgroundColor: item}} onClick={this.handleColorChange(item)}></ColorDiv>)
									} else {
										return (<ColorDiv key={index} style={{marginLeft: "5px", backgroundColor: item}} onClick={this.handleColorChange(item)}></ColorDiv>)
									}
								})
							}
							{/*<ColorDiv style={{marginLeft: "5px", textAlign: "center"}}>
								<div style={{marginTop: "6px"}}><FaPlus /></div>
							</ColorDiv>*/}
							<div style={{clear: "both"}}></div>
						</ColorPaletteContainer>
					</Floatingdiv>
					<div style={{clear: "both"}}></div>
				</RAGContainer>
			)
	}
}

export default MGRScoreRAG;