import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import {FaPlus} from 'react-icons/fa';
// import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';

const SubmitButton = styled.button`
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;

class FCMRTable extends React.Component
{
    state = {view_count: 0};

    constructor(props) {
        super(props);
    }

    componentDidMount()
    {
        // console.log("Model reviews: =======>", Store.getStoreData('model_reviews'));
    }

    refreshState = () => {
        this.setState({view_count: this.state.view_count + 1});
    }

    showMGRAddFileCheckModal = (rid) => (event) => {
        event.preventDefault();
        // console.log('rid', rid);
        Store.updateStore('rid', rid);
        Store.updateStore('MGRAddFileCheckModal', `${document.body.scrollHeight.toString()}px`);
    }

    pad(str, max) {
      str = str.toString();
      return str.length < max ? this.pad("0" + str, max) : str;
    }

    processReviewssData = () => {
        // console.log('data', this.props.data);
        let my_reviews = Store.getStoreData('my_reviews');
        console.log("my_reviews", my_reviews);
        // let filtered_my_reviews = [];
        // if (Store.getStoreData("module_config")["general"].show_parent_company === false) {
        //     my_reviews.forEach((item) => {
        //         if (item.company_id !== Store.getStoreData("parent_company_id")) {
        //             filtered_my_reviews.push(item);
        //         }
        //     })
        // } else {
        //     filtered_my_reviews = my_reviews;
        // }
        // my_reviews = filtered_my_reviews;
        // console.log('my_reviews', my_reviews);
        let contacts = Store.getStoreData('reviewee_info');
        // let cardata = Store.getStoreData('cardata').faq_tasks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 40, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Review Type', accessor: 'nick_name', width: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Company', accessor: 'company_name', width: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Description', accessor: 'description', minWidth: 200, headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={this.showMGRAddFileCheckModal(row.original.id)}>Modify</MRButton>
                           </div>
                        ), width: 100, headerStyle: {textAlign: 'left'}}];
        let gc_assoc = Store.getStoreData('gc_assoc')
        for(let d of my_reviews) {
            i++;
            //console.log("MYCOMPANY",gc_assoc[(parseInt(d.company_id)%100000).toString()])
            let inactive = "";
            if(gc_assoc[(parseInt(d.company_id)%100000).toString()].is_active === 0){
                inactive = " (Inactive)";
            }
            let elem = {
                index : i,
                id: d.id,
                nick_name : d.nick_name,
                company_name: parseInt(d.company_id) < 100000 ? gc_assoc[(d.company_id).toString()].company_name : gc_assoc[(parseInt(d.company_id)%100000).toString()].company_name + inactive,
                description : d.description
            };
            ret.data.push(elem);
        }
        ret.export_columns = {nick_name: 'Review Type', company_name: 'Company', description: 'Description'};
        return ret;
    }


    render()
    {
        let role = Store.getStoreData('role');
        let env = Store.getStoreData('env');
        // console.log("TABLEDATA:", this.props.tabledata);
        return (
            <div>
                <SubmitButton id="New_Review_Type" onClick={this.showMGRAddFileCheckModal(0)}>New Review Type</SubmitButton>
                <CSLTable export={true} export_file_name={"Review_Types"} add={false} processData={this.processReviewssData} tableRows="10" headerText={'File Review Types'} refreshCallback={this.refreshState} />
            </div>
        );
    }
}
export default FCMRTable;