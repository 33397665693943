import React from 'react';
import styled from 'styled-components';
import CslRoundDropDown from './CslRoundDropDown';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Utils from '../../Common/Utils.js';

const AnswerSectionHeader = styled.div`
	background-color: #ffffff;
	padding: 20px;
	font-size: 15px;
	font-weight: 700;
	color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const SingleQuestionContainer = styled.div`
	background-color: #ffffff;
	margin-top: 2px;
	padding: 20px;
	width: 100%;
	box-sizing: border-box;
`;
const SerialNo = styled.div`
	font-weight: 700;
	font-size: 11px;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	float: left;
	width: 5%
	box-sizing: border-box;
`;
const QuestionBody = styled.div`
	float: left;
	width: 95%;
	box-sizing: border-box;
	padding-left: 45px;
`;
const QuestionTxt = styled.div`
	font-size: 12px;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	text-align: justify;
	width: 100%;
	box-sizing: border-box;
	line-height: 18px;

`;
const AnswerTextArea = styled.textarea`
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;
const AnswerTextbox = styled.input`
    height: 25px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;
const CommentsLabel = styled.div`
	font-size: 12px;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	font-weight: 600;
`;
const Dropdown = styled.select`
    height: 35px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    background-color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
`;
const AnswerLabel = styled.div`
	font-size: 12px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    padding-top: 10px;
`;
const GuidanceLabel = styled.div`
	font-size: 12px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    line-height: 40px;
`;
const ScoreAndNaContainer = styled.div`
	width: 100%;
	padding-top: 10px;
	box-sizing: border-box;
`;
const ScoreContainer = styled.div`
	float: left;
	width: 100%;
	box-sizing: border-box;
`;
const NaContainer = styled.div`
	float: left;
	// width: 50%;
	text-align: right;
	box-sizing: border-box;
`;
const GuidanceLine = styled.div`
	width: 100%;
	height: 0px;
	border-bottom: 1px solid #c4c4c4;
`;

class AnswerSection extends React.Component
{
	state = {active_section_id: null, section_payload: null, sections: null, score_result: null, scoring: null, is_section_na: false};

	componentDidMount()
	{
		let {active_section_id, section_payload, sections, score_result, scoring} = this.state;
		active_section_id = this.props.active_section_id;
		section_payload = this.props.section_payload;
		score_result = this.props.score_result;
		sections = this.props.sections;
		scoring = this.props.scoring;
		console.log("active_section_id:", active_section_id);
		console.log("section_payload:", section_payload);
		console.log("is_section_na", this.state.is_section_na);
		this.setState({active_section_id, section_payload, sections, score_result, scoring});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			console.log("active_section_id:", this.props.active_section_id);
			console.log("section_payload:", this.props.section_payload);
			this.setState({
				active_section_id: this.props.active_section_id,
				section_payload: this.props.section_payload,
				sections: this.props.sections,
				score_result: this.props.score_result,
				scoring: this.props.scoring
			});
		}
	}

	calculateTotalScore = (sections) => {
		let totalscore = 0;
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				const q_type = question.type.find(t => t.selected === true);
				let qscore = 0;
                if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
                    if (question.auto_scoring.enabled === true) {
                        if (q_type.value === "Boolean") {
                            qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
                            console.log("qscore Boolean:", qscore);
                        }
                        if (q_type.value === "Dropdown") {
                            qscore = Math.max.apply(null, question.preset_answers.map(pa => parseInt(pa.weight)));
                            console.log("qscore Dropdown:", qscore);
                        }
                    } else {
                        const qscore_obj = question.weight.find(item => item.selected === true);
                        qscore = parseInt(qscore_obj.value);
                    }
                } else {
                    const qscore_obj = question.weight.find(item => item.selected === true);
                    qscore = parseInt(qscore_obj.value);
                }

				// const q_score = question.weight.find(w => w.selected);
				totalscore = question.included ? totalscore + parseInt(qscore) : totalscore;
			})
		})
		return totalscore;
	}

	handleTextArea = (question_id) => (event) => {
		event.preventDefault();
		let {sections, score_result, scoring} = this.state;
		// console.log("Sections:", sections);
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					if (question.id === question_id) {
						question.answer.text = event.target.value === "-1" ? "" : event.target.value ;
						const thisquestiontype = question.type.find((t) => {
							return t.selected;
						})
						if(thisquestiontype.value === "Boolean") {
							if(question.answer.text === "-2") {
								question.included = question.included ? false : true;
								console.log("question.included", question.included);
								question.obtained_score = question.included ? question.obtained_score : "0";
								question.answer.text = question.included ? question.answer.text : "";
								const q_score = question.weight.find((q) => {
									return q.selected;
								})
								// score_result.totalscore = question.included ? score_result.totalscore + parseInt(q_score.value) : score_result.totalscore - parseInt(q_score.value);
							}
						}
						if (thisquestiontype.value === "Boolean" && question.auto_scoring.enabled) {
							if (question.answer.text.toLowerCase() === "yes") {
								question.obtained_score = question.auto_scoring.yes;
							} else if (question.answer.text.toLowerCase() === "no") {
								question.obtained_score = question.auto_scoring.no;
							}else{
								question.obtained_score = "0";
							}
						}
						

						if(thisquestiontype.value === "Dropdown") {
							const selectedans = question.preset_answers.find((pa) => {
								return pa.id === event.target.value && event.target.value !== "-1";
							})
							/*question.answer.text = event.target.value === "-1" ? "" : selectedans.value ;
							question.answer.selected_drop_id = event.target.value === "-1" ? "-1" : selectedans.id ;*/
							if(event.target.value === "-1" || event.target.value === "-2") {
								question.answer.text = "";
								question.answer.selected_drop_id = "-1";
								question.obtained_score = 0;
								question.included = event.target.value === "-2" && question.included ? false : true;
							}else{
								question.answer.text = selectedans.value ;
								question.answer.selected_drop_id = selectedans.id ;
								question.obtained_score = selectedans.weight;
							}
						}
						if (thisquestiontype.value === "Dropdown" && question.auto_scoring.enabled) {
							const selectedanswer = question.preset_answers.find((pa) => {
								return pa.id === event.target.value && event.target.value !== "-1";
							})
							question.obtained_score = event.target.value !== "-1" && event.target.value !== "-2" ? selectedanswer.weight : 0;
							// if(event.target.value !== "-1" || event.target.value !== "-2") {
							// 	question.answer.text = "";
							// 	question.answer.selected_drop_id = "-1";
							// 	question.obtained_score = 0;
							// 	question.included = event.target.value === "-2" && question.included ? false : true;
							// }else{
							// 	question.answer.text = selectedanswer.value ;
							// 	question.answer.selected_drop_id = selectedanswer.id ;
							// 	question.obtained_score = selectedanswer.weight;
							// }
						}
					}
				})
			}
		})
		score_result.totalscore = this.calculateTotalScore(sections);
		let scoresobtained = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = scoresobtained + (parseInt(question.obtained_score));
            })
        })
        scoresobtained = (scoresobtained / score_result.totalscore) * 100;
        score_result.scoresobtained = scoresobtained;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		score_result.ragratingcolor = s.color;
        		score_result.ragratingname = s.termname;
        		score_result.outcome = s.actual_outcome;
        	}
        })
		this.props.modifySections(sections, score_result);
	}

	handleComment = (question_id) => (event) => {
		event.preventDefault();
		let {sections, score_result, scoring} = this.state;
		// console.log("Sections:", sections);
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					if (question.id === question_id) {
						question.comments.text = event.target.value;
					}
				})
			}
		})
		let scoresobtained = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = scoresobtained + (parseInt(question.obtained_score));
            })
        })
        scoresobtained = (scoresobtained / score_result.totalscore) * 100;
        score_result.scoresobtained = scoresobtained;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		score_result.ragratingcolor = s.color;
        		score_result.ragratingname = s.termname;
        		score_result.outcome = s.actual_outcome;
        	}
        })
		this.props.modifySections(sections, score_result);
	}

	handleObtainedScore = (question_id) => (event) => {
		event.preventDefault();
		// console.log("question_id:", question_id);
		let {sections, score_result, scoring} = this.state;
		// console.log("Sections:", sections);
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					if (question.id === question_id) {
						question.obtained_score = event.target.value;
					}
				})
			}
		})
		let scoresobtained = 0;
		let review_autofailed = false;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = scoresobtained + (parseInt(question.obtained_score));
                const q_auto_fail = question.autofail.find((af) => {
                	return af.selected;
                })
                if (q_auto_fail.value === "Yes") {
                	const q_full_weight = question.weight.find((w) => {
                		return w.selected;
                	})
                	// if (q_full_weight.value !== question.obtained_score && question.included) {
                	// 	review_autofailed = true;
					// }
					if (parseInt(question.obtained_score) === 0 && question.included) {
                		review_autofailed = true;
                	}
                }
            })
        })
        // console.log("review_autofailed", review_autofailed);
        scoresobtained = (scoresobtained / score_result.totalscore) * 100;
        score_result.scoresobtained = scoresobtained;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		score_result.ragratingcolor = s.color;
        		score_result.ragratingname = s.termname;
        		score_result.outcome = s.actual_outcome;
        	}
        })
        if (review_autofailed) {
        	const fail_score = scoring.scores.find((fsc) => {
        		return fsc.actual_outcome === "Fail";
        	})
        	score_result.ragratingcolor = fail_score.color;
        	score_result.ragratingname = fail_score.termname;
        	score_result.outcome = fail_score.actual_outcome;
        }
        console.log("score_result:", score_result);
		this.props.modifySections(sections, score_result);
	}

	handleNonScore = (question_id, data) => {
		let sections = JSON.parse(JSON.stringify(this.state.sections));
		let scoring = JSON.parse(JSON.stringify(this.state.scoring));
		sections.forEach((section) => {
			section.questions.forEach((question) => {
				if (question.id === question_id) {
					question.non_scoring_info = data;
				}
			})
		})
		this.props.handleNonScoringInfo(sections);
	}

	handleIncluded = (question_id) => (event) => {
		event.preventDefault();
		let {sections, score_result, scoring} = this.state;
		// console.log("Sections:", sections);
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					if (question.id === question_id) {
						question.included = question.included ? false : true;
						console.log("question.included", question.included);
						question.obtained_score = question.included ? question.obtained_score : "0";
						question.answer.text = question.included ? question.answer.text : "";
						const q_score = question.weight.find((q) => {
							return q.selected;
						})
						if (scoring.scoring_enabled === false) {
							question.non_scoring_info = {label: "Select", color: "#ffffff"};
						}
						// score_result.totalscore = question.included ? score_result.totalscore + parseInt(q_score.value) : score_result.totalscore - parseInt(q_score.value);
					}
				})
			}
		})
		score_result.totalscore = this.calculateTotalScore(sections);
		let scoresobtained = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = scoresobtained + (parseInt(question.obtained_score));
            })
        })
        scoresobtained = (scoresobtained / score_result.totalscore) * 100;
        score_result.scoresobtained = scoresobtained;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		score_result.ragratingcolor = s.color;
        		score_result.ragratingname = s.termname;
        		score_result.outcome = s.actual_outcome;
        	}
        })
		console.log("score_result123:", score_result);
		this.props.modifySections(sections, score_result);
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		// event.preventDefault();
		// let {sections, score_result, scoring} = this.state;
		let sections = JSON.parse(JSON.stringify(this.state.sections));
		let score_result = JSON.parse(JSON.stringify(this.state.score_result));
		let scoring = JSON.parse(JSON.stringify(this.state.scoring));
		sections.forEach((section) => {
			if (section.id === section_id) {
				section.checkedforna = section.checkedforna ? false : true;
				// let secna = section.checkedforna ? false : true;
				// section.checkedforna = secna
				section.questions.forEach((question) => {
						// question.included = question.included ? false : true;
						question.included = section.checkedforna ? false : true;
						console.log("question.included", question.included);
						question.obtained_score = question.included ? question.obtained_score : "0";
						question.answer.text = question.included ? question.answer.text : "";
						const q_score = question.weight.find((q) => {
							return q.selected;
						})
						if (scoring.scoring_enabled === false) {
							question.non_scoring_info = {label: "Select", color: "#ffffff"};
						}
						// score_result.totalscore = question.included ? score_result.totalscore + parseInt(q_score.value) : score_result.totalscore - parseInt(q_score.value);

				})
			}
		})
		score_result.totalscore = this.calculateTotalScore(sections);
		let scoresobtained = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = scoresobtained + (parseInt(question.obtained_score));
            })
        })
        scoresobtained = (scoresobtained / score_result.totalscore) * 100;
        score_result.scoresobtained = scoresobtained;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		score_result.ragratingcolor = s.color;
        		score_result.ragratingname = s.termname;
        		score_result.outcome = s.actual_outcome;
        	}
        })
		console.log("score_result123:", score_result);
		this.props.modifySections(sections, score_result);
	}

	generateAnswerSection = (question) => {
		// console.log("Question:", question);
		const question_type = question.type.find((item) => {
			return item.selected;
		})
		// console.log("question_type:", question_type);
		let disabled = false;
		if (question.naallowed && !question.included) {
			disabled = true;
		}
		disabled = this.props.readonly === true ? true : disabled;
		let return_html = <div>Invalid question type</div>
		switch(question_type.value) {
			case "Memo":
				return_html = <AnswerTextArea disabled={disabled} value={question.answer.text} onChange={this.handleTextArea(question.id)} style={{marginTop: "5px"}} rows="6" />
				break;
			case "Alpha":
				return_html = <AnswerTextbox disabled={disabled} style={{marginTop: "5px"}} type="text" value={question.answer.text} onChange={this.handleTextArea(question.id)} />
				break;
			case "Boolean":
				const selected_value = question.answer.text === "" ? "Select" : question.answer.text;
				return_html =	<Dropdown disabled={disabled} style={{marginTop: "5px", float: "left"}} value={selected_value} onChange={this.handleTextArea(question.id)}>
									<option value="-1">Select</option>
									<option value="Yes">Yes</option>
									<option value="No">No</option>
									{
										(()  => {
											if(question.naallowed){
												return (<option value="-2">N/A</option>);
											}
										})()
									}
								</Dropdown>
				break;
			case "Numeric":
				return_html = <AnswerTextbox disabled={disabled} style={{marginTop: "5px"}} type="number" value={question.answer.text} onChange={this.handleTextArea(question.id)} />
				break;
			case "Dropdown":
				const drop_value = "selected_drop_id" in question.answer && question.answer.selected_drop_id === "-1" ? "Select" : question.answer.selected_drop_id;
				return_html =	<Dropdown disabled={disabled} style={{marginTop: "5px", float: "left"}} value={drop_value} onChange={this.handleTextArea(question.id)}>
									<option value="-1">Select</option>
									{
										question.preset_answers.map((pa) => {
											return(<option key={pa.id} value={pa.id}>{pa.value}</option>);
										})
									}
									{
										(()  => {
											if(question.naallowed){
												return (<option value="-2">N/A</option>);
											}
										})()
									}
								</Dropdown>
				break;
			default:
				break;
		}
		return return_html;
	}

	toggleGuidenotes = (question_id) => (event) => {
        event.preventDefault();
        let {sections, score_result} = this.state;
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
						question.guidance_note.collapsed = question.guidance_note.collapsed ? false : true; 
				})
			}
		})
		this.props.modifySections(sections, score_result);
    }

	render()
	{
		if (this.state.active_section_id === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				<AnswerSectionHeader style={{pointerEvents: this.props.readonly === true ? "none" : "auto"}}>
				{this.state.section_payload.name.replace(/'+/g,"'")}
				{
					(() => {
						if("sectionna" in this.state.section_payload && this.state.section_payload.sectionna) {
							return(<div style={{float: "right"}}>
										{
											(() => {
												if (this.state.section_payload.checkedforna){
													return(<div style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(this.state.active_section_id)}><IoIosCheckboxOutline /></div>);
												} else {
													return(<div  style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(this.state.active_section_id)}><IoIosSquareOutline /></div>);
												}

											})()
										}
										<div style={{float: "left"}}>Mark all questions in the section as N/A</div>
										<div style={{clear:"bith"}}></div>
								</div>)
						}

					})()
				}
				</AnswerSectionHeader>
				
				{
					this.state.section_payload.questions.map((question, index) => {
						let q_autofail = false;
						let q_weight = 0;
						question.autofail.forEach((af) => {
							if (af.selected && af.value === "Yes") {
								q_autofail = true;
							}
						})
						question.weight.forEach((w) => {
							if (w.selected) {
								q_weight = parseInt(w.value);
							}
						})
						// console.log("q_autofail:", q_autofail);
						// console.log("q_weight:", q_weight);
						let background_color = "#ffffff";
						let border_top = "none";
						let border_bottom = "none";
						// if (q_autofail && parseInt(question.obtained_score) < q_weight && question.included) {
						// 	background_color = "#FAE6E6";
						// 	border_top = "3px solid #f2bfbf";
						// 	border_bottom = "3px solid #f2bfbf";
						// }
						if (q_autofail && parseInt(question.obtained_score) === 0 && question.included) {
							background_color = "#FAE6E6";
							border_top = "3px solid #f2bfbf";
							border_bottom = "3px solid #f2bfbf";
						}
						return (
							<SingleQuestionContainer style={{backgroundColor: background_color, borderBottom: border_bottom, borderTop: border_top}} key={question.id}>
								<SerialNo>Q{question.s_index}&nbsp;&nbsp;&nbsp;&nbsp;
								{
																						
									(() => {
										let autofail = false;
										question.autofail.forEach((af) => {
											if (af.value === "Yes" && af.selected) {
												autofail = true;
											}
										})
										if (autofail) {
											return (
												<span style={{color: "#ff0000", marginTop:"10px"}} title="This will result in an autofail"><IoIosWarning /></span>
											);
										}
									})()
								}
								</SerialNo>
								<QuestionBody>
									<QuestionTxt>{question.question.replace(/'+/g,"'")}</QuestionTxt>
									{
										(() => {
											if (question.guidance_note.enabled && question.guidance_note.text!=="") {
												//let guidance_note = btoa(window.encodeURIComponent(question.guidance_note.text));  
												let decoded_guidance_note = question.guidance_note.text;
												console.log("guidance_note::",question.guidance_note.text)
												if(Utils.isBase64(question.guidance_note.text)){
													decoded_guidance_note = window.decodeURIComponent(atob(decoded_guidance_note));
													console.log("decoded_guidance_note::",decoded_guidance_note)
												}
												
												//console.log("encoded_guidance_note::",guidance_note)
												
												
												// const parser = new DOMParser(),
												// dom = parser.parseFromString(question.guidance_note.text, "text/html");

												if (question.guidance_note.collapsed) {
													return (
														<div style={{marginTop: "5px"}}>
															<GuidanceLabel>Guidance Note<div style={{fontSize: "13px", float: "right", cursor:"pointer", color: process.env.DEFAULT_TEXT_COLOR}} onClick={this.toggleGuidenotes(question.id)}><IoIosArrowUp /></div></GuidanceLabel>
															<GuidanceLine></GuidanceLine>
														</div>
													);
												} else {
													return (
														<div style={{marginTop: "5px"}}>
															<GuidanceLabel>Guidance Note<div style={{fontSize: "13px", float: "right", cursor:"pointer",color:process.env.DEFAULT_TEXT_COLOR}} onClick={this.toggleGuidenotes(question.id)}><IoIosArrowDown /></div></GuidanceLabel>
															<GuidanceLine></GuidanceLine>
															<div style={{clear:"both"}}></div>
															<QuestionTxt style={{marginTop:"10px"}} dangerouslySetInnerHTML={{__html: decoded_guidance_note}}></QuestionTxt>
														</div>
													);
												}
												
											}
										})()
									}
											<AnswerLabel>Answer</AnswerLabel>
											{
												(() => {
													if (question.included) {
														return this.generateAnswerSection(question);
													}
												})()
											}
										<NaContainer style={{fontSize: "21px", pointerEvents: this.props.readonly === true ? "none" : "auto"}}>
											{
												(() => {
													let padding_left = "0px";
													const question_type = question.type.find((item) => {
														return item.selected;
													})
													if(question_type.value === "Boolean" || question_type.value ==="Dropdown") {
														padding_left = "15px";
													}
													
													if (question.naallowed) {
														if (question.included) {
															return (
																<div style={{float: "left", paddingLeft: padding_left}}>
																	<AnswerLabel style={{float: "left"}}>Question N/A?</AnswerLabel>
																	<div style={{cursor: "pointer", float: "left", paddingTop: "7px"}} onClick={this.handleIncluded(question.id)}><IoIosSquareOutline /></div>
																	<div style={{clear: "both"}}></div>
																</div>
															);
														} else {
															return (
																<div style={{float: "left", paddingLeft: padding_left}}>
																	<AnswerLabel style={{float: "left"}}>Question N/A?</AnswerLabel>
																	<div  style={{cursor: "pointer", float: "left", paddingTop: "7px"}} onClick={this.handleIncluded(question.id)}><IoIosCheckboxOutline /></div>
																	<div style={{clear: "both"}}></div>
																</div>
															);
														}
													}
												})()
											}
										</NaContainer>
									<div style={{clear: "both"}}></div>
									<ScoreAndNaContainer>
										{
											(() => {
												console.log("readonly:", this.props.readonly);
												if (this.state.scoring.scoring_enabled) {
													if(question.auto_scoring.hide_score === false) {
														const status = question.included ? false : true;
														return (
														<ScoreContainer>
															<AnswerLabel style={{padding: "5px 0px"}}>Score</AnswerLabel>
															<Dropdown style={{pointerEvents: this.props.readonly === true ? "none" : "auto"}} value={question.obtained_score} disabled={status} onChange={this.handleObtainedScore(question.id)}>
																{
																	(() => {
																		let optionhtml = [];
																		const q_type = question.type.find(t => t.selected === true);
																		if (question.auto_scoring.enabled === true && (q_type.value === "Boolean" || q_type.value === "Dropdown")) {
																			// const q_type = question.type.find(t => t.selected === true);
																			if (q_type.value === "Boolean") {
																				optionhtml.push(<option key="yes" value={question.auto_scoring.yes}>{question.auto_scoring.yes}</option>);
																				optionhtml.push(<option key="no" value={question.auto_scoring.no}>{question.auto_scoring.no}</option>);
																			}
																			if (q_type.value === "Dropdown") {
																				for (let i=0; i<question.preset_answers.length; i++) {
																					optionhtml.push(<option key={question.preset_answers[i].id} value={question.preset_answers[i].weight}>{question.preset_answers[i].weight}</option>);
																				}
																			}
																		} else {
																			for (let i=0; i<question.weight.length; i++) {
																				optionhtml.push(<option key={question.weight[i].value} value={question.weight[i].value}>{question.weight[i].value}</option>);
																				if (question.weight[i].selected) {
																					break;
																				}
																			}
																		}
																		return optionhtml;
																	})()
																}
															</Dropdown>
														</ScoreContainer>
														)
													}
												} else {
													if (question.included) {
														return (
															<CslRoundDropDown
																options={this.state.scoring.non_scores}
																selected={question.non_scoring_info}
																question_id={question.id}
																handleNonScore={this.handleNonScore}
															/>
														);
													}
												}

											})()
										}
										
									</ScoreAndNaContainer>
									{
										(() => {
											if (question.comments.enabled) {
												let comment = 'text' in question.comments ? question.comments.text.replace(/'+/g,"'") : "";
												return (
													<div>
														<CommentsLabel style={{padding: "10px 0px"}}>Comments</CommentsLabel>
														<AnswerTextArea value={comment} onChange={this.handleComment(question.id)} rows="6" cols="100" />
													</div>
												);
											}
										})()
									}
								</QuestionBody>
								<div style={{clear: "both"}}></div>
							</SingleQuestionContainer>
						);
					})
				}
			</div>
		);
	}
}

export default AnswerSection;
