let completed_filters = {
	reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},
	reviewee: {name: 'regulator', 'nickname': 'Reviewee', type: 'multi', options: {},selections: {}, size: 2},
	customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
	review_type: {name: 'review_type', 'nickname': 'File Review Type', type: 'multi', options: {},selections: {}, size: 2},
	status: {name: 'status', 'nickname': 'Status', type: 'single', options: {},selections: {}, size: 1},
	result: {name: 'result', 'nickname': 'Result', type: 'single', options: {},selections: {}, size: 1},
	review_date: {name: 'review_date', 'nickname': 'Review Date', type: 'date_range', options: {},selections: {}, size: 2},
	review_month: {name: 'review_month', 'nickname': 'Review Month', type: 'multi', options: {},selections: {}, size: 2},
	company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2}
}

module.exports = completed_filters