import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { TiArrowBack } from "react-icons/ti";
import * as Style from '../Common/StyledComponents';
import WelcomeBanner from '../Common/WelcomeBanner';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall.js';
import RegisterHeader from './RegisterHeader';
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RegisterHoc from './CSLRegister/RegisterHoc';
import filters from './reportFilters.js';
import completed_filters from './completedFilters.js';
import completed_pill_filters from './completedPillFilters.js';
import pending_pill_filters from './PendingPillFilters.js';
import CSLLoader from './../Common/CSLLoader';
import ClipLoader from "react-spinners/ClipLoader";

import ReportModal from '../MGR/ReportModal';
import AssignQcModal from '../MGR/AssignQcModal';
import AnswerView from '../Common/AnswerView';

import RegisterReporting from './CSLRegister/RegisterReporting';
import reportData from './reportData.js';
import completed_report_data from './CompletedReportData.js';
import FilecheckerHOC from '../Tasks/FilecheckerHOC.js';
import EventBus from '../../Common/EventBus.js';

const months = {
                  '01': 'January',
                  '02': 'February',
                  '03': 'March',
                  '04': 'April',
                  '05': 'May',
                  '06': 'June',
                  '07': 'July',
                  '08': 'August',
                  '09': 'September',
                  '10': 'October',
                  '11': 'November',
                  '12': 'December'
               }


const InactiveOverlay = styled.div`
    display: block;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1000;
    opacity: 0.6;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const FilterButton = styled.button`
    box-sizing: border-box;
    background-color:#EEF2F7;
    color: #243E57;
    border: 1px solid #243E57;
    width: 130px;
    font-size: 13px;
    height: 30px;
    border-radius: 100px / 70px;
    margin-right:3px;
    margin-top:7px;
    outline:none;
`;
 const CheckBox = props => (
  <input type="checkbox" />
);

const Overdue = styled.span`
  height: 15px;
  width:15px;
  background-color: #ff0000;
  position: absolute; 
  opacity: 1 ; 
`;

const Pending = styled.span`
  height: 15px;
  width:15px;
  background-color: #5656AA;
  position: absolute; 
  opacity: 1 ; 
`;

const InProgress = styled.span`
  height: 15px;
  width:15px;
  background-color: #FFD11A;
  position: absolute; 
  opacity: 1;
`;

const Complete = styled.span`
  height: 15px;
  width:15px;
  background-color: #00ff00;
  position: absolute; 
  opacity: 1 ; 
`;

const Checkmark = styled.span`
  content: "\2713";
`;

const ReportButton = styled.button`
    background-color: #213E61;
    border: 1px solid #213E61;
    border-radius: 5px;
    color: #ffffff;
    padding: 8px 25px;
`;

const DummyOverlay = styled.div`
    display: table;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1000;
    opacity: 0.6;
`;

var taskListFiltered = {};

const sourceMap = {filechecker: 'File Checker', car: 'Compliance Advice Register', rcm: 'Regulatory Change Management'}
const completeMap = {COMPLETE: 1, COMPLETED: 1, DISMISSED : 1, REJECTED: 1, DISSMISS: 1, REJECT: 1}
const statusMap = {Pending: <Pending />, Complete: <Complete />, Overdue: <Overdue />}

class QCCheckBox extends React.Component {
  state = {checked: false}

  handleQcCheck = () => {
    let stored_checked_tasks = Store.getStoreData('qc_checked_tasks')
    let selected_company = Store.getStoreData('selected_company')
    if(selected_company !== null && selected_company !== 0 && selected_company !== this.props.companyId) {
      alert('You must select all tasks from the same company.')
      return false
    }
    let qc_checked_tasks = stored_checked_tasks === null ? {} : stored_checked_tasks

    if(!this.state.checked) qc_checked_tasks[this.props.taskId] = this.props.companyId
    else delete qc_checked_tasks[this.props.taskId];

    if(Object.keys(qc_checked_tasks).length === 0)selected_company = 0
    else selected_company = this.props.companyId

    Store.updateStore('qc_checked_tasks', qc_checked_tasks)
    Store.updateStore('selected_company', selected_company)
    console.log('QCCheckBox qc_checked_tasks', qc_checked_tasks)
    let checked = this.state.checked ? false : true
    this.setState({checked})
  }

  render() {
    if(this.state.checked) {
      return (<div style={{cursor: "pointer", fontSize: "20px"}} onClick={this.handleQcCheck}><IoIosCheckboxOutline /></div>)
    } else {
      return (<div  style={{cursor: "pointer", fontSize: "20px"}} onClick={this.handleQcCheck}><IoIosSquareOutline /></div>)
    }
  }
}

class FckRegister extends React.Component {

    state = {
        pending_data: null,
        completed_data: null,
        filtered_pending_tasks: null,
        filtered_completed_tasks: null,
        prefered_columns: {},
        filters: filters,
        pending_pill_filters: pending_pill_filters,
        show_completed: false,
        show_spinner: false,
        completed_filters: completed_filters,
        completed_pill_filters: completed_pill_filters,
        quality_assurance_enabled: true,
        qc_checked: {},
        qc_checked_company: {},
        view_count: 0,
        view: 'register',
        role: Store.getStoreData("loggedin_user_info").role,
        compheaderText:'Completed File Checks',
        showTaskModal: false,
        cur_lane: null,
        company_id: null,
        task_id: null
    };

    componentDidMount() {

        const td_str = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();//1622528181
        let postData = {command: "get_filecheck_registry",toDate:td_str};
        let api = new APICall();
        api.command(postData, this.processIndexData);
    }
    processIndexData = (result) => {
        console.log("applied_filters_complete   state:", this.state.completed_filters);
        const contacts = Store.getStoreData('users');
        let module_config = Store.getStoreData('module_config');
        let prefered_columns = JSON.parse(JSON.stringify(this.state.prefered_columns));
        let pending_pill_filters = JSON.parse(JSON.stringify(this.state.pending_pill_filters));
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        for (let row of result.rows) {
            let status = this.getStatus(row[result.headers.cur_lane], row[result.headers.review_date], row[result.headers.reviewer]);
            filters.status.options[status] = {name: status};
            filters.reviewer.options[row[result.headers.reviewer].toString()] = {name: contacts[row[result.headers.reviewer].toString()].ContactName};
            filters.reviewee.options[row[result.headers.reviewee].toString()] = {name: contacts[row[result.headers.reviewee].toString()].ContactName};
            filters.customer.options[row[result.headers.customer].toString()] = {name: row[result.headers.customer].toString()};
            filters.review_type.options[row[result.headers.review_type].toString()] = {name: row[result.headers.review_type].toString()};
            filters.result.options[row[result.headers.result].toString()] = {name: row[result.headers.result].toString()};
            filters.review_month.options[moment.unix(row[result.headers.review_date]).format('MMMM')] = {name: moment.unix(row[result.headers.review_date]).format('MMMM')};
            filters.company_name.options[row[result.headers.company].toString()] = {name: row[result.headers.company]};
        }
        for (let key in result.pill_filters) {
            pending_pill_filters[key].count = result.pill_filters[key];
        }
        if(this.state.role === 'senior_manager' || this.state.role === 'admin_manager'){
          if(Store.getStoreData('parent_has_group_companies') === true ){
              prefered_columns = {'status': 'Status', 'file_type' : 'File Type', 'company_name' : 'Company', 'reviewer' : 'Reviewer', 'reviewee' : 'Reviewee', 'customer' : 'Customer', 'assign_date' : 'Assigned Date', 'completed_date' : 'Scheduled Date', 'review_date' : 'Review Date', 'score' : 'Score', 'outcome' : 'Result', 'related_individual': "Related Individual", 'confirmed_by': "File Edited By", 'confirmed_date': "File Edited Date", 'confirmation_text': "File Edit Comments"}
          }else{
              prefered_columns = {'status': 'Status', 'file_type' : 'File Type', 'reviewer' : 'Reviewer', 'reviewee' : 'Reviewee', 'customer' : 'Customer', 'assign_date': 'Assigned Date', 'completed_date' : 'Scheduled Date', 'review_date' : 'Review Date', 'score' : 'Score', 'outcome' : 'Result', 'related_individual': "Related Individual", 'confirmed_by': "File Edited By", 'confirmed_date': "File Edited Date", 'confirmation_text': "File Edit Comments"}
          }
        }else{
          if(Store.getStoreData('parent_has_group_companies') === true ){
              prefered_columns = {'status': 'Status', 'file_type' : 'File Type', 'company_name' : 'Company', 'reviewer' : 'Reviewer', 'reviewee' : 'Reviewee', 'customer' : 'Customer', 'completed_date' : 'Scheduled Date', 'review_date' : 'Review Date', 'score' : 'Score', 'outcome' : 'Result', 'related_individual': "Related Individual", 'confirmed_by': "File Edited By", 'confirmed_date': "File Edited Date", 'confirmation_text': "File Edit Comments"}
          }else{
              prefered_columns = {'status': 'Status', 'file_type' : 'File Type', 'reviewer' : 'Reviewer', 'reviewee' : 'Reviewee', 'customer' : 'Customer', 'completed_date' : 'Scheduled Date', 'review_date' : 'Review Date', 'score' : 'Score', 'outcome' : 'Result', 'related_individual': "Related Individual", 'confirmed_by': "File Edited By", 'confirmed_date': "File Edited Date", 'confirmation_text': "File Edit Comments"}
          }
        }

        let pending_data = {headers: result.headers, rows: result.rows};
        let filtered_pending_tasks = result.rows;
        let quality_assurance_enabled = this.state.quality_assurance_enabled;
        if(Object.keys(module_config).length > 0) {
            quality_assurance_enabled = module_config.general.quality_assurance_enabled;
        }
        let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
        const groupings = Store.getStoreData("groupings");
        if (Store.getStoreData("parent_has_group_companies") === true) {
          for ( let item of groupings.tags_config) {
            for (let key in item) {
              completed_filters[key.toString()] = {name: key.toString(), 'nickname': item[key.toString()], type: 'multi', options: {},selections: {}, size: 2, is_grouping: true};
              completed_report_data.table_headers.push({accessor: key.toString(), nickname: item[key.toString()], type: 'month', width: 0, "valign": {'top':false,'vcentred':true,'bottom':false},
              "halign":{'left':true,'hcentred':false, 'right':false}});
              completed_report_data.sortby.fields.push({accessor: key.toString(), nickname: item[key.toString()]});
              completed_report_data.groupby.fields.push({accessor: key.toString(), nickname: item[key.toString()]});
            }
          }
        }
        console.log('ONLOADFILTER',pending_data)
        console.log('ONLOADFILTER filtered_pending_tasks',filtered_pending_tasks)
        this.setState({pending_pill_filters, pending_data, filtered_pending_tasks, filters, quality_assurance_enabled, prefered_columns, completed_filters, completed_report_data});
        // let pen_filter = Store.getStoreData('applied_filters_pending');
        // if(pen_filter){
        //   this.setState({filters: pen_filter})
        // }

        let applied_filters_complete = Store.getStoreData('applied_filters_complete');
        console.log('applied_filters_complete',applied_filters_complete)
        if(applied_filters_complete){
          this.setState({completed_filters: applied_filters_complete,
            show_completed: true,
            compheaderText: 'Completed File Checks: '+applied_filters_complete.review_date.selections.from_date +" - "+applied_filters_complete.review_date.selections.to_date
          })
          this.applyCompletedFilter_onload(applied_filters_complete)
        }
        // else{
        //   let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
        //   const start = new Date(new Date().setDate(new Date().getDate() - 500));
        //   const formated_start = moment(start).format("DD/MM/YYYY");
        //   const today = new Date();
        //   const formated_today = moment(today).format("DD/MM/YYYY");
        //   completed_filters.review_date.selections.from_date = formated_start;
        //   completed_filters.review_date.selections.to_date = formated_today;
        //   this.setState({completed_filters})
        // }

        //this.applyCompletedFilter_onload(this.state.completed_filters);



        let applied_filters_pending = Store.getStoreData('applied_filters_pending');
        console.log('applied_filters_pending',applied_filters_pending)
        if(applied_filters_pending){
          this.setState({filters: applied_filters_pending})
        }
          this.setState({
            show_spinner: false
        });


        console.log("applied_filters_complete ",this.state);
    }


    applyCompletedFilter_onload = (filters) => {
      //let filters = this.state.completed_filters;
      this.setState({
        show_spinner: true
    });
      console.log('refreshCompCallback applyCompletedFilter', filters);
      const postData = {command: "get_filtered_register_completed_tasks", filters};
      let api = new APICall();
      api.command(postData, this.processFilteredRegisterCompletedTasks_onload);
    }
    processFilteredRegisterCompletedTasks_onload = (result) => {
      //alert("Here1")
      console.log('refreshCompCallback result', result);
      Store.updateStore("completed_fcs", result);
      this.setState({
        show_spinner: false
      })
      //Store.updateStore("applied_reg_data_complete", result);
      this.refreshCompCallback();

    }
    showLoader = (state) => {
      //console.log("show_spinner",state)
      if(state === 1){
        this.setState({show_spinner:true})
      }else{
        this.setState({show_spinner:false})
      }

    }
    switchRadio = (event) => {
        event.preventDefault();
        console.log("toggleCallback=>",this.state.show_completed);
        console.log("applied_filters_complete ",this.state.completed_filters);
        if (this.state.show_completed === true) {
            let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
            Object.keys(completed_filters).forEach(key => {
                completed_filters[key].selections = {};
            })
            this.setState({
                show_completed: false,
                completed_tasks: null,
                completed_filters,
                compheaderText:'Completed File Checks'
            });
        } else {
            this.setState({show_spinner: true});
            const postData = {command: "get_register_complete_filter_options"};
            let api = new APICall();
            api.command(postData, this.processFilterOptions);
        }
    }

    processFilterOptions = (result) => {
      console.log("applied_filters_complete ",this.state.completed_filters);
        let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
        Object.keys(completed_filters).forEach(key => {
            completed_filters[key]['options'] = result[key];
            if (key === "review_date") {
            const td = new Date();
            let dd = td.getDate();
            let mm = td.getMonth()+1;
            let yyyy = td.getFullYear();
            dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
            mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
            const td_str = `${dd}/${mm}/${yyyy}`;
            // console.log(td_str);
            // td.setUTCHours(0,0,0,0);
            completed_filters.review_date.selections.from_date = td_str;
            completed_filters.review_date.selections.to_date = td_str;
            }
        })
        let comp_headertext = '';
        // let applied_filters_comp = Store.getStoreData('applied_filters_complete');
        // if(applied_filters_comp && Object.keys(applied_filters_comp.review_date.selections.length) > 0){
        //   comp_headertext = applied_filters_comp.selections.from_date +" - "+applied_filters_comp.selections.to_date;
        // }
        this.setState({
            completed_filters
        });
        let applied_filters_complete = Store.getStoreData('applied_filters_complete');
        if(applied_filters_complete){
          this.setState({completed_filters: applied_filters_complete,show_spinner: false,
            //show_completed: true,
            compheaderText: 'Completed File Checks: '+applied_filters_complete.review_date.selections.from_date +" - "+applied_filters_complete.review_date.selections.to_date
          })
        }else{
          let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
          const start = new Date(new Date().setDate(new Date().getDate() - 7));
          const formated_start = moment(start).format("DD/MM/YYYY");
          const today = new Date();
          const formated_today = moment(today).format("DD/MM/YYYY");
          completed_filters.review_date.selections.from_date = formated_start;
          completed_filters.review_date.selections.to_date = formated_today;
          this.setState({
            show_spinner: false,
            compheaderText:'Completed File Checks: --/--/---- - --/--/----'
          })
          }

        this.applyCompletedFilter_onload(this.state.completed_filters);
        //Store.updateStore('applied_filters_complete', this.state.completed_filters)
        console.log("applied_filters_complete state",this.state);
    }

    processRDRPending = () => {
        const contacts = Store.getStoreData('users');
        const has_gc = Store.getStoreData('parent_has_group_companies');
        let ret = {data: [], columns: [], raw_data: [], row_filters: {}};
        let prefered_columns ={};
        // console.log("filter_arr==>",filter_arr)
          ret.columns =[{
                          'Header' : 'Status',
                          accessor: 'status',
                          Cell: row => (<div>
                            {
                              (() => {
                                // console.log("row.original.status", row.original.status)
                                switch(row.original.status) {
                                  case 'Overdue': return  <Overdue><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Overdue>; break;
                                  case 'QA Reviewed':
                                  case 'Review Complete':
                                  case 'QA Complete':
                                  case 'Complete': return  <Complete><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Complete>; break;
                                  case 'In Progress': return  <InProgress><span style={{paddingLeft: "25px"}}>{row.original.status}</span></InProgress>; break;
                                  case 'In QA Review': return  <InProgress><span style={{paddingLeft: "25px"}}>{row.original.status}</span></InProgress>; break;
                                  //  return  <Complete><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Complete>; break;
                                  default: return  <Pending><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Pending>
                                }
                              })()
                            }
                          </div>),
                          minWidth: 150,
                          headerStyle: {textAlign: 'left'},
                          filterMethod: (filter, row) => {
                            return row._original.status.toLowerCase().startsWith(filter.value.toLowerCase());
                          },
                          sortMethod: (a, b) => {
                            return a > b ? 1 : -1;
                          }
                        },
                        {Header: 'File Type', accessor: 'file_type', minWidth: 170, headerStyle: {textAlign: 'left'}},
                        {Header: 'Company', accessor: 'company_name', minWidth: 120, headerStyle: {textAlign: 'left'}, show: {has_gc}},
                        {Header: 'Reviewer', accessor: 'reviewer', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Reviewee', accessor: 'reviewee', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Customer', accessor: 'customer', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Review Date', accessor: 'review_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                                return 0;
                            }
                            let adue = a.split("/");
                            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                        }},
                        {
                          Header: 'Result',
                          accessor: 'score_result',
                          minWidth: 160,
                          headerStyle: {textAlign: 'left'},
                          Cell: row => (
                            <div>
                              {
                                (() => {
                                  // console.log("Cell data:", row);
                                  if (row.original.score === "N/A") {
                                    return "N/A";
                                  } else {
                                    if (row.original.scoring_enabled === true) {
                                      return (
                                        <div>
                                          <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.rag}}></div>
                                          <div style={{float: "left", paddingLeft: "5px"}}>{Math.round(row.original.score)}%</div>
                                          <div style={{float: "left", paddingLeft: "2px"}}>({row.original.autofailed === true ? "Auto-fail" : row.original.result})</div>
                                          <div style={{clear: "both"}}></div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div>
                                          <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.rag}}></div>
                                          <div style={{float: "left", paddingLeft: "5px"}}>{row.original.result}</div>
                                          <div style={{clear: "both"}}></div>
                                        </div>
                                      );
                                    }
                                  }
                                })()
                              }
                            </div>
                          )
                        },
                        // {Header: 'Edited By', accessor: 'confirmed_by', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        // {Header: 'Edit Date', accessor: 'confirmed_date', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        // {Header: 'Edit Comments', accessor: 'confirmation_text', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Action', Cell: row => (
                         <div>
                              {
                                (() => {
                                  if(this.state.role !== 'view_only_manager') {
                                    //return(<MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>)
                                    return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
                                  }else {
                                    if(this.state.role === 'view_only_manager' && row.original.status === 'Complete') {
                                      //return(<MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>)
                                      return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
                                    }
                                  }
                                })()
                              }
                         </div>
                        ), filterable: false, width: 120, headerStyle: {textAlign: 'center'}},

                      ];
        // console.log("filtered_pending_tasks:", this.state.filtered_pending_tasks)
        // console.log("filtered_pending_data:", this.state.pending_data)
        // console.log("to_date state",this.state.filters.review_date)
        let to_date = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();
        if("to_date" in this.state.filters.review_date.selections){
              //console.log("to_date selections::",this.state.filters.review_date.selections.to_date)
              let dt_split = this.state.filters.review_date.selections.to_date.split('/');
              let frmt_dt = dt_split[2]+'-'+dt_split[1]+'-'+dt_split[0]
              to_date = moment(frmt_dt).unix();
        }
        //console.log("to_date::",to_date)
        if (this.state.filtered_pending_tasks.length !== 0) {
            let j = 1;
            for (let task of this.state.filtered_pending_tasks) {
              if(task[this.state.pending_data.headers.review_date] <= to_date){
                //console.log("review_date::",moment.unix(task[this.state.pending_data.headers.review_date]).format('DD/MM/YYYY'))
                let related_individual = "N/A";
                let confirmed_by = "N/A";
                let confirmed_date = "N/A";
                let confirmation_text = "N/A";
                if (task[this.state.pending_data.headers.related_individual_id] !== 0) {
                  related_individual = task[this.state.pending_data.headers.related_individual_id].toString() in contacts ? contacts[task[this.state.pending_data.headers.related_individual_id].toString()].ContactName : "Inactive User";
                }
                if (task[this.state.pending_data.headers.confirmed_by] !== 0) {
                  confirmed_by = task[this.state.pending_data.headers.confirmed_by].toString() in contacts ? contacts[task[this.state.pending_data.headers.confirmed_by].toString()].ContactName : "Inactive User";
                }
                if (task[this.state.pending_data.headers.confirmed_date] !== 0) {
                  confirmed_date = moment.unix(task[this.state.pending_data.headers.confirmed_date]).format("DD/MM/YYYY");
                }
                if (task[this.state.pending_data.headers.confirmation_text] !== "") {
                  confirmation_text = task[this.state.pending_data.headers.confirmation_text];
                }
                let elem = {
                    'index':j,
                    'status': this.getStatus(task[this.state.pending_data.headers.cur_lane], task[this.state.pending_data.headers.review_date], task[this.state.pending_data.headers.reviewer]),
                    'file_type': task[this.state.pending_data.headers.review_type],
                    'review_type': task[this.state.pending_data.headers.review_type],
                    'company_name': task[this.state.pending_data.headers.company],
                    'reviewer': task[this.state.pending_data.headers.reviewer] in contacts ? contacts[task[this.state.pending_data.headers.reviewer].toString()].ContactName : "--",
                    'reviewee': task[this.state.pending_data.headers.reviewee] in contacts ? contacts[task[this.state.pending_data.headers.reviewee].toString()].ContactName : "--",
                    'customer': task[this.state.pending_data.headers.customer],
                    'cur_lane' : task[this.state.pending_data.headers.cur_lane],
                    'review_date' : moment.unix(task[this.state.pending_data.headers.review_date]).format('DD/MM/YYYY'),
                    'completed_date': task[this.state.pending_data.headers.completed_date] === null ? "--" : moment.unix(task[this.state.pending_data.headers.completed_date]).format('DD/MM/YYYY'),
                    'review_month' : moment.unix(task[this.state.pending_data.headers.review_date]).format('MMMM'),
                    'id' : task[this.state.pending_data.headers.id],
                    'score' : task[this.state.pending_data.headers.score] === 'N/A' ? 'N/A' : Math.round(task[this.state.pending_data.headers.score]),
                    'result' : task[this.state.pending_data.headers.result],
                    'outcome' : task[this.state.pending_data.headers.result],
                    'rag': task[this.state.pending_data.headers.rag],
                    'company_id': task[this.state.pending_data.headers.company_id],
                    'scoring_enabled': task[this.state.pending_data.headers.scoring_enabled],
                    'autofailed': task[this.state.pending_data.headers.autofailed],
                    'related_individual': related_individual,
                    'confirmed_by': confirmed_by,
                    'confirmed_date': confirmed_date,
                    'confirmation_text': confirmation_text
                };
                ret.data.push(elem);
                ret.raw_data.push(elem);
                ret.row_filters[task[this.state.pending_data.headers.id]] = {All: 1, Pending: 0, Inprogress: 0, Awaiting: 0};
                // ret.row_filters[task[this.state.pending_data.headers.id]].All = 1;
                switch(task[this.state.pending_data.headers.cur_lane]) {
                  case 'COMP_FCK_QC_ASSIGNMENT':
                  case 'COMP_FCK_ASSIGNMENT':
                  case 'COMP_FCK_CREATE': ret.row_filters[task[this.state.pending_data.headers.id]].Pending = 1; break;
                  case 'COMP_FCK_SAVED': ret.row_filters[task[this.state.pending_data.headers.id]].Inprogress = 1; break;
                  case 'COMP_FCK_AWAITING_APPROVAL': ret.row_filters[task[this.state.pending_data.headers.id]].Awaiting = 1; break;
                }
                j++;
              }
            }
        }
        console.log("filtered_pending_tasks ret===>", ret);
        ret.data = ret.data.sort((a,b) => {
          if (a.review_date === b.review_date) {
            return 0;
          }
          let adue = a.review_date.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
          let  bbdue = b.review_date.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse < bReverse ? 1 : -1;
        });

        ret.export_columns = {
          file_type: "File Type",
          status: "Status",
          customer: "Customer",
          reviewer: "Reviewer",
          reviewee: "Reviewee",
          review_date: "Review Date",
          related_individual: "Related Individual",
          confirmed_by: "File Edited By",
          confirmed_date: "File Edited Date",
          confirmation_text: "File Edit Comments"
        }
        if (has_gc === true) {
          ret.export_columns.company_name = "Company";
        }
        console.log("filtered_pending_tasks ret 2===>", ret);

        // Store.updateStore('register_raw_data', ret)
        return ret;
    }

    processCompletedData = () => {
      const has_gc = Store.getStoreData('parent_has_group_companies');
      const gc_assoc = Store.getStoreData('gc_assoc');
      const contacts = Store.getStoreData('users');
      // let tasks = Store.getStoreData("completed_fcs");
      // if (tasks === null || tasks.length === 0) tasks = null;
      // console.log(tasks);
      let ret = {data: [], columns: [], raw_data: [], row_filters: {}};
      ret.columns =[
        {
          'Header' : 'Status',
          accessor: 'status',
          Cell: row => (<div>
            {
              (() => {
                // console.log("row.original.status", row.original.status)
                switch(row.original.status) {
                  case 'Overdue': return  <Overdue><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Overdue>; break;
                  case 'QA Reviewed':
                  case 'Review Complete':
                  case 'QA Complete':
                  case 'Complete': return  <Complete><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Complete>; break;
                  case 'In Progress': return  <InProgress><span style={{paddingLeft: "25px"}}>{row.original.status}</span></InProgress>; break;
                  case 'In QA Review': return  <InProgress><span style={{paddingLeft: "25px"}}>{row.original.status}</span></InProgress>; break;
                  //  return  <Complete><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Complete>; break;
                  default: return  <Pending><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Pending>
                }
              })()
            }
          </div>),
          minWidth: 150,
          headerStyle: {textAlign: 'left'},
          filterMethod: (filter, row) => {
            return row._original.status.toLowerCase().startsWith(filter.value.toLowerCase());
          },
          sortMethod: (a, b) => {
            return a > b ? 1 : -1;
          }
        },
        {Header: 'File Type', accessor: 'file_type', minWidth: 170, headerStyle: {textAlign: 'left'}},
        {Header: 'Company', accessor: 'company_name', minWidth: 120, headerStyle: {textAlign: 'left'}, show: {has_gc}},
        {Header: 'Reviewer', accessor: 'reviewer', minWidth: 120, headerStyle: {textAlign: 'left'}},
        {Header: 'Reviewee', accessor: 'reviewee', minWidth: 120, headerStyle: {textAlign: 'left'}},
        {Header: 'Customer', accessor: 'customer', minWidth: 120, headerStyle: {textAlign: 'left'}},
        {Header: 'Review Date', accessor: 'review_date', minWidth: 100, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
            if (a === b) {
                return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
        }},
        {
          Header: 'Result',
          accessor: 'score_result',
          minWidth: 160,
          headerStyle: {textAlign: 'left'},
          Cell: row => (
            <div>
              {
                (() => {
                  // console.log("Cell data:", row);
                  if (row.original.score === "N/A") {
                    return "N/A";
                  } else {
                    if (row.original.scoring_enabled === true) {
                      return (
                        <div>
                          <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.rag}}></div>
                          <div style={{float: "left", paddingLeft: "5px"}}>{Math.round(row.original.score)}%</div>
                          <div style={{float: "left", paddingLeft: "2px"}}>({row.original.autofailed === true ? "Auto-fail" : row.original.outcome})</div>
                          <div style={{clear: "both"}}></div>
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.rag}}></div>
                          <div style={{float: "left", paddingLeft: "5px"}}>{row.original.result}</div>
                          <div style={{clear: "both"}}></div>
                        </div>
                      );
                    }
                  }
                })()
              }
            </div>
          )
        },
        // {Header: 'Edited By', accessor: 'confirmed_by', minWidth: 120, headerStyle: {textAlign: 'left'}},
        // {Header: 'Edit Date', accessor: 'confirmed_date', minWidth: 120, headerStyle: {textAlign: 'left'}},
        // {Header: 'Edit Comments', accessor: 'confirmation_text', minWidth: 120, headerStyle: {textAlign: 'left'}},
        {'Header' : 'Action', Cell: row => (
          <div>
               {
                 (() => {
                   if(this.state.role !== 'view_only_manager') {
                     //return(<MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>)
                     return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
                   }else {
                     if(this.state.role === 'view_only_manager' && row.original.status === 'Complete') {
                       //return(<MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>)
                       return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
                     }
                   }
                 })()
               }
          </div>
         ), filterable: false, width: 120, headerStyle: {textAlign: 'center'}},
      ];
      if (this.state.quality_assurance_enabled && (this.state.role === "manager" || this.state.role === "qa_manager" || this.state.role === "senior_manager" || this.state.role === "admin_manager")) {
        ret.columns.push({'Header' : 'Send For QA', Cell: row => (
                      <div>
                        {
                          (() => {
                            if(row.original.cur_lane === 'COMP_FCK_COMPLETE') return (<QCCheckBox taskId={row.original.id} companyId={row.original.company_id}/>)
                          })()
                        }
                      </div>
                    ), filterable: false, width: 120, headerStyle:{textAlign: 'left'}});
      }
      // let qc_checked = {};

      // if (this.state.filtered_completed_tasks === null && Store.getStoreData("applied_reg_data_complete")) {
      //   var completed_data = Store.getStoreData("applied_reg_data_complete")
      //   var filtered_completed_tasks = completed_data.rows
      // }else{
        var filtered_completed_tasks = this.state.filtered_completed_tasks;
        var completed_data = this.state.completed_data;
      //}
      console.log('applied_reg_data_complete',completed_data);
      console.log('applied_reg_data_complete',filtered_completed_tasks);
      if (filtered_completed_tasks !== null) {
        let i = 0;
        for (let task of filtered_completed_tasks) {
          let CompanyName = '';
          if(parseInt(task[completed_data.headers.company_id]) < 100000){
            CompanyName = Store.getStoreData('parent_company').company_name;
          }else{
            const calc_company_id = parseInt(task[completed_data.headers.company_id]) % 100000;

            console.log("calc_company_id::",calc_company_id.toString())
            let inact = "";
            if(calc_company_id.toString() in gc_assoc){
              inact = gc_assoc[calc_company_id.toString()].is_active === 0 ? " (Inactive)" : "";
            }
            CompanyName = calc_company_id.toString() in gc_assoc === true ? gc_assoc[calc_company_id.toString()].company_name + inact : "Inactive Company";
          }
          let reviewer = task[completed_data.headers.reviewer].toString() in contacts ? contacts[task[completed_data.headers.reviewer].toString()].ContactName : "--";
          let reviewee = task[completed_data.headers.reviewee].toString() in contacts ? contacts[task[completed_data.headers.reviewee].toString()].ContactName : "--";
          let review_date = moment.unix(task[completed_data.headers.review_date],).format('DD/MM/YYYY');
          let review_month = moment.unix(task[completed_data.headers.review_date],).format('MMMM');
          //console.log('dataHEADER',this.state.role)
          let related_individual = "N/A";
          let confirmed_by = "N/A";
          let confirmed_date = "N/A";
          let confirmation_text = "N/A";
          if (task[completed_data.headers.related_individual_id] !== 0) {
            related_individual = task[completed_data.headers.related_individual_id].toString() in contacts ? contacts[task[completed_data.headers.related_individual_id].toString()].ContactName : "Inactive User";
          }
          if (task[completed_data.headers.confirmed_by] !== 0) {
            confirmed_by = task[completed_data.headers.confirmed_by].toString() in contacts ? contacts[task[completed_data.headers.confirmed_by].toString()].ContactName : "Inactive User";
          }
          if (task[completed_data.headers.confirmed_date] !== 0) {
            confirmed_date = moment.unix(task[completed_data.headers.confirmed_date]).format("DD/MM/YYYY");
          }
          if (task[completed_data.headers.confirmation_text] !== "") {
            confirmation_text = task[completed_data.headers.confirmation_text];
          }
           let elem = {'index':i,
                      'status': task[completed_data.headers.status],
                      'file_type': task[completed_data.headers.review_type],
                      'review_type': task[completed_data.headers.review_type],
                      'company_name':CompanyName,
                      'reviewer': reviewer,
                      'reviewee': reviewee,
                      'customer': task[completed_data.headers.customer],
                      'cur_lane' : task[completed_data.headers.cur_lane],
                      'review_date' : review_date,
                      'review_month' : review_month,
                      'id' : task[completed_data.headers.id],
                      'check' : "checkbox",
                      'completed_date' : review_date,
                      'scoring_enabled' : task[completed_data.headers.scoring_enabled],
                      'autofailed': task[this.state.pending_data.headers.autofailed],
                      'score' : Math.round(task[completed_data.headers.score]),
                      'result' : task[completed_data.headers.result],
                      'rag' : task[completed_data.headers.rag],
                      'company_id': task[completed_data.headers.company_id],
                      'checked': task[completed_data.headers.checked],
                      'outcome': task[completed_data.headers.scoring_enabled] === true ? task[completed_data.headers.result] : task[completed_data.headers.result], //score_result.ragratingname
                      'related_individual': related_individual,
                      'confirmed_by': confirmed_by,
                      'confirmed_date': confirmed_date,
                      'confirmation_text': confirmation_text.toString().replace(/\n/g, " ").replace(/,/g, " "),
                      'completed_date': moment.unix(task[completed_data.headers.completed_date]).format('DD/MM/YYYY'),
          };
          if(this.state.role === 'senior_manager' || this.state.role === 'admin_manager'){
          let assign_date = Math.floor(task[completed_data.headers.assign_date] / 1000);
		      assign_date = moment.unix(assign_date).format('DD/MM/YYYY');
		      elem = {...elem,'assign_date': assign_date};
		      //console.log('dataHEADER elem',elem)
		      }
          const groupings = Store.getStoreData("groupings");
          if (Store.getStoreData("parent_has_group_companies") === true) {
            for (let key in groupings.tags_config) {
              elem[key.toString()] = task[completed_data.headers[key.toString()]];
            }
          }
          ret.data.push(elem);
          ret.raw_data.push(elem);
          ret.row_filters[task[completed_data.headers.id]] = {All: 1, Compliant: 0, NonCompliant: 0, Indeterminate: 0};
          switch(task[completed_data.headers.result]) {
            case 'Pass': ret.row_filters[task[completed_data.headers.id]].Compliant = 1; break;
            case 'Fail': ret.row_filters[task[completed_data.headers.id]].NonCompliant = 1; break;
            case 'Indeterminate': ret.row_filters[task[completed_data.headers.id]].Indeterminate = 1; break;
          }
          i++;
        }

      }
      console.log('Completed tasks process', ret);
      if (has_gc === true) {
        ret.export_columns = {file_type: "File Type", status: "Status", company_name: "Company", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date"};
      } else {
        ret.export_columns = {file_type: "File Type", status: "Status", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date"};
      }
      return ret;
    }

    getStatus = (cur_lane, due_date, reviewer_id) => {
      const loggedin_user_info = Store.getStoreData('loggedin_user_info');
      // console.log("task_json:", task_json);
      let lane_parts = cur_lane.split('_')
      let lane_end = lane_parts[lane_parts.length - 1]
      let status = 'Pending'
      if(lane_end in completeMap) {
        status = 'Review Complete'
      } else {
            // let due_date = new Date(row.due_date);
            let unix_due_date = moment.unix(due_date);
            due_date = unix_due_date.set('hour', 23).set('minute', 59).set('second', 59).unix();
            let cur_date = moment().unix();
            // let cur_date_int = cur_date.getFullYear()*10000 + cur_date.getMonth()*100 + cur_date.getDate();
            // let due_date_int = due_date.getFullYear()*10000 + due_date.getMonth()*100 + due_date.getDate();
            if(due_date < cur_date)status = 'Overdue'
      }
      status = cur_lane === 'COMP_FCK_AWAITING_APPROVAL' ? 'Awaiting Approval' : status;
      status = cur_lane === 'COMP_FCK_SAVED' ? 'In Progress' : status;
      if (Store.getStoreData("loggedin_user_info").role === "file_checker") {
        status = cur_lane === "COMP_FCK_COMPLETE" ? "Review Complete" : status;
        if (Store.getStoreData('module_config').general.own_reviews_qa_view === true) {
          status = cur_lane === "COMP_FCK_QC_ASSIGNMENT" && reviewer_id === loggedin_user_info.ID ? "In QA Review" : status;
          status = cur_lane === "COMP_FCK_QC_COMPLETE" && reviewer_id === loggedin_user_info.ID ? "QA Reviewed" : status;
        } else {
          status = cur_lane === "COMP_FCK_QC_ASSIGNMENT" ? "Review Complete" : status;
          status = cur_lane === "COMP_FCK_QC_COMPLETE" ? "QA Complete" : status;
        }
      }
      return status;
    }

    // goToTaskUrl = (id) => (event) => {
    //     event.preventDefault();
    //     // console.log('taskId==>', id)
    //     const task_url = Store.getStoreData('is_debug') ? `/${id}` : process.env.REACT_APP_API_URL+"mstop/filechecker/"+id;
    //     window.location.assign(task_url);
    //     // this.goToTaskUrlBare(id);
    // }

    goToTaskUrl = (id,company_id,cur_lane) => (event) => {
      event.preventDefault();
      // //console.log('isModal',Store.getStoreData('is_modal'));
      //  console.log('id',id);
      //   console.log('company_id',company_id);
      //    console.log('cur_lane',cur_lane);
      // console.log('taskId==>', id)
      const task_url = `/${id}`;
      console.log(task_url);

    //if(Store.getStoreData('is_modal')){}
      let modalview = 1;
      //console.log('REACT_APP_DETAILS_PAGE_VIEW::',modalview);

      if( modalview == 1){
      //console.log('is_modal=>',Store.getStoreData('is_modal'));
        this.setState({showTaskModal: true, task_id:id, company_id:company_id, cur_lane:cur_lane})
      }else{
        window.location.assign(task_url);
     }


      // this.goToTaskUrlBare(id);
  }

    // pendingPillClickHandler = (name) => {
    //     let pending_pill_filters = JSON.parse(JSON.stringify(this.state.pending_pill_filters));
    //     Object.keys(pending_pill_filters).forEach(key => {
    //         if (key === name) {
    //             pending_pill_filters[key].is_active = 1;
    //         } else {
    //             pending_pill_filters[key].is_active = 0;
    //         }
    //     })
    //     let filtered_pending_tasks = [];
    //     for (let row of this.state.pending_data.rows) {
    //         if (row[this.state.pending_data.headers[name]] === 1) {
    //             filtered_pending_tasks.push(row);
    //         }
    //     }
    //     this.setState({pending_pill_filters, filtered_pending_tasks});
    // }

    pendingPillClickHandler = (name) => {
        let pending_pill_filters = JSON.parse(JSON.stringify(this.state.pending_pill_filters));
        let filtered_pending_tasks = [];
        for (let key in pending_pill_filters) {
            if (key === name) {
                pending_pill_filters[key].is_active = pending_pill_filters[key].is_active === 1 ? 0 : 1;
            }
        }
        if (pending_pill_filters.All.is_active === 1) {
            filtered_pending_tasks = JSON.parse(JSON.stringify(this.state.pending_data.rows));
            for (let key in pending_pill_filters) {
                if (key !== "All") pending_pill_filters[key].is_active = 0;
            }
        } else {
            let exist = {};
            for (let row of this.state.pending_data.rows) {
                for (let key in pending_pill_filters) {
                    if (key !== "All") {
                        if (pending_pill_filters[key].is_active === 1 && row[this.state.pending_data.headers[key]] === 1 && row[this.state.pending_data.headers.id].toString() in exist === false) {
                            exist[row[this.state.pending_data.headers.id].toString()] = 1;
                            filtered_pending_tasks.push(row);
                        }
                    }
                }
            }
        }
        this.setState({pending_pill_filters, filtered_pending_tasks});
    }

    completedPillClickHandler = (name) => {
        let completed_pill_filters = JSON.parse(JSON.stringify(this.state.completed_pill_filters));
        let filtered_completed_tasks = [];
        for (let key in completed_pill_filters) {
            if (key === name) {
                completed_pill_filters[key].is_active = completed_pill_filters[key].is_active === 1 ? 0 : 1;
            }
        }
        if (completed_pill_filters.All.is_active === 1) {
            filtered_completed_tasks = JSON.parse(JSON.stringify(this.state.completed_data.rows));
            for (let key in completed_pill_filters) {
                if (key !== "All") completed_pill_filters[key].is_active = 0;
            }
        } else {
            let exist = {};
            for (let row of this.state.completed_data.rows) {
                for (let key in completed_pill_filters) {
                    if (key !== "All") {
                        if (completed_pill_filters[key].is_active === 1 && row[this.state.completed_data.headers[key]] === 1 && row[this.state.completed_data.headers.id].toString() in exist === false) {
                            exist[row[this.state.completed_data.headers.id].toString()] = 1;
                            filtered_completed_tasks.push(row);
                        }
                    }
                }
            }
        }
        this.setState({completed_pill_filters, filtered_completed_tasks});
    }

    applyPendingFilter = (filters) => {
        console.log(filters);
    }

    refreshCallback = () => {
      this.setState({view_count: this.state.view_count + 1});
    }

    processDate = () => {
        var d = new Date()
        let day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
        let monthIndex = d.getMonth() < 10 ? `0${d.getMonth().toString()}`: d.getMonth().toString();
        let year = d.getFullYear().toString();
        let date = day + '/' + (parseInt(monthIndex)+1) + '/' + year;
        return date;
    }

    applyCompletedFilter = (filters) => {
        //console.log(filters);
        const postData = {command: "get_filtered_register_completed_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredRegisterCompletedTasks);
    }

    processFilteredRegisterCompletedTasks = (result) => {
        console.log('dataHEADER processFilteredRegisterCompletedTasks',result);
        let completed_data = {headers: result.headers, rows: result.rows};
        let filtered_completed_tasks = result.rows;
        let completed_pill_filters = JSON.parse(JSON.stringify(this.state.completed_pill_filters));
        for (let key in result.pill_filters) {
            completed_pill_filters[key].count = result.pill_filters[key];
        }
        this.setState({completed_data, filtered_completed_tasks, completed_pill_filters});
    }

    handleQcCheck = (task_id, company_id) => (event) => {
      event.preventDefault();
      console.log("task_id | company_id ::", task_id, company_id);
      let filtered_completed_tasks = JSON.parse(JSON.stringify(this.state.filtered_completed_tasks));
      filtered_completed_tasks.forEach(task => {
        if (task[this.state.completed_data.headers.id] === task_id) {
          task[this.state.completed_data.headers.checked] = task[this.state.completed_data.headers.checked] === true ? false : true
        }
      })
      this.setState({filtered_completed_tasks});
    }


    clearPendingAdvancedFilter = () => {
      //console.log("pending_filters", JSON.parse(JSON.stringify(this.state.filters)));
      let filters = JSON.parse(JSON.stringify(this.state.filters));
      Object.keys(filters).forEach(key => {
          if (filters[key].type === "date_range") {
              filters[key].selections.from_date = moment().format('DD/MM/YYYY')
              filters[key].selections.to_date = moment().format('DD/MM/YYYY')
          } else {
              filters[key].selections = {};
          }
      })
      this.setState({filters});
      //Store.updateStore("applied_filters_pending", filters);
      localStorage.removeItem("applied_filters_pending")
      // window.location.reload();
    }

    clearCompletedAdvancedFilter = () => {
      console.log("applied_filters_complete ",this.state.completed_filters);
      let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
      Object.keys(completed_filters).forEach(key => {
          if (completed_filters[key].type === "date_range") {
              completed_filters[key].selections.from_date = moment().format('DD/MM/YYYY')
              completed_filters[key].selections.to_date = moment().format('DD/MM/YYYY')
          } else {
              completed_filters[key].selections = {};
          }
      })
      //this.setState({completed_filters});
      this.setState({
        completed_filters,
        compheaderText:'Completed File Checks: --/--/---- - --/--/----'
      })

      //Store.updateStore("applied_filters_complete", completed_filters);
      localStorage.removeItem("applied_filters_pending")
      // window.location.reload();
    }

    refreshCompCallback = () => {
      //alert(2)
      console.log('refreshCompCallback called', Store.getStoreData("completed_fcs"))
      let completed_data = Store.getStoreData("completed_fcs")
      console.log('dataHEADER refreshCompCallback',completed_data);
      let filtered_completed_tasks = completed_data.rows
      console.log('filtered_completed_tasks', filtered_completed_tasks)
      let qc_checked = {};
      for (let task of filtered_completed_tasks) {
        if (task.cur_lane === "COMP_FCK_COMPLETE") qc_checked[task.id.toString()] = false;
      }
      let filters = Store.getStoreData('applied_filters_complete');

      if(filters){
        this.setState({
          compheaderText:"Completed File Checks: "+filters.review_date.selections.from_date+" - "+filters.review_date.selections.to_date
        });
      }else{
        this.setState({
          compheaderText:'Completed File Checks: --/--/---- - --/--/----'
        });
      }
      this.setState({
        //compheaderText:"Completed File Checks: "+filters.review_date.selections.from_date+" - "+filters.review_date.selections.to_date,
        show_spinner: false,
        show_completed: true,
        completed_data,
        filtered_completed_tasks,
        qc_checked,
        view_count: this.state.view_count + 1
      });
    }

    toggleAssignQcModal = (view) => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      let {modal_view} = this.state;
      modal_view = view;

      let qc_checked_tasks = Store.getStoreData('qc_checked_tasks')

      if(qc_checked_tasks === null || Object.keys(qc_checked_tasks).length === 0) {
        alert("Please tick at least one review");
        return false;
      }

      let comps = {}
      for(let t_id in qc_checked_tasks) {
        comps[qc_checked_tasks[t_id]] = 1
      }

      if(Object.keys(comps).length > 1) {
        alert('Please choose same companies')
        return false
      }

      this.setState({modal_view});
    }

    old_toggleAssignQcModal = (view) => {
      //console.log(this.state.qc_checked_company)
      var firstKey = Object.keys(this.state.qc_checked_company)[0];
      // console.log(firstKey)
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      let {modal_view} = this.state;
      modal_view = view;
      let qc_checked = this.state.qc_checked;
      let check = false;
      for (let [key, value] of Object.entries(qc_checked)) {
          if(value){
            check = value;
          }
       }
       if(!check) {
         alert("Please tick at least one review");
         return false;
       }
       let is_repeat = false;
       let qc_comp = this.state.qc_checked_company;
       let com_id = qc_comp[firstKey];
       for(let k in qc_comp) {
        if(qc_comp[k]!==com_id) {
          is_repeat = true;
        }

       }

       if(is_repeat) {
         alert("Please choose same companies");
         return false;
       }

      this.setState({modal_view});
    }

    closeReportmodal = () => {
        this.setState({modal_view: null});
        Store.updateStore("fc_index", null);
        //console.log('referrer',document.referrer)
        if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
          window.location.assign(document.referrer);
        }
    }
    closeModal = () => {
      this.setState({showTaskModal: false})
    }
    render () {
        console.log('FckRegister render state', this.state)
        if (this.state.filtered_pending_tasks === null) {
            return (<div><CSLLoader /></div>);
        }
        console.log('applied_filters_complete',completed_filters)
        // console.log("pill_filters:", this.state.filters);
        // console.log("advanced_filters:", this.state.filters);
        // console.log("completed_pill_filters:", this.state.completed_pill_filters);

        let button_set = {}
        button_set['assign_qc'] = {id: 'assign_qc', display: 'Assign For QA', stack: 'assignqcmodal', button_callback: this.toggleAssignQcModal, is_visible: this.state.quality_assurance_enabled }
        return (
            <div style={{backgroundColor: "#f2f2f3", position: "relative", paddingBottom: "75px"}}>
                {
                    (() => {
                        if (this.state.show_spinner === true) {
                          return (
                              <div>
                                  <DummyOverlay>
                                      <div style={{display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                                          <ClipLoader
                                              size={150}
                                              color={"#123abc"}
                                              loading={true}
                                          />
                                      </div>
                                  </DummyOverlay>
                              </div>
                          );
                        }
                    })()
                }
                <RegisterHeader />
                <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData('loggedin_user_info').ContactName} />
                <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px"}}>
                {
                  (() => {
                  if(this.state.showTaskModal){
                    return <FilecheckerHOC closeModal = {this.closeModal} task_id={this.state.task_id} cur_lane={this.state.cur_lane} company_id={this.state.company_id}/>;
                    // return <FilecheckerHOC task_id={'38970'} cur_lane={'COMP_FCK_SAVED'} company_id={'277100050'}/>;

                }
                })()
              }
                    <RegisterReporting
                        headerText={Store.getStoreData("loggedin_user_info").role === "admin_manager" ? 'Pending File Checks' : 'Filechecker Register'}
                        penheaderText={Store.getStoreData("loggedin_user_info").role === "admin_manager" ? 'Pending File Checks: --/--/---- - --/--/---' : 'Filechecker Register: --/--/---- - --/--/---'}
                        export_file_name={Store.getStoreData("loggedin_user_info").role === "admin_manager" ? "Pending File Check Report_"+moment().format('DD/MM/YYYY') : "File Check Report_"+moment().format('DD/MM/YYYY')}
                        moduleId='filechecker'
                        data={reportData}
                        processTableData={this.processRDRPending}
                        preferedColumns={this.state.prefered_columns}
                        buttonSet={null}
                        filters={this.state.filters}
                        pillFilters={this.state.pending_pill_filters}
                        refreshCallback={this.refreshCallback}
                        viewCount={this.state.view_count}
                        toggle_text={null}
                        toggle_state={true}
                        clearAdvancedFilter={this.clearPendingAdvancedFilter}
                        report={true}
                        titlePDF={'File Check Report_'+ this.processDate()}
                    />
                </div>

                <div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                    <RegisterReporting
                        compheaderText= {this.state.compheaderText}
                        headerText='Completed File Checks'
                        export_file_name={"Completed File Check Report_"+moment().format('DD/MM/YYYY')}
                        moduleId='filechecker'
                        data={completed_report_data}
                        processTableData={this.processCompletedData}
                        preferedColumns={this.state.prefered_columns}
                        buttonSet={button_set}
                        filters={this.state.completed_filters}
                        pillFilters={this.state.completed_pill_filters}
                        refreshCallback={this.refreshCallback}
                        refreshCompCallback={this.refreshCompCallback}
                        viewCount={this.state.view_count}
                        toggle_text="Show Completed File Checks"
                        toggleCallback={this.switchRadio}
                        toggle_state={this.state.show_completed}
                        clearAdvancedFilter={this.clearCompletedAdvancedFilter}
                        report={true}
                        titlePDF={'File Check Report_'+ this.processDate()}
                        showLoader = {this.showLoader}
                    />
                </div>
                {
                    (() => {
                      // console.log('ModalView this.state', this.state)
                        if(this.state.modal_view !== null && this.state.view === "register") {
                            switch(this.state.modal_view) {
                                // case 'show_filecheck_modal': return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><FileCheckModal closeModal={this.closeReportmodal} /></div>; break;
                                case 'show_filecheck_modal': return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><AnswerView closeModal={this.closeReportmodal} /></div>; break;
                                case 'view_report_modal': return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><ReportModal closeModal={this.closeReportmodal} /></div>; break;
                                case 'assignqcmodal': return <div style={{position:'absolute', left:'10%', top:'60px', width:'100%', height:'1186px'}}><InactiveOverlay /><AssignQcModal closeModal={this.closeReportmodal} assignqc_tasks={this.state.qc_checked} qc_checked_company={this.state.qc_checked_company} /></div>; break;
                            }
                        }
                    })()
                }

            </div>

        );


    }
}

export default FckRegister;
