import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLAdminTable from '../Common/CSLAdminTable';
import AddMRModal from './AddMRModal';
import * as Style from '../Common/StyledComponents'
import APICall from '../../Common/APICall';
import MGRAddFileCheckModal from '../Modals/MGRAddFileCheckModal';

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 5px;
    font-size: 11px;
    cursor: pointer;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;

`;

class CSLIndex extends React.Component
{
    state = { modal_view: null, rid: 0, name: "", show_scores: false, description: "", active: "", resultconfigs: [], openconfigs: [], questions: [], copy: false}

    componentDidMount()
    {
        console.log("csl table data:", this.props.tabledata);
    }

    refreshState = () => {
        this.setState({view_count: this.state.view_count + 1});
    }

    showInitModelReviewModal = (rid) => (event) => {
        event.preventDefault();
        console.log("RID:", rid);
        Store.updateStore('rid', rid);
        if (rid !== 0) {
            this.setState({modal_view: 'csl_mr_modal', rid: parseInt(rid)});
        } else {
            this.setState({modal_view: 'csl_mr_modal', rid: parseInt(rid)});
        }
        // Store.updateStore('OverlayForCSLModal', `${document.body.scrollHeight.toString()}px`);
        
    }

    copyInitModelReviewModal = (rid) => (event) => {
        event.preventDefault();
        Store.updateStore('rid', rid);
        this.setState({modal_view: 'csl_mr_modal', rid: parseInt(rid), copy: true});
    }

    processPendingTasksData = () => {
        let tabledata = Store.getStoreData('my_reviews');
        console.log("tabledata", tabledata);
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 40, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Name', accessor: 'name', width: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Description', accessor: 'description', minWidth: 200, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Scoring', accessor: 'scoring', minWidth: 50, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Active', accessor: 'active', minWidth: 50, headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={this.showInitModelReviewModal(row.original.id)}>Edit</MRButton>
                               <MRButton onClick={this.copyInitModelReviewModal(row.original.id)}>Copy</MRButton>
                           </div>
                        ), width: 200, headerStyle: {textAlign: 'left'}}];

        for(let d of tabledata) {
            const review_json = JSON.parse(d.review_json);
            console.log('data element->', d);
            i++;
            let elem = {
                id: d.id,
                index : i,
                name : d.nick_name,
                active : d.is_active === 1 ? "Yes" : "No",
                description : d.description,
                scoring: review_json.scoring.scoring_enabled ? "Yes" : "No"
            };
            ret.data.push(elem);
        }
        return ret;
    }

    closeModal = () => {
        this.setState({modal_view: null, copy: false});
    }

    reloadPage = () => {
        alert("Saved successfully");
        window.location.reload(true);
    }

    dontReloadPage = () => {
        alert("Saved successfully");
    }

    saveModelReview = (data) => {
        console.log("DATA:", data);
        let api = new APICall();
        if ("mode" in data && data.mode === "save") {
            api.command(data, this.dontReloadPage);
        } else {
            api.command(data, this.reloadPage);
        }
    }

    render()
    {
        return (
            <div>
                {
                    (() => {
                        if(this.state.modal_view === null) {
                            return (
                                <div>
                                    <SubmitButton onClick={this.showInitModelReviewModal(0)}>New model review</SubmitButton>
                                    <CSLAdminTable add={false} processData={this.processPendingTasksData} headerText={'Model Reviews'} refreshCallback={this.refreshState} />
                                </div>
                            );
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                            switch(this.state.modal_view) {
                                case 'csl_mr_modal': return <div style={{backgroundColor: "#f2f2f3"}}><MGRAddFileCheckModal closemodal={this.closeModal} copy={this.state.copy}/></div>; break;
                            }
                        }
                    })()
                }
            </div>
        );
    }
}

export default CSLIndex;