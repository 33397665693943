import React from 'react';
import styled from 'styled-components';
import { FaTimes, FaTrashAlt, FaLock } from 'react-icons/fa';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils.js';
import APICall from '../../Common/APICall';
import { IoIosArrowForward } from 'react-icons/io';
import './MGRAddFileCheckModal.css';
import MGRGeneral from './MGRAddFileCheckTabs/MGRGeneral';
import MGRScoring from './MGRAddFileCheckTabs/MGRScoring';
import MGRQuestionsSection from './MGRAddFileCheckTabs/MGRQuestionsSection';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    left: 0px;
    right:0px;
    top: 40px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 1px;
    
`;

const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;

const Tabdiv1 = styled.div`
    width: 20%;
    float: left;
    
`;
const Tabdiv2 = styled.div`
    width: 70%;
    float: left;
    background-color: white;
    margin: 0px 35px;
    margin-bottom: 5px;
`;
const Tabdiv = styled.div`
    width: 100%;
`;

const Tabbutton = styled.div`
    display: block;
    color: #303030;
    padding: 10px 20px;
    width: 82%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 13px;
    margin: 5px;
    background-color: white
`;

const Tabbuttonactive = styled.div`
    display: block;
    color: #303030;
    padding: 10px 20px;
    width: 82%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 13px;
    margin: 5px;
    background-color: white;
    font-weight: bold;
`;
const NavIndicator = styled.div`
    color: #D2D4D6;
    background-color: #EBEBEB;
    border: 1px solid #ccc;
    padding: 6px 10px;
    display: inline-block;
`
const NavIndicatorActive = styled.div`
    color: #FFFFFF;
    background-color: #0F2A4A;
    border: 1px solid #ccc;
    padding: 6px 40px;
    display: inline-block;
`


class MGRAddFileCheckModal extends React.Component {
    state = {
        ready: false,
        template_id: 0,
        selected_gc_id: "0",
        starttypes: [{ value: 0, display: "Start from scratch" }, { value: 1, display: "Start from a Model Review" }],
        selectedstarttype: "0",
        selectedmodelreview: {},
        selectedmodelreview_id: "0",
        show_scores: false,
        allow_scoring:true,
        name: '',
        use_model: 0,
        description: '',
        resultconfigs: [],
        openconfigs: [],
        sections: [
            {
                name: "Untitled section",
                type: "label",
                collapsed: true,
                questions: [
                    {   
                        collapsed: true,
                        question: "",
                        type: [
                            { value: "Memo", selected: false },
                            { value: "Boolean", selected: true },
                            { value: "Alpha", selected: false },
                            { value: "Numeric", selected: false },
                            { value: "Dropdown", selected: false },
                        ],                        
                        weight: [
                            { value: "0", selected: false },
                            { value: "1", selected: true },
                            { value: "2", selected: false },
                            { value: "3", selected: false },
                            { value: "4", selected: false },
                            { value: "5", selected: false },
                            { value: "6", selected: false },
                            { value: "7", selected: false },
                            { value: "8", selected: false },
                            { value: "9", selected: false },
                            { value: "10", selected: false }
                        ],
                        autofail: [
                            { value: "Yes", selected: false },
                            { value: "No", selected: true }
                        ],
                        naallowed: false,
                        comments: { enabled: false, required: false },
                        auto_scoring: { enabled: false, yes: 6, no: 0, hide_score: false },
                        guidance_note: { enabled: false, text: "" },
                        preset_answers: [],
                        answer: { text: "", comment: "" },                        
                    }
                ]
            }
        ],
        allowConditionalQuestion: '0',
        allowGuidance: '1',
        allowActive: '1',
        secondstep: false,
        isActive: "1",
        review_json: null,
        navs: [{ name: "General", dispcomp: Tabbuttonactive }, { name: "Scoring", dispcomp: Tabbutton }, { name: "Question", dispcomp: Tabbutton }]
    };

    defaultReviewJson = () => {
        const is_select = Store.getStoreData('env');
        let gc_company_id = is_select === 'select' ? Store.getStoreData('gc_companies')[0].id.toString() : "0";
        const review_json = {
            version:1,
            general: {
                template_id: 0,
                company_id: gc_company_id,
                name: "",
                description: "",
                config: {
                    conditional_questions_enabled: false,
                    guiadance_note_enabled: true,
                    is_active: true,
                    approval_process_enabled: false
                }
            },
            scoring: {
                scoring_enabled: true,
                scores: [
                    {
                        id: 1,
                        termname: "Fail",
                        actual_outcome: "Fail",
                        outcome_meanings: ["Pass", "Fail", "Indeterminate"],
                        lbound: 0,
                        ubound: 70,
                        custom: false,
                        color: "#ff0000"
                    },
                    {
                        id: 2,
                        termname: "Pass",
                        actual_outcome: "Pass",
                        outcome_meanings: ["Pass", "Fail", "Indeterminate"],
                        lbound: 71,
                        ubound: 100,
                        custom: false,
                        color: "#0ec50e"
                    }
                ],
                non_scores: [
                    {
                        id: 1,
                        label: "Green",
                        color: "#0EC50E"
                    }
                ]
            },
            sections: [
                {
                    id: Utils.genKey(6),
                    name: "Untitled section",
                    type: "label",
                    collapsed: true,
                    sectionna:false,
                    questions: [
                        {
                            id: Utils.genKey(6),   
                            collapsed: true,
                            question: "",
                            type: [
                                { value: "Memo", selected: false },
                                { value: "Boolean", selected: true },
                                { value: "Alpha", selected: false },
                                { value: "Numeric", selected: false },
                                { value: "Dropdown", selected: false },
                            ],                        
                            weight: [
                                { value: "0", selected: false },
                                { value: "1", selected: true },
                                { value: "2", selected: false },
                                { value: "3", selected: false },
                                { value: "4", selected: false },
                                { value: "5", selected: false },
                                { value: "6", selected: false },
                                { value: "7", selected: false },
                                { value: "8", selected: false },
                                { value: "9", selected: false },
                                { value: "10", selected: false }
                            ],
                            autofail: [
                                { value: "Yes", selected: false },
                                { value: "No", selected: true }
                            ],
                            naallowed: false,
                            comments: { enabled: false, required: false },
                            auto_scoring: { enabled: false, yes: 6, no: 0, hide_score: false },
                            guidance_note: { enabled: true, text: "" },
                            preset_answers: [],
                            answer: { text: "", comment: "" },
                            s_index: 1
                        }
                    ]
                }
            ]
        }
        return review_json;
    }

    componentDidMount() {
        let model_reviews = JSON.parse(JSON.stringify(Store.getStoreData('model_reviews')));
        const rid = Store.getStoreData('rid');
        const my_reviews = Store.getStoreData('my_reviews');
        const is_select = Store.getStoreData('env');
        let gc_company_id = is_select === 'select' ? Store.getStoreData('gc_companies')[0].id.toString() : "0";

        let na_questions_enabled = false;
        let na_section_enabled = false;
        if(Store.getStoreData('env') === "select") {
            let module_config = Store.getStoreData('module_config');
            if(Object.keys(module_config).length > 0) {
                na_questions_enabled = module_config.general.allow_na_questions_enabled;
                na_section_enabled = module_config.general.allow_na_section_enabled;
            } 
            
        }     

        // console.log('my_reviews', my_reviews);
        console.log('rid:', rid);
        // console.log("MODEL REVIEWS:", model_reviews);
        if (parseInt(Store.getStoreData('rid')) === 0) {
            const review_json = JSON.parse(JSON.stringify(this.defaultReviewJson()));
            // console.log("review_json", review_json.general)
            review_json.general['config'].allow_na_questions_enabled = na_questions_enabled;
            review_json.general['config'].allow_na_section_enabled = na_section_enabled;
            review_json.general['copy'] = this.props.copy;
            console.log("review_json new", review_json);
            this.setState({
                ready: true,
                selectedstarttype: "0",
                selected_gc_id: gc_company_id,
                review_json
            });
        } else {
            const current_review = my_reviews.find((item) => {
                return item.id === rid;
            })
            console.log("CURRENT_REVIEW:", current_review);
            let review_json = JSON.parse(current_review.review_json);
            review_json.general.company_id = current_review.company_id > 100000 ? current_review.company_id % 100000 : current_review.company_id;
            if("allow_na_questions_enabled" in review_json.general.config) {
                review_json.general['config'].allow_na_questions_enabled = review_json.general.config.allow_na_questions_enabled;
            }else{
                review_json.general['config'].allow_na_questions_enabled = na_questions_enabled;
            }
            review_json.general.config.approval_process_enabled = "approval_process_enabled" in review_json.general.config ? review_json.general.config.approval_process_enabled : false;

            if("allow_na_section_enabled" in review_json.general.config) {
                review_json.general['config'].allow_na_section_enabled = review_json.general.config.allow_na_section_enabled;
            }else{
                review_json.general['config'].allow_na_section_enabled = na_section_enabled;
            }
            review_json.general['copy'] = this.props.copy;
            if(this.props.copy) {
                review_json.general['copy_name'] = review_json.general.name;
                review_json.general.name = "";
                review_json.general.description = "";
            }

            let index = 1;
            review_json.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    question.s_index = index;
                    index = index + 1;
                })
            })
            gc_company_id = ('company_id' in review_json.general) ? review_json.general.company_id : gc_company_id;
            this.setState({
                ready: true,
                selectedstarttype: "0",
                selected_gc_id: gc_company_id,
                review_json
            });
        }
    }

    saveForLater = (event) => {
        event.preventDefault();
        if (this.state.name === "") {
            alert("Nothing to save");
            return false;
        }
        const selected_group_company = Store.getStoreData('gc_companies').find((company) => {
            return company.id === parseInt(this.state.selected_gc_id);
        })
        // console.log('this.state in add', this.state);
        let postData = {
            command: "save_my_review",
            name: this.state.name,
            description: this.state.description,
            questions: this.state.questions,
            resultconfigs: this.state.resultconfigs,
            openconfigs: this.state.openconfigs,
            rid: parseInt(Store.getStoreData('rid')),
            company_id: selected_group_company.id,
            is_gc: selected_group_company.is_gc,
            source: { id: 0 },
            mode: "save"
        };
        // console.log('POSTDATA', postData);
        let api = new APICall();
        api.command(postData, this.afterSubmit);

    }

    selectedTab = (index, navname) => (event) => {
        //console.log(index);
        let { navs } = this.state;
        navs.forEach((item) => {
            item.dispcomp = Tabbutton;
            if (item.name === navname) {
                item.dispcomp = Tabbuttonactive;
            }
        })
        this.setState({ isActive: index.toString(), navs })
        event.preventDefault();
    }

    handleallowQuestionChange = (event) => {
        console.log(event.target.value)
        this.setState({
            allowQuestion: event.target.value
        });
    }

    handleallowGuidanceChange = (event) => {
        console.log(event.target.value)
        this.setState({
            allowGuidance: event.target.value
        });
    }

    handleallowActiveChange = (event) => {
        console.log(event.target.value)
        this.setState({
            allowActive: event.target.value
        });
    }

    chooseTempalte = (review_id) => {
        let {review_json} = this.state;
        const company_id = JSON.parse(JSON.stringify(review_json.general.company_id));
        let model_reviews = Store.getStoreData('model_reviews').find((item) => {
            return item.id === parseInt(review_id);
        })
        if(parseInt(review_id) > 0) {
            review_json = JSON.parse(model_reviews.review_json);
            review_json.rid = 0;
            review_json.source = review_json;
        }else{
            review_json = JSON.parse(JSON.stringify(this.defaultReviewJson()));
        }
        review_json.general.template_id = parseInt(review_id);
        let na_questions_enabled = false;
        let na_section_enabled = false;
        if(Store.getStoreData('env') === "select") {
            let module_config = Store.getStoreData('module_config');
            if(Object.keys(module_config).length > 0) {
                na_questions_enabled = module_config.general.allow_na_questions_enabled;
                na_section_enabled = module_config.general.allow_na_section_enabled;
            } 
        }
        review_json.general['config'].allow_na_questions_enabled = na_questions_enabled;
        review_json.general['config'].allow_na_section_enabled = na_section_enabled;
        review_json.general.company_id = company_id;
        console.log("review_json", review_json);
        this.setState({review_json});
    }

    generalSave = (general) => {
        let {review_json} = this.state;
        review_json.sections.forEach((section) => {
            section.questions.forEach((question) => {
                question.guidance_note.enabled = general.config.guiadance_note_enabled;
                question.guidance_note.text = general.config.guiadance_note_enabled ? question.guidance_note.text : "";
            })
        })
        review_json.general = general;
        console.log("review_json:", review_json);
        this.setState({review_json});
    }

    modifyResultItem = (resultItem) => {
        let {review_json} = this.state;
        review_json.scoring.scores.forEach((item) => {
            if (item.id === resultItem.id) {
                item = resultItem;
            }
        })
        this.setState({review_json});
    }

    modifyResultItemList = (resultItemList) => {
        // this.setState({resultconfigs: resultItemList})
        let {review_json} = this.state;
        review_json.scoring.scores = resultItemList;
        this.setState({review_json});
    }

    modifyAllowScoring = (allowScoring) => {
        // this.setState({allow_scoring: allowScoring})
        let {review_json} = this.state;
        review_json.scoring.scoring_enabled = allowScoring;
        this.setState({review_json});
    }

    modifySections = (sections) => {
        let {review_json} = this.state;
        let index = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                console.log("modifySections questions::",question)
                
                index = index + 1;
                question.s_index = index;
                if("sectionna" in section && section.sectionna){
                    question.naallowed = section.sectionna;
                }
                
            })
        })
        console.log("modifySections sections::",sections)
        review_json.sections = sections;
        this.setState({review_json});
    }

    changeDisplay = (index, show) => {
        let {sections} = this.state;
        for(let i=0; i<sections.length; i++)
        {
            if(i === parseInt(index))
            {
                sections[i].type = show
            }
        }        
        this.setState({sections});     
    }

    changeSectionName = (index, value) => {
        //event.preventDefault();
        let {sections} = this.state;
        for(let i=0; i<sections.length; i++)
        {
            if(i === parseInt(index))
            {
                sections[i].name = value;
            }
        }        
        this.setState({sections});
    }

    modifyQuestion = (question, qindex, sindex) => {
        console.log(question, qindex, sindex);

        let {sections} = this.state;
        for(let i=0; i<sections.length; i++)
        {
            if(i === parseInt(sindex))
            {
                for(let j=0; j<sections[i].questions.length; j++)
                {
                    if(j === parseInt(qindex))
                    {
                        sections[i].questions[j] = question;
                    }
                }                
            }
        }        
        this.setState({sections});
    }

    saveReview = (event) => {
        event.preventDefault();
        let {review_json} = this.state;
        let qtext = true;
        if (review_json.general.name === "") {
            alert("Please provide a name for this file review type.");
            return false;
        }
        let q_text_alert = false;
        let guidance_alert = false;
        let alert_invalid_score = false;
        review_json.sections.forEach((section) => {
            section.questions.forEach((question) => {
                if (question.question === "") {
                    // alert("Question text is missing. Please complete this required information to progress.");
                    qtext = false;
                    q_text_alert = true;
                }
                if(question.guidance_note.enabled && question.guidance_note.text === "") {
                    // alert("Guidance Notes are required")
                    qtext = false;
                    guidance_alert = true;
                }
            })
        })
        if (q_text_alert && guidance_alert) {
            alert("All questions and guidance notes must be completed before submission.");
            return false;
        }
        if (q_text_alert) {
            alert("All questions must be completed before submission.");
            return false;
        }
        if (guidance_alert) {
            alert("All guidance notes must be completed before submission.");
            return false;
        }        
        if(review_json.scoring.scoring_enabled){
            review_json.scoring.scores.forEach((score) => {
                if(score.lbound === score.ubound || score.lbound > 100 || score.ubound > 100) {
                    // alert("Invalid Scoring!");
                    alert_invalid_score = true;
                    qtext = false;
                }
            })
        }
        if (alert_invalid_score) {
            alert("The scoring parameters are invalid, please revisit this section to amend.");
            return false;
        }
        review_json.rid = Store.getStoreData('rid');
        review_json.command = "save_model_review";
        review_json.source = {id: 0};
        if('copy' in review_json.general){
            if(review_json.general.copy) {
                if(review_json.general.name === review_json.general.copy_name) {
                     alert("Name must be unique.");
                     qtext = false;
                }
                review_json.rid = 0;

            }
        }
        if (!qtext) {
            return false;
        }
        
        review_json.sections.forEach((section) => {
            section.questions.forEach((question) => {
                if(question.guidance_note.enabled) {
                    //console.log(question.guidance_note.text)
                    let guidance_note = question.guidance_note.text;
                    if(!Utils.isBase64(guidance_note)){
                        guidance_note = btoa(window.encodeURIComponent(guidance_note));
                    }
                    //let guidance_note = btoa(window.encodeURIComponent(question.guidance_note.text));  
                    //console.log("guidance_note b64::",guidance_note)
                    question.guidance_note.text = guidance_note
                }
            })
        })
        if(parseInt(review_json.general.template_id) > 0) {
            review_json.source = JSON.parse(JSON.stringify(review_json));
            review_json.source.id = parseInt(review_json.general.template_id);
        }
        if(Store.getStoreData('env') === "select"){
            review_json.command = "save_my_review";
            // let is_gc = false;
            // Store.getStoreData('gc_companies').forEach((company) => {
            //     if (company.id === parseInt(review_json.general.company_id)) {
            //         is_gc = company.is_gc;
            //     }
            // })
            // review_json.is_gc = is_gc;
            const gc_assoc = Store.getStoreData('gc_assoc');
            review_json.is_gc = gc_assoc[(review_json.general.company_id).toString()].is_gc;
        }
        if('copy' in review_json.general){
            delete  review_json.general.copy_name;
            // delete  review_json.general.copy;
        }
        let api = new APICall();
        api.command(review_json, this.processIndexData);
        console.log("Save Review:===>", review_json);
    }

    processIndexData = (result) => {
        alert("The review type has been saved.");
        console.log("after save");
        window.location.reload(true);
    }

    closeModal = (event) => {
        event.preventDefault();
        if(Store.getStoreData('env') === "select"){
            Store.updateStore('MGRAddFileCheckModal', null);
        }else {
            this.props.closemodal();
        } 
        
    }

    modifyNonScoring = (scoring) => {
        let {review_json} = this.state;
        review_json.scoring = scoring;
        this.setState({review_json});

    }

    render()
    {
        if (!this.state.ready) {
            return (
                <MRModalContainer>
                    <MRModalHeader>
                        <NavIndicator>Setup</NavIndicator><NavIndicator><FaLock />Complete</NavIndicator><NavIndicator>Review</NavIndicator><NavIndicator>Quality / Assurance</NavIndicator>
                        <MRModalHeaderCloseBtn id="MRModalHeaderCloseBtn" onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{ clear: "both" }}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyInner>
                            <center><h2 style={{ fontSize: "18px", fontWeight: "600" }}>Loading...</h2></center>
                        </MRModalBodyInner>
                    </MRModalBody>
                    <MRModalFooter>
                        <MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                    </MRModalFooter>
                </MRModalContainer>
            );
        } else {
            return (
                <MRModalContainer>
                    <MRModalHeader>
                        <NavIndicatorActive>Setup</NavIndicatorActive><NavIndicator><FaLock /> Complete</NavIndicator><NavIndicator><FaLock /> Review</NavIndicator><NavIndicator><FaLock /> Quality / Assurance</NavIndicator>
                        <MRModalHeaderCloseBtn id="MRModalHeaderCloseBtn" onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{ clear: "both" }}></div>
                    </MRModalHeader>
                    {
                        <MRModalBody>
                            <MRModalBodyInner>
                                <Tabdiv>
                                    <Tabdiv1>
                                        {
                                            this.state.navs.map((item, index) => {
                                                let ind = index + 1;
                                                if (index === 0) {
                                                    return (<item.dispcomp key={index} onClick={this.selectedTab(ind, item.name)} style={{ marginTop: "0px" }}>{item.name} <IoIosArrowForward style={{ float: 'right', color: "#37ada7", marginTop: "4px" }} /></item.dispcomp>);
                                                } else {
                                                    return (<item.dispcomp key={index} onClick={this.selectedTab(ind, item.name)}>{item.name} <IoIosArrowForward style={{ float: 'right', color: "#37ada7", marginTop: "4px" }} /></item.dispcomp>);
                                                }
                                            })
                                        }
                                    </Tabdiv1>
                                    <Tabdiv2>          
                                    {
                                        (()=>{
                                            
                                            switch(this.state.isActive) {
                                                case '1' : return <MGRGeneral general={this.state.review_json.general} generalSave={this.generalSave} chooseTempalte={this.chooseTempalte}></MGRGeneral>;break
                                                case '2' : return <MGRScoring
                                                                    scoring={this.state.review_json.scoring} 
                                                                    modifyResultItem={this.modifyResultItem}
                                                                    modifyResultItemList={this.modifyResultItemList} 
                                                                    modifyAllowScoring={this.modifyAllowScoring}
                                                                    modifyNonScoring={this.modifyNonScoring}
                                                                  />; break
                                                case '3' : return <MGRQuestionsSection
                                                                    scoring={this.state.review_json.scoring}
                                                                    sections={this.state.review_json.sections} 
                                                                    allowGuidancevalue={this.state.review_json.general.config.guiadance_note_enabled}
                                                                    allowNaquestion={this.state.review_json.general.config.allow_na_questions_enabled}
                                                                    allowNasection={this.state.review_json.general.config.allow_na_section_enabled}
                                                                    changeDisplay={this.changeDisplay}
                                                                    changeSectionName={this.changeSectionName}
                                                                    modifyQuestion={this.modifyQuestion}
                                                                    modifySections={this.modifySections}
                                                                   />; break
                                                default : return <Tabdiv2><div>home</div></Tabdiv2>
                                            }
                                        })()
                                    }
                                        <div style={{padding: "30px"}}>
                                            <MRModalNextBtn onClick={this.saveReview}>Save</MRModalNextBtn>
                                            <MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                    </Tabdiv2>
                                    <div style={{ clear: "both" }}></div>
                                </Tabdiv>
                            </MRModalBodyInner>
                        </MRModalBody>
                    }
                </MRModalContainer>
            );
        }
    }
}

export default MGRAddFileCheckModal;