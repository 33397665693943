class EventBus {
    constructor() {
      this.events = {};
    }
  
    registerEvent(event_id, module_id, callback) {
      if(!(event_id in this.events))this.events[event_id] = {}
      this.events[event_id][module_id] = callback;
    }
    
    deregisterEvent(event_id, module_id) {
      delete this.events[event_id][module_id]
    }
    
    getEventsList(event_id) {
      console.log('events for id', event_id, this.events[event_id])
    }
    
    raiseEvent(event_id, value) {
      // console.log('event_id, value, this.events', event_id, value, this.events)
      if(!(event_id in this.events))return
    
      for(let module_id in this.events[event_id]) {
        this.events[event_id][module_id](value)
      }
    }
  }
  
  export default new EventBus();