import React from 'react';
import {FaRegCheckSquare, FaRegSquare} from "react-icons/fa";

class CompanyChanger extends React.Component
{
	state = {data: null};

	componentDidMount()
	{
		this.setState({data: this.props.data});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({data: this.props.data});
		}
	}

	changeCompany = (event) => {
		event.preventDefault();
		this.props.changeCompany(this.state.data);
	}

	render()
	{
		if (this.state.data === null) {
			return (<div>Loading...</div>);
		}
		if (this.state.data.selected) {
			return (
				<div style={{cursor: "pointer"}} onClick={this.changeCompany}><FaRegCheckSquare /></div>
			);
		} else {
			return (
				<div style={{cursor: "pointer"}} onClick={this.changeCompany}><FaRegSquare /></div>
			);
		}
	}
}

export default CompanyChanger;