import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import {FiDownload} from 'react-icons/fi'

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;
const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;

const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

const IndexContainer = styled.div`
    padding-top: 8px;
    padding-left: 5px;
`;

const HeadContainer = styled.div`
    display: block;
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
`;
const HomeLogo = styled.div`
    float: left;
    margin-top: 10px;
    font-size: 16px;
    color: #929292;
`;
const HomeText = styled.div`
    float: left;
    margin-top: 10px;
    color: #c3c1c1;
    margin-left: 10px;
    font-size: 13px;
`;
const Categories = styled.div`
    float: right;
    font-size: 17px;
    background-color: #ebebeb;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    margin-top: 4px;
    padding-left: 4px;
    padding-top: 3px;
    border-radius: 2px;
`;

const BannerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
`;
const Banner = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #37ada7;
    border-radius: 4px;
    height: 40px;
    width: 100%;
`;
const WelcomeText = styled.div`
    float: left;
    color: #ffffff;
    font-weight: 600;
    padding-top: 11px;
    padding-left: 20px;
`;

const AttachmentLink = styled.input.attrs({ 
  type: 'file'
})`
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
`;

// const AttachmentLink = styled.a`
//     font-size: 12px;
//     font-weight: 600;
//     color: #BEC2C5;
//     cursor: pointer;
// `;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 75%;
    position: absolute;
    margin: 0 0;
    top: 8px;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;
const ModalHeaderText = styled.div`
    float: left;
    box-sizing: border-box;
    margin-top: 16px;
    margin-left: 20px;
    color: #949494;
`;
const ModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    color: #979797;
`;
const ModalTextAreaInvisible = styled.textarea`
    height: 150px;
    width: 100%;
    border: none;
    border-radius: 3px;
    background-color:#ffffff;
    resize: none;
    padding: 5px;
    color: #979797;
`;
const ModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #f7f7f7;
    padding: 10px 20px 10px 20px;
`;
const ModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #f7f7f7;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;



const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:7px;
`;
const ModalHeaderCloseBtn = styled.div`
    float: right;
    box-sizing: border-box;
    margin-top: 16px;
    margin-right: 20px;
    color: #949494;
    font-size: 16px;
    cursor: pointer;
`;
const ModalContainertop = styled.div`
    display: grid;
    grid-template-columns: 20% calc(80% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const SingleRowColumnContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 20px;
`;
const ModalShortDatesContainer = styled.div`
    display: grid;
    grid-template-columns: 25% calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-gap: 15px;
    padding: 20px;
`;
const ModalDateDiv = styled.div`
    display: block;
    width: 100%;
    height: auto;
`;
const ModalDateLabel = styled.label`
    font-weight: 600;
    color: #979797;
`;
const ModalDateInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;


const ModalLabelDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModaInputDiv = styled.div`
    height: auto;
    width: 100%;
`;
const ModalWiderDiv = styled.div`
    height: auto;
    padding-left: 21px;
    padding-right: 21px;
    width: 100%;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const ModalWiderHeaderDiv = styled.div`
    border-radius: 4px 4px 0px 0px;
    background-color: #04ACA7;
    width:100%;
    height:40px;
    min-height:40px;
`
const ModalClearDiv = styled.div`
    border-radius: 3px 3px 0px 0px;
    width:100%;
    height:10px;
    min-height:30px;
`
const ModalWiderLineDiv = styled.div`
    background-color: #E4E4E4;
    padding-left: 21px;
    padding-right: 21px;
    width:100%;
    height:3px;
    min-height:3px;
`
const ModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;

const CancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
`;
const CancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
`;
const RejectButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #E65164;
    color: #ffffff;
    border: 2px solid #EA5A6A;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-right:5px;
`;
const ClarifyButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #D5D5D5;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    margin-right:5px;
`;
const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
`;


export { SubmitButton, CancelButton, CancelSubmitButtonOuterContainer, ModalInput, ModalWiderLineDiv, ModalClearDiv, ModalWiderHeaderDiv,
		 MRModalSelect, ModalWiderDiv, ModaInputDiv, ModalLabelDiv, ModalDateInput, ModalDateLabel, ModalShortDatesContainer, ModalContainertop,
		 ModalHeaderCloseBtn, ModalNameInputDiv, ModalNameLabelDiv, ModalBodyInner, ModalBody, ModalTextArea, ModalHeaderText, ModalContainer,
		 AttachmentLink, FileTypeContainer, FileInfoContainer, FileContainer, ModalTextAreaInvisible, RejectButton, ClarifyButton, BannerContainer,
         Banner, WelcomeText, HomeText, HomeLogo, HeadContainer, IndexContainer, Categories, NoPermission, InactiveOverlay }

