import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import CompanyChanger from './CompanyChanger';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import * as jsPDF from 'jspdf'
import Iframe from 'react-iframe'

const marginX = 13;
const marginY = 20;
const maxX = 210 - (2 * marginX);
const maxY = 297 - (2 * marginY);
const footer_height = 15;
const para_gap = 9;
const line_height = 12;
const paraIndent = 25;
const paraGap = 35;
const columnLeftFirst = 30;
const columnLeftSecond = 70;
const columnLeftThird = 155;
const columnLeftFourth = 166;
const columnLeftFifth = 177;
const columnLeftSixth = 188;
const columnLeftSeventh = 200;
const lineHeight = 6;
const pageBottom = 255;
const pageRight = 185;
var chart_bar_unit = 1;
class AnswerAnalysis extends React.Component
{
	state = {faPDF : null, pdfURL: null, pageWidth: maxX, pageHeight: maxY, ready: false};
	//, fc_total:0
	drawHeader = () =>{
		var actionArea = maxX;
		var leftOffset = (actionArea - this.getTextWidth(Store.getStoreData('parent_company').company_name)) / 2;	
        this.faPDF.setFontSize(30);
		this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		this.faPDF.text(Store.getStoreData('parent_company').company_name, leftOffset -15, this.current_top);
		this.current_top += 30;
		this.faPDF.setFontSize(20);
		leftOffset = (actionArea - this.getTextWidth('Answer Analysis Report')) / 2;
		this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Answer Analysis Report",  marginX +leftOffset , this.current_top);
		this.current_top += 30;
	}
	getTextWidth = (text) => {
		var textWidth = this.faPDF.getTextWidth(text)
		var wordGap = this.faPDF.getTextWidth(' ')
		return (parseInt(textWidth) + parseInt(wordGap));
	}
	drawOverview = () =>{
		var loggedInConatct = Store.getStoreData('loggedin_user_info').ContactName
		this.footerSection();
		this.faPDF.setFontSize(14);
		this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Overview :", this.currentRight , this.current_top);
		this.current_top += line_height;
		this.faPDF.setFontSize(13);
		this.faPDF.setTextColor('#444444');
		this.faPDF.setFontStyle('normal');
		this.faPDF.text("This Answer Analysis Report was run by ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('This Answer Analysis Report was run by ');
		this.faPDF.text(loggedInConatct + ' ', this.currentRight , this.current_top);
        this.currentRight += this.getTextWidth(loggedInConatct + ' ');
        this.faPDF.text('at '+ this.findDateAndTime('').time + ' on ' + this.findDateAndTime('').date, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.currentRight = marginX;
		this.faPDF.text("The following filters were applied", this.currentRight , this.current_top);
		this.current_top += line_height;
		this.currentRight = marginX + paraIndent;
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Date filters applied : ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('Date filters applied : ');
		this.faPDF.setFontStyle('normal');
		this.faPDF.text("Start Date ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('Start Date ');
		this.faPDF.text(this.props.AnswerData.startDate + ', ', this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth(this.props.AnswerData.startDate + ', ');
		this.faPDF.text("End Date ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('End Date ');
		this.faPDF.text(this.props.AnswerData.endDate, this.currentRight , this.current_top);
        var reviewer_name = this.props.AnswerData.reviewer_name == undefined ? 'All' : this.props.AnswerData.reviewer_name;
        var question_type = this.props.AnswerData.question_type == 0 ? 'All' : this.props.AnswerData.question_type;
		this.current_top += line_height;
		this.currentRight = marginX + paraIndent;
		this.faPDF.setFontStyle('bold');
		this.faPDF.text("Filters applied : ", this.currentRight , this.current_top);
		this.currentRight += this.getTextWidth('Filters applied : ');
		this.faPDF.setFontStyle('normal');
		this.faPDF.text("Division : " + this.props.AnswerData.division, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.faPDF.text("Reviewer : " + reviewer_name, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.faPDF.text("Question Set : " + question_type, this.currentRight , this.current_top);
		this.current_top += line_height;
		this.currentRight = marginX;
		var answer_analysis_data = this.props.AnswerData.answerAnalysisData === undefined ? [] : this.props.AnswerData.answerAnalysisData;
		var totalFileAuditCount =  parseInt(answer_analysis_data[0].yes_count) + parseInt(answer_analysis_data[0].no_count) + parseInt(answer_analysis_data[0].na_count);
		//var totalFileAuditCount =  this.props.AnswerData.reviewee_analysis_data.length;
		if (isNaN(totalFileAuditCount)) totalFileAuditCount = 0;
		this.faPDF.text(totalFileAuditCount.toString() + " File Reviews were applicable to the report at run time", this.currentRight , this.current_top);
		this.faPDF.addPage();
        this.footerSection();
        this.currentRight = marginX;
        this.current_top = marginY;
	}
	processChartView(loop_start) {
		var answer_analysis_data = this.props.AnswerData.answerAnalysisData === undefined ? [] : this.props.AnswerData.answerAnalysisData;
        //console.log("answer_analysis_data123", answer_analysis_data)
		const vert_unit = 7;
		var   horiz_unit = 10;
		var   step = 1;
		const origin = 0;
		//const fc_total = parseInt(answer_analysis_data[0].yes_count) + parseInt(answer_analysis_data[0].no_count) + parseInt(answer_analysis_data[0].na_count);
		const fc_performed = this.props.AnswerData.reviewee_analysis_data.length;
		//const fc_performed = fc_total;
		//this.setState({fc_total:fc_performed});
		//const fc_performed = 66;
		console.log("fc_performed::",fc_performed);
		if(fc_performed <= 10){
			step = 1;
		}else if(fc_performed <= 20){
			step = 2;
		}else if(fc_performed <= 50){
			step = 5;
		}else if(fc_performed <= 100){
			step = 10;
		}else if(fc_performed <= 200){
			step = 20;
		}else if(fc_performed <= 500){
			step = 50;
		}else if(fc_performed <= 1000){
			step = 100;
		}else if(fc_performed <= 2000){
			step = 200;
		}else if(fc_performed <= 5000){
			step = 500;
		}else if(fc_performed <= 10000){
			step = 1000;
		}else{
			step =10000;
		}
		//var horizontal_axis_count = Math.round(fc_performed/step);
		var horizontal_axis_count = Math.ceil(fc_performed/step);
		var gap = 70/horizontal_axis_count
		//const vert_unit_forRect = 70/fc_performed;
		// let vert_unit_forRect = 70/fc_performed

		// if(fc_performed > 70 ){
		// 	vert_unit_forRect = 70/(fc_performed+step-5);
		// }

		console.log("dimension step::",step)	
		console.log("dimension horizontal_axis_count::",horizontal_axis_count)		
		console.log("dimension gap::",gap)		
		//console.log("dimension vert_unit_forRect::",vert_unit_forRect)

		this.currentRight = marginX;
		if(loop_start == undefined){
			this.faPDF.setFontStyle('bold');
			this.faPDF.setFontSize(13);
	        this.faPDF.setTextColor('#2E466F');
	        this.faPDF.text("Chart View",this.currentRight , this.current_top);
	        this.current_top += (line_height-3);
		}
		console.log("answer_analysis_data.length", answer_analysis_data.length)
		if(this.props.AnswerData.reviewee_analysis_data.length == 0){
			this.faPDF.setTextColor('#636161')
			this.faPDF.setFontSize(11)
        	this.faPDF.text('No File Checks qualified for this report.', this.currentRight +50, this.current_top);
        	this.current_top += para_gap;
        	this.current_top += para_gap;
        	return;
		}
       	this.current_top += lineHeight;
       	if((this.current_top + 10 * vert_unit + 20) > 270){
			this.faPDF.addPage();
	        this.footerSection();
	        this.current_top = marginY;
		}
		this.current_top += 7;
        //draw axix
		this.faPDF.line(this.currentRight, this.current_top, this.currentRight, this.current_top + 10 * vert_unit, "F");//y		
		this.faPDF.line(this.currentRight, this.current_top + 10 * vert_unit, columnLeftSeventh, this.current_top + 10 * vert_unit , "F");//x
		//draw sub ordiante line
		if(step <10000){
			this.faPDF.setDrawColor('#DCDCDC')
			
			for(let i=0; i<horizontal_axis_count; i++){
				this.faPDF.line(this.currentRight, this.current_top +(i*gap) , columnLeftSeventh, this.current_top +(i*gap) , "F");
			}
			this.faPDF.setDrawColor('#474747');
			
			for(let j=0; j<horizontal_axis_count; j++){
				this.faPDF.line(this.currentRight -2, this.current_top +(j*gap), this.currentRight, this.current_top +(j*gap) , "F");
			} 
			
			this.faPDF.setFontStyle('normal');
			this.faPDF.setFontSize('9');
			var index = [];
			for(let m=horizontal_axis_count; m >= 0; m--){
				console.log("for m::",m," step::",step," index::",(step * m).toString())
				index.push((step * m).toString())
			}
			console.log("num:: index",index)
			

			for(let k=0; k<= horizontal_axis_count; k++){
				this.faPDF.text(index[k].toString(),this.currentRight -3-this.faPDF.getTextWidth(index[k].toString()), this.current_top +(k*gap)+1);
			} 
						
			//this.faPDF.text(origin.toString(), this.currentRight -4,  (this.current_top + 10 * vert_unit)+1);
		}else{
			this.faPDF.setDrawColor('#DCDCDC');
			this.faPDF.line(this.currentRight, this.current_top+2 , columnLeftSeventh, this.current_top+2 , "F");
			this.faPDF.setDrawColor('#474747');
			this.faPDF.line(this.currentRight -2, this.current_top+2, this.currentRight, this.current_top+2 , "F");
			this.faPDF.setFontStyle('normal');
			this.faPDF.setFontSize('9');
			this.faPDF.text(fc_performed.toString(),this.currentRight -3-this.faPDF.getTextWidth(fc_performed.toString()), this.current_top +2);
		}
		//draw horizontal scale
		this.currentRight = this.currentRight + horiz_unit
        loop_start = loop_start == undefined ? 0 : loop_start
		
		for(let i = loop_start; i < answer_analysis_data.length; i++){
			var loop_end = 30 * chart_bar_unit;
			if( i === loop_end){
				chart_bar_unit ++;
				this.current_top += (10 * vert_unit) + para_gap ;
				this.processChartView(i);
				return;
			}
			var yesCount = 0 ;
	        var noCount = 0 ;
	        var naCount = 0 ;
	        var totalCount = 0 ;

	        totalCount = parseInt(answer_analysis_data[i].yes_count) + parseInt(answer_analysis_data[i].no_count) + parseInt(answer_analysis_data[i].na_count);
            console.log("totalCount::",totalCount) 
            
            if(totalCount != 0){
            	if(step <10000){
            		yesCount =  parseInt(answer_analysis_data[i].yes_count);
			        noCount  =  parseInt(answer_analysis_data[i].no_count);
			        naCount  =  parseInt(answer_analysis_data[i].na_count);
            	}else{
            		yesCount = (parseInt(answer_analysis_data[i].yes_count)/totalCount) * 10;
			        noCount =  (parseInt(answer_analysis_data[i].no_count)/totalCount) * 10;
			        naCount =  (parseInt(answer_analysis_data[i].na_count)/totalCount) * 10;
            	} 
            
			let vert_unit_forRect = 70/fc_performed;

			// if(fc_performed > 100 ){
			// 	vert_unit_forRect = 100/(fc_performed+step-400);
			// }else if(fc_performed === index[0]){
			// 	vert_unit_forRect = 70/fc_performed
			// }
			//vert_unit_forRect = 0.28
			

			// console.log("dimension vert_unit_forRect::",vert_unit_forRect)
            // console.log("num:: totalCount::",totalCount)
			// console.log("num:: totalCount index::",index[0])
			
			//totalCount += 1;
			let num = 0;
			if(totalCount <= 10){
				num = 70;
			}else if(totalCount <= 20){
				console.log("num::",1)
				if(totalCount === parseInt(index[0])){
					console.log("num::",1.2)
					num = 70;
				}else{
					console.log("num::",1.3)
					//num = totalCount%5?67:70;
					num = (totalCount/parseInt(index[0]))*70;
				}
				
			}else{
				//num = 67;
				num = (fc_performed/parseInt(index[0]))*70;
			}
			
			// console.log("nummmmm::",num)
			// console.log("nummmmm::",70-num)
			// console.log("nummmmm::",(parseInt(index[0]) - totalCount) * (70/100))
			//return;
			let height_arr = this.heightCalc(yesCount,noCount,naCount,num,totalCount);
			//console.log("height_arr::",height_arr)
			//return
            this.faPDF.setFontSize(7)
			this.faPDF.line(this.currentRight - 6 , this.current_top + 10 * vert_unit, this.currentRight - 6 , this.current_top + 10 * vert_unit + 2, "F");
			this.faPDF.text('Q' + (i+1).toString(), this.currentRight - 8,  (this.current_top + 10 * vert_unit)+5);
	  		var yesHeight = 0;
  			var noHeight = 0;
  			var naHeight = 0;
  			var yesStart = 0;
  			var noStart = 0;
  			var naStart = 0;

			// console.log("vertical::current_top::",this.current_top)
			// console.log("vertical::vert_unit::",vert_unit)
			// console.log("vertical::::",(this.current_top + 10 * vert_unit))
			// console.log("vertical::vert_unit_forRect::",vert_unit_forRect)
			// console.log("vertical::totalCount::",totalCount)
			//this.faPDF.text("AAA",this.currentRight -3-this.faPDF.getTextWidth(fc_performed.toString()), 42.2);
			//this.faPDF.text("BBB",this.currentRight -3-this.faPDF.getTextWidth(fc_performed.toString()), 132.97);
			//return;	
	  		if(step <10000){
	  			//  yesHeight = yesCount * vert_unit_forRect;
	  			//  noHeight = noCount * vert_unit_forRect;
	  			//  naHeight = naCount * vert_unit_forRect;
				 
				yesHeight = height_arr['yes_height'];
				noHeight = height_arr['no_height'];
				naHeight = height_arr['na_height'];
				
				// if(totalCount <= 10){
				// 	yesStart = (this.current_top + 10 * vert_unit) - (vert_unit_forRect*totalCount)+0.2;
				// }else{
				// 	yesStart = (this.current_top + 10 * vert_unit) - (vert_unit_forRect*totalCount)+3;
				// }

				// else if(totalCount <= 20){
				// 	yesStart = (this.current_top + 10 * vert_unit) - (vert_unit_forRect*totalCount)+3;
				// }
				if(answer_analysis_data.length <= 30){
					//console.log("start::","<30>")
					//yesStart = this.current_top;
					yesStart = (this.current_top + 10 * vert_unit) - (vert_unit_forRect*totalCount) + (70 - num)
					//(this.current_top + 10 * vert_unit) - (vert_unit_forRect*totalCount)-(index.length)
				}else{
					if(num === 70){
						//console.log("start::","70")
						yesStart = (this.current_top + 10 * vert_unit) - (vert_unit_forRect*totalCount) + 0.2;
					}else{
						//console.log("start::","67")
						yesStart = (this.current_top + 10 * vert_unit) - (vert_unit_forRect*totalCount) + (70 - num)
					}
				}

				
				//console.log("vertical::yesStart::",yesStart)
	  			noStart = yesStart + yesHeight;
	  			naStart = noStart + noHeight
	  		}else{
	  		     yesHeight = yesCount * 6.8;
		  	     noHeight = noCount * 6.8;
		  	     naHeight = naCount * 6.8;

		  	     yesStart = this.current_top + 2 -0.2;
		  	     noStart = yesStart + yesHeight;
		  	     naStart = noStart + noHeight
	  		}
			console.log("tot yes::",yesHeight+noHeight+naHeight)
	  		console.log("dimension yesCount::",yesCount)
			console.log("dimension yesHeight::",yesHeight)
			console.log("dimension yesStart::",yesStart)

			console.log("dimension noCount::",noCount)
			console.log("dimension noHeight::",noHeight)
			console.log("dimension noStart::",noStart)

			console.log("dimension naCount::",naCount) 
			console.log("dimension naHeight::",naHeight)
			console.log("dimension naStart::",naStart)


			
	 		this.faPDF.setFillColor('#008000');
	 		this.faPDF.rect(this.currentRight - 8 ,yesStart, 4 , yesHeight, 'F');
	      
	        this.faPDF.setFillColor('#FF0000');
	 		this.faPDF.rect(this.currentRight - 8 , noStart, 4 , noHeight, 'F');
	        
	        this.faPDF.setFillColor('#FFFF00');
	 		this.faPDF.rect(this.currentRight - 8 , naStart, 4 , naHeight, 'F');
	        this.currentRight = this.currentRight + 6.2;      
		}
		}
		//this.current_top += para_gap;
		
		this.current_top += (10 * vert_unit) + 2 * para_gap;
		this.faPDF.setFillColor('#008000');
	    this.faPDF.rect(marginX , this.current_top, 6 , 4, 'F');
	    this.faPDF.setFontStyle('bold');
	    this.faPDF.text('Yes', marginX + 8 , this.current_top +3)

	    this.faPDF.setFillColor('#FF0000');
	    this.faPDF.rect(marginX+18 , this.current_top, 6 , 4, 'F');
	    this.faPDF.setFontStyle('bold');
	    this.faPDF.text('No', marginX + 26 , this.current_top +3)

	    this.faPDF.setFillColor('#FFFF00');
	    this.faPDF.rect(marginX+36 , this.current_top, 6 , 4, 'F');
	    this.faPDF.setFontStyle('bold');
	    this.faPDF.text('NA', marginX + 44 , this.current_top +3)

	    this.current_top += para_gap * 4;
		return;
	}
	processLine (content, cur_right, currenttop, maxRight) {
		const right_start = cur_right
	    const line_height = 4;
	    if(content != ''){
	        var word_split = content.replace(/\n/g,"") .split(" ");	        
	         for(let i = 0 ; i< word_split.length ; i++){
	            var wordLenght = this.faPDF.getTextWidth(word_split[i]);
	            var gapLenght = this.faPDF.getTextWidth(" ");
	            if(cur_right + wordLenght + gapLenght< maxRight)
	            {
	                this.faPDF.setTextColor('#4C4C4C');
	                this.faPDF.setFontStyle('normal');
	                this.faPDF.text(word_split[i], cur_right, currenttop+4);
	                cur_right += (wordLenght + gapLenght) 
	            }
	            else
	            {
	                currenttop += line_height;
	                cur_right = right_start;
	                this.faPDF.setTextColor('#4C4C4C');
	                this.faPDF.setFontStyle('normal');
	                this.faPDF.text(word_split[i], cur_right, currenttop+4);
	                cur_right += (wordLenght + gapLenght);
	            }
	         }
	    }
	    return currenttop;
	}
	getLineNumber(content, cur_right, maxRight){
	   	const right_start = cur_right
   	   	const line_height = 4;
   	   	var sentence_number = 1;

	    if(content != ''){
	        var word_split = content.replace(/\n/g,"") .split(" ");	        
	         for(let i = 0 ; i< word_split.length ; i++){
	            var wordLenght = this.faPDF.getTextWidth(word_split[i]);
	            var gapLenght = this.faPDF.getTextWidth(" ");
	            if(cur_right + wordLenght + gapLenght< maxRight)
	            {
	                cur_right += (wordLenght + gapLenght) 
	            }
	            else
	            {
	                cur_right = right_start;           
	                cur_right += (wordLenght + gapLenght);
	                sentence_number ++;
	            }
	         }
	    }
	    return sentence_number;
	}
	processAnswerDetails = () => {
		var answer_analysis_data = this.props.AnswerData.answerAnalysisData === undefined ? [] : this.props.AnswerData.answerAnalysisData;
		var line_height = 5;
  		if((this.current_top + 2 * line_height) > 270){
			this.faPDF.addPage();
	        this.footerSection();
	       
	        this.current_top = marginY;
		}
		this.currentRight = marginX;
        this.faPDF.setTextColor('#2E466F')
        this.faPDF.text("Answer Detail",this.currentRight , this.current_top);
        this.current_top += line_height;
        this.current_top += lineHeight;
		if(this.props.AnswerData.reviewee_analysis_data.length == 0){
			this.faPDF.setTextColor('#636161');
			this.faPDF.setFontSize(11);
        	this.faPDF.text('No File Checks qualified for this report.', this.currentRight +50, this.current_top);
        	this.current_top += para_gap;
        	this.current_top += para_gap;
        	return;
		}
        this.faPDF.setLineWidth(0.3);
        this.faPDF.setFillColor('#D9E2F3');
        this.faPDF.rect(this.currentRight, this.current_top, pageRight, 2 *line_height, 'FD');
        this.faPDF.setDrawColor('#474747');
       

        this.faPDF.line(columnLeftFirst, this.current_top, columnLeftFirst, this.current_top + 2 * line_height, "F");
        this.faPDF.line(columnLeftSecond, this.current_top, columnLeftSecond, this.current_top + 2 * line_height, "F");
        this.faPDF.line(columnLeftThird, this.current_top, columnLeftThird, this.current_top + 2 * line_height, "F");
        this.faPDF.line(columnLeftFourth, this.current_top, columnLeftFourth, this.current_top + 2 * line_height, "F");
        this.faPDF.line(columnLeftFifth, this.current_top, columnLeftFifth, this.current_top + 2 * line_height, "F");
        this.faPDF.line(columnLeftSixth, this.current_top, columnLeftSixth, this.current_top + 2 * line_height, "F");
        this.faPDF.setTextColor('#444444');
        this.faPDF.setFontSize(9);
        this.faPDF.setFontStyle('bold');
        this.current_top += 4;
        this.faPDF.text('Question', this.currentRight+1, this.current_top);
        this.faPDF.text('Number', this.currentRight+1, this.current_top +3);

        this.faPDF.text('Section', columnLeftFirst+1, this.current_top);
        this.faPDF.text('Question', columnLeftSecond+1, this.current_top);
        this.faPDF.text('Yes', columnLeftThird+1, this.current_top);
        this.faPDF.text('No', columnLeftFourth+1, this.current_top);
        this.faPDF.text('N/A', columnLeftFifth+1, this.current_top);
        this.faPDF.text('Total', columnLeftSixth+1, this.current_top);
        this.current_top += 4;
        
        for(let i = 0; i < answer_analysis_data.length; i++){   	
        	var line_number = 1;
        	line_number = this.getLineNumber(answer_analysis_data[i].question_text.toString(),columnLeftSecond+1,columnLeftThird);

        	if(this.current_top + (line_number * line_height)> 270){
				this.faPDF.addPage();
		        this.footerSection();
		        this.currentRight = marginX;
		        this.current_top = marginY;
		        this.faPDF.setFontSize(9);
        	}
        	//Rectange
        	this.faPDF.setLineWidth(0.3);
	       	this.faPDF.setFillColor('#FFFFFF');
	        this.faPDF.rect(this.currentRight, this.current_top, pageRight, line_number * line_height, 'FD');
	        this.faPDF.setDrawColor('#474747');
	        
	        //Lines
	        this.faPDF.line(columnLeftFirst, this.current_top, columnLeftFirst, this.current_top + line_number * line_height, "F");
	        this.faPDF.line(columnLeftSecond, this.current_top, columnLeftSecond, this.current_top + line_number * line_height, "F");
	        this.faPDF.line(columnLeftThird, this.current_top, columnLeftThird, this.current_top + line_number * line_height, "F");
	        this.faPDF.line(columnLeftFourth, this.current_top, columnLeftFourth, this.current_top + line_number * line_height, "F");
	        this.faPDF.line(columnLeftFifth, this.current_top, columnLeftFifth, this.current_top + line_number * line_height, "F");
	        this.faPDF.line(columnLeftSixth, this.current_top, columnLeftSixth, this.current_top + line_number * line_height, "F");
	        this.faPDF.setLineWidth(0.3);
	       	this.faPDF.setFillColor('#2F5496');
	        this.faPDF.rect(this.currentRight, this.current_top, 17, line_number * line_height, 'FD');
	        //Data Entry
	        var total_count = parseInt(answer_analysis_data[i].yes_count) + parseInt(answer_analysis_data[i].no_count) + parseInt(answer_analysis_data[i].na_count);  
	        this.faPDF.setDrawColor('#474747');	
	        this.faPDF.setTextColor('#FFFFFF');
	        this.faPDF.text(answer_analysis_data[i].question_no.toString(), this.currentRight+6, this.current_top+4);
	        this.faPDF.setTextColor('#444444');
	        this.faPDF.setFontStyle('normal');
	        this.processLine(answer_analysis_data[i].section_name.toString(), columnLeftFirst+1,this.current_top, columnLeftSecond);
	        this.processLine(answer_analysis_data[i].question_text.toString(), columnLeftSecond+1,this.current_top, columnLeftThird);
	        this.faPDF.text(answer_analysis_data[i].yes_count.toString(), columnLeftThird+1, this.current_top+4);
	        this.faPDF.text(answer_analysis_data[i].no_count.toString(), columnLeftFourth+1, this.current_top+4);
	        this.faPDF.text(answer_analysis_data[i].na_count.toString(), columnLeftFifth+1, this.current_top+4);
	        this.faPDF.text(total_count.toString(), columnLeftSixth+1, this.current_top+4);
	        this.current_top += (line_number * line_height)
        }
	}
	footerSection = () =>{
		var footerTop = (297-marginY);
		this.faPDF.line(marginX, footerTop, (210 - marginX), footerTop);
		this.faPDF.setLineWidth(0.5); 
		this.faPDF.setDrawColor('#444444');
		footerTop += line_height-2;
		this.faPDF.setFontSize(12);
		this.faPDF.setFontStyle('bold');
		this.faPDF.setTextColor('#636161')
		this.faPDF.text("Answer Analysis Report", 88 , footerTop);
	}
	processPageFooters = () => {
        var totalPages = this.faPDF.internal.getNumberOfPages();
        for (var i = 1; i <= totalPages; i++) {
        	this.faPDF.setTextColor('#2E466F')
            this.faPDF.setPage(i);
            this.faPDF.setFontStyle('normal')
            this.faPDF.text("© Compliancy Services Ltd", this.faPDF.internal.pageSize.getWidth() - 195, this.faPDF.internal.pageSize.getHeight() - 10);
            this.faPDF.text("Page " + i + " of " + totalPages, this.faPDF.internal.pageSize.getWidth() - 40, this.faPDF.internal.pageSize.getHeight() - 10);
        }
    }
    processRevieweeAnalysisData =() =>{
    	this.current_top += para_gap;
    	//this.current_top += para_gap;
    	if(this.props.AnswerData.reviewee_analysis_data.length > 0){
    		this.current_top += para_gap;
    	}
    	var reviewee_analysis_data = this.props.AnswerData.reviewee_analysis_data;
    	console.log("reviewee_analysis_data", reviewee_analysis_data)
    	var line_height = 4;
    	const column_one = 30;
    	const column_two = 150;
    	const column_three = 175;
		
	
  		this.faPDF.setFontSize(12);
  		this.faPDF.setTextColor('#2E466F')
        this.faPDF.setFontStyle('bold')
        this.faPDF.text("Reviewee",this.currentRight , this.current_top);
        this.current_top += line_height;
        if(this.props.AnswerData.reviewee_analysis_data.length == 0){
			this.faPDF.setTextColor('#636161')
			this.faPDF.setFontSize(11);
        	this.faPDF.text('No File Checks qualified for this report.', this.currentRight +50, this.current_top);
        	this.current_top += para_gap;
        	this.current_top += para_gap;
        	return;
		}
        this.currentRight = marginX;
        this.current_top += lineHeight;
        this.faPDF.setFontSize(9);
        this.faPDF.setLineWidth(0.3);
        this.faPDF.setFillColor('#D9E2F3');
        this.faPDF.rect(this.currentRight, this.current_top, pageRight, 2 *line_height, 'FD');
        this.faPDF.setDrawColor('#474747');
       
        this.faPDF.line(column_one, this.current_top, column_one, this.current_top + 2 * line_height, "F");
        this.faPDF.line(column_two, this.current_top, column_two, this.current_top + 2 * line_height, "F");
        this.faPDF.line(column_three, this.current_top, column_three, this.current_top + 2 * line_height, "F");
        
        this.faPDF.setTextColor('#444444');
        this.faPDF.setFontSize(11);
        //this.faPDF.setFontStyle('bold');
        
        this.faPDF.text('Reviewee', column_one +1 , this.current_top +4);
        this.faPDF.text('Score', column_two +1 , this.current_top +4);
        this.faPDF.text('%', column_three +1 , this.current_top +4);
        this.current_top += 2 * line_height;
        var count = 0
		
		var reviewee_analysis_data_sort = reviewee_analysis_data.sort((a,b)=> (a.reviewee_name.localeCompare(b.reviewee_name) || a.percentage - b.percentage )  );
		
        for(let i = 0 ; i < reviewee_analysis_data.length ; i++){
        	if((this.current_top + 2 * line_height) > 260){
				this.faPDF.addPage();
		        this.footerSection();
		        this.currentRight = marginX;
		        this.current_top = marginY;
			}
        	var percentage = typeof reviewee_analysis_data[i]?.percentage === 'undefined' ? "0" : reviewee_analysis_data[i]?.percentage.toString();
        	
        	var percentage_final = percentage;
        	if(percentage.includes(".")){
        		var percentage_split = percentage.split('.');
	        	var percentage_bd = percentage_split[0];
	        	var percentage_ad = percentage_split[1];
	        	if(percentage_ad.length > 2){
	        		percentage_final = percentage_bd + '.' + percentage_ad.charAt(0)+percentage_ad.charAt(1) 
	        	}
        	}
        	
        	count++;
        	this.faPDF.setLineWidth(0.3);
	        this.faPDF.setFillColor('#FFFFFF');
	        this.faPDF.setFontSize(9);
	        this.faPDF.setFontStyle('normal')
	        this.faPDF.rect(this.currentRight, this.current_top, pageRight, 2 *line_height, 'FD');
	        this.faPDF.setDrawColor('#474747');
	       
	        this.faPDF.line(column_one, this.current_top, column_one, this.current_top + 2 * line_height, "F");
	        this.faPDF.line(column_two, this.current_top, column_two, this.current_top + 2 * line_height, "F");
	        this.faPDF.line(column_three, this.current_top, column_three, this.current_top + 2 * line_height, "F");

	        this.faPDF.setLineWidth(0.3);
	        this.faPDF.setFillColor('#2F5496');
	        this.faPDF.rect(this.currentRight, this.current_top, 17, 2 * line_height, 'FD');
            
            this.faPDF.setTextColor('#FFFFFF');
            this.faPDF.text(count.toString(), this.currentRight + 2, this.current_top +4);
            this.faPDF.setTextColor('#444444');
	        this.faPDF.text(reviewee_analysis_data[i].reviewee_name, column_one +1 , this.current_top +4);
	        this.faPDF.text(reviewee_analysis_data[i].score.toString(), column_two +1 , this.current_top +4);
	        this.faPDF.text(percentage_final.toString(), column_three +1 , this.current_top +4);

	        this.current_top += 2 * line_height; 
        }
    }
	
	  
	formatAMPM = (hours, minutes) =>{
	  var ampm = hours >= 12 ? 'pm' : 'am';
	  hours = hours % 12;
	  hours = hours ? hours : 12;
	  var strTime = hours + ':' + minutes + ' ' + ampm;
	  return strTime;
	}

	findDateAndTime = (input_date) =>{
		    var DateTime = {date : '', time : ''}
		    let d = new Date();
		    let date = '';
		    let day = '';
		    let monthIndex = '';
		    let year = '';
		    if(input_date != ''){
		    	d = new Date(input_date);
		        day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
		        monthIndex = d.getMonth() < 10 ? `0${d.getMonth().toString()}`: d.getMonth().toString();
		        year = d.getFullYear().toString();
	    		date = day + '/' + (parseInt(monthIndex)+1) + '/' + year;
	    		return date;
		    }
		    day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
		    monthIndex = d.getMonth();
		    year = d.getFullYear().toString();
		    var monthNames = [
		        "January", "February", "March","April", "May", "June", "July",
		        "August", "September", "October","November", "December"
		      ];

		   	date = day + ' ' + monthNames[monthIndex] + ' ' + year;
		    let hours = d.getHours() < 10 ? `0${d.getHours().toString()}` : d.getHours().toString();
		    let minutes = d.getMinutes() < 10 ? `0${d.getMinutes().toString()}` : d.getMinutes().toString();
		    let time = `${hours}:${minutes}`;
		    DateTime.date = date;
		    DateTime.time = this.formatAMPM(hours,minutes);
		    return DateTime;
	}
    generatePDF = () =>{
    	//this.setState({fc_total:0});
        this.current_top = marginY + paraGap;
        this.page_right_start = marginX;
        this.currentPageNumber = 0;
        this.currentRight = marginX;
        this.faPDF = new jsPDF({
	                                orientation: 'p',
	                                unit: 'mm',
	                                format: 'a4',
	                                putOnlyUsedFonts: true
                                });
        this.drawHeader();
        this.drawOverview();
        this.processChartView();
        this.footerSection();
        this.processAnswerDetails();
        this.processRevieweeAnalysisData();
        this.processPageFooters();
        this.faPDF.setFontSize(14)
        this.faPDF.setTextColor('#0F2D62');
		this.faPDF.setFontStyle('bold');
		if((this.current_top + 2 * line_height) > 260){
			this.faPDF.addPage();
	        this.footerSection();
	        this.current_top = marginY;
	        this.faPDF.text("End of Report",  85 , marginY +2 * line_height);
		}else{
			this.faPDF.text("End of Report",  85 , this.current_top + 2 * line_height);
		}
		
        var blob = this.faPDF.output('blob')
        var binaryData = [];
        binaryData.push(blob);
        var pdfurl = window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));

        this.setState({pdfURL:pdfurl, faPDF : this.faPDF, ready: true});
        
    }
	componentDidMount() {
	  //this.props.refreshCallback() 
	  //this.setState({fc_total:this.props.AnswerData.fc_total})   
      this.generatePDF();  
      //this.props.refreshCallback()
	}
	componentDidUpdate(prevProps) {

	   if (prevProps !== this.props) {
	   	//this.setState({fc_total:this.props.AnswerData.fc_total}) 
	   	chart_bar_unit = 1;
	    this.generatePDF(); 
	    //this.props.refreshCallback()  
	   }
	 }
	
	heightCalc = (yesCount,noCount,naCount,vert_unit,totalCount) =>{
		console.log("heightCalc yesCount::",yesCount)
		console.log("heightCalc noCount::",noCount)
		console.log("heightCalc naCount::",naCount)
		console.log("heightCalc vert_unit::",vert_unit)
		console.log("heightCalc totalCount::",totalCount)

		let yes_percent = (yesCount/totalCount)*100;
		let no_percent = (noCount/totalCount)*100;
		let na_percent = (naCount/totalCount)*100;

		// console.log("heightCalc yes_percent::",yes_percent) 
		// console.log("heightCalc no_percent::",no_percent)
		// console.log("heightCalc na_percent::",na_percent)

		let yes_height = vert_unit*(yes_percent/100)
		let no_height = vert_unit*(no_percent/100)
		let na_height = vert_unit*(na_percent/100)
		// console.log("heightCalc yes_height::",yes_height)
		// console.log("heightCalc no_height::",no_height) 
		// console.log("heightCalc na_height::",na_height) 
		let ret = [];
		ret['yes_height'] = yes_height;
		ret['no_height'] = no_height;
		ret['na_height'] = na_height;
		return ret;

	}
	render()
	{
		console.log("my props::",this.props)
		if(this.faPDF === null) {
		    return (<div>Loading...</div>);
		} else {
		    if (this.state.ready) {
		        return (<div>
		            <Iframe url={this.state.pdfURL} width="100%" height="900px" type="application/pdf" />
		        </div>);
		    } else {
		        return (<div>Loading...</div>);
		    }
		}
	}
}

export default AnswerAnalysis;