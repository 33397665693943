import React from 'react';
import styled from 'styled-components';
// import CSLTable from '../Common/CSLTable';
import BulkTable from '../Common/BulkTable';
import InfoPrompt from '../Common/InfoPrompt';
import { AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";

const BulkContainer = styled.div`
	display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #ffffff;
    box-shadow: 0 0 5px #a7a6a6;
    width: 98%;
    top: 2%;
    left: 1%;
    border: 2px solid #bbbaba;
`;
const FailedDiv = styled.div`
	color: #cd484e;
    margin-left: 20px;
    font-style: italic;
    font-weight: 600;
`;
const SaveBtn = styled.button`
    background-color: #37ada7;
    padding: 10px 25px;
    border: 1px solid #37ada7;
    border-radius: 2px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
`;
const CancelBtn = styled.button`
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    color: #666666;
    cursor: pointer;
    display: inline-block;
`;

class BulkPreview extends React.Component {

	state = {ready: false, payload: null, failed_count: 0, show_info_prompt: false};

	componentDidMount() {
		let failed_count = this.state.failed_count;
		if (this.props.data.data.length !== 0) {
			for (let item of this.props.data.data) {
				for (let key in item) {
					if (item[key].valid === false) {
						failed_count = failed_count + 1;
					}
				}
			}
		}
		this.setState({ready: true, payload: this.props.data, failed_count});
		this.props.scrollToTop();
	}

	processData = () => {
		let ret = {data: [], columns: [{Header: "", minWidth: 40, headerStyle: {textAlign: 'center'}, Cell: this.renderStatus}]};
		for (let key in this.state.payload.headers) {
			ret.columns.push({
				Header: this.state.payload.headers[key].nick_name,
				accessor: key,
				minWidth: 200,
				headerStyle: {textAlign: 'center'},
				Cell: this.renderCell
			})
		}
		for (let item of this.state.payload.data) {
			let elem = {};
			for (let key in item) {
				let valid_key = key + "_valid";
				let reason_key = key + "_reason";
				elem[key] = item[key].value;
				elem[valid_key] = item[key].valid;
				elem[reason_key] = item[key].reason;
			}
			ret.data.push(elem);
		}
		return ret;
	}

	renderCell = (cellInfo) => {
		// console.log("cellInfo:", cellInfo);
		return (
			<div style={{
				width: "100%",
				boxSizing: "border-box",
				display: "table",
				minHeight: "40px",
				backgroundColor: cellInfo.original[cellInfo.column.id+"_valid"] === true ? "#ffffff" : "#EFC8CA",
				border: cellInfo.original[cellInfo.column.id+"_valid"] === true ? "none" : "1px solid #c63940"
			}}>
				<div title={cellInfo.original[cellInfo.column.id+"_reason"]} style={{
					display: "table-cell",
					textAlign: "center",
					verticalAlign: "middle",
					fontSize: "12px",
					color: cellInfo.original[cellInfo.column.id+"_valid"] === true ? "#000000" : "#c63940",
					cursor: cellInfo.original[cellInfo.column.id+"_valid"] === true ? "auto" : "help"
				}}>
					{cellInfo.value}
				</div>
			</div>
		)
	}

	renderStatus = (cellInfo) => {
		let row_valid = true;
		for (let key in cellInfo.original) {
			if (key.includes("_valid") === true && cellInfo.original[key] === false) row_valid = false;
		}
		return (
			<div style={{
				width: "100%",
				boxSizing: "border-box",
				display: "table",
				minHeight: "40px"
			}}>
				<div style={{
					display: "table-cell",
					textAlign: "center",
					verticalAlign: "middle",
					fontSize: "22px",
					paddingTop: "5px",
					color: row_valid === true ? "#3EBEA6" : "#D15454",
				}}>
					{row_valid === true ? <AiFillCheckCircle /> : <AiFillExclamationCircle />}
				</div>
			</div>
		)
	}

	submitBulk = (event) => {
		event.preventDefault();
		this.setState({show_info_prompt: true});
		// this.props.submitBulk(this.state.payload);
	}
	
	actualSubmit = (obj) => {
		this.setState({show_info_prompt: false});
		console.log("data in actualSubmit:", obj);
		this.props.submitBulk(this.state.payload, obj);
	}

	render () {
		if (this.state.ready === false) return <div></div>
		return (
			<BulkContainer>
				{this.state.show_info_prompt === true && <InfoPrompt review_count={this.state.payload.data.length} actualSubmit={this.actualSubmit} />}
				<BulkTable
				    processData={this.processData}
				    tableRows="10"
				/>
				{this.state.failed_count !== 0 && <FailedDiv>{this.state.failed_count + " Entries failed"}</FailedDiv>}
				{this.props.related_individual_text !== '' && <FailedDiv>{this.props.related_individual_text}</FailedDiv>}
				<div style={{float: "right", margin: "25px 10px"}}>
					{
						this.state.payload.readonly === true
						?
						<div>
							<CancelBtn onClick={this.props.closeModal}>CLOSE</CancelBtn>
						</div>
						:
						<div>
							<CancelBtn onClick={this.props.closeModal}>CANCEL</CancelBtn>
		                    <SaveBtn
		                    	disabled={this.state.failed_count !== 0 ? true : false}
		                    	onClick={this.submitBulk}
		                    	style={{
		                    		marginLeft: "10px",
		                    		filter: this.state.failed_count !== 0 || this.props.fields_missing === true ? "grayscale(1)" : "none",
		                    		cursor: this.state.failed_count !== 0 || this.props.fields_missing === true ? "not-allowed" : "pointer"
		                    	}}
		                    >SUBMIT</SaveBtn>
						</div>
					}
                </div>
			</BulkContainer>
		)
	}

}

export default BulkPreview;