import React from "react";
import styled from "styled-components";
import Store from "../../Common/Store";
import APICall from "../../Common/APICall";
import CSLTable from "../Common/CSLTable";
import ReportModal from "../MGR/ReportModal";
import FollowupModal from "../Partials/FollowupModal";
import AnswerView from "../Common/AnswerView";
import AssignFileCheck from "../Partials/AssignFileCheck";
import QcView from "../Common/QcView";
import AdvancedFilter from "../Partials/AdvancedFilter";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import CSLLoader from "../Common/CSLLoader";
import CSLPageloader from "../Common/CSLPageloader";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import EventBus from '../../Common/EventBus.js';
import Utils from "../../Common/Utils";

const SubmitButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #223b5a;
  color: #ffffff;
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #223b5a;
  padding-left: 10px;
  margin-left: 9px;
  margin-top: 10px;
  font-size: 11px;
  cursor: pointer;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;
const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
  cursor: pointer;
`;
const DelButton = styled.div`
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
`;
const CardChevronContainer = styled.div`
  text-align: right;
  padding: 15px 10px 10px 0px;
  box-sizing: border-box;
`;
const ClosedChevron = styled.div`
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 13px 0px 13px;
  background-color: #ffffff;
`;
const StatusButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 15px;
`;
const DummyOverlay = styled.div`
  display: table;
  box-sizing: border-box;
  height: calc(100vh - 65px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 65px;
  z-index: 1000;
  opacity: 0.6;
`;
class FCIndex extends React.Component {
  state = {
    ready: false,
    view_count: 0,
    modal_view: null,
    page_view: "fcindex",
    task: null,
    show_completed: false,
    show_qa: false,
    show_completed_followup: false,
    pending_tasks: null,
    pending_followup_tasks: null,
    completed_tasks: null,
    completed_followup_tasks: null,
    qa_tasks: null,
    show_loader: false,
    comp_fc_autofail_result: [],
    pending_filters: {
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      reviewee: { name: "reviewee", nickname: "Reviewee", type: "multi", options: {}, selections: {}, size: 2 },
      customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
      due_date: { name: "due_date", nickname: "Due Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    completed_filters: {
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      reviewee: { name: "reviewee", nickname: "Reviewee", type: "multi", options: {}, selections: {}, size: 2 },
      customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
      completed_date: { name: "completed_date", nickname: "Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    qa_filters: {
      assign_to: { name: "assign_to", nickname: "Assigned To", type: "multi", options: {}, selections: {}, size: 2 },
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
      due_comp_date: { name: "due_comp_date", nickname: "Due/Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    pending_followup_filters: {
      assign_to: { name: "assign_to", nickname: "Assigned To", type: "multi", options: {}, selections: {}, size: 2 },
      due_date: { name: "due_date", nickname: "Due Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    completed_followup_filters: {
      assign_to: { name: "assign_to", nickname: "Assigned To", type: "multi", options: {}, selections: {}, size: 2 },
      completed_date: { name: "completed_date", nickname: "Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    pending_file_reviews_header_text: "Pending File Reviews: --/--/---- - --/--/----",
    completed_file_reviews_header_text: "Completed File Reviews",
    qa_reviews_header_text: "QA Reviews of My File Reviews",
    pending_followup_tasks_header_text: "Pending Followup Tasks: --/--/---- - --/--/----",
    completed_followup_tasks_header_text: "Completed Followup Tasks",
    show_spinner: false
  };

  constructor(props) {
    super(props);
    this.view = "fcindex";
  }

  componentDidMount() {
    let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters));
    pending_filters.due_date.selections.from_date = moment().format("DD/MM/YYYY");
    pending_filters.due_date.selections.to_date = moment().format("DD/MM/YYYY");
    let pending_followup_filters = JSON.parse(JSON.stringify(this.state.pending_followup_filters));
    pending_followup_filters.due_date.selections.from_date = moment().format("DD/MM/YYYY");
    pending_followup_filters.due_date.selections.to_date = moment().format("DD/MM/YYYY");
    EventBus.registerEvent('followup_saved_from_parent', 'MGRIndex', this.showResult_parent)
    const api = new APICall();
    const payload = { command: "list_tasks", filters: { pending_filters, pending_followup_filters } };
    //if (Store.getStoreData("fc_index") === 0) api.command(payload, this.listTasks);
    // console.log("fc_index", Store.getStoreData("fc_index"));
    // console.log("pending_fctasks", Store.getStoreData("pending_fctasks"));
    // console.log("show_task", this.props.show_task);
    // if (this.props.show_task) this.view = "answerview";
    // this.view = this.props.show_task ? "answerview" : "fcindex";
    // this.setState({page_view: this.view});
    if (Store.getStoreData("fc_index") !== null && Store.getStoreData("fc_index") !== 0) {
      const postData = { command: "get_task", task_id: Store.getStoreData("fc_index") };
      api.command(postData, this.singleTask);
    }
    this.applyPendingFilter_onload();
  }

  listTasks = (result) => {
    // console.log("listTasks",result);
    // console.log("listTasks tasks",result.result.pending_followup_tasks.tasks.length)
    // console.log("listTasks filters",result.result.pending_followup_tasks.filters)
    this.setState({
      pending_tasks: result.result.pending_tasks.tasks.length === 0 ? null : result.result.pending_tasks.tasks[0],
      pending_filters: result.result.pending_tasks.filters,
      //pending_followup_tasks: result.result.pending_followup_tasks.tasks === [] ? null : result.result.pending_followup_tasks.tasks[0],
      pending_followup_tasks: result.result.pending_followup_tasks.tasks.length === 0 ? null : result.result.pending_followup_tasks.tasks[0],
      pending_followup_filters: result.result.pending_followup_tasks.filters,
      ready: true
    });
    // if(result.result.pending_followup_tasks.tasks.length > 0){
    //   this.setState({
    //     pending_followup_tasks: result.result.pending_followup_tasks.tasks.length === 0 ? null : result.result.pending_followup_tasks.tasks[0],
    //     pending_followup_filters: result.result.pending_followup_tasks.filters,
    //     ready: true
    //   });
    // }
   // console.log("listTasks state",this.state)
  };

  listTasks_old = (result) => {
    // console.log(result);
    let parent_tasks = [];
    let followup_tasks = [];
    result.result.forEach((task) => {
      if (task.task_type === "COMP_FCK") {
        const task_json = JSON.parse(task.task_json);
        if ("version" in task_json.object_data.headerinfo.review_json) {
          parent_tasks.push(task);
        }
      } else {
        followup_tasks.push(task);
      }
    });
    Store.updateStore("followup_tasks", followup_tasks);
    let fc_tasks = [];
    let my_completed_fctasks = [];
    let my_reviews_qa_tasks = [];
    for (let i = 0; i < parent_tasks.length; i++) {
      if (parent_tasks[i].cur_lane === "COMP_FCK_CREATE" || parent_tasks[i].cur_lane === "COMP_FCK_ASSIGNMENT" || parent_tasks[i].cur_lane === "COMP_FCK_SAVED") {
        if (parent_tasks[i].cur_assigned_to === Store.getStoreData("loggedin_user_info").ID) {
          fc_tasks.push(parent_tasks[i]);
        }
      }
      const task_json = JSON.parse(parent_tasks[i].task_json);
      if (parent_tasks[i].cur_lane === "COMP_FCK_COMPLETE" || parent_tasks[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT" || parent_tasks[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
        if (parseInt(task_json.object_data.headerinfo.reviewer_id) === Store.getStoreData("loggedin_user_info").ID || parseInt(task_json.object_data.headerinfo.reviewee_id) === Store.getStoreData("loggedin_user_info").ID) {
          my_completed_fctasks.push(parent_tasks[i]);
        }
        if (parent_tasks[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT" || parent_tasks[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
          if (parseInt(task_json.object_data.headerinfo.reviewer_id) === Store.getStoreData("loggedin_user_info").ID) {
            my_reviews_qa_tasks.push(parent_tasks[i]);
          }
        }
      }
    }
    Store.updateStore("fc_tasks", fc_tasks);
    Store.updateStore("my_completed_fctasks", my_completed_fctasks);
    Store.updateStore("my_reviews_qa_tasks", my_reviews_qa_tasks);
    this.setState({ ready: true });
  };

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    // if (this.props !== prevProps) {
    //     console.log("fc_index", Store.getStoreData('fc_index'));
    //     console.log("pending_fctasks", Store.getStoreData('pending_fctasks'));
    //     console.log("show_task", this.props.show_task);
    //     this.view = this.props.show_task ? "answerview" : "fcindex";
    //     if (Store.getStoreData('fc_index') !== null && Store.getStoreData('fc_index') !== 0) {
    //         const api = new APICall();
    //         const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
    //         api.command(postData, this.singleTask);
    //     }
    // }
  }

  singleTask = (result) => {
    console.log("singleTask", result);
    // this.setState({task: result, page_view: this.view});
    // console.log("page_view:", this.state.page_view);
    // this.setState({page_view: view});
      let comp_fc_autofail_result = []

    if (this.props.show_task) {
      let this_task = result.result.task.parenttask;
      let task_json = JSON.parse(this_task.task_json);
      if (result.result.task.parenttask.cur_lane === "COMP_FCK_COMPLETE" || result.result.task.parenttask.cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
        Store.updateStore("report_task_json", task_json);
        Store.updateStore("report_task", result.result.task);
        Store.updateStore("ReportModal", `${document.body.scrollHeight.toString()}px`);
        this.modal_view = "view_report_modal";
        comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
      } else if (result.result.task.parenttask.cur_lane === "COMP_FCK_QC_COMPLETE") {
        if (Store.getStoreData("module_config").general.own_reviews_qa_view === true && task_json.object_data.headerinfo.reviewer_id === Store.getStoreData("loggedin_user_info").ID) {
          this.view = "qcview";
        } else {
          let this_task = result.result.task.parenttask;
          let task_json = JSON.parse(this_task.task_json);
          Store.updateStore("report_task_json", task_json);
          Store.updateStore("report_task", result.result.task);
          Store.updateStore("ReportModal", `${document.body.scrollHeight.toString()}px`);
          comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
          // this.view = "fcindex";
          this.modal_view = "view_report_modal";
        }
      } else if (result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE") {
        Store.updateStore("followup_task", { parenttask: result.result.task.original_parenttask, subtask: result.result.task.parenttask, childtask: result.result.task.childtask});
        // this.setState({modal_view: 'view_followup_modal'});
        this.view = "fcindex";
        this.modal_view = "view_followup_modal";
      } else if (result.result.task.parenttask.cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
        this.view = "review";
      } else {
        this.view = "answerview";
      }
    }
    console.log("page_view:", this.view);
    console.log("modal_view:", this.modal_view);
    this.setState({ task: result, page_view: this.view, modal_view: this.modal_view, ready: true, comp_fc_autofail_result });
  };

  refreshState = (modal_view) => {
    this.setState({ view_count: this.state.view_count + 1, modal_view: modal_view });
  };

  showInitFileReviewModal = (task_id) => (event) => {
    console.log(task_id);
    event.preventDefault();
    if (task_id > 0) {
      Store.updateStore("fc_index", task_id);
      this.setState({ page_view: "answerview" });
    } else {
      Store.updateStore("fc_index", task_id);
      this.setState({ page_view: "assignview" });
    }
  };

  showReportModal = (task_id) => (event) => {
    event.preventDefault();
    let postData = { command: "get_task", task_id: task_id };
    let api = new APICall();
    api.command(postData, this.processGetTask_forReport);
  };
  showResult_parent = (task_id)  => {
    this.setState({'show_loader':true})
    document.documentElement.scrollTop = 0;
    // Store.updateStore('report', JSON.parse(result.task_json).object_data);
    let postData = { command: "get_task", task_id: task_id };
    let api = new APICall();
    api.command(postData, this.processGetTask_forReport);
  };
  processGetTask_forReport = (result) => {
    console.log("Report1:", result);
    let this_task = result.result.task.parenttask;
    let task_json = JSON.parse(this_task.task_json);
    document.documentElement.scrollTop = 0;
    Store.updateStore("report_task_json", task_json);
    Store.updateStore("report_task", result.result.task);
    Store.updateStore("ReportModal", `${document.body.scrollHeight.toString()}px`);
    let comp_fc_autofail_result = []
    comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
    this.setState({ modal_view: "view_report_modal", ready: true, show_loader:false, comp_fc_autofail_result });
  };

  closeReportmodal = () => {
    this.setState({ modal_view: null });
  };

  deletePendingTask = (task_id) => (event) => {
    event.preventDefault();
    const r = window.confirm("Are you sure you want to delete this record?");
    if (r == true) {
      let postData = { command: "remove_task", task_id: task_id };
      let api = new APICall();
      api.command(postData, this.reloadTask);
    }
  };

  reloadTask = (result) => {
    if (result) {
      alert("Filecheck task removed successfully.");
    }
    window.location.reload(true);
  };

  processPendingTasksData = () => {
    // let pending_tasks = Store.getStoreData('fc_tasks');
    // console.log("pending_tasks:", pending_tasks);
    let contacts = Store.getStoreData("users");
    const has_gc = Store.getStoreData("parent_has_group_companies");
    let ret = { data: [], columns: [] };
    let i = 0;
    if (has_gc) {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Company", accessor: "company", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Scheduled Date",
          accessor: "schdate",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          width: 150,
          headerStyle: { textAlign: "left" },
          Cell: (cellInfo) => (
            <div>
              <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
            </div>
          ),
        },
        {
          Header: "Action",
          Cell: (row) => (
            <div>
              <MRButton onClick={this.showInitFileReviewModal(row.original.id)}>Check</MRButton>
            </div>
          ),
          width: 150,
          headerStyle: { textAlign: "left" },
        },
      ];
    } else {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Scheduled Date",
          accessor: "schdate",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          width: 150,
          headerStyle: { textAlign: "left" },
          Cell: (cellInfo) => (
            <div>
              <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
            </div>
          ),
        },
        {
          Header: "Action",
          Cell: (row) => (
            <div>
              <MRButton onClick={this.showInitFileReviewModal(row.original.id)}>Check</MRButton>
            </div>
          ),
          width: 150,
          headerStyle: { textAlign: "left" },
        },
      ];
    }

    let gc_assoc = Store.getStoreData("gc_assoc");
    let gc_company = "";

    if (this.state.pending_tasks !== null) {
      for (let d of this.state.pending_tasks) {
        i++;
        let today = moment().unix();
        let due_date = moment(d[5], "DD/MM/YYYY").add(1, "days");
        due_date = due_date.unix();

        let text = due_date > today ? "Pending" : "Overdue";
        let color = due_date > today ? "#2E8B57" : "#FF0000";
        text = d[6] === "COMP_FCK_SAVED" ? "In Progress" : text;
        color = d[6] === "COMP_FCK_SAVED" ? "#ffd11a" : color;
        let related_individual = "N/A";
        if (d[13] !== 0) {
          related_individual = d[13].toString() in contacts ? contacts[d[13].toString()].ContactName : "Inactive User";
        }
        let elem = {
          index: i,
          file_ref: d[9].replace(/'+/g, "'"),
          customer_name: d[1],
          company: d[2],
          reviewee: d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "Inactive User",
          reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
          schdate: d[5],
          status: { text: text, color: color },
          id: d[0],
          sale_date: d[7],
          status_value: text,
          assign_date: d[8],
          file_type: d[10],
          related_individual: related_individual
        };
        ret.data.push(elem);
      }
    }

    ret.data = ret.data.sort((a, b) => {
      if (a.schdate === b.schdate) {
        return 0;
      }
      let adue = a.schdate.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.schdate.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    if (has_gc === true) {
      ret.export_columns = { file_ref: "File Reference", customer_name: "Customer", company: "Company", reviewer: "Reviewer", reviewee: "Reviewee", schdate: "Scheduled Date", status_value: "Status", related_individual: "Related Individual" };
    } else {
      ret.export_columns = { file_ref: "File Reference", customer_name: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", schdate: "Scheduled Date", status_value: "Status", related_individual: "Related Individual" };
    }
    return ret;
  };

  processCompleteTasksData = () => {
    // const completed_tasks = Store.getStoreData('my_completed_fctasks');
    // console.log("my_completed_fctasks", completed_tasks);
    let contacts = Store.getStoreData("users");
    const has_gc = Store.getStoreData("parent_has_group_companies");
    let ret = { data: [], columns: [] };
    let i = 0;

    let prefered_columns = {};

    if (has_gc) {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Company", accessor: "company", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Completed By", accessor: "completed_by", minWidth: 160, headerStyle: { textAlign: "left" } },
        {
          Header: "Completed Date",
          accessor: "completed_date",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Action",
          Cell: (row) => (
            <div>
              <MRButton onClick={this.showReportModal(row.original.id)}>View Report</MRButton>
            </div>
          ),
        },
      ];
      prefered_columns = {
        file_ref: "File Reference",
        company: "Company",
        reviewee: "Reviewee",
        reviewer: "Reviewer",
        schdate: "Scheduled Date",
        status: "Status",
        customer_name: "Customer Name",
        sale_date: "Policy Start Date",
        assign_date: "Assigned Date",
        file_type: "File Type",
        completed_date: "Review Date",
        score: "Score",
        result: "Result",
        related_individual: "Related Individual",
        confirmed_by: "File Edited By",
        confirmed_date: "File Edited Date",
        confirmation_text: "File Edit Comments"
      };
    } else {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Completed By", accessor: "completed_by", minWidth: 160, headerStyle: { textAlign: "left" } },
        {
          Header: "Completed Date",
          accessor: "completed_date",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Action",
          Cell: (row) => (
            <div>
              <MRButton onClick={this.showReportModal(row.original.id)}>View Report</MRButton>
            </div>
          ),
        },
      ];
      prefered_columns = {
        file_ref: "File Reference",
        reviewee: "Reviewee",
        reviewer: "Reviewer",
        schdate: "Scheduled Date",
        status: "Status",
        customer_name: "Customer Name",
        sale_date: "Policy Start Date",
        assign_date: "Assigned Date",
        file_type: "File Type",
        completed_date: "Review Date",
        score: "Score",
        result: "Result",
        related_individual: "Related Individual",
        confirmed_by: "File Edited By",
        confirmed_date: "File Edited Date",
        confirmation_text: "File Edit Comments"
      };
    }
    let gc_assoc = Store.getStoreData("gc_assoc");
    let gc_company = "";

    if (this.state.completed_tasks !== null) {
      console.log("completed_tasks:", this.state.completed_tasks, contacts);
      for (let d of this.state.completed_tasks) {
        let related_individual = "N/A";
        let confirmed_by = "N/A";
        let confirmed_date = "N/A";
        let confirmation_text = "N/A";
        if (d[14] !== 0) {
          related_individual = d[14].toString() in contacts ? contacts[d[14].toString()].ContactName : "Inactive User";
        }
        if (d[15] !== 0) {
          confirmed_by = d[15].toString() in contacts ? contacts[d[15].toString()].ContactName : "Inactive User";
        }
        if (d[16] !== 0) {
          confirmed_date = moment.unix(d[16]).format("DD/MM/YYYY");
        }
        if (d[17] !== "") {
          confirmation_text = d[17];
        }
        i++;
        let elem = {
          index: i,
          file_ref: d[5].replace(/'+/g, "'"),
          customer_name: d[1].replace(/'+/g, "'"),
          company: d[2],
          reviewee: d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "--",
          reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "--",
          id: d[0],
          file_type: d[6],
          schdate: d[12],
          status: "Completed",
          file_ref: d[5],
          completed_by: contacts[d[13]].ContactName,
          sale_date: d[7],
          assign_date: d[8],
          completed_date: d[9],
          score: d[10],
          result: d[11],
          related_individual: related_individual,
          confirmed_by: confirmed_by,
          confirmed_date: confirmed_date,
          confirmation_text: confirmation_text
        };
        ret.data.push(elem);
        // }
      }
    }

    ret.data = ret.data.sort((a, b) => {
      if (a.completed_date === b.completed_date) {
        return 0;
      }
      let adue = a.completed_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.completed_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });

    ret.export_columns = prefered_columns;

    return ret;
  };

  processOwnReviewsQa = () => {
    // const my_reviews_qa_tasks = Store.getStoreData("my_reviews_qa_tasks");
    // console.log("my_completed_fctasks", completed_tasks);
    let contacts = Store.getStoreData("users");
    let gc_assoc = Store.getStoreData("gc_assoc");
    const has_gc = Store.getStoreData("parent_has_group_companies");
    let ret = { data: [], columns: [] };
    ret.columns = [
      {
        Header: "Status",
        accessor: "status",
        minWidth: 40,
        headerStyle: { textAlign: "left" },
        Cell: (cellInfo) => (
          <div>
            <StatusButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</StatusButton>
          </div>
        ),
        sortMethod: (a, b) => {
          return a.text > b.text ? 1 : -1;
        },
        filterMethod: (filter, row) => {
          // console.log(row);
          // console.log(filter);
          return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
        },
      },
      { Header: "Task", accessor: "task", minWidth: 100, headerStyle: { textAlign: "left" } },
      { Header: "Assign To", accessor: "assignto", minWidth: 40, headerStyle: { textAlign: "left" } },
      { Header: "File Reference", accessor: "file_reference", minWidth: 30, headerStyle: { textAlign: "left" } },
      { Header: "Customer Name", accessor: "customer_name", minWidth: 40, headerStyle: { textAlign: "left" } },
      { Header: "Company", accessor: "company", minWidth: 40, headerStyle: { textAlign: "left" }, show: has_gc },
      { Header: "Reviewer", accessor: "reviewer", minWidth: 40, headerStyle: { textAlign: "left" } },
      {
        Header: "Due/Completed date",
        accessor: "due_or_complete_date",
        minWidth: 30,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      { Header: "Action", accessor: "action", filterable: false, minWidth: 40, headerStyle: { textAlign: "left" } },
    ];
    if (this.state.qa_tasks !== null) {
      for (let d of this.state.qa_tasks) {
        let due_or_complete_date_str = d[5] === "COMP_FCK_QC_ASSIGNMENT" ? d[6] : d[7];
        let text = d[5] === "COMP_FCK_QC_ASSIGNMENT" ? "In QA Review" : "QA Reviewed";
        let color = d[5] === "COMP_FCK_QC_ASSIGNMENT" ? "#FFD11A" : "#2E8B57";
        let user_name = "";
        let task = "";
        let action_btn = null;
        if (d[5] === "COMP_FCK_QC_ASSIGNMENT") {
          user_name = d[8].toString() in contacts ? contacts[d[8].toString()].ContactName : "Inactive User";
          const review_name = d[10];
          task = user_name + " have been assigned a " + review_name + " QA Review";
          action_btn = <MRButton onClick={this.showReportModal(d[0])}>View</MRButton>;
        } else {
          user_name = d[9].toString() in contacts ? contacts[d[9].toString()].ContactName : "Inactive User";
          task = "This QA task have been completed by " + user_name;
          action_btn = <MRButton onClick={this.showQc(d[0])}>View</MRButton>;
        }
        let related_individual = "N/A";
        let confirmed_by = "N/A";
        let confirmed_date = "N/A";
        let confirmation_text = "N/A";
        if (d[11] !== 0) {
          related_individual = d[11].toString() in contacts ? contacts[d[11].toString()].ContactName : "Inactive User";
        }
        if (d[12] !== 0) {
          confirmed_by = d[12].toString() in contacts ? contacts[d[12].toString()].ContactName : "Inactive User";
        }
        if (d[13] !== 0) {
          confirmed_date = moment.unix(d[13]).format("DD/MM/YYYY");
        }
        if (d[14] !== "") {
          confirmation_text = d[14];
        }

        let elem = {
          status: { text: text, color: color },
          task: task,
          assignto: user_name,
          file_reference: d[2].replace(/'+/g, "'"),
          customer_name: d[3],
          company: d[1],
          reviewer: d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "Inactive User",
          due_or_complete_date: due_or_complete_date_str,
          id: d[0],
          action: action_btn,
          status_text: text,
          related_individual: related_individual,
          confirmed_by: confirmed_by,
          confirmed_date: confirmed_date,
          confirmation_text: confirmation_text
        };
        ret.data.push(elem);
      }
    }

    ret.data = ret.data.sort((a, b) => {
      if (a.due_or_complete_date === b.due_or_complete_date) {
        return 0;
      }
      let adue = a.due_or_complete_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.due_or_complete_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    ret.export_columns = {
      status_text: "Status",
      task: "Task",
      assignto: "Assigned To",
      file_reference: "File Reference",
      customer_name: "Customer",
      company: "Company",
      reviewer: "Reviewer",
      due_or_complete_date: "Due/Completed Date",
      related_individual: "Related Individual",
      confirmed_by: "File Edited By",
      confirmed_date: "File Edited Date",
      confirmation_text: "File Edit Comments"
    };
    return ret;
  };

  processOwnReviewsQa_old = () => {
    const my_reviews_qa_tasks = Store.getStoreData("my_reviews_qa_tasks");
    // console.log("my_completed_fctasks", completed_tasks);
    let contacts = Store.getStoreData("users");
    const has_gc = Store.getStoreData("parent_has_group_companies");
    let ret = { data: [], columns: [] };
    let i = 0;
    ret.columns = [
      { Header: "Status", accessor: "status", width: 120, headerStyle: { textAlign: "left" } },
      { Header: "Task", accessor: "task", width: 520, headerStyle: { textAlign: "left" } },
      { Header: "Assign To", accessor: "assignto", width: 220, headerStyle: { textAlign: "left" } },
      { Header: "Due/Completed date", accessor: "due_or_complete_date", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Action", accessor: "action", filterable: false, minWidth: 40, headerStyle: { textAlign: "left" } },
    ];
    for (let d of my_reviews_qa_tasks) {
      // console.log("d.completed_by.toString():", d.completed_by.toString());
      let task_json = JSON.parse(d.task_json);
      let due_or_complete_date_str = d.cur_lane === "COMP_FCK_QC_ASSIGNMENT" ? d.due_date : d.completed_date;
      const c_date = new Date(due_or_complete_date_str);
      let dd = c_date.getDate().toString().length === 1 ? "0" + c_date.getDate().toString() : c_date.getDate().toString();
      let mm = (c_date.getMonth() + 1).toString().length === 1 ? "0" + (c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
      let yyyy = c_date.getFullYear().toString();
      // due_date_str = `${yyyy}/${mm}/${dd}`;
      due_or_complete_date_str = dd + "/" + mm + "/" + yyyy;
      let text = d.cur_lane === "COMP_FCK_QC_ASSIGNMENT" ? "In QA Review" : "QA Reviewed";
      let user_name = "";
      let task = "";
      let action_btn = null;
      if (d.cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
        user_name = d.cur_assigned_to.toString() in contacts ? contacts[d.cur_assigned_to.toString()].ContactName : "Inactive User";
        const review_name = "general" in task_json.object_data.headerinfo.review_json ? task_json.object_data.headerinfo.review_json.general.name : task_json.object_data.headerinfo.review_json.name;
        task = user_name + " have been assigned a " + review_name + " QA Review";
        action_btn = <MRButton onClick={this.showReportModal(d.id)}>View</MRButton>;
      } else {
        user_name = d.completed_by.toString() in contacts ? contacts[d.completed_by.toString()].ContactName : "Inactive User";
        task = "This QA task have been completed by " + user_name;
        action_btn = (
          <MRButton style={{ width: "150px" }} onClick={this.showQc(d.id)}>
            View
          </MRButton>
        );
      }

      let elem = {
        status: text,
        task: task,
        assignto: user_name,
        due_or_complete_date: due_or_complete_date_str,
        id: d.id,
        action: action_btn,
      };
      ret.data.push(elem);
    }
    return ret;
  };

  processPendingFollowupTasksData = () => {
    // let followup_tasks = [];
    // if (Store.getStoreData('followup_tasks') !== null) {
    //     followup_tasks = Store.getStoreData('followup_tasks').sort((a,b) => new Date(a.due_date) - new Date(b.due_date));
    // }
    let contacts = Store.getStoreData("users");
    let ret = { data: [], columns: [] };
    let prefered_columns = {};
    let i = 0;
    ret.columns = [
      { Header: "Task", accessor: "task", minWidth: 240, headerStyle: { textAlign: "left" } },
      { Header: "Company Name", accessor: "company_name", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Assigned To", accessor: "assigned_to", minWidth: 120, headerStyle: { textAlign: "left" } },
      {
        Header: "Status",
        accessor: "status",
        minWidth: 120,
        headerStyle: { textAlign: "left" },
        Cell: (cellInfo) => (
          <div>
            <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
          </div>
        ),
        sortMethod: (a, b) => {
          return a.text > b.text ? 1 : -1;
        },
        filterMethod: (filter, row) => {
          return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
        },
      },
      { Header: "Customer Name", accessor: "customer_name", minWidth: 120, headerStyle: { textAlign: "left" } },
      {
        Header: "Due Date",
        accessor: "due_date",
        minWidth: 120,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "Action",
        minWidth: 120,
        filterable: false,
        Cell: (row) => (
          <div>
            <div style={{ float: "left" }}>
              <MRButton style={{ width: "120px" }} onClick={this.showFollowUp(row.original.id)}>
                View
              </MRButton>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        ),
        headerStyle: { textAlign: "left" },
      },
    ];
    console.log("listTasks pending_followup_tasks",this.state.pending_followup_tasks)
    if (this.state.pending_followup_tasks !== null) {
      for (let d of this.state.pending_followup_tasks) {
        i++;
        // console.log("d:", d);
        // let task_json = JSON.parse(d.task_json);
        // let due_date_str = d[6] ? d[6] : '1970-01-01T00:00:00.000Z';
        // const c_date = new Date(due_date_str);
        // let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
        // let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
        // let year = c_date.getFullYear().toString();
        // const due_date = `${year}/${month}/${day}`;
        // const due_date = day+"/"+month+"/"+year;
        // let ch_date = new Date(year, month - 1, day);
        // ch_date.setDate(ch_date.getDate() + 1);
        let today = moment().unix();
        let text = d[6] + 86400 > today ? "Pending" : "Overdue";
        let color = d[6] + 86400 > today ? "#2E8B57" : "#FF0000";
        text = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
        color = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;
        const assigned_to_name = d[3] === 0 ? (d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "Inactive User") : d[3].toString() in contacts ? contacts[d[3]].ContactName : "Inactive User";
        // const u_role = contacts[task_json.task_data.cur_assigned_to.toString()].role;
        // console.log(assigned_to_name, u_role);
        let task = assigned_to_name + " has been assigned a task titled " + d[2];
        let elem = { task: task, company_name: d[1], assigned_to: assigned_to_name, status: { text: text, color: color }, due_date: moment.unix(d[6]).format("DD/MM/YYYY"), status_value: text, id: d[0], customer_name: d[8] };
        ret.data.push(elem);
      }
      Store.updateStore("followup_tasks_columns", prefered_columns);
      ret.data = ret.data.sort((a, b) => {
        if (a.due_date === b.due_date) {
          return 0;
        }
        let adue = a.due_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
        let bbdue = b.due_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
      });
    }
    ret.export_columns = { task: "Task Name", company_name: "Company Name", assigned_to: "Assigned To", customer_name: "Customer Name", status_value: "Status", due_date: "Due Date" };
    return ret;
  };

  processFollowupTasksData = () => {
    let followup_tasks = Store.getStoreData("followup_tasks") !== null ? Store.getStoreData("followup_tasks").sort((a, b) => new Date(a.due_date) - new Date(b.due_date)) : null;
    let contacts = Store.getStoreData("users");
    let ret = { data: [], columns: [] };
    let i = 0;
    ret.columns = [
      { Header: "Task", accessor: "task", minWidth: 240, headerStyle: { textAlign: "left" } },
      { Header: "Assigned To", accessor: "assigned_to", minWidth: 120, headerStyle: { textAlign: "left" } },
      {
        Header: "Status",
        accessor: "status",
        width: 120,
        headerStyle: { textAlign: "left" },
        Cell: (cellInfo) => (
          <div>
            <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
          </div>
        ),
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        minWidth: 120,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "Action",
        Cell: (row) => (
          <div>
            <MRButton onClick={this.showFollowUp(row.original.id)} style={{ width: "150px" }}>
              View
            </MRButton>
          </div>
        ),
        headerStyle: { textAlign: "left" },
      },
    ];
    if (followup_tasks !== null) {
      for (let d of followup_tasks) {
        let task_json = JSON.parse(d.task_json);
        if (task_json.task_data.cur_assigned_to.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
          i++;
          let due_date_str = d.due_date ? d.due_date : "1970-01-01T00:00:00.000Z";
          const c_date = new Date(due_date_str);
          let day = c_date.getDate().toString().length === 1 ? "0" + c_date.getDate().toString() : c_date.getDate().toString();
          let month = (c_date.getMonth() + 1).toString().length === 1 ? "0" + (c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
          let year = c_date.getFullYear().toString();
          const due_date = day + "/" + month + "/" + year;
          // const due_date = `${year}/${month}/${day}`;
          let ch_date = new Date(year, month - 1, day);
          ch_date.setDate(ch_date.getDate() + 1);
          let today = new Date();
          let text = ch_date > today ? "Pending" : "Overdue";
          let color = ch_date > today ? "#2E8B57" : "#FF0000";
          text = d.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
          color = d.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;
          const assigned_to_name =
            d.cur_assigned_to === 0
              ? task_json.task_data.cur_assigned_to.toString() in contacts
                ? contacts[task_json.task_data.cur_assigned_to.toString()].ContactName
                : "Inactive User"
              : d.cur_assigned_to.toString() in contacts
              ? contacts[d.cur_assigned_to.toString()].ContactName
              : "Inactive User";
          let task = assigned_to_name + " has been assigned a task titled " + task_json.object_data.subject;
          let elem = { task: task, assigned_to: assigned_to_name, status: { text: text, color: color }, status_text: text, due_date: due_date, id: d.id };
          ret.data.push(elem);
        }
      }
    }

    ret.data = ret.data.sort((a, b) => {
      if (a.due_date === b.due_date) {
        return 0;
      }
      let adue = a.due_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.due_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    ret.export_columns = { task: "Task", assigned_to: "Assigned To", status_text: "Status", due_date: "Due Date" };
    return ret;
  };

  openPdfPrintView = () => {
    //this.setState({page_view: 'pdfprintview'});
    this.props.changeCurView("pdfprintview");
  };

  showFollowUp = (task_id) => (event) => {
    event.preventDefault();
    this.setState({show_spinner: true});
    let postData = { command: "get_subtask", task_id: task_id };
    let api = new APICall();
    api.command(postData, this.openFollowupModal);
  };

  openFollowupModal = (result) => {
    Store.updateStore("followup_task", result.result.task);
    this.setState({ modal_view: "view_followup_modal",show_spinner: false });
  };

  changeCurView = (view) => {
    console.log("view", view);
    this.view = view;
    if (Store.getStoreData("fc_index") !== null) {
      const api = new APICall();
      const postData = { command: "get_task", task_id: Store.getStoreData("fc_index") };
      api.command(postData, this.singleTask);
    } else {
      this.setState({ page_view: view });
    }
  };

  toggleCards = (event) => {
    event.preventDefault();
    const show_cards = this.state.show_cards ? false : true;
    this.setState({ show_cards });
  };

  showQc = (taskid) => (event) => {
    event.preventDefault();
    console.log("taskid:", taskid);
    Store.updateStore("fc_index", taskid);
    this.setState({ page_view: "qcview" });
  };

  showPage = (page) => {
    this.setState({ page_view: page });
  };

  applyPendingFilter = (filters) => {
    console.log("pending_filters:", filters);
    this.setState({
      //show_spinner: true,
      pending_file_reviews_header_text: "Pending File Reviews: "+filters.due_date.selections.from_date+" - "+filters.due_date.selections.to_date
    });
    const postData = { command: "get_filtered_pending_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredPendingTasks);
  };

  processFilteredPendingTasks = (result) => {
    // console.log("processFilteredPendingTasks:",result);
    this.setState({ pending_tasks: result });
  };

  switchRadio = (event) => {
    event.preventDefault();
    console.log(this.state.show_completed);
    if (this.state.show_completed === true) {
      let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
      Object.keys(completed_filters).forEach((key) => {
        completed_filters[key].selections = {};
      });
      this.setState({
        show_completed: false,
        completed_tasks: null,
        completed_filters,
      });
    } else {
      this.setState({ completed_file_reviews_header_text: "Completed File Reviews: --/--/---- - --/--/----"});
      
      // this.setState({show_spinner: true});
      const postData = { command: "get_complete_filter_options" };
      let api = new APICall();
      api.command(postData, this.processFilterOptions);
    }
  };

  processFilterOptions = (result) => {
    console.log(result);
    let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
    Object.keys(completed_filters).forEach((key) => {
      completed_filters[key]["options"] = result[key];
      if (key === "completed_date") {
        const td_start = new Date(new Date().setDate(new Date().getDate() - 90));
        const td_end = new Date();
        let dd = td_start.getDate();
        let mm = td_start.getMonth() + 1;
        let yyyy = td_start.getFullYear();
        dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
        mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
        const td_str = `${dd}/${mm}/${yyyy}`;
        console.log("td_str:",td_str);
        let dd1 = td_end.getDate();
        let mm1 = td_end.getMonth() + 1;
        let yyyy1 = td_end.getFullYear();
        dd1 = dd1 < 10 ? `0${dd1.toString()}` : dd1.toString();
        mm1 = mm1 < 10 ? `0${mm1.toString()}` : mm1.toString();
        const td_str_e = `${dd1}/${mm1}/${yyyy1}`;
        console.log("td_str_e:",td_str_e);
        completed_filters.completed_date.selections.from_date = td_str;
        completed_filters.completed_date.selections.to_date = td_str_e;
      }
    });
    this.setState({
      show_completed: true,
      completed_filters,
    });
    
    const postData = { command: "get_filtered_completed_tasks",ontoggle:1, completed_filters};
    let api = new APICall();
    api.command(postData, this.processFilteredCompletedTasks);
  };

  applyFilter = (filters) => {
    console.log(filters);
     this.setState({completed_file_reviews_header_text: "Completed File Reviews: "+
     filters.completed_date.selections.from_date+" - "+filters.completed_date.selections.to_date  });
     //this.setState({show_spinner: true});
    const postData = { command: "get_filtered_completed_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredCompletedTasks);
  };

  processFilteredCompletedTasks = (result) => {
    // console.log(result);
    this.setState({ completed_tasks: result });
  };

  toggleQa = (event) => {
    event.preventDefault();
    console.log(this.state.show_qa);
    if (this.state.show_qa === true) {
      let qa_filters = JSON.parse(JSON.stringify(this.state.qa_filters));
      Object.keys(qa_filters).forEach((key) => {
        qa_filters[key].selections = {};
      });
      this.setState({
        show_qa: false,
        qa_tasks: null,
        qa_filters,
      });
    } else {
      this.setState({
        //show_spinner: true,
        qa_reviews_header_text: "QA Reviews of My File Reviews: --/--/---- - --/--/----"
      });
      const postData = { command: "get_fc_qa_filter_options" };
      let api = new APICall();
      api.command(postData, this.processFcQaFilterOptions);
    }
  };

  processFcQaFilterOptions = (result) => {
    console.log(result);
    let qa_filters = JSON.parse(JSON.stringify(this.state.qa_filters));
    Object.keys(qa_filters).forEach((key) => {
      qa_filters[key]["options"] = result[key];
      if (key === "due_comp_date") {
        const td_start = new Date(new Date().setDate(new Date().getDate() - 7));
        const td_end = new Date();
        let dd = td_start.getDate();
        let mm = td_start.getMonth() + 1;
        let yyyy = td_start.getFullYear();
        dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
        mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
        const td_str = `${dd}/${mm}/${yyyy}`;
        console.log("td_str:",td_str);
        let dd1 = td_end.getDate();
        let mm1 = td_end.getMonth() + 1;
        let yyyy1 = td_end.getFullYear();
        dd1 = dd1 < 10 ? `0${dd1.toString()}` : dd1.toString();
        mm1 = mm1 < 10 ? `0${mm1.toString()}` : mm1.toString();
        const td_str_e = `${dd1}/${mm1}/${yyyy1}`;
        console.log("td_str_e:",td_str_e);
      // td.setUTCHours(0,0,0,0);
        qa_filters.due_comp_date.selections.from_date = td_str;
        qa_filters.due_comp_date.selections.to_date = td_str_e;
      }
    });
      //console.log("qaDATE",qa_filters.due_comp_date.selections.from_date)
      //console.log("qaDATE",qa_filters.due_comp_date.selections.to_date)
    this.setState({
      show_qa: true,
      qa_filters,
    });
    const postData = { command: "get_filtered_fc_qa_tasks",ontoggle:1, qa_filters };
    let api = new APICall();
    api.command(postData, this.processFilteredFcQaTasks);
  };

  applyQaFilter = (filters) => {
    console.log(filters);
    this.setState({
      //show_spinner: true,
      qa_reviews_header_text: "QA Reviews of My File Reviews: "+filters.due_comp_date.selections.from_date+" - "+filters.due_comp_date.selections.to_date
    });
    const postData = { command: "get_filtered_fc_qa_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredFcQaTasks);
  };

  processFilteredFcQaTasks = (result) => {
    // console.log(result);
    this.setState({ qa_tasks: result });
  };

  applyPendingFollowupFilter = (filters) => {
    console.log("applyPendingFollowupFilter::",filters);
    this.setState({show_spinner: true});
    const postData = { command: "get_filtered_pending_followup_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredPendingFollowupTasks);
  };

  processFilteredPendingFollowupTasks = (result) => {
    console.log("Result:", result);
    this.setState({ pending_followup_tasks: result,show_spinner: false });
  };

  closeQcView = (event) => {
    event.preventDefault();
    Store.updateStore("fc_index", null);
    if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
      window.location.assign(document.referrer);
    }
    this.setState({ page_view: "fcindex" });
  };
  clearPendingAdvancedFilter = () => {
    console.log(this.state.pending_filters);
    let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters));
    Object.keys(pending_filters).forEach((key) => {
      if (pending_filters[key].type === "date_range") {
        pending_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        pending_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        pending_filters[key].selections = {};
      }
    });
    this.setState({ pending_file_reviews_header_text: "Pending File Reviews: --/--/---- - --/--/----",pending_filters });
    // window.location.reload();
  };
  clearCompletedAdvancedFilter = () => {
    console.log(this.state.completed_filters);
    let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
    Object.keys(completed_filters).forEach((key) => {
      if (completed_filters[key].type === "date_range") {
        completed_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        completed_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        completed_filters[key].selections = {};
      }
    });
    this.setState({ completed_file_reviews_header_text: "Completed File Reviews: --/--/---- - --/--/----",completed_filters });
    // window.location.reload();
  };
  applyPendingFilter_onload = () => {
    let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters));
    //let pending_qa_filters = JSON.parse(JSON.stringify(this.state.pending_qa_filters));
    let pending_followup_filters = JSON.parse(JSON.stringify(this.state.pending_followup_filters));
    
    //const td_str = new Date().setDate(new Date().getDate() + 7);
    const td_str = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();
    pending_filters.due_date.selections = { from_date: null, to_date: td_str };
    //pending_qa_filters.due_date.selections = { from_date: null, to_date: td_str };
    pending_followup_filters.due_date.selections = { from_date: null, to_date: td_str };
    console.log("today:", td_str);
    const api = new APICall();
    const payload = { command: "list_tasks", onloading:1, filters: { pending_filters, pending_followup_filters } };
    //, pending_qa_filters
    console.log("payload:", payload);
    api.command(payload, this.listTasks);
   // return false;
  }
  closeAnswerView = (event) => {
    event.preventDefault();
    Store.updateStore("fc_index", null);
    // //console.log('referrer',document.referrer)
    // if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
    //   window.location.assign(document.referrer);
    // }
    this.setState({ page_view: "fcindex" });
  };
  clearQaAdvancedFilter = () => {
    console.log(this.state.qa_filters);
    let qa_filters = JSON.parse(JSON.stringify(this.state.qa_filters));
    Object.keys(qa_filters).forEach((key) => {
      if (qa_filters[key].type === "date_range") {
        qa_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        qa_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        qa_filters[key].selections = {};
      }
    });
    this.setState({ qa_reviews_header_text: "Completed QA Reviews: --/--/---- - --/--/----",
    qa_filters });
    // window.location.reload();
  };
  // openPdfPrintView_followup = () => {
  //   //this.setState({page_view: 'pdfprintview'});
  //   this.props.changeCurView("pdfprintview_followup");
  // };
  render() {
    console.log("State in render:", this.state);
    if (Store.getStoreData("fc_index") === 0) {
      if (Store.getStoreData("filechecker-list_tasks") === null) {
        return (
          <div>
            <CSLPageloader />
          </div>
        );
      }
    }
    if (this.state.ready === false) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }
    if (this.state.show_loader === true) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }
    if (this.state.page_view === "fcindex") {
      console.log("Page view in render:", this.state.page_view);
      console.log("Modal view in render:", this.state.modal_view);
      return (
        <div>
          {(() => {
            if (this.state.show_spinner === true) {
              return (
                <div>
                  <DummyOverlay>
                    <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                      <ClipLoader size={150} color={"#123abc"} loading={true} />
                    </div>
                  </DummyOverlay>
                </div>
              );
            }
          })()}
          <div style={{ clear: "both" }}>&nbsp;</div>
          <SubmitButton onClick={this.showInitFileReviewModal(0)}>Assign File Review</SubmitButton>
          <div style={{ clear: "both" }}></div>
          {/* <div style={{boxSizing: "border-box", padding: "15px 10px 0px 10px"}}><AdvancedFilter contacts={Store.getStoreData('reviewee_info')} review_types={Store.getStoreData('my_active_reviews')} /></div> */}
          <div style={{ padding: "15px 9px 5px 9px" }}>
            {/*<CSLTable add={false} export={true} export_file_name={"Pending_File_Reviews"} processData={this.processPendingTasksData} tableRows="10" headerText={'Pending File Reviews'} refreshCallback={this.refreshState} />*/}
            <CSLTable
              export={true}
              custom_fields={true}
              export_file_name={"Pending_File_Reviews"}
              add={false}
              processData={this.processPendingTasksData}
              tableRows="10"
              headerText={this.state.pending_file_reviews_header_text}
              refreshCallback={this.refreshState}
              filters={this.state.pending_filters}
              applyFilter={this.applyPendingFilter}
              clearAdvancedFilter={this.clearPendingAdvancedFilter}
            />
          </div>
          <div style={{ clear: "both" }}></div>
          <div style={{ padding: "15px 9px 5px 9px" }}>
            {/*<CSLTable add={false} export={true} export_file_name={"Completed_File_Reviews"} processData={this.processCompleteTasksData} tableRows="10" headerText={'Completed File Reviews'} refreshCallback={this.refreshState} />*/}
            <CSLTable
              export={true}
              custom_fields={true}
              export_file_name={"Completed_File_Reviews"}
              add={false}
              processData={this.processCompleteTasksData}
              tableRows="10"
              headerText={this.state.completed_file_reviews_header_text}
              refreshCallback={this.refreshState}
              toggle_text="Show Completed File Reviews"
              toggleCallback={this.switchRadio}
              toggle_state={this.state.show_completed}
              filters={this.state.completed_filters}
              applyFilter={this.applyFilter}
              clearAdvancedFilter={this.clearCompletedAdvancedFilter}
            />
          </div>
          {(() => {
            if (Store.getStoreData("module_config").general.own_reviews_qa_view === true) {
              return (
                <div style={{ padding: "15px 9px 5px 9px" }}>
                  {/*<CSLTable add={false} export={true} export_file_name={"QA_Reviews_of_My_File_Reviews"} processData={this.processOwnReviewsQa} tableRows="10" headerText={'QA Reviews of My File Reviews'} refreshCallback={this.refreshState} />*/}
                  <CSLTable
                    export={true}
                    custom_fields={true}
                    export_file_name={"QA_Reviews_of_My_File_Reviews"}
                    add={false}
                    processData={this.processOwnReviewsQa}
                    tableRows="10"
                    headerText={this.state.qa_reviews_header_text}
                    refreshCallback={this.refreshState}
                    toggle_text="Show QA Reviews"
                    toggleCallback={this.toggleQa}
                    toggle_state={this.state.show_qa}
                    filters={this.state.qa_filters}
                    applyFilter={this.applyQaFilter}
                    clearAdvancedFilter={this.clearQaAdvancedFilter}
                  />
                </div>
              );
            }
          })()}

          <div style={{ paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px", padding: "5px" }}>
            {(() => {
              if (this.state.modal_view === "view_followup_modal") {
                return (
                  <div style={{ position: "absolute", left: "10%", width: "100%", height: "1186px", top: this.props.show_task === true ? "10vh" : "auto" }}>
                    <InactiveOverlay style={{ height: "2384px" }} />
                    <FollowupModal closeModal={this.closeReportmodal} />
                  </div>
                );
              }
            })()}
            {/*<CSLTable add={false} export={true} export_file_name={"followup_tasks"} processData={this.processFollowupTasksData} tableRows="10" headerText={'Followup Tasks'} refreshCallback={this.refreshState} />*/}
            <CSLTable
              export={true}
              export_file_name={"pending_followup_tasks"}
              add={false}
              processData={this.processPendingFollowupTasksData}
              tableRows="10"
              headerText={this.pending_followup_tasks_header_text}
              refreshCallback={this.refreshState}
              filters={this.state.pending_followup_filters}
              applyFilter={this.applyPendingFollowupFilter}
              clearAdvancedFilter={this.clearPendingFollowupAdvancedFilter}
            />
          </div>

          {(() => {
            if (this.state.modal_view !== null) {
              switch (this.state.modal_view) {
                case "view_report_modal":
                  return (
                    <div style={{ position: "absolute", left: "10%", top: "50px", width: "100%", height: "1186px" }}>
                      <InactiveOverlay style={{ height: "2384px" }} />
                      <ReportModal closeModal={this.closeReportmodal} openPrintView={this.openPdfPrintView} comp_fc_autofail_result = {this.state.comp_fc_autofail_result}/>
                    </div>
                  );
                  break;
              }
            }
          })()}
        </div>
      );
    }
    if (this.state.page_view === "answerview") {
      return <AnswerView closeAnswerView={this.closeAnswerView} changeCurView={this.changeCurView} parentview="fcindex" setBreadcrumbLastText={this.props.setBreadcrumbLastText} />;
    } else if (this.state.page_view === "review") {
      return <AnswerView closeAnswerView={this.closeAnswerView} changeCurView={this.changeCurView} parentview="fcindex" setBreadcrumbLastText={this.props.setBreadcrumbLastText} readonly={true} showPage={this.showPage} />;
    } else if (this.state.page_view === "qcview") {
      return <QcView closeQcView={this.closeQcView} changeCurView={this.changeCurView} parentview="fcindex" readonly={true} showPage={this.showPage} />;
    } else if (this.state.page_view === "assignview") {
      return (
        <div style={{ marginLeft: "10px" }}>
          <AssignFileCheck task={this.state.task} changeCurView={this.changeCurView} parentview="fcindex" />
        </div>
      );
    }
  }
}
export default FCIndex;
