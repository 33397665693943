import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import CSLTable from '../Common/CSLTable';
import FollowupModal from '../Partials/FollowupModal';
import CSLLoader from '../Common/CSLLoader';
import CSLPageloader from '../Common/CSLPageloader';
import moment from 'moment';
import QcView from '../Common/QcView';
import ClipLoader from "react-spinners/ClipLoader";
import {IoIosTrash} from 'react-icons/io';
import ReportModal from '../MGR/ReportModal';
import FCKPrintPreview from '../FCKPrintPreview'
import EventBus from '../../Common/EventBus.js';
import Utils from "../../Common/Utils";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

const StatusButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 15px;
`;

const DummyOverlay = styled.div`
  display: table;
  box-sizing: border-box;
  height: calc(100vh - 65px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 65px;
  z-index: 1000;
  opacity: 0.6;
`;

const DelButton = styled.div`
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
`;

class QAIndex extends React.Component
{
    state = {
        ready: false,
        view_count: 0,
        modal_view: null,
        page_view: "qa_index",
        quality_assurance_enabled: false,
        show_spinner: false,
        pending_tasks: null,
        completed_fck_tasks: null,
        show_fck_completed: false,
        followup_tasks: null,   
        show_spinner: false,     
        comp_fc_autofail_result: [],
        pending_filters: {
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},            
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
            due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        completed_fck_filters: {
            reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
            reviewee: { name: "reviewee", nickname: "Reviewee", type: "multi", options: {}, selections: {}, size: 2 },
            customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
            file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
            company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
            completed_date: { name: "completed_date", nickname: "Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
        },
        followup_filters: {
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},            
            due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        pending_qa_review_header_text: "Pending QA Reviews: --/--/---- - --/--/----",
        complete_header_text: "Completed File Reviews"
    };

    constructor(props) {
        super(props);
    }

    componentDidMount()
    {
        const today = moment().format('DD/MM/YYYY');
        const formated_to_date = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();
        const api = new APICall();
        let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters))
        let followup_filters = JSON.parse(JSON.stringify(this.state.followup_filters))
        pending_filters.due_date.selections = {from_date: today, to_date: formated_to_date};
        followup_filters.due_date.selections = {from_date: today, to_date: today};
        const payload = {command: "list_tasks",onloading:1, filters: {pending_filters, followup_filters}};        
        if(Store.getStoreData('fc_index') === 0)api.command(payload, this.listTasks);
        if (Store.getStoreData('fc_index') !== null && Store.getStoreData('fc_index') !== 0) {            
            const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
            api.command(postData, this.singleTask);
        }
        let quality_assurance_enabled = this.state.quality_assurance_enabled;
        let module_config = Store.getStoreData('module_config');
        if(Object.keys(module_config).length > 0) {
            quality_assurance_enabled = module_config.general.quality_assurance_enabled;
        }
        EventBus.registerEvent('followup_saved_from_parent', 'QAIndex', this.showResult_parent)
        this.setState({quality_assurance_enabled: quality_assurance_enabled});
    }

    listTasks_old = (result) => {
        console.log("result:",result);
        let parent_tasks = [];
        let followup_tasks = [];
        result.result.forEach((task) => {
            if (task.task_type === "COMP_FCK") {
                const task_json = JSON.parse(task.task_json);
                if ("version" in task_json.object_data.headerinfo.review_json) {
                    parent_tasks.push(task);
                }
            } else {
                followup_tasks.push(task);
            }
        })
        Store.updateStore('followup_tasks', followup_tasks);
        let fc_tasks = [];
        for (let i=0; i<parent_tasks.length; i++) {
            if (parent_tasks[i].cur_assigned_to === Store.getStoreData('loggedin_user_info').ID) {
                fc_tasks.push(parent_tasks[i]);
            }
        }
        Store.updateStore('fc_tasks', fc_tasks);
        this.setState({ready: true});
    }

    listTasks = (result) => {
        console.log("result:",result);        
        this.setState({
            pending_tasks: result.result.pending_tasks.tasks.length === 0 ? null : result.result.pending_tasks.tasks[0],
            pending_filters: result.result.pending_tasks.filters,            
            followup_tasks: result.result.followup_tasks.tasks.length === 0 ? null : result.result.followup_tasks.tasks,
            followup_filters: result.result.followup_tasks.filters,            
            ready: true
        });
        console.log("result state:",this.state); 
    }

    singleTask = (result) => {
        console.log("singleTask", result.result.task.parenttask.cur_lane);
        if(this.props.show_task){
            if(result.result.task.parenttask.cur_lane === "COMP_FCK_QC_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_FCK_QC_COMPLETE"){
                // this.props.changeCurView('qcview');
                this.setState({page_view: 'qcview'})
            }else if (result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE") {
                Store.updateStore('followup_task', {parenttask: result.result.task.original_parenttask, subtask: result.result.task.parenttask, childtask: result.result.task.childtask});
                // this.setState({modal_view: 'view_followup_modal'});
                // this.view = "manager_index";
                this.setState({modal_view: "view_followup_modal"});
                // this.modal_view = "view_followup_modal";
            }
        }
        // this.setState({task: result, page_view: this.view});
        // this.setState({page_view: view});
    }

    refreshState = (modal_view) => {
        this.setState({view_count: this.state.view_count + 1, modal_view: modal_view});
    }
    closeReportmodal = () => {
        this.setState({modal_view: null});
    }

    showQc = (task_id) => (event) => {
        event.preventDefault();
        if(this.state.quality_assurance_enabled) {
            Store.updateStore('fc_index', task_id);
            // this.props.changeCurView('qcview');
            this.setState({page_view: 'qcview'})
        }else {
            alert("Quality assurance disabled");
            return false;
        }
        
    }

    // openMyReportModal = (result) => {
    //     Store.updateStore('report_task_json', JSON.parse(result.result.task.parenttask.task_json))
    //     Store.updateStore('report_task', result.result.task)
    //     this.setState({modal_view: 'view_my_report_modal'});
    // }

    showResult = (task_id) => (event) => {
        event.preventDefault();
        let tasks = Store.getStoreData('completed_fctasks');
        // let result = tasks[index];
        document.documentElement.scrollTop = 0;
        let postData = { command: "get_task", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openReportModal);
    }

    showResult_parent = (task_id)  => {
        this.setState({'show_spinner': true})
        document.documentElement.scrollTop = 0;
        // Store.updateStore('report', JSON.parse(result.task_json).object_data);
        let postData = { command: "get_task", task_id: task_id };
        let api = new APICall();
        api.command(postData, this.openReportModal);
      };
    openReportModal = (result) => {
        Store.updateStore('report_task_json', JSON.parse(result.result.task.parenttask.task_json))
        Store.updateStore('report_task', result.result.task)
        let comp_fc_autofail_result = []
        comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
        this.setState({modal_view: 'view_report_modal',show_spinner:false, comp_fc_autofail_result});
    }

    pendingQATasksData = () => {
        //let pending_tasks = Store.getStoreData('fc_tasks');
        let has_gc = Store.getStoreData('parent_has_group_companies');
        // console.log("pending_tasks", pending_tasks);
        let contacts = Store.getStoreData('users');
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = "";
        let ret = {data: [], columns: []};
        ret.columns =[  
            {
                Header: 'Status',
                accessor: 'status',
                minWidth: 40,
                headerStyle: {textAlign: 'left'},
                Cell: cellInfo => (<div><StatusButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</StatusButton></div>),
                sortMethod: (a, b) => {
                    return a.text > b.text ? 1 : -1;
                },
                filterMethod: (filter, row) => {
                    console.log(row);
                    console.log(filter);
                    return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
                }
            }, 
            {Header: 'Task', accessor: 'task', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Assign To', accessor: 'assignto', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
            {Header: 'File Reference', accessor: 'file_reference', minWidth: 30, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Customer Name', accessor: 'customer_name', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Company', accessor: 'company', minWidth: 40, headerStyle: {textAlign: 'left'}, show: has_gc}, 
            {Header: 'Reviewer', accessor: 'reviewer', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Due Date', accessor: 'due_date', minWidth: 30, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                if (a === b) {
                    return 0;
                }
                let adue = a.split("/");
                const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                let  bbdue = b.split("/");
                const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                return aReverse > bReverse ? 1 : -1;
            }}
        ];
        if (Store.getAllStoreData("loggedin_user_info").role !== "view_only_manager") {
            ret.columns.push(
                {'Header' : 'Action', filterable: false, Cell: row => (
                    <div>
                        <MRButton style={{width: '67px'}} onClick={this.showQc(row.original.id)}>View</MRButton>
                    </div>
                 ), minWidth: 40, headerStyle: {textAlign: 'left'}}
            );
        }
        if (this.state.pending_tasks !== null) {
        for(let d of this.state.pending_tasks) {
            let today = moment().unix();
            const due_date = moment.unix(d[5]).set('hour', 23).set('minute', 59).set('second', 59).unix();
            console.log("today:", today);
            console.log("due_date:", d[5]);
            let text = due_date > today ? 'Pending' : 'Overdue';
            let color = due_date > today ? '#2E8B57' : '#FF0000';
            let contact = "You"
            console.log("D12::",d[12])
            console.log("contacts::",contacts)
            if(d[12] !== 0) {
                contact = contacts[d[12].toString()].ContactName;
            }
            const review_name = d[10];
            let task = contact + " have been assigned a "+review_name+" QA Review";
            let related_individual = "N/A";
            let confirmed_by = "N/A";
            let confirmed_date = "N/A";
            let confirmation_text = "N/A";
            if (d[15] !== 0) {
                related_individual = d[15].toString() in contacts ? contacts[d[15].toString()].ContactName : "Inactive User";
            }
            if (d[16] !== 0) {
                confirmed_by = d[16].toString() in contacts ? contacts[d[16].toString()].ContactName : "Inactive User";
            }
            if (d[17] !== 0) {
                confirmed_date = moment.unix(d[17]).format("DD/MM/YYYY");
            }
            if (d[18] !== "") {
                confirmation_text = d[18];
            }
            let elem = {
                'status' : {text: text, color: color},
                'task' : task,
                'assignto' : d[11].toString() in contacts ? contacts[d[11].toString()].ContactName : 'N/A',
                'file_reference' : d[9].replace(/'+/g,"'"),
                'customer_name' : d[1],
                'company' : d[2],
                'reviewer' : d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
                'due_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                'status_value': text,
                'id' : d[0],
                'related_individual': related_individual,
                'confirmed_by': confirmed_by,
                'confirmed_date': confirmed_date,
                'confirmation_text': confirmation_text
            };
            ret.data.push(elem);
        }
        }
        ret.data = ret.data.sort((a,b) => {
            if (a.due_date === b.due_date) {
              return 0;
            }
            let adue = a.due_date.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let  bbdue = b.due_date.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse < bReverse ? 1 : -1;
        });
        ret.export_columns = {
            status_value: "Status",
            task: "Task",
            assignto: "Assigned To",
            file_reference: "File Reference",
            customer_name: "Customer",
            company: "Company",
            reviewer: "Reviewer",
            due_date: "Due Date",
            related_individual: "Related Individual",
            confirmed_by: "File Edited By",
            confirmed_date: "File Edited Date",
            confirmation_text: "File Edit Comments"
        };
        return ret;
    }

    processFckCompleteTasksData = () => {
        let contacts = Store.getStoreData("users");
        const has_gc = Store.getStoreData("parent_has_group_companies");
        let prefered_columns = {};
        let ret = { data: [], columns: [] };
        let i = 0;
        ret.columns = [
            { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
            { Header: "File Reference", accessor: "file_ref", minWidth: 150, headerStyle: { textAlign: "left" } },
            { Header: "Customer Name", accessor: "customer_name", minWidth: 150, headerStyle: { textAlign: "left" } },
            { Header: "Company", accessor: "company", minWidth: 120, headerStyle: { textAlign: "left" }, show: has_gc },
            { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
            { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
            {
              Header: "Completed Date",
              accessor: "completed_date",
              minWidth: 120,
              headerStyle: { textAlign: "left" },
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                let adue = a.split("/");
                const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                let bbdue = b.split("/");
                const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                return aReverse > bReverse ? 1 : -1;
              },
            },
            {
              Header: "Action",
              filterable: false,
              Cell: (row) => (
                <div style={{ width: "100%", boxSizing: "border-box" }}>
                  <div style={{ float: "left" }}>
                    <MRButton onClick={this.showResult(row.original.id)}>Show Report</MRButton>
                  </div>
                  <div style={{ float: "left" }}>
                    {Store.getStoreData("role") === "admin_manager" && (
                      <DelButton onClick={this.askDeleteCompletedTask(row.original.id)}>
                        <IoIosTrash />
                      </DelButton>
                    )}
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
              ),
              width: 210,
              headerStyle: { textAlign: "left" },
            },
        ];
        prefered_columns = {
            file_ref: "File Reference",
            reviewee: "Reviewee",
            reviewer: "Reviewer",
            schdate: "Scheduled Date",
            status: "Status",
            customer_name: "Customer Name",
            sale_date: "Policy Start Date",
            assign_date: "Assigned Date",
            file_type: "File Type",
            completed_date: "Review Date",
            score: "Score",
            result: "Result",
            related_individual: "Related Individual",
            confirmed_by: "File Edited By",
            confirmed_date: "File Edited Date",
            confirmation_text: "File Edit Comments"
        };
        if (has_gc === true) prefered_columns.company = "Company";
        if (this.state.completed_fck_tasks !== null) {
            for (let d of this.state.completed_fck_tasks) {
                i++;
                let related_individual = "N/A";
                let confirmed_by = "N/A";
                let confirmed_date = "N/A";
                let confirmation_text = "N/A";
                if (d[16] !== 0) {
                    related_individual = d[16].toString() in contacts ? contacts[d[16].toString()].ContactName : "Inactive User";
                }
                if (d[17] !== 0) {
                    confirmed_by = d[17].toString() in contacts ? contacts[d[17].toString()].ContactName : "Inactive User";
                }
                if (d[18] !== 0) {
                    confirmed_date = moment.unix(d[18]).format("DD/MM/YYYY");
                }
                if (d[19] !== "") {
                    confirmation_text = d[19];
                }
                let elem = {
                    index: i,
                    file_ref: d[5].replace(/'+/g, "'"),
                    customer_name: d[1].replace(/'+/g, "'"),
                    company: d[2],
                    reviewee: d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "--",
                    reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "--",
                    id: d[0],
                    file_type: d[6],
                    schdate: moment.unix(d[12]).format("DD/MM/YYYY"),
                    status: "Completed",
                    sale_date: moment.unix(d[7]).format("DD/MM/YYYY"),
                    assign_date: moment.unix(d[8]).format("DD/MM/YYYY"),
                    completed_date: moment.unix(d[9]).format("DD/MM/YYYY"),
                    completed_date_timestamp: moment(d[9]).unix(),
                    score: d[10],
                    result: d[11],
                    related_individual: related_individual,
                    confirmed_by: confirmed_by,
                    confirmed_date: confirmed_date,
                    confirmation_text: confirmation_text
                };
                ret.data.push(elem);
            }
        }
        ret.data = ret.data.sort((a, b) => {
          if (a.completed_date === b.completed_date) {
            return 0;
          }
          let adue = a.completed_date.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
          let bbdue = b.completed_date.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse < bReverse ? 1 : -1;
        });
        ret.export_columns = prefered_columns;
        return ret;
    }

    processFollowupTasksData = () => {
        //let followup_tasks = Store.getStoreData('followup_tasks').sort((a,b) => new Date(a.due_date) - new Date(b.due_date));
        let contacts = Store.getStoreData('users');
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
                        {Header: 'Task', accessor: 'task', minWidth: 240, headerStyle: {textAlign: 'left'}},
                        {Header: 'Company Name', accessor: 'company_name', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Customer Name', accessor: 'customer_name', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Status', accessor: 'status', width: 120, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 120, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                                return 0;
                            }
                            let adue = a.split("/");
                            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                        }}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton style={{width: '150px'}} onClick={this.showFollowUp(row.original.id)} >View</MRButton>
                           </div>
                        ), headerStyle:{textAlign: 'left'}}];
        if (this.state.followup_tasks !== null) {
        for(let d of this.state.followup_tasks) {
            // console.log("f_task:", d);
            // let task_json = JSON.parse(d.task_json);
            //if (task_json.task_data.cur_assigned_to.toString() === Store.getStoreData('loggedin_user_info').ID.toString()) {
                i++;
                // let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
                // const c_date = new Date(due_date_str);
                // let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
                // let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
                // let year = c_date.getFullYear().toString();
                // const due_date = day+"/"+month+"/"+year;
                // // const due_date = `${year}/${month}/${day}`;
                // let ch_date = new Date(year, month - 1, day);
                // ch_date.setDate(ch_date.getDate() + 1);
                let today = moment().unix();       
                let text = (d[6]+86400) > today ? 'Pending' : 'Overdue';
                let color = (d[6]+86400) > today ? '#2E8B57' : '#FF0000';
                text = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
                color = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;
                //const assigned_to_name = d[4] === 0 ? contacts[d[4].toString()].ContactName : contacts[d[4].toString()].ContactName;
                let task = contacts[d[4].toString()].ContactName +" has been assigned a task titled "+ d[2];
                let elem = {'task' : task,
                            'company_name': d[1],
                            'assigned_to' : contacts[d[4].toString()].ContactName,
                            'status' : {text: text, color: color},
                            'status_text': text,
                            'due_date' : moment.unix(d[6]).format('DD/MM/YYYY'),
                            'id' : d[0],
                            'customer_name': d[8],
                            'task_response': d[11] !='' ? d[11].replace(/,/g, " ").replace(/\n/g, " ") : "",
                            'complete_date': d[7] !== null ? moment.unix(d[7]).format('DD/MM/YYYY') : ''
                    };
                ret.data.push(elem);
            //}
        }
    }
        ret.data = ret.data.sort((a,b) => {
          if (a.due_date === b.due_date) {
            return 0;
          }
          let adue = a.due_date.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
          let  bbdue = b.due_date.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse < bReverse ? 1 : -1;
        });
        ret.export_columns = {task: "TaskAA", company_name: "Company Name", assigned_to: "Assigned To", 'customer_name': 'Customer Name', status_text: "Status", due_date: "Due Date", task_response: "Task Response",complete_date: "Completed Date"};
        return ret;
    }

    // showFollowUp = (index) => (event) => {
    //     event.preventDefault();
    //     let tasks = Store.getStoreData('followup_tasks');
    //     let result = tasks[index];
    //     Store.updateStore('followup_task', result);
    //     this.setState({modal_view: 'view_followup_modal'});
    // }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        console.log("task_id", task_id)
        this.setState({show_spinner: true});
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
        //let tasks = Store.getStoreData('followup_tasks');
        // let result = tasks[index];
        // console.log("result followup", fc_tasks)
        // Store.updateStore('followup_task', result);
        // this.setState({modal_view: 'view_followup_modal'});
    }

    openFollowupModal = (result) => {
        console.log("inside openReportModal:", result.result.task);
        Store.updateStore('followup_task', result.result.task);
        this.setState({modal_view: 'view_followup_modal',show_spinner: false});
    }

    showPage = (page) => {
        this.setState({page_view : page});
    }

    applyPendingFilter = (filters) => {
        console.log("pending_filters:",filters);
        // this.setState({show_spinner: true});
        
        this.setState({
            pending_qa_review_header_text:"Pending QA Reviews:"+filters.due_date.selections.from_date+" - "+filters.due_date.selections.to_date
        })
        const postData = {command: "get_filtered_pending_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredPendingTasks);
    }

    processFilteredPendingTasks = (result) => {
        this.setState({pending_tasks: result});
    }

    clearPendingAdvancedFilter = () => {        
        window.location.reload();
    }

    closeQcView = (event) => {
        event.preventDefault();
        Store.updateStore("fc_index", null);
        if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
            window.location.assign(document.referrer);
        }
        this.setState({page_view: "qa_index"});
    }

    switchCompleteRadio = (event) => {
        event.preventDefault();
        console.log(this.state.show_fck_completed);
        if (this.state.show_fck_completed === true) {
            let completed_fck_filters = JSON.parse(JSON.stringify(this.state.completed_fck_filters));
            Object.keys(completed_fck_filters).forEach(key => {
                completed_fck_filters[key].selections = {};
            })
            this.setState({
                show_fck_completed: false,
                completed_fck_tasks: null,
                completed_fck_filters,
                complete_header_text:"Completed File Reviews"
            });
        } else {
            this.setState({complete_header_text: "Completed File Reviews: --/--/---- - --/--/----"})
            const postData = {command: "get_fck_complete_filter_options"};
            let api = new APICall();
            api.command(postData, this.processFckCompleteFilterOptions);
        }
    }

    processFckCompleteFilterOptions = (result) => {
        console.log(result);
        let completed_fck_filters = JSON.parse(JSON.stringify(this.state.completed_fck_filters));
        Object.keys(completed_fck_filters).forEach(key => {
            completed_fck_filters[key]['options'] = result[key];
            if (key === "completed_date") {
                const td_start = new Date(new Date().setDate(new Date().getDate() - 90));
                const td_end = new Date();
                let dd = td_start.getDate();
                let mm = td_start.getMonth() + 1;
                let yyyy = td_start.getFullYear();
                dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
                mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
                const td_str = `${dd}/${mm}/${yyyy}`;
                console.log("td_str",td_str);
                let dd1 = td_end.getDate();
                let mm1 = td_end.getMonth() + 1;
                let yyyy1 = td_end.getFullYear();
                dd1 = dd1 < 10 ? `0${dd1.toString()}` : dd1.toString();
                mm1 = mm1 < 10 ? `0${mm1.toString()}` : mm1.toString();
                const td_str_e = `${dd1}/${mm1}/${yyyy1}`;
                console.log("td_str_e",td_str_e);
                completed_fck_filters.completed_date.selections.from_date = td_str;
                completed_fck_filters.completed_date.selections.to_date = td_str_e;
            }
        })
        this.setState({
            show_fck_completed: true,
            completed_fck_filters,
            //show_spinner: false
        });
        const postData = {command: "get_filtered_completed_tasks", filters: completed_fck_filters};
        let api = new APICall();
        api.command(postData, this.processFilteredCompletedTasks);
    }

    clearFckCompletedAdvancedFilter = () => {
        console.log(this.state.completed_fck_filters);
        let completed_fck_filters = JSON.parse(JSON.stringify(this.state.completed_fck_filters));
        Object.keys(completed_fck_filters).forEach(key => {
            if (completed_fck_filters[key].type === "date_range") {
                completed_fck_filters[key].selections.from_date = moment().format('DD/MM/YYYY')
                completed_fck_filters[key].selections.to_date = moment().format('DD/MM/YYYY')
            } else {
                completed_fck_filters[key].selections = {};
            }
        })
        this.setState({completed_fck_filters,complete_header_text:"Completed File Reviews: --/--/---- - --/--/----"});
    }

    applyCompleteFilter = (filters) => {
        console.log(filters);
        this.setState({
            show_spinner: true,
            comp_qa_review_header_text: "Completed File Reviews: "+filters.completed_date.selections.from_date+" - "+filters.completed_date.selections.to_date
        });
        const postData = {command: "get_filtered_completed_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredCompletedTasks);
    }

    processFilteredCompletedTasks = (result) => {
        console.log(result);
        this.setState({completed_fck_tasks: result, show_spinner: false});
    }

    openPdfPrintView = () => {
        this.setState({page_view: 'pdfprintview'});
    }

    render()
    {
        if(Store.getStoreData('fc_index') === 0){
            if(Store.getStoreData('filechecker-list_tasks') === null) {
              return <div><CSLPageloader /></div>
            }
        }
        if (this.state.ready === false) {
            return <div><CSLLoader /></div>
        }
        if (this.state.show_spinner === true) {
            return <div><CSLLoader /></div>
        }
        if (this.state.page_view === "qcview") {
            return <QcView closeQcView={this.closeQcView} changeCurView={this.changeCurView} parentview="fcindex" showPage={this.showPage} />
        } else if(this.state.page_view === "pdfprintview"){
            return <FCKPrintPreview />
        } else {
            return (
                <div>
                    {(() => {
                        if (this.state.show_spinner === true) {
                        return (
                            <div>
                            <DummyOverlay>
                                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                                <ClipLoader size={150} color={"#123abc"} loading={true} />
                                </div>
                            </DummyOverlay>
                            </div>
                        );
                        }
                    })()}
                    <div style={{clear: "both"}}>&nbsp;</div>
                    {
                        (() => {
                            if(this.state.quality_assurance_enabled) {
                                return <CSLTable 
                                            add={false} 
                                            export={true}
                                            custom_fields={true}
                                            export_file_name="Pending_QA_Reviews" 
                                            processData={this.pendingQATasksData} 
                                            tableRows="10" 
                                            headerText={this.state.pending_qa_review_header_text} 
                                            refreshCallback={this.refreshState} 
                                            filters={this.state.pending_filters} 
                                            applyFilter={this.applyPendingFilter}
                                            clearAdvancedFilter={this.clearPendingAdvancedFilter}
                                        />
                            }
                        })()
                    }
                    <div style={{paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px"}}>
                        <CSLTable 
                            add={false} 
                            export={true}
                            custom_fields={true}
                            export_file_name="Completed_File_Reviews" 
                            processData={this.processFckCompleteTasksData} 
                            tableRows="10" 
                            headerText={this.state.complete_header_text}
                            refreshCallback={this.refreshState} 
                            toggle_text="Show Completed Reviews"
                            toggleCallback={this.switchCompleteRadio}
                            toggle_state={this.state.show_fck_completed}
                            filters={this.state.completed_fck_filters} 
                            applyFilter={this.applyCompleteFilter}
                            clearAdvancedFilter={this.clearFckCompletedAdvancedFilter} 
                        />  
                    </div>
                    
                    <div style={{clear: "both"}}></div>
                        <div style={{paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px"}}>
                            {
                                (() => {
                                    if (this.state.modal_view === 'view_followup_modal') {
                                        return <div style={{position:'absolute', left:'10%',  width:'100%', height:'1186px', top: '78vh'}}><InactiveOverlay /><FollowupModal closeModal={this.closeReportmodal} /></div>;
                                    } else if (this.state.modal_view === "view_report_modal") {
                                        return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay style={{height: "2384px"}} /><ReportModal closeModal={this.closeReportmodal} openPrintView={this.openPdfPrintView}  comp_fc_autofail_result = {this.state.comp_fc_autofail_result}/></div>;
                                    }
                                })()
                            }
                            <CSLTable 
                                add={false} 
                                export={true} 
                                export_file_name={"followup_tasks"} 
                                processData={this.processFollowupTasksData} 
                                tableRows="10" 
                                headerText={'Followup Tasks'} 
                            />
                        </div>
                </div>
            );
        }
    }
}
export default QAIndex;
