import React from 'react';
import styled from 'styled-components';
import FCMRTable from './FCMRTable';
import Store from '../../Common/Store.js';
import APICall from '../../Common/APICall';

const FCTablesHOCContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
`;
const FCTablesHOCInnerContainer = styled.div`
    display: block;
    box-sizing: border-box;
    background-color: #ffffff;
    padding-bottom: 10px;
    width: 100%;
`;

class FCConfigTablesHOC extends React.Component
{
    state = {
        ready: false,
        csl_model_reviews: [],
        own_model_reviews: [],
        env: null,
        ContactName: null,
        mgr_pending_table_data: [],
        mgr_completed_table_data: [],
        filechecker_pending_table_data: []
    };

    componentDidMount()
    {
        // let url = Store.getStoreData('api_url');
        // let is_admin = Store.getStoreData('is_admin');
        // let is_debug = Store.getStoreData('is_debug');
        // // let url = "http://localhost:5006/api/command";
        // let postData = {command: "index", env: is_admin ? "csl" : "select"};
        // if(!is_admin) {
        //     postData['section'] = "config";
        // }
        // let api = new APICall();
        // api.command(postData, this.processIndexData);
        const env = Store.getStoreData('is_admin') === true ? "csl" : "select";
        const my_reviews = Store.getStoreData('my_reviews');
        this.setState({ready: true, my_reviews, env});
    }

    processIndexData_old = (result) => {
        // console.log('result', result);
        if (Store.getStoreData('is_admin')) {
            Store.updateStore('role', result.role);
            Store.updateStore('env', "csl");
            this.setState({
                csl_model_reviews: result.result,
                env: 'csl',
                ready: true
            });
        } else {
            Store.updateStore('model_reviews', result.result.model_reviews);
            Store.updateStore('fc_tasks', result.result.fc_tasks);
            Store.updateStore('role', result.result.role);
            Store.updateStore('my_reviews', result.result.my_reviews);
            Store.updateStore('loggedin_user_info', result.result.loggedin_user_info);
            Store.updateStore('reviewee_info', result.result.comcontacts);
            Store.updateStore('env', "select");
            let fc_tasks = result.result.fc_tasks;
            for(let i=0; i < fc_tasks.length; i++) {
                fc_tasks[i].task_json = JSON.parse(fc_tasks[i].task_json);
            }
            let pending_tasks = [];
            if (result.result.pending_tasks.length !== 0) {
                for (const task of result.result.pending_tasks) {
                    const task_json = JSON.parse(task.task_json);
                    const rev = result.result.my_reviews.find((item) => {
                        return item.id === task_json.object_data.headerinfo.cur_review_id;
                    })
                    pending_tasks.push({
                        id: task.id,
                        name: rev.nick_name,
                        description: rev.description,
                        assigned_contact_id: task.cur_assigned_to,
                        assigned_contact_name: task.assigned_contact_name
                    })
                }
            }
            let completed_tasks = [];
            if (result.result.completed_tasks.length !== 0) {
                for (const task of result.result.completed_tasks) {
                    const task_json = JSON.parse(task.task_json);
                    const rev = result.result.my_reviews.find((item) => {
                        return item.id === task_json.object_data.headerinfo.cur_review_id;
                    })
                    completed_tasks.push({
                        id: task.id,
                        name: rev.nick_name,
                        description: rev.description,
                        result: task_json.object_data.result
                    })
                }
            }
            // console.log("FCKCOMPLETE:", completed_tasks);
            // console.log('fc_tasks', fc_tasks);
            let filechecker_pending_table_data = [];
            if (Store.getStoreData('role') === "file_checker" && result.result.fc_tasks.length !== 0) {
                for (const task of result.result.fc_tasks) {
                    filechecker_pending_table_data.push({
                        id: task.id,
                        review_id: task.task_json.object_data.headerinfo.cur_review_id,
                        name: task.task_json.object_data.headerinfo.review_json.name,
                        description: task.task_json.object_data.headerinfo.review_json.description,
                        review_json: task.task_json.object_data.headerinfo.review_json,
                        headerinfo: task.task_json.object_data.headerinfo
                    });
                }
            }
            this.setState({
                model_reviews: result.result.model_reviews,
                my_reviews: result.result.my_reviews,
                mgr_pending_table_data: pending_tasks,
                mgr_completed_table_data: completed_tasks,
                filechecker_pending_table_data: filechecker_pending_table_data,
                fc_tasks: [],
                env: 'select',
                ready: true
            });
        }
    }

    render()
    {
        if (!this.state.ready) {
            return (
                <div>Loading...</div>
            );
        }
        if (this.state.ready && this.state.env === "csl") {
            return (
                <div>
                </div>
            );
        }
        if (this.state.ready && this.state.env === "select") {
            let error_text = this.state.my_reviews.length === 0 ? 'You currently have no reviews set up. Please click on the New Review Type button to add one.' : '';
            let role = Store.getStoreData('role');
            if (this.state.my_reviews.length === 0 && role === 'file_checker') {
                error_text = "You do not have any files to review";
            }
            // console.log('here in config');
            if(role === 'admin_manager'){
                return (
                    <div>
                        <FCTablesHOCContainer>
                            <FCTablesHOCInnerContainer>
                                {/* <FCMRTable headertxt="File check types" add={true} tabledata={this.state.my_reviews} /> */}
                                <FCMRTable />
                            </FCTablesHOCInnerContainer>
                            {error_text}
                        </FCTablesHOCContainer>
                    </div>
                );
            } else if(role === 'manager' || role === 'team' || role === 'file_checker'){
                return (
                    <div style={{backgroundColor: "#F6DBDB", color: "#DE8D8E", padding: "10px", fontWeight: "600", margin: "10px"}}>
                        You are not authorised to access this page. If you think this is an error, please contact your administrator.
                    </div>
                );
            } else {
                return (
                    <div style={{backgroundColor: "#F6DBDB", color: "#DE8D8E", padding: "10px", fontWeight: "600", margin: "10px"}}>
                        You are not authorised to access this page. If you think this is an error, please contact your administrator.
                    </div>
                );
            }
        }
    }
}

export default FCConfigTablesHOC;