import React from 'react';
import './ReportModal.css';

class ReportQuestions extends React.Component
{
	state = {questions: null};

	componentDidMount()
	{
		this.setState({questions: this.props.questions});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({questions: this.props.questions});
		}
	}

	render()
	{
		if (this.state.questions === null) {
			return (
				<div>Loading...</div>
			);
		}
		return (
			<div>
				{
					this.state.questions.map((question) => {
						return (
							<div key={question.id} className="ReportQuestionContainer">
								<div style={{float: "left"}}>
									<div className="ReportQuestionIndex">Q{question.index}</div>
								</div>
								<div style={{float: "right"}}>
								{
									(() =>{
										if(this.props.score_enabled){
											return (
												<div className="ReportQuestionScorePill">{question.obtained_score}</div>
												)
										}else{
											return (
												<div className="ReportQuestionScorePill" style={{backgroundColor:"non_scoring_info" in question ? question.non_scoring_info.color : "#ffffff"}}>
													{"non_scoring_info" in question ? question.non_scoring_info.label.replace(/'+/g,"'") : ""}
												</div>
												)
										}
									})()
									
								}
								</div>
								{
									(() => {
										if (question.naallowed && !question.included) {
											return (
												<div style={{float: "right", marginRight: "5px"}}>
													<div className="ReportQuestionScorePill">n/a</div>
												</div>
											);
										}
									})()
								}
								<div style={{clear: "both"}}></div>
								<div className="ReportQuestionText">{question.question.replace(/'+/g,"'")}</div>
								<div className="ReportAnswerLabel">Answer</div>
								<div className="ReportAnswerText">{question.answer.text.replace(/'+/g,"'")}</div>
								<div className="ReportAnswerLabel">Comments</div>
								<div className="ReportAnswerText">{question.comments.enabled ? "text" in question.comments ? question.comments.text.replace(/'+/g,"'") : "" : ""}</div>
							</div>
						);
					})	
				}
			</div>
		);
	}
}

export default ReportQuestions;