import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
import APICall from '../../Common/APICall';
import DatePicker from 'react-date-picker';
import {FaPlusCircle, FaTimes} from 'react-icons/fa';
import Dropzone from '../Common/Dropzone.js';
import CustomDropzone from '../Common/CustomDropzone.js';
import CSLLoader from '../Common/CSLLoader.js';
import './Followup.css';


const OverviewInfoHeader = styled.div`
	padding: 12px 15px;
	background-color: #04ada8;
	border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;
const HeaderButton = styled.div`
    float: right;
    font-size: 20px;
    cursor: pointer;
`;
const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: Montserrat,sans-serif;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const FCKCommentDiv = styled.div`
    padding-left: 20px;
    padding-right: 8px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 100;
    font-family: 'Montserrat', sans-serif;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
const ScoreContainer = styled.div`
    background-color: #ecf0f1;    
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
    padding: 15px;
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;    
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`

const ScoreBodyContainer = styled.div`
    padding: 15px;
`;

class AddTaskModal extends React.Component
{
    state = { showModal: false, followup_sub: "", followup_note: "", assigned_to: 0, review_list: [], ready: false, due_by: new Date(), parent_task_id: null, send_mail_config: false, parent_company_id: 0, binFiles: [],curBinFiles: [], cur_files_json: null}

    componentDidMount()
	{
        console.log("parent_company_id", this.props.parent_company_id);
        console.log("reviewer", this.props.reviewer);
        const admin_managers = Store.getStoreData("admin_managers");
        let review_list = [];
        const gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company_id = parseInt(this.props.parent_company_id) < 100000 ? this.props.parent_company_id : (parseInt(this.props.parent_company_id)%100000);
        for (let key in gc_assoc[gc_company_id.toString()].users) {
            review_list.push(gc_assoc[gc_company_id.toString()].users[key.toString()]);
        }
        for (let key in admin_managers) {
            const exist = review_list.find(rl => rl.ID.toString() === key.toString());
            if (exist === undefined) {
                review_list.push({ID: key, ContactName: admin_managers[key.toString()].name});
            }
        }
        if (review_list.length > 1) {
            review_list.sort((a,b) => (a.ContactName.toLowerCase() > b.ContactName.toLowerCase()) ? 1 : ((b.ContactName.toLowerCase() > a.ContactName.toLowerCase()) ? -1 : 0));
        }
        const task_reviewer = review_list.find(rl => rl.ID === this.props.reviewer.ID);
        let assigned_to = task_reviewer === undefined ? review_list[0].ID : task_reviewer.ID;
        let send_mail_config = false;
        let module_config = Store.getStoreData('module_config');
        if(Object.keys(module_config).length > 0) {
            send_mail_config =  module_config.general.email_remainder_enable;
        }
        console.log("this.props.parent_company_id ====>", send_mail_config);
        this.setState({ review_list , ready: true, followup_sub: "", followup_note: "", due_by: new Date(),  parent_task_id: this.props.parent_task_id, assigned_to: assigned_to, send_mail_config, parent_company_id: this.props.parent_company_id});   
    }

    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let review_list = [];
            const admin_managers = Store.getStoreData("admin_managers");
            const gc_assoc = Store.getStoreData('gc_assoc');
            let gc_company_id = parseInt(this.props.parent_company_id) < 100000 ? this.props.parent_company_id : (parseInt(this.props.parent_company_id)%100000);
            for (let key in gc_assoc[gc_company_id.toString()].users) {
                review_list.push(gc_assoc[gc_company_id.toString()].users[key.toString()]);
            }
            for (let key in admin_managers) {
                const exist = review_list.find(rl => rl.ID.toString() === key.toString());
                if (exist === undefined) {
                    review_list.push({ID: key, ContactName: admin_managers[key.toString()].name});
                }
            }
            if (review_list.length > 1) {
                review_list.sort((a,b) => (a.ContactName.toLowerCase() > b.ContactName.toLowerCase()) ? 1 : ((b.ContactName.toLowerCase() > a.ContactName.toLowerCase()) ? -1 : 0));
            }
            let send_mail_config = false;
            let module_config = Store.getStoreData('module_config');
            if(Object.keys(module_config).length > 0) {
                send_mail_config =  module_config.general.email_remainder_enable;
            }
            this.setState({ review_list , ready: true,  parent_task_id: this.props.parent_task_id, send_mail_config, parent_company_id: this.props.parent_company_id, showModal: false});
           
		}
    }



    showFollowupTaskModal = (event) => {
        event.preventDefault();
        this.setState({ followup_sub: "", followup_note: "", due_by: new Date(), showModal: true, attachments: [] });
    }

    closeFollowupTaskModal = (event) => {
        event.preventDefault()
        this.setState({ showModal: false });
    }
    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }
    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({followup_note: event.target.value});
    }
    onDateChangeDueBy = (date) => {
        this.setState({due_by: date});
    }

    getMailStrucData = (data) => {
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        const all_users = Store.getStoreData("users");
        let date = new Date();

        let mail_body = data.body;
        mail_body = mail_body.replace("{{scheduled_date}}", this.state.due_by);
        mail_body = mail_body.replace("{{task_name}}", this.state.followup_sub);
        mail_body = mail_body.replace("{{task_description}}", this.state.followup_note);
        mail_body = mail_body.replace("{{status}}", "Create and Assign a sub task");
        mail_body = mail_body.replace("{{created_date}}", date);
        mail_body = mail_body.replace("{{assigner}}", loggedin_user_info.ContactName);
        mail_body = mail_body.replace("{{assigned_to}}", all_users[(this.state.assigned_to).toString()].ContactName);
        data.body = mail_body;

        let mail_subject = data.subject;
        mail_subject = mail_subject.replace("{{scheduled_date}}", this.state.due_by);
        mail_subject = mail_subject.replace("{{task_name}}", this.state.followup_sub);
        mail_subject = mail_subject.replace("{{task_description}}", this.state.followup_note);
        mail_subject = mail_subject.replace("{{status}}", "Create and Assign a sub task");
        mail_subject = mail_subject.replace("{{created_date}}", date);
        mail_subject = mail_subject.replace("{{assigner}}", loggedin_user_info.ContactName);
        mail_subject = mail_subject.replace("{{assigned_to}}", all_users[(this.state.assigned_to).toString()].ContactName);
        data.subject = mail_subject;
        return data;
    }

    addSubTask_old = () => {
        const mod_due_date_obj = new Date(this.state.due_by);
        const mod_due_date = mod_due_date_obj.getFullYear() + "/" + (mod_due_date_obj.getMonth() + 1) + "/" + mod_due_date_obj.getDate();
        let module_config = Store.getStoreData('module_config');
        let send_mail_config_data = {};
        let mail_config = {};
        let send_mail = this.state.send_mail_config;
        console.log("send mail", send_mail);
        if(send_mail) {
            if(Object.keys(module_config).length > 0) {
                mail_config = module_config.email.find((obj) => {
                    return obj.id === "YRfW"
                });
            }
            send_mail = mail_config.enabled;
            if(send_mail){
                send_mail_config_data = this.getMailStrucData(mail_config);
            }
            console.log(mail_config);
        }
        let bin_files = this.state.cur_files_json === null ? this.state.binFiles : [...this.state.binFiles, ...this.state.cur_files_json];
        console.log("bin_files==>",bin_files)
        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "filechecker", "last_action": "COMP_FCK_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assigned_to, 'task_type' : 'COMP_FCK_FOLLOWUP', 'parent_task_id' : this.state.parent_task_id, 'cur_lane': 'COMP_FCK_FOLLOWUP_ASSIGNMENT', due_date: mod_due_date, 'parent_company_id': this.state.parent_company_id},
            "object_data": {subject: this.state.followup_sub, note: this.state.followup_note},
            "action_data": [{ "action": "COMP_FCK_FOLLOWUP", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_FCK_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.followup_note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, "mail_url": window.location.href, "mail_config": send_mail_config_data, "send_mail": send_mail, bin_files: bin_files };
        console.log("postData", postData);
        let api = new APICall();
        api.command(postData, this.processAddTask);
    }

    addSubTask = () => {
        let ref_id = Utils.genKey(12);
        const mod_due_date_obj = new Date(this.state.due_by);
        const mod_due_date = mod_due_date_obj.getFullYear() + "/" + (mod_due_date_obj.getMonth() + 1) + "/" + mod_due_date_obj.getDate();
        const obj = {
            id: ref_id,
            ref_id: ref_id,
            subject: this.state.followup_sub,
            note: this.state.followup_note,
            assigned_to: this.state.assigned_to,
            assigned_by: Store.getStoreData("loggedin_user_info").ID,
            due_date: mod_due_date,
            attachments: this.state.attachments
        }
        this.props.addFutureFollowup(obj);
    }

    processAddTask = (result) => {
        alert("The follow-up task has been added.");
        this.props.reloadChildTasks();
    }

    filesLoaded = (files) => {
        let me = Store.getStoreData('loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }

    addAttachment = (files) => {
        console.log("files:", files);
    }

    render()
	{
        if (!this.state.ready) {
			return (
				<div></div>
			);
		}
        return(<div>
            <CSLLoader style={{position: 'absolute'}} />
            <OverviewInfoHeader>
                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Tasks associated with this file check</div>
                {
                    (() => {
                        let readonly = this.props.readonly === undefined ? false : this.props.readonly;
                        if (readonly === false) {
                            return (
                                <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showFollowupTaskModal}><FaPlusCircle /></div>
                            );
                        }
                    })()
                }
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>
            {
                (() => {
                    if(this.state.showModal) {
                        return(
                            <ScoreContainer>
                                <ScoreHeaderContainer>Add a follow-up task to this file check<HeaderButton onClick={this.closeFollowupTaskModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
                                <ScoreBodyContainer>
                                    <MRModalLabel>Subject</MRModalLabel>
                                    <MRModalInput style={{width: "99%"}} type="text" name="followup_sub" onChange={this.handleChange} value={this.state.followup_sub}/>
                                    <FCKCommentDiv style={{padding: "0px", marginTop: "10px"}}>
                                        <MRModalLabel>Note</MRModalLabel>
                                        <FCKTextarea rows="6" style={{width: "99%"}} value={this.state.followup_note} onChange={this.changeFollowupNote} />
                                    </FCKCommentDiv>
                                    <MRModalLabel>Assign To</MRModalLabel>
                                    <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to}>
                                        {
                                            (() => {
                                                if(this.state.review_list.length !== 0) {
                                                    let reviewees = [];
                                                    console.log("this.state.reviewee_id", this.state.reviewee_id);
                                                    for(let item of this.state.review_list){
                                                        reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                                        
                                                    }
                                                    return reviewees;
                                                }
                
                                            })()
                                        }
                                    </MRModalSelect>
                                    <MRModalLabel>Due By</MRModalLabel>
                                    <CSLDateCover id="followdate">
                                        <DatePicker
                                                onChange={this.onDateChangeDueBy}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                locale={"en-GB"}
                                                value={this.state.due_by}
                                                />
                                    </CSLDateCover>
                                    {/* <Dropzone onFilesAdded={this.filesLoaded} /> */}
                                    {/* <CustomDropzone attachments={this.state.attachments} addAttachment={this.addAttachment} /> */}
                                </ScoreBodyContainer>
                                <div style={{height: '60px', width: '100%', paddingRight: "12px"}}>
                                    <MRModalNextBtn onClick={this.addSubTask}>Add Task</MRModalNextBtn>
                                    <MRModalCancelBtn onClick={this.closeFollowupTaskModal}>Close</MRModalCancelBtn>
                                </div>
                            </ScoreContainer>
                        );
                    }
                })()
            }
        </div>);
    }

}
export default AddTaskModal;
