import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import { FaPlusCircle} from 'react-icons/fa';
import {plusCircle} from 'react-icons-kit/fa/plusCircle';
import SaleDateField from './SaleDateField';
import SingleCustomField from './SingleCustomField';
import RevieweeDesignationField from './RevieweeDesignationField';
import Utils from '../../Common/Utils';
import { Container } from 'react-smooth-dnd';
import CSLToggle from './../Common/CSLToggle';
import VEllipsisMenuFolder from "./../Common/VerticalEllipsisMenuFolder";
import AddEditCustomField from './AddEditCustomField';
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";

const EmailContainer = styled.div`
	width: 70%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;
const FieldNameTitleHeader = styled.div`
  text-align: left;
  flex: 75 0 auto;
//   width: 160px;
  width: 12%;
  min-width: 30%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const DisplayForTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
//   width: 80px;
  min-width: 16%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const ActionHeader = styled.div`
  text-align: center;
  flex: 100 0 auto;
//   width: 70px;
	min-width: 7%;
//   max-width: 55px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
  font-weight: bold;
`;

const ElipsisHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
//   width: 20px;
//   max-width: 20px;
  min-width: 4%;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
  font-weight: bold;
`;
const SerialHeader = styled.div`
  text-align: center;
  flex: 30 0 auto;
  width: 4%;
  max-width: 4%;
  cursor: pointer;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  font-weight: bold;
  height: 35px;
`;
const FieldTypeTitleHeader = styled.div`
  text-align: left;
  flex: 0 0 auto;
//   width: 140px;
  width: 20%;
  min-width: 16%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const RequiredTitleHeader = styled.div`
  text-align: left;
  flex: 0 0 auto;
	width: 30px;
	min-width: 13%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const CtfStatusHeader = styled.div`
  text-align: left;
  flex: 0 0 auto;
	width: 30px;
	min-width: 13%;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
  font-weight: bold;
`;
const EmailTopHeader = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
	width:100%;
	height: 58px;
	padding: 20px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background-color: #ffffff;
	box-sizing: border-box;	
`;
const AddButton = styled.div`
  width: 30px;
  height: 30px;
  margin: 0px;
  float: right;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 150%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -2%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;
class CustomFields extends React.Component
{
	state = {
		custom_fields: null,
		final_custom_field: null,
		arrayAdditionalField: {},
		show_add_edit_custom_modal: false,
		origin: "ctf",
		curid: 0,
		content: null,
		alert_param: null
	};

	componentDidMount()
	{
		console.log("PROPS::",this.props)
		let arrayAdditionalField = Object.values(this.props.custom_fields.additional_fields);
		console.log("arrayAdditionalField:", arrayAdditionalField);
		this.setState({custom_fields: JSON.parse(JSON.stringify(this.props.custom_fields)), arrayAdditionalField,final_custom_field: this.props.custom_fields});
	}

	componentDidUpdate(prevProps)
	{
		// console.log()
		if (this.props !== prevProps) {
			// alert(2)
			let arrayAdditionalField = Object.values(this.props.custom_fields.additional_fields);
			this.setState({custom_fields: JSON.parse(JSON.stringify(this.props.custom_fields)), arrayAdditionalField, final_custom_field: this.props.custom_fields});
		}
	}

	switchRadio = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		console.log("custom fields in switchRadio:", custom_fields);
		custom_fields.enabled = custom_fields.enabled === true ? false : true;
		this.props.updateCustomFields(custom_fields);
	}

	addAdditionalField = (event) => {
		event.preventDefault();
		console.log("addAdditionalField");
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		// const utils = new Utils();
		const randtxt = Utils.genKey(10);
		custom_fields.additional_fields.push({name: randtxt, nickname: "Untitled Field", type: "Alpha", dropdown_options: [], required: false, other: false});
		this.props.updateCustomFields(custom_fields);
	}
	handleCtfStatus = (name,isActive) => (e) => {
		e.stopPropagation();
		let custom_terminology_field = this.state.custom_fields.custom_terminology_fields.find(item => item.name === name);;
		let status = isActive ? false : true;
		custom_terminology_field.is_active = status
		console.log("handlenaCtfStatus",status,custom_terminology_field)
		this.updateAdditionalFieldsValue(custom_terminology_field,"ctf")

	}
	handleAdfStatus = (name,isActive) => (e) => {
		e.stopPropagation();
		let additional_field = this.state.custom_fields.additional_fields.find(item => item.name === name);;
		let status = isActive ? false : true;
		additional_field.is_active = status;
		this.updateAdditionalFieldsValue(additional_field,"adf")

	}
	editDelete = (curid, origin) => (obj) => {
		console.log("editDelete curid==>", curid)
		console.log("editDelete obj==>", obj)
		console.log("editDelete origin==>", origin);
		let content = null;
		if(obj === 'Edit Custom Field'){
			if (origin === "adf") {
				content = this.state.arrayAdditionalField.find((item) => {
					return item.name === curid;
				})
			} else {
				content = this.state.custom_fields.custom_terminology_fields.find(item => item.name === curid);
			}
			console.log("editDelete content==>", content);

			this.setState({content,curid: curid,show_add_edit_custom_modal: true, origin});  
		}else if(obj === 'Delete'){
			let alert_param = {title: 'Warning', message: 'Are you sure you want to delete this custom field?', ok_text: 'Yes', cancel_text: 'No', confirm: true, 
						alertHandler: this.updatedeleteCustomField, stack: {id: curid}}
			this.setState({alert_param: alert_param})
		}
	};

	updatedeleteCustomField = (result, stack) => {
		console.log(result, stack);
		if (result === false) {
			this.setState({alert_param: null});
			return;
		}
		this.setState({alert_param: null});
		this.props.deleteCustomAdditionalField(stack.id);
        // if(!result) {
        //     this.setState({alert_param: null})
        //     return
        // }
        // console.log("id",stack.id)           	
        // let newreason = [];
		// for(let i=0;i<this.state.arrayAdditionalField.length;i++)
		// {
		// 	if(this.state.arrayAdditionalField[i].name !== stack.id){
		// 		newreason.push(this.state.arrayAdditionalField[i])
		// 	}
		// }			 
		// console.log("delete===>",newreason); 
		// this.setState({alert_param: null})  
		// this.props.deleteCustomAdditionalField(newreason);  		
    }

	getKey() {
        return this.keyCount++;
    }
	
	addfield = (event) => {
        event.preventDefault();
        this.setState({ show_add_edit_custom_modal: true, curid: 0 });
    }

	closeModal = () => {		
		this.setState({show_add_edit_custom_modal: false})
    }
	saveAdditionalFieldsValue = (obj) => {
		let arrayAdditionalField = this.state.arrayAdditionalField;
		arrayAdditionalField.push(obj)
		this.setState({arrayAdditionalField, show_add_edit_custom_modal: false})
		this.props.updateSaveCustomAdditionalField(arrayAdditionalField);			
    }

	updateAdditionalFieldsValue = (content, origin) => {
		console.log("content:", content);
		console.log("origin:", origin);
		if (origin === "ctf") {
			this.setState({show_add_edit_custom_modal: false});
			this.props.updateCtfs(content);
		} else {
			let {arrayAdditionalField} = this.state;
			let newreason = [];
			arrayAdditionalField.forEach((item) => {
				if (item.name === content.name) {
					item = content;			
				}
				newreason.push(item);
			})
			console.log("resons:", newreason);
			this.setState({arrayAdditionalField: newreason, show_add_edit_custom_modal: false})
			this.props.updateCustomAdditionalField(newreason);
		}			
	}
	toggleStatus = (state, id) => {
		// console.log("state, id:", state, id);
		this.state.arrayAdditionalField[id.toString()].is_active = state;
		this.props.updateCustomFieldStatus(this.state.arrayAdditionalField);
    }
	factory = (a) => {
        //debugger;
        const obj = {}
        let c = a.forEach((q, i) => {
            obj[i + 1] = q
        })
        return obj;
    }
	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}
		return (
			<div>
				{
                    (() => {
                        if (this.state.show_add_edit_custom_modal) {
                            return (
								<div style={{position:'absolute', left:'2%', top:'50px', width:'100%', height:'600px'}}>
									<InactiveOverlay />
									<AddEditCustomField outer_data_key="additional_fields" closeModal={this.closeModal} data={this.state.custom_fields} curid={this.state.curid} origin={this.state.origin} saveAdditionalFieldsValue={this.saveAdditionalFieldsValue} content={JSON.parse(JSON.stringify(this.state.content))} updateAdditionalFieldsValue={this.updateAdditionalFieldsValue} updateCustomFields={this.props.updateCustomFields}/>
								</div>
							);
                        }
                    })()
                }
				<EmailContainer style={{width: "85%"}}>
					<EmailInnerContainer>
						<EmailBoldLabel>Enable Custom Fields</EmailBoldLabel>
						<SubHeading>Configure customisable fields to be utilised within the Filechecker process</SubHeading>
						{
							(() => {
								if (this.state.custom_fields.enabled) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						{
							(() => { 
								//console.log("arrayAdditionalFieldrend::",this.state.custom_fields.additional_fields)
								if (this.state.custom_fields.enabled) {		
									return(<div>
										
									<EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
										Custom Fields
										<div style={{float:'right'}}>
											<div onClick={this.addfield} style={{float: "left", cursor: "pointer",fontSize: "20px",color: "#04ADA8"}}>
												<FaPlusCircle title={"Custom Fields"} />
											</div>	
										</div>
										{/* */}
									</EmailTopHeader>						
										{
											(() => {
												if(this.state.custom_fields.additional_fields.length !== 0){
												return(<div style={{ display: "flex", backgroundColor: '#F5F5FD'}}>
													<SerialHeader>#</SerialHeader>
													<FieldNameTitleHeader >Field Name</FieldNameTitleHeader>							
													<FieldTypeTitleHeader >Field Type</FieldTypeTitleHeader>						
													<RequiredTitleHeader>Required?</RequiredTitleHeader>							
													<RequiredTitleHeader>Status?</RequiredTitleHeader>							
													{/* <ActionHeader style={{borderRight: "none"}}>Status</ActionHeader> */}
													<ElipsisHeader style={{borderLeft: "none"}}></ElipsisHeader>
												</div>)
												}
											})()
										}
																
										<Container>
											{
												(() => {
													return this.state.custom_fields.custom_terminology_fields.map((ctf, i) => {
														console.log("custom_terminology_fields::",ctf)
														// let isActive = "is_active" in ctf ? ctf.is_active : 'true'
														return (
															<div key={ctf.name} style={{display: "flex"}}>
																<SerialHeader style={{fontWeight: 400, color: '#282828', backgroundColor: '#ffffff', height: "auto"}}>
																	{i+1}
																</SerialHeader>
																<FieldNameTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	{ctf.nickname}
																</FieldNameTitleHeader>
																<FieldTypeTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	{ctf.type === undefined ? "Date" : ctf.type}
																</FieldTypeTitleHeader>
																<RequiredTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	{ctf.required === true ? 'Yes' : 'No'}
																</RequiredTitleHeader>
																<CtfStatusHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	<div>
																		<Toggle
																			style={{ float: 'left' }}
																			checked={ctf.is_active}
																			icons={false}
																			onClick={this.handleCtfStatus(ctf.name,ctf.is_active)}
																		/>
																	</div>
																</CtfStatusHeader>
																<ElipsisHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", borderLeft: "none", height: "auto"}}>												
																	<VEllipsisMenuFolder editDelete={this.editDelete(ctf.name, "ctf")} options={["Edit Custom Field"]} />																							
																</ElipsisHeader>
															</div>
														)
													})
												})()
											}
											{
												(() => {
													let index = 2;
													return this.state.arrayAdditionalField.map((q, i) => {
														index++;
														// let isActive = "isActive" in q ? q.isActive : 'true';
														return (
															<div key={`addl${i}`} style={{ display: 'flex' }}>
																<SerialHeader style={{fontWeight: 400, color: '#282828', backgroundColor: '#ffffff', height: "auto"}}>
																	{index}
																</SerialHeader>
																<FieldNameTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	{q.nickname}
																</FieldNameTitleHeader>
																<FieldTypeTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	{q.type}
																</FieldTypeTitleHeader>
																<RequiredTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	{q.required === true ? 'Yes' : 'No'}
																</RequiredTitleHeader>
																<RequiredTitleHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", height: "auto"}}>
																	<div>
																		<Toggle
																			style={{ float: 'left' }}
																			checked={q.is_active}
																			icons={false}
																			onChange={this.handleAdfStatus(q.name,q.is_active)}
																		/>
																	</div>
																</RequiredTitleHeader>
																{/* <ActionHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", borderRight: "none", height: "auto"}}>
																	<AddButton
																		style={{
																			marginRight: '30px',
																			display: 'flex',
																			alignItems: 'center',
																			justifyContent: 'center',
																			width: '14px'
																		}}>
												
																		<CSLToggle
																			checked={q.is_active}
																			id={i}
																			onClick={this.toggleStatus}
																		/>
																	</AddButton>												
																</ActionHeader> */}
																<ElipsisHeader style={{backgroundColor: 'white',borderBottom: '0',whiteSpace: 'pre-wrap',wordBreak: 'break-all', fontWeight: "unset", borderLeft: "none", height: "auto"}}>												
																	<VEllipsisMenuFolder editDelete={this.editDelete(q.name, "adf")} options={["Edit Custom Field", "Delete"]} />																							
																</ElipsisHeader>
															</div>
														)
													})
												})()
											}
										</Container>
									</div>);
								}
							})()
						}
					</EmailInnerContainer>
				</EmailContainer>
				
				<AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default CustomFields;