import React from 'react';
import styled from 'styled-components';
import { FaHome, FaCog, FaThLarge, FaBook, FaWrench } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import Store from '../Common/Store'
import {
    faFolderOpen,
    faGear,
    faHouseBlank,
    faArrowLeft,
    faSquareList,
    faChartLine, faClipboardList
} from "@fortawesome/pro-thin-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Style from "./Common/StyledComponents";

const HeadContainer = styled.div`
  display: block;
  width: 100%;
  height: 35px;
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  color: #282828;
`;
const HomeLogo = styled.div`
  float: left;
  margin-top: 7px;
  font-size: 16px;
  color: #282828;
`;
const HomeText = styled.div`
  float: left;
  margin-top: 7px;
  color: #929292;
  margin-left: 10px;
  font-size: 13px;
  color: #282828;
`;
const Categories = styled.div`
  float: right;
  font-size: 17px;
  background-color: #ebebeb;
  color: #c4c4c4;
  width: 25px;
  height: 25px;
  margin-left: 5px;
  margin-top: 4px;
  padding-left: 4px;
  padding-top: 3px;
  border-radius: 2px;
`;
class FcHeader extends React.Component
{

    redirectToModuleconfig = (event) => {
        event.preventDefault();
        const url = "/moduleconfig";
        window.open(url, "fc_mc", "width=1400, height=900");
    }

    render()
    {
        let use_site_layout = false
        if(localStorage.getItem('site_layout')!==null){
            let site_layout = JSON.parse(localStorage.getItem('site_layout'));
            use_site_layout = site_layout.result.use_site_layout;
        }
        if(use_site_layout) return (<div></div>)
        let role = Store.getStoreData('role')
        return (
            <HeadContainer>
                <a href={'/'}>
                    <HomeLogo><FontAwesomeIcon icon={faHouseBlank} style={{ color: '#282828' }} /></HomeLogo>
                    <HomeText> <span style={{fontWeight: "600"}}>Filechecker / Filecheck Setup</span></HomeText>
                </a>

                {role === 'admin_manager' &&
                    <Categories title='Module Config' >
                        <a href={'/moduleconfig'}>
                            <FontAwesomeIcon icon={faGear} style={{ color: '#282828' }} />
                        </a>
                    </Categories>
                }
                {role === 'admin_manager' &&
                    <Categories title='Audit Logs' >
                        <a href={process.env.REACT_APP_AUDIT_URL}>
                            <FontAwesomeIcon icon={faSquareList} style={{ color: '#848484', cursor: 'pointer' }} />
                        </a>
                    </Categories>
                }
                {role === 'admin_manager' &&
                    <Categories title='Filechecker Manager' >
                        <a href={'/config'}>
                            <FontAwesomeIcon icon={faFolderOpen} style={{ color: '#848484', cursor: 'pointer' }} />
                        </a>
                    </Categories>
                }
                {(role === 'admin_manager' || role === 'senior_manager' || role === 'manager') &&
                    <Categories title='Reporting' >
                        <a href={'/reporting'}>
                            <FontAwesomeIcon icon={faChartLine} style={{ color: '#282828', cursor: 'pointer' }} />
                        </a>
                    </Categories>
                }
                {role !== 'team' &&
                    <Categories title='Filechecker Register' >
                        <a href={'/register'}>
                            <FontAwesomeIcon icon={faClipboardList} style={{ color: '#282828', cursor: 'pointer' }} />
                        </a>
                    </Categories>
                }
                <div style={{clear: "both"}}></div>
            </HeadContainer>
        );
    }
}
export default FcHeader;
