import React from 'react';
import "./Dropzone.css";
import styled from 'styled-components';
import {FiDownload} from 'react-icons/fi'
import {MdClear} from 'react-icons/md'
import { saveAs } from 'file-saver';
import APICall from '../../Common/APICall';
import ClipLoader from "react-spinners/ClipLoader";
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';

const AttachmentButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    &:hover {
      background-color: #2e948f;
      border-color: #2e948f;
    }
`;

const DummyOverlay = styled.div`
    display: table;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1000;
    opacity: 0.6;
`;

class CustomDropzone extends React.Component
{
    state = {files: [], bin_files: [], show_spinner: false};
    binFiles = [];
    files_to_prepare_count = 0;
    files_prepared = 0;

    constructor(props) {
        super(props);
        this.state = { hightlight: false, got_file: 0, files: [] };
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
    }

    openFileDialog() {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    updateProgress = (evt) => {
        if (evt.lengthComputable) {
            var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
            console.log('percentLoaded',percentLoaded);
        }
    }

    myreadBinaryFile = (file) => {
        let reader = new FileReader();
        let that = this;
        reader.onprogress = this.updateProgress;
        reader.onload = (function(theFile) {
            return function(e) {
                let fElement = {
                    uid: Utils.genKey(15),
                    name: theFile.name,
                    bin_file: window.btoa(e.target.result).replace(/\n/g,''),
                    user_id: Store.getStoreData("loggedin_user_info").ID,
                    upload_date: Date.now()
                };
                that.binFiles.push(fElement);
                that.files_prepared++;
            };
        })(file);

        reader.readAsBinaryString(file);
    }

    mycheckFilesUploading = () => {
        if (this.files_prepared < this.files_to_prepare_count) {
            setTimeout(this.mycheckFilesUploading, 500);
        } else {
            // console.log(this.binFiles);
            // const api = new APICall();
            const api = new APICall();
            const payload = {command: "upload_followup_files", binFiles: this.binFiles, task_ref: `FCK_FOLLOWUP_${this.props.followup_id}`};
            api.command(payload, this.processPostUpload);
        }
    }

    onFilesAdded = ( {target: {files} } ) => {
        // console.log(files);
        this.setState({show_spinner: true});
        this.files_to_prepare_count = files.length;
        console.log(this.files_to_prepare_count);
        setTimeout(this.mycheckFilesUploading, 500);
        for (let i=0; i<files.length; i++) {
            this.myreadBinaryFile(files.item(i));
        }
    }

    processPostUpload = (result) => {
        // console.log("post processing:", result);
        this.files_to_prepare_count = 0;
        this.files_prepared = 0;
        this.props.addAttachment(this.binFiles);
        this.binFiles = [];
        this.setState({show_spinner: false});
    }

    uploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        console.log(`loaded ${loaded} of ${total}`);
    }

    handleDownload = (task_id, file_id) => {
        // console.log(task_id, file_id);
        this.setState({show_spinner: true}, () => {
            const api = new APICall();
            console.log(task_id, file_id);
            api.command({command: "download_file", task_ref_id: `FCK_FOLLOWUP_${task_id}`, uid: file_id}, this.postDownload);
        });
    }

    postDownload = (result) => {
        console.log(result);
        if (result.result === null) {
            this.setState({show_spinner: false}, () => {
                alert("An error occured. Cannot download the file");
            })
        } else {
            const binaryString =window.atob(result.result.bin_file);
            const binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            var blob = new Blob([bytes]);
            this.setState({show_spinner: false}, () => {
                saveAs(blob, result.result.name);
            })
        }

    }

    componentDidMount() {
        this.setState({files: this.props.attachments})
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) this.setState({files: this.props.attachments});
    }

    render() {
    // console.log('state in render', this.state);

        return (
            <div>
            {
                this.state.show_spinner === true &&
                <DummyOverlay>
                    <div style={{display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={true}
                        />
                    </div>
                </DummyOverlay>
            }
            {
                (() => {
                    if(this.state.files.length > 0) {
                        return (
                            <div style={{width:'100%'}}>
                                {
                                    this.state.files.map((f, index) => {
                                        console.log("f", f);
                                        const file_parts = f.name.split(".");
                                        let ext = "PDF";
                                        switch(file_parts[file_parts.length - 1]) {
                                            case 'jpeg':
                                            case 'jpg' : ext = 'JPG';break;
                                            case 'png' : ext = 'PNG'; break;
                                            case 'docx' : ext = 'DOCX'; break;
                                            case 'doc' : ext = 'DOC'; break;
                                            case 'msg' : ext = 'MSG'; break;
                                            case 'txt' : ext = 'TXT'; break;
                                            case 'ppt' : ext = 'PPT'; break;
                                            case 'pptx' : ext = 'PPTX'; break;
                                            case 'xls' : ext = 'XLS'; break;
                                            case 'xlsx' : ext = 'XLS'; break;
                                            default: ext = 'PDF'; break;
                                        }
                                        console.log('ext', ext);
                                        let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
                                        file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
                                        const file_icon = `/dropzoneImages/${ext}.png`;
                                        return (
                                            <div key={index} style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
                                                <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                                                    <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                                        <img src={file_icon} height="100px" width="auto" />
                                                        {/*<FileTypeContainer>{ext}</FileTypeContainer>*/}
                                                    </div>
                                                    <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                                        <div><strong title={f.name} style={{color: '#000000'}}>{f.name.length > 22 ? f.name.substring(0, 22) : f.name}</strong><br /><br /></div>
                                                        <div style={{cursor: "pointer"}} onClick={() => this.handleDownload(this.props.followup_id, f.uid)}><FiDownload /> Download</div>
                                                        <div style={{cursor: "pointer", marginTop: "10px"}} onClick={this.props.removeAttachment(f.uid)}><MdClear /> Remove</div>
                                                    </div>
                                                    <div style={{clear: "both"}}></div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <div style={{clear: "both"}}></div>
                            </div>
                        )
                    }
                })()
            }
            <input
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            multiple
            accept=".pdf,.jpg,.jpeg,.png"
            onChange={this.onFilesAdded}
            />
            <div style={{paddingTop: "20px"}}>
            <AttachmentButton onClick={this.openFileDialog}>Add an attachment</AttachmentButton>
            </div>
            </div>
        );
    }
}

export default CustomDropzone;
