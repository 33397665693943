		let data = {table_headers: [], data: []};
        data.module = {id: 'filechecker', display: 'Auditor Summary'}
		data.table_headers = [
            {"accessor": "reviewer", "nickname": "Reviewer", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "gc", "nickname": "Group companies", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "last_assigned", "nickname": "L Assigned", type: 'date', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "last_completed", "nickname": "L Completed", type: 'date', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "notstarted_overdue", "nickname": "P / OD", type: 'alpha', width: 0,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "inprogress_overdue", "nickname": "InP / OD", type: 'alpha', width: 0,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "completed_overdue", "nickname": "C / COD", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "total_overdue", "nickname": "Tot / OD", type: 'alpha', width: 0,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            
            
        ];

        data.sortby = {
            fields: [
                {"accessor": "reviewer", "nickname": "Reviewer", type: 'alpha', width: 2},
                {"accessor": "last_assigned", "nickname": "Last Assigned", type: 'date', width: 2},
                {"accessor": "last_completed", "nickname": "Last Completed", type: 'date', width: 2},
                {"accessor": "lc_month", "nickname": "Last Completed month", type: 'month_year', width: 2}
            ],
            selected: {"accessor": "reviewer", "order": "Ascending"}
        };
        data.groupby = {
            fields: [
            {"accessor": "gc", "nickname": "Group companies", type: 'alpha', width: 2},
            {"accessor": "last_assigned", "nickname": "Last Assigned", type: 'date', width: 2},
            {"accessor": "last_completed", "nickname": "Last Completed", type: 'date', width: 2},
            {"accessor": "lc_month", "nickname": "Last Completed month", type: 'month_year', width: 2}
            ],
            selections: []
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        };

module.exports = data