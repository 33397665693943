import React from 'react';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import styled from 'styled-components';
import { FaPlusCircle } from "react-icons/fa";
import CSLTable from '../Common/CSLTable';

const OverviewHeader = styled.div`
	background-color: #ffffff;
	padding: 20px;
`;
const OverviewBody = styled.div`
	padding: 30px 20px;
	margin-top: 2px;
	background-color: #ffffff;
	position: relative;
`;
const Headertxt = styled.div`
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
	float: left;
`;
const HeaderBtn = styled.div`
	color: #57C1BD;
	float: right;
	font-size: 18px;
	cursor: pointer;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const UserModal = styled.div`
	box-sizing: border-box;
    width: 80%;
    position: absolute;
    left: 0px;
    top: 0px;
    margin-left: 10%;
    padding: 0px 25px 25px 25px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: #ffffff;
`;
const MRModalCloseBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid ${process.env.DEFAULT_TEXT_COLOR};
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    cursor: pointer;
`;

const UserModalFooter = styled.div`
	box-sizing: border-box;
	float: right;
	padding-top: 15px;
`;

class FlaggingSection extends React.Component
{

	state = {selected_users: [], unselected_users: [], view_modal: false};

	componentDidMount()
	{
		this.setState({selected_users: this.props.flagging.selected_users, unselected_users: this.props.flagging.unselected_users});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {
			this.setState({selected_users: this.props.flagging.selected_users, unselected_users: this.props.flagging.unselected_users});
		}
	}

	processselectedTableData = () => {
		let ret = {data: [], columns: []};
        ret.columns =[  {Header: 'Name', accessor: 'name', minWidth: 150, headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton style={{width: '150px'}} onClick={this.removeUser(row.original.id)}>Remove</MRButton>
                           </div>
                        ), width: 210, headerStyle: {textAlign: 'left'}}];
        this.state.selected_users.forEach((user) => {
        	let elem = {id: user.id, name: user.name};
        	ret.data.push(elem);
        })
        return ret;
    }

    processunselectedTableData = () => {
		let ret = {data: [], columns: []};
        ret.columns =[  {Header: 'Name', accessor: 'name', minWidth: 150, headerStyle: {textAlign: 'left'}}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton style={{width: '150px'}} onClick={this.addUser(row.original.id)}>Add</MRButton>
                           </div>
                        ), width: 210, headerStyle: {textAlign: 'left'}}];
        this.state.unselected_users.forEach((user) => {
        	let elem = {id: user.id, name: user.name};
        	ret.data.push(elem);
        })
        return ret;
    }

	showUsers = (event) => {
		event.preventDefault();
		this.setState({view_modal: true});
	}

	addUser = (user_id) => (event) => {
		event.preventDefault();
		this.props.addUser(user_id);
	}

	removeUser = (user_id) => (event) => {
		event.preventDefault();
		this.props.removeUser(user_id);
	}

	closeModal = (event) => {
		event.preventDefault();
		this.setState({view_modal: false});
	}

	render()
	{
		return (
			<div>
				<OverviewHeader>
					<Headertxt>Flagging</Headertxt>
					<HeaderBtn onClick={this.showUsers}><FaPlusCircle /></HeaderBtn>
					<div style={{clear: "both"}}></div>
				</OverviewHeader>
				<OverviewBody>
				{
					(() => {
						if (this.state.selected_users.length !== 0) {
							return <CSLTable add={false} show_header={false} processData={this.processselectedTableData} tableRows="10" headerText={''} refreshCallback={this.refreshState} />
						}
					})()
				}
				{
					(() => {
						if (this.state.view_modal) {
							return (
								<UserModal>
									<CSLTable add={false} show_header={false} processData={this.processunselectedTableData} tableRows="10" headerText={''} refreshCallback={this.refreshState} />
									<UserModalFooter>
										<MRModalCloseBtn onClick={this.closeModal}>Close</MRModalCloseBtn>
									</UserModalFooter>
								</UserModal>
							);
						}
					})()
				}
				</OverviewBody>
			</div>
		);
	}
}

export default FlaggingSection;