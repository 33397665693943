import React from 'react';
import styled from 'styled-components';
import {FaPlusCircle} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import AddTaskModal from './AddTaskModal';
import APICall from '../../Common/APICall';
import Store from '../../Common/Store';
import {IoIosTrash} from 'react-icons/io';

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;
const InfoBar = styled.div`
    font-size: 11px;
    font-style: italic;
    text-align: center;
    font-weight: 600;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #04ADA8;
    border-left: 1px solid #d7dddf;
    border-right: 1px solid #d7dddf;
`;
const DelButton = styled.div`
    font-size: 24px;
    cursor: pointer;
`;

class ChildTask extends React.Component
{
    state = {childtask: [], ready: false, parent_task_id: null, parent_company_id: 0, future_followups: [] };

	componentDidMount()
	{
        let childtask = this.props.childtask;
        let contacts = Store.getStoreData("users");
        console.log("childtask", childtask);
        console.log("parent_task_id", this.props.parent_task_id);
        childtask.forEach((task) => {
            const task_json = JSON.parse(task.task_json);
            task.assigned_to_name = task.cur_assigned_to === 0 ? contacts[task_json.task_data.cur_assigned_to.toString()].ContactName : contacts[task.cur_assigned_to.toString()].ContactName;
        })
        this.setState({ childtask , ready: true, parent_task_id: this.props.parent_task_id, parent_company_id: this.props.parent_company_id});
        console.log("childtask-",childtask);
    }

    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let childtask = this.props.childtask;
            let contacts = Store.getStoreData("users");
            childtask.forEach((task) => {
                const task_json = JSON.parse(task.task_json);
                task.assigned_to_name = task.cur_assigned_to === 0 ? contacts[task_json.task_data.cur_assigned_to.toString()].ContactName : contacts[task.cur_assigned_to.toString()].ContactName;
            })
            this.setState({ childtask , ready: true, parent_task_id: this.props.parent_task_id, parent_company_id: this.props.parent_company_id});
		}
	}

    listFollowupTasks = () => {
        const postData = {command: "get_task", task_id: this.props.parent_task_id};
        let api = new APICall();
        api.command(postData, this.arrangeFollowupTasks);
        // this.props.arrangeFollowupTasks();
    }

    arrangeFollowupTasks = (result) => {
        console.log("arrangeFollowupTasks", result);
        let childtask = result.result.task.childtask;
        let contacts = this.props.contacts;
        childtask.forEach((task) => {
            contacts.forEach((item) => {
                if(item.ID === task.cur_assigned_to) {
                    task.assigned_to_name = item.ContactName;
                }
            })
        })
        this.setState({childtask});
    }

    processTasksData = () => {
        
        // console.log("future_followups:", this.props.future_followups)
        const users = Store.getStoreData("users");
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
            {Header: '#', accessor: 'index', minWidth: 10, headerStyle: {textAlign: 'left'}},
            {Header: 'Title', accessor: 'subject', minWidth: 100, headerStyle: {textAlign: 'left'}},
            {Header: 'Assigned to', accessor: 'assigned_to', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Status', accessor: 'status', minWidth: 100, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div style={{display: "contents"}}><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
            {Header: 'Action', minWidth: 100, filterable: false, headerStyle: {textAlign: 'left'}, Cell: row => (
                <div>
                    {
                        (() => {
                            if (row.original.type === "new") {
                                return (
                                    <div>
                                        <MRButton style={{width: '150px', float: "left"}} onClick={this.editFutureFollowUp(row.original.id)}>View</MRButton>
                                        <DelButton onClick={this.removeFutureFollouUp(row.original.id)}><IoIosTrash /></DelButton>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                );
                            }
                        })()
                    }
                </div>
            )}
        ];

        for(let d of this.state.childtask) {
            
            i++;
            const task_json = JSON.parse(d.task_json);
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            // console.log('day, month, year', dd,mm,yyyy)
            var due_date = new Date(yyyy, mm-1, dd);
            due_date.setDate(due_date.getDate() + 1);
            let today = new Date();

            let text = due_date > today ? 'Pending' : 'Overdue';
            let color = due_date > today ? '#2E8B57' : '#FF0000';

            text = d.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
            color = d.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;

            let elem = {
                'id' : d.id,
                'index' : i,
                'subject': task_json.object_data.subject,
                'assigned_to' : d.assigned_to_name,
                'status' : {text: text, color: color},
                'type' : "old"
            };
            ret.data.push(elem);
        }

        //console.log("props:", typeof this.props.future_followups);
        if(this.props.future_followups !== undefined){
        for (let d of this.props.future_followups) {
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            // console.log('day, month, year', dd,mm,yyyy)
            var due_date = new Date(yyyy, mm-1, dd);
            due_date.setDate(due_date.getDate() + 1);
            let today = new Date();

            let text = due_date > today ? 'Pending' : 'Overdue';
            let color = due_date > today ? '#2E8B57' : '#FF0000';

            text = d.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
            color = d.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;

            let elem = {
                'id' : d.id,
                'index' : i,
                'subject': d.subject,
                'assigned_to' : users[d.assigned_to.toString()].ContactName,
                'status' : {text: text, color: color},
                'type' : "new"
            };
            ret.data.push(elem);
        }
    }
        return ret;
    }
    showFollowupTaskModal = (event) => {
        event.preventDefault()
        this.setState({'show_tasks_modal' : true})
    }

    editFutureFollowUp = (task_id) => (event) => {
        event.preventDefault();
        console.log(task_id);
    }

    removeFutureFollouUp = (task_id) => (event) => {
        event.preventDefault();
        console.log(task_id);
    }
    
    render()
	{
        if (this.state.childtask.length === 0) {
			return (
				<div>
                    <AddTaskModal addFutureFollowup={this.props.addFutureFollowup} readonly={this.props.readonly === undefined ? false : this.props.readonly} parent_task_id ={this.state.parent_task_id} reviewer={this.props.reviewer} reloadChildTasks={this.props.reloadChildTasks} parent_company_id={this.props.parent_company_id} listFollowupTasks={this.listFollowupTasks}/>
                    {
                        (() => {
                            if ("info" in this.props) {
                                if (this.props.info !== null && this.props.info === true) {
                                    return (
                                        <InfoBar>An automated follow-up task will be sent to the reviewee for them to acknowledge they have read and understood the results of this File Review</InfoBar>
                                    );
                                }
                            }
                        })()
                    }
                    <OverviewInfoBody>
                        <div style={{textAlign: "center",fontStyle: "italic", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>There are no tasks associated with this file check.</div>
                    </OverviewInfoBody>
                </div>
			);
		}

		return (
            <div>
                <AddTaskModal addFutureFollowup={this.props.addFutureFollowup} readonly={this.props.readonly === undefined ? false : this.props.readonly} parent_task_id ={this.state.parent_task_id} reloadChildTasks={this.props.reloadChildTasks} parent_company_id={this.props.parent_company_id} listFollowupTasks={this.listFollowupTasks}/>                
                {
                    (() => {
                        if ("info" in this.props) {
                            if (this.props.info !== null) {
                                return (
                                    <InfoBar>An automated follow-up task will be sent to the reviewee for them to acknowledge they have read and understood the results of this File Review</InfoBar>
                                );
                            }
                        }
                    })()
                }
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processTasksData} headerText={''} tableRows="10" refreshCallback={this.refreshState} />
                </OverviewInfoBody>
            </div>
        );
    }
}

export default ChildTask;
