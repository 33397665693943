import React from 'react';
import styled from 'styled-components';

const MRModalInput = styled.input`
    height: 20px;
    width: 100%;
    font-family: Montserrat,sans-serif;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;

const MRModalSelect = styled.select`
    display: inline-flex;
    margin-left:10px;
    box-sizing: border-box;
    height: 35px;
    width: 40%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;

const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    padding-top:15px;
`;
const TermLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    display: inline-block;
`;

class MGRScoreOutcome extends React.Component
{
	state = { resultItem: null, resultItemList: null }

	componentDidMount() {
		this.setState({ resultItem : this.props.resultItem })
	}

	componentDidUpdate(prevProps) {
		if(this.props !== prevProps) {
			this.setState({ resultItem : this.props.resultItem })
		}
	}

	nameChange = (event) => {
		let resultItem = this.state.resultItem;
		resultItem.termname = event.target.value
		this.props.modifyResultItem(resultItem)
	}

	outcomeChange = (event) => {
		let resultItem = this.state.resultItem;
		resultItem.actual_outcome = event.target.value	
		this.props.modifyResultItem(resultItem)	
	}

	customChange = (event) => {
		let resultItem = this.state.resultItem;
		resultItem.custom = event.target.checked	
		this.props.modifyResultItem(resultItem)
	}

	render() {
		if(this.state.resultItem === null) return <div>Loading...</div>

		return (
			<div>
				<MRModalLabel>Custom Terminology?</MRModalLabel>
				<div style={{paddingLeft: '15px', paddingTop: '15px'}}>
					
					<label className="toggle">
                        <input type="checkbox" checked={this.state.resultItem.custom} onChange={this.customChange} />
                        <span className="slider round" ></span>
                    </label>


                    <MRModalInput type="text" name="name" style={{ width: '20%', marginLeft: '10%' }} value={this.state.resultItem.termname} disabled={!this.state.resultItem.custom} onChange={this.nameChange} />
                    {
                    	(() => {
                    		if(this.state.resultItem.custom) {
                    			return (
                    				<div style={{display: 'inline-block', paddingLeft: '60px'}}>
                    					<TermLabel>Term meaning</TermLabel>
                    					<MRModalSelect value={this.state.resultItem.actual_outcome} onChange={this.outcomeChange} >
                    						<option value="Pass">Pass</option>
                    						<option value="Fail">Fail</option>
                    						<option value="Indeterminate">Indeterminate</option>
                    					</MRModalSelect>
                    				</div>
                    				)
                    		}
                    	})()
                    }
				</div>
			</div>
		)
	}
}

export default MGRScoreOutcome;