import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaTrashAlt} from 'react-icons/fa';
import Store from '../../Common/Store';
import { Icon } from 'react-icons-kit';
import {checkboxChecked} from 'react-icons-kit/icomoon/checkboxChecked';
import {checkboxUnchecked} from 'react-icons-kit/icomoon/checkboxUnchecked';
import CslRoundDropDown from '../Partials/CslRoundDropDown';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 60%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 16px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;

const AnswerHr = styled.div`
    height: 1px;
    background-color: #d9d9d9;
    margin-top: 15px;
`;

const SerialNo = styled.div`
	font-weight: 700;
	font-size: 11px;
	color: #7f7f7f;
	// display: inline-block;
	float: left;
	width: 5%
	box-sizing: border-box;
`;

const QuestionTxt = styled.div`
	font-size: 12px;
	color: #666666;
	text-align: justify;
	width: 100%;
	box-sizing: border-box;
`;
const AnswerLabel = styled.div`
	font-size: 12px;
    color: #666666;
    font-weight: 600;
    padding-top: 10px;
`;

const AnswerTextArea = styled.textarea`
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 100%;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
`;
const AnswerTextbox = styled.input`
    height: 25px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
`;
const Dropdown = styled.select`
    height: 35px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #979797;
    background-color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
`;
const CommentsLabel = styled.div`
	font-size: 12px;
	color: #666666;
	font-weight: 600;
`;
const Hardcoded = styled.div`
    background-color: #EDF0F1;
    border: 1px solid #c6cfd2;
    border-radius: 5px;
    padding: 15px;
`;
const Dsiputetext = styled.div`
    background-color: #F6DBDB;
    border: 1px solid #c6cfd2;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
`;
const NADiv = styled.div`
    background-color: #ffcc00;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 20px;
    border: 1px solid #ffcc00;
    border-radius: 4px;
`;

class AnswerDisputedModal extends React.Component
{
    state = {active_section_id: null, section_payload: null, sections: null, active_question_id: null, question : null, 
        index: null, is_disputed: false, qc_score_result: null, scoring: null};
    constructor(props) {
        super(props);
    }

    componentDidMount()
	{
		let {active_section_id, section_payload, sections, active_question_id, question,index, qc_score_result, scoring} = this.state;
		active_section_id = this.props.active_section_id;
        section_payload = this.props.section_payload;
        sections = this.props.sections;
        active_question_id = this.props.active_question_id;
        question = this.props.question;
        index = this.props.index;
        qc_score_result = this.props.qc_score_result;
        scoring = this.props.scoring;
        console.log("question:", question);
        console.log("qc_score_result:", this.props.qc_score_result);
		this.setState({active_section_id, section_payload, sections, active_question_id, question, index, qc_score_result, scoring});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            console.log("qc_score_result:", this.props.qc_score_result);
            const section = this.props.sections.find(s => s.id === this.props.active_section_id);
            const question = section.questions.find(q => q.id === this.props.active_question_id);
            console.log("question:", question);
			this.setState({
				active_section_id: this.props.active_section_id,
                section_payload: this.props.section_payload,
                sections: this.props.sections,
                active_question_id: this.props.active_question_id,
                question: question,
                index: this.props.index,
                qc_score_result: this.props.qc_score_result,
                scoring: this.props.scoring
			});
		}
    }


    
    generateAnswerSection = (question, stop) => {
		const question_type = question.type.find((item) => {
			return item.selected;
		})
        let disabled = stop;
		let return_html = <div>Invalid question type</div>
		switch(question_type.value) {
			case "Memo":
				return_html = <AnswerTextArea disabled={disabled} value={disabled ? question.answer.text : question.qa_data.answer.text} onChange={this.handleQaAnswer(question.id)} style={{marginTop: "5px", backgroundColor: disabled ? "#edf0f1" : "#ffffff"}} rows="6" />
				break;
			case "Alpha":
				return_html = <AnswerTextbox disabled={disabled} style={{marginTop: "5px", backgroundColor: disabled ? "#edf0f1" : "#ffffff"}} type="text" value={disabled ? question.answer.text : question.qa_data.answer.text} onChange={this.handleQaAnswer(question.id)}  />
				break;
			case "Boolean":
                let selected_value = "";
                if(disabled){
                     selected_value = question.answer.text === "" ? "Select" : question.answer.text;
                }else{
                    selected_value = question.qa_data.answer.text === "" ? "Select" : question.qa_data.answer.text;
                }
				
				return_html =	<Dropdown disabled={disabled} style={{marginTop: "5px", backgroundColor: disabled ? "#edf0f1" : "#ffffff"}} value={selected_value} onChange={this.handleQaAnswer(question.id)} >
									<option value="-1">Select</option>
									<option value="Yes">Yes</option>
									<option value="No">No</option>
								</Dropdown>
				break;
			case "Numeric":
				return_html = <AnswerTextbox disabled={disabled} style={{marginTop: "5px", backgroundColor: disabled ? "#edf0f1" : "#ffffff"}} type="number" value={disabled ? question.answer.text : question.qa_data.answer.text} onChange={this.handleQaAnswer(question.id)}/>
				break;
            case "Dropdown":
                const drop_value = "selected_drop_id" in question.qa_data && question.qa_data.selected_drop_id === "-1" ? "Select" : question.qa_data.selected_drop_id;
                return_html =	<Dropdown disabled={disabled} style={{margin: "5px 0px 15px 0px"}} value={drop_value} onChange={this.handleQaAnswer(question.id)}>
                                    <option value="-1">Select</option>
                                    {
                                        question.preset_answers.map((pa) => {
                                            return(<option key={pa.id} value={pa.id}>{pa.value}</option>);
                                        })
                                    }
                                </Dropdown>
                break;
			default:
				break;
		}
		return return_html;
	}

    closeView = (event) => {
        event.preventDefault();
        this.setState({is_disputed: false});
        this.props.ClosedisputeView('dispute_answer');
    }

    openDisputed = (event) => {
        event.preventDefault();
        this.setState({is_disputed: true});
    }

    handleObtainedScore = (question_id) => (event) => {
		event.preventDefault();
		console.log("question_id:", question_id);
		let {sections, qc_score_result, scoring} = this.state;
		console.log("Sections:", sections, scoring);
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					if (question.id === question_id) {
                        question.qa_data.obtained_score = event.target.value;
                        question.is_qa = true;
                        question.qa_na_included = true;
					}
				})
			}
		})
		let scoresobtained = 0;
        let totalscore = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = scoresobtained + (parseInt(question.qa_data.obtained_score));
                const q_weight = question.weight.find(q => q.selected);
                totalscore = question.qa_na_included ? (totalscore + parseInt(q_weight.value)) : totalscore;
            })
        })
        qc_score_result['totalscore'] = totalscore;
        console.log("totalscore", totalscore);
        console.log("scoresobtained", scoresobtained);
        scoresobtained = (scoresobtained / qc_score_result.totalscore) * 100;
        qc_score_result.scoresobtained = scoresobtained;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		qc_score_result.ragratingcolor = s.color;
        		qc_score_result.ragratingname = s.termname;
        		qc_score_result.outcome = s.actual_outcome;
        	}
        })
		this.props.modifySections(sections, qc_score_result);
    }
    
    handleQaAnswer = (question_id) => (event) => {
		event.preventDefault();
		let {sections, qc_score_result, scoring} = this.state;
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					if (question.id === question_id) {
                        let data = JSON.parse(JSON.stringify(question.qa_data));
                        data.answer.text = event.target.value === "-1" ? "" : event.target.value;
                        if (scoring.scoring_enabled === false) {
                            question.qa_non_score_data.answer = event.target.value;
                        }
                        question.qa_data = data;
                        question.is_qa = true;
                        // question.qa_answer = event.target.value;
						const thisquestiontype = question.type.find((t) => {
							return t.selected;
						})
						if (thisquestiontype.value === "Boolean" && question.auto_scoring.enabled) {
							if (question.qa_data.answer.text.toLowerCase() === "yes") {
								question.qa_data.obtained_score = question.auto_scoring.yes;
							} else if (question.qa_data.answer.text.toLowerCase() === "no") {
								question.qa_data.obtained_score = question.auto_scoring.no;
							} else {
								question.qa_data.obtained_score = "0";
							}
						}
                        if (thisquestiontype.value === "Dropdown") {
                            if (event.target.value === "-1") {
                                data.answer.text = "";
                                data.selected_drop_id = "-1";
                            } else {
                                const selected_response = question.preset_answers.find(item => item.id.toString() === event.target.value);
                                data.answer.text = selected_response.value;
                                data.selected_drop_id = selected_response.id;
                            }
                            question.qa_data = data;
                            if (question.auto_scoring.enabled === true) {
                                const selectedanswer = question.preset_answers.find((pa) => {
                                    return pa.id === event.target.value && event.target.value !== "-1";
                                })
                                question.qa_data.obtained_score = event.target.value !== "-1" && event.target.value !== "-2" ? selectedanswer.weight : 0;
                            }
						}
					}
				})
			}
        })
		let scoresobtained = 0;
        let totalscore = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = question.qa_na_included ? scoresobtained + (parseInt(question.qa_data.obtained_score)) : scoresobtained;
                const q_weight = question.weight.find(q => q.selected);
                totalscore = question.qa_na_included ? (totalscore + parseInt(q_weight.value)) : totalscore;
            })
        })
        scoresobtained = (scoresobtained / qc_score_result.totalscore) * 100;
        qc_score_result.scoresobtained = scoresobtained;
        qc_score_result['totalscore'] = totalscore;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		qc_score_result.ragratingcolor = s.color;
        		qc_score_result.ragratingname = s.termname;
        		qc_score_result.outcome = s.actual_outcome;
        	}
        })
        if (scoring.scoring_enabled) {
            this.props.modifySections(sections, qc_score_result);
        } else {
            this.props.modifyNonScoreSections(sections);
        }
    }
    
    handleComment = (question_id) => (event) => {
		event.preventDefault();
		let {sections, qc_score_result, scoring} = this.state;
		console.log("Sections:", sections);
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					if (question.id === question_id) {
                        if (scoring.scoring_enabled) {
                            question.qa_data.comments = event.target.value;
                        } else {
                            question.qa_non_score_data.comments = event.target.value;
                        }
                        question.is_qa = true;
					}
				})
			}
		})
        if (scoring.scoring_enabled) {
            this.props.modifySections(sections, qc_score_result);
        } else {
            this.props.modifyNonScoreSections(sections);
        }
    }
    
    submitDisputedquestion = (event) => {
        event.preventDefault();
        this.props.saveDisputed();
        this.props.ClosedisputeView('dispute_answer');
    }

    changeQuestionNa = (value) => (event) => {
        event.preventDefault();
        let sections = JSON.parse(JSON.stringify(this.state.sections));
        let qc_score_result = JSON.parse(JSON.stringify(this.state.qc_score_result));
        let scoring = JSON.parse(JSON.stringify(this.state.scoring));
        sections.forEach((section) => {
            if (section.id === this.state.active_section_id) {
                section.questions.forEach((question) => {
                    if (question.id === this.state.active_question_id) {
                        question.qa_na_included = value;
                    }
                });
            }
        });
        let scoresobtained = 0;
        let totalscore = 0;
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = question.qa_na_included ? scoresobtained + (parseInt(question.qa_data.obtained_score)) : scoresobtained;
                const q_weight = question.weight.find(q => q.selected);
                totalscore = question.qa_na_included ? (totalscore + parseInt(q_weight.value)) : totalscore;
            })
        })
        scoresobtained = (scoresobtained / totalscore) * 100;
        qc_score_result.scoresobtained = scoresobtained;
        qc_score_result['totalscore'] = totalscore;
        scoring.scores.forEach((s) => {
            if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
                qc_score_result.ragratingcolor = s.color;
                qc_score_result.ragratingname = s.termname;
                qc_score_result.outcome = s.actual_outcome;
            }
        });
        console.log("sections:", sections);
        this.props.modifySections(sections, qc_score_result);
    }

    handleNonScore = (question_id, data) => {
        let sections = JSON.parse(JSON.stringify(this.state.sections));
        sections.forEach((section) => {
            section.questions.forEach((question) => {
                if (question.id === question_id) {
                    question.qa_non_score_data.label = data.label;
                    question.qa_non_score_data.color = data.color;
                }
            })
        })
        this.props.modifyNonScoreSections(sections);
    }

    render()
    {
        if (this.state.active_question_id === null) {
			return (<div>Loading...</div>);
        }
        console.log("state", this.state)
        return (
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>Quality Assurance</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <AnswerHr />
                <MRModalBody>
                    {
                        (() => {
                            if(this.state.is_disputed){
                                return (<Dsiputetext><div style= {{textAlign: "justify", color: "#DD8B8B"}}>You are amending the original response</div></Dsiputetext>)
                            }
                        })()
                    }
                    <Hardcoded>
                        <div style={{fontWeight: "600" }}>You are reviewing a submitted response</div>
                        <div style={{textAlign: "justify", paddingTop: "10px"}}>As part of the quality assurance process, you can review the question and the provided response and comments. If you disagree with a given response you can opt to suggest a new response. Please note this will change the overall scoring of this Filecheck.</div>
                    </Hardcoded>
                    <div style={{paddingTop: "15px", textAlign: "justify"}}>
                        <SerialNo>{"Q"+(this.state.index + 1).toString()}</SerialNo>
                        <QuestionTxt>{this.state.question.question.replace(/'+/g,"'")}</QuestionTxt>
                    </div>
                <AnswerHr />
                <AnswerLabel>Answer</AnswerLabel>
                { this.generateAnswerSection(this.state.question, true) }
                {
                    (() => {
                        if (this.state.scoring.scoring_enabled === false) {
                            return (
                                <div>
                                    <AnswerLabel>Label</AnswerLabel>
                                    <div>
                                        {
                                            (() => {
                                                if (this.state.question.included) {
                                                    return <div style={{color: this.state.question.non_scoring_info.color, fontWeight: "600"}}>{this.state.question.non_scoring_info.label}</div>
                                                } else {
                                                    return <div>None</div>
                                                }
                                            })()
                                        }
                                    </div>
                                </div>
                            );
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.question.comments.enabled) {
                            let comment = 'text' in this.state.question.comments ? this.state.question.comments.text : "";
                            return (
                                <div>
                                    <CommentsLabel style={{padding: "10px 0px"}}>Comments</CommentsLabel>
                                    <Hardcoded style={{textAlign: "justify", paddingTop: "10px", border: "none"}}>{comment}</Hardcoded>
                                </div>
                            );
                        }
                    })()
                }
                {
                    (() => {
                        if (!this.state.question.included) {
                            return (
                                <NADiv>Not Applicable</NADiv>
                            );
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.is_disputed){
                            return (
                                <div style={{backgroundColor: "#EDF0F1", marginTop: "15px", padding: "15px"}}>
                                    {
                                        (() => {
                                            if (this.state.question.naallowed) {
                                                return (
                                                    <div style={{float: "right"}}>
                                                        <div style={{float: "left", fontWeight: "200", paddingTop: "2px"}}>Question N/A</div>
                                                        {
                                                            (() => {
                                                                let readonly = this.props.readonly === undefined ? false : this.props.readonly;
                                                                if (readonly === false) {
                                                                    return (
                                                                        <div style={{float: "left", paddingLeft: "7px"}}>
                                                                            {
                                                                                (() => {
                                                                                    if (!this.state.question.qa_na_included) return <Icon style={{cursor: "pointer"}} onClick={this.changeQuestionNa(true)} icon={checkboxChecked} />;
                                                                                    else return <Icon style={{cursor: "pointer"}} onClick={this.changeQuestionNa(false)} icon={checkboxUnchecked} />;
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            })()
                                                        }
                                                        <div style={{clear: "both"}}></div>
                                                    </div>
                                                );
                                            }
                                        })()
                                    }
                                    <div style={{pointerEvents: this.props.readonly === undefined ? "auto" : this.props.readonly === true ? "none" : "auto"}}>
                                        <AnswerLabel>Answer</AnswerLabel>
                                        { this.generateAnswerSection(this.state.question, false) }
                                    </div>
                                    <div style={{pointerEvents: this.props.readonly === undefined ? "auto" : this.props.readonly === true ? "none" : "auto"}}>
                                        {
                                            (() => {
                                                if (this.state.scoring.scoring_enabled) {
                                                    return (
                                                        <div>
                                                            <AnswerLabel style={{padding: "5px 0px"}}>Score</AnswerLabel>
                                                            <Dropdown value={this.state.question.qa_data.obtained_score} onChange={this.handleObtainedScore(this.state.active_question_id)} >
                                                                {
                                                                    (() => {
                                                                        let optionhtml = [];
                                                                        for (let i=0; i<this.state.question.weight.length; i++) {
                                                                            optionhtml.push(<option key={this.state.question.weight[i].value} value={this.state.question.weight[i].value}>{this.state.question.weight[i].value}</option>);
                                                                            if (this.state.question.weight[i].selected) {
                                                                                break;
                                                                            }
                                                                        }
                                                                        return optionhtml;
                                                                    })()
                                                                }
                                                            </Dropdown>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div>
                                                            <AnswerLabel style={{padding: "5px 0px"}}>Label</AnswerLabel>
                                                            <CslRoundDropDown
                                                                options={this.state.scoring.non_scores}
                                                                selected={{label: this.state.question.qa_non_score_data.label, color: this.state.question.qa_non_score_data.color}}
                                                                question_id={this.state.question.id}
                                                                handleNonScore={this.handleNonScore}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                    </div>
                                    <CommentsLabel style={{padding: "10px 0px"}}>Comments</CommentsLabel>
                                    <AnswerTextArea
                                        rows="6"
                                        cols="100"
                                        onChange={this.handleComment(this.state.active_question_id)}
                                        value={this.state.scoring.scoring_enabled ? this.state.question.qa_data.comments : this.state.question.qa_non_score_data.comments}
                                        style={{pointerEvents: this.props.readonly === undefined ? "auto" : this.props.readonly === true ? "none" : "auto"}}
                                    />
                                </div>
                                )
                        }

                    })()
                }
                </MRModalBody>

                <MRModalFooter>
                    {
                        (() => {
                            if(this.state.is_disputed === true){
                                let readonly = this.props.readonly === undefined ? false : this.props.readonly;
                                if (readonly === false) {
                                    return (<MRModalNextBtn onClick={this.submitDisputedquestion}>Submit</MRModalNextBtn>)
                                }
                            }else{
                                return (<MRModalNextBtn onClick={this.openDisputed}>Review</MRModalNextBtn>)
                            }
                        })()
                    }
                    <MRModalCancelBtn onClick={this.closeView} style={{marginLeft: "10px"}}>Close</MRModalCancelBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalFooter>
            </MRModalContainer>
        );
    }
}
export default AnswerDisputedModal;