import React from 'react'
import ReactTable from 'react-table'
import BulkHistoryPagination from "./BulkHistoryPagination";
import './BulkHistoryTable.css';

class BulkHistoryTable extends React.Component {

	render () {
		let tableData = this.props.processData();
		// console.log("tableData:", tableData);
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
        return (
        	<div id="bulkhistory">
	        	<ReactTable
	            	PaginationComponent={BulkHistoryPagination}
				    data={tableData.data}
				    columns={tableData.columns}
				    defaultPageSize={parseInt(row_count)}
				/>
			</div>
        )
	}

}

export default BulkHistoryTable;