import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import CSLTable from '../Common/CSLTable';
import QcView from '../Common/QcView';
import FollowupModal from '../Partials/FollowupModal';
import FCThumbnails from '../Partials/FCThumbnails';
import APICall from '../../Common/APICall';
import AnswerView from '../Common/AnswerView';
import ReportModal from '../MGR/ReportModal';
import FCKPrintPreview from '../FCKPrintPreview'
import AssignFileCheck from '../Partials/AssignFileCheck';
import AdvancedFilter from '../Partials/AdvancedFilter';
import {IoIosArrowUp, IoIosArrowDown, IoIosTrash, IoMdSend} from 'react-icons/io';
import ClipLoader from "react-spinners/ClipLoader";
import moment from 'moment';
import { result } from '../Register/reportFilters';
import EventBus from '../../Common/EventBus.js';
import Utils from "../../Common/Utils";

const SubmitButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #223B5A;
    color: #ffffff;
    width: 160px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    padding-left: 10px;
    margin-left: 9px;
    margin-top: 10px;
    font-size: 11px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;

const StatusButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 15px;
`;

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 120px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
`;

const DelButton = styled.div`
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
`;

const CardChevronContainer = styled.div`
    text-align: right;
    padding: 15px 10px 10px 0px;
    box-sizing: border-box;
`;
const ClosedChevron = styled.div`
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
    padding: 5px 13px 0px 13px;
    background-color: #ffffff;
`;
const DummyOverlay = styled.div`
    display: table;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1000;
    opacity: 0.6;
`;

class QAMGRIndex extends React.Component
{
    state = {
        ready: false, 
        view_count: 0, 
        page_view: "qa_manager_index", 
        modal_view: null, 
        quality_assurance_enabled: false, 
        task: null,
        completed_tasks: null,
        completed_fck_tasks: null,
        pending_tasks: null,
        followup_tasks: null,
        show_completed: false,
        show_fck_completed: false,
        show_spinner: false,
        comp_fc_autofail_result: [],
        pending_filters: {
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},            
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
            due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        completed_fck_filters: {
            reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
            reviewee: { name: "reviewee", nickname: "Reviewee", type: "multi", options: {}, selections: {}, size: 2 },
            customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
            file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
            company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
            completed_date: { name: "completed_date", nickname: "Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
        },
        completed_filters: {
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},
            reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},            
            customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2},
            file_ref: {name: 'file_ref', 'nickname': 'File Reference', type: 'multi', options: {},selections: {}, size: 2},
            company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},            
            completed_date: {name: 'completed_date', 'nickname': 'Completed Date', type: 'date_range', options: {},selections: {}, size: 2}
        },  
        followup_filters: {
            assign_to: {name: 'assign_to', 'nickname': 'Assigned To', type: 'multi', options: {},selections: {}, size: 2},            
            due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2}
        },
        pending_qa_review_header_text: "Pending QA Reviews: --/--/---- - --/--/----",
        comp_qa_review_header_text:"Completed QA Reviews",
        complete_header_text: "Completed File Reviews"
    };

    constructor(props) {
        super(props);
        this.view = "qa_manager_index";
    }

    componentDidMount()
    {
        const today = moment().format('DD/MM/YYYY');
        //const to_date = new Date(new Date().setDate(new Date().getDate() + 7));
        const formated_to_date = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();
        const api1 = new APICall();
        let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters))
        let followup_filters = JSON.parse(JSON.stringify(this.state.followup_filters))
        pending_filters.due_date.selections = {from_date: today, to_date: formated_to_date};
        followup_filters.due_date.selections = {from_date: today, to_date: today};
        // completed_filters.completed_date.selections = {from_date: today, to_date: today};
        console.log("pending_filters::",pending_filters);
        const payload = {command: "list_tasks", onloading:1, filters: {pending_filters, followup_filters}};
        api1.command(payload, this.listTasks);
        if (Store.getStoreData('fc_index') !== null && Store.getStoreData('fc_index') !== 0) {
            const api = new APICall();
            const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
            api.command(postData, this.singleTask);
        }
        let quality_assurance_enabled = this.state.quality_assurance_enabled;
        let module_config = Store.getStoreData('module_config');
        if(Object.keys(module_config).length > 0) {
            quality_assurance_enabled = module_config.general.quality_assurance_enabled;
        }
        console.log("module_config =====>moubt", quality_assurance_enabled);
        EventBus.registerEvent('followup_saved_from_parent', 'QAMGRIndex', this.showResult_parent)
        this.setState({quality_assurance_enabled: quality_assurance_enabled});
    }

    listTasks = (result) => {
        console.log("listTaskslength pending_tasks",result.result.pending_tasks.tasks.length );  
        console.log("listTaskslength followup_tasks", result.result.followup_tasks.tasks.length);  
        this.setState({
            pending_tasks: result.result.pending_tasks.tasks.length === 0 ? null : result.result.pending_tasks.tasks[0],
            pending_filters: result.result.pending_tasks.filters,            
            followup_tasks: result.result.followup_tasks.tasks.length === 0 ? null : result.result.followup_tasks.tasks,
            followup_filters: result.result.followup_tasks.filters,            
            ready: true
        });      
    }

    singleTask = (result) => {
        console.log("singleTask", result.result.task.parenttask.cur_lane);
        let comp_fc_autofail_result = []
        if(this.props.show_task){
            if(result.result.task.parenttask.cur_lane === "COMP_FCK_COMPLETE"){
                let this_task = result.result.task.parenttask;
                let task_json = JSON.parse(this_task.task_json);
                Store.updateStore('report_task_json', task_json);
                Store.updateStore('report_task', result.result.task);
                Store.updateStore('ReportModal', `${document.body.scrollHeight.toString()}px`);
                this.view = "view_report_modal";
                comp_fc_autofail_result = Utils.getAutofailQuestions(this_task)
            }else if(result.result.task.parenttask.cur_lane === "COMP_FCK_QC_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_FCK_QC_COMPLETE"){
                this.view = "qcview"
            }else if (result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_ASSIGNMENT"  || result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE") {
                Store.updateStore('followup_task', {parenttask: result.result.task.original_parenttask, subtask: result.result.task.parenttask, childtask: result.result.task.childtask});
                // this.setState({modal_view: 'view_followup_modal'});
                this.view = "qa_manager_index";
                this.modal_view = "view_followup_modal";
            }else{
                this.view = "answerview"
            }
        }
        this.setState({task: result, page_view: this.view, comp_fc_autofail_result});
        // this.setState({page_view: view});
    }

    deletePendingTask = (task_id) => (event) => {
        event.preventDefault();
        const r = window.confirm("Are you sure you want to delete this record?");
        if(r == true){
            let postData = {command: "remove_task", task_id: task_id};
            let api = new APICall();
            api.command(postData, this.reloadTask);

        }
        
    }

    reloadTask = (result) => {
        if(result){
            alert('Filecheck task removed successfully.');
        }
        window.location.reload(true);
    }

    showQc = (task_id) => (event) => {
        event.preventDefault();
        // let quality_assurance_enabled = this.state.quality_assurance_enabled;
        // let module_config = Store.getStoreData('module_config');
        // if(Object.keys(module_config).length > 0) {
        //     quality_assurance_enabled = module_config.general.quality_assurance_enabled;
        // }
        // console.log(task_id);
        if(this.state.quality_assurance_enabled) {
            Store.updateStore('fc_index', task_id);
            this.setState({page_view : "qcview"})
        }else {
            alert("Quality assurance disabled");
            return false;
        }
    }

    AssigntoQC = (task_id) => (event) => {
        event.preventDefault();
        const r = window.confirm("Are you sure you want to assign this record for QC?");
        if(r == true){
            let task_json = {"task_data": {}, "object_data":{}, "action_data": {}};
            task_json['task_data'].last_action = 'COMP_FCK_QC_ASSIGNMENT';
            task_json['task_data'].cur_lane = 'COMP_FCK_QC_ASSIGNMENT';
            task_json['task_data'].actiondate = Date.now();
            task_json['task_data'].cur_assigned_to = 0;
            task_json['action_data'] = {action: "COMP_FCK_QC_ASSIGNMENT", actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_QC_ASSIGNMENT", assigned_to: 0};
            let postData = {command: "assign_qc_tasks", task_json: task_json, task_ids: [task_id] };
            const api = new APICall();
            api.command(postData, this.afterAssign);
        }
    }
    afterAssign = (result) => {
        console.log(result);
        alert('QC assigned successfully.');
        window.location.reload(true);
    }

    processQACompleteTasksData = () => {
        //let pending_tasks = Store.getStoreData('qa_completed_fctasks');
        let has_gc = Store.getStoreData('parent_has_group_companies');
        let contacts = Store.getStoreData('users');
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = "";
        let ret = {data: [], columns: []};
        ret.columns =[  {Header: 'Status', accessor: 'status', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'Task', accessor: 'task', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
                    {Header: 'Assign To', accessor: 'assignto', minWidth: 40, headerStyle: {textAlign: 'left'}},
                    {Header: 'File Reference', accessor: 'file_reference', minWidth: 30, headerStyle: {textAlign: 'left'}},
                    {Header: 'Customer Name', accessor: 'customer_name', minWidth: 40, headerStyle: {textAlign: 'left'}},
                    {Header: 'Company', accessor: 'company', minWidth: 40, headerStyle: {textAlign: 'left'}, show: has_gc}, 
                    {Header: 'Reviewer', accessor: 'reviewer', minWidth: 40, headerStyle: {textAlign: 'left'}},
                    {Header: 'Completed Date', accessor: 'completed_date', minWidth: 30, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                        if (a === b) {
                            return 0;
                        }
                        let adue = a.split("/");
                        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                        let  bbdue = b.split("/");
                        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                        return aReverse > bReverse ? 1 : -1;
                    }},
                    {'Header' : 'Action', filterable: false, Cell: row => (
                       <div>
                           <MRButton style={{width: '67px'}} onClick={this.showQc(row.original.id)}>View</MRButton>
                       </div>
                    ), minWidth: 40, headerStyle: {textAlign: 'left'}}];
        if (this.state.completed_tasks !== null) {
            for(let d of this.state.completed_tasks) {
                let text = 'Completed';
                let color = '#2E8B57';
                let box =   <div>
                                <div style={{width: "15px", height: "15px", borderRadius: "4px", backgroundColor: color, display: "inline-block"}}></div>
                                <div style={{display: "inline", paddingLeft: "15px"}}>{text}</div>
                            </div>;
                const user_name = d[11].toString() in contacts ? contacts[d[11].toString()].ContactName : "Inactive User";
                let task = "This QA task have been completed by "+ user_name;
                let related_individual = "N/A";
                let confirmed_by = "N/A";
                let confirmed_date = "N/A";
                let confirmation_text = "N/A";
                if (d[12] !== 0) {
                    related_individual = d[12].toString() in contacts ? contacts[d[12].toString()].ContactName : "Inactive User";
                }
                if (d[13] !== 0) {
                    confirmed_by = d[13].toString() in contacts ? contacts[d[13].toString()].ContactName : "Inactive User";
                }
                if (d[14] !== 0) {
                    confirmed_date = moment.unix(d[14]).format("DD/MM/YYYY");
                }
                if (d[15] !== "") {
                    confirmation_text = d[15];
                }
                let elem = {
                    'status' : box, 
                    'task' : task,
                    'assignto' : user_name,
                    'file_reference' : d[9].replace(/'+/g,"'"),
                    'customer_name' : d[1].replace(/'+/g,"'"),
                    'company' : d[2],
                    'reviewer' : d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
                    'completed_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                    'status_text': text,
                    'id' : d[0],
                    'related_individual': related_individual,
                    'confirmed_by': confirmed_by,
                    'confirmed_date': confirmed_date,
                    'confirmation_text': confirmation_text
                };
                ret.data.push(elem);
            }
        }
        ret.data = ret.data.sort((a,b) => {
            if (a.due_date === b.due_date) {
              return 0;
            }
            let adue = a.due_date.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
            let  bbdue = b.due_date.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse < bReverse ? 1 : -1;
        });
        ret.export_columns = {
            status_text: "Status",
            task: "Task",
            assignto: "Assigned To",
            file_reference: "File Reference",
            customer_name: "Customer",
            company: "Company",
            reviewer: "Reviewer",
            completed_date: "Completed Date",
            related_individual: "Related Individual",
            confirmed_by: "File Edited By",
            confirmed_date: "File Edited Date",
            confirmation_text: "File Edit Comments"
        };
        return ret;
    }

    processFckCompleteTasksData = () => {
        let contacts = Store.getStoreData("users");
        const has_gc = Store.getStoreData("parent_has_group_companies");
        let prefered_columns = {};
        let ret = { data: [], columns: [] };
        let i = 0;
        ret.columns = [
            { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
            { Header: "File Reference", accessor: "file_ref", minWidth: 150, headerStyle: { textAlign: "left" } },
            { Header: "Customer Name", accessor: "customer_name", minWidth: 150, headerStyle: { textAlign: "left" } },
            { Header: "Company", accessor: "company", minWidth: 120, headerStyle: { textAlign: "left" }, show: has_gc },
            { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
            { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
            { Header: "Completed Date", accessor: "completed_date", minWidth: 120, headerStyle: { textAlign: "left" } },
            {
              Header: "Action",
              filterable: false,
              Cell: (row) => (
                <div style={{ width: "100%", boxSizing: "border-box" }}>
                  <div style={{ float: "left" }}>
                    <MRButton onClick={this.showResult(row.original.id)}>Show Report</MRButton>
                  </div>
                  <div style={{ float: "left" }}>
                    {Store.getStoreData("role") === "admin_manager" && (
                      <DelButton onClick={this.askDeleteCompletedTask(row.original.id)}>
                        <IoIosTrash />
                      </DelButton>
                    )}
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
              ),
              width: 210,
              headerStyle: { textAlign: "left" },
            },
        ];
        prefered_columns = {
            file_ref: "File Reference",
            reviewee: "Reviewee",
            reviewer: "Reviewer",
            schdate: "Scheduled Date",
            status: "Status",
            customer_name: "Customer Name",
            sale_date: "Policy Start Date",
            assign_date: "Assigned Date",
            file_type: "File Type",
            completed_date: "Review Date",
            score: "Score",
            result: "Result",
            related_individual: "Related Individual",
            confirmed_by: "File Edited By",
            confirmed_date: "File Edited Date",
            confirmation_text: "File Edit Comments"
        };
        if (has_gc === true) prefered_columns.company = "Company";
        if (this.state.completed_fck_tasks !== null) {
            for (let d of this.state.completed_fck_tasks) {
                i++;
                let related_individual = "N/A";
                let confirmed_by = "N/A";
                let confirmed_date = "N/A";
                let confirmation_text = "N/A";
                if (d[16] !== 0) {
                    related_individual = d[16].toString() in contacts ? contacts[d[16].toString()].ContactName : "Inactive User";
                }
                if (d[17] !== 0) {
                    confirmed_by = d[17].toString() in contacts ? contacts[d[17].toString()].ContactName : "Inactive User";
                }
                if (d[18] !== 0) {
                    confirmed_date = moment.unix(d[18]).format("DD/MM/YYYY");
                }
                if (d[19] !== "") {
                    confirmation_text = d[19];
                }
                let elem = {
                    index: i,
                    file_ref: d[5].replace(/'+/g, "'"),
                    customer_name: d[1].replace(/'+/g, "'"),
                    company: d[2],
                    reviewee: d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "--",
                    reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "--",
                    id: d[0],
                    file_type: d[6],
                    schdate: moment.unix(d[12]).format("DD/MM/YYYY"),
                    status: "Completed",
                    sale_date: moment.unix(d[7]).format("DD/MM/YYYY"),
                    assign_date: moment.unix(d[8]).format("DD/MM/YYYY"),
                    completed_date: moment.unix(d[9]).format("DD/MM/YYYY"),
                    score: d[10],
                    result: d[11],
                    related_individual: related_individual,
                    confirmed_by: confirmed_by,
                    confirmed_date: confirmed_date,
                    confirmation_text: confirmation_text.toString().replace(/\n+/g, ' ')
                };
                ret.data.push(elem);
            }
        }
        ret.export_columns = prefered_columns;
        return ret;
    }

    processCompleteTasksData = () => {
        let has_gc = Store.getStoreData('parent_has_group_companies');
        let contacts = Store.getStoreData('users');
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = "";
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  
            {
                Header: 'Status',
                accessor: 'status',
                minWidth: 40,
                headerStyle: {textAlign: 'left'},
                Cell: cellInfo => (<div><StatusButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</StatusButton></div>),
                sortMethod: (a, b) => {
                    return a.text > b.text ? 1 : -1;
                },
                filterMethod: (filter, row) => {
                    console.log(row);
                    console.log(filter);
                    return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
                }
            }, 
            {Header: 'Task', accessor: 'task', minWidth: 100, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Assign To', accessor: 'assignto', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
            {Header: 'File Reference', accessor: 'file_reference', minWidth: 30, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Customer Name', accessor: 'customer_name', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Company', accessor: 'company', minWidth: 40, headerStyle: {textAlign: 'left'}, show: has_gc}, 
            {Header: 'Reviewer', accessor: 'reviewer', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Due Date', accessor: 'due_date', minWidth: 30, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                if (a === b) {
                    return 0;
                }
                let adue = a.split("/");
                const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                let  bbdue = b.split("/");
                const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                return aReverse > bReverse ? 1 : -1;
            }}
        ];
        if (Store.getAllStoreData("loggedin_user_info").role !== "view_only_manager") {
            ret.columns.push(
                {'Header' : 'Action', filterable: false, Cell: row => (
                    <div>
                        <MRButton style={{width: '67px'}} onClick={this.showQc(row.original.id)}>View</MRButton>
                    </div>
                 ), minWidth: 40, headerStyle: {textAlign: 'left'}}
            );
        }
        if (this.state.pending_tasks !== null) {
        for(let d of this.state.pending_tasks) {
                let today = moment().set('hour', 23).set('minute', 59).set('second', 59).unix();
                let due_date = d[5];
                let text = due_date < today ? 'Pending' : 'Overdue';
                let color = due_date < today ? '#2E8B57' : '#FF0000';
                let contact = "You"
                if(d[12] !== 0) {
                    contact = contacts[d[12].toString()].ContactName;
                }
                const review_name = d[10];
                let task = contact + " have been assigned a "+review_name+" QA Review";
                let related_individual = "N/A";
                let confirmed_by = "N/A";
                let confirmed_date = "N/A";
                let confirmation_text = "N/A";
                if (d[15] !== 0) {
                related_individual = d[15].toString() in contacts ? contacts[d[15].toString()].ContactName : "Inactive User";
                }
                if (d[16] !== 0) {
                confirmed_by = d[16].toString() in contacts ? contacts[d[16].toString()].ContactName : "Inactive User";
                }
                if (d[17] !== 0) {
                confirmed_date = moment.unix(d[17]).format("DD/MM/YYYY");
                }
                if (d[18] !== "") {
                confirmation_text = d[18];
                }
                let elem = {
                    'status' : {text: text, color: color},
                    'task' : task,
                    'assignto' : d[11].toString() in contacts ? contacts[d[11].toString()].ContactName : 'N/A',
                    'file_reference' : d[9].replace(/'+/g,"'"),
                    'customer_name' : d[1],
                    'company' : d[2],
                    'reviewer' : d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
                    'due_date' : moment.unix(d[5]).format('DD/MM/YYYY'),
                    'status_value': text,
                    'id' : d[0],
                    'related_individual': related_individual,
                    'confirmed_by': confirmed_by,
                    'confirmed_date': confirmed_date,
                    'confirmation_text': confirmation_text
                };
                ret.data.push(elem);
            //}
        }
    }
        ret.data = ret.data.sort((a,b) => {
            if (a.due_date === b.due_date) {
                return 0;
            }
            let adue = a.due_date.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
            let  bbdue = b.due_date.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse < bReverse ? 1 : -1;
        });
        ret.export_columns = {
            status_value: "Status",
            task: "Task",
            assignto: "Assigned To",
            file_reference: "File Reference",
            customer_name: "Customer",
            company: "Company",
            reviewer: "Reviewer",
            due_date: "Due Date",
            related_individual: "Related Individual",
            confirmed_by: "File Edited By",
            confirmed_date: "File Edited Date",
            confirmation_text: "File Edit Comments"
        };
        return ret;
    }

    processFollowupTasksData = () => {
        //let followup_tasks = Store.getStoreData('followup_tasks');
        let contacts = Store.getStoreData('users');
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[
                        {Header: 'Task', accessor: 'task', minWidth: 240, headerStyle: {textAlign: 'left'}},
                        {Header: 'Company Name', accessor: 'company_name', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Assigned To', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Customer Name', accessor: 'customer_name', minWidth: 120, headerStyle: {textAlign: 'left'}},
                        {Header: 'Status', accessor: 'status', width: 120, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)},
                        {Header: 'Due Date', accessor: 'due_date', minWidth: 120, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
                            if (a === b) {
                                return 0;
                            }
                            let adue = a.split("/");
                            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                            let  bbdue = b.split("/");
                            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                            return aReverse > bReverse ? 1 : -1;
                        }}, 
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <MRButton onClick={this.showFollowUp(row.original.id)} style={{width: '150px'}} >View</MRButton>
                           </div>
                        ), filterable: false, headerStyle:{textAlign: 'left'}}];
        if (this.state.followup_tasks !== null) {
            for(let d of this.state.followup_tasks) {
                i++;
                let today = moment().unix();                
                let text = (d[6]+86400) > today ? 'Pending' : 'Overdue';
                let color = (d[6]+86400) > today ? '#2E8B57' : '#FF0000';
                text = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Completed" : text;
                color = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#43C50C" : color;
                //let task = contacts[d.cur_assigned_to.toString()].ContactName +" has been assigned a task titled "+ task_json.object_data.subject;
                let task = contacts[d[4].toString()].ContactName +" has been assigned a task titled "+ d[2];
                let elem = {'task' : task,
                            'company_name': d[1],
                            'assigned_to' : contacts[d[4].toString()].ContactName,
                            'status' : {text: text, color: color},
                            'due_date' : moment.unix(d[6]).format('DD/MM/YYYY'),
                            'status_text': text,
                            'id' : d[0],
                            'customer_name': d[8],
                            'task_response': d[11].replace(/,/g, " ").replace(/\n/g, " "),
                            'complete_date': d[7] !== null ? moment.unix(d[7]).format('DD/MM/YYYY') : ''
                    };
                ret.data.push(elem);
            }
            ret.data = ret.data.sort((a,b) => {
              if (a.due_date === b.due_date) {
                return 0;
              }
              let adue = a.due_date.split("/");
              const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
              let  bbdue = b.due_date.split("/");
              const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
              return aReverse < bReverse ? 1 : -1;
            });
        }
        ret.export_columns = {task: "Task", company_name: "Company Name", assigned_to: "Assigned To", customer_name: 'Customer Name', status_text: "Status", due_date: "Due Date", task_response: "Task Response",complete_date: "Completed Date"};
        return ret;
    }

    changeCurView = (view) => {
        this.view = view;
        if (Store.getStoreData('fc_index') !== null) {
            const api = new APICall();
            const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
            api.command(postData, this.singleTask);
        }
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        this.setState({show_spinner: true});
        let postData = { command: "get_subtask", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openFollowupModal);
    }

    openFollowupModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({page_view: 'view_followup_modal', show_spinner: false});
    }

    closeReportmodal = () => {
        this.setState({page_view: "qa_manager_index"});
    }

    showInitFileReviewModal = (task_id) => (event) => {
        console.log(task_id);
        event.preventDefault();
        if(task_id > 0){
            Store.updateStore('fc_index', task_id);
            this.setState({page_view: "answerview"});
        }
        else{
            Store.updateStore('fc_index', task_id);
            this.setState({page_view: "assignview"});
        }

    }

    showResult = (task_id) => (event) => {
        event.preventDefault();
        let tasks = Store.getStoreData('completed_fctasks');
        // let result = tasks[index];
        document.documentElement.scrollTop = 0;
        let postData = { command: "get_task", task_id: task_id};
        let api = new APICall();
        api.command(postData, this.openReportModal);
    }

    showResult_parent = (task_id)  => {
        this.setState({'show_spinner': true})
        document.documentElement.scrollTop = 0;
        // Store.updateStore('report', JSON.parse(result.task_json).object_data);
        let postData = { command: "get_task", task_id: task_id };
        let api = new APICall();
        api.command(postData, this.openReportModal);
      };

    openReportModal = (result) => {
        Store.updateStore('report_task_json', JSON.parse(result.result.task.parenttask.task_json))
        Store.updateStore('report_task', result.result.task)
        let comp_fc_autofail_result = []
        comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
        this.setState({page_view: 'view_report_modal',show_spinner:false, comp_fc_autofail_result});
    }

    openPdfPrintView = () => {
        this.setState({page_view: 'pdfprintview'});
    }

    showPage = (page) => {
        this.setState({page_view : page});
    }
    refreshState = (modal_view) => {
        this.setState({view_count: this.state.view_count + 1, modal_view:modal_view});
    }

    applyPendingFilter = (filters) => {
        console.log("pending_filters:",filters);
        this.setState({
            pending_qa_review_header_text:"Pending QA Reviews:"+filters.due_date.selections.from_date+" - "+filters.due_date.selections.to_date
        })
        const postData = {command: "get_filtered_pending_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredPendingTasks);
    }

    processFilteredPendingTasks = (result) => {
        this.setState({pending_tasks: result});
    }

    clearPendingAdvancedFilter = () => {        
        window.location.reload();
    }

    switchRadio = (event) => {
        event.preventDefault();
        console.log(this.state.show_completed);
        if (this.state.show_completed === true) {
            let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
            Object.keys(completed_filters).forEach(key => {
                completed_filters[key].selections = {};
            })
            this.setState({
                show_completed: false,
                completed_tasks: null,
                completed_filters,
                comp_qa_review_header_text:"Completed QA Reviews"
            });
        } else {
            this.setState({comp_qa_review_header_text: "Completed QA Reviews: --/--/---- - --/--/----"})
            const postData = {command: "get_complete_filter_options"};
            let api = new APICall();
            api.command(postData, this.processFilterOptions);
        }
    }

    switchCompleteRadio = (event) => {
        event.preventDefault();
        console.log(this.state.show_fck_completed);
        if (this.state.show_fck_completed === true) {
            let completed_fck_filters = JSON.parse(JSON.stringify(this.state.completed_fck_filters));
            Object.keys(completed_fck_filters).forEach(key => {
                completed_fck_filters[key].selections = {};
            })
            this.setState({
                show_fck_completed: false,
                completed_fck_tasks: null,
                completed_fck_filters,
                complete_header_text:"Completed File Reviews"
            });
        } else {
            this.setState({complete_header_text: "Completed File Reviews: --/--/---- - --/--/----"})
            const postData = {command: "get_fck_complete_filter_options"};
            let api = new APICall();
            api.command(postData, this.processFckCompleteFilterOptions);
        }
    }

    processFilterOptions = (result) => {
        console.log(result);
        let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
        Object.keys(completed_filters).forEach(key => {
            completed_filters[key]['options'] = result[key];
            if (key === "completed_date") {
                const td_start = new Date(new Date().setDate(new Date().getDate() - 7));
                const td_end = new Date();
                let dd = td_start.getDate();
                let mm = td_start.getMonth() + 1;
                let yyyy = td_start.getFullYear();
                dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
                mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
                const td_str = `${dd}/${mm}/${yyyy}`;
                console.log("td_str",td_str);
                let dd1 = td_end.getDate();
                let mm1 = td_end.getMonth() + 1;
                let yyyy1 = td_end.getFullYear();
                dd1 = dd1 < 10 ? `0${dd1.toString()}` : dd1.toString();
                mm1 = mm1 < 10 ? `0${mm1.toString()}` : mm1.toString();
                const td_str_e = `${dd1}/${mm1}/${yyyy1}`;
                console.log("td_str_e",td_str_e);
                completed_filters.completed_date.selections.from_date = td_str;
                completed_filters.completed_date.selections.to_date = td_str_e;
            }
        })
        this.setState({
            show_completed: true,
            completed_filters,
            //show_spinner: false
        });
        const postData = {command: "get_filtered_completed_qa_tasks", completed_filters};
        let api = new APICall();
        api.command(postData, this.processFilteredQaCompletedTasks);
    }

    processFckCompleteFilterOptions = (result) => {
        console.log(result);
        let completed_fck_filters = JSON.parse(JSON.stringify(this.state.completed_fck_filters));
        Object.keys(completed_fck_filters).forEach(key => {
            completed_fck_filters[key]['options'] = result[key];
            if (key === "completed_date") {
                const td_start = new Date(new Date().setDate(new Date().getDate() - 90));
                const td_end = new Date();
                let dd = td_start.getDate();
                let mm = td_start.getMonth() + 1;
                let yyyy = td_start.getFullYear();
                dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
                mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
                const td_str = `${dd}/${mm}/${yyyy}`;
                console.log("td_str",td_str);
                let dd1 = td_end.getDate();
                let mm1 = td_end.getMonth() + 1;
                let yyyy1 = td_end.getFullYear();
                dd1 = dd1 < 10 ? `0${dd1.toString()}` : dd1.toString();
                mm1 = mm1 < 10 ? `0${mm1.toString()}` : mm1.toString();
                const td_str_e = `${dd1}/${mm1}/${yyyy1}`;
                console.log("td_str_e",td_str_e);
                completed_fck_filters.completed_date.selections.from_date = td_str;
                completed_fck_filters.completed_date.selections.to_date = td_str_e;
            }
        })
        this.setState({
            show_fck_completed: true,
            completed_fck_filters,
            //show_spinner: false
        });
        const postData = {command: "get_filtered_completed_tasks", filters: completed_fck_filters};
        let api = new APICall();
        api.command(postData, this.processFilteredCompletedTasks);
    }

    clearCompletedAdvancedFilter = () => {
        console.log(this.state.completed_filters);
        let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
        Object.keys(completed_filters).forEach(key => {
            if (completed_filters[key].type === "date_range") {
                completed_filters[key].selections.from_date = moment().format('DD/MM/YYYY')
                completed_filters[key].selections.to_date = moment().format('DD/MM/YYYY')
            } else {
                completed_filters[key].selections = {};
            }
        })
        this.setState({completed_filters,comp_qa_review_header_text:"Completed QA Reviews: --/--/---- - --/--/----"});
    }

    clearFckCompletedAdvancedFilter = () => {
        console.log(this.state.completed_fck_filters);
        let completed_fck_filters = JSON.parse(JSON.stringify(this.state.completed_fck_filters));
        Object.keys(completed_fck_filters).forEach(key => {
            if (completed_fck_filters[key].type === "date_range") {
                completed_fck_filters[key].selections.from_date = moment().format('DD/MM/YYYY')
                completed_fck_filters[key].selections.to_date = moment().format('DD/MM/YYYY')
            } else {
                completed_fck_filters[key].selections = {};
            }
        })
        this.setState({completed_fck_filters,complete_header_text:"Completed File Reviews: --/--/---- - --/--/----"});
    }

    applyFilter = (filters) => {
        console.log(filters);
        this.setState({
            show_spinner: true,
            comp_qa_review_header_text: "Completed QA Reviews: "+filters.completed_date.selections.from_date+" - "+filters.completed_date.selections.to_date
        });
        const postData = {command: "get_filtered_completed_qa_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredQaCompletedTasks);
    }

    applyCompleteFilter = (filters) => {
        console.log(filters);
        this.setState({
            show_spinner: true,
            comp_qa_review_header_text: "Completed File Reviews: "+filters.completed_date.selections.from_date+" - "+filters.completed_date.selections.to_date
        });
        const postData = {command: "get_filtered_completed_tasks", filters};
        let api = new APICall();
        api.command(postData, this.processFilteredCompletedTasks);
    }

    processFilteredQaCompletedTasks = (result) => {
        console.log(result);
        this.setState({completed_tasks: result, show_spinner: false});
    }

    processFilteredCompletedTasks = (result) => {
        console.log(result);
        this.setState({completed_fck_tasks: result, show_spinner: false});
    }

    closeQcView = (event) => {
        event.preventDefault();
        Store.updateStore("fc_index", null);
        if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
            window.location.assign(document.referrer);
        }
        this.setState({page_view: "qa_manager_index"});
    }
    // openPdfPrintView_followup = () => {
    //     //this.setState({page_view: 'pdfprintview'});
    //     this.props.changeCurView("pdfprintview_followup");
    //   };
    render()
    {
        console.log("this.state, ", this.state);
        if (this.state.page_view === "qcview") {
            return <QcView changeCurView={this.changeCurView} closeQcView={this.closeQcView} parentview="qa_manager_index" showPage={this.showPage} />
          } else if (this.state.page_view === "review") {
            return (<AnswerView changeCurView={this.changeCurView} parentview="qa_manager_index" setBreadcrumbLastText={this.props.setBreadcrumbLastText} readonly={true} showPage={this.showPage} />);
          } else if(this.state.page_view === "assignview") {
            return (<AssignFileCheck task={this.state.task} changeCurView={this.changeCurView} parentview="qa_manager_index" />);
          }else if(this.state.page_view === "answerview") {
            return (<AnswerView changeCurView={this.changeCurView} parentview="qa_manager_index" />);
          }else if(this.state.page_view === "pdfprintview"){
            return <FCKPrintPreview />
          }else {
            console.log("ready in else:", this.state.ready);
            if (this.state.ready === false) {
                return <div>Loading...</div>;
            }
            return (
                <div>
                    {this.state.show_spinner === true && 
                        <DummyOverlay>
                            <div style={{display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                                <ClipLoader
                                    size={150}
                                    color={"#123abc"}
                                    loading={true}
                                />
                            </div>
                        </DummyOverlay>
                    }
                    {
                        (() => {
                            if(this.state.quality_assurance_enabled) {
                                return(<div>
                                        <div style={{paddingLeft: "9px", paddingRight: "9px"}}>
                                            <CSLTable 
                                                add={false} 
                                                export={true}
                                                custom_fields={true}
                                                export_file_name="Pending_QA_Reviews" 
                                                processData={this.processCompleteTasksData} 
                                                tableRows="10" 
                                                headerText={this.state.pending_qa_review_header_text}  
                                                refreshCallback={this.refreshState}
                                                filters={this.state.pending_filters} 
                                                applyFilter={this.applyPendingFilter}
                                                clearAdvancedFilter={this.clearPendingAdvancedFilter}
                                            />
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                        <div style={{paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px"}}>
                                            <CSLTable 
                                                add={false} 
                                                export={true}
                                                custom_fields={true}
                                                export_file_name="Completed_File_Reviews" 
                                                processData={this.processFckCompleteTasksData} 
                                                tableRows="10" 
                                                headerText={this.state.complete_header_text}
                                                refreshCallback={this.refreshState} 
                                                toggle_text="Show Completed Reviews"
                                                toggleCallback={this.switchCompleteRadio}
                                                toggle_state={this.state.show_fck_completed}
                                                filters={this.state.completed_fck_filters} 
                                                applyFilter={this.applyCompleteFilter}
                                                clearAdvancedFilter={this.clearFckCompletedAdvancedFilter} 
                                            />  
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                        <div style={{paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px"}}>
                                            <CSLTable 
                                                add={false} 
                                                export={true}
                                                custom_fields={true}
                                                export_file_name="Completed_QA_Reviews" 
                                                processData={this.processQACompleteTasksData} 
                                                tableRows="10" 
                                                headerText={this.state.comp_qa_review_header_text}
                                                refreshCallback={this.refreshState} 
                                                toggle_text="Show Completed QA Reviews"
                                                toggleCallback={this.switchRadio}
                                                toggle_state={this.state.show_completed}
                                                filters={this.state.completed_filters} 
                                                applyFilter={this.applyFilter}
                                                clearAdvancedFilter={this.clearCompletedAdvancedFilter} 
                                            />  
                                        </div>
                                    </div>)
                            }
                        })()
                    }
                    <div style={{clear: "both"}}></div>
                    <div style={{paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px"}}>
                        {
                            (() => {
                                if(this.state.page_view === "view_followup_modal") {
                                    return <div style={{position:'absolute', left:'10%', width:'100%', height:'1186px', top: this.props.show_task === true ? '10vh' : 'auto'}}><InactiveOverlay /><FollowupModal closeModal={this.closeReportmodal} /></div>;
                                }else if(this.state.page_view === "view_report_modal") {
                                    return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay style={{height: "2384px"}} /><ReportModal closeModal={this.closeReportmodal} openPrintView={this.openPdfPrintView} comp_fc_autofail_result = {this.state.comp_fc_autofail_result}/></div>;
                                }
                            })()
                        }
                        <CSLTable add={false} export={true} export_file_name={"followup_tasks"} processData={this.processFollowupTasksData} tableRows="10" headerText={'Followup Tasks'} />
                    </div>
                </div>
            );
          }
    }
}
export default QAMGRIndex;
