import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import { FaPlus, FaTimes } from 'react-icons/fa';
import Utils from '../../../Common/Utils';
import APICall from '../../../Common/APICall';
import AlertBox from '../AlertBox'
import {checkboxChecked} from 'react-icons-kit/icomoon/checkboxChecked';
import {checkboxUnchecked} from 'react-icons-kit/icomoon/checkboxUnchecked';
import {pencilSquareO} from 'react-icons-kit/fa/pencilSquareO';
import {times} from 'react-icons-kit/fa/times'

const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;

const ListingContainer = styled.div`
    border: 1px solid #ffffff;
    border-radius: 3px;
    color: #2d2d2d;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
    `
const AddFeedButtonDiv = styled.div`
    border: 1px solid #37ADA7;
    border-radius: 5px;
    color: #ffffff;
    background-color: #37ADA7;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    height: 20px;
    font-size: 14px;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
    font-weight: 700;
`

const AddFeedButton = styled.div`
	height: 12px;
	width: 12px;
	font-size: 14px;
	background-color: #ffffff;
	color: #37ADA7;
	border: 1px solid #ffffff;
	border-radius: 50%;
	float: right;
	padding: 3px;
	cursor: pointer;
`

const APAddFeedDialogBox = styled.div`
	position: fixed;
	background-color: #ffffff;
	top: 40vh;
	left: 40vw;
	width: 20vw;
	border: 1px solid #37ADA7;
	border-radius: 5px;
	z-index: 10000;
`
const APAddFeedDialogHeader = styled.div`
	width: calc(100% - 10px);
	height: 25px;
	padding: 5px;
	border-radius: 5px 5px 0px 0px;
	background-color: #37ADA7;
	color: #ffffff;
`
const APAddFeedDialogBody = styled.div`
	width: calc(100% - 10px);
	padding: 5px;
	border-radius: 0px 0px 0px 0px;
	background-color: #ffffff;
	color: #2d2d2d;
`
const APAddFeedDialogFooter = styled.div`
	width: calc(100% - 10px);
	padding: 5px;
	height: 25px;
	border-radius: 0px 0px 5px 5px;
	background-color: #ffffff;
	color: #2d2d2d;
`
const TextBox = styled.input`
	height: 15px;
    width: 95%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    &:focus {
		outline-width: 0;
	}
`;

const BoldLabel = styled.div`
	font-weight: 600;
	color: #2d2d2d;
`;

const CancelBtn = styled.button`
	padding: 3px 20px;
	border: 1px solid #37ADA7;
	background-color: #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
	float: right;
`;
const SaveBtn = styled.button`
	padding: 3px 30px;
	border: 1px solid #37ADA7;
	background-color: #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 8px;
	float: right;
`;
const AddBtn = styled.button`
	border: 1px solid #37ADA7;
	background-color: #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
`;
const ValuePill = styled.div`
	display: inline-block;
    background-color: #dadada;
    padding: 3px 5px;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;
`;


class APAddFeedDialog extends React.Component {
	state = {feed_name: '', alert_param: null}

	feed_id = null

	inputChange = (event) => {
		event.preventDefault()
		this.setState({[event.target.name]: event.target.value})
	}

	processBlankName = () => {
		this.setState({alert_param: null})
	}

	processSaveFeed = (result) => {
		this.props.closeDialog()
		this.props.onAddNewFeed(this.feed_id)
	}

	saveFeed = () => {
		if(this.state.feed_name === '') {
			let alert_param = {title: 'Error', confirm: false, message: 'Options Source name cannot be blank', alertHandler: this.processBlankName}
			this.setState({alert_param})
			return
		}
		this.feed_id = Utils.genKey(16)
		let post_data = {command: 'option_feeds', action: 'add_feed', feed_id: this.feed_id, feed_name: this.state.feed_name}
		let api = new APICall()
		api.command(post_data, this.processSaveFeed)
	}
	render() {
		return (<div>
			<APAddFeedDialogBox>
				<APAddFeedDialogHeader>
					New Options Source<AddFeedButton onClick={this.props.closeDialog} style={{paddingLeft: 2, paddingRight: 5}}><FaTimes /></AddFeedButton>
				</APAddFeedDialogHeader>
				<APAddFeedDialogBody>
					<BoldLabel>Options Source Name</BoldLabel>
					<TextBox name='feed_name' value={this.state.feed_name} onChange={this.inputChange} />
				</APAddFeedDialogBody>
				<APAddFeedDialogFooter>
					<SaveBtn onClick={this.saveFeed}>Save</SaveBtn><CancelBtn onClick={this.props.closeDialog}>Cancel</CancelBtn>
				</APAddFeedDialogFooter>
				{
					(() => {
						if(this.state.alert_param !== null) return <AlertBox alertParam={this.state.alert_param} />
					})()
				}
				
			</APAddFeedDialogBox>
		</div>)
	}
}

class APDPrefillBox extends React.Component {
	state = {alert_param: null, prefill: null, cur_value: "", initial_prefill: null};

	componentDidMount() {
		// console.log("prefill:", this.props.prefill);
		this.setState({prefill: this.props.prefill, initial_prefill: JSON.stringify(this.props.prefill)});
	}

	_handleKeyDown = (e) => {
		if (e.key === 'Enter' && this.state.cur_value !== '') {
			let prefill = JSON.parse(JSON.stringify(this.state.prefill));
			const option = {option_id: Utils.genKey(16), option_text: this.state.cur_value};
			prefill.values.push(option);
			this.setState({prefill, cur_value: ""});
		}
	}

	addValue = (event) => {
		event.preventDefault();
		if (this.state.cur_value !== '') {
			let prefill = JSON.parse(JSON.stringify(this.state.prefill));
			const option = {option_id: Utils.genKey(16), option_text: this.state.cur_value};
			prefill.values.push(option);
			this.setState({prefill, cur_value: ""});
		}
	}

	setCurValue = (e) => {
		this.setState({cur_value: e.target.value});
	}

	removeValue = (option_id) => (event) => {
		event.preventDefault();
		let prefill = JSON.parse(JSON.stringify(this.state.prefill));
		let new_values = [];
		prefill.values.forEach(value => {
			if (value.option_id !== option_id) {
				new_values.push(value);
			}
		})
		prefill.values = new_values;
		this.setState({prefill});
	}

	saveValues = (event) => {
		event.preventDefault();
		if (JSON.stringify(this.state.prefill) === this.state.initial_prefill) {
			const alert_param = {title: 'Error', confirm: false, message: 'Nothing to save', alertHandler: this.processBlankName}
			this.setState({alert_param})
			return
		}
		this.props.saveValues(this.state.prefill);
	}

	processBlankName = () => {
		this.setState({alert_param: null})
	}

	render() {
		if (this.state.prefill === null) {
			return <div>Loading...</div>
		}
		return (<div>
			<APAddFeedDialogBox>
				<APAddFeedDialogHeader>
					Values for {this.state.prefill.feed_name}<AddFeedButton onClick={this.props.closeDialog} style={{paddingLeft: 2, paddingRight: 5}}><FaTimes /></AddFeedButton>
				</APAddFeedDialogHeader>
				<APAddFeedDialogBody>
					<BoldLabel>Add Value</BoldLabel>
					<TextBox value={this.state.cur_value} onChange={this.setCurValue} style={{width: "75%", display: "inline-block"}} onKeyDown={this._handleKeyDown} />
					<AddBtn style={{display: "inline-block", padding: "6px 13px", marginLeft: "10px"}} onClick={this.addValue}>Add</AddBtn>
					{
						(() => {
							if (this.state.prefill.values.length !== 0) {
								return (
									<div style={{marginTop: "15px"}}>
										<BoldLabel>Values</BoldLabel>
										<div>
											{
												this.state.prefill.values.map(value => {
													return (
														<ValuePill key={value.option_id}>
															<div style={{display: "inline-block"}}>{value.option_text}</div>
															<div style={{display: "inline-block", marginLeft: "5px"}}><Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={times} onClick={this.removeValue(value.option_id)} /></div>
														</ValuePill>
													)
												})
											}
										</div>
									</div>
								);
							}
						})()
					}
				</APAddFeedDialogBody>
				<APAddFeedDialogFooter>
					<SaveBtn onClick={this.saveValues}>Save</SaveBtn><CancelBtn onClick={this.props.closeDialog}>Cancel</CancelBtn>
				</APAddFeedDialogFooter>
				{
					(() => {
						if(this.state.alert_param !== null) return <AlertBox alertParam={this.state.alert_param} />
					})()
				}
				
			</APAddFeedDialogBox>
		</div>)
	}
}

class APDropdownOptions extends React.Component {
	state = {feed_id: null, feeds: null, show_feeds_dialog: false, show_prefill_dialog: false, cur_prefill: null}

	processFeedsListing = (result) => {
		// console.log('feeds result', result)
		this.setState({feeds: result.result, feed_id: this.props.feedId, show_prefill_dialog: false});
	}

	setComponentState = () => {
		let post_data = {command: 'option_feeds', action: 'list_feeds', data_id: 'list_feeds'}
		// console.log('feeds command', post_data)
		
		let api = new APICall()

		api.command(post_data, this.processFeedsListing)
	}

	saveValues = (prefill) => {
		let post_data = {command: 'option_feeds', action: 'replace_option', prefill: prefill};
		let api = new APICall();
		api.command(post_data, this.afterSaveValues);
	}

	afterSaveValues = (result) => {
		this.setComponentState();
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	showFeedDialog = () => {
		this.setState({show_feeds_dialog: true})
	}

	closeFeedDialog = () => {
		this.setState({show_feeds_dialog: false})
	}

	closePrefillDialog = () => {
		this.setState({show_prefill_dialog: false});
	}

	selectFeed = (feed_id) => {
		// console.log('selected feed_id', feed_id)
		this.props.selectFeed(feed_id)
		this.setState({feed_id})
	}

	openOptionsDialog = (feed_id) => (event) => {
		event.preventDefault();
		let cur_prefill = {};
		cur_prefill.feed_id = feed_id;
		cur_prefill.feed_name = this.state.feeds[feed_id].feed_name;
		cur_prefill.values = this.state.feeds[feed_id].values;
		this.setState({cur_prefill, show_prefill_dialog: true});
	}

	componentDidUpdate = (prevprops) => {
		if(prevprops === this.props)return
		this.setComponentState()
	}
	render() {
		// console.log('APDropdown this.state', this.state)
		if(this.state.feeds === null)return <div></div>
		let num_feeds = Object.keys(this.state.feeds).length

		return (<div>
			<SubHeading>Select an existing Options Source or create a new one to use with this Auto-Populated Dropdown.</SubHeading>
			<AddFeedButtonDiv>Add New Options Source<AddFeedButton onClick={() => this.showFeedDialog()}><FaPlus /></AddFeedButton></AddFeedButtonDiv>
			<ListingContainer>
				{
					(() => {
						if(num_feeds === 0) {
							return <SubHeading style={{fontSize: 10, color: '#2d2d2d'}}>There are no existing Options Sources configured. Please create a new Options Source to use with this Auto-Populated Dropdown.</SubHeading>
						} else {
							return (<div>
								<table>
									<tbody>
										{
											Object.keys(this.state.feeds).map((id, i) => {
												return(
													<tr key={i}>
														<td>
															{
																(() => {
																	// console.log('this.state.feed_id, id', this.state.feed_id, id)
																	if (this.state.feed_id === id) {
																		return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} />)
																	} else {
																		return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={() => this.selectFeed(id)} />)
																	}
																})()
															}
														</td>
														<td title={this.state.feeds[id].feed_options === '' ? '[No options]' : this.state.feeds[id].feed_options + '...'}>{this.state.feeds[id].feed_name}</td>
														<td><Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={pencilSquareO} onClick={this.openOptionsDialog(id)} /></td>
													</tr>
													)
											})
										}
									</tbody>
								</table>
								
							</div>)
						}
					})()
				}
			</ListingContainer>
			{
				(() => {
					if(this.state.show_feeds_dialog) {
						return <APAddFeedDialog closeDialog={this.closeFeedDialog} onAddNewFeed={this.props.onAddNewFeed} />
					}
				})()
			}
			{
				(() => {
					if (this.state.show_prefill_dialog === true) {
						return <APDPrefillBox prefill={this.state.cur_prefill} closeDialog={this.closePrefillDialog} saveValues={this.saveValues} />
					}
				})()
			}
		</div>)
	}
}

export default APDropdownOptions;