import React from 'react';
import styled from 'styled-components';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb'


const EmailContainer = styled.div`
	width: 70%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 20px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

class Reminders extends React.Component
{
	state = {remainders: null};

	componentDidMount()
	{
		this.setState({remainders: this.props.reminders});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({remainders: this.props.reminders});
		}
	}


	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {remainders} = this.state;
		remainders[key] = remainders[key] ? false : true;
		this.props.updateReminder(remainders);
	}

	onChangeInterval = (id)=> (values, handle) => {
		let value = Math.floor(parseFloat(values[handle]));

		let {remainders} = this.state;
		let newremainders = [];
		remainders.remainder.forEach((item) => {
			if (item.id === id) {
				item.period = value;
				console.log("matched", item);
			}
			newremainders.push(item);
		})
		remainders.remainder = newremainders;
		this.props.updateReminder(remainders);
	}



	render()
	{
		if (this.state.remainders === null) {
			return (
				<div>Loading...</div>
			);
		}
		let tooltips = [wNumb({decimals: 0, suffix: 'days'})];
		return (
			<div>
				<EmailContainer>
					<EmailInnerContainer>
						<EmailBoldLabel style={{display: "inline-block"}}>Enable Reminder?</EmailBoldLabel>
						{
							(() => {
								if (this.state.remainders.remainder_enabled) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('remainder_enabled')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('remainder_enabled')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
					</EmailInnerContainer>
				{
					(() => {
						if (this.state.remainders.remainder_enabled) {
							return(
								<div>
									{
										this.state.remainders.remainder.map((resultItem, index) => {
												return (
													<div key={index}>
														<EmailInnerContainer>
															<EmailBoldLabel>Reminder Threshold</EmailBoldLabel>
															<GeneralsubLabel>Select amount of days period of overdue to trigger a reminder email</GeneralsubLabel>
															<div style={{paddingTop: '40px'}}>
																<Nouislider range={{ min: 0, max: 30 }} start={resultItem.period} step = {1} connect={[true, false]} tooltips={tooltips} onChange={this.onChangeInterval(resultItem.id)} />
															</div>
														</EmailInnerContainer>
													</div>
												)
											})
									}
								</div>

								)
							}

					})()
				}
				</EmailContainer>
			</div>
		);
	}
}

export default Reminders;