let completed_report_data = {table_headers: [], data: []};
completed_report_data.module = {id: 'filechecker', display: 'Filechecker'}
completed_report_data.table_headers = [
    {"accessor": "status", "nickname": "Status", type: 'alpha', width: 2,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "review_type", "nickname": "File Type", type: 'alpha', width: 3,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "reviewer", "nickname": "Reviewer", type: 'alpha', width: 2,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "reviewee", "nickname": "Reviewee", type: 'alpha', width: 2,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "customer", "nickname": "Customer", type: 'alpha', width: 2,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "review_date", "nickname": "Review Date", type: 'date', width: 2,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "score", "nickname": "Score", type: 'numeric', width: 1,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "result", "nickname": "Result", type: 'alpha', width: 2,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "company_name", "nickname": "Company", type: 'alpha', width: 0,
        "valign": {'top':false,'vcentred':true,'bottom':false}, 
        "halign":{'left':true,'hcentred':false, 'right':false}},
    {"accessor": "review_month", "nickname": "Review Month", type: 'month', width: 0,
        "valign": {'top':false,'vcentred':true,'bottom':false},
        "halign":{'left':true,'hcentred':false, 'right':false}}
];

completed_report_data.sortby = {
    fields: [
        {"accessor": "status", "nickname": "Status"},
        {"accessor": "review_type", "nickname": "File Type"},
        {"accessor": "reviewer", "nickname": "Reviewer"},
        {"accessor": "reviewee", "nickname": "Reviewee"},
        {"accessor": "customer", "nickname": "Customer"},
        {"accessor": "score", "nickname": "Score"},
        {"accessor": "result", "nickname": "Result"},
        {"accessor": "review_date", "nickname": "Review Date"},
        {"accessor": "review_month", "nickname": "Review Month"},
        {"accessor": "company_name", "nickname": "Company"}
    ],
    selected: {"accessor": "none", "order": "Ascending"}
};
completed_report_data.groupby = {
    fields: [
        {"accessor": "status", "nickname": "Status"},
        {"accessor": "review_type", "nickname": "File Type"},
        {"accessor": "reviewer", "nickname": "Reviewer"},
        {"accessor": "reviewee", "nickname": "Reviewee"},
        {"accessor": "customer", "nickname": "Customer"},
        {"accessor": "score", "nickname": "Score"},
        {"accessor": "result", "nickname": "Result"},
        {"accessor": "review_date", "nickname": "Review Date"},
        {"accessor": "review_month", "nickname": "Review Month"},
        {"accessor": "company_name", "nickname": "Company"}
    ],
    selections: []
}
completed_report_data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
};

module.exports = completed_report_data
