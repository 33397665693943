import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store';
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";

const QAFormNameDiv = styled.div`
    box-sizing: border-box;
    color: #a0a0a0;
`;
const QAFormDescDiv = styled.div`
    box-sizing: border-box;
    color: #a0a0a0;
    font-size: 12px;
`;


const QEnclosure = styled.div`
    box-sizing: border-box;
    color: #a0a0a0;
    width: 100%;
    padding: 5px 2px 0px 10px;
    font-weight: 600;
`;
const MRModalTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-weight: 100;
    height: 80px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-weight: 100;
`;
const MRModalInputSmall = styled.input`
    height: 40px;
    width: 90px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-weight: 100;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 35px;
    width: 90%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const ScoreSectiondiv = styled.div`
    position: fixed;
    height: 60px;
    width: 220px;
    padding: 15px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    top: 10px;
    right: 10px;
`;

class QuestionAnswerForm extends React.Component
{
    state = {
        ready: false,
        review_json: null,
        questions: null,
        cur_review_id: null,
        resultconfigs: null,
        autofailquestions: [],
        openrag: null,
        openconfigs: [],
        qmaxscores: [],
        qobtainedscores: [],
        totalscore: 0,
        obtainedscore: 0,
        scorecolor: "#ffffff",
        scorename: "",
        qanda: []
    };

    componentDidMount()
    {
        const review_json = JSON.parse(JSON.stringify(this.props.review_json));
        const cur_review_id = this.props.cur_review_id;
        const resultconfigs = JSON.parse(JSON.stringify(review_json.resultconfigs));
        // const openconfigs = JSON.parse(JSON.stringify(review_json.openconfigs));
        let openconfigs = [];
        if ("openconfigs" in review_json) {
            openconfigs = JSON.parse(JSON.stringify(review_json.openconfigs));
        }
        let questions = JSON.parse(JSON.stringify(review_json.questions));
        // console.log("cur_review_id:", cur_review_id);
        // console.log("resultconfigs:", resultconfigs);
        // console.log("questions:", questions);
        let qanda = [];
        let qmaxscores = [];
        let qobtainedscores = [];
        let autofailquestions = [];
        if (questions.length !== 0) {
            questions.forEach((item) => {
                let autofail = true;
                // let naallowed = true;
                item.autofail.forEach((t) => {
                    if(t.selected) {
                        autofail = t.value === 'Yes' ? true : false;
                    }
                })
                let qtype = "";
                item.type.forEach((typeitem) => {
                    if (typeitem.selected === true) {
                        qtype = typeitem.value;
                    }
                });
                let eqtype = qtype === "Boolean" ? "no" : null;
                let this_max_score = 0
                item.weight.forEach((subitem) => {
                    if (subitem.selected === true) {
                        this_max_score = parseInt(subitem.value)
                        if(!autofail) {
                            qmaxscores.push({qid: item.id, score: parseInt(subitem.value), autofail: autofail, na: 'no'});
                        } else {
                            qmaxscores.push({qid: item.id, score: 0, autofail: autofail, na: 'no'});
                        }
                    }
                },item)
                qanda.push({qid: item.id, question: item.question, answer: eqtype, autofail: autofail, score: 0, na: 'no', comment: '', max_score: this_max_score });
                qobtainedscores.push({qid: item.id, score: 0, autofail: autofail, na: 'no'});
            })
        }

        qanda = this.props.qanda === null ? qanda : this.props.qanda
        let totalscore = 0;
        let obtainedscore = 0;
        qmaxscores.forEach((item) => {
            totalscore += item.score;
        })
        qobtainedscores.forEach((item) => {
            obtainedscore += item.score;
        })
        let scorecolor = "#ffffff";
        let scorename ="";
        let ragscore = (obtainedscore / totalscore) * 100;
        resultconfigs.forEach((item) => {
            if (ragscore > parseFloat(item.lbound) && ragscore <= parseFloat(item.ubound)) {
                scorecolor = item.color;
                scorename = item.termname;
            }
        })
        // console.log("qmaxscores:", qmaxscores);
        // console.log("qobtainedscores:", qobtainedscores);
        // console.log("totalscore:", totalscore);
        // console.log("obtainedscore:", obtainedscore);
        // console.log("qanda:", qanda);
        Store.updateStore('qanda', qanda);
        Store.updateStore('totalscore', totalscore);
        Store.updateStore('obtainedscore', obtainedscore);
        Store.updateStore('scorecolor', scorecolor);
        Store.updateStore('scorename', scorename);
        Store.updateStore('autofailquestions', autofailquestions);
        this.setState({cur_review_id, questions, review_json, qmaxscores, qobtainedscores, totalscore, obtainedscore, resultconfigs, openconfigs, scorecolor, scorename, qanda});
    }

    setAnswer = (qid) => (event) => {
        event.preventDefault();
        // console.log("setAnswer:", qid);
        let qanda = JSON.parse(JSON.stringify(this.state.qanda));
        qanda.forEach((item) => {
            if (item.qid === parseInt(qid)) {
                if (event.target.value === "0" || event.target.value === "fail") {
                    item.score = 0;
                }
                if (event.target.value === "pass") {
                    item.score = item.max_score;
                }
                item.answer = event.target.value;
            }
        })
        Store.updateStore('qanda', qanda);
        this.setState({qanda});
    }

    onCommentChange = (question) => (event) => {
        event.preventDefault();
        // console.log("setAnswer:", qid);
        let qanda = JSON.parse(JSON.stringify(this.state.qanda));
        qanda.forEach((item) => {
            if (item.qid === parseInt(question.id)) {
                item.comment = event.target.value;
            }
        })
        Store.updateStore('qanda', qanda);
        this.setState({qanda});        
    }

    onNAChecked = (qid) => (event) => {
        event.preventDefault()
        // console.log('question id in onNAChecked', qid)
        let qanda = JSON.parse(JSON.stringify(this.state.qanda));
        
        qanda.forEach((item) => {
            // console.log('item', item)
            if (item.qid === parseInt(qid)) {
                console.log('matched item', item)
                item.na = item.na === "no" ? "yes" : "no";
            }
        })
        // console.log('qanda in onNAChecked', qanda)
        Store.updateStore('qanda', qanda);
        this.setState({qanda});
    }

    generateNASetion = (question) => {
        // event.preventDefault()
        // console.log(question);
        let autofail = true;
        let naallowed = true;
        question.autofail.forEach((item) => {
            if(item.selected) {
                autofail = item.value === 'Yes' ? true : false;
            }
        })
        question.naallowed.forEach((item) => {
            if(item.selected) {
                naallowed = item.value === 'Yes' ? true : false;
            }
        })
        if(!autofail && naallowed) {
            // let qanda_item = {}
            // this.state.qanda.forEach((item) => {
            //     if(item.qid === parseInt(question.id)) {
            //         qanda_item = item
            //     }
            // })
            // console.log('qanda_item in generateNASetion', qanda_item)
            // if(qanda_item.na) {
            //     console.log('I am in true')
            //     return <input type="checkbox" name={`check-${question.id}`} defaultChecked={true} onChange={this.onNAChecked(question.id)} />
            // } else {
            //     console.log('I am in false')
            //     return <input type="checkbox" name={`check-${question.id}`} defaultChecked={false} onChange={this.onNAChecked(question.id)} />
            // }
            // return <input type="checkbox" onChange={this.onNAChecked(question.id)} />
            const selected_qanda = this.state.qanda.find((item) => {
                return item.qid === question.id;
            })
            if (selected_qanda.na === "no") {
                return (
                    <FaRegSquare style={{cursor: "pointer"}} onClick={this.onNAChecked(question.id)} />                
                );
            } else {
                return (
                    <FaRegCheckSquare style={{cursor: "pointer"}} onClick={this.onNAChecked(question.id)} />                
                );
            }
            
        }
    }

    generateAnswerSection = (question) => {
        // console.log(question);
        let question_type = "";
        let autofail = true;
        let qandaAssoc = Store.getStoreData('qanda_assoc')
        let answer = qandaAssoc[question.id.toString()].answer === null ? '0' : qandaAssoc[question.id.toString()].answer
        // let naallowed = true;
        question.autofail.forEach((item) => {
            if(item.selected) {
                autofail = item.value === 'Yes' ? true : false;
            }
        })
        question.type.forEach((item) => {
            if (item.selected === true) {
                question_type = item.value;
            }
        })

        if(autofail) {
            return (
                <MRModalSelect style={{width: "90px"}} onChange={this.setAnswer(question.id)} value={answer}>
                    <option value="0">Choose</option>
                    <option value="fail">FAIL</option>
                    <option value="pass">PASS</option>
                </MRModalSelect>
            );
        }
        // console.log("question type:", question_type);
        let html = <div></div>;
        switch (question_type) {
            // case "Memo":
            //     html = this.createMemo(question.id);
            //     break;
            // case "Alpha":
            //     html = this.createAlpha(question.id);
            //     break;
            case "Boolean":
                html = this.createBoolean(question.id, answer);
                break;
            case "Numeric":
                html = this.createNumeric(question.id, answer);
                break;
            default:
                break;
        }
        return html;
    }

    createMemo = (question_id) => {
        return (
            <MRModalTextarea rows="6" onChange={this.setAnswer(question_id)}></MRModalTextarea>
        );
    }

    createBoolean = (question_id, answer) => {
        return (
            <MRModalSelect style={{width: "90px"}} onChange={this.setAnswer(question_id)} value={answer}>
                <option value="0">Choose</option>
                <option value="no">NO</option>
                <option value="yes">YES</option>
            </MRModalSelect>
        );
    }

    createAlpha = (question_id) => {
        return (
            <MRModalInput type="text" onChange={this.setAnswer(question_id)} />
        );
    }

    createNumeric = (question_id, answer) => {
        return (
            <MRModalInputSmall type="number" onChange={this.setAnswer(question_id)} value={answer} />
        );
    }

    onScoreChange = (qid) => (event) => {
        event.preventDefault();
        let qanda = JSON.parse(JSON.stringify(this.state.qanda));
        if (event.target.value === "choose") {
            console.log("qid", qid);
            qanda.forEach((item) => {
                if (item.qid === parseInt(qid)) {
                    item.score = 0;
                }
            })
            // return;
        }
        let {qobtainedscores} = this.state;
        qobtainedscores.forEach((item) => {
            if (item.qid === qid) {
                item.score = parseInt(event.target.value);
            }
        })
        let obtainedscore = 0;
        qobtainedscores.forEach((item) => {
            obtainedscore += item.score;
        })
        let scorecolor = "#ffffff";
        let scorename ="";
        let ragscore = (obtainedscore / this.state.totalscore) * 100;
        this.state.resultconfigs.forEach((item) => {
            if (ragscore > parseFloat(item.lbound) && ragscore <= parseFloat(item.ubound)) {
                scorecolor = item.color;
                scorename = item.termname;
            }
        })
        if(event.target.value !== 'choose'){
            qanda.forEach((item) => {
                if (item.qid === parseInt(qid)) {
                    item.score = parseInt(event.target.value);
                }
            })
        }
        // console.log("onScoreChange:", qobtainedscores);
        // console.log("obtainedscore:", obtainedscore);
        Store.updateStore('qanda', qanda);
        Store.updateStore('obtainedscore', obtainedscore);
        Store.updateStore('scorecolor', scorecolor);
        Store.updateStore('scorename', scorename);
        this.setState({qobtainedscores, obtainedscore, scorecolor, scorename, qanda});
    }

    onOpenScoreChange = (qid) => (event) => {
        event.preventDefault();
    }

    getScores = () => {
        let {qanda} = this.state
        let result = {score_color: '#ff0000', actual_outcome: '', termname: '', percentage: 0};
        let total_score = 0;
        let obtained_score = 0;
        let auto_failed = false;
        for(let d of qanda) {
            // console.log('d in getScores', d);
            if (d.autofail && d.answer === "fail") {
                auto_failed = true;
            }
            total_score = d.na === "no" ? total_score + d.max_score : total_score + 0;
            obtained_score = d.na === "no" ? obtained_score + d.score : obtained_score + 0;
        }
        const score_percentage = total_score === 0 ? 0 : Math.ceil((obtained_score/total_score)*100);
        const scoreobj = this.state.resultconfigs.find((item) => {
            return score_percentage <= item.ubound;
        })
        result.score_color = scoreobj.color;
        result.actual_outcome = scoreobj.actual_outcome;
        result.termname = scoreobj.termname;
        result.percentage = score_percentage;
        if (auto_failed) {
            const autofailed_scoreobj = this.state.resultconfigs.find((item) => {
                return item.actual_outcome === "Fail";
            })
            result.score_color = autofailed_scoreobj.color;
            result.actual_outcome = autofailed_scoreobj.actual_outcome;
            result.termname = autofailed_scoreobj.termname;
        }
        Store.updateStore('scorecolor', result.score_color);
        Store.updateStore('scorename', result.termname);
        Store.updateStore('obtainedscore', result.percentage);
        Store.updateStore('totalscore', total_score);
        Store.updateStore('actual_outcome', result.actual_outcome);
        // console.log("RESULT CONFIGURATIONS:", this.state.resultconfigs);
        // console.log("QANDA:", qanda);
        return result
    }

    handleAutofailChange = (qid) => (event) => {
        event.preventDefault();
        let {autofailquestions} = this.state;
        autofailquestions.forEach((item) => {
            if (parseInt(item.qid) === parseInt(qid) && event.target.value === "fail") {
                item.failed = true;
            }
        })
        Store.updateStore('autofailquestions', autofailquestions);
    }

    render()
    {
        // console.log('questions in render', this.state.questions);
        let qanda = Store.getStoreData('qanda')
        let qandaAssoc = {}
        console.log('store qanda', qanda)
        if(qanda !== null) {
            for(let q of qanda) {
                qandaAssoc[q.qid.toString()] = q
            }            
        }
        Store.updateStore('qanda_assoc', qandaAssoc)
        return (
            <div>
                {
                    (() => {
                        if (this.state.questions !== null && this.state.openconfigs.length === 0) {
                            let scores = this.getScores();
                            // console.log("RETURN FROM GET SCORES:", scores);
                            return (
                                <div>
                                    <ScoreSectiondiv>
                                        <div style={{float: "left", fontSize: "16px", fontWeight: "600"}}>{scores.percentage}%</div>
                                        <div style={{float: "left", width: "20px", height: "20px", marginLeft: "5px", borderRadius: "2px", backgroundColor: scores.score_color}}></div>
                                        <div style={{clear: "both"}}></div>
                                        <div>{scores.termname} ({scores.actual_outcome})</div>
                                    </ScoreSectiondiv>
                                </div>
                            );
                        }
                    })()
                }
                {
                    (() => {
                        let qNumber = 0
                        if (this.state.questions !== null) {
                            return (
                                <div>
                                    <div style={{width: "100%", fontSize:12, fontWeight:400, paddingLeft: "10px", paddingRight: "10px"}}>
                                        <div style={{float: "left", width: "5%", paddingLeft: "10px"}}>#</div>
                                        <div style={{float: "left", width: "30%", paddingLeft: "10px"}}>Question</div>
                                        <div style={{float: "left", width: "45%", paddingLeft: "10px"}}>Answer</div>
                                        <div style={{float: "left", width: "10%", paddingLeft: "10px"}}>Score</div>
                                        <div style={{float: "left", width: "10%", paddingLeft: "10px"}}>N/A</div>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                    {
                                        this.state.questions.map((question, index) => {
                                            // const odd = index%2 !== 0 ? true : odd;
                                            qNumber++
                                            let autofail = true;
                                            question.autofail.forEach((t) => {
                                                if(t.selected) {
                                                    autofail = t.value === 'Yes' ? true : false;
                                                }
                                            })
                                            let row_color = autofail ? '#ffeedd' : '#ffffff'
                                            // console.log('question',question)
                                            // console.log('qanda',qanda)
                                            return (
                                                <div key={index} style={{width: "100%", fontSize:12, fontWeight:400, backgroundColor: row_color, padding: "5px 10px 5px 10px"}}>
                                                    <div style={{float: "left", width: "5%", paddingLeft: "10px"}}>Q{qNumber}</div>
                                                    <div style={{float: "left", width: "30%", paddingLeft: "10px"}}>{question.question.replace(/'+/g, "'")}</div>
                                                    <div style={{float: "left", width: "45%", paddingLeft: "10px"}}>
                                                        {this.generateAnswerSection(question)}
                                                        <MRModalTextarea style={{marginTop: "5px", width: "90%"}} onChange={this.onCommentChange(question)} value={qandaAssoc[question.id.toString()].comment} />
                                                    </div>
                                                    <div style={{float: "left", width: "10%", paddingLeft: "10px"}}>
                                                    {
                                                        (() => {
                                                            if (this.state.openconfigs.length === 0) {
                                                                return (
                                                                    <MRModalSelect onChange={this.onScoreChange(question.id)} value={qandaAssoc[question.id.toString()].score}>
                                                                        {
                                                                            (() => {
                                                                                let options = [];
                                                                                if (autofail) {
                                                                                    const qandaobj = this.state.qanda.find((item) => {
                                                                                        return item.qid === question.id;
                                                                                    })
                                                                                    if (qandaobj.answer === "fail") {
                                                                                        options.push(<option key="choose" value="choose">Choose</option>);
                                                                                        for (let i=0; i<=qandaobj.max_score-1; i++) {
                                                                                            options.push(<option key={i} value={i}>{i}</option>);
                                                                                        }
                                                                                    } else if (qandaobj.answer === "pass") {
                                                                                        for (let i=qandaobj.max_score; i<=qandaobj.max_score; i++) {
                                                                                            options.push(<option key={i} value={i}>{i}</option>);
                                                                                        }
                                                                                    } else {
                                                                                        options.push(<option key="choose" value="choose">Choose</option>);
                                                                                        for (let i=0; i<=qandaobj.max_score; i++) {
                                                                                            options.push(<option key={i} value={i}>{i}</option>);
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    options.push(<option key="choose" value="choose">Choose</option>);
                                                                                    for (let i=0; i<question.weight.length; i++) {
                                                                                        options.push(<option key={question.weight[i].value} value={question.weight[i].value}>{question.weight[i].value}</option>);
                                                                                        if (question.weight[i].selected === true) {
                                                                                            break;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                return options;
                                                                            })()
                                                                        }
                                                                    </MRModalSelect>
                                                                );
                                                            } else {
                                                                let autofail = false;
                                                                question.autofail.forEach((item) => {
                                                                    if (item.value === "Yes" && item.selected === true) {
                                                                        autofail = true;
                                                                    }
                                                                })
                                                                if (!autofail) {
                                                                    return (
                                                                        <MRModalSelect onChange={this.onOpenScoreChange(question.id)}>
                                                                            {
                                                                                (() => {
                                                                                    let options = [];
                                                                                    for (let i=0; i<this.state.openconfigs.length; i++) {
                                                                                        options.push(<option key={i} value={this.state.openconfigs[i].value}>{this.state.openconfigs[i].termname}</option>);
                                                                                    }
                                                                                    return options;
                                                                                })()
                                                                            }
                                                                        </MRModalSelect>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <MRModalSelect onChange={this.handleAutofailChange(question.id)}>
                                                                            {
                                                                                (() => {
                                                                                    let options = [<option value="pass">Pass</option>, <option value="fail">Fail</option>];
                                                                                    return options;
                                                                                })()
                                                                            }
                                                                        </MRModalSelect>
                                                                    );
                                                                }
                                                            }
                                                        })()
                                                    }
                                                    </div>
                                                    <div style={{float: "left", width: "10%", paddingLeft: "10px"}}>{this.generateNASetion(question)}</div>
                                                    <div style={{clear: "both"}}></div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            );
                        }
                    })()
                }
            </div>
        );
    }
}

export default QuestionAnswerForm;