import React from 'react';
import Store from '../../Common/Store';
import RegisterIndex from './GenericRegisterHome';
import FckRegister from './FckRegister';
import TeamRegister from './TeamRegister';

class RegisterHome extends React.Component {
	render () {
		switch (Store.getStoreData('role')) {
			case "admin_manager":
			case "manager":
            case "senior_manager":
            case "view_only_manager":
            case "qa_manager":
            	return <FckRegister />;
            	break;
            case "qa_reviewer":
            case "file_checker":
            	return <RegisterIndex />;
            	break;
			default:
				return <TeamRegister />;
		}
	}
}

export default RegisterHome;