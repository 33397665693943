import React from 'react';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js';
import {FiDownload} from 'react-icons/fi';
import {FaTimes} from 'react-icons/fa';
import ClipLoader from "react-spinners/ClipLoader";

// const MRModalContainer = styled.div`
//     display: block;
//     box-sizing: border-box;
//     height: auto;
//     position: absolute;
//     z-index: 1001;
//     background-color: #f7f7f7;
//     border-radius: 5px;
//     box-shadow: 0 0 20px #a7a6a6;
//     width: 92%;
//     left: -6%;
// `;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: fixed;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 55%;
    left: 30%
    bottom: 10%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderReview = styled.div`
    box-sizing: border-box;
    float: left;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 400;
    font-size: 15px;
    padding-top: 10px;
    `;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;
const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;

const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
const ScoreContainer = styled.div`
    background-color: #ecf0f1;
    width: 100%;
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
    margin-top: 10px;
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`

const ScoreBodyContainer = styled.div`
    padding: 15px;
`

const DummyOverlay = styled.div`
    display: table;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1002;
    opacity: 0.6;
`;

class AssignQcModal extends React.Component
{
    state = {cur_files_json: null, qa_reviewers: {}, cur_qa_review_id: null, task_ids: [], schedule_date: new Date(), binFiles: [], show_spinner: false};
    
    componentDidMount() {
        let task_ids = JSON.parse(JSON.stringify(this.state.task_ids));
        let qa_reviewers = JSON.parse(JSON.stringify(this.state.qa_reviewers));
        let company_id = 0;
        for (let key in Store.getStoreData('qc_checked_tasks')) {
            task_ids.push(parseInt(key));
            company_id = parseInt(Store.getStoreData('qc_checked_tasks')[key]);
        }
        company_id = company_id < 100000 ? company_id : company_id % 100000;
        let gc_assoc = Store.getStoreData('gc_assoc');
        let contacts = gc_assoc[company_id.toString()].users;
        let com_roles = Store.getStoreData('com_roles');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let admin_managers = Store.getStoreData('admin_managers');
        let gc_associate_users = Store.getStoreData('gc_associate_users');
        for (let key in contacts) {
            if (contacts[key.toString()].role === "qa_reviewer" || contacts[key.toString()].role === "qa_manager" ||contacts[key.toString()].role === "senior_manager") {
                qa_reviewers[key.toString()] = contacts[key.toString()].ContactName;
            }
        }
        for (let key in admin_managers) qa_reviewers[key.toString()] = admin_managers[key.toString()].ContactName;
        let cur_qa_review_id = Object.keys(qa_reviewers)[0];
        this.setState({task_ids, qa_reviewers, cur_qa_review_id});
    }
    
    filesLoaded = (files) => {
        let me = Store.getStoreData('loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({binFiles: files});
    }
    
    closeView = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    assignQC = (event) => {
        event.preventDefault();
        this.setState({show_spinner: true});
        let task_json = {"task_data": {}, "object_data":{}, "action_data": {}};
        task_json['task_data'].last_action = 'COMP_FCK_QC_ASSIGNMENT';
        task_json['task_data'].cur_lane = 'COMP_FCK_QC_ASSIGNMENT';
        task_json['task_data'].actiondate = Date.now();
        task_json['task_data'].cur_assigned_to = this.state.cur_qa_review_id;
        task_json['object_data'].bin_files = [...this.state.binFiles];
        let curmonth = parseInt(this.state.schedule_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.schedule_date.getFullYear()+'/'+currmonth+'/' : this.state.schedule_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.schedule_date.getDate() < 10 ? '0' + this.state.schedule_date.getDate() : this.state.schedule_date.getDate();
        task_json['action_data'] = {action: "COMP_FCK_QC_ASSIGNMENT", actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_QC_ASSIGNMENT", assigned_to: this.state.cur_qa_review_id, due_date:dueDateStr };
        let postData = {command: "assign_qc_tasks", task_json: task_json, task_ids: this.state.task_ids, assign_for_qa: true };
        const api = new APICall();
        api.command(postData, this.afterAssign);

        // console.log("postData", postData);
    }
    afterAssign = (result) => {
        console.log(result);
        alert('QA assigned successfully.');
        window.location.reload(true);
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    onScheduleDateChange = (date) => {
        this.setState({schedule_date: date});
    }

    render()
    {
        return (
            <div>
                {
                    (() => {
                        if (this.state.show_spinner === true) {
                          return (
                              <div>
                                  <DummyOverlay>
                                      <div style={{display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                                          <ClipLoader
                                              size={150}
                                              color={"#123abc"}
                                              loading={true}
                                          />
                                      </div>
                                  </DummyOverlay>
                              </div>
                          );
                        }
                    })()
                }
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Assign Quality Assurance</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        <MRModalHeaderReview>You have selected {this.state.task_ids.length} files to assign for QA</MRModalHeaderReview>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyInner style={{backgroundColor: "#ffffff"}}>
                            <ScoreContainer>
                                <ScoreHeaderContainer>File Check Header Information</ScoreHeaderContainer>
                                <ScoreBodyContainer>
                                    <MRModalLabel>QA Reviewer</MRModalLabel>
                                    <MRModalSelect onChange={this.handleChange} name="cur_qa_review_id" defaultValue={this.state.cur_qa_review_id}>
                                        {
                                            (() => {
                                                let qcreviewers = [];
                                                for (let [key, value] of Object.entries(this.state.qa_reviewers)) {
                                                    qcreviewers.push(<option key={key} value={key}>{value}</option>);
                                                 }
                                                 return qcreviewers;
                                            })()
                                        }
                                    </MRModalSelect>
                                    <MRModalLabel>Scheduled Date</MRModalLabel>
                                    <CSLDateCover id="qc_assign">
                                        <DatePicker
                                                onChange={this.onScheduleDateChange}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                locale={"en-GB"}
                                                value={this.state.schedule_date}
                                                />
                                    </CSLDateCover>

                                    <Style.ModalNameLabelDiv>Existing Attachments</Style.ModalNameLabelDiv>
                                    <Style.ModalNameInputDiv style={{ minHeight:'100px', height: '100px'}}>
                                    {
                                        (() => {
                                            if(this.state.cur_files_json !== null) {
                                                return(
                                                    <div style={{width:'100%', display: 'flex', overflow: 'auto', minHeight: 140}}>
                                                    {
                                                        this.state.curBinFiles.map((f, index) => {
                                                            // console.log('f',f);
                                                            let file_parts = f.name.split('.');
                                                            let ext = 'PDF';
                                                            switch(file_parts[file_parts.length - 1]) {
                                                            case 'jpeg':
                                                            case 'jpg' : ext = 'JPG';break;
                                                            case 'png' : ext = 'PNG'; break;
                                                            default: ext = 'PDF';
                                                            }
                                                            // console.log('ext',ext);
                                                            return(
                                                                <Style.FileContainer key={index}>
                                                                <Style.FileTypeContainer>{ext}</Style.FileTypeContainer>
                                                                <Style.FileInfoContainer>
                                                                    <strong style={{color: '#000000'}}>{f.name}</strong><br /><br />
                                                                    Added just now<br /><br />
                                                                    <a style={{ cursor: 'pointer'}} onClick={() => this.handleDownload(index)}><FiDownload /> Download</a> &nbsp; 
                                                                </Style.FileInfoContainer>
                                                                </Style.FileContainer>
                                                            )

                                                        })
                                                    }
                                                    </div>
                                                )
                                            } else {
                                                return <div>No existing attachments.</div>
                                            }
                                        })()

                                    }
                                    </Style.ModalNameInputDiv>
                                    
                                    <div style={{display: 'flex'}}>
                                        <ModalNameLabelDiv>Add files</ModalNameLabelDiv>
                                        <ModalNameInputDiv style={{ minHeight:'140px'}}>
                                            <Dropzone onFilesAdded={this.filesLoaded} />
                                        </ModalNameInputDiv>                            
                                    </div>
                                </ScoreBodyContainer>
                            </ScoreContainer>                            
                        </MRModalBodyInner>
                    </MRModalBody>
                    <MRModalFooter>
                        <MRModalNextBtn onClick={this.assignQC}>Submit</MRModalNextBtn>
                        <MRModalNextBtn onClick={this.closeView}>Close</MRModalNextBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalFooter>
                </MRModalContainer>
            </div>
        );
    }
}

export default AssignQcModal;