import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import FcHeader from './FcHeader';
import WelcomeBanner from './Common/WelcomeBanner';
import FCConfigTablesHOC from './Tables/FCConfigTablesHOC';
import MGRAddFileCheckModal from './Modals/MGRAddFileCheckModal';
import CSLHeader from './Common/CSLHeader';


class Index extends React.Component
{
	state = {OverlayHeight: null, FCKOverlayHeight: null, ContactName: null, OverlayForModal: null, CheckModalOverlay: null, MGRAddFileCheckModal: null, ReportModal: null, rid: 0};

	constructor(props) {
		super(props);
		this.component_id = Store.registerCallBack('MGRAddFileCheckModal', this.addMGRFileCheckModal);
	}

	addMGRFileCheckModal = () => {
		const MGRAddFileCheckModal = Store.getStoreData('MGRAddFileCheckModal');
		this.setState({MGRAddFileCheckModal});
	}

	render()
	{
		// console.log('config called');
		return(
			<div style={{backgroundColor: "#f2f2f3", position: "relative"}}>
				<FcHeader />
				<WelcomeBanner welcome={'Filechecker Configuration'} />
				{/* <CSLHeader toggleModuleConfigView={this.refreshThisState} from_view={this.state.cur_view} refreshParentState={this.refreshThisState} homeText={'File Checker'} /> */}
				{
					(() => {
						if (this.state.MGRAddFileCheckModal !== null) {
							return <MGRAddFileCheckModal />
						} else {
							return <FCConfigTablesHOC />
						}
					})()
				}
				
				{/* <WelcomeBanner welcomeText={'Filechecker Configuration'} /> */}
				
				
			</div>
		);
	}
}

export default Index;