import React from 'react';
import styled from 'styled-components';
import Store from '../../../Common/Store';
import {AiFillQuestionCircle} from 'react-icons/ai'
import '../MGRAddFileCheckModal.css';


const MRModalLabel = styled.label`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;
const MRModalTextArea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;

const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 350px;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;    
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;

const FormGroup = styled.div`
    margin-top: 25px;
`;

const RadiolevelOn = styled.label`
    color: white;
    background-color: #37ADA7;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 60px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;

const Radiolevel = styled.label`
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #ccc;
    width: 60px;
    padding: 6px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;    
`;

class MGRGeneral extends React.Component {

    state = {
        ready: false,
        general: null,
        sys_templates_enabled: false,
        na_questions_enabled: false,
        na_section_enabled: false,
        approval_process_enabled: false
    };

    componentDidMount() {
        console.log("general", this.props.general)
        let sys_templates_enabled = false;
        let na_questions_enabled = false;
        let na_section_enabled = false;
        let approval_process_enabled = false;
        if(Store.getStoreData('env') === "select") {
            let module_config = Store.getStoreData('module_config');
            if(Object.keys(module_config).length > 0) {
                sys_templates_enabled = module_config.general.sys_templates_enabled;
                na_questions_enabled = module_config.general.allow_na_questions_enabled;
                na_section_enabled = module_config.general.allow_na_section_enabled;
                approval_process_enabled = module_config.general.approval_process_enabled;
            } 
            
        }        
        this.setState({general: this.props.general, ready: true, sys_templates_enabled,na_questions_enabled, na_section_enabled, approval_process_enabled});
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            let sys_templates_enabled = false;
            let na_questions_enabled = false;
            let na_section_enabled = false;
            let approval_process_enabled = false;
            if(Store.getStoreData('env') === "select") {
                let module_config = Store.getStoreData('module_config');
                if(Object.keys(module_config).length > 0) {
                    sys_templates_enabled = module_config.general.sys_templates_enabled;
                    na_questions_enabled = module_config.general.allow_na_questions_enabled;
                    na_section_enabled = module_config.general.allow_na_section_enabled;
                    approval_process_enabled = module_config.general.approval_process_enabled;
                } 
            }
            this.setState({general: this.props.general, sys_templates_enabled, na_questions_enabled, na_section_enabled, approval_process_enabled});
        }
    }

    // generalSave = (event) => {
    //     event.preventDefault();
    //     const general = {
    //         selected_gc_id: this.state.selected_gc_id,
    //         name: this.state.name,
    //         description: this.state.description,
    //         allowConditionalQuestion: this.state.allowConditionalQuestion,
    //         allowGuidance: this.state.allowGuidance,
    //         allowActive: this.state.allowActive
    //     }               
    //     console.log("this.general", this.state)
    //     this.props.generalSave(general);
    // }

    handleChange = (event) => {
        event.preventDefault();
        let {general} = this.state;
        general[event.target.name] = event.target.value;
        this.props.generalSave(general);
    }

    chooseTempalte = (event) => {
        event.preventDefault();
        this.props.chooseTempalte(parseInt(event.target.value))
    }

    handleConditionChange = () => {
        let {general} = this.state;
        if (general.config.conditional_questions_enabled) {
            general.config.conditional_questions_enabled = false;
        } else {
            general.config.conditional_questions_enabled = true;
        }
        this.props.generalSave(general);
    }

    handleGuidanceChange = () => {
        let {general} = this.state;
        if (general.config.guiadance_note_enabled) {
            general.config.guiadance_note_enabled = false;
        } else {
            general.config.guiadance_note_enabled = true;
        }
        this.props.generalSave(general);
    }

    handleActiveChange = () => {
        let {general} = this.state;
        if (general.config.is_active) {
            general.config.is_active = false;
        } else {
            general.config.is_active = true;
        }
        this.props.generalSave(general);
    }

    handleNaQuestionChange = () => {
        let {general} = this.state;
        if (general.config.allow_na_questions_enabled) {
            general.config.allow_na_questions_enabled = false;
        } else {
            general.config.allow_na_questions_enabled = true;
        }
        this.props.generalSave(general);
    }

    handleNaSectionChange = () => {
        let {general} = this.state;
        if (general.config.allow_na_section_enabled) {
            general.config.allow_na_section_enabled = false;
        } else {
            general.config.allow_na_section_enabled = true;
        }
        this.props.generalSave(general);
    }

    handleApprovalProcess = () => {
        let {general} = this.state;
        console.log("general", general);
        if (general.config.approval_process_enabled) {
            general.config.approval_process_enabled = false;
        } else {
            general.config.approval_process_enabled = true;
        }
        this.props.generalSave(general);
    }
    

    render()
    {
        if (!this.state.ready) {
            return (<div>Loading</div>);
        }
        
        return (   
            <div style={{padding: "20px 30px"}}>
                {
                    (() => {
                        if(Store.getStoreData('env') === "select") {
                            return(<div><MRModalLabel>Choose Group Company</MRModalLabel>
                                <MRModalSelect style={{width: "100%", marginTop: "10px"}} onChange={this.handleChange} name="company_id" value={this.state.general.company_id} >
                                {
                                    (() => {
                                        let grp_companies = [];
                                        Store.getStoreData('gc_companies').forEach((item) => {
                                            if(item.is_active === 1)grp_companies.push(<option key={item.id} value={item.id}>{item.company_name}</option>);
                                        })
                                        return grp_companies;
                                    })()
                                }
                                </MRModalSelect></div>)
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.sys_templates_enabled && Store.getStoreData('rid') === 0) {
                            return (<div><FormGroup>Template</FormGroup>
                            <MRModalSelect style={{width: "100%", marginTop: "10px"}} id="template_id" name="template_id" value={this.state.general.template_id} onChange={this.chooseTempalte}>
                                <option key="0" value="0">Select Template</option>
                                {
                                    (() => {
                                        let model_reviews = [];
                                        console.log("model_reviews:", Store.getStoreData('model_reviews'));
                                        Store.getStoreData('model_reviews').forEach((item) => {
                                            model_reviews.push(<option key={item.id} value={item.id}>{item.nick_name}</option>);
                                        })
                                        return model_reviews;
                                    })()
                                }
                                </MRModalSelect>
                            </div>)
                        }

                    })()
                }
                <FormGroup>
                    <MRModalLabel>Name</MRModalLabel>
                    <MRModalInput style={{marginTop: "10px", fontSize: "14px", padding: "0px 0px 0px 5px"}} type="text" name="name" value={this.state.general.name} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup>
                    <MRModalLabel>Description</MRModalLabel>
                    <MRModalTextArea style={{marginTop: "10px", marginBottom: "25px"}} rows="4" name="description" value={this.state.general.description} onChange={this.handleChange}></MRModalTextArea>
                </FormGroup>
                {/*<MRModalLabel>Allow Conditional Question ? <AiFillQuestionCircle style={{color : '#37ada7'}}/></MRModalLabel>                                                                         
                <div style={{paddingBottom: "15px"}}>
                    <div className="switch" style={{ margin: '0px 0px 5px 60px' }}>
                        {
                            (() => {
                                if(this.state.general.config.conditional_questions_enabled) return <div onClick={this.handleConditionChange}><RadiolevelOn>Yes</RadiolevelOn><Radiolevel>No</Radiolevel></div>
                                else return <div onClick={this.handleConditionChange}><Radiolevel>Yes</Radiolevel><RadiolevelOn>No</RadiolevelOn></div>
                            })()
                        }
                    </div>
                </div>*/}
                <MRModalLabel>Allow Guidance Note ? <AiFillQuestionCircle style={{color : '#37ada7'}}/></MRModalLabel>
                <div style={{paddingBottom: "15px"}}>
                    <div className="switch" style={{ margin: '0px 0px 5px 60px' }}>
                        {
                            (() => {
                                if(this.state.general.config.guiadance_note_enabled) return <div onClick={this.handleGuidanceChange}><RadiolevelOn>Yes</RadiolevelOn><Radiolevel>No</Radiolevel></div>
                                else return <div onClick={this.handleGuidanceChange}><Radiolevel>Yes</Radiolevel><RadiolevelOn>No</RadiolevelOn></div>
                            })()
                        }
                    </div>
                </div> 
                <MRModalLabel>Active ? <AiFillQuestionCircle style={{color : '#37ada7'}}/></MRModalLabel>
                <div style={{paddingBottom: "15px"}}>
                    <div className="switch" style={{ margin: '0px 0px 5px 60px' }}>
                        {
                            (() => {
                                if(this.state.general.config.is_active) return <div onClick={this.handleActiveChange}><RadiolevelOn>Yes</RadiolevelOn><Radiolevel>No</Radiolevel></div>
                                else return <div onClick={this.handleActiveChange}><Radiolevel>Yes</Radiolevel><RadiolevelOn>No</RadiolevelOn></div>
                            })()
                        }
                    </div>
                </div> 
                <div>
                    <MRModalLabel>Allow N/A Questions ? <AiFillQuestionCircle style={{color : '#37ada7'}}/></MRModalLabel>
                    <div style={{paddingBottom: "15px"}}>
                        <div className="switch" style={{ margin: '0px 0px 5px 60px' }}>
                            {
                                (() => {
                                    if(this.state.general.config.allow_na_questions_enabled) return <div onClick={this.handleNaQuestionChange}><RadiolevelOn>Yes</RadiolevelOn><Radiolevel>No</Radiolevel></div>
                                    else return <div onClick={this.handleNaQuestionChange}><Radiolevel>Yes</Radiolevel><RadiolevelOn>No</RadiolevelOn></div>
                                })()
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <MRModalLabel>Allow N/A Section logic ? <AiFillQuestionCircle style={{color : '#37ada7'}}/></MRModalLabel>
                    <div style={{paddingBottom: "15px"}}>
                        <div className="switch" style={{ margin: '0px 0px 5px 60px' }}>
                            {
                                (() => {
                                    if(this.state.general.config.allow_na_section_enabled) return <div onClick={this.handleNaSectionChange}><RadiolevelOn>Yes</RadiolevelOn><Radiolevel>No</Radiolevel></div>
                                    else return <div onClick={this.handleNaSectionChange}><Radiolevel>Yes</Radiolevel><RadiolevelOn>No</RadiolevelOn></div>
                                })()
                            }
                        </div>
                    </div>
                </div>
                {
                    (() => {
                        if (this.state.approval_process_enabled) {
                            return (
                                <div>
                                    <MRModalLabel>Enable Approval Process ? <AiFillQuestionCircle style={{color : '#37ada7'}}/></MRModalLabel>
                                    <div style={{paddingBottom: "15px"}}>
                                        <div className="switch" style={{ margin: '0px 0px 5px 60px' }}>
                                            {
                                                (() => {
                                                    if(this.state.general.config.approval_process_enabled) return <div onClick={this.handleApprovalProcess}><RadiolevelOn>Yes</RadiolevelOn><Radiolevel>No</Radiolevel></div>
                                                    else return <div onClick={this.handleApprovalProcess}><Radiolevel>Yes</Radiolevel><RadiolevelOn>No</RadiolevelOn></div>
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })()
                }
            </div>
        );
        
    }


}

export default MGRGeneral;