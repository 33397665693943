import React from "react";
import Store from "../Common/Store.js";
import Iframe from "react-iframe";
import * as jsPDF from "jspdf";
import ReportSectionHOC from "./MGR/ReportSectionHOC";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import * as Style from "./Common/StyledComponents";
import moment from "moment";

const CloseBtn = styled.button`
  box-sizing: border-box;
  background-color: #ffcc00;
  color: #282828;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 2px solid #dadada;
  margin-left: 5px;
  font-size: 11px;
  float: right;
  cursor: pointer;
  font-weight: bold;
  font-family: "Montserrat";
`;

const marginX = 12;
const marginY = 8;
const maxX = 210 - 2 * marginX;
const maxY = 297 - 2 * marginY;
const footer_height = 15;
const para_gap = 9;
const line_gap = 5;
class FCKPrintview extends React.Component {
  state = { fck: null, fckPDF: null, pdfTemp: null, pdfURL: null, pageWidth: maxX, pageHeight: maxY, ready: true };

  constructor(props) {
    super(props);
  }

  processDate = (date) => {
    let date_str = date.split("T")[0];
    let date_parts = date_str.split("-");
    return `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`;
  };

  companyHeaderSection() {
    let report_task = Store.getStoreData("report_task");
    let gc_assoc = Store.getStoreData("gc_assoc");
    let report_task_json = Store.getStoreData("report_task_json");
    let gc_company = parseInt(report_task.parenttask.company_id) < 100000 ? Store.getStoreData("parent_company").company_name : gc_assoc[(parseInt(report_task.parenttask.company_id) % 100000).toString()].company_name;
    let cur_review = Store.getStoreData("my_reviews").filter(x => parseInt(x.id) === parseInt(report_task_json.object_data.headerinfo.cur_review_id))
    let cur_review_name = cur_review && 'nick_name' in cur_review[0] ? cur_review[0].nick_name : '';
    this.fckPDF.setFillColor("#FFFFFF");
    this.fckPDF.rect(0, 0, 210, 12, "F");
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontStyle("bold");
    if(cur_review_name === ''){
    	this.fckPDF.text("Filecheck Report for", 63, this.current_top);
    	this.fckPDF.setTextColor("#4C4C4C");
    	this.fckPDF.text(gc_company.replace(/'+/g, "'"), 99, this.current_top);
    }else{
    	this.fckPDF.text(`${cur_review_name} Report for ${gc_company.replace(/'+/g, "'")}`, 63, this.current_top);
    	this.fckPDF.setTextColor("#4C4C4C");
    	// this.fckPDF.text(gc_company.replace(/'+/g, "'"), 120, this.current_top);
    	// this.fckPDF.text(" Report for", 63, this.current_top);
    }
    // this.fckPDF.text("Filecheck Report for", 63, this.current_top);
    // this.fckPDF.setTextColor("#4C4C4C");
    // this.fckPDF.text(gc_company.replace(/'+/g, "'"), 99, this.current_top);

    this.fckPDF.setTextColor("#4C4C4C");
    this.fckPDF.setFontSize(19);
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text(report_task_json.object_data.headerinfo.file_reference.replace(/'+/g, "'"), 63, this.current_top + 15);
  }
  getAutofailQuestions = (task) => {
    let score_result = "N/A";
    let result_obj = {};
    let is_autofailed = false;
    let totalscore = 0;
    let obtained_score = 0;
    let question_info = [];

    //console.log("task.cur_lane", task.cur_lane)
    if (task.cur_lane === "COMP_FCK_COMPLETE" || task.cur_lane === "COMP_FCK_QC_COMPLETE") {
      const task_json = JSON.parse(task.task_json);
      const scoring = "scoring" in task_json.object_data.headerinfo.review_json ? task_json.object_data.headerinfo.review_json.scoring : {};
      const sections = task_json.object_data.headerinfo.review_json.sections;
      let q = 0;
      if ("scoring_enabled" in scoring && scoring.scoring_enabled === true) {
        sections.forEach((section) => {
          section.questions.forEach((question) => {
            q++;
            if (question.included === true) {
              const autofail_enabled = question.autofail.find((af) => af.selected === true);
              obtained_score = obtained_score + parseInt(question.obtained_score);
              if (autofail_enabled.value === "Yes") {
                if (parseInt(question.obtained_score) === 0) {
                  is_autofailed = true;
                  question_info.push(q);
                }
              }
            }
          });
        });
      }
    }
    return question_info;
  };
  leftSummaryPanel() {
    this.local_top = 239;
    const line_gap = 7;
    let report_task_json = Store.getStoreData("report_task_json");
    var res = this.questionAnswerdCalculation(report_task_json);
    this.fckPDF.setFillColor("#334761");
    this.fckPDF.rect(0, 0, 55, 297, "F");
    this.fckPDF.setFontSize(11);
    this.fckPDF.setTextColor("#FFFFFF");
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text(2, this.local_top, "Results");
    this.local_top += line_gap;
    let complaint = report_task_json.object_data.result.ragratingname;
    if (complaint && report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
      this.fckPDF.setTextColor(report_task_json.object_data.result.ragratingcolor);
      this.fckPDF.text(report_task_json.object_data.result.ragratingname, 2, this.local_top);
      this.local_top += line_gap;
    }

    this.fckPDF.setTextColor("#FFFFFF");
    this.fckPDF.setFontSize(10);
    this.fckPDF.setFontStyle("normal");
    this.fckPDF.text(2, this.local_top, "Questions Answered");
    this.fckPDF.text(res.qa, 38, this.local_top);
    this.local_top += line_gap;
    this.fckPDF.text(2, this.local_top, "N/A Questions");
    this.fckPDF.text(res.na_count, 38, this.local_top);
    this.local_top += line_gap;
    if (report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
      this.fckPDF.text(2, this.local_top, "Score");
      this.fckPDF.setFontStyle("normal");
      this.fckPDF.text(Math.round(report_task_json.object_data.result.scoresobtained).toString(), 38, this.local_top);
      var scroe_width = this.fckPDF.getTextWidth(Math.round(report_task_json.object_data.result.scoresobtained).toString());
      this.fckPDF.text("%", 38 + scroe_width, this.local_top);
    }
  }
  getColorFromText = (text) => {
    if (text === "Red") {
      return "#ff0000";
    } else if (text === "Amber") {
      return "#f7af00";
    } else if (text === "Green") {
      return "#0EC50E";
    } else {
      return "#FFFFFF";
    }
  };

  text = (text, x, y, { maxWidth, fontSize, vspace, fontWeight, colwidth, ignore_vspace }, mode = 1) => {
    console.log("THETEXT::",text, x, y, { maxWidth, fontSize, vspace, fontWeight, colwidth, ignore_vspace }, mode)
    let X = x;
    let Y = y;

    const EPSILON = 1.164167;
    let max_width = maxWidth || 0;
    let col_width = colwidth ? colwidth : null;
    let v_space = (fontSize + (vspace || 4)) * 0.2645833333;
    if(ignore_vspace === 1){
      v_space = 0
    }
    let fontStyle = fontWeight || "normal";
    this.fckPDF.setFontStyle(fontStyle);

    const words = text.trim().split(" ");
    // console.log("THETEXT::words",words)
    for (let i = 0; i < words.length; i++) {
      if (X + this.fckPDF.getTextWidth(`${words[i]}`) > max_width && max_width) {
        if (col_width && this.fckPDF.getTextWidth(words[i]) > col_width) {
          for (let k = 0; k < words[i].length; k++) {
            let str = (k + 1) % words[i].length ? "" : " ";

            mode && this.fckPDF.text(words[i][k] + str, X, Y);
            X += this.fckPDF.getTextWidth(words[i][k] + str);

            if (X + EPSILON > max_width) {
              mode && this.fckPDF.text("-", X, Y);
              X = x;
              Y += v_space;
            }
          }
          continue;
        } else {
          X = x;
          Y += v_space;
        }
      }
      mode && this.fckPDF.text(words[i] + " ", X, Y);
      X += this.fckPDF.getTextWidth(`${words[i]} `);
    }
    return Y + v_space;
  };
// Y = this.generateSummary(data, X, Y, { colwidth: 66, range: RANGE, vspace: 10, padTop: 8 }, mode);
  generateSummary = (data, x, y, { ncols, colwidth, range, rowGap, colGap, headerFontSize, dataFontSize, padTop, vspace, justifyCenter = true }, mode) => {
    let X = x;
    let Y = y;
    const labels = Object.keys(data);
    const values = Object.values(data);
    const n_cols = ncols || 2;

    console.log("generateSummary::",data,X,Y,labels,values)

    let gap = justifyCenter ? (range - n_cols * colwidth) / (n_cols - 1) : colGap || 2;
    // gap = 120;
    console.log("GAP ", gap);
    let cellHeight = 0;
    for (let i = 0; i < labels.length; i++) {

      let ignore_vspace = 0;
      if(labels[i] === 'File Edit Comments' || labels[i] === 'Comments By' || labels[i] === 'Comments'){
          ignore_vspace = 1
          
      }

      let lab_Y = this.text(labels[i] + ":", X, Y, { maxWidth: X + colwidth, vspace: padTop, fontSize: headerFontSize || 10, fontWeight: "bold", ignore_vspace:ignore_vspace }, mode);
      if(ignore_vspace){
        if(labels[i] === 'Comments By'){X = X+26}
        if(labels[i] === 'File Edit Comments'){X = X+35; values[i] = values[i].replace(/'+/g, "'").replace(/(?:\r\n|\r|\n)/g, " ")}
        if(labels[i] === 'Comments'){X = X+20}
      }

  	  if(labels[i] === 'File Edit Comments' || labels[i] === 'Comments'){
  	  	var val_Y = this.text(values[i], X, lab_Y, { maxWidth: maxX, fontSize: 10, fontWeight: "normal", colwidth: 26 }, mode);
  	  }else{
  	  	var val_Y = this.text(values[i], X, lab_Y, { maxWidth: X + colwidth, fontSize: dataFontSize || 10, fontWeight: "normal", colwidth: colwidth }, mode);
  	  }
      

      

      cellHeight = cellHeight < val_Y ? val_Y : cellHeight;

      if ((i + 1) % n_cols === 0 || gap < 0 || i === labels.length - 1) {
        X = x;
        Y = cellHeight + (rowGap || 0);
      } else {
        X += gap + colwidth;
      }
      // X += value_X + 26;
    }
    return Y + (vspace || 0);
  };

  getSummary = (y, { contact_list, report_task_json, status, assigned_date, due_date, completed_date, rd_obj, sale_date, summary, msg_text,file_edited_date,sale_date_label }, mode = 0) => {
    const RANGE = 136;
    this.fckPDF.setTextColor("#4C4C4C");

    let X = 65;
    let Y = y;

    let data = {};

    data["Reviewer"] = contact_list[report_task_json.object_data.headerinfo.reviewer_id];
    data["Reviewee"] = contact_list[report_task_json.object_data.headerinfo.reviewee_id];
    data["Status"] = status;
    data["Customer"] = report_task_json.object_data.headerinfo.customer_name.replace(/'+/g, "'");
    data["Assigned Date"] = assigned_date;
    data["Due Date"] = due_date;
    data["Review Date"] = completed_date;
    data["File Edited Date"] = file_edited_date

    Y = this.generateSummary(data, X, Y, { ncols: 4, colwidth: 30, range: RANGE, headerFontSize: 10, rowGap: 2, vspace: 10, padTop: 8 }, mode);

    data = {};
    data[rd_obj.display] = rd_obj.value;
    data[sale_date_label] = sale_date;

    Y = this.generateSummary(data, X, Y, { ncols: 2, colwidth: 36, range: RANGE, justifyCenter: false, colGap: 14, vspace: 10, padTop: 8 }, mode);

    const text_char = completed_date.length;
    console.log("text ", text_char);
    // draw 5th row
    let additional_fields = report_task_json.object_data.headerinfo.additional_fields;
    const m_additional_fields = Store.getStoreData("module_config").custom_fields.additional_fields;
    data = {};
    if (additional_fields.length !== 0) {
      for (let addl of additional_fields) {
        console.log("addl:", addl);
        if (addl.value !== "") {
          let m_single_field = m_additional_fields.find((item) => item.name === addl.name);
          let display = m_single_field !== undefined ? m_single_field.nickname.trim() : addl.nickname.trim();
          let value = addl.value.toString().trim();
          if (addl.type === "Dropdown") {
            if (addl.value === "other") value = addl.other_value.trim();
          }
          data[display] = value;
        }
      }
    }
    console.log("addl_fields:", data);
    Y = this.generateSummary(data, X, Y, { ncols: 3, colwidth: 36, range: RANGE, rowGap: 2, vspace: 10, padTop: 8 }, mode);

    data = {};
    data["Comments"] = summary;
    Y = this.generateSummary(data, X, Y, { colwidth: 33, range: RANGE, vspace: 10, padTop: 8 }, mode);

    if ("related_individual_id" in report_task_json.object_data.headerinfo) {
      if (parseInt(report_task_json.object_data.headerinfo.related_individual_id) !== 0) {
        data = {};
        data["Related Individual"] = contact_list[report_task_json.object_data.headerinfo.related_individual_id.toString()];
        Y = this.generateSummary(data, X, Y, { colwidth: 33, range: RANGE, vspace: 10, padTop: 8 }, mode);
      }
    }
// console.log("CONSOLEY::1",Y)
    if('admin_confirmation_text' in report_task_json.object_data){
      let edited_questions = "edited_questions" in report_task_json.object_data ? report_task_json.object_data.edited_questions : [];
      let edited_questions_as_text = '';
      if(edited_questions.length > 0){
          for(let i = 0; i < edited_questions.length; i++){
              if(edited_questions_as_text === ''){
                  edited_questions_as_text = edited_questions[i].s_index;
              }else{
                  edited_questions_as_text = edited_questions_as_text + ',' + edited_questions[i].s_index;
              }
          }
      }

      let lastConfirmedAction = null;
      let action_data = report_task_json.action_data;
      for (let i = action_data.length - 1; i >= 0; i--) {
        const action = action_data[i];

        if(action.cur_lane === "COMP_FCK_COMPLETE" && action.hasOwnProperty("confirmed_by")) {
          lastConfirmedAction = action;
          break;
        }
      }

      
      let comment_by = lastConfirmedAction !== null && "confirmed_by" in lastConfirmedAction ? contact_list[lastConfirmedAction.confirmed_by] : ""
      // console.log("lastConfirmedAction::",report_task_json.object_data.admin_confirmation_text,lastConfirmedAction,comment_by,edited_questions_as_text);
        data = {};
        data["File Edit Comments"] = report_task_json.object_data.admin_confirmation_text.replace(/'+/g, "'").replace(/(?:\r\n|\r|\n)/g, " ")
        Y = this.generateSummary(data, X, Y, { colwidth: 66, range: RANGE, vspace: 10, padTop: 8 }, mode);
        // Y = this.text("File Edit Comments :" + report_task_json.object_data.admin_confirmation_text, X, Y, { maxWidth: maxX, fontSize: 10, vspace: 3, fontWeight: "bold", colwidth: 26 }, mode);

        data = {};
        data["Comments By"] = comment_by
        Y = this.generateSummary(data, X, Y, { colwidth: 33, range: RANGE, vspace: 10, padTop: 8 }, mode);
        // Y = this.text("Comments By :" + comment_by, X, Y, { maxWidth: maxX, fontSize: 10, vspace: 3, fontWeight: "bold", colwidth: 26 }, mode);

        if(edited_questions_as_text !== ""){
          // Y = this.generateSummary(data, X, Y, { colwidth: 33, range: RANGE, vspace: 10, padTop: 8 }, mode);         
          Y = this.text("Question " + edited_questions_as_text + " were edited", X, Y, { maxWidth: maxX, fontSize: 10, vspace: 3, fontWeight: "bold", colwidth: 26 }, mode);
          
        }
        Y = Y+7
      }
    
    Y = this.text(msg_text, X, Y, { maxWidth: maxX, fontSize: 10, vspace: 3, fontWeight: "bold", colwidth: 26 }, mode);

    return Y;
  };

  processSummaryData = () => {
    let report_task = Store.getStoreData("report_task");
    let autofail_list = [];
    autofail_list = this.getAutofailQuestions(report_task.parenttask);
    let msg_text = "";
    if (autofail_list.length > 0) {
      let q_series = "";
      for (let i = 0; i < autofail_list.length; i++) {
        if (i < autofail_list.length - 1) {
          q_series += autofail_list[i] + ", ";
        } else {
          q_series = q_series.replace(/,\s*$/, "");
          q_series += " and " + autofail_list[i];
        }
      }
      if (autofail_list.length === 1) {
        msg_text = "Question  " + autofail_list[0] + " has triggered this review to automatically fail.";
      } else {
        msg_text = "Questions   " + q_series + " have triggered this review to automatically fail.";
      }
    }
    let report_task_json = Store.getStoreData("report_task_json");

    let summary = "";
    summary = "summary" in report_task_json.object_data.headerinfo.review_json ? report_task_json.object_data.headerinfo.review_json.summary.replace(/'+/g, "'").replace(/(?:\r\n|\r|\n)/g, " ") : "";
    if (report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled === false) {
      summary = report_task_json.object_data.non_score_assessment.reviewer_comments;
    }

    const module_custom_fields = Store.getStoreData("module_config").custom_fields;
    let sale_date_label = "Sale Date";
    let sd_is_active = true

    let rd_obj = { display: "Reviewee Designation", value: report_task_json.object_data.headerinfo.reviewee_designation };
    let rd_is_active = true

    if ("custom_terminology_fields" in report_task_json.object_data.headerinfo) {
      if (report_task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
        const sale_obj = report_task_json.object_data.headerinfo.custom_terminology_fields.find((item) => item.name === "sale_date");
        const rd_task_obj = report_task_json.object_data.headerinfo.custom_terminology_fields.find((item) => item.name === "reviewee_designation");
        if (sale_obj !== undefined) {
          sd_is_active = "is_active" in sale_obj ? sale_obj.is_active : true
          const module_sale_obj = module_custom_fields.custom_terminology_fields.find((item) => item.name === "sale_date");
          if (module_sale_obj !== undefined) {
            if (module_sale_obj.nickname !== "") sale_date_label = module_sale_obj.nickname;
          }
        }
        if (rd_task_obj !== undefined) {
          rd_is_active = "is_active" in rd_task_obj ? rd_task_obj.is_active : true
          const module_rd_obj = module_custom_fields.custom_terminology_fields.find((item) => item.name === "reviewee_designation");
          if (module_rd_obj !== undefined) {
            if (module_rd_obj.nickname !== "") rd_obj.display = module_rd_obj.nickname;
          }
        }
      }
    }
    if(!rd_is_active){
      rd_obj.value = 'N/A'
    }
    let due_date = this.processDate(report_task.parenttask.due_date);
    let sale_date_arr = report_task_json.task_data.sale_date.split("/");
    let sale_date = sd_is_active ? `${sale_date_arr[2]}/${sale_date_arr[1]}/${sale_date_arr[0]}` : "N/A";
    let status = report_task.parenttask.completed_by > 0 ? "Complete" : "Pending";
    let assigned_date = this.processDate(report_task.parenttask.created_at);
    let completed_date = report_task.parenttask.completed_date !== null ? this.processDate(report_task.parenttask.completed_date) : "-";
    let file_edited_date = report_task.parenttask.confirmed_time !== null ? moment.unix(report_task.parenttask.confirmed_time).format("DD/MM/YYYY") : '-'    
    let reviewee_list = Store.getStoreData("reviewee_list");
    let all_users = Store.getStoreData("users");
    let contact_list = {};

    for (let key in all_users) {
      contact_list[key.toString()] = all_users[key.toString()].ContactName;
    }

    return { contact_list, report_task_json, status, assigned_date, due_date, completed_date, rd_obj, sale_date, summary, msg_text,file_edited_date,sale_date_label };
  };

  generalFileCheckSummary() {
    let Y = 0;
    let local_top_general_summary = 52;
    const data = this.processSummaryData();

    Y = this.getSummary(local_top_general_summary, data, 0);
    console.log("Y ", Y);

    // Container
    this.fckPDF.roundedRect(63, 35, 139, Y - 35, 1, 1, "F");

    // Header
    if (data.report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
      this.fckPDF.setFillColor(data.report_task_json.object_data.result.ragratingcolor);
    } else {
      this.fckPDF.setFillColor("color" in data.report_task_json.object_data.non_score_assessment ? data.report_task_json.object_data.non_score_assessment.color : "#b3b2b1");
    }
    this.fckPDF.roundedRect(63, 35, 139, 11, 1, 1, "F");

    if (data.report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
      this.fckPDF.setTextColor("#FFFFFF");
      let complaint = data.report_task_json.object_data.result.ragratingname;
      let result_status = "";
      if (complaint === "Compliant") {
        result_status = data.report_task_json.object_data.result.ragratingname + " (" + data.report_task_json.object_data.result.outcome + ")";
        this.fckPDF.text(result_status, 68, 42);
      } else {
        result_status = data.report_task_json.object_data.result.ragratingname + " (" + data.report_task_json.object_data.result.outcome + ")";
        this.fckPDF.text(result_status, 68, 42);
      }
      this.fckPDF.text("Score", 176, 42);
      this.fckPDF.text(Math.round(data.report_task_json.object_data.result.scoresobtained).toString(), 189, 42);
      var scroe_width = this.fckPDF.getTextWidth(Math.round(data.report_task_json.object_data.result.scoresobtained).toString());
      this.fckPDF.text("%", 189 + scroe_width, 42);
    } else {
      var non_score_res = "non_score_assessment" in data.report_task_json.object_data ? data.report_task_json.object_data.non_score_assessment.result.replace(/'+/g, "'") : "";
      var non_score_res_width = this.fckPDF.getTextWidth(non_score_res);
      this.fckPDF.text(non_score_res.toString(), 198 - non_score_res_width, 42);
    }

    Y = this.getSummary(local_top_general_summary, this.processSummaryData(), 1);
    this.current_top = Y - 35;
    console.log("currenttop1", this.current_top);
  }
  associatedTask() {
    let report_task = Store.getStoreData("report_task");
    const childtasks = report_task.childtask;
    let reviewee_list = Store.getStoreData("reviewee_list");
    let all_users = Store.getStoreData("users");
    let contact_list = {};
    for (let key in all_users) {
      contact_list[key.toString()] = all_users[key.toString()].ContactName;
    }
    //console.log("currenttop2", this.current_top)
    this.current_top += 40;
    let right_first_col = 65;
    let right_second_col = 120;
    let right_third_col = 160;
    let right_forth_col = 180;
    var rect_hight = 20;
    childtasks.forEach((ctask, index) => {
      const ctask_json = JSON.parse(ctask.task_json);
      var task = ctask_json.object_data.subject === undefined ? "" : ctask_json.object_data.subject;
      rect_hight += this.getboxheight(task, right_second_col) * 0.8;
    });
    this.fckPDF.setFillColor("#FFFFFF");
    this.fckPDF.roundedRect(63, this.current_top, 139, rect_hight, 1, 1, "F");
    this.fckPDF.setFillColor("#37ADA7");
    this.fckPDF.roundedRect(63, this.current_top, 139, 11, 1, 1, "F");
    this.fckPDF.setTextColor("#FFFFFF");
    var local_top = this.current_top;
    local_top += 6;
    this.fckPDF.setFontStyle("bold");
    this.fckPDF.text("Task Associated with this Filecheck", right_first_col, local_top);
    local_top += 10;
    this.fckPDF.setTextColor("#767676");
    this.fckPDF.text("Task", right_first_col, local_top);
    this.fckPDF.text("Assigned To", right_second_col, local_top);
    this.fckPDF.text("Status", right_third_col, local_top);
    this.fckPDF.text("Due Date", right_forth_col, local_top);
    local_top += 5;

    if (childtasks.length !== 0) {
      let ctasks_html = [];
      childtasks.forEach((ctask, index) => {
        let assigned_to_id = null;
        let status = null;
        const cur_lanearr = ctask.cur_lane.split("_");
        if (cur_lanearr[cur_lanearr.length - 1] === "ASSIGNMENT") {
          assigned_to_id = ctask.cur_assigned_to;
          status = "Open";
        } else {
          assigned_to_id = ctask.completed_by;
          status = "Completed";
        }
        const assigned_to = contact_list[assigned_to_id] === undefined ? "" : contact_list[assigned_to_id];
        const due_date = this.processDate(ctask.due_date);
        const ctask_json = JSON.parse(ctask.task_json);
        const subject = ctask_json.object_data.subject === undefined ? "" : ctask_json.object_data.subject;

        this.fckPDF.setTextColor("#767676");
        this.fckPDF.setFontStyle("normal");
        //this.fckPDF.text(subject, right_first_col, this.current_top);
        //this.fckPDF.text(assigned_to, right_second_col, local_top);
        this.fckPDF.text(status, right_third_col, local_top);
        this.fckPDF.text(due_date, right_forth_col, local_top);
        //console.log("local_top1",local_top)
        var local_top_1 = this.processLine(subject, local_top, right_second_col);
        var local_top_2 = this.processLine(assigned_to, local_top, right_third_col, right_second_col);
        var local_increament = local_top_1 > local_top_2 ? local_top_1 : local_top_2;
        local_top = local_increament;
        local_top += 6;
        //this.processLine(assigned_to,local_top, right_third_col)

        //local_top = local_line_height;
        //console.log("local_top2",local_top)
      });
      this.current_top += rect_hight - 5;
    } else {
      this.current_top += 9;
    }
  }
  countTotalScore(question_arr) {
    var totalscore_sec = 0;
    for (let i = 0; i < question_arr.length; i++) {
      totalscore_sec += parseInt(question_arr[i].obtained_score);
    }
    return totalscore_sec;
  }
  questionAnswerdCalculation(report_task_json) {
    let sections = report_task_json.object_data.headerinfo.review_json.sections;
    //console.log("sections",sections)
    var totalcount = 0;
    var answeredcount = 0;
    var na_count = 0;
    var result = { qa: "", na_count: 0 };
    if (sections.length > 0) {
      for (let i = 0; i < sections.length; i++) {
        totalcount += sections[i].questions.length;
        for (var j = 0; j < sections[i].questions.length; j++) {
          if (sections[i].questions[j].answer.text != "") {
            answeredcount += 1;
          }
          if (sections[i].questions[j].naallowed == true) {
            na_count += 1;
          }
        }
      }
    }
    result.qa = answeredcount + "/" + totalcount;
    result.na_count = na_count.toString();
    return result;
  }
  questionSection() {
    this.current_top += 16;
    let report_task_json = Store.getStoreData("report_task_json");
    //console.log("report questiom", report_task_json);
    let sections = report_task_json.object_data.headerinfo.review_json.sections;
    var count = 0;
    let comtext = "";
    if (sections.length > 0) {
      for (var i = 0; i < sections.length; i++) {
        this.fckPDF.setFillColor("#0B223E");
        this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, 11, 1, 1, "F");
        this.fckPDF.setTextColor("#FFFFFF");
        this.fckPDF.setFontSize(10);
        this.fckPDF.setFontStyle("bold");
        this.current_top += line_gap;
        this.fckPDF.text(sections[i].name, 68, this.current_top);
        this.fckPDF.setFillColor("#FFFFFF");
        if (report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
          var totalscore_sec = this.countTotalScore(sections[i].questions);
          var score_width = this.fckPDF.getTextWidth(totalscore_sec.toString());
          this.fckPDF.roundedRect(191, this.current_top - 3, score_width + 2, 6, 1, 1, "F");
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.text(totalscore_sec.toString(), 192, this.current_top + 1);
        }

        this.current_top += line_gap;
        var localtop = 0;
        for (var j = 0; j < sections[i].questions.length; j++) {
          count += 1;
          var question_count = "Q" + count;
          var questionText = sections[i].questions[j].question.replace(/'+/g, "'");
          let is_autofail = false;
          let autofail_enabled = sections[i].questions[j].autofail.find((af) => af.selected === true);
          let na_enabled = sections[i].questions[j].included;
          let scoring_enabled = report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled;
          let obtained_sc = "obtained_score" in sections[i].questions[j] ? sections[i].questions[j].obtained_score : -1;
          var answerText = sections[i].questions[j].included === true ? sections[i].questions[j].answer.text.replace(/'+/g, "'") : "N/A";
          localtop = this.getboxheight(questionText);
          localtop += this.getboxheight(answerText);
          if (sections[i].questions[j].comments.enabled) {
            comtext = "text" in sections[i].questions[j].comments ? sections[i].questions[j].comments.text.replace(/'+/g, "'") : "";
            localtop += this.getboxheight(comtext.replace(/'+/g, "'"));
          }
          if (this.current_top + localtop >= 280) {
            this.fckPDF.addPage();
            this.fckPDF.setFillColor("#F2F2F2");
            this.fckPDF.rect(55, 0, 210, 297, "F");
            this.leftSummaryPanel();
            // this.footersection();
            this.current_top = marginY;
            this.fckPDF.setFillColor("#FFFFFF");
            if (na_enabled == true && scoring_enabled == true && autofail_enabled.value == "Yes" && obtained_sc == "0") {
              this.fckPDF.setDrawColor(255, 0, 0);
              this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, localtop, 1, 1, "FD");
            } else {
              this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, localtop, 1, 1, "F");
            }

            this.current_top += para_gap;
            this.fckPDF.setTextColor("#4C4C4C");
            this.fckPDF.setFontStyle("bold");
            this.fckPDF.text(question_count, this.page_right_start + 2, this.current_top);

            if (report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
              let obtained_score = "obtained_score" in sections[i].questions[j] ? sections[i].questions[j].obtained_score : "";
              let obtained_score_width = this.fckPDF.getTextWidth(obtained_score.toString());
              this.fckPDF.setFillColor("#7A8593");
              this.fckPDF.roundedRect(191, this.current_top - 4, obtained_score_width + 2, 6, 1, 1, "F");
              this.fckPDF.setTextColor("#FFFFFF");
              this.fckPDF.text(obtained_score.toString(), 192, this.current_top);
            } else {
              let non_score_res = "";
              if (sections[i].questions[j].included) {
                non_score_res = "non_scoring_info" in sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.label : "";
              } else {
                non_score_res = "n/a";
              }

              let non_score_res_width = this.fckPDF.getTextWidth(non_score_res.toString());
              var back_gr_col = "non_scoring_info" in sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.color : "#7A8593";
              back_gr_col = non_score_res === "n/a" ? "#7A8593" : back_gr_col;
              this.fckPDF.setFillColor(back_gr_col);
              this.fckPDF.roundedRect(197 - non_score_res_width, this.current_top - 4, non_score_res_width + 2, 6, 1, 1, "F");
              this.fckPDF.setTextColor("#FFFFFF");
              this.fckPDF.text(non_score_res.toString(), 198 - non_score_res_width, this.current_top);
            }

            this.current_top += para_gap;
            this.current_top = this.processLine(questionText, this.current_top);
            this.current_top += para_gap;
            this.fckPDF.setFontStyle("bold");
            this.fckPDF.text("Answer", this.page_right_start + 2, this.current_top);
            this.current_top += line_gap;
            this.current_top = this.processLine(answerText, this.current_top);
            if (sections[i].questions[j].comments.enabled) {
              this.current_top += para_gap;
              this.fckPDF.setFontStyle("bold");
              this.fckPDF.text("Comments", this.page_right_start + 2, this.current_top);
              this.current_top += para_gap;
              this.fckPDF.setFontStyle("normal");
              comtext = "text" in sections[i].questions[j].comments ? sections[i].questions[j].comments.text.replace(/'+/g, "'") : "";
              this.current_top = this.processLine(comtext, this.current_top);
            }
            this.current_top += 15;
          } else {
            this.fckPDF.setFillColor("#FFFFFF");
            if (na_enabled == true && scoring_enabled == true && autofail_enabled.value == "Yes" && obtained_sc == "0") {
              this.fckPDF.setDrawColor(255, 0, 0);
              this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, localtop, 1, 1, "FD");
            } else {
              this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, localtop, 1, 1, "F");
            }

            this.current_top += para_gap;
            this.fckPDF.setFontStyle("bold");
            this.fckPDF.text(question_count, this.page_right_start + 2, this.current_top);
            this.fckPDF.setFillColor("#7A8593");

            if (report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
              let obtained_score = "obtained_score" in sections[i].questions[j] ? sections[i].questions[j].obtained_score : "";
              let obtained_score_width = this.fckPDF.getTextWidth(obtained_score.toString());
              this.fckPDF.roundedRect(191, this.current_top - 4, obtained_score_width + 2, 6, 1, 1, "F");
              this.fckPDF.setTextColor("#FFFFFF");
              this.fckPDF.text(obtained_score.toString(), 192, this.current_top);
            } else {
              let non_score_res = "";
              if (sections[i].questions[j].included) {
                non_score_res = "non_scoring_info" in sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.label : "";
              } else {
                non_score_res = "n/a";
                answerText = "N/A";
              }

              let non_score_res_width = this.fckPDF.getTextWidth(non_score_res.toString());
              back_gr_col = "non_scoring_info" in sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.color : "#7A8593";
              back_gr_col = non_score_res === "n/a" ? "#7A8593" : back_gr_col;
              this.fckPDF.setFillColor(back_gr_col);
              this.fckPDF.roundedRect(197 - non_score_res_width, this.current_top - 4, non_score_res_width + 2, 6, 1, 1, "F");
              this.fckPDF.setTextColor("#FFFFFF");
              this.fckPDF.text(non_score_res.toString(), 198 - non_score_res_width, this.current_top);
            }

            this.current_top += para_gap;
            this.current_top = this.processLine(questionText, this.current_top);
            this.current_top += para_gap;
            this.fckPDF.setFontStyle("bold");
            this.fckPDF.text("Answer", this.page_right_start + 2, this.current_top);
            this.current_top += line_gap;
            this.current_top = this.processLine(answerText, this.current_top);
            this.fckPDF.setFontStyle("bold");
            if (sections[i].questions[j].comments.enabled) {
              this.current_top += para_gap;
              this.fckPDF.text("Comments", this.page_right_start + 2, this.current_top);
              this.current_top += 7;
              this.fckPDF.setFontStyle("normal");
              comtext = "text" in sections[i].questions[j].comments ? sections[i].questions[j].comments.text : "";
              this.current_top = this.processLine(comtext, this.current_top);
            }
            this.current_top += 15;
          }
        }
      }
    }
  }

  getboxheight(content, ud_line_right, pre_height) {
    var line_right = 202;
    if (ud_line_right !== undefined) {
      line_right = ud_line_right;
    }
    var cur_right = 65;
    const line_height = 4;
    var sentence = "";
    var localtop = 18;
    if (pre_height !== undefined) {
      localtop = pre_height;
    }
    if (content !== "") {
      var word_split = content.replace(/\n/g, "").split(" ");
      for (let i = 0; i < word_split.length; i++) {
        var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && word_split[i] != "<br>") {
          cur_right += wordLenght + gapLenght;
        } else {
          localtop += line_height;
          cur_right = 65;
          cur_right += wordLenght + gapLenght;
        }
      }
    }

    return localtop;
  }

  processLine(content, currenttop, ud_line_right, ud_Line_start, style) {
    var line_right = 202;
    if (ud_line_right !== undefined) {
      line_right = ud_line_right;
    }
    var cur_right = 65;
    if (ud_Line_start !== undefined) {
      cur_right = ud_Line_start;
    }
    const line_height = 4;
    if (content !== "") {
      var word_split = content.replace(/\n/g, "").split(" ");
      for (let i = 0; i < word_split.length; i++) {
        var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
        var gapLenght = this.fckPDF.getTextWidth(" ");
        if (cur_right + wordLenght + gapLenght < line_right && word_split[i] !== "<br>") {
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          this.fckPDF.text(word_split[i], cur_right, currenttop);
          cur_right += wordLenght + gapLenght;
        } else {
          currenttop += line_height;
          cur_right = 65;
          if (ud_Line_start !== undefined) {
            cur_right = ud_Line_start;
          }
          this.fckPDF.setTextColor("#4C4C4C");
          this.fckPDF.setFontStyle("normal");
          if (style !== undefined) {
            this.fckPDF.setFontStyle(style.weight);
            this.fckPDF.setTextColor(style.color);
          }
          if (word_split[i] !== "<br>") {
            this.fckPDF.text(word_split[i], cur_right, currenttop);
            cur_right += wordLenght + gapLenght;
          }
        }
      }
    }
    return currenttop;
  }

  processPageNumber = function () {
    var totalPages = this.fckPDF.internal.getNumberOfPages();
    for (var i = 1; i <= totalPages; i++) {
      var pageNumber = i.toString();
      this.fckPDF.setPage(i);
      this.fckPDF.text(pageNumber, this.fckPDF.internal.pageSize.getWidth() - 10, this.fckPDF.internal.pageSize.getHeight() - 15);
    }
  };
  footersection() {
    this.fckPDF.setFillColor("#0CABA6");
    this.fckPDF.rect(0, 283, 210, 297, "F");
    this.fckPDF.setTextColor("#FFFFFF");
    this.fckPDF.setFontStyle("normal");
    this.fckPDF.text("Copyright © 2020 Compliancy Services Ltd.", 74, 291);
  }

  bodySection() {
    this.fckPDF.setFillColor("#F2F2F2");
    this.fckPDF.rect(55, 12, 210, 297, "F");
    // this.fckPDF.clip();
  }

  addPage() {
    // this.footersection();
    this.fckPDF.addPage("a4", Store.getStoreData("orientation"));
  }

  generatePDF() {
    this.current_top = marginY;
    // this.current_top = 26;
    this.page_right_start = 63;
    this.currentPageNumber = 0;
    this.fckPDF = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
    });
    this.bodySection();
    // this.footersection();
    this.companyHeaderSection();
    this.leftSummaryPanel();
    // this.footersection();
    this.generalFileCheckSummary();
    this.associatedTask();
    this.questionSection();
    this.processPageNumber();
    var blob = this.fckPDF.output("blob");
    var binaryData = [];
    binaryData.push(blob);
    var pdfurl = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
    this.setState({ pdfURL: pdfurl, fckPDF: this.fckPDF, ready: true });
  }

  componentDidMount() {
    this.generatePDF();
  }

  closePdf = (event) => {
    event.preventDefault();
    window.location.reload("true");
  };

  render() {
    // window.scrollTo(0, 1000);
    // console.log("TOP ", window.scrollY);
    if (this.fckPDF === null) {
      return <div>Loading...</div>;
    } else {
      if (this.state.ready) {
        return (
          <div style={{ padding: "1.1rem 2.6rem" }}>
            <div style={{ paddingRight: "10px" }}>
              <Style.Categories>
                <span style={{ cursor: "pointer", color: "#337ab7" }} onClick={this.closePdf}>
                  <FaTimes />
                </span>
              </Style.Categories>
              <div style={{ clear: "both" }}></div>
            </div>
            <Iframe url={this.state.pdfURL} width="100%" height="900px" type="application/pdf" />
          </div>
        );
      } else {
        return <div>Loading...</div>;
      }
    }
  }
}

export default FCKPrintview;
