import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import Store from '../../Common/Store';
import General from './General';
import Email from './Email';
import CustomFields from './CustomFields';
import AlertBox from '../Common/AlertBox'
import Reminders from './Reminders';
import APICall from '../../Common/APICall.js';
import { Link } from "react-router-dom";
import Reload from '../../Common/Reload.js';
import { result } from '../Register/reportFilters';

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 70%;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;

class ModuleConfigHOC extends React.Component
{
    state = {
		active_section_id: "general",
		dirty: false,
        permissions: {},
		module_config: null,
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        initial_custom_fields: null,
        is_loading:false,
        alert_param: null
    };

    constructor(props) {
        super(props);
        const postData = { command: "list_company_and_users" };
        const api = new APICall();
        api.command(postData, this.getInitialPermission);
    }

    dynamicSort = (property) => {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : "";
        // console.log("requester:", requester);
        console.log("result", result);
        let company_users = JSON.parse(JSON.stringify(result.company_users));
        company_users.sort(this.dynamicSort("name"));
        const permissions = {
            user_roles: result.user_roles,
            gc_companies: result.gc_companies,
            roles: result.roles,
            company_users: company_users
        };
        let module_config = result.moduleconfig
        let value = 'Auto-Populated Dropdown'
        let auto_pop_exists = module_config.custom_fields.available_field_types.find((str) => str === value);
        if(!auto_pop_exists) {
            module_config.custom_fields.available_field_types.push('Auto-Populated Dropdown')
        }
        for(let f of module_config.custom_fields.custom_terminology_fields) {
            if('available_field_types' in f) {
                auto_pop_exists = f.available_field_types.find((str) => str === value);
                if(!auto_pop_exists) {
                    f.available_field_types.push(value)
                }
            }
        }
        const all_company_users = JSON.parse(JSON.stringify(company_users));
        this.setState({permissions, all_company_users, module_config: module_config, requester, initial_custom_fields: result.moduleconfig.custom_fields});
    }

	returnSection = (section) => (event) => {
		event.preventDefault();
        this.setState({active_section_id: section});
	}

	updateGeneral = (obj) => {
		let {module_config, dirty} = this.state;
		console.log("old_config", this.state);
		const old_config = {...module_config};
		// console.log("old_config", old_config);
		module_config.general = obj;
		const new_config = {...module_config};
		// console.log("new_config", new_config);
		if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
			dirty = true;
		}
        // console.log("dirty", dirty);
        this.setState({module_config});
    }

    updateGeneralServer = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.general = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    afterUpdate = (result) => {
        // console.log(result);
        alert(result.error_msg);
        this.setState({module_config: result.result, initial_custom_fields: result.result.custom_fields});
    }

    afterRoleUpdate = (result) => {
        alert(result.error_msg);
    }

    updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const task_assigned_email = module_config.email.find(item => item.id === "YEgo");
        if (module_config.general.email_remainder_enable === true && task_assigned_email.enabled === false) {
            module_config.general.self_notification.selected_option = "Never";
        }
        if (module_config.general.email_remainder_enable === true && task_assigned_email.enabled === true) {
            module_config.general.self_notification.selected_option = "Ask";
        }
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    updateEmailRemainder = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        if (module_config.general.email_remainder_enable === false) {
            module_config.email.forEach((s_mail) => {
                s_mail.enabled = false;
            })
            module_config.general.self_notification.selected_option = "Never";
        }
        const task_assigned_email = module_config.email.find(item => item.id === "YEgo");
        if (module_config.general.email_remainder_enable === true && task_assigned_email.enabled === false) {
            module_config.general.self_notification.selected_option = "Never";
        }
        if (module_config.general.email_remainder_enable === true && task_assigned_email.enabled === true) {
            module_config.general.self_notification.selected_option = "Ask";
        }
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }


    updateModuleconfig = () => {
        let {module_config, changed_user_roles, dirty} = this.state;
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config};
        postData['is_dirty'] = dirty;
        api.command(postData, this.afterUpdate);

        this.setState({is_loading: true})
    }


    updateSaveCustomAdditionalField = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        console.log('call obj::',obj)
        module_config.custom_fields.additional_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterAddCustomAdditionalField);
	}

    updateCustomAdditionalField = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields.additional_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterEditCustomAdditionalField);
	}
    samealertremoveHandler = (result,stack) => {
        this.setState({alert_param: null})
    }

    deleteCustomAdditionalField_old = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields.additional_fields = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterDeleteCustomAdditionalField);
	}

    deleteCustomAdditionalField = (addl_name) => {
        const postData = {command: "delete_additional_field", addl_name: addl_name};
        const api = new APICall();
        api.command(postData, this.afterDeleteCustomAdditionalField);
	}

    afterDeleteCustomAdditionalField = (result) => {
        console.log("afterDeleteCustomAdditionalField result:", result);
        const title = "Alert";
        let msg = "";
        if (result.result === "field_present") {
            msg = "This field cannot be removed as it is associated with one or more filechecks";
        } else {
            msg = "Custom Field deleted successfully";
            this.setState({module_config: result.result});
        }
        const alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}};
        this.setState({alert_param});
        // this.setState({module_config: result.result});
        // let msg = '';
        // let title = 'Alert';
        // msg = 'Custom Field deleted successfully.'
        // // alert("Custom Field deleted successfully.")
        // let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        // this.setState({alert_param: alert_param})

    }

    afterAddCustomAdditionalField = (result) => {
        let msg = '';
        let title = 'Alert';
        msg = 'Custom Field added successfully.'
        // alert("Custom Field added successfully.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        //this.setState({alert_param: alert_param, module_config: result.result})
        //alert(result.error_msg);
        this.setState({alert_param: alert_param, module_config: result.result, initial_custom_fields: result.result.custom_fields});
        let filechecker_index = JSON.parse(localStorage.getItem("filechecker-index"));
        filechecker_index.result.module_config = result.result;
        localStorage.setItem("filechecker-index", JSON.stringify(filechecker_index));

    }
    afterEditCustomAdditionalField = (result) => {
        let msg = '';
        let title = 'Alert';
        msg = 'Custom Field updated successfully.'
        // alert("Custom Field updated successfully.")
        let alert_param = {title: title, message: msg, ok_text: 'OK', confirm: false, alertHandler: this.samealertremoveHandler, stack: {id: 0}}
        this.setState({alert_param: alert_param})
        this.setState({module_config: result.result});
        let filechecker_index = JSON.parse(localStorage.getItem("filechecker-index"));
        filechecker_index.result.module_config = result.result;
        localStorage.setItem("filechecker-index", JSON.stringify(filechecker_index));
    }

    afterCustomUpdateStatus = (result) => {
        this.setState({module_config: result.result});
        let gifts_index = Store.getStoreData('gifts-index')
        if(gifts_index !== null && 'result' in gifts_index && 'module_config' in gifts_index.result){
            gifts_index.result.module_config = result.result;
        }
        console.log('gifts_index==>', gifts_index);
        Store.updateStore('gifts-index', gifts_index)
    }

    updateCustomFieldStatus = (obj) => {
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields.additional_fields = obj;
        //this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterCustomUpdateStatus);
	}
    updateReminder = (obj) => {
        let {module_config} = this.state;
        module_config.reminders = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterUpdate);
    }

    updateCustomFields = (obj) => {
        console.log('updateCustomFields obj', obj)
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        module_config.custom_fields = obj;
        this.setState({module_config});
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterToggle);
    }

    afterToggle = (result) => {
        console.log("Toggled");
    }

    updateCtfs = (obj) => {
        console.log("obj:", obj);
        let module_config = JSON.parse(JSON.stringify(this.state.module_config));
        for (let item of module_config.custom_fields.custom_terminology_fields) {
            if (item.name === obj.name) {
                if (obj.name === "sale_date") {
                    item.nickname = obj.nickname;
                    item.required = obj.required;
                    item.is_active = obj.is_active;
                } else {
                    console.log("item:", item);
                    item.nickname = obj.nickname;
                    item.required = obj.required;
                    item.other = obj.other;
                    item.type = obj.type;
                    item.dropdown_options = obj.dropdown_options;
                    item.is_active = obj.is_active;
                }
            }
        }
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        api.command(postData, this.afterEditCustomAdditionalField);
    }


    closePage = (event) => {
        event.preventDefault();
        let url = "/"
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        window.location.replace(url);
    }

	render()
	{
        if (Store.getStoreData('role') !== 'admin_manager') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
        if (this.state.module_config === null) {
            return (<div>Loading...</div>);
        }

        console.log('this.state', this.state)
		return (
			<div style={{padding: "10px 10px 20px 10px"}}>
                <CloseBtn id="close_moduleconfig" onClick={this.closePage}><FaTimes /></CloseBtn>
                <div style={{clear: "both"}}></div>
                <ReviewBodyContainer>
                	<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                		<ReviewTab onClick={this.returnSection("general")}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>General</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "email") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Email</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Email</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("custom_fields")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "custom_fields") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Custom Fields</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Custom Fields</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("reminders")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "reminders") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Reminders</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Reminders</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                	</ReviewLeftContainer>
                	<ReviewRightContainer>
                		{
                			(() => {
                				if (this.state.active_section_id === "general") {
                					return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} />);
                				}
                                if (this.state.active_section_id === "email") {
                                    return (<Email variables={this.state.module_config.variables} email={JSON.parse(JSON.stringify(this.state.module_config.email))} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateEmail={this.updateEmail} updateGeneralSave={this.updateEmailRemainder} updateGeneralServer={this.updateGeneralServer} />);
                                }
                                if (this.state.active_section_id === "custom_fields") {
                                    return (<CustomFields custom_fields={this.state.module_config.custom_fields} updateCtfs={this.updateCtfs} updateCustomFields={this.updateCustomFields} updateSaveCustomAdditionalField={this.updateSaveCustomAdditionalField} updateCustomAdditionalField={this.updateCustomAdditionalField}  deleteCustomAdditionalField={this.deleteCustomAdditionalField} updateCustomFieldStatus={this.updateCustomFieldStatus}/>);
                                }
                                if(this.state.active_section_id === "reminders") {
                                    return (<Reminders reminders={JSON.parse(JSON.stringify(this.state.module_config.reminders))} updateReminder={this.updateReminder} />);
                                }
                			})()
                		}
                        {
                            (() => {
                                //|| this.state.active_section_id === "custom_fields"
                                if (this.state.active_section_id === "general") {
                                    return (
                                        <FooterButtonsContainer>
                                            <CancelBtn id="moduleconfig_cancel" onClick={this.closePage}>Cancel</CancelBtn><SaveBtn id="moduleconfig_save" onClick={this.updateModuleconfig}>Save</SaveBtn>
                                        </FooterButtonsContainer>
                                    );
                                }
                            })()
                        }
                	</ReviewRightContainer>
                	<div style={{clear: "both"}}></div>
                </ReviewBodyContainer>
			    <AlertBox alertParam = {this.state.alert_param}/>
			</div>
		);
	}
}

export default ModuleConfigHOC;
