import React from 'react';
import Store from '../../Common/Store';
import TAPICall from './TAPICall';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import AnswerOverview from '../Partials/AnswerOverview';
import AnswerSection from '../Partials/AnswerSection';
import AnswerSummary from '../Partials/AnswerSummary';
import AssignFileCheck from '../Partials/AssignFileCheck';
import NonScoreResult from '../Partials/NonScoreResult';
import Assess from '../Partials/Assess';
//import TaskHeader from '../../Common/TaskHeader';
import Utils from '../../Common/Utils';
import CSLLoader from '../Common/CSLLoader';

const PillBox = styled.div`
    background-color: rgb(235, 235, 235);
    border: 1px solid rgb(223, 223, 223);
    text-align: center;
    display: inline-block;
    padding: 10px 18px;
`;

const ModalViewBack = styled.div`
    display: block;
    box-sizing: border-box;
    height: 1178px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    left: -10%;
    top: -5px;
    z-index: 1000;
    opacity: 0.8;
`;
const ModalView = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 80%;
    left: 1%;
`;

const ModalView_contain = styled.div`
       position: absolute;
        left: 10%;
        top: 50px;
        width: 100%;
        height: 1186px;
`;

const PillIcon = styled.div`
    display: inline-block;
`;
const PillText = styled.div`
    display: inline-block;
    padding-left: 25px;
`;
const ReviewHeaderText = styled.div`
    padding-top: 25px;
    font-size: 20px;
    font-weight: 600;
`;
const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const ReviewFooter = styled.div`
    text-align: right;
    padding-top: 30px;
`;
const SaveBtn = styled.button`
    background-color: #37ada7;
    padding: 10px 25px;
    border: 1px solid #37ada7;
    border-radius: 2px;
    color: #ffffff;
    cursor: pointer;
`;
const CancelBtn = styled.button`
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    color: #666666;
    cursor: pointer;
`;
const CompleteBtn = styled.button`
    background-color: #37ada7;
    padding: 10px 35px;
    border: 1px solid #37ada7;
    border-radius: 2px;
    color: #ffffff;
    cursor: pointer;
`;
const AnswerHr = styled.div`
    height: 2px;
    background-color: #d9d9d9;
    margin-top: 15px;
`;
const QStatus = styled.div`
    background-color: #0f2b4b;
    padding: 10px;
    color: #ffffff;
    margin-top: 15px;
`;
const SideBtnContainer = styled.div`
    margin-top: 15px;
    width: 100%;
`;

const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;

class AnswerViewHOC extends React.Component
{
    state = {task: null, cur_lane: null, task_json: null, admin_confirmation_text: "", action_data: null, review_json: null, active_section_id: "overview", payload: {}, task_id: null, section_payload: null, score_result: {}, non_score_result: [], non_score_assessment: {}, score_progression_enabled: true, send_mail_config: false, cur_view: "complete", loggedin_user_info: {}, taks_created_by: 0, show_approval:false, cur_manager_id: 0, manager_list:[], alertParam: null};
    componentDidMount() {

            let task_id = this.props.task_id;
         if(task_id === undefined){
             task_id = Store.getStoreData('fc_index');
        }

        //alert(task_id)
        //Store.getStoreData('fc_index');
        // const task_id = Store.getStoreData('fc_index');
        // console.log("document.referrer:", document.referrer);
        console.log("current task id:", task_id);
        const api = new TAPICall();
        const postData = {command: "get_task", task_id: task_id};
        api.command(postData, this.singleTask);
    }

    componentDidUpdate(prevProps) {
        if (this.state.show_approval) {
            this.el.scrollIntoView({ behavior: 'smooth' });
            // console.log("did update fired");
        }
        // console.log("State after update:", this.state);
    }

    shoApprovalsec = (event) => {
        let show_approval = this.state.show_approval ? false : true;
        this.setState({show_approval: show_approval});
    }

    singleTask = (result) => {
        // console.log("get_task result:", result);
        let is_register = Store.getStoreData('is_register');
        // console.log("is register", is_register)
        let {task, task_json, review_json, payload, task_id, score_result, score_progression_enabled, send_mail_config, loggedin_user_info, taks_created_by, cur_manager_id, manager_list} = this.state;
        let module_config = Store.getStoreData('module_config');
        if(Object.keys(module_config).length > 0) {
            score_progression_enabled = module_config.general.score_progression_enabled;
            send_mail_config =  module_config.general.email_remainder_enable;
        }


        task_json = JSON.parse(result.result.task.parenttask.task_json);
        if ("version" in task_json.object_data.headerinfo.review_json && task_json.object_data.headerinfo.review_json.version === 1) {
            if (!("approval_process_enabled" in task_json.object_data.headerinfo.review_json.general.config)) {
                task_json.object_data.headerinfo.review_json.general.config.approval_process_enabled = false;
            }
        }
        let non_score_assessment = "non_score_assessment" in task_json.object_data ? task_json.object_data.non_score_assessment : {reviewer_comments: "", result: "-"};
        review_json = task_json.object_data.headerinfo.review_json;
        if (review_json.scoring.scoring_enabled === false) {
            review_json.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    if (!("non_scoring_info" in question)) {
                        const non_scoring_info = {label: "Select", color: "#ffffff"};
                        question.non_scoring_info = non_scoring_info;
                    }
                })
            })
        }
        const task_company_id = parseInt(result.result.task.parenttask.company_id);
        const calculated_task_company_id = task_company_id < 100000 ? task_company_id : task_company_id % 100000;
        const gc_assoc = Store.getStoreData('gc_assoc');

        console.log("calculated_task_company_id", calculated_task_company_id);

        // const users = Store.getStoreData("users");
        const users = gc_assoc[calculated_task_company_id.toString()].users;
        let contacts = [];
        for (let key in users) {
            contacts.push(users[key.toString()]);
        }
        // const contacts = Store.getStoreData("reviewee_list");
        const admin_managers = Store.getStoreData("admin_managers");
        for (let key in admin_managers) {
            const exist = contacts.find(item => item.ID.toString() === key.toString());
            if (exist === undefined) {
                contacts.push({ID: key, ContactName: admin_managers[key.toString()].name, email: admin_managers[key.toString()].email});
            }
        }
        manager_list = [];
        for (let i=0; i<contacts.length; i++) {
            if (contacts[i].role === "admin_manager" || contacts[i].role === "senior_manager" || contacts[i].role === "manager") {
                manager_list.push({id: contacts[i].ID, name: contacts[i].ContactName});
            }
        }
        // console.log("task_json", task_json);
        console.log("contacts", contacts);

        // const reviewee_info = Store.getStoreData("reviewee_info");
        // const com_roles = Store.getStoreData('com_roles');
        // Object.entries(reviewee_info).forEach(([key, value]) => {
        //     if (com_roles[key.toString()] === 'admin_manager' ||  com_roles[key.toString()] === 'manager' || com_roles[key.toString()] === 'senior_manager') {
        //         manager_list.push({"id": key, "name": value})
        //     }
        // })

        manager_list.sort(this.compare);
        // console.log("manager_list", manager_list);

        cur_manager_id = manager_list[0].id;
        console.log("task_json.object_data.headerinfo.reviewer_id", task_json.object_data.headerinfo.reviewer_id);

        const gc_companies = Store.getStoreData("gc_companies");
        task_id = result.result.task.parenttask.id;
        payload.reviewer = contacts.find((item) => {
            return parseInt(item.ID) === task_json.object_data.headerinfo.reviewer_id;
        })

        payload.reviewer = payload.reviewer === undefined ? {ContactName: "Inactive User"} : payload.reviewer;
        payload.reviewee = contacts.find((item) => {
            return parseInt(item.ID) === task_json.object_data.headerinfo.reviewee_id;
        })
        payload.reviewee = payload.reviewee === undefined ? {ContactName: "Inactive User"} : payload.reviewee;

        if ("related_individual_id" in task_json.object_data.headerinfo === true) {
            if (parseInt(task_json.object_data.headerinfo.related_individual_id) !== 0) {
                payload.related_individual = contacts.find((item) => {
                    return parseInt(item.ID) === parseInt(task_json.object_data.headerinfo.related_individual_id);
                })
                payload.related_individual = payload.related_individual === undefined ? {ContactName: "Inactive User"} : payload.related_individual;
            }
        }

        payload.name = review_json.general.name;
        payload.description = review_json.general.description;
        payload.status = task_json.task_data.cur_lane === "COMP_FCK_ASSIGNMENT" ? "Pending" : "In Progress";
        //payload.company_name = parseInt(task_json.task_data.company_id) < 100000 ? Store.getStoreData('my_company').CompanyName : Store.getStoreData("gc_assoc")[(parseInt(task_json.task_data.company_id)%100000).toString()].company_name;
        payload.company_name = task_json.object_data.headerinfo.customer_name;
        let d = new Date(task_json.task_data.actiondate);
        payload.assigned_date = d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
        const saledatearr = task_json.task_data.sale_date.split("/");
        payload.sale_date = saledatearr[2]+"/"+saledatearr[1]+"/"+saledatearr[0];
        const duedatearr = task_json.task_data.due_date.split("/");
        payload.due_date = duedatearr[2]+"/"+duedatearr[1]+"/"+duedatearr[0];
        payload.childtask = result.result.task.childtask;
        payload.contacts = contacts;
        payload.company_id = result.result.task.parenttask.company_id;
        taks_created_by = result.result.task.parenttask.contact_id;
        // console.log("result.result.task.parenttask.company_id", result.result.task.parenttask.company_id)
        if (!("summary" in review_json)) {
            review_json.summary = "";
        }
        let s_index = 1;
        review_json.sections.forEach((section) => {
            if (!("id" in section)) {
                section.id = this.makeid(6);
            }
            if("sectionna" in section){
                section.checkedforna = section.checkedforna ? section.checkedforna : false;
            }
            section.questions.forEach((question) => {
                question.obtained_score = ('obtained_score' in question) ? question.obtained_score : "0";
                if('naallowed' in question) {
                    question.included = ('included' in question) ? question.included : true;
                }
                question.s_index = s_index;
                s_index = s_index + 1;
                if(!("hide_score" in question.auto_scoring)) {
                    question.auto_scoring.hide_score = false;
                }
                if("guidance_note" in question) {
                    question.guidance_note.collapsed  = ('collapsed' in question.guidance_note) ? question.guidance_note.collapsed : true;
                }
            })
        })
        let totalscore = 0;
        review_json.sections.forEach((section) => {
            section.questions.forEach((question) => {
                const q_type = question.type.find(t => t.selected === true);
                let qscore = 0;
                if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
                    if (question.auto_scoring.enabled === true) {
                        if (q_type.value === "Boolean") {
                            qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
                            // console.log("qscore Boolean:", qscore);
                        }
                        if (q_type.value === "Dropdown") {
                            qscore = Math.max.apply(null, question.preset_answers.map(pa => parseInt(pa.weight)));
                            console.log("qscore Dropdown:", qscore);
                        }
                    } else {
                        const qscore_obj = question.weight.find(item => item.selected === true);
                        qscore = parseInt(qscore_obj.value);
                    }
                } else {
                    const qscore_obj = question.weight.find(item => item.selected === true);
                    qscore = parseInt(qscore_obj.value);
                }
                // const qscore = question.weight.find((item) => {
                //     return item.selected;
                // })
                if (question.included) {
                    totalscore = totalscore + (parseInt(qscore));
                }
                // if (question.included) {
                //     totalscore = totalscore + (parseInt(qscore.value));
                // } else {
                //     totalscore = totalscore - (parseInt(qscore.value));
                // }
            })
        })
        let scoresobtained = 0;
        review_json.sections.forEach((section) => {
            section.questions.forEach((question) => {
                scoresobtained = scoresobtained + (parseInt(question.obtained_score));
            })
        })
        // console.log("totalscore ==>", totalscore)
        // console.log("scoresobtained ==>", scoresobtained)
        scoresobtained = scoresobtained === 0 || isNaN(scoresobtained) ? 0 : (scoresobtained / totalscore) * 100;
        score_result = task_json.object_data.result;
        let restructured_scores = [];
        let score_lbound = 0;
        // console.log("restructured_scores:", restructured_scores);
        while (score_lbound < 100) {
            let single_score = review_json.scoring.scores.find((score) => {
                return (parseInt(score.lbound) === score_lbound || parseInt(score.lbound) === (score_lbound + 1));
            })
            // console.log("single_score", single_score);
            restructured_scores.push(single_score);
            score_lbound = parseInt(single_score.ubound);
        }
        // console.log("restructured_scores:", restructured_scores);
        review_json.scoring.scores = restructured_scores;
        if (scoresobtained === 0) {
            score_result.ragratingcolor = review_json.scoring.scores[0].color;
            score_result.ragratingname = review_json.scoring.scores[0].termname;
            score_result.outcome = review_json.scoring.scores[0].actual_outcome;
        } else {
            review_json.scoring.scores.forEach((score) => {
                if (scoresobtained > score.lbound && scoresobtained <=score.ubound) {
                    score_result.ragratingcolor = score.color;
                    score_result.ragratingname = score.termname;
                    score_result.outcome = score.actual_outcome;
                }
            })
        }
        score_result.totalscore = totalscore;
        score_result.scoresobtained = scoresobtained;
        // console.log("score score_result", score_result)
        // if (document.referrer === "http://localhost:3002" || document.referrer.includes("filechecker")) {
        //     this.props.setBreadcrumbLastText(review_json.general.name);
        // }
        loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let non_score_result = [];
        if (review_json.scoring.scoring_enabled === false) {
            review_json.scoring.non_scores.forEach((item) => {
                const record = {color: item.color, label: item.label, count: "00"};
                non_score_result.push(record);
            });
        }

        if (review_json.scoring.scoring_enabled === false) {
            review_json.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    if (question.included) {
                        review_json.scoring.non_scores.forEach((item) => {
                            if (question.non_scoring_info.label !== "Select" && question.non_scoring_info.label === item.label) {
                                non_score_result.forEach((sitem) => {
                                    if (question.non_scoring_info.label === sitem.label) {
                                        let ext_count = parseInt(sitem.count);
                                        ext_count = ext_count + 1;
                                        ext_count = ext_count.toString();
                                        ext_count = ext_count.length === 1 ? ("0"+ext_count) : ext_count;
                                        sitem.count = ext_count;
                                    }
                                })
                            }
                        })
                    }
                })
            })
        }
        // console.log("non_score_result:", non_score_result);
        // console.log("Review JSON:", review_json);
        console.log("payload:", payload);
        let admin_confirmation_text = "admin_confirmation_text" in task_json.object_data ? task_json.object_data.admin_confirmation_text : "";
        let cur_lane = result.result.task.parenttask.cur_lane;
        this.setState({task: result, task_json, admin_confirmation_text, cur_lane, action_data: task_json.action_data, non_score_result, non_score_assessment, review_json, payload, task_id, score_result, score_progression_enabled, send_mail_config, loggedin_user_info, taks_created_by,cur_manager_id, manager_list});
    }

    compare = (a, b) => {
        const bandA = a.name.toUpperCase();
        const bandB = b.name.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
     }

    handleNonScoringInfo = (sections) => {
        let review_json = JSON.parse(JSON.stringify(this.state.review_json));
        let section_payload = sections.find(item => item.id === this.state.active_section_id);
        review_json.sections = sections;
        let non_score_result = [];
        if (review_json.scoring.scoring_enabled === false) {
            review_json.scoring.non_scores.forEach((item) => {
                const record = {color: item.color, label: item.label, count: "00"};
                non_score_result.push(record);
            });
        }

        if (review_json.scoring.scoring_enabled === false) {
            review_json.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    if (question.included) {
                        review_json.scoring.non_scores.forEach((item) => {
                            if (question.non_scoring_info.label !== "Select" && question.non_scoring_info.label === item.label) {
                                non_score_result.forEach((sitem) => {
                                    if (question.non_scoring_info.label === sitem.label) {
                                        let ext_count = parseInt(sitem.count);
                                        ext_count = ext_count + 1;
                                        ext_count = ext_count.toString();
                                        ext_count = ext_count.length === 1 ? ("0"+ext_count) : ext_count;
                                        sitem.count = ext_count;
                                    }
                                })
                            }
                        })
                    }
                })
            })
        }
        // console.log("section_payload:", this.state.section_payload);
        // console.log("active_section_id:", this.state.active_section_id);
        this.setState({review_json, non_score_result, section_payload});
    }

    makeid = (length) => {
       let result = '';
       let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       let charactersLength = characters.length;
       for ( let i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }

    returnSection = (section_index) => (event) => {
        // console.log("Section Index:", section_index);
        let {active_section_id, qanda, section_payload, review_json} = this.state;
        // console.log("section_payload", section_payload);
        active_section_id = section_index;
        if (section_index !== "overview" && section_index !== "summary" && section_index !== "assess") {
            section_payload = this.state.review_json.sections.find((section) => {
                return section.id === section_index;
            })
        }
        if (section_index === "summary") {
            section_payload = review_json.summary;
        }
        this.setState({active_section_id, section_payload});
    }

    modifySections = (sections, s_result) => {
        let {review_json, section_payload, score_result} = this.state;
        score_result = s_result;
        review_json.sections = sections;
        section_payload = review_json.sections.find((section) => {
            return section.id === this.state.active_section_id;
        })
        let non_score_result = [];
        if (this.state.review_json.scoring.scoring_enabled === false) {
            if (review_json.scoring.scoring_enabled === false) {
                review_json.scoring.non_scores.forEach((item) => {
                    const record = {color: item.color, label: item.label, count: "00"};
                    non_score_result.push(record);
                });
            }

            if (review_json.scoring.scoring_enabled === false) {
                review_json.sections.forEach((section) => {
                    section.questions.forEach((question) => {
                        if (question.included) {
                            review_json.scoring.non_scores.forEach((item) => {
                                if (question.non_scoring_info.label !== "Select" && question.non_scoring_info.label === item.label) {
                                    non_score_result.forEach((sitem) => {
                                        if (question.non_scoring_info.label === sitem.label) {
                                            let ext_count = parseInt(sitem.count);
                                            ext_count = ext_count + 1;
                                            ext_count = ext_count.toString();
                                            ext_count = ext_count.length === 1 ? ("0"+ext_count) : ext_count;
                                            sitem.count = ext_count;
                                        }
                                    })
                                }
                            })
                        }
                    })
                })
            }
        }
        // console.log("STATE SCORE:", score_result);
        this.setState({review_json, section_payload, score_result, non_score_result});
    }

    closeView = (event) => {
        //window.location.assign("/register");
        this.props.closeModal();
    }

    saveFileCheck = (event) => {
        event.preventDefault();
        let task_json = JSON.parse(JSON.stringify(this.state.task_json));
        let task = JSON.parse(JSON.stringify(this.state.task.result.task));
        let cur_lane = task.parenttask.cur_lane;
        task_json['task_data'].last_action = cur_lane === "COMP_FCK_AWAITING_APPROVAL" ? 'COMP_FCK_AWAITING_APPROVAL' : 'COMP_FCK_SAVED';
        task_json['task_data'].cur_lane = cur_lane === "COMP_FCK_AWAITING_APPROVAL" ? 'COMP_FCK_AWAITING_APPROVAL_SAVE' : 'COMP_FCK_SAVED';
        task_json['object_data'].headerinfo.review_json = this.state.review_json;
        let new_action_data = JSON.parse(JSON.stringify(task_json.action_data));
        new_action_data.push({action: "COMP_FCK_SAVED", actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_SAVED", assigned_to: task_json['task_data'].cur_assigned_to });
        task_json['action_data'] = {action: "COMP_FCK_SAVED", actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_SAVED", assigned_to: task_json['task_data'].cur_assigned_to };
        if(cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
            task_json['action_data'].action = 'COMP_FCK_AWAITING_APPROVAL_SAVE';
            task_json['action_data'].cur_lane = 'COMP_FCK_AWAITING_APPROVAL';
        }
        if (this.state.review_json.scoring.scoring_enabled === false) {
            task_json['object_data']['non_score_result'] = this.state.non_score_result;
            task_json['object_data']['non_score_assessment'] = this.state.non_score_assessment;
        }
        this.setState({action_data: new_action_data});
        let postData = {command: "update_filecheck_task", task_json: task_json, task_id: this.state.task_id };
        // console.log("postData", postData);
        const api = new TAPICall();
        api.command(postData, this.afterSave);
    }

    afterSave = (result) => {
        console.log("SAVED:", result);
        alert("Your responses have been saved");
        let task_json = JSON.parse(JSON.stringify(this.state.task_json));
        let action_data = JSON.parse(JSON.stringify(this.state.action_data));
        task_json.action_data = action_data;
        this.setState({task_json, action_data});
    }

    getMailStrucData = (data) => {
        let task_json = this.state.task_json;
        let headerinfo = task_json.object_data.headerinfo;
        let review_json_d = task_json.object_data.headerinfo.review_json;
        let com_roles = Store.getStoreData('com_roles');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let gr_company_id = review_json_d.general.company_id;
        let gc_assoc = Store.getStoreData('gc_assoc');
        let gc_company = "";
        //gc_company = parseInt(gr_company_id) < 100000 ? Store.getStoreData('my_company').CompanyName : gc_assoc[(parseInt(gr_company_id)%100000).toString()].company_name;
        gc_company = parseInt(task_json.task_data.company_id) < 100000 ? Store.getStoreData('parent_company').company_name : Store.getStoreData("gc_assoc")[(parseInt(task_json.task_data.company_id)%100000).toString()].company_name;
        let payload = this.state.payload;
        let date = new Date();

        // console.log("payload", payload.company_name,gc_company);

        let mail_body = data.body;
        mail_body = mail_body.replace(/{{reviewer}}/g, payload.reviewer.ContactName);
        mail_body = mail_body.replace(/{{reviewee}}/g, payload.reviewee.ContactName);
        mail_body = mail_body.replace(/{{scheduled_date}}/g, payload.due_date);
        mail_body = mail_body.replace(/{{task_name}}/g, payload.name);
        mail_body = mail_body.replace(/{{task_description}}/g, payload.description);
        mail_body = mail_body.replace(/{{status}}/g, "Completed");
        // mail_body = mail_body.replace(/{{review_type}}/g, com_roles[payload.reviewee.ID.toString()]);
        mail_body = mail_body.replace(/{{policy_start_date}}/g, payload.sale_date);
        mail_body = mail_body.replace(/{{created_date}}/g, date);
        mail_body = mail_body.replace(/{{company}}/g, gc_company);
        mail_body = mail_body.replace(/{{assigner}}/g, loggedin_user_info.ContactName);
        mail_body = mail_body.replace(/{{file_reference}}/g, headerinfo.file_reference);
        mail_body = mail_body.replace(/{{reviewee_designation}}/g, headerinfo.reviewee_designation);
        mail_body = mail_body.replace(/{{customer_name}}/g, payload.company_name);
        mail_body = mail_body.replace(/{{source_module}}/g, "");
        mail_body = mail_body.replace(/{{assigned_to}}/g, "");
        data.body = mail_body;

        let mail_subject = data.subject;
        mail_subject = mail_subject.replace(/{{reviewer}}/g, payload.reviewer.ContactName);
        mail_subject = mail_subject.replace(/{{reviewee}}/g, payload.reviewee.ContactName);
        mail_subject = mail_subject.replace(/{{scheduled_date}}/g, payload.due_date);
        mail_subject = mail_subject.replace(/{{task_name}}/g, payload.name);
        mail_subject = mail_subject.replace(/{{task_description}}/g, payload.description);
        mail_subject = mail_subject.replace(/{{status}}/g, "Completed");
        // mail_subject = mail_subject.replace(/{{review_type}}/g, com_roles[payload.reviewee.ID.toString()]);
        mail_subject = mail_subject.replace(/{{policy_start_date}}/g, payload.sale_date);
        mail_subject = mail_subject.replace(/{{created_date}}/g, date);
        mail_subject = mail_subject.replace(/{{company}}/g, gc_company);
        mail_subject = mail_subject.replace(/{{assigner}}/g, loggedin_user_info.ContactName);
        mail_subject = mail_subject.replace(/{{file_reference}}/g, headerinfo.file_reference);
        mail_subject = mail_subject.replace(/{{reviewee_designation}}/g, headerinfo.reviewee_designation);
        mail_subject = mail_subject.replace(/{{customer_name}}/g, payload.company_name);
        mail_subject = mail_subject.replace(/{{source_module}}/g, "");
        mail_subject = mail_subject.replace(/{{assigned_to}}/g, "");
        data.subject = mail_subject;
        return data;
    }

    completeFileCheck = (mode) => (event) => {
        event.preventDefault();
        let good_to_go = true;
        let answer_fault = false;
        let comment_fault = false;
        let label_fault = false;
        let blank_answers = {};
        let blank_comments = {};
        let blank_labels = {};
        // console.log("state:", this.state);
        // return false;
        this.state.review_json.sections.forEach((section) => {
            let q_indexes = [];
            let cq_indexes = [];
            let lq_indexes = [];
            section.questions.forEach((question, index) => {
                if (question.answer.text === "" && question.included) {
                    q_indexes.push(question.s_index);
                    good_to_go = false;
                    answer_fault = true;
                }
                if (this.state.review_json.scoring.scoring_enabled === false) {
                    if (question.included === true && question.non_scoring_info.label === "Select") {
                        lq_indexes.push(question.s_index);
                        good_to_go = false;
                        label_fault = true;
                    }
                }
                if (question.included === true && question.comments.enabled === true && question.comments.required === "true") {
                    const com_text = "text" in question.comments ? question.comments.text : "";
                    if (com_text === "") {
                        cq_indexes.push(question.s_index);
                        good_to_go = false;
                        comment_fault = true;
                    }
                }
            })
            blank_answers[section.name] = q_indexes;
            blank_comments[section.name] = cq_indexes;
            blank_labels[section.name] = lq_indexes;
        })
        let alertmsg = "";
        let commentmsg = "";
        let labelmsg = "";
        if (!good_to_go) {
            if (answer_fault) {
                alertmsg = "Answer field is mandatory. Please provide a response for\n";
                Object.entries(blank_answers).forEach(([key, value]) => {
                    if (value.length !== 0) {
                        let batext = "";
                        value.forEach((v) => {
                            batext = batext + v.toString() + ", ";
                        })
                        batext = batext.slice(0, -2);
                        alertmsg = alertmsg + "Question " + batext + " within " + key + "\n";
                    }
                })
            }
            if (label_fault) {
                labelmsg = "Labels for the following questions are blank. Please provide suitable response\n";
                Object.entries(blank_labels).forEach(([key, value]) => {
                    if (value.length !== 0) {
                        let batext = "";
                        value.forEach((v) => {
                            batext = batext + v.toString() + ", ";
                        })
                        batext = batext.slice(0, -2);
                        labelmsg = labelmsg + "Question " + batext + " within " + key + "\n";
                    }
                })
            }
            if (comment_fault) {
                commentmsg = "Comments for the following questions are blank. Please provide suitable response\n";
                Object.entries(blank_comments).forEach(([key, value]) => {
                    if (value.length !== 0) {
                        let batext = "";
                        value.forEach((v) => {
                            batext = batext + v.toString() + ", ";
                        })
                        batext = batext.slice(0, -2);
                        commentmsg = commentmsg + "Question " + batext + " within " + key + "\n";
                    }
                })
            }
            alert(alertmsg + labelmsg + commentmsg);
            return false;
        }
        if ("editcomp" in this.props && this.props.editcomp === true) {
            if (this.state.admin_confirmation_text === "") {
                alert("Please add Edit Confirmation Text to save your changes");
                return false;
            }
        }
        // console.log("hault");
        if (this.state.review_json.scoring.scoring_enabled === false) {
            if (this.state.non_score_assessment.result === "-" && this.state.non_score_assessment.reviewer_comments === "") {
                alert("Reviewer comments and Result are blank in assess section. Please provide a suitable response for these fields");
                return false;
            }
            if (this.state.non_score_assessment.result === "-" && this.state.non_score_assessment.reviewer_comments !== "") {
                alert("Result field is blank in assess section. Please provide a suitable response.");
                return false;
            }
            if (this.state.non_score_assessment.result !== "-" && this.state.non_score_assessment.reviewer_comments === "") {
                alert("Reviewer comments field is blank in assess section. Please provide a suitable response.");
                return false;
            }
        }

        let module_config = Store.getStoreData('module_config');
        let send_mail_config_data = {};
        let mail_config = {};
        let send_mail = this.state.send_mail_config;
        if(this.state.send_mail_config) {
            if(Object.keys(module_config).length > 0) {
                if (mode === "complete") {
                    mail_config = module_config.email.find((obj) => {
                        return obj.id === "LStk"
                    });
                }
                if (mode === "approval") {
                    mail_config = module_config.email.find((obj) => {
                        return obj.id === "EJsy"
                    });
                }
            }
            send_mail = mail_config.enabled;
            if(send_mail){
                send_mail_config_data = this.getMailStrucData(mail_config);
            }
            // console.log(mail_config);
        }

        let task_json = this.state.task_json;
        let send_autofail_email_data = null;
        if (this.state.review_json.scoring.scoring_enabled === true) {
            const ret_data = this.calculateFinalResult(JSON.parse(JSON.stringify(task_json.object_data.result)), this.state.review_json);
            task_json.object_data.result = ret_data.score;
            if (ret_data.is_autofailed === true) {
                const mc_config = Store.getStoreData('module_config');
                const autofail_email = mc_config.email.find(em => em.id === "PJfz");
                if (autofail_email !== undefined) {
                    if (autofail_email.enabled === true) {
                        send_autofail_email_data = {};
                        send_autofail_email_data.mail = this.getMailStrucData(autofail_email);
                        // const task_record = Store.getStoreData("pending_fctasks").find(pt => pt.id === this.state.task_id);
                        let af_users = [];
                        let gc_id = (this.state.task.result.task.parenttask.company_id > 100000) ? parseInt(this.state.task.result.task.parenttask.company_id) % 100000 : this.state.task.result.task.parenttask.company_id;
                        let gc_assoc = Store.getStoreData('gc_assoc');
                        let com_roles = Store.getStoreData('com_roles');

                        const gc_comp_users = gc_assoc[gc_id.toString()].users;
                        for (let key in gc_comp_users) {
                            if (gc_comp_users[key.toString()].role === 'manager' || gc_comp_users[key.toString()].role === 'senior_manager' || gc_comp_users[key.toString()].role === 'admin_manager') {
                                af_users.push(gc_comp_users[key.toString()])
                            }
                        }
                        // const admin_managers = Store.getStoreData("admin_managers");
                        // for (let key in admin_managers) {
                        //     const a_user = af_users.find(item => parseInt(item.ID) === parseInt(key));
                        //     if (a_user === undefined) {
                        //         af_users.push({ID: key, ContactName: admin_managers[key.toString()].name, email: admin_managers[key.toString()].email});
                        //     }
                        // }
                        send_autofail_email_data.af_users = af_users;
                    }
                }
            }
        }
        // console.log("send_autofail_email_data", send_autofail_email_data);
        // console.log("task_json", task_json);
        // return false;
        let last_action = mode === "approval" ? 'COMP_FCK_AWAITING_APPROVAL' : 'COMP_FCK_COMPLETE';
        let cur_lane = mode === "approval" ? 'COMP_FCK_AWAITING_APPROVAL' : 'COMP_FCK_COMPLETE';
        task_json['task_data'].last_action = last_action;
        task_json['task_data'].cur_lane = cur_lane;
        task_json['object_data'].headerinfo.review_json = this.state.review_json;
        task_json['action_data'] = {action: last_action, actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: cur_lane, assigned_to: 0 };
        if (this.state.review_json.scoring.scoring_enabled === false) {
            task_json['object_data']['non_score_result'] = this.state.non_score_result;
            task_json['object_data']['non_score_assessment'] = this.state.non_score_assessment;
        }
        if(mode ==="approval") {
            task_json['object_data'].approval_id = parseInt(this.state.cur_manager_id);
            task_json['action_data'].approval_id = parseInt(this.state.cur_manager_id);
            task_json['action_data'].assigned_to = parseInt(this.state.cur_manager_id);
        }
        if ("editcomp" in this.props && this.props.editcomp === true) {
            if (this.state.admin_confirmation_text !== "") {
                task_json['object_data'].admin_confirmation_text = this.state.admin_confirmation_text;
                task_json['action_data'].confirmed_by = parseInt(Store.getStoreData('loggedin_user_info').ID);
            }
        }
        // console.log("task_json:", task_json);
        let postData = {command: "update_filecheck_task", task_json: task_json, task_id: this.state.task_id, mail_url: window.location.href, mail_config: send_mail_config_data, send_mail: send_mail, mode: mode, send_autofail_email_data: send_autofail_email_data };
        if ("editcomp" in this.props && this.props.editcomp === true) {
            postData.editcomp = true;
        }
        console.log("postData", postData);
        const api = new TAPICall();
        api.command(postData, this.afterCompleted);
    }

    changeApprover = (event) => {
        event.preventDefault();
        this.setState({cur_manager_id: parseInt(event.target.value)});
    }

    afterCompleted = (result) => {
        // console.log("Completed:", result);
        if(result.mode === "approval"){
            alert('Filecheck task Completed and assigned to Manager for approval.');
        }else {
            alert('The file check has been completed.');
        }

        if (document.referrer.includes("/register") || document.referrer.includes("/tmview") || document.referrer === "http://localhost:3000/" ) {
            window.location.assign(document.referrer);
            //this.props.closeModal();
        } else {
            window.location.assign("/");
        }
    }

    calculateFinalResult = (result_obj, review_json) => {
        let is_autofailed = false;
        let totalscore = 0;
        let obtained_score = 0;
        review_json.sections.forEach((section) => {
            section.questions.forEach((question) => {
                if (question.included === true) {
                    // const qweight = question.weight.find(w => w.selected === true);
                    // let qhweight = parseInt(qweight.value);

                    const q_type = question.type.find(t => t.selected === true);
                    let qscore = 0;
                    if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
                        if (question.auto_scoring.enabled === true) {
                            if (q_type.value === "Boolean") {
                                qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
                                console.log("qscore Boolean:", qscore);
                            }
                            if (q_type.value === "Dropdown") {
                                qscore = Math.max.apply(null, question.preset_answers.map(pa => parseInt(pa.weight)));
                                console.log("qscore Dropdown:", qscore);
                            }
                        } else {
                            const qscore_obj = question.weight.find(item => item.selected === true);
                            qscore = parseInt(qscore_obj.value);
                        }
                    } else {
                        const qscore_obj = question.weight.find(item => item.selected === true);
                        qscore = parseInt(qscore_obj.value);
                    }
                    // if (question.auto_scoring.enabled === true) qhweight = parseInt(question.auto_scoring.yes);
                    totalscore = totalscore + qscore;
                    const autofail_enabled = question.autofail.find(af => af.selected === true);
                    obtained_score = obtained_score + parseInt(question.obtained_score);
                    if (autofail_enabled.value === "Yes") {
                        if (parseInt(question.obtained_score) === 0) {
                            is_autofailed = true;
                        }
                        // if (parseInt(question.obtained_score) < qscore) {
                        //     is_autofailed = true;
                        // }
                    }
                }
            })
        })
        result_obj.scoresobtained = (obtained_score / totalscore) * 100;
        result_obj.totalscore = totalscore;
        if (is_autofailed === true) {
            const fail_obj = review_json.scoring.scores.find(sc => sc.actual_outcome === "Fail");
            result_obj.ragratingcolor = fail_obj.color;
            result_obj.ragratingname = fail_obj.termname;
            result_obj.outcome = fail_obj.actual_outcome;
        } else {
            review_json.scoring.scores.forEach((single_score) => {
                if (result_obj.scoresobtained > single_score.lbound && result_obj.scoresobtained <= single_score.ubound) {
                    result_obj.ragratingcolor = single_score.color;
                    result_obj.ragratingname = single_score.termname;
                    result_obj.outcome = single_score.actual_outcome;
                }
            })
        }
        return {score: result_obj, is_autofailed: is_autofailed};
    }

    hexToRgb = (hex) => {
        hex = hex.slice(1);
        let bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return r + "," + g + "," + b;
    }

    reloadChildTasks = () => {
        const task_id = Store.getStoreData('fc_index');
        // console.log("current task id:", task_id);
        const api = new TAPICall();
        const postData = {command: "get_task", task_id: task_id};
        api.command(postData, this.listChildTasks);
    }

    modifySummary = (text) => {
        let {review_json, section_payload} = this.state;
        section_payload = text;
        review_json.summary = text;
        this.setState({review_json, section_payload});
    }

    togglePills = (view) => (event) => {
        //alert("view:", view);
        event.preventDefault();
        if(Store.getStoreData('role') === 'file_checker' && parseInt(this.state.taks_created_by) != parseInt(this.state.loggedin_user_info.ID)) {
            alert("You are unable to edit this assignment");
            return false;
        }
        // this.saveFileCheck();
        // if(view === "assign") {
        //     let task_json = this.state.task_json;
        //     let task = this.state.task.result.task;
        //     let cur_lane = task.parenttask.cur_lane;
        //     console.log("cur_lane", cur_lane);
        //     task_json['task_data'].last_action = cur_lane === "COMP_FCK_AWAITING_APPROVAL" ? 'COMP_FCK_AWAITING_APPROVAL' : 'COMP_FCK_SAVED';
        //     task_json['task_data'].cur_lane = cur_lane === "COMP_FCK_AWAITING_APPROVAL" ? 'COMP_FCK_AWAITING_APPROVAL_SAVE' : 'COMP_FCK_SAVED';
        //     task_json['object_data'].headerinfo.review_json = this.state.review_json;
        //     task_json['action_data'] = {action: "COMP_FCK_SAVED", actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_SAVED", assigned_to: task_json['task_data'].cur_assigned_to };
        //     if(cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
        //         task_json['action_data'].action = 'COMP_FCK_AWAITING_APPROVAL_SAVE';
        //         task_json['action_data'].cur_lane = 'COMP_FCK_AWAITING_APPROVAL';
        //     }

        //     let postData = {command: "update_filecheck_task", task_json: task_json, task_id: this.state.task_id };
        //     console.log("postData", postData);
        //     const api = new APICall();
        //     api.command(postData, this.afterAutoSave);
        // }
        this.props.changeCurView(view === "assign" ? "assignview" : "answerview");

        // this.setState({cur_view: view});
    }

    afterAutoSave = (result) => {
        // console.log("result", result);
    }

    changeCurView = (view) => {
        // event.preventDefault();
        this.props.changeCurView('answerview');
    }

    handleNonScoreAssessment = (non_score_assessment) => {
        const ret = this.state.task_json.object_data.headerinfo.review_json.scoring.non_scores.find(item => item.label === non_score_assessment.result);
        if (ret !== undefined) {
            non_score_assessment.color = ret.color;
        } else {
            delete non_score_assessment.color;
        }
        this.setState({non_score_assessment: non_score_assessment});
    }

    addSubTask = (task_data) => {
        // console.log("task_data:", task_data);
        let module_config = Store.getStoreData('module_config');
        let send_mail = this.state.send_mail_config;
        // console.log("send mail", send_mail);
        let mail_config = {};
        let send_mail_config_data = {};
        if(send_mail) {
            if(Object.keys(module_config).length > 0) {
                mail_config = module_config.email.find((obj) => {
                    return obj.id === "YRfW"
                });
            }
            send_mail = mail_config.enabled;
            if(send_mail){
                send_mail_config_data = this.getMailStrucData(mail_config);
            }
            // console.log(mail_config);
        }

        let ref_id = Utils.genKey(12);
        let task = {
            "task_data": { "module_id": "filechecker", "last_action": "COMP_FCK_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : task_data.assigned_to, 'task_type' : 'COMP_FCK_FOLLOWUP', 'parent_task_id' : this.state.task_id, 'cur_lane': 'COMP_FCK_FOLLOWUP_ASSIGNMENT', due_date: task_data.due_by, 'parent_company_id': this.state.payload.company_id},
            "object_data": {subject: this.state.followup_sub, note: this.state.followup_note},
            "action_data": [{ "action": "COMP_FCK_FOLLOWUP", "actiondate": Date.now(), "assigned_to": task_data.assigned_to, "cur_lane":"COMP_FCK_FOLLOWUP_ASSIGNMENT", due_by: task_data.due_by, "note": task_data.followup_note }]
        };
        let postData = { "command": "createfollowuptask", "task": task, "mail_url": window.location.href, "mail_config": send_mail_config_data, "send_mail": send_mail };
        // console.log("postData", postData);
        let api = new TAPICall();
        api.command(postData, this.processAddTask);
    }

    processAddTask = (result) => {
        const api = new TAPICall();
        const postData = {command: "get_task", task_id: this.state.task_id};
        api.command(postData, this.listChildTasks);
    }

    listChildTasks = (result) => {
        console.log("task result:", result);
        let payload = this.state.payload;
        payload.childtask = result.result.task.childtask;
        // payload.childtask.forEach((task) => {
        //     const user = payload.contacts.find(contact => task.cur_assigned_to === contact.ID);
        //     task.assigned_to_name = user.ContactName;
        // });
        this.setState({payload});
    }

    showPage = (page) => (event) => {
        event.preventDefault();
        this.props.showPage(page);
    }

    addFutureFollowup = (obj) => {
        console.log("obj:", obj);
        let task_json = JSON.parse(JSON.stringify(this.state.task_json));
        if (task_json.object_data.future_followups.length === 0) {
            task_json.object_data.future_followups.push(obj);
        } else {
            const exist = task_json.object_data.future_followups.find(ft => ft.id === obj.id);
            if (exist === undefined) {
                task_json.object_data.future_followups.push(obj);
            } else {
                task_json.object_data.future_followups.forEach(ft => {
                    if (ft.id === obj.id) ft = obj;
                })
            }
        }
        this.setState({task_json});
    }

    removeFutureFollowup = (task_id) => {
        let task_json = JSON.parse(JSON.stringify(this.state.task_json));
        let mod_tasks = [];
        for (let task of task_json.object_data.future_followups) {
            if (task.id !== task_id) mod_tasks.push(task);
        }
        task_json.object_data.future_followups = mod_tasks;
        this.setState({task_json});
    }
    modifyAdminConfirmationText = (text) => {
        //console.log("modifyAdminConfirmationText::",text)
        this.setState({admin_confirmation_text: text});
    }
    render()
    {
        console.log('Answerview_modal');
        let readonly = false;
        if ("readonly" in this.props) {
            readonly = this.props.readonly;
        }
        let editcomp = false;
        if ("editcomp" in this.props) {
            editcomp = this.props.editcomp;
        }
        if (this.state.review_json === null) {
            return (
                <div>
                    <CSLLoader style={{position: 'absolute'}} />
                    {/* Loading... */}
                </div>
            );
        }

        return (
            <ModalView_contain>

            <ModalViewBack></ModalViewBack>
             <ModalView>
                <CSLLoader style={{position: 'absolute'}} />
                <div style={{padding: "10px 10px 20px 10px"}}>
                    <div style={{float: "left"}}>
                        {
                            (() => {
                                if (readonly === true  || editcomp === true) {
                                    return (
                                        <PillBox>
                                            <PillIcon style={{opacity: "0.2"}}><IoIosLock/></PillIcon><PillText style={{opacity: "0.2"}}>Assign</PillText>
                                        </PillBox>
                                    );
                                } else {
                                    if (this.state.cur_view === "assign") {
                                        return (
                                            <PillBox style={{backgroundColor: "#0f2b4b", border: "1px solid #0f2b4b"}}>
                                                <PillText style={{paddingLeft: "0px", color: "#ffffff"}}>Assign</PillText>
                                            </PillBox>
                                        );
                                    } else {
                                        return (
                                            <PillBox style={{cursor: "pointer", backgroundColor: "#a3c7f1"}} onClick={this.togglePills("assign")}>
                                                <PillIcon style={{opacity: "0.2"}}></PillIcon><PillText style={{paddingLeft: "0px", color: "#282828"}}>Assign</PillText>
                                            </PillBox>
                                        );
                                    }
                                }
                            })()
                        }
                        {
                            (() => {
                                if (readonly === true) {
                                    return (
                                        <PillBox>
                                            <PillIcon style={{opacity: "0.2"}}><IoIosLock/></PillIcon><PillText style={{opacity: "0.2"}}>Complete</PillText>
                                        </PillBox>
                                    );
                                } else {
                                    if (this.state.cur_view === "complete") {
                                        return (
                                            <PillBox style={{backgroundColor: "#0f2b4b", border: "1px solid #0f2b4b"}}>
                                                <PillText style={{paddingLeft: "0px", color: "#ffffff"}}>Complete</PillText>
                                            </PillBox>
                                        );
                                    } else {
                                        return (
                                            <PillBox style={{cursor: "pointer"}} onClick={this.togglePills("complete")}>
                                                <PillIcon style={{opacity: "0.2"}}><IoIosLock/></PillIcon><PillText style={{opacity: "0.2"}}>Complete</PillText>
                                            </PillBox>
                                        );
                                    }
                                }
                            })()
                        }
                        {
                            (() => {
                                if (readonly === true) {
                                    return (
                                        <PillBox style={{backgroundColor: "#0f2b4b", border: "1px solid #0f2b4b"}}>
                                            <PillText style={{paddingLeft: "0px", color: "#ffffff"}}>Review</PillText>
                                        </PillBox>
                                    );
                                } else {
                                    return (
                                        <PillBox>
                                            <PillIcon style={{opacity: "0.2"}}><IoIosLock/></PillIcon><PillText style={{opacity: "0.2"}}>Review</PillText>
                                        </PillBox>
                                    );
                                }
                            })()
                        }
                        {
                            (() => {
                                if (readonly === true && Store.getStoreData("loggedin_user_info").role !== "file_checker") {
                                    return (
                                        <PillBox style={{cursor: "pointer", backgroundColor: "#a3c7f1"}} onClick={this.showPage("qcview")}>
                                            <PillIcon style={{opacity: "0.2"}}></PillIcon><PillText style={{paddingLeft: "0px", color: "#282828"}}>Quality Assurance</PillText>
                                        </PillBox>
                                    );
                                } else {
                                    return (
                                        <PillBox>
                                            <PillIcon style={{opacity: "0.2"}}><IoIosLock/></PillIcon><PillText style={{opacity: "0.2"}}>Quality Assurance</PillText>
                                        </PillBox>
                                    );
                                }
                            })()
                        }
                    </div>
                    <CloseBtn onClick={this.closeView}><FaTimes /></CloseBtn>
                    <div style={{clear: "both"}}></div>
                    {
                        (() => {
                            if (this.state.cur_view === "complete") {
                                return (
                                    <div>
                                        <ReviewHeaderText>{this.state.review_json.general.name.replace(/'+/g,"'")}</ReviewHeaderText>
                                        <ReviewBodyContainer>
                                            <ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                                                <ReviewTab onClick={this.returnSection("overview")}>
                                                    {
                                                        (() => {
                                                            if (this.state.active_section_id === "overview") {
                                                                return (<div style={{float: "left", fontWeight: "600"}}>Overview</div>);
                                                            } else {
                                                                return (<div style={{float: "left"}}>Overview</div>);
                                                            }
                                                        })()
                                                    }
                                                    <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                                    <div style={{clear: "both"}}></div>
                                                </ReviewTab>
                                                {
                                                    this.state.review_json.sections.map((section, index) => {
                                                        return (
                                                            <ReviewTab key={index} style={{marginTop: "2px"}} onClick={this.returnSection(section.id)}>
                                                                {
                                                                    (() => {
                                                                        let na = "";
                                                                        if("sectionna" in section && section.sectionna){
                                                                            na = "(N/A)";
                                                                        }
                                                                        if (this.state.active_section_id === section.id) {
                                                                            return (<div style={{float: "left", fontWeight: "600"}}>{section.name.replace(/'+/g,"'")} {na}</div>);
                                                                        } else {
                                                                            return (<div style={{float: "left"}}>{section.name.replace(/'+/g,"'")} {na}</div>);
                                                                        }
                                                                    })()
                                                                }
                                                                <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                                                <div style={{clear: "both"}}></div>
                                                            </ReviewTab>
                                                        )
                                                    })
                                                }
                                                {
                                                    (() => {
                                                        if (this.state.review_json.scoring.scoring_enabled) {
                                                            return (
                                                                <ReviewTab onClick={this.returnSection("summary")} style={{marginTop: "2px"}}>
                                                                    {
                                                                        (() => {
                                                                            if (this.state.active_section_id === "summary") {
                                                                                return (<div style={{float: "left", fontWeight: "600"}}>Summary</div>);
                                                                            } else {
                                                                                return (<div style={{float: "left"}}>Summary</div>);
                                                                            }
                                                                        })()
                                                                    }
                                                                    <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                                                    <div style={{clear: "both"}}></div>
                                                                </ReviewTab>
                                                            );
                                                        } else {
                                                            return (
                                                                <ReviewTab onClick={this.returnSection("assess")} style={{marginTop: "2px"}}>
                                                                    {
                                                                        (() => {
                                                                            if (this.state.active_section_id === "assess") {
                                                                                return (<div style={{float: "left", fontWeight: "600"}}>Assess</div>);
                                                                            } else {
                                                                                return (<div style={{float: "left"}}>Assess</div>);
                                                                            }
                                                                        })()
                                                                    }
                                                                    <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                                                    <div style={{clear: "both"}}></div>
                                                                </ReviewTab>
                                                            );
                                                        }
                                                    })()
                                                }
                                                <AnswerHr />
                                                <QStatus>
                                                    <div style={{float: "left", width: "80%"}}>Questions Answered</div>
                                                    <div style={{float: "left", width: "20%", textAlign: "right"}}>
                                                        {
                                                            (() => {
                                                                let total_questions = 0;
                                                                let answered_questions = 0;
                                                                this.state.review_json.sections.forEach((section) => {
                                                                    // total_questions = total_questions + section.questions.length;
                                                                    section.questions.forEach((question) => {
                                                                        if (question.answer.text !== "") {
                                                                            answered_questions = answered_questions + 1;
                                                                        }
                                                                        if (question.included) {
                                                                            total_questions = total_questions + 1;
                                                                        }
                                                                    })
                                                                })
                                                                return answered_questions+"/"+total_questions;
                                                            })()
                                                        }
                                                    </div>
                                                    <div style={{clear: "both"}}></div>
                                                </QStatus>
                                                {
                                                    (() => {
                                                        if (this.state.non_score_result.length !== 0) {
                                                            return (<NonScoreResult data={this.state.non_score_result} />);
                                                        }
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if (this.state.review_json.scoring.scoring_enabled && this.state.score_progression_enabled) {
                                                            return (
                                                                <div style={{width: "100%", marginTop: "20px"}}>
                                                                    {
                                                                        (() => {
                                                                            let scores = this.state.review_json.scoring.scores;
                                                                            let proghtml = [];
                                                                            for (let i=0; i<scores.length; i++) {
                                                                                let rgb = this.hexToRgb(scores[i].color);
                                                                                rgb = "rgb("+rgb.toString()+",0.3)";
                                                                                // console.log("RGB:", rgb);
                                                                                if (i === 0) {
                                                                                    if (this.state.score_result.scoresobtained <= scores[i].lbound) {
                                                                                        const fadedwidth = scores[i].ubound - scores[i].lbound;
                                                                                        proghtml.push(<div key={`first-${i}`} style={{height: "10px", width: fadedwidth.toString()+"%", backgroundColor: rgb, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", float: "left"}}></div>)
                                                                                    } else if (this.state.score_result.scoresobtained >= scores[i].ubound) {
                                                                                        const solidwidth = scores[i].ubound - scores[i].lbound;
                                                                                        proghtml.push(<div key={`first-${i}`} style={{height: "10px", width: solidwidth.toString()+"%", backgroundColor: scores[i].color, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", float: "left"}}></div>)
                                                                                    } else {
                                                                                        const solidwidth = this.state.score_result.scoresobtained - scores[i].lbound;
                                                                                        const fadedwidth = scores[i].ubound - this.state.score_result.scoresobtained;
                                                                                        proghtml.push(<div key={`first-${i}`} style={{height: "10px", width: solidwidth.toString()+"%", backgroundColor: scores[i].color, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", float: "left"}}></div>)
                                                                                        proghtml.push(<div key={`first-${i}`} style={{height: "10px", width: fadedwidth.toString()+"%", backgroundColor: rgb, float: "left"}}></div>)
                                                                                    }
                                                                                } else if (i === scores.length - 1){
                                                                                    if (this.state.score_result.scoresobtained > scores[i].lbound) {
                                                                                        if (this.state.score_result.scoresobtained === scores[i].ubound) {
                                                                                            const solidwidth = scores[i].ubound - scores[i].lbound;
                                                                                            proghtml.push(<div key={`last-${i}`} style={{height: "10px", width: solidwidth.toString()+"%", backgroundColor: this.state.score_result.ragratingcolor, borderTopRightRadius: "10px", borderBottomRightRadius: "10px", float: "left"}}></div>)
                                                                                        } else {
                                                                                            const solidwidth = this.state.score_result.scoresobtained - scores[i].lbound;
                                                                                            const fadedwidth = scores[i].ubound - this.state.score_result.scoresobtained;
                                                                                            proghtml.push(<div key={`last-${i}`} style={{height: "10px", width: solidwidth.toString()+"%", backgroundColor: this.state.score_result.ragratingcolor, float: "left"}}></div>)
                                                                                            proghtml.push(<div key={`last-${i}`} style={{height: "10px", width: fadedwidth.toString()+"%", backgroundColor: rgb, borderTopRightRadius: "10px", borderBottomRightRadius: "10px", float: "left"}}></div>)
                                                                                        }
                                                                                    } else {
                                                                                        const fadedwidth = scores[i].ubound - scores[i].lbound;
                                                                                        proghtml.push(<div key={`last-${i}`} style={{height: "10px", width: fadedwidth.toString()+"%", backgroundColor: rgb, borderTopRightRadius: "10px", borderBottomRightRadius: "10px", float: "left"}}></div>)
                                                                                    }
                                                                                } else {
                                                                                    if (this.state.score_result.scoresobtained < scores[i].lbound) {
                                                                                        const fadedwidth = scores[i].ubound - scores[i].lbound;
                                                                                        proghtml.push(<div key={`middle-${i}`} style={{height: "10px", width: fadedwidth.toString()+"%", backgroundColor: rgb, float: "left"}}></div>)
                                                                                    } else if (this.state.score_result.scoresobtained >= scores[i].ubound) {
                                                                                        const solidwidth = scores[i].ubound - scores[i].lbound;
                                                                                        proghtml.push(<div key={`middle-${i}`} style={{height: "10px", width: solidwidth.toString()+"%", backgroundColor: scores[i].color, float: "left"}}></div>)
                                                                                    } else {
                                                                                        const solidwidth = this.state.score_result.scoresobtained - scores[i].lbound;
                                                                                        const fadedwidth = scores[i].ubound - this.state.score_result.scoresobtained;
                                                                                        proghtml.push(<div key={`middle-${i}`} style={{height: "10px", width: solidwidth.toString()+"%", backgroundColor: scores[i].color, float: "left"}}></div>)
                                                                                        proghtml.push(<div key={`middle-${i}`} style={{height: "10px", width: fadedwidth.toString()+"%", backgroundColor: rgb, float: "left"}}></div>)
                                                                                    }

                                                                                }
                                                                            }
                                                                            return proghtml;
                                                                        })()
                                                                    }
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>
                                                            );
                                                        }
                                                    })()
                                                }
                                                <div style={{marginTop: "15px"}}>
                                                    {
                                                        this.state.review_json.scoring.scores.map((score, index) => {
                                                            return (
                                                                (() => {
                                                                    if(this.state.review_json.scoring.scoring_enabled && this.state.score_progression_enabled) {
                                                                        return (<div key={index} style={{width: "100%", marginTop: "10px"}}>
                                                                                <div style={{width: "10%", float: "left"}}>
                                                                                    <div style={{width: "10px", backgroundColor: score.color, height: "10px", borderRadius: "50%"}}></div>
                                                                                </div>
                                                                                <div style={{width: "calc(90% - 15px)", float: "left", color: score.color, fontSize: "10px", fontWeight: "600"}}>{score.termname}&nbsp;({score.ubound}%)</div>
                                                                                <div style={{clear: "both"}}></div>
                                                                            </div>)
                                                                    }
                                                                })()
                                                            );
                                                        })
                                                    }
                                                </div>
                                                <AnswerHr />
                                                <SideBtnContainer>
                                                    <CancelBtn onClick={this.closeView} style={{width: "40%"}}>Cancel</CancelBtn>
                                                    {
                                                        (() => {
                                                            if (readonly === false) {
                                                                return <SaveBtn onClick={this.saveFileCheck} style={{width: "calc(60% - 15px)", float: "right"}}>Save</SaveBtn>
                                                            }
                                                        })()
                                                    }
                                                    <div style={{clear: "both"}}></div>
                                                </SideBtnContainer>
                                            </ReviewLeftContainer>
                                            <ReviewRightContainer>
                                                {
                                                    (() => {
                                                        if (this.state.active_section_id === "overview") {
                                                            return (<AnswerOverview task_json={this.state.task_json} review_json={this.state.review_json} payload={this.state.payload} task_id={this.state.task_id} reloadChildTasks={this.reloadChildTasks} addFutureFollowup={this.addFutureFollowup} removeFutureFollowup={this.removeFutureFollowup} readonly={readonly}></AnswerOverview>);
                                                        } else if (this.state.active_section_id === "summary") {
                                                            console.log("summary curlane:",this.state.cur_lane )
                                                            //return (<AnswerSummary section_payload={this.state.section_payload} modifySummary={this.modifySummary} readonly={readonly} />);

                                                            return (<AnswerSummary section_payload={this.state.section_payload} modifySummary={this.modifySummary} readonly={readonly} editcomp={editcomp} admin_confirmation_text={this.state.admin_confirmation_text} cur_lane={this.state.cur_lane} modifyAdminConfirmationText={this.modifyAdminConfirmationText} />);

                                                        } else if (this.state.active_section_id === "assess") {
                                                            return (
                                                                <Assess
                                                                    non_score_result={this.state.non_score_result}
                                                                    non_score_assessment={this.state.non_score_assessment}
                                                                    review_json={this.state.review_json}
                                                                    handleNonScoreAssessment={this.handleNonScoreAssessment}
                                                                    payload={this.state.payload}
                                                                    addSubTask={this.addSubTask}
                                                                    show_subtask_modal={false}
                                                                    task_id={this.state.task_id}
                                                                    reloadChildTasks={this.reloadChildTasks}
                                                                    task_json={this.state.task_json}
                                                                    readonly={readonly}
                                                                    addFutureFollowup={this.addFutureFollowup}
                                                                    removeFutureFollowup={this.removeFutureFollowup}
                                                                    editcomp={editcomp}
                                                                    admin_confirmation_text={this.state.admin_confirmation_text}
                                                                    cur_lane={this.state.cur_lane}
                                                                    modifyAdminConfirmationText={this.modifyAdminConfirmationText}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <AnswerSection
                                                                    sections={this.state.review_json.sections}
                                                                    section_payload={this.state.section_payload}
                                                                    score_result={this.state.score_result}
                                                                    scoring={this.state.review_json.scoring}
                                                                    active_section_id={this.state.active_section_id}
                                                                    modifySections={this.modifySections}
                                                                    handleNonScoringInfo={this.handleNonScoringInfo}
                                                                    readonly={readonly}
                                                                />
                                                            );
                                                        }
                                                    })()
                                                }
                                            </ReviewRightContainer>
                                            <div style={{clear: "both"}}></div>
                                        </ReviewBodyContainer>
                                        <ReviewFooter>
                                            <CancelBtn onClick={this.closeView}>Cancel</CancelBtn>
                                            {
                                                (() => {
                                                    if (readonly === false) {
                                                        return <SaveBtn onClick={this.saveFileCheck} style={{marginLeft: "10px"}}>Save</SaveBtn>
                                                    }
                                                })()
                                            }

                                            {
                                                (() => {
                                                    if (readonly === false) {
                                                        if(Store.getStoreData('role') === 'file_checker' && Store.getStoreData('module_config').general.approval_process_enabled && this.state.task_json.object_data.headerinfo.review_json.general.config.approval_process_enabled) {
                                                            return(<CompleteBtn onClick={this.shoApprovalsec} style={{marginLeft: "10px"}}>Send for Approval</CompleteBtn>)
                                                        }else{
                                                            return(<CompleteBtn onClick={this.completeFileCheck('complete')} style={{marginLeft: "10px"}}>Complete</CompleteBtn>)
                                                        }
                                                    }
                                                })()
                                            }
                                        </ReviewFooter>
                                            <div style={{clear:"both"}}></div>
                                            {
                                                (() => {
                                                    if(this.state.show_approval) {
                                                        return(
                                                            <ReviewRightContainer>
                                                                <AnswerHr />
                                                                <MRModalLabel>Select Manager for approval</MRModalLabel>
                                                                    <MRModalSelect name="cur_manager_id" value={this.state.cur_manager_id} onChange={this.changeApprover} >
                                                                        {

                                                                            (() => {
                                                                                let mgr_list = [];
                                                                                this.state.manager_list.forEach((mgr) => {
                                                                                   mgr_list.push(<option key={mgr.id} value={mgr.id}>{mgr.name}</option>)
                                                                                })
                                                                                return mgr_list;
                                                                            })()
                                                                        }
                                                                    </MRModalSelect>
                                                                <ReviewFooter>
                                                                    <CancelBtn onClick={this.shoApprovalsec}>Cancel</CancelBtn>
                                                                    {
                                                                        (() => {
                                                                            if (readonly === false) {
                                                                                return <SaveBtn onClick={this.completeFileCheck('approval')} style={{marginLeft: "10px"}}>Submit</SaveBtn>
                                                                            }
                                                                        })()
                                                                    }
                                                                </ReviewFooter>
                                                                <div style={{clear:"both"}}></div>
                                                        </ReviewRightContainer>
                                                        )
                                                    }
                                                })()
                                            }
                                            <div style={{clear:"both"}} ref={el => { this.el = el; }}></div>
                                    </div>
                                );
                            }
                        })()
                    }
                    {
                        (() => {
                            if (this.state.cur_view === "assign") {
                                return (
                                    <AssignFileCheck task={this.state.task} changeCurView={this.changeCurView} parentview="fc_index"/>
                                );
                            }
                        })()
                    }
                </div>
            </ModalView>
            </ModalView_contain>
        );
    }
}

export default AnswerViewHOC;
