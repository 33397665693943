import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaTrashAlt} from 'react-icons/fa';
import Store from '../../Common/Store';
import FCKQuestion from '../FCKQuestion';
import { Container, Draggable } from 'react-smooth-dnd';
import {IoIosArrowForward} from 'react-icons/io';
import MRGeneral from './MRTabs/MRGeneral';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 90%;
    left: 5%;
    top: 30px;
`;

const DeaggableItem = styled.div`
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0,0,0,.125);
  margin-bottom: 2px;
  margin-top: 2px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddResultConfigButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    padding-left: 20px;
    font-weight: 600;
`;
const MultisetLayoutContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    margin-bottom: 5px;
`;
const TermDiv = styled.div`
    display: inline-block;
    box-sizing: border-box;
    width: 25%;
    height: auto;
    float: left;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalBackBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    margin-right: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const MRModalTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const Tabdiv1 = styled.div`
    width: 20%;
    float: left;
    
`;
const Tabdiv2 = styled.div`
    width: 80%;
    float: left;
    background-color: white;
    padding: 22px 16px;
    margin-bottom: 5px;
`;
const Tabdiv = styled.div`
    width: 100%;
    
    
`;

const Tabbutton = styled.div`
    display: block;
    
    color: #303030;
    padding: 16px 23px;
    width: 82%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 17px;
    margin: 5px;
    background-color: white
`;

const Tabbuttonactive = styled.div`
    display: block;
    
    color: #303030;
    padding: 16px 23px;
    width: 82%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 17px;
    margin: 5px;
    background-color: white;
    font-weight: bold;
`;

class AddMRModal extends React.Component
{
    state = {
        name: '',
        use_model: 0,
        show_scores: false,
        is_active: true,
        description: '',
        resultconfigs: [],
        openconfigs: [],
        questions: [
            {
                id: 1,
                type: [
                    {value: "Memo", selected: true},
                    {value: "Boolean", selected: false},
                    {value: "Alpha", selected: false},
                    {value: "Numeric", selected: false}
                ],
                question: "",
                weight: [
                    {value: "0", selected: true},
                    {value: "1", selected: false},
                    {value: "2", selected: false},
                    {value: "3", selected: false},
                    {value: "4", selected: false},
                    {value: "5", selected: false},
                    {value: "6", selected: false},
                    {value: "7", selected: false},
                    {value: "8", selected: false},
                    {value: "9", selected: false},
                    {value: "10", selected: false}
                ],
                autofail: [
                    {value: "Yes", selected: false},
                    {value: "No", selected: true}
                ],
                naallowed: [{value: "Yes", selected: false}, {value: "No", selected: true}]
            },
            {
                id: 2,
                type: [
                    {value: "Memo", selected: true},
                    {value: "Boolean", selected: false},
                    {value: "Alpha", selected: false},
                    {value: "Numeric", selected: false}
                ],
                question: "",
                weight: [
                    {value: "0", selected: true},
                    {value: "1", selected: false},
                    {value: "2", selected: false},
                    {value: "3", selected: false},
                    {value: "4", selected: false},
                    {value: "5", selected: false},
                    {value: "6", selected: false},
                    {value: "7", selected: false},
                    {value: "8", selected: false},
                    {value: "9", selected: false},
                    {value: "10", selected: false}
                ],
                autofail: [
                    {value: "Yes", selected: false},
                    {value: "No", selected: true}
                ],
                naallowed: [{value: "Yes", selected: false}, {value: "Yes", selected: true}]
            }
        ],
        secondstep: false,
        selectedTab: '1',
        navs: [{ name: "General", dispcomp: Tabbuttonactive }, { name: "Scoring", dispcomp: Tabbutton }, { name: "Question", dispcomp: Tabbutton }, { name: "Template", dispcomp: Tabbutton }, { name: "Preview", dispcomp: Tabbutton }]

    };

    constructor(props) {
        super(props);
        this.currentState = JSON.parse(JSON.stringify(this.state));
    }

    componentDidMount()
    {
        console.log("RID:", this.props.rid);
        if (this.props.rid !== 0) {
            const name = this.props.name;
            const show_scores = this.props.show_scores;
            const description = this.props.description;
            const resultconfigs = this.props.resultconfigs;
            const openconfigs = this.props.openconfigs;
            const questions = this.props.questions;
            const is_active = this.props.is_active;
            this.setState({name, show_scores, description, resultconfigs, openconfigs, questions, is_active});
        } else {
            this.setState({name: "", show_scores: false, description: "", resultconfigs: [], openconfigs: [], questions: [], is_active: true});
        }
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closemodal();
        // this.setState({resultconfigs: [], secondstep: false});
        // Store.updateStore('OverlayForCSLModal', null);
    }

    addNewResultConfiguration = (event) => {
        event.preventDefault();
        switch (this.state.show_scores) {
            case true:
                let {resultconfigs} = this.state;
                if (resultconfigs.length === 0) {
                    resultconfigs.push({
                        id: 1,
                        termname: "",
                        outcome_meanings: ["Pass", "Fail", "Indeterminate"],
                        actual_outcome: "Pass",
                        lbound: "0",
                        ubound: "100",
                        color: "#ffffff"
                    });
                } else {
                    let sum = 0;
                    const id = resultconfigs[resultconfigs.length - 1].id + 1;
                    resultconfigs.forEach((elm) => {
                        sum  = sum + (parseInt(elm.ubound) - parseInt(elm.lbound));
                    })
                    if (sum >= 100) {
                        alert("Range exceds");
                        return;
                    }
                    const lbound = resultconfigs[resultconfigs.length - 1].ubound;
                    resultconfigs.push({
                        id: id,
                        termname: "",
                        outcome_meanings: ["Pass", "Fail", "Indeterminate"],
                        actual_outcome: "Pass",
                        lbound: lbound,
                        ubound: "100",
                        color: "#ffffff"
                    });
                }
                console.log("result configs:", resultconfigs);
                this.setState({resultconfigs});
                break;
            case false:
                let {openconfigs} = this.state;
                if (openconfigs.length === 0) {
                    openconfigs.push({
                        id: 1,
                        termname: "",
                        outcome_meanings: ["Pass", "Fail", "Indeterminate"],
                        actual_outcome: "Pass",
                        color: "#ffffff"
                    });
                } else {
                    const id = openconfigs[openconfigs.length - 1].id + 1;
                    openconfigs.push({
                        id: id,
                        termname: "",
                        outcome_meanings: ["Pass", "Fail", "Indeterminate"],
                        actual_outcome: "Pass",
                        color: "#ffffff"
                    });
                }
                this.setState({openconfigs});
                break;
            default: break;
        }
    }

    removeResultConfig = (rowid) => (event) => {
        event.preventDefault();
        let ires = [];
        let {resultconfigs} = this.state;
        resultconfigs.forEach((item) => {
            if(item.id !== rowid) {
                ires.push(item);
            }
        })
        this.setState({resultconfigs: ires});
    }

    removeOpenConfig = (rowid) => (event) => {
        event.preventDefault();
        let ires = [];
        let {openconfigs} = this.state;
        openconfigs.forEach((item) => {
            if(item.id !== rowid) {
                ires.push(item);
            }
        })
        this.setState({openconfigs: ires});
    }

    handleConfigChange = (rowid) => (event) => {
        event.preventDefault();
        let {resultconfigs} = this.state;
        resultconfigs.forEach((item) => {
            if (item.id === rowid) {
                item[event.target.name] = event.target.value;
            }
        })
        this.setState({resultconfigs});
    }

    handleOpenConfigChange = (rowid) => (event) => {
        event.preventDefault();
        let {openconfigs} = this.state;
        openconfigs.forEach((item) => {
            if (item.id === rowid) {
                item[event.target.name] = event.target.value;
            }
        })
        this.setState({openconfigs});
    }

    goToNextStep = (event) => {
        event.preventDefault();
        const {resultconfigs} = this.state;
        let sum = 0;
        resultconfigs.forEach((elm) => {
            sum  = sum + (parseInt(elm.ubound) - parseInt(elm.lbound));
        })
        if (this.state.resultconfigs.length === 0 && this.state.openconfigs.length === 0) {
            alert("Please specify Result Configuration");
            return;
        }
        if (this.state.resultconfigs.length !== 0 && sum < 100) {
            alert("Total range is not covered");
            return;
        }
        if (this.state.resultconfigs.length !== 0 && sum > 100) {
            alert("Overlaping exists or range exceds. Please remove overlaping or limit range");
            return;
        }
        this.setState({secondstep: true});
    }

    handleQChange = (qid) => (event) => {
        event.preventDefault();
        let questions = JSON.parse(JSON.stringify(this.state.questions));
        questions.forEach((q) => {
            if (q.id === parseInt(qid)) {
                console.log(typeof q[event.target.name]);
                if (typeof q[event.target.name] === "string") {
                    q[event.target.name] = event.target.value;
                } else {
                    q[event.target.name].forEach((elm) => {
                        if (elm.value === event.target.value) {
                            elm.selected = true;
                        } else {
                            elm.selected = false;
                        }
                    })
                }
            }
        })
        this.setState({questions});
    }

    addNewQuestion = (event) => {
        event.preventDefault();
        let questions = JSON.parse(JSON.stringify(this.state.questions));
        let entry = {};
        if (questions.length === 0) {
            entry.id = 1;
        } else {
            entry.id = parseInt(questions[questions.length - 1].id) + 1;
        }
        entry.type = [
            {value: "Memo", selected: true},
            {value: "Boolean", selected: false},
            {value: "Alpha", selected: false},
            {value: "Numeric", selected: false}
        ];
        entry.weight = [
            {value: "#FF0000", name: "red", selected: true},
            {value: "#FFBF00", name: "amber", selected: true},
            {value: "#008000", name: "green", selected: true}
        ];
        if (this.state.show_scores) {
            entry.weight = [
                {value: "0", selected: true},
                {value: "1", selected: false},
                {value: "2", selected: false},
                {value: "3", selected: false},
                {value: "4", selected: false},
                {value: "5", selected: false},
                {value: "6", selected: false},
                {value: "7", selected: false},
                {value: "8", selected: false},
                {value: "9", selected: false},
                {value: "10", selected: false}
            ];
        }
        entry.autofail = [
            {value: "Yes", selected: false},
            {value: "No", selected: true}
        ];
        entry.naallowed = [{value: "Yes", selected: false}, {value: "No", selected: true}];
        entry.question = "";
        questions.push(entry);
        this.setState({questions});
    }

    handleMandatoryCheck = (qid) => (event) => {
        event.preventDefault();
        let questions = JSON.parse(JSON.stringify(this.state.questions));
        questions.forEach((q) => {
            if (q.id === parseInt(qid)) {
                q.mandatory = q.mandatory === "false" ? "true" : "false";
            }
        })
        this.setState({questions});
    }

    removeQuestion = (qid) => (event) => {
        event.preventDefault();
        console.log(qid);
        // console.log(this.state.questions);
        let questions = JSON.parse(JSON.stringify(this.state.questions));
        let ques = [];
        questions.forEach((q) => {
            if (q.id !== parseInt(qid)) {
                ques.push(q);
            }
        })
        this.setState({questions: ques});
    }

    addQuestionBtnClick = (event) => {
        event.preventDefault();
        let postData = {
            command: "save_model_review",
            review_id: this.props.rid,
            name: this.state.name,
            description: this.state.description,
            questions: this.state.questions,
            resultconfigs: this.state.resultconfigs,
            openconfigs: this.state.openconfigs,
            is_active: this.state.is_active
        };
        this.props.saveModelReview(postData);
    }

    saveForLater = (event) => {
        event.preventDefault();
        const rid = this.props.rid;
        console.log("RID in saveForLater:", rid);
        if (this.state.name === "") {
            alert("To save must provide a name");
            return;
        }
        let postData = {
            command: "save_model_review",
            mode: "save",
            review_id: this.props.rid,
            name: this.state.name,
            description: this.state.description,
            questions: this.state.questions,
            resultconfigs: this.state.resultconfigs,
            openconfigs: this.state.openconfigs
        };
        this.props.saveModelReview(postData);
        // this.addQuestionBtnClick(event);
    }

    applyDrag = (arr, dragResult) => {
      let { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
    //   console.log("dragResult", dragResult);
    //   removedIndex--;
    //   addedIndex--;

      let removedElement = JSON.parse(JSON.stringify(arr[removedIndex]));
      let addedElement = JSON.parse(JSON.stringify(arr[addedIndex]));
      let result = [...arr];

      for(let i=0; i < result.length; i++) {
        switch(i) {
            case removedIndex: result[i] = addedElement; break;
            case addedIndex: result[i] = removedElement; break;
        }
      }

      return result;
    }


    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({[evt.target.name]: evt.target.value});
    }

    handleModelChange = (evt) => {
        evt.preventDefault();

        console.log('selected model', evt.target.value);
        let review_id = evt.target.value;
        if(review_id === '0') {
            this.setState(this.currentState);
            return;
        }

        let url = Store.getStoreData('api_url');
        let is_admin = Store.getStoreData('is_admin');
        let is_debug = Store.getStoreData('is_debug');
        let postData = {command: "get_model_review", review_id: review_id };
        let httpVerb = { method: "POST", mode: "cors", headers: {"Content-Type": "application/x-www-form-urlencoded"}, body: "jsondata=" + JSON.stringify(postData) };
        fetch(url, httpVerb)
        .then((res) => res.json())
        .then(result => {
            let review_json = JSON.parse(result.result.review_json);
            Store.updateStore('source_model', result.result);
            console.log('review_json', review_json);
            this.setState({name: review_json.name, description: review_json.description, use_model: result.result.id, questions: review_json.questions, resultconfigs: review_json.resultconfigs});
        })
    }

    showResultScores = (event) => {
        // event.preventDefault();
        this.setState({show_scores: event.target.checked, resultconfigs: [], openconfigs: []});
    }

    goBack = (event) => {
        this.setState({secondstep: false});
    }

    isActiveChange = (event) => {
        let {is_active} = this.state;
        is_active = event.target.checked;
        this.setState({is_active});
    }

    selectedTabIndex = (index, navname) => (event) => {
        //console.log(index);
        let { navs } = this.state;
        navs.forEach((item) => {
            item.dispcomp = Tabbutton;
            if (item.name === navname) {
                item.dispcomp = Tabbuttonactive;
            }
        })
        this.setState({ selectedTab: index.toString(), navs })
        event.preventDefault();
    }

    render()
    {
        return (
            <MRModalContainer>
                {/* <div style={{position: "fixed", right: "25px", top: "133px"}}>
                    <MRModalNextBtn onClick={this.saveForLater}>Save</MRModalNextBtn>
                </div> */}
                <MRModalHeader>
                    <MRModalHeaderText>Add Model Review</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                {
                    (() => {
                        if (!this.state.secondstep) {
                            return (
                                <MRModalBody>
                                    <MRModalBodyInner>{
                                            (() => {
                                                if(!Store.getStoreData('is_admin')) {
                                                    let model_reviews = Store.getStoreData('model_reviews');
                                                    model_reviews.push({'id' : 0, 'nick_name' : 'Start from scratch...'});
                                                    model_reviews = model_reviews.reverse();
                                                    // console.log('model_reviews', model_reviews);
                                                    return(<div>
                                                        {
                                                            (() => {
                                                                if (Store.getStoreData('role') === "manager") {
                                                                    return (
                                                                        <div>
                                                                            <MRModalLabel>Start from scratch / Start from a Model Review</MRModalLabel>
                                                                            <MRModalSelect name="type" onChange={this.handleModelChange}>
                                                                            {
                                                                                model_reviews.map((opt, index) => {
                                                                                    return (<option key={index} value={opt.id}>{opt.nick_name}</option>);
                                                                                })
                                                                            }
                                                                            </MRModalSelect>
                                                                        </div>
                                                                    );
                                                                }
                                                            })()
                                                        }
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                        <Tabdiv>
                                                <Tabdiv1>
                                                    {
                                                        this.state.navs.map((item, index) => {
                                                            let ind = index + 1;
                                                            if (index === 0) {
                                                                return (<item.dispcomp key={index} onClick={this.selectedTabIndex(ind, item.name)} style={{marginTop: "0px"}}>{item.name} <IoIosArrowForward style={{float:'right', color: "#37ada7", marginTop: "4px"}}/></item.dispcomp>);
                                                            } else {
                                                                return (<item.dispcomp key={index} onClick={this.selectedTabIndex(ind, item.name)}>{item.name} <IoIosArrowForward style={{float:'right', color: "#37ada7", marginTop: "4px"}}/></item.dispcomp>);
                                                            }
                                                        })
                                                    }
                                                </Tabdiv1>
                                                {
                                                    (()=>{

                                                        switch(this.state.selectedTab) {
                                                            case '1' : return <MRGeneral rid={this.props.rid} name={this.props.name} is_active={this.props.is_active} show_scores={this.props.show_scores} description={this.props.description} resultconfigs={this.props.resultconfigs} openconfigs={this.props.openconfigs} questions={this.props.questions}></MRGeneral>;break
                                                            case '2' : return <Tabdiv2><div>Paul</div></Tabdiv2>; break
                                                            default : return <Tabdiv2><div>home</div></Tabdiv2>
                                                        }
                                                    })()
                                                }

                                                <div style={{clear: "both"}}></div>
                                            </Tabdiv>

                                    </MRModalBodyInner>
                                </MRModalBody>
                            );
                        } else {
                            return (
                                <MRModalBody>
                                    <MRModalBodyInner>
                                        <MRModalLabel>Add Questions</MRModalLabel>
                                        <Container onDrop={e => this.setState({ questions: this.applyDrag(this.state.questions, e) })}>
                                        {
                                            this.state.questions.map((elm, index) => {
                                                return (
                                                    <Draggable key={index}>
                                                      <DeaggableItem>
                                                        <FCKQuestion index={index} question={elm} showScores={this.state.show_scores} handleQChange={this.handleQChange} handleMandatoryCheck={this.handleMandatoryCheck} removeQuestion={this.removeQuestion} />
                                                      </DeaggableItem>
                                                    </Draggable>
                                                  );
                                            })
                                        }
                                        </Container>
                                        <AddResultConfigButton onClick={this.addNewQuestion}>Add New Question</AddResultConfigButton>
                                    </MRModalBodyInner>
                                </MRModalBody>
                            );
                        }
                    })()
                }
                <MRModalFooter>
                    {
                        (() => {
                            if (this.state.secondstep) {
                                if (parseInt(this.props.rid) === 0) {
                                    return (<MRModalNextBtn onClick={this.addQuestionBtnClick}>Add</MRModalNextBtn>);
                                } else {
                                    return (<MRModalNextBtn onClick={this.addQuestionBtnClick}>Modify</MRModalNextBtn>);
                                }
                            } else {
                                return (<MRModalNextBtn onClick={this.goToNextStep}>Next</MRModalNextBtn>);
                            }
                        })()
                    }
                    <MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                    {
                        (() => {
                            if (this.state.secondstep) {
                                return (<MRModalBackBtn onClick={this.goBack}>Back</MRModalBackBtn>);
                            }
                        })()
                    }
                </MRModalFooter>
            </MRModalContainer>
        );
    }
}

export default AddMRModal;
