import React from 'react';
import styled from 'styled-components';
import {FaPlusCircle, FaTimes} from 'react-icons/fa';
import CSLTable from '../Common/CSLTable';
import AddFTModal from './AddFTModal';

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const FTHeaderContainer = styled.div`
	padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;
const FTHeaderText = styled.div`
	float: left;
    color: rgb(255, 255, 255);
    font-weight: 600;
`;
const FTAdd = styled.div`
	float: right;
    color: rgb(255, 255, 255);
    font-size: 17px;
    cursor: pointer;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class FollowupTasks extends React.Component
{
	state = {payload: null, show_modal: false};

	componentDidMount()
	{
		this.setState({payload: this.props.payload, show_modal: this.props.show_subtask_modal});
	}

	componentDidUpdate(prevProps)
	{
		if (this.props !== prevProps) {
			this.setState({payload: this.props.payload, show_modal: this.props.show_subtask_modal});
		}
	}

	listFollowupTasks = () => {
		let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 90, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Assigned to', accessor: 'assigned_to', width: 400, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Status', accessor: 'status', minWidth: 180, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}, 
                     ];

        for(let d of this.state.payload.childtask) {
            i++;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            // console.log('day, month, year', dd,mm,yyyy)
            var due_date = new Date(yyyy, mm-1, dd);
            due_date.setDate(due_date.getDate() + 1);
            let today = new Date();

            let text = due_date > today ? 'Pending' : 'Overdue';
            let color = due_date > today ? '#2E8B57' : '#FF0000';

            let elem = {'index' : i,
                    'assigned_to' : d.assigned_to_name,
                    'status' : {text: text, color: color}
                   };
            ret.data.push(elem);
        }
        return ret;
	}

	showFollowupTaskModal = (event) => {
		event.preventDefault();
		console.log("showFollowupTaskModal fired");
		this.setState({show_modal: true});
	}
	closeFollowupTaskModal = () => {
		this.setState({show_modal: false});
	}

	render()
	{
		if (this.state.payload === null) {
			return (<div></div>);
		}

		return (
			<div>
				<FTHeaderContainer>
					<FTHeaderText>Tasks associated with this file check</FTHeaderText>
					<FTAdd onClick={this.showFollowupTaskModal}><FaPlusCircle /></FTAdd>
					<div style={{clear: "both"}}></div>
				</FTHeaderContainer>
				{
					(() => {
						if (this.state.payload.childtask.length === 0) {
							return (
								<OverviewInfoBody>
								{
									(() => {
										if (this.state.show_modal === false) {
											return <div style={{textAlign: "center",fontStyle: "italic", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>There are no tasks associated with this file check.</div>
										} else {
											return <AddFTModal payload={this.state.payload} closeFollowupTaskModal={this.closeFollowupTaskModal} addSubTask={this.props.addSubTask} />
										}
									})()
								}
								</OverviewInfoBody>
							);
						} else {
							return (
								<OverviewInfoBody>
								{
									(() => {
										if (this.state.show_modal === false) {
											return <CSLTable add={false} processData={this.listFollowupTasks} headerText={''} tableRows="10" />
										} else {
											return <AddFTModal payload={this.state.payload} closeFollowupTaskModal={this.closeFollowupTaskModal} addSubTask={this.props.addSubTask} />
										}
									})()
								}
								</OverviewInfoBody>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default FollowupTasks;