import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import RegisterReporting from './CSLRegister/RegisterReporting'
import reportData from './rtbcData.js';
import filters from './rtbcFilters.js';
import moment from 'moment-business-days'
import CSLLoader from '../Common/CSLLoader';

class ReviewsTobeCompleted extends React.Component {

	state = {ready: false,pill_filters: {}, view_count: 0, filters: filters, tasks: []}

	componentDidMount = () => {
		let api = new APICall()
    let payload = {command: "list_reporting_tasks"}
    api.command(payload, this.processListTasks)
	}

  processListTasks = (result) => {
    console.log('result rtbc', result)
    let tasks = result
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let prefered_columns = {
            'status': 'Status', 
            'file_type' : 'File Type', 
            'reviewer' : 'Reviewer', 
            'reviewee' : 'Reviewee', 
            'assigned_date' : 'Assigned Date',  
            'start_date' : 'Date Started', 
            'due_date' : 'Due Date'
          }
    if(has_gc) {
      prefered_columns['company_name'] = 'Company'
    }
    this.setState({prefered_columns: prefered_columns, tasks, ready: true})
  }

	refreshCallback = () => {
		this.setState({view_count: this.state.view_count + 1})
	}

  dateNumber = (date) => {
    if(date === '-')return 0
    let d = date.split('/')
    return (parseInt(d[2]) * 10000) + (parseInt(d[1]) * 100) + parseInt(d[0])
  }

  processTableData = () => {
    let ret = {columns: [], data: [], raw_data: [], row_filters: {}}
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let users = Store.getStoreData('users')
    let parent_company = Store.getStoreData('parent_company')
    let gc_companies = Store.getStoreData('gc_companies')
    let companies = {}
    for(let c of gc_companies) {
      companies[c.id] = c
    }
    console.log('parent_company, gc_companies, users', parent_company, gc_companies, users)
    if(has_gc)ret.columns.push({Header: 'Company', accessor: 'company_name', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'File Check Type', accessor: 'review_type', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Reviewer', accessor: 'reviewer', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Status', accessor: 'status', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Date assigned', accessor: 'assigned_date', minWidth: 170, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
      return (this.dateNumber(a) > this.dateNumber(b))
    }})
    ret.columns.push({Header: 'Date started', accessor: 'start_date', minWidth: 170, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
      return (this.dateNumber(a) > this.dateNumber(b))
    }})
    ret.columns.push({Header: 'Due Date', accessor: 'due_date', minWidth: 170, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
      return (this.dateNumber(a) > this.dateNumber(b))
    }})
    ret.columns.push({Header: 'Reviewee', accessor: 'reviewee', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Customer', accessor: 'customer', minWidth: 170, headerStyle: {textAlign: 'left'}})

    console.log('this.state.tasks', this.state.tasks)

    for(let t of this.state.tasks) {
      if(t.cur_lane === "COMP_FCK_ASSIGNMENT" || t.cur_lane === "COMP_FCK_CREATE" || t.cur_lane === "COMP_FCK_SAVED") {
//        t.obdata = JSON.parse(t.task_json)
        
        let start_date = '-'
        if (t.saved_date !== null) start_date = moment.unix(t.saved_date / 1000).format("DD/MM/YYYY");
//        for(let a of t.obdata.action_data) {
//          if(a.action === 'COMP_FCK_SAVED') {
//            start_date = moment.unix(a.actiondate / 1000).format("DD/MM/YYYY");
//            break
//          }
//        }
        console.log('start_date', start_date)
        let due_date = moment.unix(t.due_date).format('DD/MM/YYYY')
        let today = moment().format('DD/MM/YYYY')
        console.log('due_date, today', due_date, today)
        let status = 'In Progress'
        if(due_date === today) {
          status = 'Due today'
        } else if(this.dateNumber(due_date) < this.dateNumber(today)) {
          let diff = moment(due_date, 'DD/MM/YYYY').businessDiff(moment(today,'DD/MM/YYYY'))
          let ext = diff > 1 ? ' days' : ' day'
          status = 'Overdue ' + diff + ext
        }
        let elem = {
                      id: t.id,
                      review_type: t.review_name,
                      reviewer: t.reviewer_id.toString() in users ? users[t.reviewer_id].ContactName : "Inactive User",
                      reviewee: t.reviewee_id.toString() in users ? users[t.reviewee_id].ContactName : "Inactive User",
                      customer: t.customer,
                      status: status,
                      assigned_date: moment.unix(t.created_at).format('DD/MM/YYYY'),
                      due_date: due_date,
                      start_date: start_date
                   }
        if(has_gc) {
          elem.company_name = t.company_id < 100000 ? parent_company.company_name : companies[(t.company_id % 100000).toString()].company_name
          elem.company_id = t.company_id
        }
        ret.data.push(elem)
        ret.raw_data.push(elem)
        ret.row_filters[t.id] = {}
      }
    }

    return ret
  }

  processDate = () => {
    var d = new Date()
    let day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
    let monthIndex = d.getMonth() < 10 ? `0${d.getMonth().toString()}`: d.getMonth().toString();
    let year = d.getFullYear().toString();
    let date = day + '/' + (parseInt(monthIndex)+1) + '/' + year;
    return date;
  }

	render() {
		let button_set={}
    if(!this.state.ready)return <div><CSLLoader /></div>
    reportData.company_name = Store.getStoreData('parent_company').company_name
    let filters = this.state.filters 
    let has_gc = Store.getStoreData('parent_has_group_companies')
    if(!has_gc) {
      let i = 0
      for(i = 0; i < reportData.table_headers.length; i++) {
        if(reportData.table_headers.accessor === 'company_name')break
      }
      reportData.table_headers.splice(i, 1)
      for(i = 0; i < reportData.sortby.fields.length; i++) {
        if(reportData.sortby.fields.accessor === 'company_name')break
      }
      reportData.sortby.fields.splice(i, 1)
      for(i = 0; i < reportData.groupby.fields.length; i++) {
        if(reportData.groupby.fields.accessor === 'company_name')break
      }
      reportData.groupby.fields.splice(i, 1)
      for(i = 0; i < reportData.groupby.selections.length; i++) {
        if(reportData.groupby.selections.accessor === 'company_name')break
      }
      reportData.groupby.selections.splice(i, 1)
      delete filters.company_name
    }


		return (<div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                  <RegisterReporting
                                      headerText='Answer Analysis'
                                      moduleId='filechecker'
                                      data={reportData}
                                      processTableData={this.processTableData}
                                      preferedColumns={this.state.prefered_columns}
                                      buttonSet={button_set}
                                      filters={filters}
                                      pillFilters={this.state.pill_filters}
                                      refreshCallback={this.refreshCallback}
                                      viewCount={this.state.view_count}
                                      titlePDF={'File Check Report_'+ this.processDate()}
                                  />
                        </div>)
	}
}

export default ReviewsTobeCompleted;