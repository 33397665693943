import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import RegisterReporting from './CSLRegister/RegisterReporting'
import CSLPreviewTable from './CSLRegister/CSLPreviewTable'
import PdfReportGenerator from './CSLRegister/PdfReportGenerator'
import reportData from './raData.js';
import filters from './raFilters.js';
import moment from 'moment-business-days'
import CSLLoader from '../Common/CSLLoader';

const SubHeading = styled.div`
  padding: 10px 15px;
    margin: 0px;  
    font-weight: 600;
    font-size: '16px';
`

const TableHead = styled.div`
  float: left;
    background-color: #EDF0F1;
    padding: 10px 0px 10px 20px;
    font-weight: 600;
    font-size: smaller;
    box-sizing: border-box;
`;

const map_month = {
  '01' : 'January',
  '02' : 'February',
  '03' : 'March',
  '04' : 'April',
  '05' : 'May',
  '06' : 'June',
  '07' : 'July',
  '08' : 'August',
  '09' : 'September',
  '10' : 'October',
  '11' : 'November',
  '12' : 'December'
}

const map_role = {
  file_checker : 'File Checker',
  manager: 'Manager',
  senior_manager: 'Senior Manager',
  no_access: 'No Access',
  view_only_manager: 'View Only Manager',
  team: 'Team',
  admin_manager: 'Admin Manager',
  qa_manager: 'QA Manager',
  qa_reviewer: 'QA Reviewer'
}


class RevieweeAssignment extends React.Component {

	state = {ready: false,pill_filters: {}, view_count: 0, filters: filters, auditor_summary_data: {}, from_day: null, to_day: null}

	setComponentState = () => {
    let now = moment()
    let month = now.format('MM')
    let year = parseInt(now.format('YYYY'))
    let to_day = this.state.to_day
    let from_day = this.state.from_day
    if(from_day === null || to_day === null) {
      switch(month) {
        case '01':
        case '02':
        case '03': year -= 1; to_day = `${year}/12/31`; from_day = `${year}/10/01`; break;
        case '04':
        case '05':
        case '06': to_day = `${year}/03/31`; from_day = `${year}/01/01`; break;
        case '07':
        case '08':
        case '09': to_day = `${year}/06/30`; from_day = `${year}/04/01`; break;      
        case '10':
        case '11':
        case '12': to_day = `${year}/09/30`; from_day = `${year}/07/01`; break;
      }
    }

		let api = new APICall()
    let payload = {command: "get_reviewee_assignment", to_day: to_day, from_day: from_day}
    api.command(payload, this.processRevieweesAssigned)
	}

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
    if(this.props === prevprops)return
    this.setComponentState()
  }

  processRevieweesAssigned = (result) => {
    console.log('result processRevieweesAssigned', result)
    let auditor_summary_data = result.result.data
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let prefered_columns = {
            'reviewee' : 'Reviewee', 
            'role' : 'Role',  
            'last_reviewed' : 'Last Reviewed', 
            'reviewed' : 'Reviewed',
          }
    if(has_gc) {
      prefered_columns['gc'] = 'Group Companies'
    }
    this.setState({prefered_columns, auditor_summary_data, ready: true, from_day: result.result.from_day, to_day: result.result.to_day})
  }

  processListTasks = (result) => {
    console.log('result as', result)
    let tasks = result.result
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let prefered_columns = {
            'reviewee' : 'Reviewee', 
            'role' : 'Role',  
            'last_reviewed' : 'Last Reviewed', 
            'reviewed' : 'Reviewed',
          }
    if(has_gc) {
      prefered_columns['gc'] = 'Group Companies'
    }
    this.setState({prefered_columns: prefered_columns, tasks, ready: true})
  }

	refreshCallback = (filters) => {
    console.log('Applied filters', filters)
    let from_day = moment(filters.last_reviewed.selections.from_date, 'DD/MM/YYYY').format('YYYY/MM/DD')
    let to_day = moment(filters.last_reviewed.selections.to_date, 'DD/MM/YYYY').format('YYYY/MM/DD')
    if(from_day === this.state.from_day && to_day === this.state.to_day) {
      this.setState({view_count: this.state.view_count + 1})
      return
    }
    let api = new APICall()
    let payload = {command: "get_reviewee_assignment", to_day: to_day, from_day: from_day}
    api.command(payload, this.processRevieweesAssigned)
    this.setState({ready: false})
		// this.setState({view_count: this.state.view_count + 1, from_day, to_day})
	}

  dateNumber = (date) => {
    if(date === '-' || date === 'Never')return 0
    let d = date.split('/')
    return (parseInt(d[2]) * 10000) + (parseInt(d[1]) * 100) + parseInt(d[0])
  }


  getMonthYear = (date) => {
    let parts = date.split('/')
    return `${map_month[parts[1]]}, ${parts[2]}`
  }
  processTableData = () => {
    let ret = {columns: [], data: [], raw_data: [], row_filters: {}}
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let users = Store.getStoreData('users')
    let parent_company = Store.getStoreData('parent_company')
    let gc_companies = Store.getStoreData('gc_companies')
    let companies = {}
    for(let c of gc_companies) {
      companies[c.id] = c
    }
    // console.log('parent_company, gc_companies, users', parent_company, gc_companies, users)
    
    ret.columns.push({Header: 'Reviewee', accessor: 'reviewee', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Role', accessor: 'role', minWidth: 170, headerStyle: {textAlign: 'left'}})
    if(has_gc)ret.columns.push({Header: 'Group Companies', accessor: 'gc', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Last Reviewed', accessor: 'last_reviewed', minWidth: 170, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
      return (this.dateNumber(a) > this.dateNumber(b))
    }})
    ret.columns.push({Header: 'Reviewed', accessor: 'reviewed', minWidth: 170, headerStyle: {textAlign: 'left'}})

    console.log('users, gc_companies', users, gc_companies)
    let valid_roles = {file_checker: 1, manager: 1, senior_manager: 1}

    let asrows = this.state.auditor_summary_data
    for(let uid in asrows) {
      if(!(users[uid].role in map_role))continue
      let gc_count = 0
      let gc_name = ''
      for(let gc of gc_companies) {
        if(gc.is_gc && uid in gc.users){gc_count++; gc_name = gc.company_name}
      }
      let gcnum = gc_count === 1 ? gc_name : `${gc_count} companies`
      let last_reviewed = asrows[uid].last_reviewed === 0 ? 'Never' : moment.unix(asrows[uid].last_reviewed).format('DD/MM/YYYY')
      let lc_month = last_reviewed === 'Never' ? 'Never' : this.getMonthYear(last_reviewed)

      // console.log('asrows[uid].last_completed', asrows[uid].last_completed, moment.unix(asrows[uid].last_completed).format('DD/MM/YYYY'))
      let elem = {
        id: uid,
        role: map_role[users[uid].role],
        reviewee: users[uid].ContactName,
        gc: gcnum,
        last_reviewed: last_reviewed,
        reviewed: asrows[uid].reviewed.toString(),
        lc_month: lc_month
      }
      ret.data.push(elem)
      ret.raw_data.push(elem)
      ret.row_filters[uid] = {}
    }

    return ret
  }

  processDate = () => {
    var d = new Date()
    let day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
    let monthIndex = d.getMonth() < 10 ? `0${d.getMonth().toString()}`: d.getMonth().toString();
    let year = d.getFullYear().toString();
    let date = day + '/' + (parseInt(monthIndex)+1) + '/' + year;
    return date;
  }

	render() {
		let button_set={}
    if(!this.state.ready)return <div><CSLLoader /></div>
    reportData.company_name = Store.getStoreData('parent_company').company_name
    let filters = this.state.filters 
    let has_gc = Store.getStoreData('parent_has_group_companies')
    if(!has_gc) {
      let i = 0
      for(i = 0; i < reportData.table_headers.length; i++) {
        if(reportData.table_headers.accessor === 'gc')break
      }
      reportData.table_headers.splice(i, 1)
      for(i = 0; i < reportData.sortby.fields.length; i++) {
        if(reportData.sortby.fields.accessor === 'gc')break
      }
      reportData.sortby.fields.splice(i, 1)
      for(i = 0; i < reportData.groupby.fields.length; i++) {
        if(reportData.groupby.fields.accessor === 'gc')break
      }
      reportData.groupby.fields.splice(i, 1)
      for(i = 0; i < reportData.groupby.selections.length; i++) {
        if(reportData.groupby.selections.accessor === 'gc')break
      }
      reportData.groupby.selections.splice(i, 1)
      delete filters.gc
    }

    filters.last_reviewed.selections.from_date = moment(this.state.from_day, 'YYYY/MM/DD').format('DD/MM/YYYY')
    filters.last_reviewed.selections.to_date = moment(this.state.to_day, 'YYYY/MM/DD').format('DD/MM/YYYY')
    filters.role.selections['File Checker'] = {name: 'File Checker'}
    filters.role.selections['No Access'] = {name: 'No Access'}


		return (<div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                  <RegisterReporting
                                      headerText='Answer Analysis'
                                      moduleId='filechecker'
                                      data={reportData}
                                      processTableData={this.processTableData}
                                      preferedColumns={this.state.prefered_columns}
                                      buttonSet={button_set}
                                      filters={filters}
                                      pillFilters={this.state.pill_filters}
                                      refreshCallback={this.refreshCallback}
                                      viewCount={this.state.view_count}
                                      titlePDF={'File Check Report_'+ this.processDate()}
                                  />
                        </div>)
	}
}

export default RevieweeAssignment;