import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import EmailConfigModal from './EmailConfigModal';
import { IoIosCheckboxOutline, IoIosSquareOutline, IoIosWarning } from "react-icons/io";

const EmailContainer = styled.div`
	width: 70%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailLabel = styled.div`
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const MRModalSelect = styled.select`
	margin-top: 10px;
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 40%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;

class Email extends React.Component
{
	state = {email: null, variables: null, show_email_config_modal: null, content: null, general: true};

	componentDidMount()
	{
		this.setState({email: this.props.email, variables: this.props.variables, general: this.props.general});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({email: this.props.email, variables: this.props.variables, general: this.props.general});
		}
	}

	showEmailConfigModal = (email_id) => (event) => {
		event.preventDefault();
		console.log("email_id:", email_id);
		const content = this.state.email.find((item) => {
			return item.id === email_id;
		})
		this.setState({content, show_email_config_modal: true});
	}

	modifyEmails = (content) => {
		console.log("content:", content);
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === content.id) {
				item = content;
				console.log("matched", item);
			}
			newemail.push(item);
		})
		console.log("emails:", newemail);
		this.props.updateEmail(newemail);
		this.setState({email: newemail, show_email_config_modal: null});
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneralSave(general);
	}

	closeModal = () => {
		this.setState({show_email_config_modal: null, content: null});
	}

	handlenaSectionIncluded = (section_id) => (event) => {
		console.log("content:", section_id);
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === section_id) {
				item.enabled = item.enabled ? false : true; 
				console.log("matched", item);
			}
			newemail.push(item);
		})
		console.log("emails:", newemail);
		this.props.updateEmail(newemail);
	}

	tableData = () => {
		// console.log("Emails:", this.state.email);
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Email', accessor: 'email', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Subject', accessor: 'subject', minWidth: 240, headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Enabled', Cell: row => (
                           <div>
                              	{
                              		(()=> {
                              			if (row.original.enabled) {
                              				return(<div style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosCheckboxOutline /></div>);
                              			} else {
                              				return(<div  style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.handlenaSectionIncluded(row.original.id)}><IoIosSquareOutline /></div>);
                              			}
                              		})()
                              	}
                           </div>
                        ), headerStyle:{textAlign: 'center'}},
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <ConfigBtn onClick={this.showEmailConfigModal(row.original.id)}>Configure</ConfigBtn>
                           </div>
                        ), headerStyle:{textAlign: 'left'}}
                    ];
        this.state.email.forEach((item) => {
        	let elem = {id: item.id, email: item.name, subject: item.subject, enabled: item.enabled, active: item.enabled === true ? "Yes" : "No", email_text: item.body.replace(/\n/g, " ")};
        	ret.data.push(elem);
		})
		ret.export_columns = {subject: 'Subject', email_text: 'Email Text', active: 'Active'};
        return ret;
	}

	changeSelfNotification = (event) => {
		event.preventDefault();
		let general = JSON.parse(JSON.stringify(this.state.general));
		general.self_notification.selected_option = event.target.value;
		this.props.updateGeneralServer(general);
	}

	render()
	{
		if (this.state.email === null) {
			return (
				<div>Loading...</div>
			);
		}
		const task_assigned_email = this.state.email.find(item => item.id === "YEgo");
		return (
			<div>
			{
				(() => {
					if (this.state.show_email_config_modal !== null) {
						return (<EmailConfigModal variables={this.state.variables} content={JSON.parse(JSON.stringify(this.state.content))} modifyEmails={this.modifyEmails} closeModal={this.closeModal
						} />);
					}
				})()
			}

				<EmailContainer>
					<EmailInnerContainer>
						<EmailBoldLabel style={{display: "inline-block"}}>Enable Email Notification?</EmailBoldLabel>
						{
							(() => {
								if (this.state.general.email_remainder_enable) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						<GeneralLabel>Send notifications for File Checks that a user assigns to themself</GeneralLabel>
						<MRModalSelect style={{pointerEvents: this.state.general.email_remainder_enable === true && task_assigned_email.enabled === true ? "all" : "none"}} id="self_notification" value={this.state.general.self_notification.selected_option} onChange={this.changeSelfNotification}>
							{
								this.state.general.self_notification.options.map(item => {
									return (<option value={item} key={item}>{item}</option>);
								})
							}
						</MRModalSelect>
					</EmailInnerContainer>
				</EmailContainer>
				<div styled={{clear:"both"}}></div>
				{
					(() => {
						if (this.state.general.email_remainder_enable) {
							return(
									<EmailContainer>
										<EmailInnerContainer>
											<EmailBoldLabel style={{display: "inline-block"}}>Emails</EmailBoldLabel>
											<EmailLabel style={{display: "inline-block"}}>(inherited from Task Manager)</EmailLabel>
											<CSLTable export={true} export_file_name={"Emails"} add={false} processData={this.tableData} headerText={''} tableRows="6" refreshCallback={this.refreshState} />
										</EmailInnerContainer>
									</EmailContainer>
								)
							
						}
					})()
				}
			</div>
		);
	}
}

export default Email;