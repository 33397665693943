import React from "react";
import styled from "styled-components";
import Store from "../../Common/Store";
import APICall from "../../Common/APICall";
import CSLTable from "../Common/CSLTable";
import FCThumbnails from "../Partials/FCThumbnails";
import AssignFileCheckModal from "./AssignFileCheckModal";
import ReportModal from "./ReportModal";
import FollowupModal from "../Partials/FollowupModal";
import AnswerView from "../Common/AnswerView";
import QcView from "../Common/QcView";
import AssignFileCheck from "../Partials/AssignFileCheck";
import { IoIosArrowUp, IoIosArrowDown, IoIosTrash, IoMdCreate } from "react-icons/io";
import CSLLoader from "../Common/CSLLoader";
import CSLPageloader from "../Common/CSLPageloader";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import AlertBox from "../Common/AlertBox";
import BulkModal from '../Modals/BulkModal'
import BulkPreview from '../Modals/BulkPreview'
import EventBus from '../../Common/EventBus.js';
import Utils from "../../Common/Utils";

const SubmitButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #223b5a;
  color: #ffffff;
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #223b5a;
  padding-left: 10px;
  margin-left: 9px;
  margin-top: 10px;
  font-size: 11px;
  cursor: pointer;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 120px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    padding 5px;
`;
const StatusButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 15px;
`;
const DelButton = styled.div`
  font-size: 24px;
  cursor: pointer;
`;
const CardChevronContainer = styled.div`
  text-align: right;
  padding: 15px 10px 10px 0px;
  box-sizing: border-box;
`;
const ClosedChevron = styled.div`
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 13px 0px 13px;
  background-color: #ffffff;
`;
const DummyOverlay = styled.div`
  display: table;
  box-sizing: border-box;
  height: calc(100vh - 65px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 65px;
  z-index: 1002;
  opacity: 0.6;
`;

class MGRIndex extends React.Component {
  state = {
    ready: false,
    view_count: 0,
    modal_view: null,
    page_view: "manager_index",
    show_cards: true,
    task: null,
    quality_assurance_enabled: false,
    show_completed: false,
    show_completed_qa: false,
    show_completed_folloup: false,
    pending_tasks: null,
    qa_pending_tasks: null,
    qa_completed_tasks: null,
    pending_followup_tasks: null,
    completed_followup_tasks: null,
    completed_tasks: null,
    stats: null,
    alertParam: null,
    show_spinner: false,
    fields_missing: false,
    show_loader: false,
    comp_fc_autofail_result: [],
    pending_filters: {
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      reviewee: { name: "reviewee", nickname: "Reviewee", type: "multi", options: {}, selections: {}, size: 2 },
      customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
      due_date: { name: "due_date", nickname: "Due Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    completed_filters: {
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      reviewee: { name: "reviewee", nickname: "Reviewee", type: "multi", options: {}, selections: {}, size: 2 },
      customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
      completed_date: { name: "completed_date", nickname: "Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    pending_qa_filters: {
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      assign_to: { name: "assign_to", nickname: "Assigned To", type: "multi", options: {}, selections: {}, size: 2 },
      customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
      due_date: { name: "due_date", nickname: "Due Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    completed_qa_filters: {
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      assign_to: { name: "assign_to", nickname: "Assigned To", type: "multi", options: {}, selections: {}, size: 2 },
      customer: { name: "customer", nickname: "Customer", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      company_name: { name: "company_name", nickname: "Company", type: "multi", options: {}, selections: {}, size: 2 },
      completed_date: { name: "completed_date", nickname: "Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    pending_followup_filters: {
      assign_to: { name: "assign_to", nickname: "Assigned To", type: "multi", options: {}, selections: {}, size: 2 },
      due_date: { name: "due_date", nickname: "Due Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    completed_followup_filters: {
      assign_to: { name: "assign_to", nickname: "Assigned To", type: "multi", options: {}, selections: {}, size: 2 },
      completed_date: { name: "completed_date", nickname: "Completed Date", type: "date_range", options: {}, selections: {}, size: 2 },
    },
    pending_file_reviews_header_text: "Pending File Reviews: --/--/---- - --/--/----",
    completed_file_reviews_header_text: "Completed File Reviews",
    pending_qa_reviews_header_text: "Pending QA Reviews: --/--/---- - --/--/----",
    completed_qa_reviews_header_text: "Completed QA Reviews",
    pending_followup_tasks_header_text: "Pending Followup Tasks: --/--/---- - --/--/----",
    completed_followup_tasks_header_text: "Completed Followup Tasks",
    related_individual_text: ""
  };

  constructor(props) {
    super(props);
    this.view = "manager_index";
    this.from_change_cur_view = false;
    this.myRef = React.createRef()   // Create a ref object 
  }

  componentDidMount() {
    // console.log("fc_index", Store.getStoreData('fc_index'));
    let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters));
    let pending_qa_filters = JSON.parse(JSON.stringify(this.state.pending_qa_filters));
    let pending_followup_filters = JSON.parse(JSON.stringify(this.state.pending_followup_filters));
    // const td = new Date();
    const td_str = moment().format("DD/MM/YYYY");
    // console.log("today:", td_str);
    // td.setUTCHours(0,0,0,0);
    pending_filters.due_date.selections = { from_date: td_str, to_date: td_str };
    pending_qa_filters.due_date.selections = { from_date: td_str, to_date: td_str };
    // completed_qa_filters.completed_date.selections = {from_date: td_str, to_date: td_str};
    pending_followup_filters.due_date.selections = { from_date: td_str, to_date: td_str };
    const api = new APICall();
    // const payload = { command: "list_tasks", filters: { pending_filters, pending_qa_filters, pending_followup_filters } };
    // console.log("payload:", payload);
    //if (Store.getStoreData("fc_index") === 0) api.command(payload, this.listTasks);
    console.log("fc_index",Store.getStoreData("fc_index"))
    if (Store.getStoreData("fc_index") !== null && Store.getStoreData("fc_index") !== 0) {
      const postData = { command: "get_task", task_id: Store.getStoreData("fc_index") };
      console.log("postData:", postData);
      api.command(postData, this.singleTask);
    }

    let quality_assurance_enabled = this.state.quality_assurance_enabled;
    let module_config = Store.getStoreData("module_config");
    if (Object.keys(module_config).length > 0) {
      quality_assurance_enabled = module_config.general.quality_assurance_enabled;
    }
    EventBus.registerEvent('followup_saved_from_parent', 'MGRIndex', this.showResult_parent)
    this.applyPendingFilter_onload();
    this.setState({ quality_assurance_enabled: quality_assurance_enabled });
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate");
    // if (this.props !== prevProps) {
    //     console.log("fc_index", Store.getStoreData('fc_index'));
    //     console.log("pending_fctasks", Store.getStoreData('pending_fctasks'));
    //     if (this.props.show_task) this.view = "answerview";
    //     if (Store.getStoreData('fc_index') !== null && Store.getStoreData('fc_index') !== 0) {
    //         const api = new APICall();
    //         const postData = {command: "get_task", task_id: Store.getStoreData('fc_index')};
    //         api.command(postData, this.singleTask);
    //     }
    // }
  }

  listTasks = (result) => {
    // console.log("listTasks",result);
    //console.log("listTasks tasks",result.result.pending_tasks.tasks[0])
    // console.log("listTaskstsaks pending_tasks",result.result.pending_tasks.tasks.length)
    // console.log("listTaskstsaks followup_tasks",result.result.followup_tasks.tasks.length)
    // console.log("listTaskstsaks qa_pending_tasks",result.result.qa_pending_tasks.tasks.length)
    this.setState({
      pending_tasks: result.result.pending_tasks.tasks.length ===0 ? null : result.result.pending_tasks.tasks[0],
      pending_filters: result.result.pending_tasks.filters,
      qa_pending_tasks: result.result.qa_pending_tasks.tasks.length ===0 ? null : result.result.qa_pending_tasks.tasks[0],
      pending_qa_filters: result.result.qa_pending_tasks.filters,
      // qa_completed_tasks: result.qa_completed_tasks,
      pending_followup_tasks: result.result.followup_tasks.tasks.length ===0 ? null : result.result.followup_tasks.tasks[0],
      pending_followup_filters: result.result.followup_tasks.filters,
      stats: result.result.stats,
      ready: true,
    });
  };

  singleTask = (result) => {
    // console.log("singleTask", result, this.props.show_task);
    const page_view = this.view;
    let comp_fc_autofail_result = []
    console.log("this.view:", this.view);
    if (this.props.show_task) {
      if (result.result.task.parenttask.cur_lane === "COMP_FCK_COMPLETE") {
        let this_task = result.result.task.parenttask;
        let task_json = JSON.parse(this_task.task_json);
        Store.updateStore("report_task_json", task_json);
        Store.updateStore("report_task", result.result.task);
        Store.updateStore("ReportModal", `${document.body.scrollHeight.toString()}px`);
        this.modal_view = "view_report_modal";
        comp_fc_autofail_result = Utils.getAutofailQuestions(this_task)
        // console.log('comp_fc_autofail_result B::',comp_fc_autofail_result)

      } else if (result.result.task.parenttask.cur_lane === "COMP_FCK_QC_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_FCK_QC_COMPLETE") {
        this.view = "qcview";
      } else if (result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_ASSIGNMENT" || result.result.task.parenttask.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE") {
        Store.updateStore("followup_task", { parenttask: result.result.task.original_parenttask, subtask: result.result.task.parenttask, childtask: result.result.task.childtask});
        this.view = "manager_index";
        this.modal_view = "view_followup_modal";
      } else {
        this.view = "answerview";
      }
    }
    this.view = this.from_change_cur_view === true ? page_view : this.view;
    this.from_change_cur_view = false;
    console.log("page_view:", this.view);
    this.setState({ task: result, page_view: this.view, modal_view: this.modal_view, ready: true, comp_fc_autofail_result });
  };

  refreshState = (modal_view) => {
    this.setState({ view_count: this.state.view_count + 1, modal_view: modal_view });
  };

  scrollToTop = () => {
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  showInitFileReviewModal = (task_id) => (event) => {
    // console.log(task_id);
    event.preventDefault();
    if (task_id > 0) {
      Store.updateStore("fc_index", task_id);
      this.setState({ page_view: "answerview", inside: true });
    } else {
      Store.updateStore("fc_index", task_id);
      this.setState({ page_view: "assignview", task: null });
    }
  };

  showResult = (task_id) => (event) => {

    event.preventDefault();
    console.log('showResult',task_id)
    // let tasks = Store.getStoreData('completed_fctasks');
    // let result = tasks[index];
    document.documentElement.scrollTop = 0;
    // Store.updateStore('report', JSON.parse(result.task_json).object_data);
    let postData = { command: "get_task", task_id: task_id };
    let api = new APICall();
    api.command(postData, this.openReportModal);
  };

  showResult_parent = (task_id)  => {
    this.setState({'show_loader':true})
    document.documentElement.scrollTop = 0;
    // Store.updateStore('report', JSON.parse(result.task_json).object_data);
    let postData = { command: "get_task", task_id: task_id };
    let api = new APICall();
    api.command(postData, this.openReportModal);
  };
  AssigntoQC = (task_id) => (event) => {
    event.preventDefault();
    const r = window.confirm("Are you sure you want to assign this record for QC?");
    if (r === true) {
      let task_json = { task_data: {}, object_data: {}, action_data: {} };
      task_json["task_data"].last_action = "COMP_FCK_QC_ASSIGNMENT";
      task_json["task_data"].cur_lane = "COMP_FCK_QC_ASSIGNMENT";
      task_json["task_data"].actiondate = Date.now();
      task_json["task_data"].cur_assigned_to = 0;
      task_json["action_data"] = { action: "COMP_FCK_QC_ASSIGNMENT", actiondate: Date.now(), performed_by: Store.getStoreData("loggedin_user_info").ID, cur_lane: "COMP_FCK_QC_ASSIGNMENT", assigned_to: 0 };
      let postData = { command: "assign_qc_tasks", task_json: task_json, task_ids: [task_id] };
      const api = new APICall();
      api.command(postData, this.afterAssign);
    }
  };
  afterAssign = (result) => {
    // console.log(result);
    alert("QC assigned successfully.");
    window.location.reload(true);
  };

  openReportModal = (result) => {
    Store.updateStore("report_task_json", JSON.parse(result.result.task.parenttask.task_json));
    Store.updateStore("report_task", result.result.task);
    let comp_fc_autofail_result = []
    comp_fc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
    // console.log('comp_fc_autofail_result A::',comp_fc_autofail_result)
    this.setState({ modal_view: "view_report_modal",show_loader:false, comp_fc_autofail_result });
  };

  closeReportmodal = () => {
    this.setState({ modal_view: null });
  };

  deletePendingTask = (task_id) => (event) => {
    event.preventDefault();
    const r = window.confirm("Are you sure you want to delete this record?");
    if (r === true) {
      this.setState({ show_spinner: true });
      let postData = { command: "remove_task", task_id: task_id };
      let api = new APICall();
      api.command(postData, this.reloadTask);
    }
  };

  reloadTask = (result) => {
    if (result) {
      alert("Filecheck task removed successfully.");
    }
    // window.location.reload(true);
    console.log(result);
    let filter_tasks = [];
    let pending_tasks = JSON.parse(JSON.stringify(this.state.pending_tasks));
    for (let d of pending_tasks) {
      if (d[0] !== result.result) filter_tasks.push(d);
    }
    this.setState({ pending_tasks: filter_tasks, show_spinner: false });
  };

  askDeleteCompletedTask = (task_id) => (event) => {
    console.log("task_id:", task_id);
    const alertParam = {
      confirm: true,
      title: "Confirm",
      message: "Are you sure you want to delete this file check?",
      ok_text: "Yes",
      cancel_text: "No",
      stack: task_id,
      alertHandler: this.deleteCompletedTask,
    };
    this.setState({ alertParam });
  };
  
  askDeleteCompletedTaskQA = (task_id) => (event) => {
    console.log("task_id:", task_id);
    const alertParam = {
      confirm: true,
      title: "Confirm",
      message: "Deleting this record will delete the whole file, this includes the original review; the QA details and all related tasks",
      ok_text: "Yes",
      cancel_text: "No",
      stack: task_id,
      alertHandler: this.deleteCompletedTaskQA,
    };
    this.setState({ alertParam });
  };

  showAnswerView = (task_id) => (event) => {
    event.preventDefault();
    console.log("showAnswerView task id:", task_id);
    Store.updateStore("fc_index", task_id);
    Store.updateStore("is_edit", true);
    this.setState({ page_view: "editcomp"});
  }

  deleteCompletedTask = (confirm, task_id) => {
    console.log("confirm:", confirm);
    console.log("task_id:", task_id);
    if (confirm === false) {
      this.setState({ alertParam: null });
    } else {
      this.setState({ alertParam: null, show_spinner: true });
      let postData = { command: "remove_task", task_id: task_id };
      let api = new APICall();
      api.command(postData, this.reloadCompletedTask);
    }
  };
  deleteCompletedTaskQA = (confirm, task_id) => {
    console.log("confirm:", confirm);
    console.log("task_id:", task_id);
    if (confirm === false) {
      this.setState({ alertParam: null });
    } else {
      this.setState({ alertParam: null, show_spinner: true });
      let postData = { command: "remove_task_qa", task_id: task_id };
      let api = new APICall();
      api.command(postData, this.reloadCompletedTaskQA);
    }
  };

  reloadCompletedTask = (result) => {
    if (result) {
      alert("Filecheck task removed successfully.");
    }
    // window.location.reload(true);
    console.log(result);
    let filter_tasks = [];
    let completed_tasks = JSON.parse(JSON.stringify(this.state.completed_tasks));
    for (let d of completed_tasks) {
      if (d[0] !== result.result) filter_tasks.push(d);
    }
    this.setState({ completed_tasks: filter_tasks, show_spinner: false });
  };
  
  reloadCompletedTaskQA = (result) => {
    if (result) {
      alert("QA Review removed successfully.");
    }
    // window.location.reload(true);
    console.log(result);
    let filter_tasks = [];
    let qa_completed_tasks = JSON.parse(JSON.stringify(this.state.qa_completed_tasks));
    
    for (let d of qa_completed_tasks) {
      if (d[0] !== result.result) filter_tasks.push(d);
    }
    this.setState({ qa_completed_tasks: filter_tasks, show_spinner: false });
  };

  processPendingTasksData = () => {
    // let pending_tasks = Store.getStoreData('pending_fctasks');
    let contacts = Store.getStoreData("users");
    const has_gc = Store.getStoreData("parent_has_group_companies");
    let ret = { data: [], columns: [] };
    let prefered_columns = {};
    let i = 0;
    if (has_gc) {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Company", accessor: "company", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Scheduled Date",
          accessor: "schdate",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          width: 160,
          headerStyle: { textAlign: "left" },
          Cell: (cellInfo) => (
            <div>
              <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
            </div>
          ),
          sortMethod: (a, b) => {
            return a.text > b.text ? 1 : -1;
          },
          filterMethod: (filter, row) => {
            console.log(row);
            console.log(filter);
            return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
          },
        },
      ];
      prefered_columns = {
        file_ref: "File Reference",
        company: "Company",
        reviewee: "Reviewee",
        reviewer: "Reviewer",
        schdate: "Scheduled Date",
        status_value: "Status",
        customer_name: "Customer Name",
        sale_date: "Policy Start Date",
        assign_date: "Assigned Date",
        file_type: "File Type",
        related_individual: "Related Individual"
      };
    } else {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 160, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Scheduled Date",
          accessor: "schdate",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          width: 160,
          headerStyle: { textAlign: "left" },
          Cell: (cellInfo) => (
            <div>
              <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
            </div>
          ),
          sortMethod: (a, b) => {
            return a.text > b.text ? 1 : -1;
          },
          filterMethod: (filter, row) => {
            return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
          },
        },
      ];
      prefered_columns = {
        file_ref: "File Reference",
        reviewee: "Reviewee",
        reviewer: "Reviewer",
        schdate: "Scheduled Date",
        status_value: "Status",
        customer_name: "Customer Name",
        sale_date: "Policy Start Date",
        assign_date: "Assigned Date",
        file_type: "File Type",
        related_individual: "Related Individual"
      };
    }

    if (Store.getStoreData("role") !== "view_only_manager") {
      ret.columns.push({
        Header: "Action",
        filterable: false,
        Cell: (row) => (
          <div style={{ width: "100%", boxSizing: "border-box" }}>
            <div style={{ float: "left" }}>
              <MRButton id={`tbl-1-${row.original.index}`} onClick={this.showInitFileReviewModal(row.original.id)}>
                View
              </MRButton>
            </div>
            <div style={{ float: "left" }}>
              {(() => {
                if (Store.getStoreData("role") === "admin_manager" || Store.getStoreData("role") === "senior_manager" || Store.getStoreData("role") === "manager") {
                  return (
                    <DelButton onClick={this.deletePendingTask(row.original.id)}>
                      <IoIosTrash />
                    </DelButton>
                  );
                }
              })()}
              {/*{Store.getStoreData('role') === "admin_manager" && <DelButton onClick={this.deletePendingTask(row.original.id)}><IoIosTrash /></DelButton>}*/}
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        ),
        width: 210,
        headerStyle: { textAlign: "left" },
      });
    }
    // console.log("pending_tasks",this.state.pending_tasks[0])
    if (this.state.pending_tasks !== null) {
      for (let d of this.state.pending_tasks) {
        i++;

        let today = moment().unix();
        let due_date = moment(d[5], "DD/MM/YYYY").set('hour', 23).set('minute', 59).set('second', 59);
        due_date = due_date.unix();

        let text = due_date > today ? "Pending" : "Overdue";
        let color = due_date > today ? "#2E8B57" : "#FF0000";
        text = d[6] === "COMP_FCK_AWAITING_APPROVAL" ? "Awaiting Approval" : text;
        color = d[6] === "COMP_FCK_AWAITING_APPROVAL" ? "#182d4d" : color;
        text = d[6] === "COMP_FCK_SAVED" ? "In Progress" : text;
        color = d[6] === "COMP_FCK_SAVED" ? "#ffd11a" : color;
        let related_individual = "N/A";
        if (d[13] !== 0) {
          related_individual = d[13].toString() in contacts ? contacts[d[13].toString()].ContactName : "Inactive User";
        }
        let sale_date_val = "N/A";
        if(d[14]){
          sale_date_val = d[7]
        }
        console.log("pending_tasks For ",d[0]," sale date is ",d[7],d[14],sale_date_val)
        let elem = {
          index: i,
          //file_ref: d[9],
          file_ref: d[9].replace(/'+/g, "'"),
          customer_name: d[1],
          company: d[2],
          reviewee: d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "Inactive User",
          reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
          schdate: d[5],
          status: { text: text, color: color },
          id: d[0],
          sale_date: sale_date_val,
          status_value: text,
          assign_date: d[8],
          file_type: d[10],
          related_individual: related_individual
        };
        console.log("pending_tasks elem",elem);
        //return;
        ret.data.push(elem);
      }
    }
    ret.data = ret.data.sort((a, b) => {
      if (a.schdate === b.schdate) {
        return 0;
      }
      let adue = a.schdate.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.schdate.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    ret.export_columns = prefered_columns;
    return ret;
  };

  processCompleteTasksData = () => {
    let contacts = Store.getStoreData("users");
    const has_gc = Store.getStoreData("parent_has_group_companies");
    let prefered_columns = {};
    let ret = { data: [], columns: [] };
    let i = 0;
    if (has_gc) {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 150, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 150, headerStyle: { textAlign: "left" } },
        { Header: "Company", accessor: "company", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Completed Date",
          accessor: "completed_date",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Action",
          filterable: false,
          Cell: (row) => (
            <div style={{ width: "100%", boxSizing: "border-box" }}>
              <div style={{ float: "left" }}>
                <MRButton onClick={this.showResult(row.original.id)}>Show Report</MRButton>
              </div>
              <div style={{ float: "left" }}>
                {Store.getStoreData("role") === "admin_manager" && (
                  <DelButton onClick={this.askDeleteCompletedTask(row.original.id)}>
                    <IoIosTrash />
                  </DelButton>
                )}
              </div>
              {
                Store.getStoreData("role") === "admin_manager" &&
                <div style={{float: "left"}}>
                  <DelButton onClick={this.showAnswerView(row.original.id)}><IoMdCreate /></DelButton>
                </div>
              }
              <div style={{ clear: "both" }}></div>
            </div>
          ),
          width: 210,
          headerStyle: { textAlign: "left" },
        },
      ];
      prefered_columns = {
        file_ref: "File Reference",
        company: "Company",
        reviewee: "Reviewee",
        reviewer: "Reviewer",
        schdate: "Scheduled Date",
        status: "Status",
        customer_name: "Customer Name",
        sale_date: "Policy Start Date",
        assign_date: "Assigned Date",
        file_type: "File Type",
        completed_date: "Review Date",
        score: "Score",
        result: "Result",
        related_individual: "Related Individual",
        confirmed_by: "File Edited By",
        confirmed_date: "File Edited Date",
        confirmation_text: "File Edit Comments"
      };
    } else {
      ret.columns = [
        { Header: "#", accessor: "index", width: 50, headerStyle: { textAlign: "left" } },
        { Header: "File Reference", accessor: "file_ref", minWidth: 150, headerStyle: { textAlign: "left" } },
        { Header: "Customer Name", accessor: "customer_name", minWidth: 150, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Completed Date",
          accessor: "completed_date",
          minWidth: 120,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        {
          Header: "Action",
          filterable: false,
          Cell: (row) => (
            <div style={{ width: "100%", boxSizing: "border-box" }}>
              <div style={{ float: "left" }}>
                <MRButton onClick={this.showResult(row.original.id)}>Show Report</MRButton>
              </div>
              <div style={{ float: "left" }}>
                {Store.getStoreData("role") === "admin_manager" && (
                  <DelButton onClick={this.askDeleteCompletedTask(row.original.id)}>
                    <IoIosTrash />
                  </DelButton>
                )}
              </div>
              {
                Store.getStoreData("role") === "admin_manager" &&
                <div style={{float: "left"}}>
                  <DelButton onClick={this.showAnswerView(row.original.id)}><IoMdCreate /></DelButton>
                </div>
              }
              <div style={{ clear: "both" }}></div>
            </div>
          ),
          width: 210,
          headerStyle: { textAlign: "left" },
        },
      ];
      prefered_columns = {
        file_ref: "File Reference",
        reviewee: "Reviewee",
        reviewer: "Reviewer",
        schdate: "Scheduled Date",
        status: "Status",
        customer_name: "Customer Name",
        sale_date: "Policy Start Date",
        assign_date: "Assigned Date",
        file_type: "File Type",
        completed_date: "Review Date",
        score: "Score",
        result: "Result",
        related_individual: "Related Individual",
        confirmed_by: "File Edited By",
        confirmed_date: "File Edited Date",
        confirmation_text: "File Edit Comments"
      };
    }
    if (this.state.completed_tasks !== null) {
      for (let d of this.state.completed_tasks) {
        i++;
        let related_individual = "N/A";
        let confirmed_by = "N/A";
        let confirmed_date = "N/A";
        let confirmation_text = "N/A";
        if (d[14] !== 0) {
          related_individual = d[14].toString() in contacts ? contacts[d[14].toString()].ContactName : "Inactive User";
        }
        if (d[15] !== 0) {
          confirmed_by = d[15].toString() in contacts ? contacts[d[15].toString()].ContactName : "Inactive User";
        }
        if (d[16] !== 0) {
          confirmed_date = moment.unix(d[16]).format("DD/MM/YYYY");
        }
        if (d[17] !== "") {
          confirmation_text = d[17];
        }
        let elem = {
          index: i,
          file_ref: d[5].replace(/'+/g, "'"),
          customer_name: d[1].replace(/'+/g, "'"),
          company: d[2],
          reviewee: d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "--",
          reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "--",
          id: d[0],
          file_type: d[6],
          schdate: moment.unix(d[12]).format("DD/MM/YYYY"),
          status: "Completed",
          sale_date: moment.unix(d[7]).format("DD/MM/YYYY"),
          assign_date: moment.unix(d[8]).format("DD/MM/YYYY"),
          completed_date: moment.unix(d[9]).format("DD/MM/YYYY"),
          completed_date_timestamp: moment(d[9]).unix(),
          score: d[10],
          result: d[11],
          related_individual: related_individual,
          confirmed_by: confirmed_by,
          confirmed_date: confirmed_date,
          confirmation_text: confirmation_text.toString().replace(/\n/g, " ").replace(/,/g, " ")
        };
        ret.data.push(elem);
      }
    }
    ret.data = ret.data.sort((a, b) => {
      if (a.completed_date === b.completed_date) {
        return 0;
      }
      let adue = a.completed_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.completed_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    ret.export_columns = prefered_columns;
    return ret;
  };

  processPendingFollowupTasksData = () => {
    // let followup_tasks = [];
    // if (Store.getStoreData('followup_tasks') !== null) {
    //     followup_tasks = Store.getStoreData('followup_tasks').sort((a,b) => new Date(a.due_date) - new Date(b.due_date));
    // }
    let contacts = Store.getStoreData("users");
    let ret = { data: [], columns: [] };
    let prefered_columns = {};
    // let i = 0;
    ret.columns = [
      { Header: "Task", accessor: "task", minWidth: 240, headerStyle: { textAlign: "left" } },
      { Header: "Company Name", accessor: "company_name", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Assigned To", accessor: "assigned_to", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Customer Name", accessor: "customer_name", minWidth: 120, headerStyle: { textAlign: "left" } },
      {
        Header: "Status",
        accessor: "status",
        minWidth: 120,
        headerStyle: { textAlign: "left" },
        Cell: (cellInfo) => (
          <div>
            <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
          </div>
        ),
        sortMethod: (a, b) => {
          return a.text > b.text ? 1 : -1;
        },
        filterMethod: (filter, row) => {
          return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
        },
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        minWidth: 120,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "Action",
        minWidth: 120,
        filterable: false,
        Cell: (row) => (
          <div>
            <div style={{ float: "left" }}>
              <MRButton style={{ width: "120px" }} onClick={this.showFollowUp(row.original.id)}>
                View
              </MRButton>
            </div>
            {(() => {
              if (Store.getStoreData("loggedin_user_info").role === "admin_manager") {
                return (
                  <div style={{ float: "left" }}>
                    <DelButton onClick={this.deleteFollowupTask(row.original.id, "pending")}>
                      <IoIosTrash />
                    </DelButton>
                  </div>
                );
              }
            })()}
            <div style={{ clear: "both" }}></div>
          </div>
        ),
        headerStyle: { textAlign: "left" },
      },
    ];
    // console.log("pending__tasks follow",this.state.pending_followup_tasks)
    if (this.state.pending_followup_tasks !== null) {
      for (let d of this.state.pending_followup_tasks) {
        // i++;
        // console.log("d:", d);
        // let task_json = JSON.parse(d.task_json);
        // let due_date_str = d[6] ? d[6] : '1970-01-01T00:00:00.000Z';
        // const c_date = new Date(due_date_str);
        // let day = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
        // let month = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
        // let year = c_date.getFullYear().toString();
        // const due_date = `${year}/${month}/${day}`;
        // const due_date = day+"/"+month+"/"+year;
        // let ch_date = new Date(year, month - 1, day);
        // ch_date.setDate(ch_date.getDate() + 1);
        let today = moment().set("hour", 23).set("minute", 59).set("second", 59).unix();
        let text = d[6] > today ? "Pending" : "Overdue";
        let color = d[6] > today ? "#2E8B57" : "#FF0000";
        text = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
        color = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;
        const assigned_to_name = d[3] === 0 ? (d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "Inactive User") : d[3].toString() in contacts ? contacts[d[3]].ContactName : "Inactive User";
        // const u_role = contacts[task_json.task_data.cur_assigned_to.toString()].role;
        // console.log(assigned_to_name, u_role);
        let task = assigned_to_name + " has been assigned a task titled " + d[2];
        let elem = { task: task, company_name: d[1], assigned_to: assigned_to_name, status: { text: text, color: color }, due_date: moment.unix(d[6]).format("DD/MM/YYYY"), status_value: text, id: d[0], customer_name: d[8] };
        ret.data.push(elem);
      }
      Store.updateStore("followup_tasks_columns", prefered_columns);
      ret.data = ret.data.sort((a, b) => {
        if (a.due_date === b.due_date) {
          return 0;
        }
        let adue = a.due_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
        let bbdue = b.due_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
      });
    }
    ret.export_columns = { task: "Task Name", company_name: "Company Name", assigned_to: "Assigned To", customer_name: "Customer Name", status_value: "Status", due_date: "Due Date" };
    return ret;
  };

  processCompletedFollowupTasksData = () => {
    let contacts = Store.getStoreData("users");
    let ret = { data: [], columns: [] };
    let prefered_columns = {};
    // let i = 0;
    ret.columns = [
      { Header: "Task", accessor: "task", minWidth: 240, headerStyle: { textAlign: "left" } },
      { Header: "Company Name", accessor: "company_name", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Assigned To", accessor: "assigned_to", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Customer Name", accessor: "customer_name", minWidth: 120, headerStyle: { textAlign: "left" } },
      {
        Header: "Status",
        accessor: "status",
        width: 120,
        headerStyle: { textAlign: "left" },
        Cell: (cellInfo) => (
          <div>
            <MRButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton>
          </div>
        ),
        sortMethod: (a, b) => {
          return a.text > b.text ? 1 : -1;
        },
        filterMethod: (filter, row) => {
          return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
        },
      },
      {
        Header: "Completed Date",
        accessor: "completed_date",
        minWidth: 120,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "Action",
        filterable: false,
        Cell: (row) => (
          <div>
            <MRButton style={{ width: "150px" }} onClick={this.showFollowUp(row.original.id)}>
              View
            </MRButton>
          </div>
        ),
        headerStyle: { textAlign: "left" },
      },
    ];
    if (this.state.completed_followup_tasks !== null) {
      for (let d of this.state.completed_followup_tasks) {
        let today = moment().unix();
        let text = d[7] + 86400 > today ? "Pending" : "Overdue";
        let color = d[7] + 86400 > today ? "#2E8B57" : "#FF0000";
        text = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "Complete" : text;
        color = d[5] === "COMP_FCK_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;
        const assigned_to_name = d[3] === 0 ? (d[4].toString() in contacts ? contacts[d[4].toString()].ContactName : "Inactive User") : d[3].toString() in contacts ? contacts[d[3]].ContactName : "Inactive User";
        let task = assigned_to_name + " has been assigned a task titled " + d[2];
        let performedBy = d[10] !== 0 ? (d[10].toString() in contacts ? contacts[d[10]]?.ContactName : "Inactive User") : "";
        let reviewType = Store.getStoreData("my_reviews").find(c => c.id === d[11])?.nick_name

        let elem = {
          task: task,
          company_name: d[1],
          assigned_to: assigned_to_name,
          status: { text: text, color: color },
          status_value: text,
          id: d[0],
          due_date: moment.unix(d[6]).format("DD/MM/YYYY"),
          customer_name: d[8],
          task_response: d[9].replace(/,/g, " ").replace(/\n/g, " "),
          completed_date: moment.unix(d[7]).format("DD/MM/YYYY"),
          completed_by: performedBy,
          review_type: reviewType
        };
        ret.data.push(elem);
      }
      Store.updateStore("followup_tasks_columns", prefered_columns);
      ret.data = ret.data.sort((a, b) => {
        if (a.completed_date === b.completed_date) {
          return 0;
        }
        let adue = a.completed_date.split("/");
        const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
        // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
        let bbdue = b.completed_date.split("/");
        const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
        return aReverse < bReverse ? 1 : -1;
      });
    }
    ret.export_columns = { task: "Task Name", company_name: "Company Name", assigned_to: "Assigned To", customer_name: "Customer Name", status_value: "Status", due_date: "Due Date", task_response:'Task Response', completed_date: "Completed Date", completed_by : "Completed By", review_type : "File Review Type" };
    return ret;
  };

  //for qc
  processQACompleteTasksData = () => {
    let has_gc = Store.getStoreData("parent_has_group_companies");
    let contacts = Store.getStoreData("users");
    let ret = { data: [], columns: [] };
    ret.columns = [
      { Header: "Status", accessor: "status", minWidth: 40, headerStyle: { textAlign: "left" } },
      { Header: "Task", accessor: "task", minWidth: 100, headerStyle: { textAlign: "left" } },
      { Header: "Assign To", accessor: "assignto", minWidth: 40, headerStyle: { textAlign: "left" } },
      { Header: "File Reference", accessor: "file_reference", minWidth: 30, headerStyle: { textAlign: "left" } },
      { Header: "Customer Name", accessor: "customer_name", minWidth: 40, headerStyle: { textAlign: "left" } },
      { Header: "Company", accessor: "company", minWidth: 40, headerStyle: { textAlign: "left" }, show: has_gc },
      { Header: "Reviewer", accessor: "reviewer", minWidth: 40, headerStyle: { textAlign: "left" } },
      {
        Header: "Completed Date",
        accessor: "due_date",
        minWidth: 30,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "Action",
        filterable: false,
        Cell: (row) => (
          <div style={{ width: "100%", boxSizing: "border-box" }}>
             <div style={{ float: "left" }}>
              <MRButton style={{ width: "67px" }} onClick={this.showQc(row.original.id)}>
                View
              </MRButton>
            </div>
            
            <div style={{ float: "left" }}>
                {Store.getStoreData("role") === "admin_manager" && (
                  <DelButton onClick={this.askDeleteCompletedTaskQA(row.original.id)}>
                    <IoIosTrash />
                  </DelButton>
                )}
              </div>

          </div>
          
        ),
        minWidth: 40,
        headerStyle: { textAlign: "left" },
      },
    ];
    if (this.state.qa_completed_tasks !== null) {
      for (let d of this.state.qa_completed_tasks) {
        // let due_date_str = d[5] ? d[5] : '1970-01-01T00:00:00.000Z';
        // const c_date = new Date(due_date_str);
        // let dd = c_date.getDate().toString().length === 1 ? "0"+c_date.getDate().toString() : c_date.getDate().toString();
        // let mm = (c_date.getMonth() + 1).toString().length === 1 ? "0"+(c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
        // let yyyy = c_date.getFullYear().toString();
        // due_date_str = dd +"/"+ mm + "/" + yyyy;
        let text = "Completed";
        let color = "#2E8B57";
        let box = (
          <div>
            <div style={{ width: "15px", height: "15px", borderRadius: "4px", backgroundColor: color, display: "inline-block" }}></div>
            <div style={{ display: "inline", paddingLeft: "15px" }}>{text}</div>
          </div>
        );
        const user_name = d[11].toString() in contacts ? contacts[d[11].toString()].ContactName : "Inactive User";
        let task = "This QA task have been completed by " + user_name;
        let related_individual = "N/A";
        let confirmed_by = "N/A";
        let confirmed_date = "N/A";
        let confirmation_text = "N/A";
        if (d[12] !== 0) {
          related_individual = d[12].toString() in contacts ? contacts[d[12].toString()].ContactName : "Inactive User";
        }
        if (d[13] !== 0) {
          confirmed_by = d[13].toString() in contacts ? contacts[d[13].toString()].ContactName : "Inactive User";
        }
        if (d[14] !== 0) {
          confirmed_date = moment.unix(d[14]).format("DD/MM/YYYY");
        }
        if (d[15] !== "") {
          confirmation_text = d[15];
        }
        let elem = {
          status: box,
          task: task,
          assignto: user_name,
          file_reference: d[9].replace(/'+/g, "'"),
          customer_name: d[1].replace(/'+/g, "'"),
          company: d[2],
          reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
          due_date: moment.unix(d[5]).format("DD/MM/YYYY"),
          status_text: text,
          id: d[0],
          related_individual: related_individual,
          confirmed_by: confirmed_by,
          confirmed_date: confirmed_date,
          confirmation_text: confirmation_text
        };
        ret.data.push(elem);
      }
    }
    ret.data = ret.data.sort((a, b) => {
      if (a.due_date === b.due_date) {
        return 0;
      }
      let adue = a.due_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.due_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    ret.export_columns = {
      status_text: "Status",
      task: "Task",
      assignto: "Assigned To",
      file_reference: "File Reference",
      customer_name: "Customer",
      company: "Company",
      reviewer: "Reviewer",
      due_date: "Completed Date",
      related_individual: "Related Individual",
      confirmed_by: "File Edited By",
      confirmed_date: "File Edited Date",
      confirmation_text: "File Edit Comments"
    };
    return ret;
  };

  processPendingQATasksData = () => {
    // let pending_tasks = Store.getStoreData("completed_fctasks");
    let has_gc = Store.getStoreData("parent_has_group_companies");
    // console.log("pending_tasks", pending_tasks);
    let contacts = Store.getStoreData("users");
    // let gc_assoc = Store.getStoreData("gc_assoc");
    // let gc_company = "";
    let ret = { data: [], columns: [] };
    ret.columns = [
      {
        Header: "Status",
        accessor: "status",
        minWidth: 40,
        headerStyle: { textAlign: "left" },
        Cell: (cellInfo) => (
          <div>
            <StatusButton style={{ cursor: "default", backgroundColor: `${cellInfo.value.color}`, borderColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</StatusButton>
          </div>
        ),
        sortMethod: (a, b) => {
          return a.text > b.text ? 1 : -1;
        },
        filterMethod: (filter, row) => {
          console.log(row);
          console.log(filter);
          return row._original.status.text.toLowerCase().startsWith(filter.value.toLowerCase());
        },
      },
      { Header: "Task", accessor: "task", minWidth: 100, headerStyle: { textAlign: "left" } },
      { Header: "Assign To", accessor: "assignto", minWidth: 40, headerStyle: { textAlign: "left" } },
      { Header: "File Reference", accessor: "file_reference", minWidth: 30, headerStyle: { textAlign: "left" } },
      { Header: "Customer Name", accessor: "customer_name", minWidth: 40, headerStyle: { textAlign: "left" } },
      { Header: "Company", accessor: "company", minWidth: 40, headerStyle: { textAlign: "left" }, show: has_gc },
      { Header: "Reviewer", accessor: "reviewer", minWidth: 40, headerStyle: { textAlign: "left" } },
      {
        Header: "Due Date",
        accessor: "due_date",
        minWidth: 30,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
    ];
    if (Store.getAllStoreData("loggedin_user_info").role !== "view_only_manager") {
      ret.columns.push({
        Header: "Action",
        filterable: false,
        Cell: (row) => (
          <div>
            <MRButton style={{ width: "67px" }} onClick={this.showQc(row.original.id)}>
              View
            </MRButton>
          </div>
        ),
        minWidth: 40,
        headerStyle: { textAlign: "left" },
      });
    }
    // console.log("qa_pending_tasks",this.state.qa_pending_tasks)
    if (this.state.qa_pending_tasks !== null) {
      for (let d of this.state.qa_pending_tasks) {
        if (d[6] === "COMP_FCK_QC_ASSIGNMENT") {
          let today = moment().unix();
          const due_unix = moment.unix(d[5]).set("hour", 23).set("minute", 59).set("second", 59).unix();
          let text = due_unix > today ? "Pending" : "Overdue";
          let color = due_unix > today ? "#2E8B57" : "#FF0000";
          let contact = d[10].toString() in contacts ? contacts[d[10].toString()].ContactName : "Inactive User";
          const review_name = d[8];
          let task = contact + " have been assigned a " + review_name + " QA Review";
          let related_individual = "N/A";
          let confirmed_by = "N/A";
          let confirmed_date = "N/A";
          let confirmation_text = "N/A";
          if (d[13] !== 0) {
            related_individual = d[13].toString() in contacts ? contacts[d[13].toString()].ContactName : "Inactive User";
          }
          if (d[14] !== 0) {
            confirmed_by = d[14].toString() in contacts ? contacts[d[14].toString()].ContactName : "Inactive User";
          }
          if (d[15] !== 0) {
            confirmed_date = moment.unix(d[15]).format("DD/MM/YYYY");
          }
          if (d[16] !== "") {
            confirmation_text = d[16];
          }

          let elem = {
            status: { text: text, color: color },
            task: task,
            assignto: d[7].toString() in contacts ? contacts[d[7].toString()].ContactName : "N/A",
            file_reference: d[9].replace(/'+/g, "'"),
            customer_name: d[1],
            company: d[2],
            reviewer: d[3].toString() in contacts ? contacts[d[3].toString()].ContactName : "Inactive User",
            due_date: moment.unix(d[5]).format("DD/MM/YYYY"),
            status_value: text,
            id: d[0],
            related_individual: related_individual,
            confirmed_by: confirmed_by,
            confirmed_date: confirmed_date,
            confirmation_text: confirmation_text
          };
          ret.data.push(elem);
        }
      }
    }
    ret.data = ret.data.sort((a, b) => {
      if (a.due_date === b.due_date) {
        return 0;
      }
      let adue = a.due_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.due_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    ret.export_columns = {
      status_value: "Status",
      task: "Task",
      assignto: "Assigned To",
      file_reference: "File Reference",
      customer_name: "Customer",
      company: "Company",
      reviewer: "Reviewer",
      due_date: "Due Date",
      related_individual: "Related Individual",
      confirmed_by: "File Edited By",
      confirmed_date: "File Edited Date",
      confirmation_text: "File Edit Comments"
    };
    return ret;
  };

  showQc = (task_id) => (event) => {
    event.preventDefault();
    let quality_assurance_enabled = this.state.quality_assurance_enabled;
    let module_config = Store.getStoreData("module_config");
    if (Object.keys(module_config).length > 0) {
      quality_assurance_enabled = module_config.general.quality_assurance_enabled;
    }
    console.log(task_id);
    if (quality_assurance_enabled) {
      Store.updateStore("fc_index", task_id);
      this.setState({ page_view: "qcview" });
    } else {
      alert("Quality assurance disabled");
      return false;
    }
  };
  //end for qc

  openPdfPrintView = () => {
    //this.setState({page_view: 'pdfprintview'});
    this.props.changeCurView("pdfprintview");
  };
 
  showFollowUp = (task_id) => (event) => {
    event.preventDefault();
    this.setState({show_spinner: true})
    let postData = { command: "get_subtask", task_id: task_id };
    let api = new APICall();
    api.command(postData, this.openFollowupModal);
  };

  openFollowupModal = (result) => {
    console.log("followup_task:", result.result.task.parenttask.cur_lane);
    Store.updateStore("followup_task", result.result.task);
    this.setState({ modal_view: "view_followup_modal",show_spinner: false });
    
  };
  singleTask_followup = (result) => {
    let this_task = result.result.task.parenttask;
    let task_json = JSON.parse(this_task.task_json);
    Store.updateStore("report_task_json", task_json);
    Store.updateStore("report_task", result.result.task);
};
  myfun = (view) => {
    // console.log("view:", view);
  };

  deleteFollowupTask = (task_id, status) => (event) => {
    event.preventDefault();
    console.log("task_id:", task_id);
    const r = window.confirm("Are you sure you want to delete this record?");
    if (r === true) {
      let postData = { command: "remove_followup_task", task_id: task_id };
      let api = new APICall();
      this.setState({ show_spinner: true });
      if (status === "pending") {
        api.command(postData, this.refreshPendingFollowup);
      }
    }
  };

  refreshPendingFollowup = (result) => {
    console.log(result);
    let filter_tasks = [];
    let pending_followup_tasks = JSON.parse(JSON.stringify(this.state.pending_followup_tasks));
    for (let d of pending_followup_tasks) {
      if (d[0] !== result.result) filter_tasks.push(d);
    }
    this.setState({ pending_followup_tasks: filter_tasks, show_spinner: false });
  };

  changeCurView = (view) => {
    this.view = view;
    this.from_change_cur_view = true;
    console.log("view:", this.view,this.state);
    if (Store.getStoreData("fc_index") !== null && Store.getStoreData("fc_index") !== 0) {
      const api = new APICall();
      const postData = { command: "get_task", task_id: Store.getStoreData("fc_index") };
      api.command(postData, this.singleTask);
    } else {
      this.setState({ page_view: view });
    }
  };

  toggleCards = (event) => {
    event.preventDefault();
    const show_cards = this.state.show_cards ? false : true;
    this.setState({ show_cards });
  };
  processDate = (date) => {
    let date_str = date.split("T")[0];
    let date_parts = date_str.split("-");
    return `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`;
  };

  showPage = (page) => {
    this.setState({ page_view: page });
  };

  switchRadio = (event) => {
    event.preventDefault();
    console.log(this.state.show_completed);
    if (this.state.show_completed === true) {
      let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
      Object.keys(completed_filters).forEach((key) => {
        completed_filters[key].selections = {};
      });
      this.setState({
        show_completed: false,
        completed_tasks: null,
        completed_filters,
        completed_file_reviews_header_text: "Completed File Reviews"
      });
    } else {
      this.setState({ show_spinner: true, completed_file_reviews_header_text: "Completed File Reviews: --/--/---- - --/--/----"});
      const postData = { command: "get_complete_filter_options" };
      let api = new APICall();
      api.command(postData, this.processFilterOptions);
    }
  };

  toggleQa = (event) => {
    event.preventDefault();
    if (this.state.show_completed_qa === true) {
      let completed_qa_filters = JSON.parse(JSON.stringify(this.state.completed_qa_filters));
      Object.keys(completed_qa_filters).forEach((key) => {
        completed_qa_filters[key].selections = {};
      });
      this.setState({
        show_completed_qa: false,
        qa_completed_tasks: null,
        completed_qa_filters,
        completed_qa_reviews_header_text: "Completed QA Reviews"
      });
    } else {
      this.setState({ show_spinner: true,completed_qa_reviews_header_text: "Completed QA Reviews: --/--/---- - --/--/----" });
      const postData = { command: "get_qa_complete_filter_options" };
      let api = new APICall();
      api.command(postData, this.processQaCompletedFilterOptions);
    }
  };

  toggleCompletedFollowup = (event) => {
    event.preventDefault();
    if (this.state.show_completed_folloup === true) {
      let completed_followup_filters = JSON.parse(JSON.stringify(this.state.completed_followup_filters));
      Object.keys(completed_followup_filters).forEach((key) => {
        completed_followup_filters[key].selections = {};
      });
      this.setState({
        show_completed_folloup: false,
        completed_followup_tasks: null,
        completed_followup_filters,
        completed_followup_tasks_header_text: "Completed Followup Tasks"
      });
    } else {
      this.setState({ show_spinner: true,completed_followup_tasks_header_text: "Completed Followup Tasks: --/--/---- - --/--/----" });
      const postData = { command: "get_followup_complete_filter_options" };
      let api = new APICall();
      api.command(postData, this.processFollowupCompletedFilterOptions);
    }
  };

  processFollowupCompletedFilterOptions = (result) => {
    let completed_followup_filters = JSON.parse(JSON.stringify(this.state.completed_followup_filters));
    Object.keys(completed_followup_filters).forEach((key) => {
      completed_followup_filters[key]["options"] = result[key];
      if (key === "completed_date") {
        
        const start = new Date(new Date().setDate(new Date().getDate() - 7));
        const formated_start = moment(start).format("DD/MM/YYYY");
        const today = new Date();
        const formated_today = moment(today).format("DD/MM/YYYY");
        completed_followup_filters.completed_date.selections.from_date = formated_start;
        completed_followup_filters.completed_date.selections.to_date = formated_today;
      }
    });
    this.setState({ 
      show_completed_folloup: true, 
      completed_followup_filters, 
      //show_spinner: false 
    });
    
  const postData = { command: "get_filtered_completed_followup_tasks",ontoggle:1, completed_followup_filters };
  let api = new APICall();
  api.command(postData, this.processFilteredCompletedFollowupTasks);

  };

  processFilterOptions = (result) => {
    console.log(result);
    let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
    Object.keys(completed_filters).forEach((key) => {
      completed_filters[key]["options"] = result[key];
      if (key === "completed_date") {
        // const td = new Date();
        // let dd = td.getDate();
        // let mm = td.getMonth() + 1;
        // let yyyy = td.getFullYear();
        // dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
        // mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
        // const td_str = `${dd}/${mm}/${yyyy}`;
        
        const td_start = new Date(new Date().setDate(new Date().getDate() - 90));
        const td_end = new Date();
        let dd = td_start.getDate();
        let mm = td_start.getMonth() + 1;
        let yyyy = td_start.getFullYear();
        dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
        mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
        const td_str = `${dd}/${mm}/${yyyy}`;
        console.log("td_str",td_str);
        let dd1 = td_end.getDate();
        let mm1 = td_end.getMonth() + 1;
        let yyyy1 = td_end.getFullYear();
        dd1 = dd1 < 10 ? `0${dd1.toString()}` : dd1.toString();
        mm1 = mm1 < 10 ? `0${mm1.toString()}` : mm1.toString();
        const td_str_e = `${dd1}/${mm1}/${yyyy1}`;
        
        
        // td.setUTCHours(0,0,0,0);
        //quarter settings
        // let date = new Date();
        // var curr_month = date.getMonth() + 1;
        // var start_month = 0;
        // var end_month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
        // if (curr_month == 1 || curr_month == 2 || curr_month == 3) {
        //   start_month = "01";
        // } else if (curr_month == 4 || curr_month == 5 || curr_month == 6) {
        //   start_month = "04";
        // } else if (curr_month == 7 || curr_month == 8 || curr_month == 9) {
        //   start_month = "07";
        // } else if (curr_month == 10 || curr_month == 11 || curr_month == 12) {
        //   start_month = "10";
        // }
        // const td_str = "01/" + start_month +"/"+ date.getFullYear() ;
        // const td_str_e = date.getDate()+ "/" + end_month + "/" + date.getFullYear() ;
        // console.log("td_str_e",td_str,td_str_e);
        
        completed_filters.completed_date.selections.from_date = td_str;
        completed_filters.completed_date.selections.to_date = td_str_e;
      }
    });
    this.setState({
      show_completed: true,
      completed_filters,
      //show_spinner: false,
    });

    const postData = { command: "get_filtered_completed_tasks",ontoggle:1, completed_filters};
    let api = new APICall();
    api.command(postData, this.processFilteredCompletedTasks);
  };

  // processQaCompletedFilterOptions = (result) => {
  //   let completed_qa_filters = JSON.parse(JSON.stringify(this.state.completed_qa_filters));
  //   Object.keys(completed_qa_filters).forEach((key) => {
  //     completed_qa_filters[key]["options"] = result[key];
  //     if (key === "completed_date") {
  //       const today = new Date();
  //       const formated_today = moment(today).format("DD/MM/YYYY");
  //       completed_qa_filters.completed_date.selections.from_date = formated_today;
  //       completed_qa_filters.completed_date.selections.to_date = formated_today;
  //     }
  //   });
  //   this.setState({ show_completed_qa: true, completed_qa_filters, show_spinner: false });
  // };
  processQaCompletedFilterOptions = (result) => {
    console.log("processQaCompletedFilterOptions result",result);
    let completed_qa_filters = JSON.parse(JSON.stringify(this.state.completed_qa_filters));
    console.log("HERE 1452")
    Object.keys(completed_qa_filters).forEach((key) => {
      completed_qa_filters[key]["options"] = result[key];
      //console.log("HERE 1455",completed_qa_filters[key]["options"])
      if (key === "completed_date") {
        // console.log("HERE 1457")
        const start = new Date(new Date().setDate(new Date().getDate() - 7));
        const formated_start = moment(start).format("DD/MM/YYYY");
        const today = new Date();
        const formated_today = moment(today).format("DD/MM/YYYY");
        //console.log("HERE 1462",formated_start,formated_today)
        completed_qa_filters.completed_date.selections.from_date = formated_start;
        completed_qa_filters.completed_date.selections.to_date = formated_today;
      }
    });
    console.log("processQaCompletedFilterOptions",completed_qa_filters);
    this.setState({ 
      show_completed_qa: true, 
      completed_qa_filters, 
      //show_spinner: false 
    });
    const postData = { command: "get_filtered_completed_qa_tasks",ontoggle:1, completed_qa_filters };
    let api = new APICall();
    api.command(postData, this.processFilteredCompletedQaTasks);
  };
  applyFilter = (filters) => {
    console.log("processFilteredCompleted=>",filters);
    this.setState({ show_spinner: true,
      completed_file_reviews_header_text: "Completed File Reviews: "+filters.completed_date.selections.from_date+" - "+filters.completed_date.selections.to_date  });
    const postData = { command: "get_filtered_completed_tasks", filters};
    let api = new APICall();
    api.command(postData, this.processFilteredCompletedTasks);
  };

  processFilteredCompletedTasks = (result) => {
    // console.log(result);
    this.setState({ completed_tasks: result, show_spinner: false });
  };
  applyPendingFilter_onload = () => {
    let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters));
    let pending_qa_filters = JSON.parse(JSON.stringify(this.state.pending_qa_filters));
    let pending_followup_filters = JSON.parse(JSON.stringify(this.state.pending_followup_filters));
    
    //const td_str = new Date().setDate(new Date().getDate() + 7);
    const td_str = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();
    pending_filters.due_date.selections = { from_date: null, to_date: td_str };
    pending_qa_filters.due_date.selections = { from_date: null, to_date: td_str };
    pending_followup_filters.due_date.selections = { from_date: null, to_date: td_str };
    // console.log("today:", td_str);
    const api = new APICall();
    const payload = { command: "list_tasks", onloading:1, filters: { pending_filters, pending_qa_filters, pending_followup_filters } };
    // console.log("payload:", payload);
    api.command(payload, this.listTasks);
   // return false;
  }
  applyPendingFilter = (filters) => {
    console.log("pending_filters:", filters);
    this.setState({ show_spinner: true,
      pending_file_reviews_header_text: "Pending File Reviews: "+filters.due_date.selections.from_date+" - "+filters.due_date.selections.to_date});
    const postData = { command: "get_filtered_pending_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredPendingTasks);
  };

  processFilteredPendingTasks = (result) => {
    console.log("processFilteredPendingTasks:",result);
    this.setState({ pending_tasks: result, show_spinner: false });
  };

  applyPendingQaFilter = (filters) => {
    // if (filters.due_date.selections.from_date.includes("/") === true) {
    //     const from_arr = filters.due_date.selections.from_date.split("/");
    // }
    console.log("applyPendingQaFilter",filters)
    this.setState({ show_spinner: true,
      pending_qa_reviews_header_text: "Pending QA Reviews: "+filters.due_date.selections.from_date+" - "+filters.due_date.selections.to_date });
    const postData = { command: "get_filtered_pending_qa_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredPendingQaTasks);
  };

  processFilteredPendingQaTasks = (result) => {
    // console.log("processFilteredPendingTasks:",result);
    this.setState({ qa_pending_tasks: result, show_spinner: false });
  };

  applyCompletedQaFilter = (filters) => {
    console.log("applyCompletedQaFilter",filters);
    this.setState({ show_spinner: true,
      completed_qa_reviews_header_text: "Completed QA Reviews: "+filters.completed_date.selections.from_date+" - "+filters.completed_date.selections.to_date });
    const postData = { command: "get_filtered_completed_qa_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredCompletedQaTasks);
  };

  processFilteredCompletedQaTasks = (result) => {
    console.log("result:", result);
    this.setState({ qa_completed_tasks: result, show_spinner: false });
  };

  applyPendingFollowupFilter = (filters) => {
    console.log("applyPendingFollowupFilter",filters);
    this.setState({ show_spinner: true,
      pending_followup_tasks_header_text: "Pending Followup Tasks: "+filters.due_date.selections.from_date+" - "+filters.due_date.selections.to_date });
    const postData = { command: "get_filtered_pending_followup_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredPendingFollowupTasks);
  };

  processFilteredPendingFollowupTasks = (result) => {
    this.setState({ pending_followup_tasks: result, show_spinner: false });
  };

  applyCompletedFollowupFilter = (filters) => {
    console.log("applyCompletedFollowupFilter",filters);
    this.setState({ show_spinner: true,
    completed_followup_tasks_header_text: "Completed Followup Tasks: "+filters.completed_date.selections.from_date+" - "+filters.completed_date.selections.to_date});
    const postData = { command: "get_filtered_completed_followup_tasks", filters };
    let api = new APICall();
    api.command(postData, this.processFilteredCompletedFollowupTasks);
  };

  processFilteredCompletedFollowupTasks = (result) => {
    this.setState({ completed_followup_tasks: result, show_spinner: false });
  };

  clearPendingAdvancedFilter = () => {
    console.log(this.state.pending_filters);
    let pending_filters = JSON.parse(JSON.stringify(this.state.pending_filters));
    Object.keys(pending_filters).forEach((key) => {
      if (pending_filters[key].type === "date_range") {
        pending_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        pending_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        pending_filters[key].selections = {};
      }
    });
    this.setState({ pending_file_reviews_header_text: "Pending File Reviews: --/--/---- - --/--/----",pending_filters });
    // window.location.reload();
  };

  clearCompletedAdvancedFilter = () => {
    console.log(this.state.completed_filters);
    let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
    Object.keys(completed_filters).forEach((key) => {
      if (completed_filters[key].type === "date_range") {
        completed_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        completed_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        completed_filters[key].selections = {};
      }
    });
    this.setState({ completed_file_reviews_header_text: "Completed File Reviews: --/--/---- - --/--/----",completed_filters });
    // window.location.reload();
  };

  clearPendingQaAdvancedFilter = () => {
    console.log(this.state.pending_qa_filters);
    let pending_qa_filters = JSON.parse(JSON.stringify(this.state.pending_qa_filters));
    Object.keys(pending_qa_filters).forEach((key) => {
      if (pending_qa_filters[key].type === "date_range") {
        pending_qa_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        pending_qa_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        pending_qa_filters[key].selections = {};
      }
    });
    this.setState({ pending_qa_reviews_header_text: "Pending QA Reviews: --/--/---- - --/--/----",pending_qa_filters });
    // window.location.reload();
  };

  clearCompletedQaAdvancedFilter = () => {
    console.log(this.state.completed_qa_filters);
    let completed_qa_filters = JSON.parse(JSON.stringify(this.state.completed_qa_filters));
    Object.keys(completed_qa_filters).forEach((key) => {
      if (completed_qa_filters[key].type === "date_range") {
        completed_qa_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        completed_qa_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        completed_qa_filters[key].selections = {};
      }
    });
    this.setState({ completed_qa_reviews_header_text: "Completed QA Reviews: --/--/---- - --/--/----",completed_qa_filters });
    // window.location.reload();
  };

  clearPendingFollowupAdvancedFilter = () => {
    console.log(this.state.pending_followup_filters);
    let pending_followup_filters = JSON.parse(JSON.stringify(this.state.pending_followup_filters));
    Object.keys(pending_followup_filters).forEach((key) => {
      if (pending_followup_filters[key].type === "date_range") {
        pending_followup_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        pending_followup_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        pending_followup_filters[key].selections = {};
      }
    });
    this.setState({ pending_followup_tasks_header_text: "Pending Followup Tasks: --/--/---- - --/--/----",pending_followup_filters });
    // window.location.reload();
  };

  clearCompletedFollowupAdvancedFilter = () => {
    console.log(this.state.completed_followup_filters);
    let completed_followup_filters = JSON.parse(JSON.stringify(this.state.completed_followup_filters));
    Object.keys(completed_followup_filters).forEach((key) => {
      if (completed_followup_filters[key].type === "date_range") {
        completed_followup_filters[key].selections.from_date = moment().format("DD/MM/YYYY");
        completed_followup_filters[key].selections.to_date = moment().format("DD/MM/YYYY");
      } else {
        completed_followup_filters[key].selections = {};
      }
    });
    this.setState({ completed_followup_filters, completed_followup_tasks_header_text: "Completed Followup Tasks: --/--/---- - --/--/----"});
    // window.location.reload();
  };

  closeQcView = (event) => {
    event.preventDefault();
    Store.updateStore("fc_index", null);
    if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
      window.location.assign(document.referrer);
    }
    this.setState({ page_view: "manager_index" });
  };
  closeAnswerView = () => {
    // event.preventDefault();
    Store.updateStore("fc_index", null);
    //console.log('referrer',document.referrer)
    if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
      window.location.assign(document.referrer);
    }
    this.setState({ page_view: "manager_index" });
  };

  showBulkModal = (event) => {
      event.preventDefault();
      // console.log("showBulkModal");
      this.setState({modal_view: "bulk_assign"});
  }

  closeBulkmodal = (event) => {
      event.preventDefault();
      this.setState({modal_view: null});
  }

  goNext = (data) => {
      console.log("data in goNext:", data);
      const mandatory_columns = {
        reviewer: "Reviewer",
        reviewee: "Reviewee",
        file_review_type: "File Review Type",
        due_date: "Scheduled Date",
        file_reference: "File Reference",
        customer_name: "Customer Name"
      };
      if (Store.getStoreData("parent_has_group_companies") === true){
        mandatory_columns.group_company = "Group Company";
      } 
      const module_config = Store.getStoreData("module_config");
      if (module_config.custom_fields.enabled === true) {
        for (let item of module_config.custom_fields.additional_fields) {
          if (item.required === true && item.is_active === true){
            mandatory_columns[item.name] = item.nickname;
          } 
        }
        for (let item of module_config.custom_fields.custom_terminology_fields) {
          if (item.required === true && item.is_active === true){
            if(item.name === "reviewee_designation")console.log("mandatory_columns item:", item);
            mandatory_columns[item.name] = item.nickname;
          } 
          if (item.name === "sale_date" && item.is_active === true){
            mandatory_columns[item.name] = item.nickname;
          } 
          if(item.name === "reviewee_designation")console.log("mandatory_columns A:", mandatory_columns);
        }
      }
      let ctf_field = module_config.custom_fields.custom_terminology_fields.find(c => c.name === "reviewee_designation");
      if ("reviewee_designation" in mandatory_columns === false && module_config.general.reviewee_designation_required === true && ctf_field.is_active) {
        mandatory_columns.reviewee_designation = "Reviewee Designation";
      }
      let check_mandatory_columns = {};
      for (let key in mandatory_columns) {
        if (key in data.headers === false) check_mandatory_columns[key] = mandatory_columns[key];
      }
      console.log("mandatory_columns B:", mandatory_columns);
      console.log("check_mandatory_columns:", check_mandatory_columns);
      let alertParam = null;
      let fields_missing = false;
      if (Object.keys(check_mandatory_columns).length !== 0) {
        let msg = `<div style="padding: 20px 0px 0px 20px;">
          <div>This uploaded data cannot be submitted</div>
          <div>The following field(s) are missing</div>
          <ol>
        `;
        for (let key in check_mandatory_columns) {
          msg = msg + `<li>${check_mandatory_columns[key]}</li>`;
        }
        msg = msg + `</ol></div>`;
        alertParam = {title: "Error", message: msg, ok_text: 'OK', confirm: false, alertHandler: this.closeAlertBox, stack: {id: 0}, render_html: true};
        fields_missing = true;
      }

      let related_individual_text = "";

      if('related_individual_text' in data){
        related_individual_text = data.related_individual_text;
      }
      //console.log('related_individual_text::',related_individual_text);
      this.setState({bulk_data: data, modal_view: "bulk_preview", alertParam, fields_missing, related_individual_text});
  }

  closeAlertBox = (result,stack) => {
    this.setState({alertParam: null})
  }

  submitBulk = (data, notifications) => {
      // console.log("data in submitBulk:", data);
      this.setState({show_spinner: true});
      const api = new APICall();
      const postData = {command: "create_bulk_assignment_job", payload: {data: data, notifications: notifications}};
      // console.log("postData:", postData);
      api.command(postData, this.postSubmit);
  }

  postSubmit = (result) => {
      // console.log(result);
      alert("File checks will be created in the background. Please be aware that this may take a few minutes to update");
      this.setState({modal_view: null, show_spinner: false});
  }

  attachFile = (file) => {
      console.log(file);
  }

  render() {
    console.log('page_view::',this.state)
    console.log('modal_view::',this.state.modal_view)
    
    if (Store.getStoreData("fc_index") === 0) {
      if (Store.getStoreData("filechecker-list_tasks") === null) {
        return (
          <div>
            <CSLPageloader />
          </div>
        );
      }
    }

    if (this.state.ready === false) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }

    if (this.state.show_loader === true) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }
    // console.log("show_task: ", this.props.show_task);
    if (this.state.page_view === "manager_index") {
      return (
        <div ref={this.myRef}>
          {this.state.alertParam !== null && <AlertBox alertParam={this.state.alertParam} />}
          {(() => {
            if (this.state.show_spinner === true) {
              return (
                <div>
                  <DummyOverlay>
                    <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                      <ClipLoader size={150} color={"#123abc"} loading={true} />
                    </div>
                  </DummyOverlay>
                </div>
              );
            }
          })()}
          {(() => {
            if (Store.getStoreData("module_config").general.data_cards_enabled) {
              if (this.state.show_cards) {
                return (
                  <div>
                    <CardChevronContainer>
                      <div onClick={this.toggleCards} style={{ fontSize: "16px", cursor: "pointer" }}>
                        <IoIosArrowUp />
                      </div>
                    </CardChevronContainer>
                    <FCThumbnails stats={this.state.stats} />
                  </div>
                );
              } else {
                return (
                  <CardChevronContainer>
                    <div style={{ display: "inline-block" }}>Data Cards</div>
                    <ClosedChevron onClick={this.toggleCards}>
                      <IoIosArrowDown />
                    </ClosedChevron>
                  </CardChevronContainer>
                );
              }
            }
          })()}

          <div style={{ clear: "both" }}></div>
          
          <div style={{float: "left"}}>
            {(() => {
              if (Store.getStoreData("role") !== "view_only_manager") {
                return (
                  <SubmitButton id="assign-fck-btn" onClick={this.showInitFileReviewModal(0)}>
                    Assign File Review
                  </SubmitButton>
                );
              }
            })()}
          </div>

          <div style={{float: "left"}}>
              {
                  (() => {
                      if (Store.getStoreData('module_config').general.bulk_assign_from_spreadsheet === true) {
                          if (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'manager' || Store.getStoreData('role') === 'senior_manager') {
                            return(<SubmitButton id="assign-bulk-btn" onClick={this.showBulkModal}>Bulk Assign Reviews</SubmitButton>);
                          }
                      }
                  })()
              }
          </div>
          <div style={{clear: "both"}}></div>

          <div style={{ clear: "both" }}></div>
          <div style={{ padding: "15px 9px 5px 9px" }}>
            <CSLTable
              tableIndex="1"
              export={true}
              custom_fields={true}
              export_file_name={"Pending_File_Reviews:"}
              add={false}
              processData={this.processPendingTasksData}
              tableRows="10"
              headerText={this.state.pending_file_reviews_header_text}
              refreshCallback={this.refreshState}
              filters={this.state.pending_filters}
              applyFilter={this.applyPendingFilter}
              clearAdvancedFilter={this.clearPendingAdvancedFilter}
            />
          </div>
          <div style={{ clear: "both" }}></div>
          <div style={{ padding: "15px 9px 5px 9px" }}>
            <CSLTable
              export={true}
              custom_fields={true}
              export_file_name={"Completed_File_Reviews"}
              add={false}
              processData={this.processCompleteTasksData}
              tableRows="10"
              headerText={this.state.completed_file_reviews_header_text}
              refreshCallback={this.refreshState}
              toggle_text="Show Completed File Reviews"
              toggleCallback={this.switchRadio}
              toggle_state={this.state.show_completed}
              filters={this.state.completed_filters}
              applyFilter={this.applyFilter}
              clearAdvancedFilter={this.clearCompletedAdvancedFilter}
            />
          </div>

          {(() => {
            if (this.state.quality_assurance_enabled) {
              return (
                <div>
                  <div style={{ paddingLeft: "9px", paddingRight: "9px" }}>
                    <CSLTable
                      export={true}
                      custom_fields={true}
                      add={false}
                      export_file_name="Pending_QA_Reviews"
                      processData={this.processPendingQATasksData}
                      tableRows="10"
                      headerText={this.state.pending_qa_reviews_header_text}
                      filters={this.state.pending_qa_filters}
                      applyFilter={this.applyPendingQaFilter}
                      clearAdvancedFilter={this.clearPendingQaAdvancedFilter}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px" }}>
                    <CSLTable
                      export={true}
                      custom_fields={true}
                      add={false}
                      export_file_name="Completed_QA_Reviews"
                      processData={this.processQACompleteTasksData}
                      tableRows="10"
                      headerText={this.state.completed_qa_reviews_header_text}
                      toggle_text="Show Completed QA Reviews"
                      toggleCallback={this.toggleQa}
                      toggle_state={this.state.show_completed_qa}
                      filters={this.state.completed_qa_filters}
                      applyFilter={this.applyCompletedQaFilter}
                      clearAdvancedFilter={this.clearCompletedQaAdvancedFilter}
                    />
                  </div>
                </div>
              );
            }
          })()}

          <div style={{ clear: "both" }}></div>
          {(() => {
            if (Store.getStoreData("role") !== "view_only_manager") {
              return (
                <div style={{ paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px", padding: "5px" }}>
                  {(() => {
                    if (this.state.modal_view === "view_followup_modal") {
                      return (
                        <div style={{ position: "absolute", left: "10%", width: "100%", height: "1186px", top: this.props.show_task === true ? "10vh" : "auto" }}>
                          <InactiveOverlay style={{ height: "2384px" }} />
                          <FollowupModal closeModal={this.closeReportmodal} />
                        </div>
                      );
                    }
                  })()}
                  <CSLTable
                    export={true}
                    export_file_name={"followup_tasks"}
                    add={false}
                    processData={this.processPendingFollowupTasksData}
                    tableRows="10"
                    headerText={this.state.pending_followup_tasks_header_text}
                    refreshCallback={this.refreshState}
                    filters={this.state.pending_followup_filters}
                    applyFilter={this.applyPendingFollowupFilter}
                    clearAdvancedFilter={this.clearPendingFollowupAdvancedFilter}
                  />
                </div>
              );
            }
          })()}
          <div style={{ clear: "both" }}></div>
          {(() => {
            if (Store.getStoreData("role") !== "view_only_manager") {
              return (
                <div style={{ paddingLeft: "9px", paddingRight: "9px", paddingBottom: "20px" }}>
                  <CSLTable
                    export={true}
                    add={false}
                    export_file_name="Completed_followup_tasks"
                    processData={this.processCompletedFollowupTasksData}
                    tableRows="10"
                    headerText={this.state.completed_followup_tasks_header_text}
                    toggle_text="Show Completed Followup Tasks"
                    toggleCallback={this.toggleCompletedFollowup}
                    toggle_state={this.state.show_completed_folloup}
                    filters={this.state.completed_followup_filters}
                    applyFilter={this.applyCompletedFollowupFilter}
                    clearAdvancedFilter={this.clearCompletedFollowupAdvancedFilter}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (this.state.modal_view !== null) {
              switch (this.state.modal_view) {
                case "select_filereview_modal":
                  return (
                    <div style={{ position: "absolute", left: "10%", top: "50px", width: "100%", height: "1186px" }}>
                      <InactiveOverlay />
                      <AssignFileCheckModal refreshParent={this.refreshState} />
                    </div>
                  );
                case "view_report_modal":
                  return (
                    <div style={{ position: "absolute", left: "10%", top: "50px", width: "100%", height: "1186px" }}>
                      <InactiveOverlay style={{ height: "2384px" }} />
                      <ReportModal closeModal={this.closeReportmodal} openPrintView={this.openPdfPrintView} comp_fc_autofail_result = {this.state.comp_fc_autofail_result} />
                    </div>
                  );
                case 'bulk_assign': return <BulkModal closeModal={this.closeBulkmodal} attachFile={this.attachFile} goNext={this.goNext} />;
                case 'bulk_preview': return <BulkPreview data={this.state.bulk_data} closeModal={this.closeBulkmodal} submitBulk={this.submitBulk} fields_missing={this.state.fields_missing} scrollToTop={this.scrollToTop} related_individual_text={this.state.related_individual_text}/>;
                default: return <div></div>;
                // case 'view_assign_modal': return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay style={{height: "2384px"}} /><AssignView closeModal={this.closeReportmodal} changeCurView={this.changeCurView} parentview="manager_index" /></div>; break;
              }
            }
          })()}
        </div>
      );
    }
    if (this.state.page_view === "answerview") {
      return <AnswerView closeAnswerView={this.closeAnswerView} changeCurView={this.changeCurView} parentview="manager_index" setBreadcrumbLastText={this.props.setBreadcrumbLastText} />;
    } else if (this.state.page_view === "assignview") {
      return (
        <div style={{ marginLeft: "10px" }}>
          <AssignFileCheck task={this.state.task} changeCurView={this.changeCurView} parentview="manager_index" />
        </div>
      );
    } else if (this.state.page_view === "qcview") {
      return <QcView closeQcView={this.closeQcView} changeCurView={this.changeCurView} parentview="manager_index" showPage={this.showPage} />;
    } else if (this.state.page_view === "review") {
      return <AnswerView closeAnswerView={this.closeAnswerView} changeCurView={this.changeCurView} parentview="manager_index" setBreadcrumbLastText={this.props.setBreadcrumbLastText} readonly={true} showPage={this.showPage} />;
    } else if (this.state.page_view === "editcomp") {
      return <AnswerView closeAnswerView={this.closeAnswerView} changeCurView={this.changeCurView} parentview="manager_index" setBreadcrumbLastText={this.props.setBreadcrumbLastText} editcomp={true} showPage={this.showPage} />;
    } else if (this.state.page_view === "assignview") {
      return <AssignFileCheck task={this.state.task} changeCurView={this.changeCurView} parentview="manager_index" />;
    }
  }
}
export default MGRIndex;
