import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaTrashAlt} from 'react-icons/fa';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 60%;
    top: 13%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 16px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
    cursor: pointer;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;

const AnswerHr = styled.div`
    height: 1px;
    background-color: #d9d9d9;
    margin-top: 15px;
`;

const SerialNo = styled.div`
	font-weight: 700;
	font-size: 11px;
	color: #7f7f7f;
	// display: inline-block;
	float: left;
	width: 5%
	box-sizing: border-box;
`;

const QuestionTxt = styled.div`
	font-size: 12px;
	color: #666666;
	text-align: justify;
	width: 100%;
	box-sizing: border-box;
`;

const AnswerTextArea = styled.textarea`
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 100%;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
`;
const AnswerTextbox = styled.input`
    height: 25px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-family: 'Montserrat', sans-serif;
`;
const Dropdown = styled.select`
    height: 35px;
    width: 20%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #979797;
    background-color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
`;
const CommentsLabel = styled.div`
	font-size: 12px;
	color: #666666;
	font-weight: 600;
`;
const Hardcoded = styled.div`
    background-color: #EDF0F1;
    border: 1px solid #c6cfd2;
    border-radius: 5px;
    padding: 15px;
`;
const Dsiputetext = styled.div`
    background-color: #F6DBDB;
    border: 1px solid #c6cfd2;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
`;
const QuestionLabel = styled.div`
	font-size: 12px;
    color: #666666;
    font-weight: 600;
    padding-top: 10px;
`;
const Question = styled.div`
	font-size: 12px;
	color: #666666;
	text-align: justify;
	width: 100%;
	box-sizing: border-box;
`;
const AnswerLabel = styled.div`
	font-size: 12px;
    color: #666666;
    font-weight: 600;
    padding-top: 10px;
`;
const Answer = styled.div`
	font-size: 12px;
	color: #666666;
	text-align: justify;
	width: 100%;
	box-sizing: border-box;
`;
const ScoreLabel = styled.div`
	font-size: 12px;
    color: #666666;
    font-weight: 600;
    padding-top: 10px;
`;
const Score = styled.div`
	font-size: 12px;
    color: #666666;
    font-weight: 600;
    padding-top: 10px;
`;

class QANewModal extends React.Component
{
	state = {question: null};

	componentDidMount()
	{
        // console.log("question:", this.props.question);
		this.setState({question: this.props.question});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({question: this.props.question});
		}
	}

	render()
	{
        console.log("ques:", this.state.question);
		return (
			<MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>New</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.props.toggleModal(null)}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>
                <AnswerHr />
                <MRModalBody>
                    {
                    	(() => {
                    		if (this.state.question === null) {
                    			return (<div>Loading...</div>);
                    		} else {
                    			return (
                    				<div>
                    					<QuestionLabel>Question</QuestionLabel>
	                    				<Question>{this.state.question.question.replace(/'+/g,"'")}</Question>
	                    				<AnswerLabel>Answer</AnswerLabel>
	                    				<Answer>{this.props.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled ? this.state.question.qa_data.answer.text : this.state.question.qa_non_score_data.answer}</Answer>
	                    				<ScoreLabel>{this.props.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled ? "Score" : "Label"}</ScoreLabel>
	                    				<Score>{this.props.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled ? this.state.question.qa_data.obtained_score : this.state.question.qa_non_score_data.label}</Score>
                                        <AnswerLabel>Comments</AnswerLabel>
                                        <Answer>{this.state.question.qa_data.comments}</Answer>
                                    </div>
                    			);
                    		}
                    	})()
                    }
                </MRModalBody>

                <MRModalFooter>
                    
                </MRModalFooter>
            </MRModalContainer>
		);
	}
}

export default QANewModal;