let pill_filters = {
          All: {name: 'All', display: 'All', is_active: 1, is_visible: true, count: 0},
          Pending: {name: 'Pending', display: 'Pending', is_active: 0, is_visible: true, count: 0},
          Inprogress: {name: 'Inprogress', display: 'In Progress', is_active: 0, is_visible: true, count: 0},
          Completed: {name: 'Completed', display: 'Completed', is_active: 0, is_visible: true, count: 0},
          Indeterminate: {name: 'Indeterminate', display: 'Indeterminate', is_active: 0, is_visible: true, count: 0},
          Compliant: {name: 'Compliant', display: 'Passed', is_active: 0, is_visible: true, count: 0},
          NonCompliant: {name: 'NonCompliant', display: 'Failed', is_active: 0, is_visible: true, count: 0},
          Awaiting: {name: 'Awaiting', display: 'For Approval', is_active: 0, is_visible: true, count: 0},
          InQaReview: {name: 'InQaReview', display: 'In QA Review', is_active: 0, is_visible: true, count: 0},
          QaReviewed: {name: 'QaReviewed', display: 'QA Reviewed', is_active: 0, is_visible: true, count: 0}
}

module.exports = pill_filters
