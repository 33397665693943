import React from "react";
import moment from 'moment';
import styled from "styled-components";
import { TiArrowBack } from "react-icons/ti";
import * as Style from "../Common/StyledComponents";
import WelcomeBanner from "../Common/WelcomeBanner";
import Store from "../../Common/Store";
import APICall from "../../Common/APICall.js";
import CSLTable from "./../Common/CSLTable";
import FileCheckModal from "../FC/FileCheckModal";
import ReportModal from "../MGR/ReportModal";
import AssignQcModal from "../MGR/AssignQcModal";
import AnswerView from "../Common/AnswerView";
import QcView from "../Common/QcView";
import RegisterHeader from "./RegisterHeader";
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import RDRHOC from './RDR/RDRHOC';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #01b0a2;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 100px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
  margin-right: 3px;
`;
const FilterButton = styled.button`
  box-sizing: border-box;
  background-color: #eef2f7;
  color: #243e57;
  border: 1px solid #243e57;
  width: 130px;
  font-size: 13px;
  height: 30px;
  border-radius: 100px / 70px;
  margin-right: 3px;
  margin-top: 7px;
  outline: none;
`;
const CheckBox = (props) => <input type="checkbox" />;

const Overdue = styled.span`
  height: 15px;
  width: 15px;
  background-color: #ff0000;
  position: absolute;
  opacity: 1;
`;

const Pending = styled.span`
  height: 15px;
  width: 15px;
  background-color: #5656aa;
  position: absolute;
  opacity: 1;
`;

const Complete = styled.span`
  height: 15px;
  width: 15px;
  background-color: #00ff00;
  position: absolute;
  opacity: 1;
`;

const InProgress = styled.span`
  height: 15px;
  width: 15px;
  background-color: #ffd11a;
  position: absolute;
  opacity: 1;
`;

const Checkmark = styled.span`
  content: "\2713";
`;

const ReportButton = styled.button`
  background-color: #213e61;
  border: 1px solid #213e61;
  border-radius: 5px;
  color: #ffffff;
  padding: 8px 25px;
`;

var taskListFiltered = {};

const sourceMap = { filechecker: "File Checker", car: "Compliance Advice Register", rcm: "Regulatory Change Management" };
const completeMap = { COMPLETE: 1, COMPLETED: 1, DISMISSED: 1, REJECTED: 1, DISSMISS: 1, REJECT: 1 };
const statusMap = { Pending: <Pending />, Complete: <Complete />, Overdue: <Overdue /> };

class RegisterIndex extends React.Component {
  state = {
    filter: {
      All: 1,
      Completed: 0,
      Indeterminate: 0,
      Compliant: 0,
      NonCompliant: 0,
      Pending: 0,
      Inprogress: 0,
      Awaiting: 0,
      InQaReview: 0,
      QaReviewed: 0,
    },
    ready: false,
    qc_checked: {},
    qc_checked_company: {},
    task_list: null,
    role: null,
    page_view: "register_index",
    quality_assurance_enabled: true,
    prefered_columns: {},
    view: "register",
    showTaskModal: false,
    cur_lane: null,
    company_id: null,
    task_id: null,
  };
  setView = (event) => {
    event.preventDefault();
    this.props.refreshParentState(Store.getStoreData("prev_view"));
    Store.updateStore("is_register", false);
  };
  processIndex = (result) => {
    let prefered_columns = {};
    // console.log("Result========>",result.result);
    console.log("Role:", Store.getStoreData("role"));
    // let completed_count = 0;
    if (!Store.getStoreData("is_admin")) {
      console.log("result:", result);
      let filter = this.state.filter;
      let resp = null;
      switch (Store.getStoreData("role")) {
        case "admin_manager":
          resp = this.listAdminManagerTasks(result);
          break;
        case "manager":
        case "senior_manager":
        case "view_only_manager":
          resp = this.listCommonManagerTasks(result);
          break;
        case "qa_manager":
          resp = this.listQAManagerTasks(result);
          break;
        case "qa_reviewer":
          resp = this.listQAReviewerTasks(result);
          break;
        case "file_checker":
          resp = this.listFileCheckerTasks(result);
          break;
        default:
          break;
      }
      Store.updateStore("taskdata", resp.taskdata);
      Store.updateStore("all_count", resp.all_count);
      Store.updateStore("pending_count", resp.pending_count);
      Store.updateStore("inprogress_count", resp.inprogress_count);
      Store.updateStore("completed_count", resp.completed_count);
      Store.updateStore("indeterminate_count", resp.indeterminate_count);
      Store.updateStore("compliant_count", resp.compliant_count);
      Store.updateStore("noncompliant_count", resp.noncompliant_count);
      Store.updateStore("awaiting_count", resp.awaiting_count);
      const checkarr = resp.checkarr;
      const taskdata = resp.taskdata;
      let quality_assurance_enabled = this.state.quality_assurance_enabled;
      let module_config = Store.getStoreData("module_config");
      if (Object.keys(module_config).length > 0) {
        quality_assurance_enabled = module_config.general.quality_assurance_enabled;
      }
      if (Store.getStoreData("parent_has_group_companies") === true) {
        prefered_columns = {
          status: "Status",
          file_type: "File Type",
          company_name: "Company",
          reviewer: "Reviewer",
          reviewee: "Reviewee",
          customer: "Customer",
          completed_date: "Scheduled Date",
          review_date: "Review Date",
          score: "Score",
          result: "Result",
        };
      } else {
        prefered_columns = {
          status: "Status",
          file_type: "File Type",
          reviewer: "Reviewer",
          reviewee: "Reviewee",
          customer: "Customer",
          completed_date: "Scheduled Date",
          review_date: "Review Date",
          score: "Score",
          result: "Result",
        };
      }
      // Store.updateStore('registered_column', prefered_columns)
      this.setState({ filter, ready: true, prefered_columns: prefered_columns, qc_checked: checkarr, role: Store.getStoreData("role"), task_list: taskdata, quality_assurance_enabled });
      // this.setState({filter, ready: true, prefered_columns: prefered_columns, qc_checked: checkarr, role: Store.getStoreData("role"), task_list: result.result.rows, quality_assurance_enabled});
    }
  };

  listAdminManagerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_lane === "COMP_FCK_ASSIGNMENT" || result[i].cur_lane === "COMP_FCK_CREATE" || result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0 } };
          pending_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_SAVED") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 1, Awaiting: 0 } };
          inprogress_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 1 } };
          awaiting_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
          if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
            if (task_json.object_data.result.outcome === "Pass") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              compliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Fail") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              noncompliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Indeterminate") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              indeterminate_count += 1;
            }
          } else {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
          }
          completed_count += 1;
          if (result[i].cur_lane === "COMP_FCK_COMPLETE") {
            checkarr[result[i].id.toString()] = false;
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listCommonManagerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_lane === "COMP_FCK_ASSIGNMENT" || result[i].cur_lane === "COMP_FCK_CREATE" || result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0 } };
          pending_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_SAVED") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 1, Awaiting: 0 } };
          inprogress_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 1 } };
          awaiting_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
          if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
            if (task_json.object_data.result.outcome === "Pass") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              compliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Fail") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              noncompliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Indeterminate") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              indeterminate_count += 1;
            }
          } else {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
          }
          completed_count += 1;
          if (result[i].cur_lane === "COMP_FCK_COMPLETE") {
            checkarr[result[i].id.toString()] = false;
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listQAManagerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0 } };
          pending_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
          if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
            if (task_json.object_data.result.outcome === "Pass") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              compliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Fail") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              noncompliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Indeterminate") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
              indeterminate_count += 1;
            }
          } else {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
          }
          completed_count += 1;
          if (result[i].cur_lane === "COMP_FCK_COMPLETE") {
            checkarr[result[i].id.toString()] = false;
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listQAReviewerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_assigned_to === Store.getStoreData("loggedin_user_info").ID) {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0 } };
          pending_count += 1;
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listFileCheckerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_lane === "COMP_FCK_ASSIGNMENT" || result[i].cur_lane === "COMP_FCK_CREATE") {
          if (result[i].cur_assigned_to === Store.getStoreData("loggedin_user_info").ID) {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0 } };
            pending_count += 1;
          }
        }
        if (result[i].cur_lane === "COMP_FCK_SAVED") {
          if (result[i].cur_assigned_to === Store.getStoreData("loggedin_user_info").ID) {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 1, Awaiting: 0 } };
            inprogress_count += 1;
          }
        }
        if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
          if (parseInt(task_json.object_data.headerinfo.reviewer_id) === Store.getStoreData("loggedin_user_info").ID) {
            if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
              if (task_json.object_data.result.outcome === "Pass") {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
                compliant_count += 1;
              }
              if (task_json.object_data.result.outcome === "Fail") {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0 } };
                noncompliant_count += 1;
              }
              if (task_json.object_data.result.outcome === "Indeterminate") {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
                indeterminate_count += 1;
              }
            } else {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0 } };
            }
            completed_count += 1;
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  componentDidMount() {
    // console.log('this.props.taskId',this.props.taskId);
    if (Store.getStoreData("role") === "team") {
      window.location.replace("/");
    } else {
      let postData = { command: "get_filecheck_registry"};
      let api = new APICall();
      api.command(postData, this.processIndex);
    }
  }
  refreshState = (modal_view) => {
    this.setState({ view_count: this.state.view_count + 1, modal_view: modal_view });
  };
  closeReportmodal = () => {
    this.setState({ modal_view: null });
  };

  getTaskTitle = (row) => {
    let task_json = JSON.parse(row.task_json);

    let contacts = Store.getStoreData("contacts");
    let task_title = "";
    switch (row.module_id) {
      case "filechecker":
        task_title = "headerinfo" in task_json.object_data && "review_json" in task_json.object_data.headerinfo && "general" in task_json.object_data.headerinfo.review_json ? task_json.object_data.headerinfo.review_json.general.name.replace(/'+/g, "'") : "-";
        break;
    }
    return task_title;
  };

  getStatus = (row) => {
    let lane_parts = row.cur_lane.split("_");
    let lane_end = lane_parts[lane_parts.length - 1];
    let status = "Pending";
    if (lane_end in completeMap) {
      status = "Complete";
    } else {
      let due_date = new Date(row.due_date);
      let cur_date = new Date();
      let cur_date_int = cur_date.getFullYear() * 10000 + cur_date.getMonth() * 100 + cur_date.getDate();
      let due_date_int = due_date.getFullYear() * 10000 + due_date.getMonth() * 100 + due_date.getDate();
      if (due_date_int < cur_date_int) status = "Overdue";
    }
    status = row.cur_lane === "COMP_FCK_AWAITING_APPROVAL" ? "Awaiting Approval" : status;
    status = row.cur_lane === "COMP_FCK_SAVED" ? "In Progress" : status;
    return status;
  };

  getDate = (due_date) => {
    let dateStr = due_date.split("T")[0];
    let dateParts = dateStr.split("-");
    let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    return ret;
  };

  getInclusion = (filter) => {
    // console.log("filter in getInclusion:", filter);
    // console.log("filter in state:", this.state.filter);
    let count = 0;
    let keys = Object.keys(filter);
    for (let i = 0; i < keys.length; i++) {
      // console.log("from filter:", filter[keys[i]]);
      // console.log("state filter:", this.state.filter[keys[i]]);
      count += parseInt(filter[keys[i]]) * parseInt(this.state.filter[keys[i]]);
    }
    // return true;
    // console.log("count in inclusion:", count);
    return count > 0;
  };

  getGroupCompanies = (company_id) => {
    var group_company_id = parseInt(company_id) % 100000;
    let group_company_name = "";
    let gc_companies = Store.getStoreData("gc_companies");
    let grp = {};
    if (gc_companies.length > 0) {
      for (var k = 0; k < gc_companies.length; k++) {
        grp[gc_companies[k].id] = gc_companies[k].company_name;
      }
    }
    group_company_name = grp[group_company_id];
    return group_company_name;
  };

  handleQcCheck = (task_id, company_id) => (event) => {
    event.preventDefault();
    let { qc_checked, qc_length, qc_arr, qc_checked_company } = this.state;
    qc_checked[task_id] = qc_checked[task_id] ? false : true;
    if (qc_checked[task_id]) {
      qc_checked_company[task_id] = company_id;
    } else {
      delete qc_checked_company[task_id];
    }
    this.setState({ qc_checked, qc_checked_company });
  };

  processAcceptanceQueueData = (Data) => {
    let taskData = Store.getStoreData("taskdata");
    let contacts = Store.getStoreData("contacts");
    let contact = Store.getStoreData("loggedincontact");
    const gc_assoc = Store.getStoreData("gc_assoc");

    const has_gc = Store.getStoreData("parent_has_group_companies");
    // console.log("contact:", contact);
    // console.log("taskData:", taskData);
    // let filtered_taskData = {};
    // if (Store.getStoreData("has_gc") === true) {
    //   for (let key in taskData) {
    //     if (taskData[key.toString()].row.company_id !== Store.getStoreData("parent_company_id")) {
    //       filtered_taskData[key] = taskData[key];
    //     }
    //   }
    // } else {
    //   filtered_taskData = taskData;
    // }
    // taskData = JSON.parse(JSON.stringify(filtered_taskData));
    var task_arr = [];
    var filter_arr = [];
    for (var k = 0; k < Object.keys(taskData).length; k++) {
      task_arr.push(taskData[Object.keys(taskData)[k]].row);
      filter_arr.push(taskData[Object.keys(taskData)[k]].filter);
    }

    // console.log("task_arr===>",task_arr)
    let ret = { data: [], columns: [] };
    let i = 1;
    let prefered_columns = {};
    // console.log("filter_arr==>",filter_arr)
    if (has_gc) {
      ret.columns = [
        {
          Header: "Status",
          accessor: "status",
          Cell: (row) => (
            <div>
              {(() => {
                // console.log("row.original.status", row.original.status)
                switch (row.original.status) {
                  case "Overdue":
                    return (
                      <Overdue>
                        <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                      </Overdue>
                    );
                    break;
                  case "Complete":
                    return (
                      <Complete>
                        <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                      </Complete>
                    );
                    break;
                  case "In Progress":
                    return (
                      <InProgress>
                        <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                      </InProgress>
                    );
                    break;
                  default:
                    return (
                      <Pending>
                        <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                      </Pending>
                    );
                }
              })()}
            </div>
          ),
          width: 120,
          headerStyle: { textAlign: "left" },
          filterMethod: (filter, row) => {
            return row._original.status.toLowerCase().startsWith(filter.value.toLowerCase());
          },
          sortMethod: (a, b) => {
            return a > b ? 1 : -1;
          },
        },
        { Header: "File Type", accessor: "file_type", minWidth: 170, headerStyle: { textAlign: "left" } },
        { Header: "Company", accessor: "company_name", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Customer", accessor: "customer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Review Date",
          accessor: "review_date",
          minWidth: 100,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        // {
        //   Header: 'Result',
        //   accessor: 'score_result',
        //   minWidth: 160,
        //   headerStyle: {textAlign: 'left'},
        //   Cell: row => (
        //     <div>
        //       {
        //         (() => {
        //           // console.log("Cell data:", row);
        //           if (row.original.score_result === "N/A") {
        //             return "N/A";
        //           } else {
        //             if (row.original.score_result.scoring_enabled === true) {
        //               return (
        //                 <div>
        //                   <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor}}></div>
        //                   <div style={{float: "left", paddingLeft: "5px"}}>{Math.round(row.original.score_result.scoresobtained)}%</div>
        //                   <div style={{float: "left", paddingLeft: "2px"}}>({row.original.score_result.autofailed === true ? "Auto-fail" : row.original.score_result.outcome})</div>
        //                   <div style={{clear: "both"}}></div>
        //                 </div>
        //               );
        //             } else {
        //               return (
        //                 <div>
        //                   <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor}}></div>
        //                   <div style={{float: "left", paddingLeft: "5px"}}>{row.original.score_result.ragratingname}</div>
        //                   <div style={{clear: "both"}}></div>
        //                 </div>
        //               );
        //             }
        //           }
        //         })()
        //       }
        //     </div>
        //   )
        // },
        {
          Header: "Action",
          Cell: (row) => (
            <div>
              {(() => {
                if (this.state.role !== "view_only_manager") {
                  return <MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>;
                } else {
                  if (this.state.role === "view_only_manager" && row.original.status === "Complete") {
                    return <MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>;
                  }
                }
              })()}
            </div>
          ),
          filterable: false,
          width: 120,
          headerStyle: { textAlign: "center" },
        },
      ];
    } else {
      ret.columns = [
        {
          Header: "Status",
          Cell: (row) => (
            <div>
              {(() => {
                // console.log("row.original.status", row.original.status)
                switch (row.original.status) {
                  case "Overdue":
                    return (
                      <Overdue>
                        <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                      </Overdue>
                    );
                    break;
                  case "Complete":
                    return (
                      <Complete>
                        <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                      </Complete>
                    );
                    break;
                  default:
                    return (
                      <Pending>
                        <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                      </Pending>
                    );
                }
              })()}
            </div>
          ),
          width: 120,
          headerStyle: { textAlign: "left" },
        },
        { Header: "File Type", accessor: "file_type", minWidth: 170, headerStyle: { textAlign: "left" } },
        { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
        { Header: "Customer", accessor: "customer", minWidth: 120, headerStyle: { textAlign: "left" } },
        {
          Header: "Review Date",
          accessor: "review_date",
          minWidth: 100,
          headerStyle: { textAlign: "left" },
          sortMethod: (a, b) => {
            if (a === b) {
              return 0;
            }
            let adue = a.split("/");
            const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
            let bbdue = b.split("/");
            const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
            return aReverse > bReverse ? 1 : -1;
          },
        },
        // {
        //   Header: 'Result',
        //   accessor: 'score_result',
        //   minWidth: 160,
        //   headerStyle: {textAlign: 'left'},
        //   Cell: row => (
        //     <div>
        //       {
        //         (() => {
        //           // console.log("Cell data:", row);
        //           if (row.original.score_result === "N/A") {
        //             return "N/A";
        //           } else {
        //             if (row.original.score_result.scoring_enabled === true) {
        //               return (
        //                 <div>
        //                   <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor}}></div>
        //                   <div style={{float: "left", paddingLeft: "5px"}}>{Math.round(row.original.score_result.scoresobtained)}%</div>
        //                   <div style={{float: "left", paddingLeft: "2px"}}>({row.original.score_result.autofailed === true ? "Auto-fail" : row.original.score_result.outcome})</div>
        //                   <div style={{clear: "both"}}></div>
        //                 </div>
        //               );
        //             } else {
        //               return (
        //                 <div>
        //                   <div style={{float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor}}></div>
        //                   <div style={{float: "left", paddingLeft: "5px"}}>{row.original.score_result.ragratingname}</div>
        //                   <div style={{clear: "both"}}></div>
        //                 </div>
        //               );
        //             }
        //           }
        //         })()
        //       }
        //     </div>
        //   )
        // },
        {
          Header: "Action",
          filterable: false,
          sortable: false,
          Cell: (row) => (
            <div>
              {(() => {
                if (this.state.role !== "view_only_manager") {
                  return <MRButton onClick={this.goToTaskUrl(row.original.id, row.original.company_id, row.original.cur_lane)}>View</MRButton>;
                } else {
                  if (this.state.role === "view_only_manager" && row.original.status === "Complete") {
                    return <MRButton onClick={this.goToTaskUrl(row.original.id, row.original.company_id, row.original.cur_lane)}>View</MRButton>;
                  }
                }
              })()}
            </div>
          ),
          width: 120,
          headerStyle: { textAlign: "center" },
        },
      ];
    }
    if (this.state.quality_assurance_enabled && (this.state.role === "qa_manager" || this.state.role === "senior_manager" || this.state.role === "admin_manager")) {
      ret.columns.push({
        Header: "Send For QA",
        Cell: (row) => (
          <div>
            {(() => {
              if (row.original.cur_lane === "COMP_FCK_COMPLETE") {
                // return (<MRButton onClick={this.goToTaskUrl(row.original.id)}>Check</MRButton>);
                if (this.state.qc_checked[row.original.id]) {
                  return (
                    <div style={{ cursor: "pointer", fontSize: "20px" }} onClick={this.handleQcCheck(row.original.id, row.original.company_id)}>
                      <IoIosCheckboxOutline />
                    </div>
                  );
                } else {
                  return (
                    <div style={{ cursor: "pointer", fontSize: "20px" }} onClick={this.handleQcCheck(row.original.id, row.original.company_id)}>
                      <IoIosSquareOutline />
                    </div>
                  );
                }
              }
            })()}
          </div>
        ),
        filterable: false,
        width: 120,
        headerStyle: { textAlign: "left" },
      });
    }
    // console.log("task_arr:", task_arr);
    let to_date = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();
    if("to_date" in this.state.filters.review_date.selections){
        //console.log("to_date selections::",this.state.filters.review_date.selections.to_date)
        let dt_split = this.state.filters.review_date.selections.to_date.split('/');
        let frmt_dt = dt_split[2]+'-'+dt_split[1]+'-'+dt_split[0]
        to_date = moment(frmt_dt).unix();
    }

    for (var j = 0; j < task_arr.length; j++) {
    if(task_arr[j].due_date <= to_date){
	      // console.log("single item:", task_arr[j]);
	      let contacts = Store.getStoreData("users");
	      let task_json = JSON.parse(task_arr[j].task_json);
	      // console.log("task_json", task_json )
	      let CompanyName = "";
	      if (parseInt(task_arr[j].company_id) < 100000) {
	        CompanyName = Store.getStoreData("parent_company").company_name;
	      } else {
	        const calc_company_id = parseInt(task_arr[j].company_id) % 100000;
	        CompanyName = gc_assoc[calc_company_id.toString()].company_name;
	        // CompanyName =this.getGroupCompanies(task_arr[j].company_id)
	      }
	      let review_date = this.getDate(task_arr[j].due_date);
	      if (task_arr[j].cur_lane === "COMP_FCK_COMPLETE" || task_arr[j].cur_lane === "COMP_FCK_QC_COMPLETE") {
	        review_date = this.getDate(task_arr[j].completed_date);
	      }

	      let status = this.getStatus(task_arr[j]);
	      let reviewer = task_json.object_data.headerinfo.reviewer_id in contacts ? contacts[task_json.object_data.headerinfo.reviewer_id.toString()].ContactName : "--";
	      let reviewee = task_json.object_data.headerinfo.reviewee_id in contacts ? contacts[task_json.object_data.headerinfo.reviewee_id.toString()].ContactName : "--";
	      let customer = "headerinfo" in task_json.object_data ? task_json.object_data.headerinfo.customer_name.replace(/'+/g, "'") : "--";
	      let score = "--";
	      if ("result" in task_json.object_data) {
	        if ("scoresobtained" in task_json.object_data.result) {
	          if (task_json.object_data.result.scoresobtained != null) {
	            score = task_json.object_data.result.scoresobtained.toString();
	          }
	        }
	      }
	      // let score = task_json.object_data.result.scoresobtained != null ? task_json.object_data.result.scoresobtained.toString() : "--";
	      let result = "--";
	      if ("result" in task_json.object_data) {
	        if ("outcome" in task_json.object_data.result) {
	          if (task_json.object_data.result.outcome != null) {
	            score = task_json.object_data.result.outcome;
	          }
	        }
	      }
	      // let result = task_json.object_data.result.outcome != "" ? task_json.object_data.result.outcome : "--"
	      // console.log("this.getInclusion(filter_arr[j])",filter_arr[j])
	      let checkbox = status === "Complete" ? 1 : 0;
	      // const score_result = this.getScoreResult(task_arr[j]);
	      if (this.getInclusion(filter_arr[j])) {
	        let elem = {
	          index: j,
	          status: status,
	          file_type: this.getTaskTitle(task_arr[j]),
	          company_name: CompanyName,
	          reviewer: reviewer,
	          reviewee: reviewee,
	          customer: customer,
	          cur_lane: task_arr[j].cur_lane,
	          review_date: review_date,
	          // 'score_result' : score_result,
	          id: task_arr[j].id,
	          module_id: task_arr[j].module_id,
	          check: checkbox,
	          completed_date: task_arr[j].completed_date != null ? this.getDate(task_arr[j].completed_date) : "--",
	          score: score,
	          result: result,
	          company_id: task_arr[j].company_id,
	        };
	        ret.data.push(elem);
	      }
	  	}
    }
    console.log("ret:", ret);
    ret.data = ret.data.sort((a, b) => {
      if (a.review_date === b.review_date) {
        return 0;
      }
      let adue = a.review_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      // console.log("parseInt(adue[0]) ==>", aReverse, a.due_date);
      let bbdue = b.review_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    if (has_gc === true) {
      ret.export_columns = { file_type: "File Type", status: "Status", company_name: "Company", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date" };
    } else {
      ret.export_columns = { file_type: "File Type", status: "Status", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date" };
    }
    return ret;
  };

  getScoreResult = (task) => {
    // console.log("fired");
    let score_result = "N/A";
    let result_obj = {};
    let is_autofailed = false;
    let totalscore = 0;
    let obtained_score = 0;
    if (task.cur_lane === "COMP_FCK_COMPLETE" || task.cur_lane === "COMP_FCK_QC_COMPLETE") {
      // console.log("calculate");
      const task_json = JSON.parse(task.task_json);
      const scoring = task_json.object_data.headerinfo.review_json.scoring;
      const sections = task_json.object_data.headerinfo.review_json.sections;
      if (scoring.scoring_enabled === true) {
        sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.included === true) {
              const q_type = question.type.find((t) => t.selected === true);
              let qscore = 0;
              if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
                if (question.auto_scoring.enabled === true) {
                  if (q_type.value === "Boolean") {
                    qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
                    // console.log("qscore Boolean:", qscore);
                  }
                  if (q_type.value === "Dropdown") {
                    qscore = Math.max.apply(
                      null,
                      question.preset_answers.map((pa) => parseInt(pa.weight))
                    );
                    // console.log("qscore Dropdown:", qscore);
                  }
                } else {
                  const qscore_obj = question.weight.find((item) => item.selected === true);
                  qscore = parseInt(qscore_obj.value);
                }
              } else {
                const qscore_obj = question.weight.find((item) => item.selected === true);
                qscore = parseInt(qscore_obj.value);
              }
              totalscore = totalscore + qscore;
              const autofail_enabled = question.autofail.find((af) => af.selected === true);
              obtained_score = obtained_score + parseInt(question.obtained_score);
              if (autofail_enabled.value === "Yes") {
                if (parseInt(question.obtained_score) < qscore) {
                  is_autofailed = true;
                }
              }
            }
          });
        });
        result_obj.scoring_enabled = true;
        result_obj.scoresobtained = (obtained_score / totalscore) * 100;
        result_obj.totalscore = totalscore;
        if (is_autofailed === true) {
          const fail_obj = scoring.scores.find((sc) => sc.actual_outcome === "Fail");
          result_obj.ragratingcolor = fail_obj.color;
          result_obj.ragratingname = fail_obj.termname;
          result_obj.outcome = fail_obj.actual_outcome;
          result_obj.autofailed = true;
        } else {
          result_obj.autofailed = false;
          scoring.scores.forEach((single_score) => {
            if (result_obj.scoresobtained > single_score.lbound && result_obj.scoresobtained <= single_score.ubound) {
              result_obj.ragratingcolor = single_score.color;
              result_obj.ragratingname = single_score.termname;
              result_obj.outcome = single_score.actual_outcome;
            }
          });
        }
        score_result = result_obj;
      } else {
        result_obj.scoring_enabled = false;
        result_obj.ragratingname = task_json.object_data.non_score_assessment.result;
        result_obj.ragratingcolor = task_json.object_data.non_score_assessment.color;
        score_result = result_obj;
      }
    }
    return score_result;
  };

  processGetTask = (result) => {
    let loggedinContactId = Store.getStoreData("loggedincontact").contact_id;
    let this_task = result.result.task.parenttask;
    // console.log('result', result)
    let task_json = JSON.parse(this_task.task_json);
    let Role = Store.getStoreData("role");
    Store.updateStore("CheckModalOverlay", `${document.body.scrollHeight.toString()}px`);
    Store.updateStore("cur_review_json", task_json.object_data.headerinfo.review_json);
    Store.updateStore("cur_files_json", task_json.object_data.bin_files);
    Store.updateStore("cur_task_id", this_task.id);
    Store.updateStore("headerinfo", task_json.object_data.headerinfo);
    Store.updateStore("parent_task", this_task);
    this.setState({ modal_view: "show_filecheck_modal", ready: true });
  };

  openFileCheckerModal = (id) => {
    let tasks = Store.getStoreData("taskdata");
    const selected_task = tasks[id.toString()].row;
    // console.log("selected_task:", selected_task);
    let role = Store.getStoreData("role");
    // console.log("role", role);
    if (role === "admin_manager" || role === "manager" || role === "senior_manager") {
      Store.updateStore("fc_index", id);
      this.setState({ page_view: "answerview", ready: true });
    } else if (role === "file_checker") {
      if (selected_task.cur_lane === "COMP_FCK_COMPLETE" || selected_task.cur_lane === "COMP_FCK_QC_COMPLETE") {
        const postData = { command: "get_task", task_id: id };
        const api = new APICall();
        api.command(postData, this.forReport);
      } else {
        Store.updateStore("fc_index", id);
        this.setState({ page_view: "answerview" });
      }
    } else if (role === "qa_manager" || role === "qa_reviewer") {
      if (this.state.quality_assurance_enabled) {
        Store.updateStore("fc_index", id);
        this.setState({ page_view: "qaview" });
      } else {
        alert("Quality assurance disabled");
        return false;
      }
    } else {
      let postData = { command: "get_task", task_id: id };
      // console.log("postData", postData)
      let api = new APICall();
      api.command(postData, this.forReport);
    }
  };

  forReport = (result) => {
    // console.log("result:", result);
    Store.updateStore("report_task", result.result.task);
    Store.updateStore("report_task_json", JSON.parse(result.result.task.parenttask.task_json));
    Store.updateStore("cur_task_id", result.result.task.parenttask.id);
    this.setState({ modal_view: "view_report_modal" });
  };

  processGetTask_forReport = (result) => {
    // console.log("Report1:", result);
    let this_task = result.result.task.parenttask;
    let task_json = JSON.parse(this_task.task_json);
    // let tasks = Store.getStoreData('completed_fctasks');
    // let result = tasks[index];
    document.documentElement.scrollTop = 0;
    // console.log("Report:234", task_json.object_data);
    Store.updateStore("report_task_json", task_json);
    Store.updateStore("report_task", result.result.task);
    Store.updateStore("ReportModal", `${document.body.scrollHeight.toString()}px`);
    this.setState({ modal_view: "view_report_modal", ready: true });
  };

  openReportModal = (id) => {
    // console.log("Report1:", id);
    let postData = { command: "get_task", task_id: id };
    let api = new APICall();
    api.command(postData, this.processGetTask_forReport);
  };

  goToTaskUrlBare = (id) => {
    // console.log('taskId==>', id);
    Store.updateStore("cur_task_id", id);
    let loggedinContactId = Store.getStoreData("loggedincontact").contact_id;
    let tasks = Store.getStoreData("taskdata");
    // console.log("task", tasks);
    let curAssignedto = tasks[id].row.cur_assigned_to;

    let Role = Store.getStoreData("role");

    // console.log("tasks =====>",tasks[id].row.cur_lane)
    // console.log("curAssignedto",curAssignedto)
    this.openFileCheckerModal(id);
  };

  goToTaskUrl = (id, company_id, cur_lane) => (event) => {
    event.preventDefault();
    // console.log('taskId==>', id)
    const task_url = `/${id}`;

    window.location.assign(task_url);

    // this.goToTaskUrlBare(id);
  };

  handleBtn = (event) => {
    event.preventDefault();
    console.log(event.target.name);
    let filter = this.state.filter;
    if ([event.target.name] == "Indeterminate") {
      filter.Indeterminate = filter.Indeterminate === 0 ? 1 : 0;
    }
    if ([event.target.name] == "All") {
      filter.All = !filter.All ? 1 : 0;
    }
    if ([event.target.name] == "Pending") {
      filter.Pending = filter.Pending === 0 ? 1 : 0;
    }
    if ([event.target.name] == "Inprogress") {
      filter.Inprogress = filter.Inprogress === 0 ? 1 : 0;
    }
    if ([event.target.name] == "Completed") {
      filter.Completed = filter.Completed === 0 ? 1 : 0;
    }
    if ([event.target.name] == "Compliant") {
      filter.Compliant = filter.Compliant === 0 ? 1 : 0;
    }
    if ([event.target.name] == "NonCompliant") {
      filter.NonCompliant = filter.NonCompliant === 0 ? 1 : 0;
    }
    if ([event.target.name] == "awaiting") {
      filter.Awaiting = filter.Awaiting === 0 ? 1 : 0;
    }
    this.setState({ filter });
  };

  refreshCallback = () => {
    this.props.refreshCallback();
  };

  toggleAssignQcModal = (view) => {
    console.log(this.state.qc_checked_company);
    var firstKey = Object.keys(this.state.qc_checked_company)[0];
    // console.log(firstKey)
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let { modal_view } = this.state;
    modal_view = view;
    let qc_checked = this.state.qc_checked;
    let check = false;
    for (let [key, value] of Object.entries(qc_checked)) {
      if (value) {
        check = value;
      }
    }
    if (!check) {
      alert("Please tick at least one review");
      return false;
    }
    let is_repeat = false;
    let qc_comp = this.state.qc_checked_company;
    let com_id = qc_comp[firstKey];
    for (let k in qc_comp) {
      if (qc_comp[k] !== com_id) {
        is_repeat = true;
      }
    }

    if (is_repeat) {
      alert("Please choose same companies");
      return false;
    }

    // console.log("qc", qc_checked)

    this.setState({ modal_view });
  };

  changeCurView = (view) => {
    this.setState({ page_view: view });
  };

  goToReport = () => {
    this.setState({ view: "report" });
  };

  goToRegister = () => {
    this.setState({ view: "register" });
  };

  render() {
    if (!this.state.ready) {
      return <div>Loading...</div>;
    }
    if (this.state.page_view === "answerview") {
      return <AnswerView changeCurView={this.changeCurView} parentview="register_index" />;
    } else if (this.state.page_view === "qaview") {
      return <QcView changeCurView={this.changeCurView} parentview="register_index" />;
    }
    return (
      <div style={{ backgroundColor: "#f2f2f3", position: "relative" }}>
        <RegisterHeader />
        <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData("loggedin_user_info").ContactName} />
        {(() => {
          if (this.state.view === "register") {
            return (
              <div style={{ position: "relative", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px" }}>
                {(() => {
                  if (this.state.role !== "view_only_manager") {
                    if (this.state.filter.All === 1) {
                      return (
                        <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="All" onClick={this.handleBtn}>
                          <span style={{ float: "left" }}>&#10003; </span>All<span style={{ float: "right" }}> {Store.getStoreData("all_count")} </span>
                        </FilterButton>
                      );
                    } else {
                      return (
                        <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="All" onClick={this.handleBtn}>
                          All<span style={{ float: "right" }}>{Store.getStoreData("all_count")}</span>
                        </FilterButton>
                      );
                    }
                  }
                })()}
                {(() => {
                  if (this.state.filter.Pending === 1) {
                    return (
                      <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="Pending" onClick={this.handleBtn}>
                        <span style={{ float: "left" }}>&#10003; </span>Pending<span style={{ float: "right" }}> {Store.getStoreData("pending_count")}</span>
                      </FilterButton>
                    );
                  } else {
                    return (
                      <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="Pending" onClick={this.handleBtn}>
                        Pending<span style={{ float: "right" }}> {Store.getStoreData("pending_count")}</span>
                      </FilterButton>
                    );
                  }
                })()}
                {(() => {
                  if (this.state.filter.Inprogress === 1) {
                    return (
                      <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="Inprogress" onClick={this.handleBtn}>
                        <span style={{ float: "left" }}>&#10003; </span>In Progress<span style={{ float: "right" }}>{Store.getStoreData("inprogress_count")}</span>
                      </FilterButton>
                    );
                  } else {
                    return (
                      <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="Inprogress" onClick={this.handleBtn}>
                        In Progress<span style={{ float: "right" }}> {Store.getStoreData("inprogress_count")}</span>
                      </FilterButton>
                    );
                  }
                })()}
                {(() => {
                  if (this.state.filter.Completed === 1) {
                    return (
                      <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="Completed" onClick={this.handleBtn}>
                        <span style={{ float: "left" }}>&#10003; </span>Completed<span style={{ float: "right" }}> {Store.getStoreData("completed_count")}</span>
                      </FilterButton>
                    );
                  } else {
                    return (
                      <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="Completed" onClick={this.handleBtn}>
                        Completed<span style={{ float: "right" }}> {Store.getStoreData("completed_count")}</span>
                      </FilterButton>
                    );
                  }
                })()}
                {(() => {
                  if (this.state.role !== "view_only_manager") {
                    if (this.state.filter.Indeterminate === 1) {
                      return (
                        <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="Indeterminate" onClick={this.handleBtn}>
                          <span style={{ float: "left" }}>&#10003; </span>Indeterminate<span style={{ float: "right" }}> {Store.getStoreData("indeterminate_count")}</span>
                        </FilterButton>
                      );
                    } else {
                      return (
                        <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="Indeterminate" onClick={this.handleBtn}>
                          Indeterminate<span style={{ float: "right" }}> {Store.getStoreData("indeterminate_count")}</span>
                        </FilterButton>
                      );
                    }
                  }
                })()}
                {(() => {
                  if (this.state.role !== "view_only_manager") {
                    if (this.state.filter.Compliant === 1) {
                      return (
                        <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="Compliant" onClick={this.handleBtn}>
                          <span style={{ float: "left" }}>&#10003; </span>Compliant<span style={{ float: "right" }}> {Store.getStoreData("compliant_count")}</span>
                        </FilterButton>
                      );
                    } else {
                      return (
                        <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="Compliant" onClick={this.handleBtn}>
                          Compliant<span style={{ float: "right" }}> {Store.getStoreData("compliant_count")}</span>
                        </FilterButton>
                      );
                    }
                  }
                })()}
                {(() => {
                  if (this.state.role !== "view_only_manager") {
                    if (this.state.filter.NonCompliant === 1) {
                      return (
                        <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="NonCompliant" onClick={this.handleBtn}>
                          <span style={{ float: "left" }}>&#10003; </span>NonCompliant<span style={{ float: "right" }}> {Store.getStoreData("noncompliant_count")}</span>
                        </FilterButton>
                      );
                    } else {
                      return (
                        <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="NonCompliant" onClick={this.handleBtn}>
                          NonCompliant<span style={{ float: "right" }}> {Store.getStoreData("noncompliant_count")}</span>
                        </FilterButton>
                      );
                    }
                  }
                })()}
                {(() => {
                  if (this.state.role === "manager" || this.state.role === "admin_manager" || this.state.role === "senior_manager") {
                    if (this.state.filter.Awaiting === 1) {
                      return (
                        <FilterButton style={{ backgroundColor: "#243E57", color: "#EEF2F7", marginRight: "15px" }} name="awaiting" onClick={this.handleBtn}>
                          <span style={{ float: "left" }}>&#10003; </span>Awaiting Approval<span style={{ float: "right" }}> {Store.getStoreData("awaiting_count")} </span>
                        </FilterButton>
                      );
                    } else {
                      return (
                        <FilterButton style={{ backgroundColor: "#EEF2F7", marginRight: "15px" }} name="awaiting" onClick={this.handleBtn}>
                          Awaiting Approval<span style={{ float: "right" }}>{Store.getStoreData("awaiting_count")}</span>
                        </FilterButton>
                      );
                    }
                  }
                })()}
                <CSLTable
                  export={true}
                  report={false}
                  goToReport={this.goToReport}
                  export_file_name={"Register"}
                  add={false}
                  processData={this.processAcceptanceQueueData}
                  headerText={"Filechecker Register"}
                  tableRows={10}
                  refreshCallback={this.refreshCallback}
                  qcbutton={true}
                  toggleAssignQcModal={this.toggleAssignQcModal}
                  qa_enabled={this.state.quality_assurance_enabled}
                />
                {/*<ReportButton onClick={this.goToReport}>Report</ReportButton>*/}
              </div>
            );
          } else {
            // return (<RDRHOC register_top_filters={this.state.filter} backToRegister={this.goToRegister} />);
          }
        })()}
        {(() => {
          if (this.state.modal_view !== null && this.state.view === "register") {
            switch (this.state.modal_view) {
              // case 'show_filecheck_modal': return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><FileCheckModal closeModal={this.closeReportmodal} /></div>; break;
              case "show_filecheck_modal":
                return (
                  <div style={{ position: "absolute", left: "10%", top: "50px", width: "100%", height: "1186px" }}>
                    <InactiveOverlay />
                    <AnswerView closeModal={this.closeReportmodal} />
                  </div>
                );
                break;
              case "view_report_modal":
                return (
                  <div style={{ position: "absolute", left: "10%", top: "50px", width: "100%", height: "1186px" }}>
                    <InactiveOverlay />
                    <ReportModal closeModal={this.closeReportmodal} />
                  </div>
                );
                break;
              case "assignqcmodal":
                return (
                  <div style={{ position: "absolute", left: "10%", top: "60px", width: "100%", height: "1186px" }}>
                    <InactiveOverlay />
                    <AssignQcModal closeModal={this.closeReportmodal} assignqc_tasks={this.state.qc_checked} qc_checked_company={this.state.qc_checked_company} />
                  </div>
                );
                break;
            }
          }
        })()}
      </div>
    );
  }
}

export default RegisterIndex;
