import React from 'react';
import './ReportModalHOC.css';
import ReportQuestionsHOC from './ReportQuestionsHOC';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

class ReportSectionHOC extends React.Component
{
	state = {sections: null};

	componentDidMount()
	{
		const rsections = this.restructureSections(this.props.sections);
		console.log("rsections", rsections);
		this.setState({sections: rsections});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			const rsections = this.restructureSections(this.props.sections);
			this.setState({sections: rsections});
		}
	}

	restructureSections = (sections) => {
		let qindex = 1;
		sections.forEach((section) => {
			section.collapsed = false;
			section.questions.forEach((question) => {
				question.index = qindex;
				qindex = qindex + 1;
			})
		})
		return sections;
	}

	toggleReportSection = (section_id) => (event) => {
		event.preventDefault();
		let {sections} = this.state;
		sections.forEach((section) => {
			if (section.id === section_id) {
				section.collapsed = section.collapsed ? false : true;
			}
		})
		this.setState({sections});
	}

	render()
	{
		if (this.state.sections === null) {
			return (<div>Loading...</div>);
		}

		return (
			<div style={{padding: "0px 15px"}}>
				{
					this.state.sections.map((section) => {
						if (section.collapsed) {
							return (
								<div key={section.id} className="ReportSectionHeaderContainer" onClick={this.toggleReportSection(section.id)}>
									<div style={{float: "left", padding: "1% 15px"}}>
										<div style={{color: "#ffffff"}}>{section.name.replace(/'+/g,"'")}</div>
									</div>
									<div style={{float: "right", padding: "10px 15px"}}>
										
											{
												(() => {
													if(this.props.score_enabled){
														let section_score = 0;
														section.questions.forEach((question) => {
															section_score = section_score + parseInt(question.obtained_score);
														})
														return(
															<div className="ReportSectionScore">
															   {section_score}
															</div>
															)
													}
													
												})()
											}
										
									</div>
									<div style={{float: "right", paddingTop: "1%"}}>
										<div style={{color: "#ffffff", cursor: "pointer", fontWeight: "600", fontSize: "24px"}}><IoIosArrowDown /></div>
									</div>
									<div style={{clear: "both"}}></div>
								</div>
							);
						} else {
							return (
								<div key={section.id}>
									<div className="ReportSectionHeaderContainer" onClick={this.toggleReportSection(section.id)}>
										<div style={{float: "left", padding: "1% 15px"}}>
											<div style={{color: "#ffffff"}}>{section.name.replace(/'+/g,"'")}</div>
										</div>
										<div style={{float: "right", padding: "10px 15px"}}>
											
												{
													(() => {
														if(this.props.score_enabled){
															let section_score = 0;
															section.questions.forEach((question) => {
																section_score = section_score + parseInt(question.obtained_score);
															})
															console.log("section_score",section_score)
															return(
																<div className="ReportSectionScore">
																 	{section_score}
																</div>
																)
														}
														
													})()
												}
											
										</div>
										<div style={{float: "right", paddingTop: "1%"}}>
											<div style={{color: "#ffffff", cursor: "pointer", fontWeight: "600", fontSize: "24px"}}><IoIosArrowUp /></div>
										</div>
										<div style={{clear: "both"}}></div>
									</div>
									<div style={{padding: "10px", backgroundColor: "#F0F0F0"}}>
										<ReportQuestionsHOC questions={section.questions} score_enabled = {this.props.score_enabled}/>
									</div>
								</div>
							);
						}
					})
				}
			</div>
		);
	}
}

export default ReportSectionHOC;