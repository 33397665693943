import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import AnswerDisputedModal from '../Modals/AnswerDisputedModal';
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import { Icon } from 'react-icons-kit';
import {arrows_square_check} from 'react-icons-kit/linea/arrows_square_check';
import {software_layout} from 'react-icons-kit/linea/software_layout';
import {checkSquare} from 'react-icons-kit/fa/checkSquare';
import {square} from 'react-icons-kit/fa/square';

const AnswerSectionQA = styled.div`
	background-color: #ffffff;
	padding: 20px;
	`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const HeaderText = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
`;

class AnswerQASection extends React.Component
{
	state = {reviw_json: null, active_section_id: null, section_payload: null, sections: null, score_result: null, qc_score_result: null, qa_non_score_result: null,
		scoring: null, view_count: 0, modal_view: null, active_question_id: null, question: null, index: null};
    constructor(props) {
        super(props);
    }

    refreshState = (modal_view) => {
        this.setState({view_count: this.state.view_count + 1, modal_view: modal_view});
    }

	componentDidMount()
	{
		let {reviw_json,active_section_id, section_payload, sections, score_result, qc_score_result, qa_non_score_result, scoring} = this.state;
		active_section_id = this.props.active_section_id;
		section_payload = this.props.section_payload;
		score_result = this.props.score_result;
		qc_score_result = this.props.qc_score_result;
		qa_non_score_result = this.props.qa_non_score_result;
		sections = this.props.sections;
		scoring = this.props.scoring;
		reviw_json = this.props.review_json;
		console.log("reviw_json:", reviw_json);
		console.log("sections:", this.props.sections);
		// console.log("section_payload:", section_payload);
		this.setState({reviw_json, active_section_id, section_payload, sections, score_result, qc_score_result, qa_non_score_result, scoring});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			console.log("active_section_id:", this.props.active_section_id);
			console.log("section_payload:", this.props.section_payload);
			this.setState({
				reviw_json : this.props.review_json,
				active_section_id: this.props.active_section_id,
				section_payload: this.props.section_payload,
				sections: this.props.sections,
				score_result: this.props.score_result,
				qc_score_result: this.props.qc_score_result,
				qa_non_score_result: this.props.qa_non_score_result,
				scoring: this.props.scoring
			});
		}
	}

	modifySections = (sections, s_result) => {
        this.props.modifySections(sections, s_result);
	}
	
	// saveDisputed = () => {
	// 	this.props.saveDisputed;
	// }

	ViewDispute = (question_id) => (event) => {
		event.preventDefault();
		let question = this.state.section_payload.questions[question_id];
		this.setState({"modal_view" : "dispute_answer", active_question_id: question.id, question: question, index: question_id});
	}

    processMySectionQuestion = () => {
		let questions = this.state.section_payload.questions;
		console.log("questions ===>1212", questions)
        let ret = {data: [], columns: []};
        let i = 0;
        if (this.state.scoring.scoring_enabled) {
        	ret.columns =[  {Header: '#', accessor: 'qnumber', minWidth: 20, headerStyle: {textAlign: 'left'}}, 
        	                {Header: 'Question', accessor: 'question', minWidth: 280, headerStyle: {textAlign: 'left'},
        	                	filterMethod: (filter, row) => {
				                    console.log(row);
				                    console.log(filter);
				                    return row._original.q_text.toLowerCase().includes(filter.value.toLowerCase());
				                }
        	            	}, 
        	                {Header: 'Type', accessor: 'type', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
        	                {Header: 'Auto fail', accessor: 'autofail', minWidth: 30, headerStyle: {textAlign: 'left'}}, 
        	                {Header: 'Weighting', accessor: 'weighting', minWidth: 30, headerStyle: {textAlign: 'left'}},
        	                {Header: 'Answer', accessor: 'answer', minWidth: 30, headerStyle: {textAlign: 'left'}},
        	                {'Header' : 'Action', Cell: row => (
        	                   <div>
        	                       <MRButton style={{width: '60px'}} onClick={this.ViewDispute(row.index)}>View</MRButton>
        	                   </div>
        	                ), width: 100, headerStyle: {textAlign: 'left'}}];
        } else {
        	ret.columns =[  {Header: '#', accessor: 'qnumber', minWidth: 20, headerStyle: {textAlign: 'left'}}, 
        	                {Header: 'Question', accessor: 'question', minWidth: 100, headerStyle: {textAlign: 'left'},
        	                	filterMethod: (filter, row) => {
				                    console.log(row);
				                    console.log(filter);
				                    return row._original.q_text.toLowerCase().includes(filter.value.toLowerCase());
				                }
        	            	}, 
        	                {Header: 'Type', accessor: 'type', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
        	                {Header: 'Label', accessor: 'label', minWidth: 40, headerStyle: {textAlign: 'left'}},
        	                {Header: 'Answer', accessor: 'answer', minWidth: 100, headerStyle: {textAlign: 'left'}},
        	                {'Header' : 'Action', Cell: row => (
        	                   <div>
        	                       <MRButton style={{width: '60px'}} onClick={this.ViewDispute(row.index)}>View</MRButton>
        	                   </div>
        	                ), width: 100, headerStyle: {textAlign: 'left'}}];
        }
        
        for(let q of questions) {
            i = i + 1;
            let serial_no = "Q"+(i).toString();
            const question_type = q.type.find((item) => {
                return item.selected;
            })
            const autofail = q.autofail.find((item) => {
                return item.selected;
            })
            const waight = q.weight.find((item) => {
                return item.selected;
            })
            let ans = q.answer.text === "" ? "N/A": q.answer.text;
            if (this.state.scoring.scoring_enabled === false) {
            	ans = q.qa_non_score_data.answer;
            }
            const anstxt = ans.length > 3 ? <div title = {ans}> {ans}</div> : ans;
            const sq = q.question.replace(/'+/g,"'");
            let elem = {};
            if (this.state.scoring.scoring_enabled) {
            	elem = {
            		'qnumber' : serial_no,
            		'question' : <div title={sq}>{sq}</div>,
            	    'type' : question_type.value,
            	    'autofail' : autofail.value,
            	    'weighting' : waight.value,
            	    'answer' : anstxt,
            	    'id' : q.id,
            	    'q_text': sq
            	};
            } else {
            	elem = {
            		'qnumber' : serial_no,
            		'question' : <div title={sq}>{sq}</div>,
            	    'type' : question_type.value,
            	    'label' : q.qa_non_score_data.label,
            	    'answer' : anstxt,
            	    'id' : q.id,
            	    'q_text': sq
            	};
            }
            
            ret.data.push(elem);
        }
        return ret;
	}
	
	CloseModal = () => {
		this.setState({"view_count": this.state.view_count + 1, "modal_view" : null})
		// this.setState({view_count: this.state.view_count + 1, modal_view: modal_view});
	}

	changeSectionNa = (value) => (event) => {
		event.preventDefault();
		let sections = JSON.parse(JSON.stringify(this.state.sections));
		let scoring = JSON.parse(JSON.stringify(this.state.scoring));
		let qc_score_result = JSON.parse(JSON.stringify(this.state.qc_score_result));
		console.log("qc_score_result", qc_score_result);
		let totalscore = 0;
		let scoresobtained = 0;
		sections.forEach((section) => {
			if (section.id === this.state.active_section_id) {
				section.questions.forEach((question) => {
					question.qa_na_included = value ? false : true;
				});
			}
			section.questions.forEach((question) => {
				const q_weight = question.weight.find(w => w.selected);
				totalscore = question.qa_na_included ? totalscore + parseInt(q_weight.value) : totalscore;
				question.qa_data.answer.text = question.qa_na_included ? question.qa_data.answer.text : "";
				scoresobtained = question.qa_na_included ? scoresobtained + parseInt(question.obtained_score) : scoresobtained;
			});
		});
		scoresobtained = (scoresobtained / totalscore) * 100;
        qc_score_result.scoresobtained = scoresobtained;
        qc_score_result.totalscore = totalscore;
        scoring.scores.forEach((s) => {
        	if (scoresobtained > s.lbound && scoresobtained <= s.ubound) {
        		qc_score_result.ragratingcolor = s.color;
        		qc_score_result.ragratingname = s.termname;
        		qc_score_result.outcome = s.actual_outcome;
        	}
        });
        this.props.modifySections(sections, qc_score_result);
	}

	render()
	{
		if (this.state.active_section_id === null) {
			return (<div>Loading...</div>);
		}
		console.log("this.state.qc_score_result:", this.state.qc_score_result);
		return (
			<div>
				
				{
					(() => {
						if(this.state.modal_view) {
							return (
								<div style={{top:'50px', width:'100%', height:'1186px'}}>
									<AnswerDisputedModal
										ClosedisputeView={this.CloseModal}
										modifySections={this.modifySections}
										modifyNonScoreSections={this.props.modifyNonScoreSections}
										saveDisputed={this.props.saveDisputed}
										active_section_id={this.state.active_section_id}
										sections={this.state.sections}
										section_payload={this.state.section_payload}
										active_question_id={this.state.active_question_id}
										question={this.state.question}
										qc_score_result={this.state.qc_score_result}
										qa_non_score_result={this.state.qa_non_score_result}
										scoring={this.state.scoring}
										index={this.state.index}
										readonly={this.props.readonly === undefined ? false : this.props.readonly}
									/>
								</div>
							);
						}else {
							return (
								<div>
									<AnswerSectionQA>
										<HeaderText>
											<div style={{float: "left"}}>{this.state.section_payload.name}</div>
											{
												(() => {
													let section_na_eligible = false;
													this.state.section_payload.questions.every((question) => {
														if (question.naallowed === false) {
															section_na_eligible = false;
															return false;
														} else {
															section_na_eligible = true;
															return true;
														}
													});
													console.log("section_na_eligible", section_na_eligible);
													let is_section_na = false;
													this.state.section_payload.questions.every((question) => {
														if (question.qa_na_included) {
															is_section_na = false;
															return false;
														} else {
															is_section_na = true;
															return true;
														}
													});
													console.log("is_section_na", is_section_na);
													if (section_na_eligible) {
														return (
															<div style={{float: "right"}}>
																<div style={{float: "left", fontWeight: "200", paddingTop: "2px"}}>Section N/A</div>
																<div style={{float: "left", paddingLeft: "7px", pointerEvents: this.props.readonly === false ? "auto" : "none"}}>
																	{
																		(() => {
																			if (is_section_na) return <Icon style={{cursor: "pointer"}} onClick={this.changeSectionNa(false)} icon={checkSquare} />;
																			else return <Icon style={{cursor: "pointer"}} onClick={this.changeSectionNa(true)} icon={square} />;
																		})()
																	}
																</div>
																<div style={{clear: "both"}}></div>
															</div>
														);
													}
												})()
											}
											<div style={{clear: "both"}}></div>
										</HeaderText>
									<CSLTable add={false} show_header={false} headerText={this.state.section_payload.name} processData={this.processMySectionQuestion} tableRows="10" refreshCallback={this.refreshState} />
									</AnswerSectionQA>
								</div>
							);
						}

					})()
				}
                
			</div>
		);

	}
}

export default AnswerQASection;