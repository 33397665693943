import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle} from 'react-icons/fa';
import Store from '../../Common/Store';
import QuestionAnswerForm from '../QuestionAnswerForm';
import {FiDownload} from 'react-icons/fi'
import {MdClear} from 'react-icons/md'
import { saveAs } from 'file-saver';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js'
import DatePicker from 'react-date-picker';
import APICall from '../../Common/APICall';
import Utils from '../../Common/Utils';
import TasksList from '../TasksList'
import '../MGR/ReportModal.css'

const FileContainer = styled.div`
    width: 300px;
    display: flex;
`;
const FileInfoContainer = styled.div`
    margin-left:10px;
    color: #969EA2;
    width: 220px;
    height: 70px;
`;
const MRModalSaveBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 130px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    font-size: 11px;
    margin-left: 10px;
`;

const HeaderButton = styled.div`
    margin-left:10px;
    color: #ffffff;
    width: 50px;
    height: 50px;
    float: right;
    font-size:30px;
    cursor: pointer;
    margin-top:-5px;
`;
const FileTypeContainer = styled.div`
    width: 100px;
    min-height:70px;
    height: 70px;
    background-color: #fff;
    color: #969EA2;
    font-size:22px;
    text-align:center;
    padding-top:22px;
    font-weight:800;
    border: 1px solid #AA9EA2;
`;
const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 92%;
    left: -6%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const ReportModalHRule = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #eaeaea;
    margin-top: 5px;
    margin-bottom: 5px;
`

const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalTextArea = styled.textarea`
    height: 150px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const AddResultConfigButton = styled.button`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    padding-left: 20px;
    font-weight: 600;
`;
const MultisetLayoutContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    margin-bottom: 5px;
`;
const TermDiv = styled.div`
    display: inline-block;
    box-sizing: border-box;
    width: 25%;
    height: auto;
    float: left;
`;
const TermInputDiv = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const MRModalTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const FCKCommentDiv = styled.div`
    padding-left: 20px;
    padding-right: 8px;
    color: #a0a0a0;
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-weight: 100;
`;
const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: #979797;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;
const MRModalBackBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    margin-right: 10px;
    float: right;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
const ScoreContainer = styled.div`
    background-color: #ecf0f1;
    width: 100%;
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
    margin-top: 10px;
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;
    width: 100%;
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`

const ScoreBodyContainer = styled.div`
    padding: 15px;
`
const SaveAnswersDiv = styled.div`
    position: fixed;
    padding-left: 32px;
    padding-top: 14px;
    height: 62px;
    width: 220px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    top: 75px;
    right: 10px;
`;



class FileCheckModal extends React.Component
{
    // state = {cur_task_id: 0, cur_review_json: null, comment: "", cur_files_json: null, openrag: null};
    state = {
        memo: '',
        step: "second",
        my_reviews: [],
        loggedin_user_info: {},
        reviewee_info: {},
        cur_review_id: 0,
        reviewee_id: 0,
        review_json: {},
        customer_name:'',
        reviewee_designation:'',
        file_reference:'',
        date: new Date(),
        due_date: '1970-01-01',
        sale_date: '1970-01-01',
        review_list: [],
        binFiles: [],
        curBinFiles: [],
        cur_task_id: 0,
        cur_review_json: null, 
        comment: "", 
        cur_files_json: null, 
        openrag: null,
        followup_sub: "",
        assigned_to: "",
        cur_lane: "COMP_FCK_COMPLETE",
        followup_note: "",
        due_by: new Date(),
        show_tasks_modal: false,
        qanda: null,
        cur_gc_user: []
    };
    

    changeComment = (event) => {
        event.preventDefault();
        this.setState({comment: event.target.value});
    }

    closeModal = (event) => {
        event.preventDefault();
        Store.updateStore('CheckModalOverlay', null);
        this.props.closeModal(null);
    }

    componentDidMount()
    {
        let my_reviews = Store.getStoreData('my_reviews');
        let this_task = Store.getStoreData('parent_task');
        let task_json = JSON.parse(this_task.task_json);
        let headerinfo = task_json.object_data.headerinfo;
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let review_list = Store.getStoreData('reviewee_list');
        let reviewee_id = headerinfo.reviewee_id;
        let gc_assoc = Store.getStoreData('gc_assoc')
        console.log('gc_assoc', gc_assoc);
        console.log('my_reviews', my_reviews);
        let gc_company_id = parseInt(this_task.company_id) < 100000 ? this_task.company_id : gc_assoc[(parseInt(this_task.company_id)%100000).toString()].id;
        console.log('this_task', this_task)
        console.log('gc_company_id', gc_company_id)
        let cur_gc_user = [];
        for(let gc in gc_assoc[gc_company_id].users){
            for(let item of review_list){
                if(parseInt(item.ID) === parseInt(gc)){
                    cur_gc_user.push({"ID" : gc, "contact_name": item.ContactName })
                }
            }
        }

        let qanda = 'qanda' in task_json.object_data ? task_json.object_data.qanda : null
        let comment = 'result' in task_json.object_data ? task_json.object_data.result.comment.replace(/''+/g, "'") : ''
        let cur_review_json = JSON.parse(JSON.stringify(task_json.object_data.headerinfo.review_json));
        let cur_files_json = JSON.parse(JSON.stringify(task_json.object_data.bin_files));
        let cur_task_id = this_task.id;
        let openrag = null;
        if ("openconfigs" in cur_review_json && cur_review_json.openconfigs.length !== 0) {
            openrag = {color: cur_review_json.openconfigs[0].color, termname: cur_review_json.openconfigs[0].termname};
        }
        let curBinFiles = [];
        if('bin_files' in task_json.object_data) {
            curBinFiles = task_json.object_data.bin_files;
        }
        let date = new Date();
        let due_date = new Date();
        let sale_date = new Date();

        due_date = new Date(this_task.due_date);
        if('sale_date' in task_json.task_data) {
            sale_date = new Date(task_json.task_data.sale_date);
        }

        this.setState({cur_task_id:cur_task_id , cur_review_json:cur_review_json,cur_files_json:cur_files_json,openrag:openrag,my_reviews: my_reviews,
            reviewee_id: reviewee_id, cur_review_id: headerinfo.cur_review_id, customer_name: headerinfo.customer_name, 
            due_date:due_date,sale_date:sale_date, date: date, loggedin_user_info: loggedin_user_info, 
            review_list: review_list, reviewee_designation: headerinfo.reviewee_designation, file_reference: headerinfo.file_reference ,
            curBinFiles :curBinFiles, cur_gc_user: cur_gc_user, qanda:qanda, comment: comment
         });
    }

    doFileCheck = (is_saving) => (event) => {
        event.preventDefault();
        // console.log('is_saving', is_saving)
        Store.updateStore('is_saving', is_saving)
        let header_info = Store.getStoreData('headerinfo');
        header_info.cur_review_id = this.state.cur_review_id;
        header_info.customer_name = this.state.customer_name;
        header_info.date = this.state.date;
        header_info.file_reference = this.state.file_reference;
        header_info.reviewee_designation = this.state.reviewee_designation;
        header_info.reviewee_id = this.state.reviewee_id;
        // console.log("header info ==>", header_info);
        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 

        curmonth = parseInt(this.state.sale_date.getMonth());
        currmonth = curmonth + 1;
        var saleDateStr = currmonth >= 10 ? this.state.sale_date.getFullYear()+'/'+currmonth+'/' : this.state.sale_date.getFullYear()+'/0'+currmonth+'/';
        saleDateStr += this.state.sale_date.getDate() < 10 ? '0' + this.state.sale_date.getDate() : this.state.sale_date.getDate();

        let task_data = {
            module_id: "filechecker",
            last_action: "COMP_FCK_COMPLETE",
            actiondate: Date.now(),
            company_id: Store.getStoreData('loggedin_user_info').ClientCompanyID,
            contact_id: 0,
            system_id: "1001",
            cur_lane: "COMP_FCK_COMPLETE",
            cur_assigned_to: 0,
            task_type: "COMP_FCK",
            parent_task_id: 0,
            due_date : dueDateStr, 
            sale_date : saleDateStr
        };
        let scorecolor = null;
        let scorename = null;
        let outcome = null;
        let obtainedscore = 0;
        let totalscore = 0;
        let actual_outcome = '';
        if ("openconfigs" in this.state.cur_review_json && this.state.cur_review_json.openconfigs.length !== 0) {
            scorecolor = this.state.openrag.color;
            scorename = this.state.openrag.termname;
            this.state.cur_review_json.openconfigs.forEach((item) => {
                if (item.termname === scorename) {
                    outcome = item.actual_outcome;
                }
            })
        } else {
            scorecolor = Store.getStoreData('scorecolor');
            scorename = Store.getStoreData('scorename');
            obtainedscore = Store.getStoreData('obtainedscore');
            totalscore = Store.getStoreData('totalscore');
            actual_outcome = Store.getStoreData('actual_outcome');
        }
        const object_data = {
            headerinfo: header_info,
            bin_files: [...this.state.binFiles, ...this.state.curBinFiles],
            qanda: Store.getStoreData('qanda'),
            result: {
                comment: this.state.comment,
                ragratingcolor: scorecolor,
                ragratingname: scorename,
                scoresobtained: obtainedscore,
                totalscore: totalscore,
                outcome: actual_outcome
            }
        };
        let action_data = {
            action: "COMP_FCK_COMPLETE",
            actiondate: Date.now(),
            assigned_to: 0,
            performed_by: Store.getStoreData('loggedin_user_info').ID,
            cur_lane: "COMP_FCK_COMPLETE"
        }
        if(is_saving === 1) {
            let this_user = Store.getStoreData('loggedin_user_info')
            action_data = {
                action: "COMP_FCK_FC_SAVE",
                actiondate: Date.now(),
                assigned_to: 0,
                performed_by: this_user.ID,
                cur_lane: "COMP_FCK_FC_SAVE"
            }
            task_data.last_action = 'COMP_FCK_FC_SAVE'
            task_data.cur_lane = 'COMP_FCK_FC_SAVE'
            action_data.assigned_to = this_user.ID
        }
        const task_json = {task_data, object_data, action_data};
        console.log('task_json', task_json)
        let postData = {command: "update_filecheck_task", task_id: Store.getStoreData('cur_task_id'), task_json: task_json };
        let api = new APICall();
        api.command(postData, this.processdoFCKJob);
    }

    doFollowupCreate = (event) => {
        let ref_id = Utils.genKey(12);
        // alert(store.getStoreData('current_task_id'));
        let task = {
            "task_data": { "module_id": "filechecker", "last_action": "COMP_FCK_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assigned_to, 'task_type' : 'COMP_FCK_FOLLOWUP', 'parent_task_id' : Store.getStoreData('cur_task_id'), 'cur_lane': 'COMP_FCK_FOLLOWUP_ASSIGNMENT', due_date: this.state.due_by},
            "object_data": {},
            "action_data": [{ "action": "COMP_FCK_FOLLOWUP", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_FCK_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.followup_note }]
        };
        let postData = { "command": "createfollowuptask", "task": task };
        // console.log("task follow up", task);
        let api = new APICall();
        api.command(postData, this.CreateFollowups);
    }
    CreateFollowups = (result) => {
        alert('You have successfully Create followup task.')
        window.location.reload(true);
    }

    processdoFCKJob = (result) => {
        // console.log('result', result);
        let isSaving = Store.getStoreData('is_saving')
        if(isSaving === 0) {
            console.log(result.error_msg);
            alert('You have successfully completed the File Review.')
            window.location.reload(true);
        } else {
            alert('Your File Check has been saved.')
        }
        
    }

    filesLoaded = (files) => {
        let me = Store.getStoreData('loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
            }
        }
        // console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    handleDownload = (index) => {
        let cur_file = this.state.cur_files_json[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    handleOpenRag = (event) => {
        event.preventDefault();
        let openrag = {};
        this.state.cur_review_json.openconfigs.forEach((item) => {
            if (item.termname === event.target.value) {
                openrag.termname = item.termname;
                openrag.color = item.color;
            }
        })
        this.setState({openrag});
    }

    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({[evt.target.name]: evt.target.value});
    }

    goToNextStep =(event) => {
        event.preventDefault();
        this.setState({step: "second"});
    }
    goBack = (event) => {
        this.setState({step: "first", cur_lane: "COMP_FCK_COMPLETE"});
        // console.log(this.state);
    }

    onDateChange = (date) => {
        this.setState({due_date: date});
    }

    onSaleDateChange = (date) => {
        this.setState({sale_date: date});
    }

    addFollowupAssignection = (event) => {
        event.preventDefault();
        this.setState({ followuptask: true, cur_lane: "COMP_FCK_FOLLOWUP"});
    }

    onDateChangeDueBy = (date) => {
        this.setState({due_by: date});
    }

    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({followup_note: event.target.value});
    }

    showFollowupTaskModal = (event) => {
        event.preventDefault()
        this.setState({'show_tasks_modal' : true})
    }

    closeFollowupTaskModal = () => {
        // event.preventDefault()
        this.setState({'show_tasks_modal' : false})
    }

    render()
    {
        let review_name = (this.state.cur_review_json !== null) ? ': ' +this.state.cur_review_json.name : ''
        let cur_task_id = Store.getStoreData('parent_task').id
        // console.log('this.state.cur_task_id', cur_task_id)
        return (
            <MRModalContainer>
                <SaveAnswersDiv>
                    <MRModalSaveBtn onClick={this.doFileCheck(1)}>Save File Check</MRModalSaveBtn>
                </SaveAnswersDiv>
            <MRModalHeader>
                <MRModalHeaderText>File Checking</MRModalHeaderText>
                <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                <div style={{clear: "both"}}></div>
                <ReportModalHRule />
            </MRModalHeader>
            <MRModalBody>
                <MRModalBodyInner>
                    {
                        (() => {
                            if (this.state.my_reviews.length === 0) {
                                return (<div>No reviews available</div>);
                            } else {
                                // console.log('this.state.step', this.state.step)
                                if (this.state.step === "second") {
                                    return (
                                        <div>
                                            <ScoreContainer>
                                                <ScoreHeaderContainer>File Check Header Information</ScoreHeaderContainer>
                                                <ScoreBodyContainer>
                                                    <MRModalLabel>Review</MRModalLabel>
                                                    {
                                                        (() => {
                                                            const cur_review = this.state.my_reviews.find((item) => {
                                                                return item.id === this.state.cur_review_id;
                                                            })
                                                            console.log("CUR_REVIEW:", cur_review);
                                                            return (<p>{cur_review.nick_name}</p>);
                                                        })()
                                                    }
                                                    <MRModalLabel>Customer Name</MRModalLabel>
                                                    <MRModalInput type="text" name="customer_name" onChange={this.handleChange} value={this.state.customer_name} />
                                                    <MRModalLabel>Date of Sale</MRModalLabel>
                                                    <CSLDateCover>
                                                        <DatePicker
                                                                onChange={this.onSaleDateChange}
                                                                clearIcon={null}
                                                                calendarIcon={null}
                                                                locale={"en-GB"}
                                                                value={this.state.sale_date}
                                                                />
                                                    </CSLDateCover>
                                                    <MRModalLabel>Reviewer</MRModalLabel>
                                                    <p>{this.state.loggedin_user_info.ContactName}</p>
                                                    <MRModalLabel>Reviewee</MRModalLabel>
                                                    <MRModalSelect onChange={this.handleChange} name="reviewee_id" value={this.state.reviewee_id} style={{width: "350px"}}>
                                                        {
                                                            (() => {
                                                                if(this.state.review_list.length !== 0) {
                                                                    let reviewees = [];
                                                                    for(let item of this.state.review_list){
                                                                        for(let cur_item of this.state.cur_gc_user){
                                                                            if(parseInt(cur_item.ID) === parseInt(item.ID)){
                                                                                reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                                                            }
                                                                        }
                                                                           
                                                                    }
                                                                    return reviewees;
                                                                }
        
                                                            })()
                                                        }
                                                    </MRModalSelect>
                                                    <MRModalLabel>Reviewee Designation</MRModalLabel>
                                                    <MRModalInput type="text" name="reviewee_designation" onChange={this.handleChange} value={this.state.reviewee_designation}/>
                                                    <MRModalLabel>File reference</MRModalLabel>
                                                    <MRModalInput type="text" name="file_reference" onChange={this.handleChange} value={this.state.file_reference}/>
                                                    <MRModalLabel>Scheduled Date</MRModalLabel>
                                                    <CSLDateCover>
                                                        <DatePicker
                                                                onChange={this.onDateChange}
                                                                clearIcon={null}
                                                                calendarIcon={null}
                                                                locale={"en-GB"}
                                                                value={this.state.due_date}
                                                                />
                                                    </CSLDateCover>
                                                    <ModalNameLabelDiv>Existing Attachments</ModalNameLabelDiv>
                                                    <ModalNameInputDiv style={{ minHeight:'100px', height: '100px'}}>
                                                    {
                                                        (() => {
                                                            if(this.state.cur_files_json !== null) {
                                                                return(
                                                                    <div style={{width:'100%', display: 'flex', overflow: 'auto', minHeight: 140}}>
                                                                    {
                                                                        this.state.curBinFiles.map((f, index) => {
                                                                            // console.log('f',f);
                                                                            let file_parts = f.name.split('.');
                                                                            let ext = 'PDF';
                                                                            switch(file_parts[file_parts.length - 1]) {
                                                                            case 'jpeg':
                                                                            case 'jpg' : ext = 'JPG';break;
                                                                            case 'png' : ext = 'PNG'; break;
                                                                            default: ext = 'PDF';
                                                                            }
                                                                            // console.log('ext',ext);
                                                                            return(
                                                                                <Style.FileContainer key={index}>
                                                                                <Style.FileTypeContainer>{ext}</Style.FileTypeContainer>
                                                                                <Style.FileInfoContainer>
                                                                                    <strong style={{color: '#000000'}}>{f.name}</strong><br /><br />
                                                                                    Added just now<br /><br />
                                                                                    <a style={{ cursor: 'pointer'}} onClick={() => this.handleDownload(index)}><FiDownload /> Download</a> &nbsp; 
                                                                                </Style.FileInfoContainer>
                                                                                </Style.FileContainer>
                                                                            )

                                                                        })
                                                                    }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return <div>No existing attachments.</div>
                                                            }
                                                        })()

                                                    }
                                                    </ModalNameInputDiv>
                                                    <div style={{display: 'flex'}}>
                                                        <ModalNameLabelDiv>Add files</ModalNameLabelDiv>
                                                        <ModalNameInputDiv style={{ minHeight:'140px', height: '100px'}}>
                                                            <Dropzone onFilesAdded={this.filesLoaded} />
                                                        </ModalNameInputDiv>
                                                    </div>

                                                </ScoreBodyContainer>
                                            </ScoreContainer>
                                            <ScoreContainer>
                                                <ScoreHeaderContainer>Questions{review_name}</ScoreHeaderContainer>
                                                <ScoreBodyContainer>
                                                    <QuestionAnswerForm review_json={this.state.cur_review_json} cur_review_id="0" qanda={this.state.qanda} />
                                                </ScoreBodyContainer>
                                            </ScoreContainer>
                                            <ScoreContainer>
                                                <ScoreHeaderContainer>Comment</ScoreHeaderContainer>
                                                <ScoreBodyContainer>
                                                    <FCKCommentDiv>
                                                        <FCKTextarea rows="6" style={{width: "100%"}} value={this.state.comment} onChange={this.changeComment} />
                                                    </FCKCommentDiv>
                                                </ScoreBodyContainer>
                                            </ScoreContainer>
                                            {
                                                (() => {
                                                    if(this.state.cur_review_json !== null && "openconfigs" in this.state.cur_review_json && this.state.cur_review_json.openconfigs.length !== 0) {
                                                        return (
                                                            <FCKCommentDiv>
                                                                <label style={{display: "block"}}>RAG Rating</label>
                                                                <div style={{float: "left", paddingRight: "10px", width: "50%"}}>
                                                                    <MRModalSelect value={this.state.openrag.termname} onChange={this.handleOpenRag}>
                                                                        {
                                                                            this.state.cur_review_json.openconfigs.map((item, index) => {
                                                                                return (
                                                                                    <option key={index} value={item.termname}>{item.termname}</option>
                                                                                );
                                                                            })
                                                                        }
                                                                    </MRModalSelect>
                                                                </div>
                                                                <div style={{float: "left", paddingLeft: "10px", width: "50%"}}>
                                                                    <MRModalInput type="color" value={this.state.openrag.color} />
                                                                </div>
                                                                <div style={{clear: "both"}}></div>
                                                            </FCKCommentDiv>
                                                        );
                                                    }
                                                })()
                                            }
                                            {
                                            (() => {
                                                if(this.state.cur_lane === "COMP_FCK_FOLLOWUP"){
                                                    return (
                                                        <div>
                                                            <MRModalLabel>Subject</MRModalLabel>
                                                            <MRModalInput type="text" name="followup_sub" onChange={this.handleChange}/>
                                                            <FCKCommentDiv>
                                                                <label>Note</label>
                                                                <FCKTextarea rows="6" style={{width: "100%"}} value={this.state.followup_note} onChange={this.changeFollowupNote} />
                                                            </FCKCommentDiv>
                                                            <MRModalLabel>Assign To</MRModalLabel>
                                                            <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to}>
                                                                {
                                                                    (() => {
                                                                        if(this.state.review_list.length !== 0) {
                                                                            let reviewees = [];
                                                                            // console.log("his.state.reviewee_id", this.state.reviewee_id);
                                                                            for(let item of this.state.review_list){
                                                                                reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                                                                
                                                                            }
                                                                            return reviewees;
                                                                        }
                                        
                                                                    })()
                                                                }
                                                            </MRModalSelect>
                                                            <MRModalLabel>Due By</MRModalLabel>
                                                            <CSLDateCover>
                                                                <DatePicker
                                                                        onChange={this.onDateChangeDueBy}
                                                                        clearIcon={null}
                                                                        calendarIcon={null}
                                                                        locale={"en-GB"}
                                                                        value={this.state.due_by}
                                                                        />
                                                            </CSLDateCover>
                                                        </div>
                                                        )
                                                }
                                            })()
                                            }
                                            <ScoreContainer>
                                                <ScoreHeaderContainer>Tasks Associated with this Filecheck<HeaderButton onClick={this.showFollowupTaskModal}><FaPlusCircle /></HeaderButton></ScoreHeaderContainer>
                                                <ScoreBodyContainer>
                                                    <TasksList taskId={cur_task_id} tableRows={5} showModal={this.state.show_tasks_modal} revieweeList={this.state.review_list} closeFollowupTaskModal={this.closeFollowupTaskModal} />
                                                </ScoreBodyContainer>
                                            </ScoreContainer>
                                        </div>
                                    );
                                }
                            }
                        })()
                    }
                </MRModalBodyInner>
            </MRModalBody>
            <MRModalFooter>
                {
                    (() => {
                        if (this.state.step === "first") {
                            return (<div><MRModalNextBtn onClick={this.goToNextStep}>Next</MRModalNextBtn><MRModalNextBtn onClick={this.closeModal}>Close</MRModalNextBtn></div>);
                        } else if (this.state.step === "second" && this.state.cur_lane ==="COMP_FCK_COMPLETE") {
                            return (<div><MRModalNextBtn onClick={this.doFileCheck(0)}>Submit</MRModalNextBtn><MRModalNextBtn onClick={this.closeModal}>Close</MRModalNextBtn></div>);
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.step === "second" && this.state.cur_lane ==="COMP_FCK_FOLLOWUP") {
                            return (<div><MRModalNextBtn onClick={this.doFollowupCreate}>Create</MRModalNextBtn><MRModalNextBtn onClick={this.closeModal}>Close</MRModalNextBtn></div>);
                        }
                    })()
                }
                <div style={{clear: "both"}}></div>
            </MRModalFooter>
        </MRModalContainer>
        );
    }
}

export default FileCheckModal;