import React from "react";
import styled from "styled-components";
import CSLTable from "../Common/CSLTable";
import CompanyChanger from "./CompanyChanger";
import APICall from "../../Common/APICall";
import Store from "../../Common/Store";
import DatePicker from "react-date-picker";
import FeeEarnarAnalysis from "./FeeEarnarAnalysis";
import FileAuditData from "./FileAuditData";
import AnswerAnalysis from "./AnswerAnalysis";
import ConfigFilter from "./ConfigFilter";
import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import CSLMultiSelect from "./CSLRegister/CSLMultiSelect";
import CSLLoader from "../Common/CSLLoader";
import AlertBox from '../Common/AlertBox';
import moment from "moment";
import FlaggingSection from "../Partials/FlaggingSection";

const MultiGridDivs = styled.div`
  display: grid;
  grid-template-columns: 40% 40%;
  grid-gap: 1em;
`;

const ConfigContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  padding-bottom: 74px;
`;
const MRModalSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: #222222;
`;
const ConfigLabel = styled.div`
  color: #222222;
  font-weight: 600;
`;
const VDivider = styled.div`
  border: 1px solid #919191;
  height: 100%;
`;
const ReportSelect = styled.select`
  margin-top: 15px;
  display: inline-block;
  box-sizing: border-box;
  height: 35px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const ReportLabel = styled.label`
  color: #222222;
  font-weight: 600;
  // margin-top: 30px;
  font-size: 14px;
`;
const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 284px;
  border-radius: 3px;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
  background-color: #fff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 0 4px #c4c4c4;
  color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #01b0a2;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: 15px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const FilterCell1 = styled.div`
  padding: 5px;
  width: calc(17% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell2 = styled.div`
  // padding: 5px;
  width: 100%;
  // float: left;
  box-sizing: border-box;
  height: 75px;
  margin-top: 15px;
`;
const FilterCell3 = styled.div`
  padding: 5px;
  width: calc(50% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell4 = styled.div`
  padding: 5px;
  width: calc(67% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell5 = styled.div`
  padding: 5px;
  width: calc(84% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell6 = styled.div`
  padding: 5px;
  width: calc(100% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const Label = styled.div`
  padding-bottom: 7px;
  font-weight: bolder;
`;
// const date_filter = [
//   { key: "lastyear", value: "Last Year" },
//   { key: "rollingyear", value: "Rolling Year" },
//   { key: "quartertodate", value: "Quarter To Date" },
//   { key: "lastquarter", value: "Last Quarter" },
//   { key: "rollingquarter", value: "Rolling Quarter" },
//   { key: "customrange", value: "Custom Range" },
// ];
const date_filter = [
  { key: "lastyear", value: "Last Year" },
  { key: "currentyear", value: "Current Year" },
  { key: "quartertodate", value: "Current Quarter" },
  { key: "lastquarter", value: "Last Quarter" },
  { key: "currentmonth", value: "Current Month" },
  { key: "lastmonth", value: "Last Month" },
  { key: "customrange", value: "Custom Range" },
];
let f_stDate = new Date();
let f_start_month = f_stDate.getMonth() + 1 > 9 ? f_stDate.getMonth() + 1 : "0" + (f_stDate.getMonth() + 1);
let f_start_date = f_stDate.getDate() + "/" + f_start_month + "/" + f_stDate.getFullYear();

    
let f_enDate = new Date();
let f_end_month = f_enDate.getMonth() + 1 > 9 ? f_enDate.getMonth() + 1 : "0" + (f_enDate.getMonth() + 1);
let f_end_date = f_enDate.getDate() + "/" + f_end_month + "/" + f_enDate.getFullYear();



class ReportFilter extends React.Component {
  state = {
    tabledata: [],
    task_data: [],
    review_list: [],
    reviewer: [],
    gc_company_id_all: [],
    cur_gc_user: [],
    file_reference: [],
    date_filters: "customrange",
    start_date: new Date(),
    end_date: new Date(),
    my_reviews: [],
    division: "",
    feeEarnerData: [],
    //cur_company_id: 0,
    //cur_reviewer_id: "0",cur_reviewer_id
    //cur_reviewee_id: "0",
    //cur_file_reference_id: "0",
    cur_company_id:[],
    cur_reviewer_id: [],
    cur_reviewee_id: [],
    cur_file_reference_id: [],
    file_audit_data: [],
    //group_companies_filter: {},
    cur_review_id: 0,
    cur_review_name: "",
    answer_analysis_data: [],
    reviewee_analysis_data: [],
    load_report: false,
    //fil_start: "01/01/" + pre_year,
    //fil_end: "31/12/" + pre_year,
    fil_start: f_start_date,
    fil_end: f_end_date,
    tagList: [],
    user_selection: null,
    filter_type: "company",
    config_disabled: true,
    multiselect_filters: {
      reviewer: { name: "reviewer", nickname: "Reviewer", type: "multi", options: {}, selections: {}, size: 2 },
      reviewee: { name: "reviewee", nickname: "Reviewee", type: "multi", options: {}, selections: {}, size: 2 },
      file_ref: { name: "file_ref", nickname: "File Reference", type: "multi", options: {}, selections: {}, size: 2 },
      group_companies:{ selections: {}, options: {}, name: "group_companies", size: 2, type: "multi", nickname: "Group Companies" }
    },
    alert_param: ""
  };

  componentDidMount() {
    // console.log('start_date',this.state.start_date);
    // console.log('end_date',this.state.end_date);
    //  let postData = { command: "report_config" };
    //  let api = new APICall();
    //  api.command(postData, this.ShowReportConfig);
    let postData = { command: "get_report_data"};
    let api = new APICall();
    api.command(postData, this.ShowReportData);
  }

  ShowReportConfig = (result) => {
    console.log("asnswerfilter gc comp", result);
    let tags = result.tags;
    let gcarrr = [];
    let multiselect_filters = JSON.parse(JSON.stringify(this.state.multiselect_filters));
    let gcdataFil = multiselect_filters.group_companies;
    //{ selections: {}, options: {}, name: "group_companies", size: 2, type: "multi", nickname: "Group Companies" };
    //console.log("HERE  1")
    //console.log(gcdataFil)
    var All_gc_comp = Store.getStoreData('gc_companies');
    console.log('GCkey All_gc_comp::',All_gc_comp)
    tags.forEach((data) => {
      let gcdata = {};
      gcdata.company_id = data.id;
      gcdata.name = data.company_name;
      
      gcdataFil.options['All']={'id':0,'name':'All','division':'0','hub':'0'}
      gcdataFil.options[data.company_name] = { name: data.company_name, id: data.id };
      let div = data.tags_config.find(b=>b.name === 'Division')
      if(div && div.value.name != undefined){
        gcdataFil.options[data.company_name].division = div.value.name
      }
      let hub = data.tags_config.find(b=>b.name === 'Hub')
      if(hub && hub.value.name != undefined){
        gcdataFil.options[data.company_name].hub = hub.value.name
      }
      
      gcdata.parent_id = data.parent_id;
      
      data.tags_config.forEach((condata) => {
        switch (condata.name) {
          case "Division":
            gcdata.div_id = Object.keys(condata.value).length === 0 ? 0 : condata.value.id;
            gcdata.division = Object.keys(condata.value).length === 0 ? "--" : condata.value.name;
            break;
          case "Region":
            gcdata.reg_id = Object.keys(condata.value).length === 0 ? 0 : condata.value.id;
            gcdata.region = Object.keys(condata.value).length === 0 ? "--" : condata.value.name;
            break;
          case "Hub":
            gcdata.hub_id = Object.keys(condata.value).length === 0 ? 0 : condata.value.id;
            gcdata.hub = Object.keys(condata.value).length === 0 ? "--" : condata.value.name;
            break;
        }
      });
      
      gcdata.selected = false;
      gcarrr.push(gcdata);
      console.log('gcarr=>',gcarrr)
    });
    
    console.log("AdvanceFilter", gcdataFil);
    //this.setState({load_report:false});
    this.setState({ tabledata: gcarrr, multiselect_filters, tagList: result.tag_list, tagCompany: result.tags });

    //if (this.props.reportType === "answer_analysis" || this.props.reportType === "file_audit_data" || this.props.reportType === "fee_earner_analysis") {
      //     alert('changeFilter')
      let tabledata = JSON.parse(JSON.stringify(this.state.tabledata));
      tabledata.forEach((tdata) => {
        tdata.selected = false;
      });
      
      let user_selection = {};
      var companyData = this.state.tagList;

      for (let i = 0; i < companyData.length; i++) {
        user_selection[Object.keys(companyData[i])] = "0";
      }
      
      this.changeConfigs(user_selection);
      this.setState({ filter_type: "config", tabledata: tabledata, group_companies_filter: { group_companies: gcdataFil }, config_disabled: false, reviewer: [], division: "All" });
      this.changeFilter();
    //}
  };
  fileAuditData = () => {
    var task_arr = [];
    task_arr = Store.getStoreData("report_task_data") == null ? [] : Store.getStoreData("report_task_data");
    var task_filtered = [];
    var active_filter = { reviewer: 0, reviewee: 0 };
    if (this.state.cur_reviewer_id != 0) {
      active_filter.reviewer = 1;
    }
    if (this.state.cur_reviewee_id != 0) {
      active_filter.reviewee = 1;
    }

    for (let i = 0; i < task_arr.length; i++) {
      let taskJson = JSON.parse(task_arr[i].task_json);
      if (this.state.cur_reviewer_id == 0 || (this.state.cur_reviewer_id == taskJson.object_data.headerinfo.reviewer_id && this.state.cur_reviewee_id == 0) || this.state.cur_reviewee_id == taskJson.object_data.headerinfo.reviewee_id) {
        task_filtered.push(task_arr[i]);
      }
    }
  };
  taskDataFilter_call = () =>{ 
    console.log("taskDataFilter_call",this.state)
    this.setState({ alert_param: "" });
    if(this.state.cur_company_id.length == 0){
     this.setState({ alert_param: "Please select Group Company." });
    }else if(this.state.cur_review_id == 0 || this.state.cur_review_id == ""){
      this.setState({ alert_param: "Please select File Check Type." });
     }else{
    
      
    //event.preventDefault();
    let postData = { command: "get_report_data_filtered", gc_company_id: this.state.cur_company_id, reviewer_id:this.state.cur_reviewer_id,
    reviewee_id:this.state.cur_reviewee_id,file_ref_is:this.state.cur_file_reference_id,
    review_name:this.state.cur_review_id,start_date:this.state.start_date,end_date:this.state.end_date };
    
    let api = new APICall();
    api.command(postData, this.ShowReportData_apply);
    }
  }
  
  taskDataFilter = () => { 
    var task_arr = [];
    //console.log('report_task_data',Store.getStoreData('report_task_data'))
    task_arr = Store.getStoreData("report_task_data") == null ? [] : Store.getStoreData("report_task_data");
    //console.log('taskdata main',task_arr);
    var task_filtered = [];
    task_filtered =task_arr;
    // console.log('cust_fields:: task_filtered',this.state.custom_fields)
     
    let feeEarnerData = [];
    for (let k = 0; k < task_filtered.length; k++) {
      var task_json = task_filtered[k];
      var designation = Store.getStoreData("users")[task_json.reviewee_id].role;
      //task_json.reviewee_designation;
      var reviewee_id = task_json.reviewee_id;
      var child_count = task_filtered[k].child_task_ids.length > 0 ? task_filtered[k].child_task_ids.length : 0;
      let container = {
        fee_earner_name: Store.getStoreData("users")[reviewee_id] === undefined ? "" : Store.getStoreData("users")[reviewee_id].ContactName,
        reviewee_id: reviewee_id,
        child_count: child_count,
        designation: designation,
        total_audit_count: 1,
      };
      feeEarnerData.push(container);
    }
    const fee_earner_final = [
      ...feeEarnerData
        .reduce((r, o) => {
          const key = o.reviewee_id + "-" + o.designation;
          const item =
            r.get(key) ||
            Object.assign({}, o, {
              total_audit_count: 0,
              child_count: 0,
            });

          item.total_audit_count += o.total_audit_count;
          item.child_count += o.child_count;

          return r.set(key, item);
        }, new Map())
        .values(),
    ];

    //Answer Analysisview_id)
    //console.log('answertab review',Store.getStoreData('my_reviews'))
    var answer_analysis_data = [];
    var my_reviews = Store.getStoreData("my_reviews");
    
    //var my_reviews = this.state.my_reviews;
    //console.log('answertab my_reviews',my_reviews)
    console.log("get",this.state.cur_review_id, typeof this.state.cur_review_id)
    
    for (let i = 0; i < my_reviews.length; i++) {
      console.log("get 2",my_reviews[i].id, typeof my_reviews[i].id)
      if (my_reviews[i].id === parseInt(this.state.cur_review_id)){
        //console.log('taskdata review',my_reviews[i]);
        var section_arr = JSON.parse(my_reviews[i].review_json).sections;
        var question_num = 1;
        for (let j = 0; j < section_arr.length; j++) {
          var question_arr = section_arr[j].questions;
          console.log("question_arr::",question_arr)
          for (let k = 0; k < question_arr.length; k++) {
            var cont = {};
            var type = this.getQuestionType(question_arr[k].type);
            if (type == "Boolean") {
              var res = this.getAnswerCount(task_filtered, question_arr[k].id,question_arr[k].question.replace(/\n/g, "").replace(/'+/g, "'"));
              cont.question_no = question_num;
              cont.section_name = section_arr[j].name;
              cont.id = question_arr[k].id;
              cont.question_text = question_arr[k].question.replace(/\n/g, "").replace(/'+/g, "'");
              cont.yes_count = res.yes_count;
              cont.no_count = res.no_count;
              cont.na_count = res.na_count;
              answer_analysis_data.push(cont);
            }
            question_num++;
          }
        }
      }
    }
    //console.log('task_arr answer_analysis_data',answer_analysis_data)
    var reviewee_analysis_data = [];
    //console.log('reviewee_analysis_data',task_filtered)
    for (let k = 0; k < task_filtered.length; k++) {
      var task_json = task_filtered[k];
      let container = {
        reviewee_name: Store.getStoreData("users")[task_json.reviewee_id] === undefined ? "" : Store.getStoreData("users")[task_json.reviewee_id].ContactName,
        score: task_json.totalscore === null ? 0 : task_json.totalscore,
        percentage: task_json.scoresobtained === null ? 0 : task_json.scoresobtained,
      };
      reviewee_analysis_data.push(container);
    }
    console.log('taskdata my_reviewe',reviewee_analysis_data);
    //  console.log('file_audit_data my_reviews length',my_reviews.length);
    // console.log('file_audit_data cur_review_id',this.state.cur_review_id);
    var question_array = [];

    //for(let i =0; i< Store.getStoreData('my_reviews').length; i++){
    for (let i = 0; i < my_reviews.length; i++) {
      let container = {};
      if (parseInt(this.state.cur_review_id) === my_reviews[i].id) {
        let sections = JSON.parse(my_reviews[i].review_json).sections;
        var Q = 0;
        for (let j = 0; j < sections.length; j++) {
          let questions = sections[j].questions;
          for (let k = 0; k < questions.length; k++) {
            Q++;
            container["question_text"] = questions[k].question;
            container["question_id"] = questions[k].id;
            container["question_number"] = "Q" + Q;
            question_array.push(container);
            container = {};
          }
        }
      }
    }
    // //console.log("curReviewId", this.state.cur_review_id)//division
    // console.log("file_audit_data question_array", question_array)
    // console.log("file_audit_data props",this.props.reportType)
    if (this.props.reportType == "file_audit_data") {
      let fileAuditData = [];
      let obj_title = { title: "File Audit Report" };
      fileAuditData.push(obj_title);
      let obj_datefilter = { DateTitle: "Date filters applied:", value1: "Start Date", value2: this.state.fil_start, value3: "End Date", value4: this.state.fil_end };
      fileAuditData.push(obj_datefilter);
      let file_review_nickname = Store.getStoreData("my_reviews").find(o => o.id === parseInt(this.state.cur_review_id)).nick_name;
      console.log("file_review::",file_review_nickname)
      let obj_genFilter = {
        gen_filtitle: "Filters applied",
        department: "Department",
        DeptVal: this.state.division,
        question_set: "Question Set",
        file_val: this.state.cur_review_id == 0 ? "" : file_review_nickname.replace(/,/g, " "),
      };
      fileAuditData.push(obj_genFilter);
      let reviewee_designation_cus_field = Store.getStoreData('module_config').custom_fields.custom_terminology_fields.filter(function(dt){
      	return(dt.name === 'reviewee_designation')
      })
      console.log('reviewee_designation_cus_field',reviewee_designation_cus_field)
      let reviewee_designation_col = 'Reviewee Designation'
      if(reviewee_designation_cus_field.length > 0){
			  reviewee_designation_col = reviewee_designation_cus_field[0].nickname
      }
      let sale_date_cus_field = Store.getStoreData('module_config').custom_fields.custom_terminology_fields.filter(function(dt){
        return(dt.name === 'sale_date')
      })

      let sale_date_col = 'Policy Date'
      if(sale_date_cus_field.length > 0){
        sale_date_col = sale_date_cus_field[0].nickname
      }
      let obj_head = {
        file_reference: "File Reference",
        client_name: "Client Name",
        fee_earner_name: "Reviewee",
        sale_date: sale_date_col,
        department: reviewee_designation_col,
        office: "Group Company",
        auditor: "Reviewer",
        related_individual: "Related Individual",
        audit_date: "Completed Date",
        corrective_actions_req: "Corrective Action Required",
      };
      obj_head.score = "Score";
      obj_head.outcome = "Pass/Fail";
      obj_head.review_autofail = "Did this review autofail?";
      obj_head.summary = "Summary Comments";
      var Counter = 0;
      for (let i = 0; i < question_array.length; i++) {
        Counter++;
        //obj_head["question_no" + Counter] = '"' + question_array[i].question_number + '"';
        obj_head["question_no" + Counter] ='"' + question_array[i].question_number + '-' + question_array[i].question_text.replace(/,/g, " ").replace(/\n/g, " ") + '"';
        obj_head["question_text" + Counter] = 'Comments'
        obj_head["question_autofailed" + Counter] = 'Autofailed?'
      }
      console.log('file_audit_data task_filtered',task_filtered);
      console.log('file_audit_data task_filtered length',task_filtered.length);
      if (task_filtered.length == 0) {
        obj_head = { blank_msg: "No File Checks qualified for this report" };
      }
      fileAuditData.push(obj_head);
      for (let k = 0; k < task_filtered.length; k++) {
        console.log("file_audit_data task",task_filtered[k])
        var task_json = task_filtered[k];
        var sections = task_json.sections;
        console.log('file_audit_data sections',sections);
        let company_id = task_filtered[k].company_id% 1e5;
        let company_obj = Store.getStoreData("gc_companies").find(o => o.id === company_id);
        
        //console.log("fileAuditData compdate",this.getFormattedDate(task_filtered[k].completed_date))
        //var a = Store.getStoreData("users")[task_json.object_data.headerinfo.reviewee_id] === undefined ? "" : Store.getStoreData("users")[task_json.object_data.headerinfo.reviewee_id].ContactName;
        //console.log("fileAuditData freeern name",Store.getStoreData("users")[task_json.reviewer_id].ContactName)
       let related_individual = '' ;
       if(task_json.related_individual > 0){
       	related_individual = '"' + Store.getStoreData("users")[task_json.related_individual] === undefined ? "" : '\"'+Store.getStoreData("users")[task_json.related_individual].ContactName + '"'
       }

        let sale_date_value = !task_filtered[k].sale_date_is_active ? "N/A" : task_filtered[k].sale_date === null ? "" : this.getFormattedDate(task_filtered[k].sale_date);

        let cont = {
          file_reference: '"' + task_json.file_reference.replace(/,/g, " ") + '"',
          client_name: '"' + task_json.customer_name.replace(/,/g, " ") + '"',
          fee_earner_name: '"' + Store.getStoreData("users")[task_json.reviewee_id] === undefined ? "" : '\"'+Store.getStoreData("users")[task_json.reviewee_id].ContactName + '"',
          sale_date: '"' + sale_date_value + '"',
          department: '"' + task_json.reviewee_designation + '"',
          office: '"' + company_obj.company_name + '"',
          auditor: '"' + Store.getStoreData("users")[task_json.reviewer_id] === undefined ? "" : '\"'+Store.getStoreData("users")[task_json.reviewer_id].ContactName + '"',
          related_individual: related_individual,
          audit_date: '"' + this.getFormattedDate(task_filtered[k].completed_date) + '"',
          corrective_actions_req: task_filtered[k].child_task_ids === null ? '"' + 0 + '"' : '"' + task_filtered[k].child_task_ids.length + '"',
        };
        console.log("filecont",cont)
        var score_obtained = task_json.scoresobtained === null ? 0 : Math.round(task_json.scoresobtained);
        //cont.score = score_obtained.toString().includes(".") ? '"' + score_obtained.toString().split(".")[0] + '"' : '"' + score_obtained + '"';
        cont.score = score_obtained.toString();
        var result_status = "";
        result_status = task_json.ragratingname + " (" + task_json.ragratingname + ")";
        let scoring_enabled = "autofailed" in task_json.for_autofail ?  task_json.for_autofail.scoring_enabled : false;
        let review_autofail = "autofailed" in task_json.for_autofail ? task_json.for_autofail.autofailed : false;
        cont.outcome = '"' + result_status.replace(/,/g, " ") + '"';
        cont.review_autofail =  review_autofail === true ? 'YES':'NO';
        //cont.summary = "summary" in task_json.object_data.headerinfo.review_json ? '"'+task_json.object_data.headerinfo.review_json.summary.replace(/,/g, ' ')+'"' : "";
        cont.summary = "summary" in task_json ? '"' + task_json.summary.replace(/,/g, " ").replace(/\n/g, " ")  + '"' : "";
        var Q = 0;
        //console.log("file_audit_question cont",cont)

        for (let j = 0; j < sections.questions.length; j++) {
          var questions = sections.questions[j];
          
          //console.log("file_audit_question questions",questions)
          //var questions = sections[j];
          for (let m = 0; m < questions.length; m++) {
            console.log("AUTOFAIL questions:", questions[m]);
            Q++;
          	let question_autofailed = 'N/A'
            // console.log('file_audit_question Q++',Q,questions[m])
            let is_autofailed = false;
            if(scoring_enabled){
            	let autofail = "autofail" in  questions[m] ? questions[m].autofail : []
	            if(autofail.length > 0){
                const autofail_enabled = questions[m].autofail.find((af) => af.selected === true);

                  let included = questions[m].included
                  let naallowed = questions[m].naallowed
                  let is_na = naallowed && !included;
                  let weight = questions[m].weight.find((item) => {
                      return item.selected;
                  }).value;

                  // console.log("file_audit_question is_na::",is_na)
                  console.log("AUTOFAIL autofail_enabled:included:", autofail_enabled,included);
                if (typeof autofail_enabled !== 'undefined' && autofail_enabled.value === "Yes" && included) {
                      let q_type = questions[m].type.find((t) => t.selected === true);
                      console.log("AUTOFAIL q_type:", q_type);
                      let qscore = 0;
                      if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
                        if (questions[m].auto_scoring.enabled === true) {
                          if (q_type.value === "Boolean") {
                            qscore = Math.max(parseInt(questions[m].auto_scoring.yes), parseInt(questions[m].auto_scoring.no));
                            console.log("AUTOFAIL qscore Boolean:", qscore);
                          }
                          if (q_type.value === "Dropdown") {
                            qscore = Math.max.apply(
                              null,
                              questions[m].preset_answers.map((pa) => parseInt(pa.weight))
                            );
                            console.log("AUTOFAIL qscore Dropdown:", qscore);
                          }
                        } else {
                          let qscore_obj = questions[m].weight.find((item) => item.selected === true);
                          qscore = parseInt(qscore_obj.value);
                          console.log("qscore score not enable:", qscore);
                        }
                      } else {
                        let qscore_obj = questions[m].weight.find((item) => item.selected === true);
                        qscore = parseInt(qscore_obj.value);
                        console.log("qscore other:", qscore);
                      }
                    console.log('AUTOFAIL obtained_score::',questions[m].obtained_score)
                    console.log('AUTOFAIL qscore::',qscore)
                  if (parseInt(questions[m].obtained_score) < qscore) {
                    
                    question_autofailed = 'Yes'
                  }else{
                    question_autofailed = 'No'
                  }
                }

	            }
            }
             // console.log('file_audit_question question_autofailed',question_autofailed)
             // console.log('file_audit_question answer_text',questions[m].answer.text)
            
            //console.log('file_audit_question Q',Q)
            cont["answer_text" + Q] = questions[m].answer.text==="" ? 'N/A':'"' + questions[m].answer.text.replace(/,/g, " ").replace(/\n/g, " ") + '"';
            // if(cont["answer_text" + Q] === ""){
            //   cont["answer_text" + Q] = 'N/A'
            // }
            cont["answer_comment" + Q] = questions[m].comment !== null && questions[m].comment !== undefined && questions[m].comment !== "" ? '"' + questions[m].comment.replace(/,/g, " ").replace(/\n/g, " ") + '"' : '';
            //console.log('file_audit_question text',questions[m].answer.text)
            //console.log('file_audit_question comment',questions[m].comment)
             cont["question_autofailed" + Q] = question_autofailed
          }
        }

        fileAuditData.push(cont);
      }
      console.log('file_audit_data file_audit_question fileAuditData',fileAuditData)
      var cur_table_arr = [];
      for (let i = 0; i < fileAuditData.length; i++) {
        var container = [];
        container = Object.values(fileAuditData[i]);
        if (container.lenght > 0);
        cur_table_arr.push(container);
      }
      console.log("file_audit_data cur_table_arr", cur_table_arr);
      let csvContent = cur_table_arr.map((e) => e.join(",")).join("\n");
      var a = window.document.createElement("a");
      a.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(csvContent));
      a.setAttribute("download", "FileAuditData.csv");
      window.document.body.appendChild(a);
      a.click();
    }

    this.setState({ feeEarnerData: fee_earner_final, answer_analysis_data: answer_analysis_data, reviewee_analysis_data: reviewee_analysis_data, load_report: true });
    console.log("Mystate", this.state);
  };
  
  refreshState = () => {
    this.setState({ load_report: false });
  };
  getFormattedDate = function (inputDate) {
    console.log("getFormattedDate",inputDate)
    var date_input = new Date(inputDate*1000);
    var date_output = date_input.getDate() + "/" + (date_input.getMonth() + 1) + "/" + date_input.getFullYear();
    console.log("date_output",inputDate)
    return date_output;
  };
  getAnswerCount = (filteredTask, questionID,Qtext) => {
    //console.log("taskdata task_filtered ggetans",filteredTask)
    if (filteredTask.length > 0) {
      //console.log('getAnswerCount questionID',questionID)
      //console.log('getAnswerCount length',filteredTask.length)
      var yes_count = 0;
      var no_count = 0;
      var na_count = 0;

      for (let i = 0; i < filteredTask.length; i++) {
        

        //var section_arr = JSON.parse(filteredTask[i].task_json).object_data.headerinfo.review_json.sections;
        var section_arr = filteredTask[i].sections.questions;
        // if(filteredTask[i].task_id == 26998) {
        //   console.log('getAnswerCount getting in',filteredTask[i].task_id);
        //   console.log('getAnswerCount filteredTask .sections',filteredTask[i].sections)
        //   console.log('getAnswerCount filteredTask .questionID',questionID)
        //  // console.log('getAnswerCount filteredTask .sections',filteredTask[i].sections)
        // }
        //console.log('getAnswerCount filteredTask .sectionslength',section_arr.length)
        for (let j = 0; j < section_arr.length; j++) {
          //var question_arr = section_arr[j].questions;
          var question_arr = section_arr[j];
          
          for (let k = 0; k < question_arr.length; k++) {
            var questionText = question_arr[k].question.replace(/\n/g, "").replace(/'+/g, "'");
            //if(filteredTask[i].task_id == 26998)console.log('getAnswerCount question_id', question_arr[k].question_id);
            //if (questionText == Qtext) {
            if (question_arr[k].question_id == questionID) {
              if (question_arr[k].naallowed === true && question_arr[k].included === false) {
               //if(filteredTask[i].task_id == 26998) console.log('getAnswerCount question_arr NA',filteredTask[i].task_id,question_arr[k])
                //return;
                na_count++;
              } else if (question_arr[k].answer.text == "Yes") {
                //if(filteredTask[i].task_id == 6301) console.log('getAnswerCount question_arr Yes',filteredTask[i].task_id,question_arr[k])
                yes_count++;
              } else if (question_arr[k].answer.text == "No") {
                no_count++;
              }
              
            }
          //   else{
          //     if(filteredTask[i].task_id == 26998) {
          //       console.log("IM HERE");return;
          //     }
          //   }
            
           }
        }
      }
    }
    
    na_count = filteredTask.length - (yes_count+no_count);
    var result = { yes_count: yes_count, no_count: no_count, na_count: na_count };
    //console.log('getAnswerCount result',result);
    return result;
  };
  getQuestionType = (types) => {
    var type = "";
    for (let l = 0; l < types.length; l++) {
      if (types[l].selected == true) {
        type = types[l].value;
      }
    }
    return type;
  };
  changeCompany = (obj) => {
    var company_ids = [];
    let tabledata = JSON.parse(JSON.stringify(this.state.tabledata));
    tabledata.forEach((tdata) => {
      if (tdata.company_id === obj.company_id) {
        if (tdata.selected == true) {
          tdata.selected = false;
        } else {
          tdata.selected = true;
        }
      }
      if (tdata.selected == true) {
        company_ids.push(tdata.company_id);
      }
    });
    //alert(850)
    let postData = { command: "get_report_data", gc_company_id: company_ids };
    let api = new APICall();
    api.command(postData, this.ShowReportData);
    this.setState({ tabledata: tabledata, division: obj.division, cur_company_id: obj.company_id, group_company_name: obj.name });
  };
  changeCompany_ans = () => {
    //event.preventDefault();
    let filters_gc = this.state.multiselect_filters.group_companies;
    //console.log("changeCompany_ans event.target.name",event.target.name);
    // console.log("changeCompany_ans ",filters_gc.group_companies.selections)
    //this.setState({[event.target.name]: event.target.value});
    var company_ids = [];
    //let tabledata = JSON.parse(JSON.stringify(this.state.tabledata));
    let tdata = filters_gc.selections;
    console.log("tdata",tdata)
    if ('All' in tdata){
      tdata = filters_gc.options;
    }
    //let tdata_opt = filters_gc.options;
    let divisions = "";
    // if(this.state.division !== 'All'){
    //   divisions = this.state.division;
    // }
    let company_name = "";
    let selected_id = "";
    console.log('changeCompany_ans tdata',tdata);

    Object.keys(tdata).forEach(function (key) {
      console.log('changeCompany_ans keys',key, tdata[key]);
      if(tdata[key].id != 0){
      company_ids.push(tdata[key].id);

      if (divisions === "") {
        divisions = tdata[key].division;
      } else {
        if("division" in tdata[key]){
          if (!divisions.includes(tdata[key].division)) {
            divisions = divisions + "," + tdata[key].division;
          }
        }
      }

      if (company_name === "") {
        company_name = tdata[key].name;
      } else {
        company_name = company_name + "," + tdata[key].name;
      }
    }
      // if (selected_id === "") {
      //   selected_id = tdata[key].id;
      // } else {
      //   selected_id = selected_id + "," + tdata[key].id;
      // }
    });
    // console.log('changeCompany_ans division',divisions)
    // console.log('changeCompany_ans selected_id',selected_id)
    // console.log('changeCompany_ans company_name',company_name)
    // console.log('changeCompany_ans company_ids',company_ids)
    let postData = { command: "get_report_data", gc_company_id: company_ids };
    //this.setState({ tabledata: tdata, division: divisions, cur_company_id: selected_id, group_company_name: company_name });
    this.setState({ tabledata: tdata, division: divisions, group_company_name: company_name });
    //let api = new APICall();
    //api.command(postData, this.ShowReportData);
  };
  changeConfigs = (data) => {
    console.log("changeConfigs",data)
    var user_selection = data;
    var com_arr = [];
    var companyData = this.state.tagList;
    var tag_company = this.state.tagCompany;
    for (let j = 0; j < tag_company.length; j++) {
      console.log("changeConfigs tag com id===>",tag_company[j].id)
      var cont = {};
      var tag_config = tag_company[j].tags_config;
      for (let k = 0; k < tag_config.length; k++) {
        if (user_selection[tag_config[k].name] == "0") {
          cont[tag_config[k].name] = "0";
        } else {
          cont[tag_config[k].name] = tag_config[k].value.id === undefined ? "0" : tag_config[k].value.id;
        }
      }
      if (JSON.stringify(cont) === JSON.stringify(user_selection)) {
        
        console.log("changeConfigs data", data);
        console.log("changeConfigs cont",cont)
        com_arr.push(tag_company[j].id);
      }
    }
    console.log("com_arr", com_arr);
    this.setState({gc_company_id_all:com_arr})
    //alert(919) com_arr
    let postData = { command: "get_report_data", gc_company_id: com_arr, user_selection: user_selection };
    let api = new APICall();
    api.command(postData, this.ShowReportData);
  };

  ShowReportData = (result) => {
    // alert('this')
    //let gc_assoc = Store.getStoreData("gc_assoc");
    let res_reviewee = result.reviewee;
    let st_users = Store.getStoreData("users");
    let reviewer = [];
    let res_reviewer = result.reviewer;
    ////for reviewer//////
    for (let i = 0; i < res_reviewer.length; i++) {
      var reviewer_list_raw = Object.values(st_users[res_reviewer[i]]);
      //for (let j = 0; j < reviewer_list_raw.length; j++) {
        //if (reviewer_list_raw[j].role != "team") {
          reviewer.push(JSON.stringify({ id: reviewer_list_raw[0], contact_name: reviewer_list_raw[1] }));
        //}
      //}
    }
    var reviewer_unique = reviewer.filter((a, b) => reviewer.indexOf(a) === b);
    var reviewer_final = [];
    var reviewer_multi = {'All':{'id':'0','name':'All'}};
    for (let k = 0; k < reviewer_unique.length; k++) {
      let review_parse = JSON.parse(reviewer_unique[k]);
      reviewer_final.push(review_parse);
      reviewer_multi[review_parse.contact_name]={'id':review_parse.id,'name':review_parse.contact_name}
      
    }
    ////for reviewee/////
    let reviewee = [];
    for (let i = 0; i < res_reviewee.length; i++) {
      var reviewee_list_raw = Object.values(st_users[res_reviewee[i]]);
      //for (let j = 0; j < reviewer_list_raw.length; j++) {
        //if (reviewer_list_raw[j].role != "team") {
          reviewee.push(JSON.stringify({ id: reviewee_list_raw[0], contact_name: reviewee_list_raw[1] }));
        //}
      //}
    }
    var reviewee_unique = reviewee.filter((a, b) => reviewee.indexOf(a) === b);
    var reviewee_final = [];
    var reviewee_multi = {'All':{'id':'0','name':'All'}};
    for (let k = 0; k < reviewee_unique.length; k++) {
      let review_parse = JSON.parse(reviewee_unique[k]);
      reviewee_final.push(review_parse);
      reviewee_multi[review_parse.contact_name]={'id':review_parse.id,'name':review_parse.contact_name}
      
    }
    ///for gc companies////
    let gc_companies = Store.getStoreData("gc_companies");
    let res_comp = result.gc_company;
    let all_comp = [];
    for (let i = 0; i < res_comp.length; i++) {
     // console.log("1 debug::",res_comp[i])
      var gc_companies_raw = gc_companies.find(o => o.id === res_comp[i]);
      //var gc_companies_raw = Object.values(gc_companies[res_comp[i]]);
      //for (let j = 0; j < gc_companies_raw.length; j++) {
        console.log("1.a debug::",gc_companies_raw)
        if (gc_companies_raw) {
          if(gc_companies_raw.is_gc){
            all_comp.push(JSON.stringify({ id: gc_companies_raw.id, name: gc_companies_raw.company_name }));
          }
          
        }
      //}
    }
    var company_unique = all_comp.filter((a, b) => all_comp.indexOf(a) === b);
    var company_multi = {'All':{'id':'0','name':'All'}};
    for (let k = 0; k < company_unique.length; k++) {
      let company_parse = JSON.parse(company_unique[k]);
      company_multi[company_parse.name]={'id':company_parse.id,'name':company_parse.name}
      
    }
    let multiselect_filters = JSON.parse(JSON.stringify(this.state.multiselect_filters));
    multiselect_filters.reviewer.options = reviewer_multi;
    multiselect_filters.reviewee.options = reviewee_multi;
    multiselect_filters.group_companies.options = company_multi;
    //console.log("multiselect_filters debug::",multiselect_filters)
    this.setState({ multiselect_filters });
    let file_ref_str = [];
    var file_ref_multi = {'All':{'id':'0','name':'All'}}
   // console.log('file_ref_multi result',result.file_reference); 
    for (let i = 0; i < result.file_reference.length; i++) {
      let file_ref_json = JSON.stringify(result.file_reference[i]);
      file_ref_str.push(file_ref_json); }
    //console.log('file_ref_multi',file_ref_multi); 
    var file_ref_unique = file_ref_str.filter((a, b) => file_ref_str.indexOf(a) === b);
    var file_ref_final = [];

    for (let k = 0; k < file_ref_unique.length; k++) {
      let file_ref_parse = JSON.parse(file_ref_unique[k]);
      file_ref_final.push(file_ref_parse);
      
      file_ref_multi[file_ref_parse]={'id':file_ref_parse,'name':file_ref_parse}
   
    }
    
    multiselect_filters.file_ref.options = file_ref_multi;
    
    this.setState({
      reviewer: reviewer_final,
      //feeEarnerData: feeEarnerData,
      //my_reviews: my_reviews,
      file_reference: file_ref_final,
      user_selection: result.user_selection,
      //division: division_selected,
      //cur_review_id: curReviewId,
      //cur_reviewer_id: [],
      //cur_reviewee_id: [],
    });
  };
  ShowReportData_apply = (result) => {
    //alert('this')
    //let gc_assoc = Store.getStoreData("gc_assoc");

    //console.log("pdfprops result", this.state);

    if (this.state.division === null) {
      var division_selected = "All";
    } else {
      var division_selected = this.state.division;
    }
    Store.updateStore("report_task_data", result.my_tasks);
    //var tag_list = this.state.tagList;

    var cur_review_name = Store.getStoreData("my_reviews").find(o => o.id === parseInt(this.state.cur_review_id)).nick_name;


    // if(result.my_review.length > 0){
    //   var cur_review_name = result.my_review[0].name;
    // }else{      
    //   var cur_review_name = Store.getStoreData("my_active_reviews").find(o => o.id === parseInt(this.state.cur_review_id)).nick_name;        
    // }
    
    //let my_reviews = result.my_review;
    var gc_selected = result.gc_selected;
    var task_arr = result.my_tasks;
    let feeEarnerData = [];
    var total_audit_count = 0;
    //console.log("ShowReportData_apply task_arr", task_arr.length);
    for (let k = 0; k < task_arr.length; k++) {
      var task_json = task_arr[k];
      //console.log('finalreviewer task_json',task_json)
      if (task_json.reviewee_id > 0) {
        total_audit_count++;
      }
      //console.log("finalreviewer total_audit_count", total_audit_count);
      let container = {
        fee_earner_name: Store.getStoreData("users")[task_json.reviewee_id] === undefined ? "" : Store.getStoreData("users")[task_json.reviewee_id].ContactName,
        child_count: task_json.child_task_ids.length > 0 ? task_arr[k].child_task_ids.length : 0,
        designation: Store.getStoreData("users")[task_json.reviewee_id].role,
        total_audit_count: total_audit_count,
      };
      //console.log("asnswerfilter container123",container)
      //console.log("res1123", Store.getStoreData('users'))
      feeEarnerData.push(container);
      total_audit_count = 0;
    }
    //console.log('ShowReportData_apply container',feeEarnerData)
    //let reviewer = [];

    // for (let i = 0; i < gc_selected.length; i++) {
    //   var reviewer_list_raw = Object.values(gc_assoc[gc_selected[i]].users);
    //   for (let j = 0; j < reviewer_list_raw.length; j++) {
    //     if (reviewer_list_raw[j].role != "team") {
    //       reviewer.push(JSON.stringify({ id: reviewer_list_raw[j].ID, contact_name: reviewer_list_raw[j].ContactName }));
    //     }
    //   }
    // }
    //console.log('ShowReportData_apply reviewer',reviewer)
   // var reviewer_unique = reviewer.filter((a, b) => reviewer.indexOf(a) === b);
    //var reviewer_final = [];
    //var reviewer_multi = {'All':{'id':'0','name':'All'}};
    // for (let k = 0; k < reviewer_unique.length; k++) {
    //   let review_parse = JSON.parse(reviewer_unique[k]);
    //   reviewer_final.push(review_parse);
    //   //reviewer_multi[review_parse.contact_name]={'id':review_parse.id,'name':review_parse.contact_name}
      
    // }
    //console.log('ShowReportData_apply reviewer_final',reviewer_final)
     //let multiselect_filters = JSON.parse(JSON.stringify(this.state.multiselect_filters));
     //multiselect_filters.reviewer.options = reviewer_multi;
     //multiselect_filters.reviewee.options = reviewer_multi;
     //this.setState({ multiselect_filters });
    let file_ref_str = [];
    //var file_ref_multi = {'All':{'id':'0','name':'All'}}
    //console.log('ShowReportData_apply file_ref_multi result',result.file_reference); 
    for (let i = 0; i < result.file_reference.length; i++) {
      let file_ref_json = JSON.stringify(result.file_reference[i]);
      file_ref_str.push(file_ref_json); }
    //console.log('ShowReportData_apply file_ref_multi',file_ref_multi); 
    var file_ref_unique = file_ref_str.filter((a, b) => file_ref_str.indexOf(a) === b);
    var file_ref_final = [];

    for (let k = 0; k < file_ref_unique.length; k++) {
      let file_ref_parse = JSON.parse(file_ref_unique[k]);
      file_ref_final.push(file_ref_parse);
      
      //file_ref_multi[file_ref_parse.name]={'id':file_ref_parse.name,'name':file_ref_parse.name}
   
    }
    
    //multiselect_filters.file_ref.options = file_ref_multi;
    //let curReviewId = my_reviews.length > 0 ? my_reviews[0].nick_name : '';
    //var curReviewId = my_reviews.length > 0 ? my_reviews[0] : "";
    
    //console.log("asnswerfilter curReviewId", curReviewId);
    this.setState({
      //reviewer: reviewer_final,
      feeEarnerData: feeEarnerData,
      //my_reviews: my_reviews,
      file_reference: file_ref_final,
      user_selection: result.user_selection,
      division: result.divisions,
      cur_review_name: cur_review_name
      //cur_review_id: curReviewId,
      //cur_reviewer_id: [],
      //cur_reviewee_id: [],
    });
    //alert("kl")
    this.taskDataFilter();
  };
  tableData = () => {
    let ret = { data: [], columns: [] };
    ret.columns = [
      { Header: "Select", Cell: (row) => <CompanyChanger key={row.original.company_id} data={row.original} changeCompany={this.changeCompany} />, headerStyle: { textAlign: "left" } },
      { Header: "Name", accessor: "name", headerStyle: { textAlign: "left" } },
      { Header: "Hub", accessor: "hub", headerStyle: { textAlign: "left" } },
      { Header: "Division", accessor: "division", headerStyle: { textAlign: "left" } },
      { Header: "Region", accessor: "region", headerStyle: { textAlign: "left" } },
    ];
    ret.data = JSON.parse(JSON.stringify(this.state.tabledata));
    return ret;
  };
  // onStartDateClick = (date){
  //   alert(date);return;
  // }
  onStartDateChange = (date) => {
    //alert(date);return;
    if (!moment.isDate(date)) {
      return;
    }

    if (date > this.state.end_date) {
      alert("Start date must be smaller than end date");
      return;
    }
    let stDate = new Date(date);
    let start_month = stDate.getMonth() + 1 > 9 ? stDate.getMonth() + 1 : "0" + (stDate.getMonth() + 1);
    let start_date = stDate.getDate() + "/" + start_month + "/" + stDate.getFullYear();

    
    // let enDate = new Date();
    // let end_month = enDate.getMonth() + 1 > 9 ? enDate.getMonth() + 1 : "0" + (enDate.getMonth() + 1);
    // let end_date = enDate.getDate() + "/" + end_month + "/" + enDate.getFullYear();
    //,end_date: date, fil_end: end_date 
    this.setState({ start_date: date, fil_start: start_date,date_filters : "customrange"});
  };
  
  onEndDateChange = (date) => {
    //alert(date);return;
    console.log("onEndDateChange",date,moment.isDate(date),new Date(date)) 
    if (!moment.isDate(date)) {
      return;
    }

    let enDate = new Date(date);


    let end_month = enDate.getMonth() + 1 > 9 ? enDate.getMonth() + 1 : "0" + (enDate.getMonth() + 1);
    let end_year = enDate.getFullYear();


    if (date < this.state.start_date && end_year.toString().length === 4) {
      alert("End date must be greater than start date");
      return;
    }

    let end_date = enDate.getDate() + "/" + end_month + "/" + enDate.getFullYear();

    //console.log("onEndDateChange Y", end_year.toString().length) 

    this.setState({ end_date: date, fil_end: end_date,date_filters : "customrange" }); 
  };
  handleChange = (event) => {
    event.preventDefault();
    //console.log("event.target.name", event.target.name);
    //console.log("event.target.value", event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleChange_multi = (data,type) => {
    
    
    let state_name = '';
    let state_array = [];
    if(type === 'reviewer' ){
      state_name = 'cur_reviewer_id';
      //state_array = JSON.parse(JSON.stringify(this.state.cur_reviewer_id));
    }else if(type === 'reviewee' ){
      state_name = 'cur_reviewee_id';
     // state_array = JSON.parse(JSON.stringify(this.state.cur_reviewee_id));
    }else if(type === 'file_ref' ){
      state_name = 'cur_file_reference_id';
      //state_array = JSON.parse(JSON.stringify(this.state.cur_file_reference_id));
    }else if(type === 'group_companies' ){
      state_name = 'cur_company_id';
      //this.changeCompany_ans();
      //state_array = JSON.parse(JSON.stringify(this.state.cur_file_reference_id));
    }
    // console.log("selectCallback A",state_name,state_array);
    // console.log("selectcALLBACK b",data)
    // console.log("selectcALLBACK b",Object.keys(data).length)
    for (const keys in data) {
      //console.log("selectCallback",data[keys])
      if(!state_array.includes(data[keys].id) && data[keys].id != 0){
        if(type === 'file_ref' ){
          state_array.push(data[keys].id)
        }else{
          state_array.push(parseInt(data[keys].id))
        }
        
        this.setState({[state_name]:state_array });
        }
      
    }
    if(Object.keys(data).length === 0){
        this.setState({[state_name]:[] });
    }
    if(type === 'group_companies' ){
      this.changeCompany_ans();
    }
    //console.log("selectCallback B",this.state[state_name],state_array);
    //console.log("selectCallback state",this.state);
    //return false;
    //console.log("selectCallback event.target.value", event.target.value);
    //this.setState({ [event.target.name]: event.target.value });
  };
  handleDateChange = (event) => {
    event.preventDefault();
   // alert(event.target.value)date_filters
    var start_date = new Date();
    var end_date = new Date();
    let date = new Date();
    if (event.target.value == "lastyear") {
      var prev_year = parseInt(date.getFullYear()) - 1;
      start_date = prev_year+"/01/01";
      end_date = prev_year+"/12/31";

    }else if (event.target.value == "currentyear") {
      var curr_year = parseInt(date.getFullYear());
      start_date = curr_year+"/01/01";
      end_date = curr_year+"/12/31";

    } else if (event.target.value == "lastmonth") {
      var curr_month = date.getMonth();
      let year =  date.getFullYear()
      if(curr_month === 0){
        curr_month=12
        year = year - 1;
      } 
      // console.log("MYDATE MONTH",curr_month)
      // console.log("MYDATE year",curr_month)
      var d=new Date(); // current date
      d.setDate(1); // going to 1st of the month
      d.setHours(-1); // going to last hour before this date even started. 
      start_date = year +"/"+curr_month+"/01";
      end_date = year +"/"+curr_month+"/"+d.getDate();
      

    }else if (event.target.value == "currentmonth") {
      var curr_month = date.getMonth() + 1;
      start_date = date.getFullYear() +"/"+curr_month+"/01";
      end_date = date.getFullYear() +"/"+curr_month+"/"+date.getDate();
      
    }
    else if (event.target.value == "rollingyear") {
      var ds = this.addMonths(new Date(), -12);
      var month = ds.getMonth() + 1 > 9 ? ds.getMonth() + 1 : "0" + (ds.getMonth() + 1);
      start_date = ds.getDate() + "/" + month + "/" + ds.getFullYear();
      end_date = date.getDate() + "/" + month + "/" + date.getFullYear();
      
    } else if (event.target.value == "quartertodate") {
      var curr_month = date.getMonth() + 1;
      var start_month = 0;
      var end_month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
      if (curr_month == 1 || curr_month == 2 || curr_month == 3) {
        start_month = "01";
      } else if (curr_month == 4 || curr_month == 5 || curr_month == 6) {
        start_month = "04";
      } else if (curr_month == 7 || curr_month == 8 || curr_month == 9) {
        start_month = "07";
      } else if (curr_month == 10 || curr_month == 11 || curr_month == 12) {
        start_month = "10";
      }
      start_date = date.getFullYear() +"/"+start_month+"/01";
      end_date = date.getFullYear() + "/" + end_month + "/" +date.getDate();
     
    } else if (event.target.value == "lastquarter") {
      var curr_month = date.getMonth() + 1;
      var curr_year =  date.getFullYear();
      if (curr_month == 1 || curr_month == 2 || curr_month == 3) {
        start_date = (curr_year - 1)+"/10/01";
        end_date = (curr_year - 1)+"/12/31"; 
      } else if (curr_month == 4 || curr_month == 5 || curr_month == 6) {
        start_date = curr_year + "/01/01";
        end_date = curr_year + "/03/31";
      } else if (curr_month == 7 || curr_month == 8 || curr_month == 9) {
        start_date = curr_year +"/04/01";
        end_date = curr_year+"/06/30";
      } else if (curr_month == 10 || curr_month == 11 || curr_month == 12) {
        start_date = curr_year + "/07/01";
        end_date = curr_year +"/09/30";
      }
      
    } else if (event.target.value == "rollingquarter") {
      var ds = this.addMonths(new Date(), -3);
      var start_month = ds.getMonth() + 1 > 9 ? ds.getMonth() + 1 : "0" + (ds.getMonth() + 1);
      var end_month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
      start_date = ds.getDate() + "/" + start_month + "/" + ds.getFullYear();
      end_date = date.getDate() + "/" + end_month + "/" + date.getFullYear();
      
    } else if (event.target.value == "customrange") {
      var stDate = new Date();
      var enDate = new Date();
      var start_month = stDate.getMonth() + 1 > 9 ? stDate.getMonth() + 1 : "0" + (stDate.getMonth() + 1);
      var end_month = enDate.getMonth() + 1 > 9 ? enDate.getMonth() + 1 : "0" + (enDate.getMonth() + 1);

      start_date = stDate.getFullYear() + "/" + start_month + "/" + stDate.getDate();
      end_date = enDate.getFullYear() + "/" + end_month + "/" + enDate.getDate();
      // start_date = new Date();
      // end_date = new Date();
      // console.log('MYDATE start_date C', new Date(start_date))
      // console.log('MYDATE end_date C', new Date(end_date))

      // st_date = new Date();
      // en_date = new Date();
      // console.log('MYDATE start_date C', new Date(start_date))
      // console.log('MYDATE end_date C', new Date(end_date))
      
      // start_date =  st_date.getFullYear() + "/" + (stDate.getMonth() + 1) + "/" + st_date.getDate();
      // end_date =  en_date.getFullYear() + "/" + (stDate.getMonth() + 1) + "/" + en_date.getDate() ;
    }
    // console.log('MYDATE start_date',start_date)
    // console.log('MYDATE type start_dateN', typeof start_date)
    // console.log('MYDATE end_date',end_date)
    // console.log('MYDATE end_dateN',typeof  end_date)
    this.setState({ [event.target.name]: event.target.value, fil_start: start_date, fil_end: end_date 
      ,start_date: new Date(start_date),end_date: new Date(end_date)});
  };
  fileAuditReportData = () => {};
  getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  addMonths = (input, months) => {
    months = isNaN(months) ? parseInt(-100) : months;
    const date = new Date(input);
    date.setDate(1);
    date.setMonth(date.getMonth() + months);
    date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth() + 1)));
    return date;
  };
  changeFilter = (event) => {
    event.preventDefault();

    if (this.state.filter_type == "company") {
      //alert('filterchangae',this.state.filter_type)
      let tabledata = JSON.parse(JSON.stringify(this.state.tabledata));
      tabledata.forEach((tdata) => {
        tdata.selected = false;
      });
      let user_selection = {};
      var companyData = this.state.tagList;

      for (let i = 0; i < companyData.length; i++) {
        user_selection[Object.keys(companyData[i])] = "0";
      }
      //console.log("user_selection", user_selection);
      this.changeConfigs(user_selection);
      this.setState({ filter_type: "config", tabledata: tabledata, config_disabled: false, reviewer: [], division: "All" });
    } else {
      //alert('filterchangae else')
      var user_selection = { Division: "0", Region: "0", Hub: "0" };
      this.setState({ filter_type: "company", user_selection: user_selection, config_disabled: true, reviewer: [], file_reference: [], my_reviews: [] });
    }
  };
  selectCallback = (selected, stack) => {
    let type = stack.type;
    let filter_id = stack.filter_id;
    let filters = this.state.multiselect_filters;
    // console.log('selectCallback filters',filters[filter_id])
    // console.log('selectCallback selected',selected)
    // console.log('selectCallback filter_id',filter_id)

    
      filters[filter_id].selections[selected] = filters[filter_id].options[selected];
  
    //console.log('selectCallback selections',filters[filter_id].selections[selected])
    let divisions = "";
    this.setState({ filters });
    if(selected === 'All'){
      this.handleChange_multi(filters[filter_id].options,filters[filter_id].name);

    }else{
      this.handleChange_multi(filters[filter_id].selections,filters[filter_id].name);
    }
    
    // if(filters[filter_id].name === "group_companies"){
    //   this.changeCompany_ans();
    // }
  };
  deselectCallback = (deselected, stack) => {
    let type = stack.type;
    let filter_id = stack.filter_id;
    let filters = this.state.multiselect_filters;
    delete filters[filter_id].selections[deselected];
    let selection = filters[filter_id].selections;
    //console.log('selectCallback deselect selection=>',selection)
    
    //console.log('deselect filters',filters)
    //
    //console.log('deselect filters length selections',Object.keys(selection).length)
    this.setState({ filters });
    if(Object.keys(selection).includes('All')){
      console.log('selectCallback includes all')
      this.handleChange_multi(filters[filter_id].options,filters[filter_id].name);
    }else{
      console.log('selectCallback excludes all')
      this.handleChange_multi(filters[filter_id].selections,filters[filter_id].name);
    }
    
    // if(filters[filter_id].name === "group_companies"){
    //   if (Object.keys(selection).length > 0) {
    //     //this.changeCompany_ans();
    //   } else {
    //     this.setState({ division: "All" });
    //     let data = {
    //       Division: "0",
    //       Hub: "0",
    //       "Principal Network": "0",
    //     };
    //     this.changeConfigs(data);
    //   }
    // }
       
      
  };
  alertHandler = (result, stack) => {
    this.setState({ alert_param: ""})
  }
  render() {
    console.log("TATE", this.state);
    //console.log("date fill",this.state.fil_start)
    console.log("MULTISELECT multiselect_filters", this.state.multiselect_filters);
    // console.log("MULTISELECT group_companies_filter", this.state.group_companies_filter);
    // console.log("AdvanceFilter",this.state.group_companies_filter.length)
  
    //if (this.state.group_companies_filter.length === 0) {
    if (this.state.multiselect_filters.group_companies.length === 0) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    } else {
      return (
        <div>
          <ConfigContainer>
            {(() => {
              if (this.props.reportType !== "answer_analysis" && this.props.reportType !== "file_audit_data" && this.props.reportType !== "fee_earner_analysis") {
                return (
                  <div style={{ color: "#222222", fontWeight: "600", marginTop: "15px", marginBottom: "15px" }}>
                    {(() => {
                      // if(this.props.reportType !== 'answer_analysis'){
                      if (this.state.filter_type == "company") {
                        return <FaRegSquare onClick={this.changeFilter} style={{ cursor: "pointer", marginRight: "18px" }} />;
                      } else {
                        return <FaRegCheckSquare onClick={this.changeFilter} style={{ cursor: "pointer", marginRight: "18px" }} />;
                      }
                      //}
                    })()}
                    Filter by Configuration
                  </div>
                );
              }
            })()}
            {(() => {
              if (this.props.reportType !== "answer_analysis" && this.props.reportType !== "file_audit_data" && this.props.reportType !== "fee_earner_analysis") {
                return <ConfigFilter style={{ marginBottom: "15px" }} configData={this.state.tagList} changeConfigs={this.changeConfigs} user_selection={this.state.user_selection} configDisabled={this.state.config_disabled} />;
              }
            })()}
            {(() => {
              if (this.props.reportType !== "answer_analysis" && this.props.reportType !== "file_audit_data" && this.props.reportType !== "fee_earner_analysis") {
                return (
                  <div style={{ color: "#222222", fontWeight: "600", marginTop: "15px" }}>
                    {(() => {
                      if (this.state.filter_type == "company") {
                        return <FaRegCheckSquare onClick={this.changeFilter} style={{ cursor: "pointer", marginRight: "18px" }} />;
                      } else {
                        return <FaRegSquare onClick={this.changeFilter} style={{ cursor: "pointer", marginRight: "18px" }} />;
                      }
                    })()}
                    Filter by Group Companies
                  </div>
                );
              }
            })()}

            {(() => {
              if (this.props.reportType !== "answer_analysis" && this.props.reportType !== "file_audit_data" && this.props.reportType !== "fee_earner_analysis") {
                if (this.state.config_disabled) {
                  return (
                    <div style={{ marginTop: "5px" }}>
                      <CSLTable add={false} processData={this.tableData} headerText={""} tableRows="6" />
                    </div>
                  );
                } else {
                  return (
                    <div style={{ marginTop: "5px", pointerEvents: "none", backgroundColor: "#ffffff", opacity: "0.3" }}>
                      <CSLTable add={false} processData={this.tableData} headerText={""} tableRows="6" />
                    </div>
                  );
                }
              }
            })()}

           <div>
           <MultiGridDivs>

              <div style={{ display: "grid", width: "100%"}}>
                <ReportLabel>Reviewer</ReportLabel>
                {Object.keys(this.state.multiselect_filters).map((id, i) => {
                        //console.log('=>>id',id)
                        if(id === 'reviewer'){
                        let filter = this.state.multiselect_filters[id];
                        const filterCells = { 1: FilterCell1, 2: FilterCell2, 3: FilterCell3, 4: FilterCell4, 5: FilterCell5, 6: FilterCell6 };
                        const FilterCell = filterCells[2];
                        const filterInputs = { multi: CSLMultiSelect };
                        const FilterInput = filterInputs[filter.type];
                        // console.log("Reviewer AdvanceFilter id", id);
                        // console.log("Reviewer AdvanceFilter filter", filter);
                        // console.log('Reviewer AdvanceFilter filterInputs, FilterInput', filterInputs, FilterInput)
                        if (Object.keys(this.state.multiselect_filters[id].options).length <= 1) return <div key={i}></div>;
                        //if('is_grouping' in this.state.filters[id]) {
                        return (
                          <FilterCell key={i} style={{ width: "100%" }}>
                            {/*<Label>{filter.nickname}</Label>*/}
                            {(() => {
                              return <FilterInput  items={filter.options} selectedItems={filter.selections} 
                              stack={{ type: filter.type, filter_id: id }} 
                              selectCallback={this.selectCallback} deselectCallback={this.deselectCallback} />;
                            })()}
                          </FilterCell>
                        );
                        }
                      })}
              </div>
              <div style={{ display: "grid", width: "100%"}}>
                <ReportLabel style={{}}>Reviewee</ReportLabel>
                {Object.keys(this.state.multiselect_filters).map((id, i) => {
                        //console.log('=>>id',id)
                        if(id === 'reviewee'){
                        let filter = this.state.multiselect_filters[id];
                        const filterCells = { 1: FilterCell1, 2: FilterCell2, 3: FilterCell3, 4: FilterCell4, 5: FilterCell5, 6: FilterCell6 };
                        const FilterCell = filterCells[2];
                        const filterInputs = { multi: CSLMultiSelect };
                        const FilterInput = filterInputs[filter.type];
                        // console.log("Reviewer AdvanceFilter id", id);
                        // console.log("Reviewer AdvanceFilter filter", filter);
                        // console.log('Reviewer AdvanceFilter filterInputs, FilterInput', filterInputs, FilterInput)
                        if (Object.keys(this.state.multiselect_filters[id].options).length <= 1) return <div key={i}></div>;
                        //if('is_grouping' in this.state.filters[id]) {
                        return (
                          <FilterCell key={i} style={{ width: "100%" }}>
                            {/*<Label>{filter.nickname}</Label>*/}
                            {(() => {
                              return <FilterInput items={filter.options} selectedItems={filter.selections} 
                              stack={{ type: filter.type, filter_id: id }} 
                              selectCallback={this.selectCallback} deselectCallback={this.deselectCallback} />;
                            })()}
                          </FilterCell>
                        );
                        }
                      })}
                
              </div>
              <div style={{ display: "grid", width: "100%"}}>
                <ReportLabel style={{}}>File Reference</ReportLabel>

                {/* <ReportSelect style={{ width: "100%" }} onChange={this.handleChange} name="cur_file_reference_id" value={this.state.cur_file_reference_id}>
                  {(() => {
                    if (this.state.file_reference.length !== 0) {
                      var file_ref = [];
                      file_ref.push(
                        <option key={0} value={"0"}>
                          {"All"}
                        </option>
                      );
                      for (let cur_item of this.state.file_reference) {
                        file_ref.push(
                          <option key={cur_item.name} value={cur_item.name}>
                            {cur_item.name}
                          </option>
                        );
                      }
                    }
                    return file_ref;
                  })()}
                </ReportSelect> */}
                {Object.keys(this.state.multiselect_filters).map((id, i) => {
                        //console.log('=>>id',id)
                        if(id === 'file_ref'){
                        let filter = this.state.multiselect_filters[id];
                        const filterCells = { 1: FilterCell1, 2: FilterCell2, 3: FilterCell3, 4: FilterCell4, 5: FilterCell5, 6: FilterCell6 };
                        const FilterCell = filterCells[2];
                        const filterInputs = { multi: CSLMultiSelect };
                        const FilterInput = filterInputs[filter.type];
                         //console.log("Reviewer AdvanceFilter id", id);
                        // console.log("Reviewer AdvanceFilter filter", filter);
                        // console.log('Reviewer AdvanceFilter filterInputs, FilterInput', filterInputs, FilterInput)
                        if (Object.keys(this.state.multiselect_filters[id].options).length <= 1) return <div key={i}></div>;
                        //if('is_grouping' in this.state.filters[id]) {
                        return (
                          <FilterCell key={i} style={{ width: "100%" }}>
                            {/*<Label>{filter.nickname}</Label>*/}
                            {(() => {
                              return <FilterInput items={filter.options} selectedItems={filter.selections} 
                              stack={{ type: filter.type, filter_id: id }} 
                              selectCallback={this.selectCallback} deselectCallback={this.deselectCallback} />;
                            })()}
                          </FilterCell>
                        );
                        }
                      })}
              </div>
              
              <div style={{ display: "grid", width: "100%"}}>
                <ReportLabel>File Check Type</ReportLabel>
                <ReportSelect style={{ width: "100%",height: "44px", margin: "-11px 0 0 0"}} onChange={this.handleChange} name="cur_review_id" >
                 {/*value={this.state.cur_review_id} */}
                  {(() => {
                    if (Store.getStoreData("my_reviews").length !== 0) {
                      let file_check_type = [];
                      file_check_type.push(
                        <option key={0} value={"0"}>
                          {"Select"}
                        </option>
                      );
                      let all_companies = this.state.multiselect_filters['group_companies'];
                      console.log("GROUPCOMP COMP::",all_companies)
                      let store_reviews = Store.getStoreData("my_reviews");
                      //console.log("GROUPCOMP store_reviews::",store_reviews)
                      let logged_user_role = Store.getStoreData("loggedin_user_info").role;
                      let parent_comp_id = Store.getStoreData("parent_company").id.toString();
                       //console.log("GROUPCOMP parent_comp_id::",parent_comp_id)
                       //console.log("GROUPCOMP logged_user_role::",logged_user_role)
                      //let my_reviews = [];
                      if(logged_user_role === "manager" || logged_user_role === 'senior_manager'){
                        for (let item of store_reviews) {
                         
                          let review_company = JSON.parse(item.review_json).general.company_id.toString();
                         
                          //console.log("GROUPCOMP exists::",Object.values(all_companies.options).filter(function (comp) { return comp.id == review_company }))
                          let comp_in_multi = Object.values(all_companies.options).filter(function (comp) { return comp.id == review_company })
                          if( review_company === parent_comp_id || comp_in_multi.length > 0){
                            //Object.values(all_companies.options).indexOf(review_company) >= 0 
                              {/*console.log("GROUPCOMP review_company::",review_company)
                              console.log("GROUPCOMP review::",item.id)
                              console.log("GROUPCOMP review::",item.nick_name)*/}
                              file_check_type.push(
                                <option key={item.id} value={item.id}>
                                  {item.nick_name}
                                </option>
                              );
                          }
                          
                        }
                      }else{
                        for (let item of store_reviews) {
                          //console.log(item.id)
                          //console.log(item.nick_name)
                          file_check_type.push(
                            <option key={item.id} value={item.id}>
                              {item.nick_name}
                            </option>
                          );
                        }
                      }
                      return file_check_type;
                    }
                  })()}
                </ReportSelect>
              </div>
              </MultiGridDivs>
              <div style={{ display:"flex" }}>
                <div style={{ display:"grid",width: "33%" }}>
                <ReportLabel style={{margin:"0 0 10px 0"}}>Date Range</ReportLabel>
                <ReportSelect style={{  margin: "0 0 20px 0", width: "16vw"}} onChange={this.handleDateChange} name="date_filters"
                value={this.state.date_filters}>
                  {(() => {
                    let datefilters = [];
                    for (let item of date_filter) {
                      datefilters.push(
                        <option key={item.key} value={item.key}>
                          {item.value}
                        </option>
                      );
                    }
                    return datefilters;
                  })()}
                </ReportSelect>
                </div>
              {(() => {
              if (this.state.date_filters == "customrange") {
                return (
                  <div style={{ /*marginTop: "15px",*/width:"66%" }}>
                    <ReportLabel>Start Date</ReportLabel>
                    <ReportLabel style={{ marginLeft: "30%" }}>End Date</ReportLabel>
                    <div>
                      <CSLDateCover style={{ display: "inline-block" }}>
                        <DatePicker onChange={this.onStartDateChange} clearIcon={null} calendarIcon={null}
                         locale={"en-GB"} value={new Date(this.state.start_date)} />
                      </CSLDateCover>
                      <CSLDateCover style={{ display: "inline-block", marginLeft: "2%" }}>
                        <DatePicker onChange={this.onEndDateChange} clearIcon={null} calendarIcon={null} 
                        locale={"en-GB"} value={new Date(this.state.end_date)} />
                      </CSLDateCover>
                    </div>
                  </div>
                );
              }
              else{
                return (
                  <div style={{/*marginTop: "15px",*/width:"66%" }}>
                    <ReportLabel>Start Date</ReportLabel>
                    <ReportLabel style={{ marginLeft: "30%" }}>End Date</ReportLabel>
                    <div>
                      <CSLDateCover style={{ display: "inline-block" }}>
                        <DatePicker onChange={this.onStartDateChange} clearIcon={null} calendarIcon={null} 
                        locale={"en-GB"} value={this.state.start_date} />
                      </CSLDateCover>
                      <CSLDateCover style={{ display: "inline-block", marginLeft: "2%" }}>
                        <DatePicker onChange={this.onEndDateChange} clearIcon={null} calendarIcon={null} 
                        locale={"en-GB"} value={this.state.end_date} />

                      </CSLDateCover>
                    </div>
                  </div>
                );
              }
            })()}
            </div>
              {(() => {
                //if (this.props.reportType === "answer_analysis" || this.props.reportType === "file_audit_data" || this.props.reportType === "fee_earner_analysis") {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ReportLabel style={{}}>Group Companies</ReportLabel>
                      {Object.keys(this.state.multiselect_filters).map((id, i) => {
                        //console.log('=>>id',id)
                        if(id === 'group_companies'){
                        let filter = this.state.multiselect_filters[id];
                        const filterCells = { 1: FilterCell1, 2: FilterCell2, 3: FilterCell3, 4: FilterCell4, 5: FilterCell5, 6: FilterCell6 };
                        const FilterCell = filterCells[2];
                        const filterInputs = { multi: CSLMultiSelect };
                        const FilterInput = filterInputs[filter.type];
                        //console.log("AdvanceFilter id", id);
                        //console.log("AdvanceFilter filter", filter);
                        // console.log('AdvanceFilter filterInputs, FilterInput', filterInputs, FilterInput)
                        if (Object.keys(this.state.multiselect_filters[id].options).length <= 1) return <div key={i}></div>;
                        //if('is_grouping' in this.state.filters[id]) {
                        return (
                          <FilterCell key={i} style={{ width: "50vw" }}>
                            {/*<Label>{filter.nickname}</Label>*/}
                            {(() => {
                              return <FilterInput items={filter.options} selectedItems={filter.selections} stack={{ type: filter.type, filter_id: "group_companies" }} selectCallback={this.selectCallback} deselectCallback={this.deselectCallback} />;
                            })()}
                          </FilterCell>
                        );
                        }
                      })}
                    </div>
                  );
                //}
              })()}
            </div>

            
            {/*<MRButton onClick={this.taskDataFilter()}>Apply</MRButton>*/ } 
            <MRButton onClick={()=>{this.taskDataFilter_call()}}>Apply</MRButton>
          </ConfigContainer>
          <ConfigContainer style={{ marginTop: "10px" }}>
            {(() => {
              if(this.state.alert_param != ""){
                
                let alert_param = {
                  title: "Alert", message: this.state.alert_param, ok_text: 'Ok', confirm: false,
                  alertHandler: this.alertHandler, stack: { id: 0 }
                }
                return <AlertBox alertParam={alert_param} />;
              }else{
              if (this.state.load_report == true) {
                let reviewer_name = "";
               if(this.state.cur_reviewer_id.length > 0){
                  //if(this.state.cur_reviewer_id.includes('0')){
                  if('All' in this.state.multiselect_filters.reviewer.selections){
                    reviewer_name = "All";
                  }else{
                    for(let i =0;i <this.state.cur_reviewer_id.length;i++){
                      if(reviewer_name === ""){
                        reviewer_name = Store.getStoreData("users")[this.state.cur_reviewer_id[i]].ContactName;
                      }else{
                        reviewer_name = reviewer_name+","+Store.getStoreData("users")[this.state.cur_reviewer_id[i]].ContactName;
                      }
                    }
                  }
                }else{
                  reviewer_name = "All";
                }
                //let reviewer_name = this.state.cur_reviewer_id.length === 0 ? "All" : Store.getStoreData("users")[this.state.cur_reviewer_id].ContactName;
                //console.log('reviewer_name',reviewer_name);
                switch (this.props.reportType) {
                  case "fee_earner_analysis":
                    return (
                      <FeeEarnarAnalysis
                        FeeData={{
                          startDate: this.state.fil_start,
                          endDate: this.state.fil_end,
                          division: this.state.division,
                          reviewer_name: reviewer_name,                          
                          // question_type: this.state.cur_review_id,
                          question_type: this.state.cur_review_name,
                          feeEarnerData: this.state.feeEarnerData,
                        }}
                      />
                    );
                    break;
                  case "file_audit_data":
                    return <FileAuditData />;
                  case "answer_analysis":
                    return (
                      <AnswerAnalysis
                        AnswerData={{
                          startDate: this.state.fil_start,
                          endDate: this.state.fil_end,
                          division: this.state.division,
                          reviewer_name: reviewer_name,
                          //question_type: this.state.cur_review_id,
                          question_type: this.state.cur_review_name,
                          answerAnalysisData: this.state.answer_analysis_data,
                          reviewee_analysis_data: this.state.reviewee_analysis_data,
                          //fc_total:0
                        }}
                        refreshCallback={this.refreshState}
                      />
                    );
                }
              } else {
                return (
                  <div>
                    <CSLLoader />
                  </div>
                );
              }
            }
            })()}
          </ConfigContainer>
        </div>
      );
    }
  }
}

export default ReportFilter;
