import React from 'react';
import styled from 'styled-components';
import {FaTimes,FaTrashAlt, FaPlusCircle,FaChevronUp} from 'react-icons/fa';
import Store from '../../Common/Store';
import DatePicker from 'react-date-picker';
import Dropzone from '../Common/Dropzone.js'
import {FiDownload} from 'react-icons/fi'
import { saveAs } from 'file-saver';
import * as Style from '../Common/StyledComponents'
import '../../App.css';
import APICall from '../../Common/APICall';
import QuestionAnswerForm from '../QuestionAnswerForm';
import TasksList from '../TasksList'
import {IoIosArrowForward} from 'react-icons/io';
import Scoresegment from './Scoresegment';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 90%;
    left: 5%;
`;

const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
 
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;

const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;

const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;

const Tabdiv1 = styled.div`
    width: 20%;
    float: left;
    
`;
const Tabdiv2 = styled.div`
    width: 80%;
    float: left;
    background-color: #ffffff;
    padding: 22px 16px;
    margin-bottom: 7px;
`;

const Tabdiv = styled.div`
    width: 100%;
    `;

const Tabbutton = styled.div`
    display: block;
    
    color: #303030;
    padding: 16px 23px;
    width: 82%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 17px;
    margin: 5px;
    background-color: white
`;

const Tabbuttonactive = styled.div`
    display: block;
    
    color: #303030;
    padding: 16px 23px;
    width: 82%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 17px;
    margin: 5px;
    background-color: white;
    font-weight: bold;
`;

class AssignFileCheckModal extends React.Component
{
    state = {
        cur_review_id: 0,
        cur_reviewer_id: 0,
        cur_reviewee_id: 0,
        customer_name: "",
        reviewee_designation: "",
        file_reference: "",
        date: new Date(),
        due_date: '1970-01-01',
        sale_date: '1970-01-01',
        my_reviews: [],
        reviewers: [],
        reviewees: [],
        binFiles: [],
        curBinFiles: [],
        review_list: [],
        temp_reviewers: [],
        gc_companies: [],
        cur_gc_id: 0,
        cur_gc_user: [],
        is_gc: false,
        mode: null,
        cur_review_json: null,
        qanda: null,
        cur_task_id: 0,
        review_json: {},
        comment: "",
        show_tasks_modal: false,
        isActive: "1",
        navs: [{name: "Overview", dispcomp: Tabbuttonactive}, {name: "Section1", dispcomp: Tabbutton}, {name: "Section2", dispcomp: Tabbutton}, {name: "Section3", dispcomp: Tabbutton}, {name: "Section4", dispcomp: Tabbutton}],
        scores: [
            {lbound: 0, ubound: 70, termname: "Non Compliant", actual_outcome: "Fail", color: "#ff0000"},
            {lbound: 70, ubound: 80, termname: "Indeterminate", actual_outcome: "Indeterminate", color: "#ffff00"},
            {lbound: 80, ubound: 100, termname: "Compliant", actual_outcome: "Pass", color: "#00ff00"}
        ],
        obtainedscorepercentage: 30
        
    };

    changeComment = (event) => {
        event.preventDefault();
        this.setState({comment: event.target.value});
    }


    componentDidMount()
    {
        
        //change end
        let index = Store.getStoreData('fc_index');
        let my_reviews = Store.getStoreData('my_reviews');
        let contacts = Store.getStoreData('reviewee_info');
        let com_roles = Store.getStoreData('com_roles');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let review_list = Store.getStoreData('reviewee_list');
        let reviewers = {};
        let reviewees = {};
        let customer_name = '';
        // console.log('loggedin_user_info.ID', loggedin_user_info.ID);
        Object.entries(contacts).forEach(([key, value]) => {
            if (loggedin_user_info.ID.toString() !== key.toString() && com_roles[key.toString()] === 'file_checker' ) {
                reviewers[key] = value;
            }
            reviewees[key] = value;
        })
        let gc_companies = Store.getStoreData('gc_list');
        let cur_gc_id = gc_companies.length === 0 ? 0 : gc_companies[0].id;
        let is_gc = gc_companies[0].is_gc;
        let cur_gc_user = [];
        for(let gc in gc_companies[0].users){
            for(let item of review_list){
                if(parseInt(item.ID) === parseInt(gc)){
                    cur_gc_user.push({"ID" : gc, "contact_name": item.ContactName })
                }
            }
        }

        let temp_reviewers = [];
        for(let item of cur_gc_user){
            if (loggedin_user_info.ID.toString() !== item.ID.toString() && com_roles[item.ID.toString()] != 'team' ) {
                temp_reviewers.push({"id": item.ID, "value": item.contact_name});
            }
        }

        let cur_review_id = my_reviews.length === 0 ? 0  : my_reviews[0].id;
        let cur_reviewer_id = temp_reviewers.length === 0 ? 0 : temp_reviewers[0].id;
        let cur_reviewee_id = review_list.length === 0 ? 0 : review_list[0].ID;
        let this_task = null;
        let date = new Date();
        let due_date = new Date();
        let sale_date = new Date();
        let reviewee_designation = '';
        let curBinFiles = [];
        let file_reference = '';
        let mode = false;
        if(index > 0) {
            this_task = Store.getStoreData('pending_fctasks')[index-1];
            let this_task_id = this_task.id;
            // console.log(this_task_id);
            let postData = {command: "get_task", task_id:  this_task_id};
            // console.log(postData);
            let api = new APICall();
            api.command(postData, this.processTaskData);
        }else{
            this.setState({my_reviews, reviewers, reviewees, cur_review_id, cur_reviewer_id, cur_reviewee_id, customer_name, date, due_date, 
                sale_date, curBinFiles, reviewee_designation, file_reference, review_list, temp_reviewers, gc_companies, cur_gc_id, cur_gc_user, mode, is_gc});
        }
    }

    processTaskData = (result) => {
        let my_reviews = Store.getStoreData('my_reviews');
        let contacts = Store.getStoreData('reviewee_info');
        let com_roles = Store.getStoreData('com_roles');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let review_list = Store.getStoreData('reviewee_list');
        let reviewers = {};
        let reviewees = {};
        let customer_name = '';
        Object.entries(contacts).forEach(([key, value]) => {
            if (loggedin_user_info.ID.toString() !== key.toString() && com_roles[key.toString()] === 'file_checker' ) {
                reviewers[key] = value;
            }
            reviewees[key] = value;
        })
        let cur_review_id = my_reviews[0].id;
        let cur_reviewer_id = Object.keys(reviewers)[0];
        let cur_reviewee_id = review_list[0].ID;
        let date = new Date();
        let due_date = new Date();
        let sale_date = new Date();
        let reviewee_designation = '';
        let curBinFiles = [];
        let file_reference = '';
        let task = result.result.task.parenttask;
        console.log("task:", task)
        let gc_companies = Store.getStoreData('gc_list');
        let gc_assoc = Store.getStoreData('gc_assoc')
        let cur_gc_id = parseInt(task.company_id) < 100000 ? task.company_id : gc_assoc[(parseInt(task.company_id)%100000).toString()].id;
        let cur_gc_user = [];
        for(let gc in gc_assoc[cur_gc_id].users){
            for(let item of review_list){
                if(parseInt(item.ID) === parseInt(gc)){
                    cur_gc_user.push({"ID" : gc, "contact_name": item.ContactName })
                }
            }
        }

        let temp_reviewers = [];
        for(let item of cur_gc_user){
            if (loggedin_user_info.ID.toString() !== item.ID.toString() && com_roles[item.ID.toString()] != 'team' ) {
                temp_reviewers.push({"id": item.ID, "value": item.contact_name});
            }
        }
        let task_json = JSON.parse(task.task_json);
        let qanda = 'qanda' in task_json.object_data ? task_json.object_data.qanda : null
        let comment = 'result' in task_json.object_data ? task_json.object_data.result.comment.replace(/''+/g, "'") : ''
        let cur_review_json = JSON.parse(JSON.stringify(task_json.object_data.headerinfo.review_json));

        let cur_task_id = task.id;

        console.log("cur_task_id", cur_task_id)
        cur_review_id = task_json.object_data.headerinfo.cur_review_id;
        customer_name = task_json.object_data.headerinfo.customer_name;
        cur_reviewer_id = task_json.object_data.headerinfo.reviewer_id;
        cur_reviewee_id = task_json.object_data.headerinfo.reviewee_id;
        reviewee_designation = task_json.object_data.headerinfo.reviewee_designation;
        due_date = new Date(task.due_date);
        if('sale_date' in task_json.task_data) {
            sale_date = new Date(task_json.task_data.sale_date);
        }
        if('bin_files' in task_json.object_data) {
            curBinFiles = task_json.object_data.bin_files;
        }
        let mode = true;
        file_reference = task_json.object_data.headerinfo.file_reference;
        this.setState({my_reviews, reviewers, reviewees, cur_review_id, cur_reviewer_id, cur_reviewee_id, customer_name, date, due_date, sale_date, curBinFiles, 
            reviewee_designation, file_reference, review_list, temp_reviewers, gc_companies, cur_gc_id, cur_gc_user, mode, cur_review_json, qanda, cur_task_id });
    }


    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    handleChangeCurGc = (evt) => {
        let review_list = Store.getStoreData('reviewee_list');
        let gc_companies = Store.getStoreData('gc_list');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let com_roles = Store.getStoreData('com_roles');
        let cur_gc_user = [];
        let is_gc = false;
        for(let gc of gc_companies) {
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                for (let key in gc.users){
                    for(let item of review_list){
                        if(parseInt(item.ID) === parseInt(key)){
                            cur_gc_user.push({"ID" : key, "contact_name": item.ContactName })
                        }
                    }
                }
                is_gc = gc.is_gc;
            }
        }
        let temp_reviewers = [];
        for(let item of cur_gc_user){
            if (loggedin_user_info.ID.toString() !== item.ID.toString() && com_roles[item.ID.toString()] != 'team' ) {
                temp_reviewers.push({"id": item.ID, "value": item.contact_name});
            }
        }
        this.setState({cur_gc_id:evt.target.value, cur_gc_user: cur_gc_user, is_gc: is_gc, temp_reviewers: temp_reviewers })
    }

    handleReviewerChange = (event) => {
        event.preventDefault();
        this.setState({cur_reviewer_id: parseInt(event.target.value)});
    }

    assignFCKJob = (index) => (event) => {
        event.preventDefault();
        const cur_review = this.state.my_reviews.find((item) => {
            return item.id === parseInt(this.state.cur_review_id);
        })
        let gr_company_id = this.state.cur_gc_id;
        let is_gc = this.state.is_gc;
        let headerinfo = {
            cur_review_id: parseInt(this.state.cur_review_id),
            customer_name: this.state.customer_name,
            date: this.state.date,
            file_reference: this.state.file_reference,
            reviewee_designation: this.state.reviewee_designation,
            reviewer_id: parseInt(this.state.cur_reviewer_id),
            reviewee_id: parseInt(this.state.cur_reviewee_id),
            review_json: JSON.parse(cur_review.review_json)
        };
        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 

        curmonth = parseInt(this.state.sale_date.getMonth());
        currmonth = curmonth + 1;
        var saleDateStr = currmonth >= 10 ? this.state.sale_date.getFullYear()+'/'+currmonth+'/' : this.state.sale_date.getFullYear()+'/0'+currmonth+'/';
        saleDateStr += this.state.sale_date.getDate() < 10 ? '0' + this.state.sale_date.getDate() : this.state.sale_date.getDate(); 

        let scorecolor = null;
        let scorename = null;        
        let obtainedscore = 0;
        let totalscore = 0;
        let actual_outcome = '';

        let task_json = {
            "task_data": { module_id: "filechecker", "last_action": "COMP_FCK_CREATE", "actiondate": Date.now(), 
                        'company_id' : 0, 'contact_id' : 0, 'system_id' : '1001', 'cur_lane' : 'COMP_FCK_ASSIGNMENT',
                        "cur_assigned_to" : parseInt(this.state.cur_reviewer_id), 'task_type' : 'COMP_FCK', 'parent_task_id' : 0, 'due_date' : dueDateStr, 'sale_date' : saleDateStr, 'gr_company_id': gr_company_id,'is_gc': is_gc },
            "object_data": {headerinfo: headerinfo, bin_files: [...this.state.binFiles, ...this.state.curBinFiles],qanda: Store.getStoreData('qanda'),
            result: {
                comment: this.state.comment,
                ragratingcolor: scorecolor,
                ragratingname: scorename,
                scoresobtained: obtainedscore,
                totalscore: totalscore,
                outcome: actual_outcome
            }},
            "action_data": [
                { action: "COMP_FCK_CREATE", actiondate: Date.now(), assigned_to: parseInt(this.state.cur_reviewer_id), cur_lane: "COMP_FCK_ASSIGNMENT" }
            ]
        };
        // let url = Store.getStoreData('api_url');
        // let is_admin = Store.getStoreData('is_admin');
        // let is_debug = Store.getStoreData('is_debug');

        let postData = {command: "create_filecheck_task", task_json: task_json, mail_url: window.location.href };
        let fc_index = Store.getStoreData('fc_index');

        if(fc_index > 0) {
            let this_task = Store.getStoreData('pending_fctasks')[fc_index-1];
            task_json['task_data'].last_action = 'COMP_FCK_COMPLETE'
            task_json['task_data'].cur_lane = 'COMP_FCK_COMPLETE'
            task_json.action_data = {action: "COMP_FCK_COMPLETE", actiondate: Date.now(), assigned_to: 0, performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_COMPLETE" };
            postData = {command: "update_filecheck_task", task_json: task_json, task_id: this_task.id };
        }

        console.log("postData", postData);

        let api = new APICall();
        api.command(postData, this.reloadData);
    }

    reloadData = () => {
        alert('Filecheck task created and assigned successfully.');
        window.location.reload(true);
    }

    closeModal = () => {
        Store.updateStore('OverlayForModal', null);
        this.props.refreshParent(null);
    }

    onDateChange = (date) => {
        this.setState({due_date: date});
    }

    onSaleDateChange = (date) => {
        this.setState({sale_date: date});
    }

    filesLoaded = (files) => {
        let me = Store.getStoreData('loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
            }
        }
        // console.log('files in Modal', files);
        this.setState({binFiles: files});
    }

    handleDownload = (index) => {
        let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }

    showFollowupTaskModal = (event) => {
        event.preventDefault()
        this.setState({'show_tasks_modal' : true})
    }

    closeFollowupTaskModal = () => {
        // event.preventDefault()
        this.setState({'show_tasks_modal' : false})
    }

    selectedTab = (index, navname) => (event) => {
        //console.log(index);
        let {navs} = this.state;
        navs.forEach((item) => {
            item.dispcomp = Tabbutton;
            if (item.name === navname) {
                item.dispcomp = Tabbuttonactive;
            }
        })
        this.setState({isActive : index.toString(), navs})
        event.preventDefault();
    }

    render()
    {
        
        let fc_index = Store.getStoreData('fc_index');
         console.log('fc_index', fc_index);
        let gc_assoc = Store.getStoreData('gc_assoc');
        let review_name = (this.state.cur_review_json !== null) ? ': ' +this.state.cur_review_json.name : '';
        let reveiw_name = "";
        let cur_task_id = this.state.cur_task_id;
        for(let key of this.state.my_reviews){
            if(parseInt(key.id) === parseInt(this.state.cur_review_id)){
                reveiw_name = key.nick_name;
            }
        }
        // console.log("review list", this.state.my_reviews);
        if(this.state.mode === null){
            return(<div>Loading</div>)
        }
        if(this.state.my_reviews.length === 0){
            return(
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Assign a File Checking Job</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    </MRModalHeader>
                <MRModalBody>
                    <MRModalBodyInner>
                        No review types are available. Please setup at-least one review type to be able to asign file review jobs.
                    </MRModalBodyInner>
                </MRModalBody>
                <MRModalFooter>
                <MRModalNextBtn onClick={this.closeModal}>Close</MRModalNextBtn>
                </MRModalFooter>
                </MRModalContainer>)
        }
        return (
            <MRModalContainer>
                <MRModalHeader>
                    {
                        (() => {
                            if(this.state.mode === false){
                                return(<MRModalHeaderText>Assign a File Checking Job</MRModalHeaderText>)
                            }else{
                                return(<MRModalHeaderText style={{fontWeight:"bold"}}>GRP Underwriting</MRModalHeaderText>)
                            }
                        })()
                    }
                    
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>
                </MRModalHeader>

                <MRModalBody>
                    <MRModalBodyInner>
                        <Tabdiv>
                            <Tabdiv1>
                                {
                                    this.state.navs.map((item, index) => {
                                        let ind = index + 1;
                                        if (index === 0) {
                                            return (<item.dispcomp key={index} onClick={this.selectedTab(ind, item.name)} style={{marginTop: "0px"}}>{item.name} <IoIosArrowForward style={{float:'right', color: "#37ada7", marginTop: "4px"}}/></item.dispcomp>);    
                                        } else {
                                            return (<item.dispcomp key={index} onClick={this.selectedTab(ind, item.name)}>{item.name} <IoIosArrowForward style={{float:'right', color: "#37ada7", marginTop: "4px"}}/></item.dispcomp>);
                                        }
                                    })
                                }
                                <div style={{paddingRight: "23px", paddingTop: "10px"}}>
                                    <div style={{height: "3px", width: "100%", backgroundColor: "#e9e9e9"}}></div>
                                </div>
                                <div style={{paddingRight: "23px", paddingTop: "10px", width: "100%"}}>
                                    <div style={{backgroundColor: "#213e61", color: "#ffffff", fontSize: "11px", padding: "10px", borderRadius: "3px"}}>
                                        <div style={{float: "left"}}>Questions Answered</div>
                                        <div style={{float: "right"}}>05/21</div>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                </div>
                                <div style={{paddingRight: "23px", paddingTop: "15px", width: "100%"}}>
                                    <Scoresegment scores={this.state.scores} obtainedscore={this.state.obtainedscorepercentage} />
                                </div>
                            </Tabdiv1>
                            {
                                (()=>{
                                    
                                    switch(this.state.isActive) {
                                        case '2' : return <Tabdiv2><div>London2</div></Tabdiv2>;break
                                        default : return <Tabdiv2><div>home</div></Tabdiv2>
                                    }
                                    
                                })()
                            }
                            <div style={{clear: "both"}}></div>
                        </Tabdiv>
                    </MRModalBodyInner>
                </MRModalBody>

               <MRModalFooter>
                {
                    (() => {
                        if(this.state.my_reviews.length > 0)
                            {
                                return(<MRModalNextBtn onClick={this.assignFCKJob(fc_index)}>Submit</MRModalNextBtn>)
                            }

                    })()
                } 
                <MRModalNextBtn onClick={this.closeModal}>Close</MRModalNextBtn>
                <div style={{clear: "both"}}></div>
                </MRModalFooter>
            </MRModalContainer>
        );
    }
}

export default AssignFileCheckModal;


