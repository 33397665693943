import React from 'react';
import Store from '../../Common/Store';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import * as Style from '../Common/StyledComponents';
import Dropzone from '../Common/Dropzone.js';
import APICall from '../../Common/APICall';
import {FiDownload} from 'react-icons/fi';
import {FaTimes, FaStarOfLife} from 'react-icons/fa';
const AssignContainer = styled.div`
    width: 50%;
    position: absolute;
    z-index: 1001;
    left: -12%;
    margin-left: 25%;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding-bottom: 20px;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
    font-weight: 600;
    font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border-radius: 4px;
    padding: 15px;
    
`;
const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 28px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;
const MRModalFooter = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;

const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 20%;
    padding-top: 7px;
    font-weight: 600;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 80%;
    padding-top:7px;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    `;
const ScoreContainer = styled.div`
    background-color: #ecf0f1;
    width: 100%;
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
    margin-top: 10px;
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`

const ScoreBodyContainer = styled.div`
    padding: 15px;
`
const Tabbutton = styled.div`
    display: block;
    
    color: #303030;
    padding: 16px 23px;
    width: 65%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 17px;
    margin: 5px;
    background-color: white
`;

const Tabbuttonactive = styled.div`
    display: block;
    
    color: #303030;
    padding: 16px 23px;
    width: 65%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 17px;
    margin: 5px;
    background-color: white;
    font-weight: bold;
`;

class AssignView extends React.Component
{

    state = {
        my_reviews: [], reviewers: null, reviewees: null, cur_review_id: null, cur_reviewer_id: null, cur_reviewee_id: null, customer_name: null,
        date: null, due_date: null, sale_date: null,binFiles: [], curBinFiles: null, reviewee_designation: null, file_reference: null, review_list: null, temp_reviewers: null,
        gc_companies: null, cur_gc_id: null,cur_gc_user: null, ready: false, is_gc: null, cur_review_json: null,
        navs: [{name: "Overview", dispcomp: Tabbuttonactive}, {name: "Section1", dispcomp: Tabbutton}, {name: "Section2", dispcomp: Tabbutton}, {name: "Section3", dispcomp: Tabbutton}, {name: "Section4", dispcomp: Tabbutton}],
        isActive: '1', reviewee_designation_required: false, send_mail_config: false, button_diasabled: false
    };

    componentDidMount() {
        let my_reviews = Store.getStoreData('my_active_reviews');
        let contacts = Store.getStoreData('reviewee_info');
        let com_roles = Store.getStoreData('com_roles');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let review_list = Store.getStoreData('reviewee_list');
        let module_config = Store.getStoreData('module_config');
        let reviewee_designation_required = false;
        let send_mail_config = false;
        if(Object.keys(module_config).length > 0) {
            reviewee_designation_required = module_config.general.reviewee_designation_required;
            send_mail_config =  module_config.general.email_remainder_enable;
        }
        let reviewers = {};
        let reviewees = {};
        let customer_name = '';
        Object.entries(contacts).forEach(([key, value]) => {
            if (loggedin_user_info.ID.toString() !== key.toString() && com_roles[key.toString()] === 'file_checker' ) {
                reviewers[key] = value;
            }
            reviewees[key] = value;
        })
        let gc_companies = Store.getStoreData('gc_list');
        let parent_company_id = gc_companies[0].parent_id;
        let cur_gc_id = gc_companies.length === 0 ? 0 : gc_companies[0].id;
        let gc_company_id = parseInt(parent_company_id) * 100000 + parseInt(cur_gc_id);
        console.log("cur_gc_id", gc_company_id);
        let temp_my_review = [];
        for(let i = 0; i < my_reviews.length; i++) {
            if(my_reviews[i].company_id === parent_company_id || my_reviews[i].company_id === gc_company_id) {
                temp_my_review.push(my_reviews[i]);
            }
        }

        // console.log("temp_my_review", temp_my_review, my_reviews);
        let cur_review_id = my_reviews.length === 0 ? 0  : my_reviews[0].id;

        let is_gc = gc_companies[0].is_gc;
        let cur_gc_user = [];
        for(let gc in gc_companies[0].users){
            for(let item of review_list){
                if(parseInt(item.ID) === parseInt(gc)){
                    cur_gc_user.push({"ID" : gc, "contact_name": item.ContactName })
                }
            }
        }

        let temp_reviewers = [];
        for(let item of cur_gc_user){
            if (com_roles[item.ID.toString()] != 'team') {
                temp_reviewers.push({"id": item.ID, "value": item.contact_name});
            }
        }

        // if (loggedin_user_info.ID.toString() !== key.toString()

        // let cur_reviewer_id = temp_reviewers.length === 0 ? 0 : temp_reviewers[0].id;
        let cur_reviewer_id = loggedin_user_info.ID;
        let temp_review_list = [];
        for(let temp_review of review_list){
            if(parseInt(temp_review.ID) != parseInt(cur_reviewer_id)){
                temp_review_list.push({"ID" : temp_review.ID, "ContactName": temp_review.ContactName })
            }
        }

        let cur_reviewee_id = temp_review_list.length === 0 ? 0 : temp_review_list[0].ID;
        let date = new Date();
        let due_date = new Date();
        let sale_date = new Date();
        let reviewee_designation = '';
        let curBinFiles = [];
        let file_reference = '';
        let ready = true;        
        this.setState({my_reviews: temp_my_review, reviewers, reviewees, cur_review_id, cur_reviewer_id, cur_reviewee_id, customer_name, date, due_date, 
            sale_date, curBinFiles, reviewee_designation, file_reference, review_list, temp_reviewers, gc_companies, cur_gc_id, cur_gc_user, ready, is_gc, reviewee_designation_required, send_mail_config});
    }   

    handleChangeCurGc = (evt) => {
        console.log(evt.target.value);
        let review_list = Store.getStoreData('reviewee_list');
        let gc_companies = Store.getStoreData('gc_list');
        let parent_company_id = gc_companies[0].parent_id;
        let gc_company_id = parseInt(parent_company_id) * 100000 + parseInt(evt.target.value);
        let my_reviews = Store.getStoreData('my_active_reviews');
        // let my_reviews = Store.getStoreData('my_reviews');
        let temp_my_review = [];
        for(let i = 0; i < my_reviews.length; i++) {
            if(my_reviews[i].company_id === parent_company_id || my_reviews[i].company_id === gc_company_id) {
                temp_my_review.push(my_reviews[i]);
            }
        }

        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let com_roles = Store.getStoreData('com_roles');
        let cur_gc_user = [];
        let is_gc = false;
        for(let gc of gc_companies) {
            if(parseInt(gc.id) === parseInt(evt.target.value)){
                for (let key in gc.users){
                    for(let item of review_list){
                        if(parseInt(item.ID) === parseInt(key)){
                            cur_gc_user.push({"ID" : key, "contact_name": item.ContactName })
                        }
                    }
                }
                is_gc = gc.is_gc;
            }
        }
        let temp_reviewers = [];
        for(let item of cur_gc_user){
            if (com_roles[item.ID.toString()] != 'team' ) {
                temp_reviewers.push({"id": item.ID, "value": item.contact_name});
            }
        }
        this.setState({cur_gc_id:evt.target.value, cur_gc_user: cur_gc_user, is_gc: is_gc, temp_reviewers: temp_reviewers, my_reviews: temp_my_review })
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }
    onSaleDateChange = (date) => {
        this.setState({sale_date: date});
    }
    handleReviewerChange = (event) => {
        event.preventDefault();
        this.setState({cur_reviewer_id: parseInt(event.target.value)});
    }
    onDateChange = (date) => {
        this.setState({due_date: date});
    }

    closeView = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    getMailStrucData = (data) => {
        const cur_review = this.state.my_reviews.find((item) => {
            return item.id === parseInt(this.state.cur_review_id);
        })
        let review_json_d = JSON.parse(cur_review.review_json);
        let reviewer_obj = this.state.review_list.find((obj) => {
            return obj.ID === parseInt(this.state.cur_reviewer_id);
        })
        let reviewee_obj = this.state.review_list.find((obj) => {
            return obj.ID === parseInt(this.state.cur_reviewer_id);
        })
        let gr_company_id = this.state.cur_gc_id;
        let com_roles = Store.getStoreData('com_roles');
        let gc_assoc = Store.getStoreData('gc_assoc')
        let gc_company = "";
        gc_company = parseInt(gr_company_id) < 100000 ? Store.getStoreData('my_company').CompanyName : gc_assoc[(parseInt(gr_company_id)%100000).toString()].company_name;
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let curmonth = parseInt(this.state.due_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
        dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 
        
        curmonth = parseInt(this.state.sale_date.getMonth());
        currmonth = curmonth + 1;
        var saleDateStr = currmonth >= 10 ? this.state.sale_date.getFullYear()+'/'+currmonth+'/' : this.state.sale_date.getFullYear()+'/0'+currmonth+'/';
        saleDateStr += this.state.sale_date.getDate() < 10 ? '0' + this.state.sale_date.getDate() : this.state.sale_date.getDate(); 

        let mail_body = data.body;
        mail_body = mail_body.replace("{{reviewer}}", reviewer_obj.ContactName);
        mail_body = mail_body.replace("{{reviewee}}", reviewee_obj.ContactName);
        mail_body = mail_body.replace("{{scheduled_date}}", dueDateStr);
        mail_body = mail_body.replace("{{task_name}}", review_json_d.general.name);
        mail_body = mail_body.replace("{{task_description}}", review_json_d.general.description);
        mail_body = mail_body.replace("{{status}}", "Create");
        mail_body = mail_body.replace("{{review_type}}", com_roles[reviewee_obj.ID.toString()]);
        mail_body = mail_body.replace("{{policy_start_date}}", saleDateStr);
        mail_body = mail_body.replace("{{created_date}}", this.state.date);
        mail_body = mail_body.replace("{{company}}", gc_company);
        mail_body = mail_body.replace("{{assigner}}", loggedin_user_info.ContactName);
        mail_body = mail_body.replace("{{file_reference}}", this.state.file_reference);
        mail_body = mail_body.replace("{{reviewee_designation}}", this.state.reviewee_designation);
        mail_body = mail_body.replace("{{customer_name}}", this.state.customer_name);
        mail_body = mail_body.replace("{{source_module}} ", "");
        mail_body = mail_body.replace("{{assigned_to}}", reviewer_obj.ContactName);
        data.body = mail_body;

        let mail_subject = data.subject;
        mail_subject = mail_subject.replace("{{reviewer}}", reviewer_obj.ContactName);
        mail_subject = mail_subject.replace("{{reviewee}}", reviewee_obj.ContactName);
        mail_subject = mail_subject.replace("{{scheduled_date}}", dueDateStr);
        mail_subject = mail_subject.replace("{{task_name}}", review_json_d.general.name);
        mail_subject = mail_subject.replace("{{task_description}}", review_json_d.general.description);
        mail_subject = mail_subject.replace("{{status}}", "Create");
        mail_subject = mail_subject.replace("{{review_type}}", com_roles[reviewee_obj.ID.toString()]);
        mail_subject = mail_subject.replace("{{policy_start_date}}", saleDateStr);
        mail_subject = mail_subject.replace("{{created_date}}", this.state.date);
        mail_subject = mail_subject.replace("{{company}}", gc_company);
        mail_subject = mail_subject.replace("{{assigner}}", loggedin_user_info.ContactName);
        mail_subject = mail_subject.replace("{{file_reference}}", this.state.file_reference);
        mail_subject = mail_subject.replace("{{reviewee_designation}}", this.state.reviewee_designation);
        mail_subject = mail_subject.replace("{{customer_name}}", this.state.customer_name);
        mail_subject = mail_subject.replace("{{source_module}} ", "");
        mail_subject = mail_subject.replace("{{assigned_to}}", reviewer_obj.ContactName);
        data.subject = mail_subject;
        return data;
    }

    assignFCKJob = (index) => (event) => {
        event.preventDefault();
        if(this.state.file_reference != ''){
            if(this.state.reviewee_designation_required && this.state.reviewee_designation === "") {
                alert('There are missing required fields. Please complete the required information to submit your review');
                return false;
            }
            const cur_review = this.state.my_reviews.find((item) => {
                return item.id === parseInt(this.state.cur_review_id);
            })
            let gr_company_id = this.state.cur_gc_id;
            let is_gc = this.state.is_gc;
            let headerinfo = {
                cur_review_id: parseInt(this.state.cur_review_id),
                customer_name: this.state.customer_name,
                date: this.state.date,
                file_reference: this.state.file_reference,
                reviewee_designation: this.state.reviewee_designation,
                reviewer_id: parseInt(this.state.cur_reviewer_id),
                reviewee_id: parseInt(this.state.cur_reviewee_id),
                review_json: JSON.parse(cur_review.review_json)
            };
            let curmonth = parseInt(this.state.due_date.getMonth());
            let currmonth = curmonth + 1;
            var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
            dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 
    
            curmonth = parseInt(this.state.sale_date.getMonth());
            currmonth = curmonth + 1;
            var saleDateStr = currmonth >= 10 ? this.state.sale_date.getFullYear()+'/'+currmonth+'/' : this.state.sale_date.getFullYear()+'/0'+currmonth+'/';
            saleDateStr += this.state.sale_date.getDate() < 10 ? '0' + this.state.sale_date.getDate() : this.state.sale_date.getDate(); 
    
            let scorecolor = null;
            let scorename = null;        
            let obtainedscore = 0;
            let totalscore = 0;
            let actual_outcome = '';
            let module_config = Store.getStoreData('module_config');
            let send_mail_config_data = {};
            let mail_config = {};
            let send_mail = this.state.send_mail_config;
            if(send_mail) {
                if(Object.keys(module_config).length > 0) {
                    mail_config = module_config.email.find((obj) => {
                        return obj.id === "YEgo"
                    });
                }
                send_mail = mail_config.enabled;
                if(send_mail){
                    send_mail_config_data = this.getMailStrucData(mail_config);
                }
                
                console.log(mail_config);
            }
            
    
            let task_json = {
                "task_data": { module_id: "filechecker", "last_action": "COMP_FCK_CREATE", "actiondate": Date.now(), 
                            'company_id' : 0, 'contact_id' : 0, 'system_id' : '1001', 'cur_lane' : 'COMP_FCK_ASSIGNMENT',
                            "cur_assigned_to" : parseInt(this.state.cur_reviewer_id), 'task_type' : 'COMP_FCK', 'parent_task_id' : 0, 'due_date' : dueDateStr, 'sale_date' : saleDateStr, 'gr_company_id': gr_company_id,'is_gc': is_gc },
                "object_data": {headerinfo: headerinfo, bin_files: [...this.state.binFiles, ...this.state.curBinFiles],qanda: Store.getStoreData('qanda'),
                result: {
                    comment: this.state.comment,
                    ragratingcolor: scorecolor,
                    ragratingname: scorename,
                    scoresobtained: obtainedscore,
                    totalscore: totalscore,
                    outcome: actual_outcome
                }},
                "action_data": [
                    { action: "COMP_FCK_CREATE", actiondate: Date.now(), assigned_to: parseInt(this.state.cur_reviewer_id), cur_lane: "COMP_FCK_ASSIGNMENT" }
                ]
            };           
    
            let postData = {command: "create_filecheck_task", task_json: task_json, mail_url: window.location.href, mail_config: send_mail_config_data, send_mail: send_mail };
            let fc_index = Store.getStoreData('fc_index');
    
            if(fc_index > 0) {
                let this_task = Store.getStoreData('pending_fctasks')[fc_index-1];
                task_json['task_data'].last_action = 'COMP_FCK_COMPLETE'
                task_json['task_data'].cur_lane = 'COMP_FCK_COMPLETE'
                task_json.action_data = {action: "COMP_FCK_COMPLETE", actiondate: Date.now(), assigned_to: 0, performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_COMPLETE" };
                postData = {command: "update_filecheck_task", task_json: task_json, task_id: this_task.id };
            }
    
            console.log("postData", postData);
    
            let api = new APICall();
            api.command(postData, this.reloadData);
            this.setState({button_diasabled: true});

        }else{
            alert('Please complete all mandatory fields');
        }   
    }

    reloadData = (result) => {
        if(result){
            this.setState({button_diasabled: false});
            alert('Filecheck task created and assigned successfully.');
        }
        window.location.reload(true);
    }
    filesLoaded = (files) => {
        let me = Store.getStoreData('loggedin_user_info');
        for(let i=0; i < files.length; i++) {
            if(!('user_id' in files[i])) {
                files[i].user_id = me.ID;
                files[i].upload_date = Date.now();
            }
        }
        // console.log('files in Modal', files);
        this.setState({binFiles: files});
    }    

    render()
    {
        let fc_index = Store.getStoreData('fc_index');
        let review_name = (this.state.cur_review_json !== null) ? ': ' +this.state.cur_review_json.name : '';
        let reveiw_name = "";
        let cur_task_id = this.state.cur_task_id;
        for(let key of this.state.my_reviews){
            if(parseInt(key.id) === parseInt(this.state.cur_review_id)){
                reveiw_name = key.nick_name;
            }
        }
        if (!this.state.ready) {
            return(
                <AssignContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Assign a File Checking Job</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyInner>
                            Loading...
                        </MRModalBodyInner>
                    </MRModalBody>
                    <MRModalFooter>
                    <MRModalNextBtn onClick={this.closeView}>Close</MRModalNextBtn>
                    </MRModalFooter>
                </AssignContainer>
            );
        }

        if (this.state.ready && this.state.my_reviews.length === 0) {
            return(
                <AssignContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Assign a File Checking Job</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyInner>
                            You currently have no reviews configured. Please click New Review Type, top left to start configuration of a new review.
                        </MRModalBodyInner>
                    </MRModalBody>
                    <MRModalFooter>
                    <MRModalNextBtn onClick={this.closeView}>Close</MRModalNextBtn>
                    </MRModalFooter>
                </AssignContainer>
            );
        }

        if (this.state.ready && this.state.my_reviews.length !== 0) {
            return(
                <AssignContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Assign a File Checking Job</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyInner style={{backgroundColor: '#ffffff'}}>
                            <ScoreContainer>
                                <ScoreHeaderContainer>File Check Header Information</ScoreHeaderContainer>
                                <ScoreBodyContainer>
                                    <MRModalLabel>Choose Group Company</MRModalLabel>
                                    <MRModalSelect onChange={this.handleChangeCurGc} name="cur_gc_id">
                                        {
                                            this.state.gc_companies.map((gc) => {
                                                return(
                                                <option key={gc.id} value={gc.id}>{gc.company_name}</option>
                                                );
                                            })
                                        }
                                    </MRModalSelect>
                                    <MRModalLabel>Choose a review</MRModalLabel>
                                    <MRModalSelect onChange={this.handleChange} name="cur_review_id" value={this.state.cur_review_id}>
                                        {
                                            this.state.my_reviews.map((item) => {
                                                {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.nick_name}</option>
                                                    );
                                                }
                                            })
                                        }
                                    </MRModalSelect>
                                    <MRModalLabel>Customer Name</MRModalLabel>
                                    <MRModalInput type="text" name="customer_name" value={this.state.customer_name} onChange={this.handleChange} />
                                    <MRModalLabel>Policy Start Date</MRModalLabel>
                                    <CSLDateCover>
                                        <DatePicker
                                                onChange={this.onSaleDateChange}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                locale={"en-GB"}
                                                value={this.state.sale_date}
                                                />
                                    </CSLDateCover>

                                    <MRModalLabel>Reviewer</MRModalLabel>
                                    <MRModalSelect onChange={this.handleReviewerChange} name="cur_reviewer_id" value={this.state.cur_reviewer_id} >
                                    {
                                        (() => {
                                            if(this.state.review_list.length !== 0) {
                                                let reviewers = [];
                                                for(let item of this.state.review_list){
                                                    for(let cur_item of this.state.temp_reviewers){
                                                        if(parseInt(cur_item.id) === parseInt(item.ID)){
                                                            reviewers.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                                        }
                                                    }
                                                        
                                                }
                                                return reviewers;
                                            }

                                        })()
                                    }
                                    </MRModalSelect>
                                    <MRModalLabel>Reviewee</MRModalLabel>
                                    <MRModalSelect onChange={this.handleChange} name="cur_reviewee_id" value={this.state.cur_reviewee_id}>
                                    {
                                        (() => {
                                            if(this.state.review_list.length !== 0) {
                                                let reviewees = [];
                                                for(let item of this.state.review_list){
                                                    for(let cur_item of this.state.cur_gc_user){
                                                        if(parseInt(cur_item.ID) === parseInt(item.ID) && parseInt(cur_item.ID) != parseInt(this.state.cur_reviewer_id)){
                                                            reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                                        }
                                                    }
                                                    
                                                }
                                                return reviewees;
                                            }

                                        })()
                                    }
                                    
                                    </MRModalSelect>

                                    <MRModalLabel>Reviewee Designation
                                        {
                                            (() => {
                                                if(this.state.reviewee_designation_required) {
                                                    return <span style={{color: "red", paddingLeft: "5px", fontSize: "8px"}}><FaStarOfLife  title="Review designation required"/></span>;
                                                }
                                            })()
                                        }
                                    </MRModalLabel>
                                    <MRModalInput type="text" name="reviewee_designation" value={this.state.reviewee_designation} onChange={this.handleChange}/>
                                    <MRModalLabel>File reference</MRModalLabel>
                                    <MRModalInput type="text" name="file_reference" value={this.state.file_reference} onChange={this.handleChange}/>
                                    <MRModalLabel>Scheduled Date</MRModalLabel>
                                    <CSLDateCover>
                                     <DatePicker
                                                onChange={this.onDateChange}
                                                clearIcon={null}
                                                calendarIcon={null}
                                                locale={"en-GB"}
                                                value={this.state.due_date}
                                                /> 
                                                
                                    </CSLDateCover>

                                    <MRModalLabel>Existing Attachments</MRModalLabel>
                                    <Style.ModalNameInputDiv style={{ minHeight:'100px', height: '100px'}}>
                                    {
                                        (() => {
                                            if(this.state.cur_files_json !== null) {
                                                return(
                                                    <div style={{width:'100%', display: 'flex', overflow: 'auto', minHeight: 140}}>
                                                    {
                                                        this.state.curBinFiles.map((f, index) => {
                                                            // console.log('f',f);
                                                            let file_parts = f.name.split('.');
                                                            let ext = 'PDF';
                                                            switch(file_parts[file_parts.length - 1]) {
                                                            case 'jpeg':
                                                            case 'jpg' : ext = 'JPG';break;
                                                            case 'png' : ext = 'PNG'; break;
                                                            case 'docx' : ext = 'DOCX'; break;
                                                            case 'doc' : ext = 'DOC'; break;
                                                            case 'msg' : ext = 'MSG'; break;
                                                            case 'txt' : ext = 'TXT'; break;
                                                            case 'ppt' : ext = 'PPT'; break;
                                                            case 'pptx' : ext = 'PPTX'; break;
                                                            case 'xls' : ext = 'XLS'; break;
                                                            case 'xlsx' : ext = 'XLSX'; break;
                                                            default: ext = 'PDF'; break;
                                                            }
                                                            // console.log('ext',ext);
                                                            return(
                                                                <Style.FileContainer key={index}>
                                                                <Style.FileTypeContainer>{ext}</Style.FileTypeContainer>
                                                                <Style.FileInfoContainer>
                                                                    <strong style={{color: '#000000'}}>{f.name}</strong><br /><br />
                                                                    Added just now<br /><br />
                                                                    <a style={{ cursor: 'pointer'}} onClick={() => this.handleDownload(index)}><FiDownload /> Download</a> &nbsp; 
                                                                </Style.FileInfoContainer>
                                                                </Style.FileContainer>
                                                            )

                                                        })
                                                    }
                                                    </div>
                                                )
                                            } else {
                                                return <div>No existing attachments.</div>
                                            }
                                        })()

                                    }
                                    </Style.ModalNameInputDiv>
                                    
                                    <div style={{display: 'flex'}}>
                                        <ModalNameLabelDiv>Add files</ModalNameLabelDiv>
                                        <ModalNameInputDiv style={{ minHeight:'140px', height: '100px'}}>
                                            <Dropzone onFilesAdded={this.filesLoaded} />
                                        </ModalNameInputDiv>                            
                                    </div>
                                </ScoreBodyContainer>
                            </ScoreContainer>                            
                        </MRModalBodyInner>
                    </MRModalBody>
                    <MRModalFooter>
                    {
                        (() => {
                            if(this.state.my_reviews.length > 0)
                                {
                                    return(<MRModalNextBtn onClick={this.assignFCKJob(fc_index)} disabled={this.state.button_diasabled}>Submit</MRModalNextBtn>)
                                }

                        })()
                    } 
                    <MRModalNextBtn onClick={this.closeView}>Close</MRModalNextBtn>
                    </MRModalFooter>
                </AssignContainer>
            );
        }        
    }
}

export default AssignView;