import React from "react";
import moment from "moment";
import styled from "styled-components";
import { TiArrowBack } from "react-icons/ti";
import * as Style from "../Common/StyledComponents";
import WelcomeBanner from "../Common/WelcomeBanner";
import Store from "../../Common/Store";
import APICall from "../../Common/APICall.js";
import CSLTable from "./../Common/CSLTable";
import FileCheckModal from "../FC/FileCheckModal";
import ReportModal from "../MGR/ReportModal";
import AssignQcModal from "../MGR/AssignQcModal";
import AnswerView from "../Common/AnswerView";
import QcView from "../Common/QcView";
import RegisterHeader from "./RegisterHeader";
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RegisterReporting from "./CSLRegister/RegisterReporting";
import reportData from "./reportData.js";
import completed_report_data from "./CompletedReportData.js";
import filters from "./reportFilters.js";
import completed_filters from "./completedFilters.js";
import completed_pill_filters from "./completedPillFilters.js";
import pill_filters from "./pillFilters.js";
import CSLLoader from "./../Common/CSLLoader";
import ClipLoader from "react-spinners/ClipLoader";
import FilecheckerHOC from '../Tasks/FilecheckerHOC.js';

const months = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: calc(100vh - 65px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 65px;
  z-index: 1000;
  opacity: 0.6;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #01b0a2;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 100px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
  margin-right: 3px;
`;
const FilterButton = styled.button`
  box-sizing: border-box;
  background-color: #eef2f7;
  color: #243e57;
  border: 1px solid #243e57;
  width: 130px;
  font-size: 13px;
  height: 30px;
  border-radius: 100px / 70px;
  margin-right: 3px;
  margin-top: 7px;
  outline: none;
`;
const CheckBox = (props) => <input type="checkbox" />;

const Overdue = styled.span`
  height: 15px;
  width: 15px;
  background-color: #ff0000;
  position: absolute;
  opacity: 1;
`;

const Pending = styled.span`
  height: 15px;
  width: 15px;
  background-color: #5656aa;
  position: absolute;
  opacity: 1;
`;

const InProgress = styled.span`
  height: 15px;
  width: 15px;
  background-color: #ffd11a;
  position: absolute;
  opacity: 1;
`;

const Complete = styled.span`
  height: 15px;
  width: 15px;
  background-color: #00ff00;
  position: absolute;
  opacity: 1;
`;

const Checkmark = styled.span`
  content: "\2713";
`;

const ReportButton = styled.button`
  background-color: #213e61;
  border: 1px solid #213e61;
  border-radius: 5px;
  color: #ffffff;
  padding: 8px 25px;
`;

const DummyOverlay = styled.div`
  display: table;
  box-sizing: border-box;
  height: calc(100vh - 65px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 65px;
  z-index: 1000;
  opacity: 0.6;
`;

var taskListFiltered = {};

const sourceMap = { filechecker: "File Checker", car: "Compliance Advice Register", rcm: "Regulatory Change Management" };
const completeMap = { COMPLETE: 1, COMPLETED: 1, DISMISSED: 1, REJECTED: 1, DISSMISS: 1, REJECT: 1 };
const statusMap = { Pending: <Pending />, Complete: <Complete />, Overdue: <Overdue /> };

class GenericRegister extends React.Component {
  state = {
    filter: {
      All: 1,
      Completed: 0,
      Indeterminate: 0,
      Compliant: 0,
      NonCompliant: 0,
      Pending: 0,
      Inprogress: 0,
      Awaiting: 0,
      InQaReview: 0,
      QaReviewed: 0,
    },
    pill_filters: {},
    completed_pill_filters: {},
    ready: false,
    qc_checked: {},
    qc_checked_company: {},
    task_list: null,
    role: null,
    page_view: "register_index",
    quality_assurance_enabled: true,
    prefered_columns: {},
    view: "register",
    applied_filter: {},
    filters: filters,
    completed_filters: completed_filters,
    completed_tasks: null,
    view_count: 0,
    show_completed: false,
    show_spinner: false,
    showTaskModal: false,
    cur_lane: null,
    company_id: null,
    task_id: null
  };
  setView = (event) => {
    event.preventDefault();
    this.props.refreshParentState(Store.getStoreData("prev_view"));
    Store.updateStore("is_register", false);
  };
  // componentDidMount() {
  //   let postData = { command: "get_filecheck_registry" };
  //   let api = new APICall();
  //   api.command(postData, this.processIndex);
  // }

  processIndex = (result) => {
    console.log("result:", result);
    // console.log("pill_filters:", pill_filters);
    let prefered_columns = {};
    if (!Store.getStoreData("is_admin")) {
      let filter = this.state.filter;
      let resp = null;
      switch (Store.getStoreData("role")) {
        case "admin_manager":
          resp = this.listAdminManagerTasks(result);
          break;
        case "manager":
        case "senior_manager":
        case "view_only_manager":
          resp = this.listCommonManagerTasks(result);
          break;
        case "qa_manager":
          resp = this.listQAManagerTasks(result);
          break;
        case "qa_reviewer":
          resp = this.listQAReviewerTasks(result);
          break;
        case "file_checker":
          resp = this.listFileCheckerTasks(result);
          break;
        default:
          break;
      }
      // console.log("resp:", resp);
      let role = Store.getStoreData("role");
      let module_config = Store.getStoreData("module_config");
      Store.updateStore("taskdata", resp.taskdata);
      Store.updateStore("all_count", resp.all_count);
      Store.updateStore("pending_count", resp.pending_count);
      Store.updateStore("inprogress_count", resp.inprogress_count);
      Store.updateStore("completed_count", resp.completed_count);
      Store.updateStore("indeterminate_count", resp.indeterminate_count);
      Store.updateStore("compliant_count", resp.compliant_count);
      Store.updateStore("noncompliant_count", resp.noncompliant_count);
      Store.updateStore("awaiting_count", resp.awaiting_count);
      if (role === "file_checker" && module_config.general.own_reviews_qa_view === true) {
        Store.updateStore("in_qa_review_count", resp.in_qa_review_count);
        Store.updateStore("qa_reviewed_count", resp.qa_reviewed_count);
      }

      const checkarr = resp.checkarr;
      const taskdata = resp.taskdata;
      let quality_assurance_enabled = this.state.quality_assurance_enabled;
      if (Object.keys(module_config).length > 0) {
        quality_assurance_enabled = module_config.general.quality_assurance_enabled;
      }
      if (Store.getStoreData("parent_has_group_companies") === true) {
        prefered_columns = {
          status: "Status",
          file_type: "File Type",
          company_name: "Company",
          reviewer: "Reviewer",
          reviewee: "Reviewee",
          customer: "Customer",
          completed_date: "Scheduled Date",
          review_date: "Review Date",
          score: "Score",
          outcome: "Result",
          confirmed_by: "File Edited By",
          confirmed_date: "File Edited Date",
          confirmation_text: "File Edit Comments"
        };
      } else {
        prefered_columns = {
          status: "Status",
          file_type: "File Type",
          reviewer: "Reviewer",
          reviewee: "Reviewee",
          customer: "Customer",
          completed_date: "Scheduled Date",
          review_date: "Review Date",
          score: "Score",
          outcome: "Result",
          confirmed_by: "File Edited By",
          confirmed_date: "File Edited Date",
          confirmation_text: "File Edit Comments"
        };
      }
      if (role === "manager" || role === "admin_manager" || role === "senior_manager" || role === "file_checker") {
        pill_filters["Awaiting"].is_visible = true;
      } else {
        pill_filters["Awaiting"].is_visible = false;
      }
      pill_filters["InQaReview"].is_visible = false;
      pill_filters["QaReviewed"].is_visible = false;
      if (role === "file_checker" && module_config.general.own_reviews_qa_view === true) {
        pill_filters["InQaReview"].is_visible = true;
        pill_filters["QaReviewed"].is_visible = true;
      }
      if (role === "admin_manager") {
        pill_filters["Completed"].is_visible = false;
        pill_filters["Indeterminate"].is_visible = false;
        pill_filters["Compliant"].is_visible = false;
        pill_filters["NonCompliant"].is_visible = false;
      }
      //console.log("StateFil::",filter)
      this.setState({ filter, ready: true, prefered_columns: prefered_columns, qc_checked: checkarr,
        role: Store.getStoreData("role"), task_list: taskdata, quality_assurance_enabled, pill_filters,
        completed_pill_filters });

      let applied_filters_pending = Store.getStoreData('applied_filters_pending');
        console.log('applied_filters_pending',applied_filters_pending)
        if(applied_filters_pending){
          this.setState({filters: applied_filters_pending})
        }

    }
  };

  pillClickHandler = (event) => {
    event.preventDefault();

    let pill_id = [event.target.name];
    let role = Store.getStoreData("role");
    let pill_filters = this.state.pill_filters;
    pill_filters.pills[pill_id] = (pill_filters.pills[pill_id] + 1) % 2;
  };

  listAdminManagerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_lane === "COMP_FCK_ASSIGNMENT" || result[i].cur_lane === "COMP_FCK_CREATE" || result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          pending_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_SAVED") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 1, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          inprogress_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 1, InQaReview: 0, QaReviewed: 0 } };
          awaiting_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
          if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
            if (task_json.object_data.result.outcome === "Pass") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              compliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Fail") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              noncompliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Indeterminate") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              indeterminate_count += 1;
            }
          } else {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          }
          completed_count += 1;
          if (result[i].cur_lane === "COMP_FCK_COMPLETE") {
            checkarr[result[i].id.toString()] = false;
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listCommonManagerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_lane === "COMP_FCK_ASSIGNMENT" || result[i].cur_lane === "COMP_FCK_CREATE" || result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          pending_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_SAVED") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 1, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          inprogress_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 1, InQaReview: 0, QaReviewed: 0 } };
          awaiting_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
          if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
            if (task_json.object_data.result.outcome === "Pass") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              compliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Fail") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              noncompliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Indeterminate") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              indeterminate_count += 1;
            }
          } else {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          }
          completed_count += 1;
          if (result[i].cur_lane === "COMP_FCK_COMPLETE") {
            checkarr[result[i].id.toString()] = false;
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listQAManagerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          pending_count += 1;
        }
        if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
          if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
            if (task_json.object_data.result.outcome === "Pass") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              compliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Fail") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              noncompliant_count += 1;
            }
            if (task_json.object_data.result.outcome === "Indeterminate") {
              taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              indeterminate_count += 1;
            }
          } else {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          }
          completed_count += 1;
          if (result[i].cur_lane === "COMP_FCK_COMPLETE") {
            checkarr[result[i].id.toString()] = false;
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listQAReviewerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        if (result[i].cur_assigned_to.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
          taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
          pending_count += 1;
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count };
  };

  listFileCheckerTasks = (result) => {
    let taskdata = {};
    let checkarr = {};
    let all_count = 0;
    let pending_count = 0;
    let inprogress_count = 0;
    let completed_count = 0;
    let indeterminate_count = 0;
    let compliant_count = 0;
    let noncompliant_count = 0;
    let awaiting_count = 0;
    let in_qa_review_count = 0;
    let qa_reviewed_count = 0;
    console.log("RESULT :: ", result);
    const module_config = Store.getStoreData("module_config");
    for (let i = 0; i < result.length; i++) {
      const task_json = JSON.parse(result[i].task_json);
      const review_json = task_json.object_data.headerinfo.review_json;
      if ("version" in review_json === true) {
        console.log("RES ", result[i].cur_lane);
        if (result[i].cur_lane === "COMP_FCK_ASSIGNMENT" || result[i].cur_lane === "COMP_FCK_CREATE") {
          if (result[i].cur_assigned_to.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 1, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
            pending_count += 1;
          }
        }
        if (result[i].cur_lane === "COMP_FCK_SAVED") {
          if (result[i].cur_assigned_to.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 1, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
            inprogress_count += 1;
          }
        }
        if (result[i].cur_lane === "COMP_FCK_AWAITING_APPROVAL") {
          if (task_json.object_data.headerinfo.reviewer_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
            taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 0, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 1, InQaReview: 0, QaReviewed: 0 } };
            awaiting_count += 1;
          }
        }
        if (module_config.general.own_reviews_qa_view === true) {
          if (result[i].cur_lane === "COMP_FCK_COMPLETE") {
            if (
              task_json.object_data.headerinfo.reviewer_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString() ||
              task_json.object_data.headerinfo.reviewee_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString() ||
              task_json.object_data.headerinfo.related_individual_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString()
            ) {
              if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
                if (result[i].score_result.outcome === "Pass") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  compliant_count += 1;
                }
                if (result[i].score_result.outcome === "Fail") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  noncompliant_count += 1;
                }
                if (result[i].score_result.outcome === "Indeterminate") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  indeterminate_count += 1;
                }
              } else {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              }
              completed_count += 1;
            }
          }
          if (result[i].cur_lane === "COMP_FCK_QC_COMPLETE") {
            if (task_json.object_data.headerinfo.reviewer_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
              if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
                if (result[i].score_result.outcome === "Pass") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 1 } };
                  compliant_count += 1;
                }
                if (result[i].score_result.outcome === "Fail") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 1 } };
                  noncompliant_count += 1;
                }
                if (result[i].score_result.outcome === "Indeterminate") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 1 } };
                  indeterminate_count += 1;
                }
              } else {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 1 } };
              }
              qa_reviewed_count += 1;
              completed_count += 1;
            }
            if (task_json.object_data.headerinfo.reviewee_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
              if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
                if (result[i].score_result.outcome === "Pass") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  compliant_count += 1;
                }
                if (result[i].score_result.outcome === "Fail") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  noncompliant_count += 1;
                }
                if (result[i].score_result.outcome === "Indeterminate") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  indeterminate_count += 1;
                }
              } else {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              }
              completed_count += 1;
            }
          }
          if (result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
            if (task_json.object_data.headerinfo.reviewer_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
              if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
                if (result[i].score_result.outcome === "Pass") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 1, QaReviewed: 0 } };
                  compliant_count += 1;
                }
                if (result[i].score_result.outcome === "Fail") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 1, QaReviewed: 0 } };
                  noncompliant_count += 1;
                }
                if (result[i].score_result.outcome === "Indeterminate") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 1, QaReviewed: 0 } };
                  indeterminate_count += 1;
                }
              } else {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 1, QaReviewed: 0 } };
              }
              in_qa_review_count += 1;
              completed_count += 1;
            }
            if (task_json.object_data.headerinfo.reviewee_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString()) {
              if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
                if (result[i].score_result.outcome === "Pass") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  compliant_count += 1;
                }
                if (result[i].score_result.outcome === "Fail") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  noncompliant_count += 1;
                }
                if (result[i].score_result.outcome === "Indeterminate") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  indeterminate_count += 1;
                }
              } else {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              }
              completed_count += 1;
            }
          }
        } else {
          if (result[i].cur_lane === "COMP_FCK_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_COMPLETE" || result[i].cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
            if (
              task_json.object_data.headerinfo.reviewer_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString() ||
              task_json.object_data.headerinfo.reviewee_id.toString() === Store.getStoreData("loggedin_user_info").ID.toString()
            ) {
              if (review_json.scoring.scoring_enabled === true && task_json.object_data.result.outcome !== "") {
                if (result[i].score_result.outcome === "Pass") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 1, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  compliant_count += 1;
                }
                if (result[i].score_result.outcome === "Fail") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 1, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  noncompliant_count += 1;
                }
                if (result[i].score_result.outcome === "Indeterminate") {
                  taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 1, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
                  indeterminate_count += 1;
                }
              } else {
                taskdata[result[i].id.toString()] = { row: result[i], filter: { All: 1, Completed: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0, Pending: 0, Inprogress: 0, Awaiting: 0, InQaReview: 0, QaReviewed: 0 } };
              }
              completed_count += 1;
            }
          }
        }
      }
    }
    all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    // if (module_config.general.own_reviews_qa_view === true) {
    //   all_count = pending_count + inprogress_count + awaiting_count + completed_count + in_qa_review_count + qa_reviewed_count;
    // } else {
    //   all_count = pending_count + inprogress_count + awaiting_count + completed_count;
    // }
    console.log("RET ", { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count, in_qa_review_count, qa_reviewed_count });
    return { taskdata, checkarr, all_count, pending_count, inprogress_count, awaiting_count, completed_count, indeterminate_count, noncompliant_count, compliant_count, in_qa_review_count, qa_reviewed_count };
  };

  componentDidMount() {
    if (Store.getStoreData("role") === "team") {
      window.location.replace("/");
    } else {
      const td_str = moment(Date.now() + 7 * 24 * 3600 * 1000).unix();
      let postData = { command: "get_filecheck_registry",toDate:td_str };
      let api = new APICall();
      api.command(postData, this.processIndex);
    }
  }

  refreshState = (modal_view) => {
    this.setState({ view_count: this.state.view_count + 1, modal_view: modal_view });
  };
  closeReportmodal = () => {
    this.setState({ modal_view: null });
  };

  getTaskTitle = (row) => {
    let task_json = JSON.parse(row.task_json);
    let contacts = Store.getStoreData("contacts");
    let task_title =
      "headerinfo" in task_json.object_data && "review_json" in task_json.object_data.headerinfo && "general" in task_json.object_data.headerinfo.review_json
        ? task_json.object_data.headerinfo.review_json.general.name.replace(/'+/g, "'")
        : "-";
    // switch(row.module_id) {
    //   case 'filechecker' : task_title = 'headerinfo' in task_json.object_data && 'review_json' in task_json.object_data.headerinfo && 'general' in  task_json.object_data.headerinfo.review_json ? task_json.object_data.headerinfo.review_json.general.name.replace(/'+/g,"'") : '-';
    //                        break;
    // }
    return task_title;
  };

  getStatus = (row) => {
    const task_json = JSON.parse(row.task_json);
    const loggedin_user_info = Store.getStoreData("loggedin_user_info");
    // console.log("task_json:", task_json);
    let lane_parts = row.cur_lane.split("_");
    let lane_end = lane_parts[lane_parts.length - 1];
    let status = "Pending";
    if (lane_end in completeMap) {
      status = "Review Complete";
    } else {
      let due_date = row.due_date;
      let unix_due_date = moment.unix(due_date);
      due_date = unix_due_date.set("hour", 23).set("minute", 59).set("second", 59).unix();
      let cur_date = moment().unix();
      // let cur_date_int = cur_date.getFullYear()*10000 + cur_date.getMonth()*100 + cur_date.getDate();
      // let due_date_int = due_date.getFullYear()*10000 + due_date.getMonth()*100 + due_date.getDate();
      if (due_date < cur_date) status = "Overdue";
    }
    status = row.cur_lane === "COMP_FCK_AWAITING_APPROVAL" ? "Awaiting Approval" : status;
    status = row.cur_lane === "COMP_FCK_SAVED" ? "In Progress" : status;
    if (Store.getStoreData("loggedin_user_info").role === "file_checker") {
      status = row.cur_lane === "COMP_FCK_COMPLETE" ? "Review Complete" : status;
      if (Store.getStoreData("module_config").general.own_reviews_qa_view === true) {
        status = row.cur_lane === "COMP_FCK_QC_ASSIGNMENT" && task_json.object_data.headerinfo.reviewer_id === loggedin_user_info.ID ? "In QA Review" : status;
        status = row.cur_lane === "COMP_FCK_QC_COMPLETE" && task_json.object_data.headerinfo.reviewer_id === loggedin_user_info.ID ? "QA Reviewed" : status;
      } else {
        status = row.cur_lane === "COMP_FCK_QC_ASSIGNMENT" ? "Review Complete" : status;
        status = row.cur_lane === "COMP_FCK_QC_COMPLETE" ? "QA Complete" : status;
      }
    }
    return status;
  };

  getDate = (due_date) => {
    // console.log("due_date:", due_date);
    // let dateStr = due_date.split('T')[0];
    // let dateParts = dateStr.split('-');
    // let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    // return ret;
    return moment.unix(due_date).format("DD/MM/YYYY");
  };

  getMonth = (due_date) => {
    // let dateStr = due_date.split('T')[0];
    // let dateParts = dateStr.split('-');
    // let ret = months[dateParts[1]];
    // return ret;
    return moment.unix(due_date).format("MMMM");
  };

  // getInclusion = (filter, pill_filter) => {
  //   // console.log("filter in getInclusion:", filter);
  //   // console.log("filter in state:", this.state.filter);
  //   let count = 0;
  //   let keys = Object.keys(filter)
  //   for(let i=0; i < keys.length; i++) {
  //     // console.log("from filter:", filter[keys[i]]);
  //     // console.log("state filter:", this.state.filter[keys[i]]);
  //     count += (parseInt(filter[keys[i]]) * parseInt(pill_filter[keys[i]]))
  //   }
  //   // return true;
  //   // console.log("count in inclusion:", count);
  //   return (count > 0)
  // }

  getGroupCompanies = (company_id) => {
    var group_company_id = parseInt(company_id) % 100000;
    let group_company_name = "";
    let gc_companies = Store.getStoreData("gc_companies");
    console.log("getGroupCompanies::",gc_companies);
    let grp = {};
    if (gc_companies.length > 0) {
      for (var k = 0; k < gc_companies.length; k++) {
        grp[gc_companies[k].id] = gc_companies[k].company_name;
      }
    }
    group_company_name = grp[group_company_id];
    return group_company_name;
  };

  handleQcCheck = (task_id, company_id) => (event) => {
    event.preventDefault();
    let { qc_checked, qc_length, qc_arr, qc_checked_company } = this.state;
    qc_checked[task_id] = qc_checked[task_id] ? false : true;
    if (qc_checked[task_id]) {
      qc_checked_company[task_id] = company_id;
    } else {
      delete qc_checked_company[task_id];
    }
    this.setState({ qc_checked, qc_checked_company });
  };

  processCompletedData = () => {
    const has_gc = Store.getStoreData("parent_has_group_companies");
    const gc_assoc = Store.getStoreData("gc_assoc");
    const contacts = Store.getStoreData("users");
    let tasks = Store.getStoreData("completed_fcs");
    if (tasks === null || tasks.length === 0) tasks = null;
    console.log(tasks);
    let ret = { data: [], columns: [], raw_data: [], row_filters: {} };
    ret.columns = [
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => (
          <div>
            {(() => {
              // console.log("row.original.status", row.original.status)
              switch (row.original.status) {
                case "Overdue":
                  return (
                    <Overdue>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </Overdue>
                  );
                  break;
                case "QA Reviewed":
                case "Review Complete":
                case "QA Complete":
                case "Complete":
                  return (
                    <Complete>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </Complete>
                  );
                  break;
                case "In Progress":
                  return (
                    <InProgress>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </InProgress>
                  );
                  break;
                case "In QA Review":
                  return (
                    <InProgress>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </InProgress>
                  );
                  break;
                //  return  <Complete><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Complete>; break;
                default:
                  return (
                    <Pending>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </Pending>
                  );
              }
            })()}
          </div>
        ),
        minWidth: 150,
        headerStyle: { textAlign: "left" },
        filterMethod: (filter, row) => {
          return row._original.status.toLowerCase().startsWith(filter.value.toLowerCase());
        },
        sortMethod: (a, b) => {
          return a > b ? 1 : -1;
        },
      },
      { Header: "File Type", accessor: "file_type", minWidth: 170, headerStyle: { textAlign: "left" } },
      { Header: "Company", accessor: "company_name", minWidth: 120, headerStyle: { textAlign: "left" }, show: { has_gc } },
      { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Customer", accessor: "customer", minWidth: 120, headerStyle: { textAlign: "left" } },
      {
        Header: "Review Date",
        accessor: "review_date",
        minWidth: 100,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "Result",
        accessor: "score_result",
        minWidth: 160,
        headerStyle: { textAlign: "left" },
        Cell: (row) => (
          <div>
            {(() => {
              // console.log("Cell data:", row);
              if (row.original.score_result === "N/A") {
                return "N/A";
              } else {
                if (row.original.score_result.scoring_enabled === true) {
                  return (
                    <div>
                      <div style={{ float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor }}></div>
                      <div style={{ float: "left", paddingLeft: "5px" }}>{Math.round(row.original.score_result.scoresobtained)}%</div>
                      <div style={{ float: "left", paddingLeft: "2px" }}>({row.original.score_result.autofailed === true ? "Auto-fail" : row.original.score_result.outcome})</div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <div style={{ float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor }}></div>
                      <div style={{ float: "left", paddingLeft: "5px" }}>{row.original.score_result.ragratingname}</div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                }
              }
            })()}
          </div>
        ),
      },
      {
        Header: "Action",
        Cell: (row) => (
          <div>
            {(() => {
              if (this.state.role !== "view_only_manager") {
                //return <MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>;
                return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
              } else {
                if (this.state.role === "view_only_manager" && row.original.status === "Complete") {
                  //return <MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>;
                  return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
                }
              }
            })()}
          </div>
        ),
        filterable: false,
        width: 120,
        headerStyle: { textAlign: "center" },
      },
    ];
    if (this.state.quality_assurance_enabled && (this.state.role === "qa_manager" || this.state.role === "senior_manager" || this.state.role === "admin_manager")) {
      ret.columns.push({
        Header: "Send For QA",
        Cell: (row) => (
          <div>
            {(() => {
              if (row.original.cur_lane === "COMP_FCK_COMPLETE") {
                // return (<MRButton onClick={this.goToTaskUrl(row.original.id)}>Check</MRButton>);
                if (this.state.qc_checked[row.original.id]) {
                  return (
                    <div style={{ cursor: "pointer", fontSize: "20px" }} onClick={this.handleQcCheck(row.original.id, row.original.company_id)}>
                      <IoIosCheckboxOutline />
                    </div>
                  );
                } else {
                  return (
                    <div style={{ cursor: "pointer", fontSize: "20px" }} onClick={this.handleQcCheck(row.original.id, row.original.company_id)}>
                      <IoIosSquareOutline />
                    </div>
                  );
                }
              }
            })()}
          </div>
        ),
        filterable: false,
        width: 120,
        headerStyle: { textAlign: "left" },
      });
    }
    // let qc_checked = {};
    if (tasks !== null) {
      let i = 0;
      for (let task of tasks) {
        // console.log("task.completed_date:", task.completed_date);
        const task_json = JSON.parse(task.task_json);
        let status = task.cur_lane === "COMP_FCK_COMPLETE" ? "Review Complete" : "QA Complete";
        let CompanyName = "";
        if (parseInt(task.company_id) < 100000) {
          CompanyName = Store.getStoreData("parent_company").company_name;
        } else {
          const calc_company_id = parseInt(task.company_id) % 100000;
          CompanyName = gc_assoc[calc_company_id.toString()].company_name;
        }
        let reviewer = task_json.object_data.headerinfo.reviewer_id.toString() in contacts ? contacts[task_json.object_data.headerinfo.reviewer_id.toString()].ContactName : "--";
        let reviewee = task_json.object_data.headerinfo.reviewee_id.toString() in contacts ? contacts[task_json.object_data.headerinfo.reviewee_id.toString()].ContactName : "--";
        let customer = "headerinfo" in task_json.object_data ? task_json.object_data.headerinfo.customer_name.replace(/'+/g, "'") : "--";
        let review_date = moment.unix(task.completed_date).format("DD/MM/YYYY");
        let review_month = moment.unix(task.completed_date).format("MMMM");
        let score_result = {};
        if (task.cur_lane === "COMP_FCK_COMPLETE") {
          score_result = task.score_result;
        } else {
          if (task_json.object_data.headerinfo.review_json.scoring.scoring_enabled === true) {
            score_result = task_json.object_data.qc_score_result;
            score_result.scoring_enabled = task_json.object_data.headerinfo.review_json.scoring.scoring_enabled;
          } else {
            score_result = task_json.object_data.qc_non_score_assessment;
            score_result.scoring_enabled = task_json.object_data.headerinfo.review_json.scoring.scoring_enabled;
          }
        }
        let elem = {
          index: i,
          status: status,
          file_type: task_json.object_data.headerinfo.review_json.general.name,
          review_type: task_json.object_data.headerinfo.review_json.general.name,
          company_name: CompanyName,
          reviewer: reviewer,
          reviewee: reviewee,
          customer: customer,
          cur_lane: task.cur_lane,
          review_date: review_date,
          review_month: review_month,
          score_result: score_result,
          id: task.id,
          check: "checkbox",
          completed_date: review_date,
          score: Math.round(score_result.scoresobtained),
          result: score_result.outcome,
          company_id: task.company_id,
          outcome: score_result.scoring_enabled === true ? score_result.outcome : score_result.ragratingname,
        };
        i++;
        ret.data.push(elem);
        ret.raw_data.push(elem);
        let filter = { All: 1, Indeterminate: 0, Compliant: 0, NonCompliant: 0 };
        if (task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
          if (task.cur_lane === "COMP_FCK_COMPLETE") {
            if (task_json.object_data.result.outcome === "Pass") filter.Compliant = 1;
            if (task_json.object_data.result.outcome === "Fail") filter.NonCompliant = 1;
            if (task_json.object_data.result.outcome === "Indeterminate") filter.Indeterminate = 1;
          }
          if (task.cur_lane === "COMP_FCK_QC_COMPLETE") {
            if (task_json.object_data.qc_score_result.outcome === "Pass") filter.Compliant = 1;
            if (task_json.object_data.qc_score_result.outcome === "Fail") filter.NonCompliant = 1;
            if (task_json.object_data.qc_score_result.outcome === "Indeterminate") filter.Indeterminate = 1;
          }
        }
        ret.row_filters[task.id] = JSON.parse(JSON.stringify(filter));
      }
      console.log(ret);
    }
    if (has_gc === true) {
      ret.export_columns = { file_type: "File Type", status: "Status", company_name: "Company", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date" };
    } else {
      ret.export_columns = { file_type: "File Type", status: "Status", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date" };
    }
    return ret;
  };

  processAcceptanceQueueData = () => {
    let taskData = Store.getStoreData("taskdata");
    let contacts = Store.getStoreData("contacts");
    let contact = Store.getStoreData("loggedincontact");
    const gc_assoc = Store.getStoreData("gc_assoc");

    const has_gc = Store.getStoreData("parent_has_group_companies");
    const show_qa = Store.getStoreData("loggedin_user_info").role === "admin_manager" ? false : true;
    var task_arr = [];
    var filter_arr = [];
    for (var k = 0; k < Object.keys(taskData).length; k++) {
      task_arr.push(taskData[Object.keys(taskData)[k]].row);
      filter_arr.push(taskData[Object.keys(taskData)[k]].filter);
    }

    console.log("task_arr===>", task_arr);
    let ret = { data: [], columns: [], raw_data: [], row_filters: {} };
    let i = 1;
    let prefered_columns = {};
    // console.log("filter_arr==>",filter_arr)
    ret.columns = [
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => (
          <div>
            {(() => {
              // console.log("row.original.status", row.original.status)
              switch (row.original.status) {
                case "Overdue":
                  return (
                    <Overdue>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </Overdue>
                  );
                  break;
                case "QA Reviewed":
                case "Review Complete":
                case "QA Complete":
                case "Complete":
                  return (
                    <Complete>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </Complete>
                  );
                  break;
                case "In Progress":
                  return (
                    <InProgress>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </InProgress>
                  );
                  break;
                case "In QA Review":
                  return (
                    <InProgress>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </InProgress>
                  );
                  break;
                //  return  <Complete><span style={{paddingLeft: "25px"}}>{row.original.status}</span></Complete>; break;
                default:
                  return (
                    <Pending>
                      <span style={{ paddingLeft: "25px" }}>{row.original.status}</span>
                    </Pending>
                  );
              }
            })()}
          </div>
        ),
        minWidth: 150,
        headerStyle: { textAlign: "left" },
        filterMethod: (filter, row) => {
          return row._original.status.toLowerCase().startsWith(filter.value.toLowerCase());
        },
        sortMethod: (a, b) => {
          return a > b ? 1 : -1;
        },
      },
      { Header: "File Type", accessor: "file_type", minWidth: 170, headerStyle: { textAlign: "left" } },
      { Header: "Company", accessor: "company_name", minWidth: 120, headerStyle: { textAlign: "left" }, show: { has_gc } },
      { Header: "Reviewer", accessor: "reviewer", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Reviewee", accessor: "reviewee", minWidth: 120, headerStyle: { textAlign: "left" } },
      { Header: "Customer", accessor: "customer", minWidth: 120, headerStyle: { textAlign: "left" } },
      {
        Header: "Review Date",
        accessor: "review_date",
        minWidth: 100,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "Result",
        accessor: "score_result",
        minWidth: 160,
        headerStyle: { textAlign: "left" },
        Cell: (row) => (
          <div>
            {(() => {
              // console.log("Cell data:", row);
              if (row.original.score_result === "N/A") {
                return "N/A";
              } else {
                if (row.original.score_result.scoring_enabled === true) {
                  return (
                    <div>
                      <div style={{ float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor }}></div>
                      <div style={{ float: "left", paddingLeft: "5px" }}>{Math.round(row.original.score_result.scoresobtained)}%</div>
                      <div style={{ float: "left", paddingLeft: "2px" }}>({row.original.score_result.autofailed === true ? "Auto-fail" : row.original.score_result.outcome})</div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <div style={{ float: "left", height: "17px", width: "17px", border: "1px solid #282828", backgroundColor: row.original.score_result.ragratingcolor }}></div>
                      <div style={{ float: "left", paddingLeft: "5px" }}>{row.original.score_result.ragratingname}</div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                  );
                }
              }
            })()}
          </div>
        ),
      },
      // {Header: 'Edited By', accessor: 'confirmed_by', minWidth: 120, headerStyle: {textAlign: 'left'}},
      // {Header: 'Edit Date', accessor: 'confirmed_date', minWidth: 120, headerStyle: {textAlign: 'left'}},
      // {Header: 'Edit Comments', accessor: 'confirmation_text', minWidth: 120, headerStyle: {textAlign: 'left'}},
      {
        Header: "Action",
        Cell: (row) => (
          <div>
            {(() => {
              if (this.state.role !== "view_only_manager") {
                //return <MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>;
                return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
              } else {
                if (this.state.role === "view_only_manager" && row.original.status === "Complete") {
                  //return <MRButton onClick={this.goToTaskUrl(row.original.id)}>View</MRButton>;
                  return(<MRButton onClick={this.goToTaskUrl(row.original.id,row.original.company_id,row.original.cur_lane)}>View</MRButton>)
                }
              }
            })()}
          </div>
        ),
        filterable: false,
        width: 120,
        headerStyle: { textAlign: "center" },
      },
    ];
    if (this.state.quality_assurance_enabled && (this.state.role === "qa_manager" || this.state.role === "senior_manager")) {
      ret.columns.push({
        Header: "Send For QA",
        Cell: (row) => (
          <div>
            {(() => {
              if (row.original.cur_lane === "COMP_FCK_COMPLETE") {
                // return (<MRButton onClick={this.goToTaskUrl(row.original.id)}>Check</MRButton>);
                if (this.state.qc_checked[row.original.id]) {
                  return (
                    <div style={{ cursor: "pointer", fontSize: "20px" }} onClick={this.handleQcCheck(row.original.id, row.original.company_id)}>
                      <IoIosCheckboxOutline />
                    </div>
                  );
                } else {
                  return (
                    <div style={{ cursor: "pointer", fontSize: "20px" }} onClick={this.handleQcCheck(row.original.id, row.original.company_id)}>
                      <IoIosSquareOutline />
                    </div>
                  );
                }
              }
            })()}
          </div>
        ),
        filterable: false,
        width: 120,
        headerStyle: { textAlign: "left" },
      });
    }
    // console.log("task_arr:", task_arr);
    for (var j = 0; j < task_arr.length; j++) {

      let confirmed_by = "N/A";
      let confirmed_date = "N/A";
      let confirmation_text = "N/A";
      // console.log("single item:", task_arr[j]);
      let contacts = Store.getStoreData("users");
      let task_json = JSON.parse(task_arr[j].task_json);
      // console.log("task_json", task_json )
      let CompanyName = "";
      if (parseInt(task_arr[j].company_id) < 100000) {
        CompanyName = Store.getStoreData("parent_company").company_name;
      } else {
        const calc_company_id = parseInt(task_arr[j].company_id) % 100000;
        console.log("MyComp::",gc_assoc[calc_company_id.toString()])
        let inac = "";
        if(gc_assoc[calc_company_id.toString()].is_active === 0){
          inac = " (Inactive)";
        }
        CompanyName = gc_assoc[calc_company_id.toString()].company_name + inac;

      }
      let review_date = this.getDate(task_arr[j].due_date);
      let review_month = this.getMonth(task_arr[j].due_date);
      if (task_arr[j].cur_lane === "COMP_FCK_COMPLETE" || task_arr[j].cur_lane === "COMP_FCK_QC_COMPLETE") {
        review_date = this.getDate(task_arr[j].completed_date);
        review_month = this.getMonth(task_arr[j].completed_date);
      }

      let status = this.getStatus(task_arr[j]);
      let reviewer = task_json.object_data.headerinfo.reviewer_id in contacts ? contacts[task_json.object_data.headerinfo.reviewer_id.toString()].ContactName : "--";
      let reviewee = task_json.object_data.headerinfo.reviewee_id in contacts ? contacts[task_json.object_data.headerinfo.reviewee_id.toString()].ContactName : "--";
      let customer = "headerinfo" in task_json.object_data ? task_json.object_data.headerinfo.customer_name.replace(/'+/g, "'") : "--";
      let score = "--";
      if ("result" in task_json.object_data) {
        if ("scoresobtained" in task_json.object_data.result) {
          if (task_json.object_data.result.scoresobtained != null) {
            score = task_json.object_data.result.scoresobtained.toString();
          }
        }
      }

      let result = "--";
      if ("result" in task_json.object_data) {
        if ("outcome" in task_json.object_data.result) {
          if (task_json.object_data.result.outcome != null) {
            score = task_json.object_data.result.outcome;
          }
        }
      }
      let checkbox = status === "Complete" ? 1 : 0;
      const score_result = task_arr[j].score_result;

      if (task_arr[j].confirmed_by !== 0) {
        confirmed_by = task_arr[j].confirmed_by.toString() in contacts ? contacts[task_arr[j].confirmed_by].ContactName : "Inactive User";
      }
      if (task_arr[j].confirmed_date !== 0) {
        confirmed_date = moment.unix(task_arr[j].confirmed_date).format("DD/MM/YYYY");
      }
      if (task_arr[j].confirmation_text !== "") {
        confirmation_text = task_arr[j].confirmation_text;
      }

      let elem = {
        index: j,
        status: status,
        file_type: this.getTaskTitle(task_arr[j]),
        review_type: this.getTaskTitle(task_arr[j]),
        company_name: CompanyName,
        reviewer: reviewer,
        reviewee: reviewee,
        customer: customer,
        cur_lane: task_arr[j].cur_lane,
        review_date: review_date,
        review_month: review_month,
        score_result: score_result,
        id: task_arr[j].id,
        module_id: task_arr[j].module_id,
        check: checkbox,
        completed_date: task_arr[j].completed_date != null ? this.getDate(task_arr[j].completed_date) : "--",
        score: score_result === "N/A" ? "N/A" : Math.round(score_result.scoresobtained),
        result: score_result === "N/A" ? "N/A" : score_result.outcome,
        outcome: score_result === "N/A" ? "N/A" : score_result.scoring_enabled === true ? score_result.outcome : score_result.ragratingname,
        company_id: task_arr[j].company_id,
        confirmed_by: confirmed_by,
        confirmed_date: confirmed_date,
        confirmation_text: confirmation_text
      };
      ret.data.push(elem);
      ret.raw_data.push(elem);
      ret.row_filters[elem.id] = taskData[elem.id].filter;
    }
    console.log("ret===>", ret);
    ret.data = ret.data.sort((a, b) => {
      if (a.review_date === b.review_date) {
        return 0;
      }
      let adue = a.review_date.split("/");
      const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
      let bbdue = b.review_date.split("/");
      const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
      return aReverse < bReverse ? 1 : -1;
    });
    if (has_gc === true) {
      ret.export_columns = { file_type: "File Type", status: "Status", company_name: "Company", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date" };
    } else {
      ret.export_columns = { file_type: "File Type", status: "Status", customer: "Customer", reviewer: "Reviewer", reviewee: "Reviewee", review_date: "Review Date" };
    }
    console.log("ret", ret);

    // Store.updateStore('register_raw_data', ret)
    return ret;
  };

  dateScoreReverse = (date) => {
    let date_parts = date.split("/");
    return parseInt(`${date_parts[2]}${date_parts[1]}${date_parts[0]}`);
  };

  dateScore = (date) => {
    return parseInt(date.replace(/\//g, ""));
  };

  getScoreResult = (task) => {
    // console.log("fired");
    let score_result = "N/A";
    let result_obj = {};
    let is_autofailed = false;
    let totalscore = 0;
    let obtained_score = 0;
    if (task.cur_lane === "COMP_FCK_COMPLETE" || task.cur_lane === "COMP_FCK_QC_COMPLETE") {
      // console.log("calculate");
      const task_json = JSON.parse(task.task_json);
      const scoring = task_json.object_data.headerinfo.review_json.scoring;
      const sections = task_json.object_data.headerinfo.review_json.sections;
      if (scoring.scoring_enabled === true) {
        sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.included === true) {
              const q_type = question.type.find((t) => t.selected === true);
              let qscore = 0;
              if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
                if (question.auto_scoring.enabled === true) {
                  if (q_type.value === "Boolean") {
                    qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
                    // console.log("qscore Boolean:", qscore);
                  }
                  if (q_type.value === "Dropdown") {
                    qscore = Math.max.apply(
                      null,
                      question.preset_answers.map((pa) => parseInt(pa.weight))
                    );
                    // console.log("qscore Dropdown:", qscore);
                  }
                } else {
                  const qscore_obj = question.weight.find((item) => item.selected === true);
                  qscore = parseInt(qscore_obj.value);
                }
              } else {
                const qscore_obj = question.weight.find((item) => item.selected === true);
                qscore = parseInt(qscore_obj.value);
              }
              totalscore = totalscore + qscore;
              const autofail_enabled = question.autofail.find((af) => af.selected === true);
              obtained_score = obtained_score + parseInt(question.obtained_score);
              if (autofail_enabled.value === "Yes") {
                if (parseInt(question.obtained_score) === 0) {
                  is_autofailed = true;
                }
                // if (parseInt(question.obtained_score) < qscore) {
                //     is_autofailed = true;
                // }
              }
            }
          });
        });
        result_obj.scoring_enabled = true;
        result_obj.scoresobtained = (obtained_score / totalscore) * 100;
        result_obj.totalscore = totalscore;
        if (is_autofailed === true) {
          const fail_obj = scoring.scores.find((sc) => sc.actual_outcome === "Fail");
          result_obj.ragratingcolor = fail_obj.color;
          result_obj.ragratingname = fail_obj.termname;
          result_obj.outcome = fail_obj.actual_outcome;
          result_obj.autofailed = true;
        } else {
          result_obj.autofailed = false;
          scoring.scores.forEach((single_score) => {
            if (result_obj.scoresobtained > single_score.lbound && result_obj.scoresobtained <= single_score.ubound) {
              result_obj.ragratingcolor = single_score.color;
              result_obj.ragratingname = single_score.termname;
              result_obj.outcome = single_score.actual_outcome;
            }
          });
        }
        score_result = result_obj;
      } else {
        result_obj.scoring_enabled = false;
        result_obj.ragratingname = task_json.object_data.non_score_assessment.result;
        result_obj.ragratingcolor = task_json.object_data.non_score_assessment.color;
        score_result = result_obj;
      }
    }
    return score_result;
  };

  processGetTask = (result) => {
    let loggedinContactId = Store.getStoreData("loggedincontact").contact_id;
    let this_task = result.result.task.parenttask;
    // console.log('result', result)
    let task_json = JSON.parse(this_task.task_json);
    let Role = Store.getStoreData("role");
    Store.updateStore("CheckModalOverlay", `${document.body.scrollHeight.toString()}px`);
    Store.updateStore("cur_review_json", task_json.object_data.headerinfo.review_json);
    Store.updateStore("cur_files_json", task_json.object_data.bin_files);
    Store.updateStore("cur_task_id", this_task.id);
    Store.updateStore("headerinfo", task_json.object_data.headerinfo);
    Store.updateStore("parent_task", this_task);
    this.setState({ modal_view: "show_filecheck_modal", ready: true });
  };

  openFileCheckerModal = (id) => {
    let tasks = Store.getStoreData("taskdata");
    const selected_task = tasks[id.toString()].row;
    // console.log("selected_task:", selected_task);
    let role = Store.getStoreData("role");
    // console.log("role", role);
    if (role === "admin_manager" || role === "manager" || role === "senior_manager") {
      Store.updateStore("fc_index", id);
      this.setState({ page_view: "answerview", ready: true });
    } else if (role === "file_checker") {
      if (selected_task.cur_lane === "COMP_FCK_COMPLETE" || selected_task.cur_lane === "COMP_FCK_QC_COMPLETE") {
        const postData = { command: "get_task", task_id: id };
        const api = new APICall();
        api.command(postData, this.forReport);
      } else {
        Store.updateStore("fc_index", id);
        this.setState({ page_view: "answerview" });
      }
    } else if (role === "qa_manager" || role === "qa_reviewer") {
      if (this.state.quality_assurance_enabled) {
        Store.updateStore("fc_index", id);
        this.setState({ page_view: "qaview" });
      } else {
        alert("Quality assurance disabled");
        return false;
      }
    } else {
      let postData = { command: "get_task", task_id: id };
      // console.log("postData", postData)
      let api = new APICall();
      api.command(postData, this.forReport);
    }
  };

  forReport = (result) => {
    Store.updateStore("report_task", result.result.task);
    Store.updateStore("report_task_json", JSON.parse(result.result.task.parenttask.task_json));
    Store.updateStore("cur_task_id", result.result.task.parenttask.id);
    this.setState({ modal_view: "view_report_modal" });
  };
  processGetTask_forReport = (result) => {
    let this_task = result.result.task.parenttask;
    let task_json = JSON.parse(this_task.task_json);
    document.documentElement.scrollTop = 0;
    Store.updateStore("report_task_json", task_json);
    Store.updateStore("report_task", result.result.task);
    Store.updateStore("ReportModal", `${document.body.scrollHeight.toString()}px`);
    this.setState({ modal_view: "view_report_modal", ready: true });
  };

  openReportModal = (id) => {
    let postData = { command: "get_task", task_id: id };
    let api = new APICall();
    api.command(postData, this.processGetTask_forReport);
  };

  goToTaskUrlBare = (id) => {
    Store.updateStore("cur_task_id", id);
    let loggedinContactId = Store.getStoreData("loggedincontact").contact_id;
    let tasks = Store.getStoreData("taskdata");
    let curAssignedto = tasks[id].row.cur_assigned_to;

    let Role = Store.getStoreData("role");
    this.openFileCheckerModal(id);
  };

  // goToTaskUrl = (id) => (event) => {
  //   event.preventDefault();
  //   // console.log('taskId==>', id)
  //   const task_url = Store.getStoreData("is_debug") ? `/${id}` : process.env.REACT_APP_API_URL + "mstop/filechecker/" + id;
  //   window.location.assign(task_url);
  //   // this.goToTaskUrlBare(id);
  // };
  goToTaskUrl = (id,company_id,cur_lane) => (event) => {
    event.preventDefault();
      //console.log('isModal',Store.getStoreData('is_modal'));
       console.log('id',id);
        console.log('company_id',company_id);
         console.log('cur_lane',cur_lane);
      // console.log('taskId==>', id)
      const task_url = `/${id}`;
      console.log(task_url);

    //if(Store.getStoreData('is_modal')){}
      let modalview = 1;
      console.log('REACT_APP_DETAILS_PAGE_VIEW::',modalview);

      if( modalview == 1){
      console.log('is_modal=>',Store.getStoreData('is_modal'));
      this.setState({showTaskModal: true, task_id:id, company_id:company_id, cur_lane:cur_lane})
    }else{
        window.location.assign(task_url);
     }
  };

  handleBtn = (event) => {
    event.preventDefault();
    //console.log(event.target.name);
    let filter = this.state.filter;
    if ([event.target.name] == "Indeterminate") {
      filter.Indeterminate = filter.Indeterminate === 0 ? 1 : 0;
    }
    if ([event.target.name] == "All") {
      filter.All = !filter.All ? 1 : 0;
    }
    if ([event.target.name] == "Pending") {
      filter.Pending = filter.Pending === 0 ? 1 : 0;
    }
    if ([event.target.name] == "Inprogress") {
      filter.Inprogress = filter.Inprogress === 0 ? 1 : 0;
    }
    if ([event.target.name] == "Completed") {
      filter.Completed = filter.Completed === 0 ? 1 : 0;
    }
    if ([event.target.name] == "Compliant") {
      filter.Compliant = filter.Compliant === 0 ? 1 : 0;
    }
    if ([event.target.name] == "NonCompliant") {
      filter.NonCompliant = filter.NonCompliant === 0 ? 1 : 0;
    }
    if ([event.target.name] == "Awaiting") {
      filter.Awaiting = filter.Awaiting === 0 ? 1 : 0;
    }
    this.setState({ filter });
  };

  refreshCallback = () => {
    this.setState({ view_count: this.state.view_count + 1 });
  };

  refreshCompCallback = () => {
    let qc_checked = {};
    for (let task of Store.getStoreData("completed_fcs")) {
      if (task.cur_lane === "COMP_FCK_COMPLETE") qc_checked[task.id.toString()] = false;
    }
    this.setState({ qc_checked, view_count: this.state.view_count + 1 });
  };

  toggleAssignQcModal = (view) => {
    //console.log(this.state.qc_checked_company)
    var firstKey = Object.keys(this.state.qc_checked_company)[0];
    // console.log(firstKey)
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let { modal_view } = this.state;
    modal_view = view;
    let qc_checked = this.state.qc_checked;
    let check = false;
    for (let [key, value] of Object.entries(qc_checked)) {
      if (value) {
        check = value;
      }
    }
    if (!check) {
      alert("Please tick at least one review");
      return false;
    }
    let is_repeat = false;
    let qc_comp = this.state.qc_checked_company;
    let com_id = qc_comp[firstKey];
    for (let k in qc_comp) {
      if (qc_comp[k] !== com_id) {
        is_repeat = true;
      }
    }

    if (is_repeat) {
      alert("Please choose same companies");
      return false;
    }

    // console.log("qc", qc_checked)

    this.setState({ modal_view });
  };

  changeCurView = (view) => {
    this.setState({ page_view: view });
  };

  goToReport = () => {
    this.setState({ view: "report" });
  };

  goToRegister = () => {
    this.setState({ view: "register" });
  };

  getTaskStatus = (task) => {
    let status = "Pending";
    if (task.cur_lane === "COMP_FCK_COMPLETE") {
      status = "Completed";
    }
    return status;
  };

  getReviewType = (task_json) => {
    let review_type = "";
    if ("version" in task_json.object_data.headerinfo.review_json) {
      review_type = task_json.object_data.headerinfo.review_json.general.name;
    } else {
      review_type = task_json.object_data.headerinfo.review_json.name;
    }
    return review_type;
  };

  getScoreAndResult = (task_json) => {
    let data = { score: "N/A", result: "N/A" };
    if ("version" in task_json.object_data.headerinfo.review_json) {
      if (task_json.object_data.headerinfo.review_json.scoring.scoring_enabled === false) {
        data.score = "N/A";
        data.result = task_json.object_data.non_score_assessment.result;
      } else {
        data.score = Math.ceil(task_json.object_data.result.scoresobtained);
        data.result = task_json.object_data.result.outcome;
      }
    } else {
      data.score = Math.ceil(task_json.object_data.result.scoresobtained);
      data.result = task_json.object_data.result.outcome;
    }
    return data;
  };

  getScoreAndResultNew = (task) => {
    let data = { score: "N/A", result: "N/A" };
    if (task.cur_lane !== "COMP_FCK_ASSIGNMENT" && task.cur_lane !== "COMP_FCK_CREATE") {
      const task_json = JSON.parse(task.task_json);
      if ("version" in task_json.object_data.headerinfo.review_json) {
        if (task_json.object_data.headerinfo.review_json.scoring.scoring_enabled === false) {
          data.score = "N/A";
          data.result = task_json.object_data.non_score_assessment.result;
        } else {
          data.score = Math.ceil(task_json.object_data.result.scoresobtained);
          data.result = task_json.object_data.result.outcome;
        }
      } else {
        data.score = Math.ceil(task_json.object_data.result.scoresobtained);
        data.result = task_json.object_data.result.outcome;
      }
    }
    return data;
  };

  applyAdvancedFilterOld = (selections_gathered) => {
    //console.log("applied");
    let data = JSON.parse(JSON.stringify(this.state.original_data.data));
    //console.log('this.state.original_data.data', this.state.original_data.data)
    // let selections_gathered = this.state.selections_gathered;
    if (selections_gathered.length === 0) return false;
    let filtered_tasks = {};
    selections_gathered.forEach((sg) => {
      let newdata = [];
      if (sg.type === "multiple_select" && sg.selection.length !== 0) {
        data.forEach((d) => {
          if (sg.selection.includes(d[sg.accessor])) {
            newdata.push(d);
            filtered_tasks[d["task_id"]] = 1;
          }
        });
        data = JSON.parse(JSON.stringify(newdata));
      }
      if (sg.type === "single_select" && sg.selection !== "Choose") {
        data.forEach((d) => {
          if (d[sg.accessor] === sg.selection) {
            newdata.push(d);
            filtered_tasks[d["task_id"]] = 1;
          }
        });
        data = JSON.parse(JSON.stringify(newdata));
      }
    });
    let odata = JSON.parse(JSON.stringify(this.state.data));
    odata.data = data;
    this.setState({ data: odata, filtered_tasks: filtered_tasks });
  };

  switchRadio = (event) => {
    event.preventDefault();
    console.log(this.state.show_completed);
    if (this.state.show_completed === true) {
      let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
      Object.keys(completed_filters).forEach((key) => {
        completed_filters[key].selections = {};
      });
      this.setState({
        show_completed: false,
        completed_tasks: null,
        completed_filters,
      });
    } else {
      this.setState({ show_spinner: true });
      const postData = { command: "get_register_complete_filter_options" };
      let api = new APICall();
      api.command(postData, this.processFilterOptions);
    }
  };

  processFilterOptions = (result) => {
    console.log(result);
    let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
    Object.keys(completed_filters).forEach((key) => {
      completed_filters[key]["options"] = result[key];
      if (key === "review_date") {
        const td = new Date();
        let dd = td.getDate();
        let mm = td.getMonth() + 1;
        let yyyy = td.getFullYear();
        dd = dd < 10 ? `0${dd.toString()}` : dd.toString();
        mm = mm < 10 ? `0${mm.toString()}` : mm.toString();
        const td_str = `${dd}/${mm}/${yyyy}`;
        console.log(td_str);
        // td.setUTCHours(0,0,0,0);
        completed_filters.review_date.selections.from_date = td_str;
        completed_filters.review_date.selections.to_date = td_str;
      }
    });
    this.setState({
      show_completed: true,
      completed_filters,
      show_spinner: false,
    });
  };

  clearPendingAdvancedFilter = () => {
    // console.log("pending_filters", JSON.parse(JSON.stringify(this.state.filters)));
    // let filters = JSON.parse(JSON.stringify(this.state.filters));
    // Object.keys(filters).forEach(key => {
    //     if (filters[key].type === "date_range") {
    //         filters[key].selections.from_date = moment().format('DD/MM/YYYY')
    //         filters[key].selections.to_date = moment().format('DD/MM/YYYY')
    //     } else {
    //         filters[key].selections = {};
    //     }
    // })
    // this.setState({filters});

      localStorage.removeItem("applied_filters_pending")
    window.location.reload();
  };

  clearCompletedAdvancedFilter = () => {
    // console.log(this.state.completed_filters);
    // let completed_filters = JSON.parse(JSON.stringify(this.state.completed_filters));
    // Object.keys(completed_filters).forEach(key => {
    //     if (completed_filters[key].type === "date_range") {
    //         completed_filters[key].selections.from_date = moment().format('DD/MM/YYYY')
    //         completed_filters[key].selections.to_date = moment().format('DD/MM/YYYY')
    //     } else {
    //         completed_filters[key].selections = {};
    //     }
    // })
    // this.setState({completed_filters});
    window.location.reload();
  };

  processDate = () => {
    var d = new Date();
    let day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
    let monthIndex = d.getMonth() < 10 ? `0${d.getMonth().toString()}` : d.getMonth().toString();
    let year = d.getFullYear().toString();
    let date = day + "/" + (parseInt(monthIndex) + 1) + "/" + year;
    return date;
  };

  closeModal = () => {
    this.setState({showTaskModal: false})
  }
  render() {
    console.log("GC_filters:", this.state.filters);
    if (!this.state.ready) {
      return (
        <div>
          <CSLLoader />
        </div>
      );
    }
    if (this.state.page_view === "answerview") {
      return <AnswerView changeCurView={this.changeCurView} parentview="register_index" />;
    } else if (this.state.page_view === "qaview") {
      return <QcView changeCurView={this.changeCurView} parentview="register_index" />;
    }

    let button_set = {};
    button_set["assign_qc"] = { id: "assign_qc", display: "Assign For QA", stack: "assignqcmodal", button_callback: this.toggleAssignQcModal, is_visible: this.state.quality_assurance_enabled };

    return (
      <div style={{ backgroundColor: "#f2f2f3", position: "relative", paddingBottom: Store.getStoreData("loggedin_user_info").role === "admin_manager" ? "75px" : "25px" }}>
        {/* {
                  (() => {
                      if (this.state.show_spinner === true) {
                          return (
                              <div>
                                  <DummyOverlay>
                                      <div style={{display: "table-cell", verticalAlign: "middle", textAlign: "center"}}>
                                          <ClipLoader
                                              size={150}
                                              color={"#123abc"}
                                              loading={true}
                                          />
                                      </div>
                                  </DummyOverlay>
                              </div>
                          );
                      }
                  })()
              } */}
        <RegisterHeader />
        <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData("loggedin_user_info").ContactName} />
        {(() => {
          if (this.state.view === "register") {
            // console.log("in register");
            return (
              <div style={{ position: "relative", paddingTop: "10px", paddingLeft: "10px" }}>
              {
                (() => {
                if(this.state.showTaskModal){
                  return <FilecheckerHOC closeModal = {this.closeModal} task_id={this.state.task_id} cur_lane={this.state.cur_lane} company_id={this.state.company_id}/>;
                  // return <FilecheckerHOC task_id={'38970'} cur_lane={'COMP_FCK_SAVED'} company_id={'277100050'}/>;

              }
              })()
            }
                <RegisterReporting
                  headerText={Store.getStoreData("loggedin_user_info").role === "admin_manager" ? "Pending File Checks" : "Filechecker Register"}
                  export_file_name={Store.getStoreData("loggedin_user_info").role === "admin_manager" ? "Pending File Check Report_" + moment().format("DD/MM/YYYY") : "File Check Report_" + moment().format("DD/MM/YYYY")}
                  moduleId="filechecker"
                  data={reportData}
                  processTableData={this.processAcceptanceQueueData}
                  preferedColumns={this.state.prefered_columns}
                  buttonSet={Store.getStoreData("loggedin_user_info").role === "admin_manager" ? null : button_set}
                  filters={this.state.filters}
                  pillFilters={this.state.pill_filters}
                  refreshCallback={this.refreshCallback}
                  viewCount={this.state.view_count}
                  toggle_text={null}
                  toggle_state={true}
                  clearAdvancedFilter={this.clearPendingAdvancedFilter}
                  report={true}
                  titlePDF={"File Check Report_" + this.processDate()}
                />
              </div>
            );
          }
        })()}
        {(() => {
          if (this.state.view === "register" && Store.getStoreData("loggedin_user_info").role === "admin_manager") {
            return (
              <div style={{ position: "relative", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px" }}>
                <RegisterReporting
                  headerText="Completed File Checks"
                  export_file_name={"Completed File Check Report_" + moment().format("DD/MM/YYYY")}
                  moduleId="filechecker"
                  data={completed_report_data}
                  processTableData={this.processCompletedData}
                  preferedColumns={this.state.prefered_columns}
                  buttonSet={button_set}
                  filters={this.state.completed_filters}
                  pillFilters={this.state.completed_pill_filters}
                  refreshCallback={this.refreshCallback}
                  refreshCompCallback={this.refreshCompCallback}
                  viewCount={this.state.view_count}
                  toggle_text="Show Completed File Checks"
                  toggleCallback={this.switchRadio}
                  toggle_state={this.state.show_completed}
                  clearAdvancedFilter={this.clearCompletedAdvancedFilter}
                  report={false}
                  titlePDF={"File Check Report_" + this.processDate()}
                />
              </div>
            );
          }
        })()}
        {(() => {
          if (this.state.modal_view !== null && this.state.view === "register") {
            switch (this.state.modal_view) {
              // case 'show_filecheck_modal': return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><FileCheckModal closeModal={this.closeReportmodal} /></div>; break;
              case "show_filecheck_modal":
                return (
                  <div style={{ position: "absolute", left: "10%", top: "50px", width: "100%", height: "1186px" }}>
                    <InactiveOverlay />
                    <AnswerView closeModal={this.closeReportmodal} />
                  </div>
                );
                break;
              case "view_report_modal":
                return (
                  <div style={{ position: "absolute", left: "10%", top: "50px", width: "100%", height: "1186px" }}>
                    <InactiveOverlay />
                    <ReportModal closeModal={this.closeReportmodal} />
                  </div>
                );
                break;
              case "assignqcmodal":
                return (
                  <div style={{ position: "absolute", left: "10%", top: "60px", width: "100%", height: "1186px" }}>
                    <InactiveOverlay />
                    <AssignQcModal closeModal={this.closeReportmodal} assignqc_tasks={this.state.qc_checked} qc_checked_company={this.state.qc_checked_company} />
                  </div>
                );
                break;
            }
          }
        })()}
      </div>
    );
  }
}

export default GenericRegister;
