import Store from './Store.js';

const roleScore = {
    "SMF" : 6,
    "SMP" : 5,
    "SMO" : 4,
    "CRR" : 3,
    "COC" : 2,
    "OTH" : 1,
    "Mis" : 0
};
const roleColor=["#e2e2e2", "#d88480", "#a6d9a1", "#efac4e", "#49668a", "#49668a", "#49668a" ];

class Utils {
  // constructor() {
  // }
  genKey = (length) => {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  copyObject = (obj) => {
    return JSON.parse(JSON.stringify(obj))
  }

  log() {
      if(Store.getStoreData('is_debug'))console.log.apply(null, arguments)
  }

  setEnv(module_name) {
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('module_name',module_name);
    Store.updateStore('api_url',api_url);
    Store.updateStore('is_admin',false);
    Store.updateStore('is_debug',false);
  }

  isObject = function(a) {
      return (!!a) && (a.constructor === Object);
  };

  getMaxRoleColor(roles) {
    let maxRole = 0;
    for(var r in roles) {
      let key = r.substring(0,3);
      if(maxRole < roleScore[key]) maxRole = roleScore[key];
    }

    return roleColor[maxRole];
  }

  getRoleColor(role) {
    let key = role.substring(0,3);
    let ret = '#FFFFFF';
    if(key === 'CRR') ret = '#000000';
    return ret;
  }
  getRoleBGColor(role) {
    let key = role.substring(0,3);
    return roleColor[roleScore[key]];
  }
    
  isBase64(str) {
    if (str ==='' || str.trim() ===''){ return false; }
    try {
        return btoa(atob(str)) == str;
    } catch (err) {
        return false;
    }
  }

  getAutofailQuestions = (task) => {
    let score_result = "N/A";
    let result_obj = {};
    let is_autofailed = false;
    let totalscore = 0;
    let obtained_score = 0;
    let question_info = [];

    //console.log("task.cur_lane", task.cur_lane)
    if (task.cur_lane === "COMP_FCK_COMPLETE" || task.cur_lane === "COMP_FCK_QC_COMPLETE"  || task.cur_lane === "COMP_FCK_QC_ASSIGNMENT") {
      const task_json = JSON.parse(task.task_json);
      const scoring = "scoring" in task_json.object_data.headerinfo.review_json ? task_json.object_data.headerinfo.review_json.scoring : {};
      const sections = task_json.object_data.headerinfo.review_json.sections;
      let q = 0;
      if ("scoring_enabled" in scoring && scoring.scoring_enabled === true) {
        sections.forEach((section) => {
          section.questions.forEach((question) => {
            q++;
            if (question.included === true) {
              const autofail_enabled = question.autofail.find((af) => af.selected === true);
              obtained_score = obtained_score + parseInt(question.obtained_score);
              if (autofail_enabled.value === "Yes") {
                if (parseInt(question.obtained_score) === 0) {
                  is_autofailed = true;
                  question_info.push(q);
                }
              }
            }
          });
        });
      }
    }
    return question_info;
  };
}

export default new Utils();