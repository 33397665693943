import React from 'react';
import Store from '../../Common/Store';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import APICall from '../../Common/APICall.js';
import ReportRightPane from './ReportRightPane';
import ReportFilter from './ReportFilter';

const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 280px;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: calc(100% - 280px);
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    padding: 10px 20px;
    cursor: pointer;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const ReportHr = styled.div`
	border: 1px solid #919191;
`;

class ReportingIndex extends React.Component
{

    state = {
         orig_report_types: {answer_analysis: 1, fee_earner_analysis: 1, file_audit_data: 1}
        // active_section_id: "auditor_summary", orig_report_types: {answer_analysis: 1, fee_earner_analysis: 1, file_audit_data: 1}
    };

	constructor(props)
	{
		super(props);
	}
    componentDidMount() {
        let active_section_id = "reviews_tobe_completed";
        if (Store.getStoreData('role') === 'senior_manager' || Store.getStoreData('role') === 'manager') {
            active_section_id = "answer_analysis";
         }

        this.setState({
                active_section_id: active_section_id
        })
    }
	closePage = (event) => {
        event.preventDefault();
        let url = "/"
        window.location.replace(url);
    }

    returnSection = (section) => (event) => {
		event.preventDefault();
        this.setState({active_section_id: section});
        console.log("active_section_id", section)
	}

	saveReport = (event) => {
		event.preventDefault();
	}

	render()
	{
	    console.log ("answertab active_section_id",this.state.active_section_id)
	    console.log("answertab orig_report_types",this.state.orig_report_types)
	     
		if (Store.getStoreData('role') !== 'admin_manager' && Store.getStoreData('role') !== 'senior_manager' && Store.getStoreData('role') !== 'manager') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
		return (
			<div style={{padding: "10px 10px 20px 10px"}}>
				<div style={{color: "#222222", fontWeight: "600", fontSize: "18px", float: "left"}}>Report Configuration</div>
				<CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
                <div style={{clear: "both"}}></div>
                <ReviewBodyContainer>
                	<ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
                    {
                        (() => {
                            if (Store.getStoreData('role') === 'admin_manager'){
                                return(
                                    <div>
                                    <ReviewTab onClick={this.returnSection("reviews_tobe_completed")} style={{marginTop: "2px"}}>
                                        {
                                            (() => {
                                                if (this.state.active_section_id === "reviews_tobe_completed") {
                                                    return (<div style={{float: "left", fontWeight: "600"}}>Reviews to be completed</div>);
                                                } else {
                                                    return (<div style={{float: "left"}}>Reviews to be completed</div>);
                                                }
                                            })()
                                        }
                                        <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                        <div style={{clear: "both"}}></div>
                                    </ReviewTab>
                                    <ReviewTab onClick={this.returnSection("auditor_summary")} style={{marginTop: "2px"}}>
                                        {
                                            (() => {
                                                if (this.state.active_section_id === "auditor_summary") {
                                                    return (<div style={{float: "left", fontWeight: "600"}}>Auditor Summary</div>);
                                                } else {
                                                    return (<div style={{float: "left"}}>Auditor Summary</div>);
                                                }
                                            })()
                                        }
                                        <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                        <div style={{clear: "both"}}></div>
                                    </ReviewTab>
                                    <ReviewTab onClick={this.returnSection("reviewee_assignment")} style={{marginTop: "2px"}}>
                                        {
                                            (() => {
                                                if (this.state.active_section_id === "reviewee_assignment") {
                                                    return (<div style={{float: "left", fontWeight: "600"}}>Reviewee Assignment</div>);
                                                } else {
                                                    return (<div style={{float: "left"}}>Reviewee Assignment</div>);
                                                }
                                            })()
                                        }
                                        <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                        <div style={{clear: "both"}}></div>
                                    </ReviewTab>
                                    </div>
                                )
                                
                            }
                        })()
                    }
                		<ReviewTab onClick={this.returnSection("answer_analysis")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "answer_analysis") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Answer Analysis</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Answer Analysis</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>

                    {
                        (() => {
                            if (Store.getStoreData('role') === 'admin_manager'){
                                return(
                                    <ReviewTab onClick={this.returnSection("fee_earner_analysis")} style={{marginTop: "2px"}}>
                                        {
                                            (() => {
                                                if (this.state.active_section_id === "fee_earner_analysis") {
                                                    return (<div style={{float: "left", fontWeight: "600"}}>Fee Earner Analysis</div>);
                                                } else {
                                                    return (<div style={{float: "left"}}>Fee Earner Analysis</div>);
                                                }
                                            })()
                                        }
                                        <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                                        <div style={{clear: "both"}}></div>
                                    </ReviewTab>
                                )
                            }
                        })()
                    }
                       {/*} <ReviewTab onClick={this.returnSection("audit_analysis")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "audit_analysis") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Audit Analysis</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Audit Analysis</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>*/}
                        <ReviewTab onClick={this.returnSection("file_audit_data")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "file_audit_data") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>File Audit Data</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>File Audit Data</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReportHr style={{marginTop: "20px"}} />
                	</ReviewLeftContainer>
                	<ReviewRightContainer>
                    {
                        (() => {
                            if(this.state.active_section_id in this.state.orig_report_types) {
                                return <ReportFilter reportType = {this.state.active_section_id} />
                            } else {
                                return <ReportRightPane reportType = {this.state.active_section_id} />
                            }
                        })()
                        
                    }
                	</ReviewRightContainer>
                	<div style={{clear: "both"}}></div>
                </ReviewBodyContainer>
			</div>
		);
	}
}

export default ReportingIndex;
