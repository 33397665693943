import React from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import { IoIosCheckmark } from 'react-icons/io';
import { Icon } from 'react-icons-kit';
import {bin} from 'react-icons-kit/icomoon/bin';

const Note = styled.div`
	background-color: #FDF1DD;
	padding: 10px;
	border: 1px solid #f4ae3e;
	border-radius: 4px;
	color: #f4ae3e;
	font-weight: 600;
	margin-top: 15px;
`;
const RAGContainer = styled.div`
	width: 100%;
	display: block;
`;
const Floatingdiv = styled.div`
	float: left;
`;
const RAGHeading = styled.div`
	color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const SelectedRAGContainer = styled.div`
	width: 30px;
	height: 30px;
	border: 1px solid #a7a7a7;
	border-radius: 4px;
	padding: 1px;
`;
const SelectedRAGColor = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 4px;
`;
const VerticalRule = styled.div`
	width: 2px;
	height: 56px;
	background-color: #a7a7a7;
`;
const ColorPaletteContainer = styled.div`
	width: 100%;
	margin-top: 10px;
`;
const ColorDiv = styled.div`
	width: 30px;
	height: 30px;
	margin-left: 5px;
	float: left;
	border: 1px solid #a7a7a7;
	border-radius: 4px;
	cursor: pointer;
`;
const LabelTxt = styled.input`
	height: 30px;
    width: 40%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding-left: 10px;
    margin-top: 10px;
    font-family: 'Montserrat',sans-serif;
`;
const AddRstBtn = styled.button`
	cursor: pointer;
    background-color: #37ADA7;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    padding: 10px;
    border: 1px solid #37ADA7;
    border-radius: 5px;
    margin-top: 10px;
`;

class NonScoringSection extends React.Component
{
	state = {non_scores: null, scoring: null, colorpalette: ["#ff0000", "#0ec50e", "#37ada7", "#1e3e62", "#f7af00", "#f2f2f3", "#929292", "#86B039"]};

	componentDidMount() {
		// this.setState({scoring: JSON.parse(JSON.stringify(this.props.scoring))});
		this.processDefaultNonScoringData(this.props.scoring);
		let result = this.props.scoring;
		let scoring = JSON.parse(JSON.stringify(result));
		const newelm = {
			id: 1,
            label: "Green",
            color: "#0EC50E"
		};
		let non_scores = [];
		if('non_scores' in scoring ) {
			non_scores = scoring.non_scores;
		}else{
			non_scores.push(newelm);
		}
		
		scoring.non_scores = non_scores;
		this.setState({scoring: scoring, non_scores});
		// console.log("Scoring:", this.props.scoring);
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			//this.setState({scoring: JSON.parse(JSON.stringify(this.props.scoring))});
			// this.processDefaultNonScoringData(this.props.scoring);
			let result = this.props.scoring;
			let scoring = JSON.parse(JSON.stringify(result));
			const newelm = {
				id: 1,
	            label: "Green",
	            color: "#0EC50E"
			};
			let non_scores = [];
			if('non_scores' in scoring ) {
				non_scores = scoring.non_scores;
			}else{
				non_scores.push(newelm);
			}
			
			scoring.non_scores = non_scores;
			this.setState({scoring: scoring});
		}
	}

	processDefaultNonScoringData = (result) => {
		// let scoring = JSON.parse(JSON.stringify(result));
		// const newelm = {
		// 	id: 1,
  //           label: "Green",
  //           color: "#0EC50E"
		// };
		// let non_scores = [];
		// if('non_scores' in scoring ) {
		// 	non_scores = scoring.non_scores;
		// }else{
		// 	non_scores.push(newelm);
		// }
		
		// scoring.non_scores = non_scores;
		// this.setState({scoring: scoring, non_scores});
	}

	handleColorChange = (coloritem, item) => (event) => {
		event.preventDefault();
		console.log("coloritem:", coloritem);
		console.log("item:", item);
		let scoring = JSON.parse(JSON.stringify(this.state.scoring));
		scoring.non_scores.forEach((element) => {
			if (element.id === item.id) {
				element.color = coloritem;
			}
		});
		this.setState({non_scores: JSON.parse(JSON.stringify(scoring.non_scores))});
		this.props.modifyNonScoring(scoring);
	}

	handleLabel = (item) => (event) => {
		event.preventDefault();
		// let cursor = event.target.selectionEnd;
		let scoring = JSON.parse(JSON.stringify(this.state.scoring));
		scoring.non_scores.forEach((element) => {
			if (element.id === item.id) {
				element.label = event.target.value;
			}
		});
		let non_scores = JSON.parse(JSON.stringify(this.state.non_scores));
		non_scores.forEach((element) => {
			if (element.id === item.id) {
				element.label = event.target.value;
			}
		});
		// this.$lbltxt.selectionEnd = cursor;
		this.setState({non_scores});
		this.props.modifyNonScoring(scoring);
	}

	addRstOpt = (event) => {
		event.preventDefault();
		let scoring = JSON.parse(JSON.stringify(this.state.scoring));
		const newelm = {
			id: scoring.non_scores[scoring.non_scores.length - 1].id + 1,
            label: "Green",
            color: "#0EC50E"
		};
		scoring.non_scores.push(newelm);
		this.setState({non_scores: JSON.parse(JSON.stringify(scoring.non_scores))});
		this.props.modifyNonScoring(scoring);
	}

	delRstOpt = (data) => (event) => {
		event.preventDefault();
		// console.log("data:", data);
		let scoring = JSON.parse(JSON.stringify(this.state.scoring));
		let new_non_scores = [];
		scoring.non_scores.forEach((item) => {
			if (item.id !== data.id) {
				new_non_scores.push(item);
			}
		})
		scoring.non_scores = new_non_scores;
		this.setState({non_scores: JSON.parse(JSON.stringify(scoring.non_scores))});
		this.props.modifyNonScoring(scoring);
	}
	render()
	{
		if (this.state.scoring === null) {
			return (<div></div>);
		}
		console.log("this.state.scoring", this.state.scoring);
		return (
			<div>
				<Note>With scoring turned off, users can assign labels against each question instead</Note>
				<div style={{marginTop: "15px", marginBottom: "15px"}}><span style={{fontWeight: "600"}}>Labels - </span>Configure your labels to enable</div>
				{
					this.state.non_scores.map((item, idx) => {
						return (
							<div key={idx} style={{padding: '30px', marginTop: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
								<RAGContainer>
									{
										(() => {
											if (idx !== 0) {
												return <div style={{float: "right", cursor: "pointer"}} onClick={this.delRstOpt(item)}><Icon icon={bin}/></div>
											}
										})()
									}
									<div style={{clear: "both"}}></div>
									<Floatingdiv style={{width: "10%"}}>
										<div style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Selected</div>
										<SelectedRAGContainer style={{marginTop: "10px"}}>
											<SelectedRAGColor style={{backgroundColor: item.color}}>
												<div style={{float: "right"}}><IoIosCheckmark /></div>
												<div style={{clear: "both"}}></div>
											</SelectedRAGColor>
										</SelectedRAGContainer>
									</Floatingdiv>
									<Floatingdiv style={{width: "3%"}}>
										<VerticalRule style={{marginTop: "4px"}} />
									</Floatingdiv>
									<Floatingdiv style={{width: "85%"}}>
										<div style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Colour Palette</div>
										<ColorPaletteContainer>
											{
												this.state.colorpalette.map((coloritem, index) => {
													if (index === 0) {
														return (<ColorDiv key={index} style={{marginLeft: "0px", backgroundColor: coloritem}} onClick={this.handleColorChange(coloritem, item)}></ColorDiv>)
													} else {
														return (<ColorDiv key={index} style={{marginLeft: "5px", backgroundColor: coloritem}} onClick={this.handleColorChange(coloritem, item)}></ColorDiv>)
													}
												})
											}
											{/*<ColorDiv style={{marginLeft: "5px", textAlign: "center"}}>
												<div style={{marginTop: "6px"}}><FaPlus /></div>
											</ColorDiv>*/}
											<div style={{clear: "both"}}></div>
										</ColorPaletteContainer>
									</Floatingdiv>
									<div style={{clear: "both"}}></div>
								</RAGContainer>
								<div style={{marginTop: "10px", fontWeight: "600"}}>Label</div>
								<LabelTxt ref={node => (this.$lbltxt = node)} type="text" value={item.label} onChange={this.handleLabel(item)} />
							</div>
						);
					})
				}
				<AddRstBtn onClick={this.addRstOpt}>Add Result Option</AddRstBtn>
			</div>
		);
	}
}

export default NonScoringSection;