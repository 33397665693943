import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store';
import { FaTasks } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import * as Style from './Common/StyledComponents';

class ReferrerHeader extends React.Component
{

	redirectTo = (event) => {
		event.preventDefault();
		window.location.assign(document.referrer);
	}

	render()
	{
		let use_site_layout = false
		if(localStorage.getItem('site_layout')!==null){
			let site_layout = JSON.parse(localStorage.getItem('site_layout'));
			use_site_layout = site_layout.result.use_site_layout;
		}
		if(use_site_layout) return (<div></div>)
		
		return (
			<Style.HeadContainer>
			    <Style.HomeLogo style={{color: "#282828"}}><FaTasks /></Style.HomeLogo>
			    <Style.HomeText style={{color: "#282828"}}>
			        <span style={{fontWeight: "600"}}> Task Manager / </span>
			        <span style={{fontWeight: "600", color: "#929292"}}>File Checker</span>
			    </Style.HomeText>
			    <Style.Categories><span style={{cursor: "pointer", color: "#337ab7"}} onClick={this.redirectTo}><TiArrowBack /></span></Style.Categories>
			    <div style={{clear: "both"}}></div>
			</Style.HeadContainer>
		);
	}
}

export default ReferrerHeader;