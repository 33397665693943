import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store'
import { FaHome, FaCog, FaBook, FaElementor, FaWrench, FaRegListAlt } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import * as Style from './StyledComponents'

class CSLHeader extends React.Component
{
    state = {breadcrumbLastText: null};

    componentDidMount()
    {
        this.setState({breadcrumbLastText: this.props.breadcrumbLastText});
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.setState({breadcrumbLastText: this.props.breadcrumbLastText});
        }
    }

    setView = (event) => {
        event.preventDefault();
        window.location.assign("/register");
        // Store.updateStore('prev_view', this.props.from_view);
        // this.props.refreshParentState('register_index');
        // Store.updateStore('is_register', true);
    }

    setHomeView = (event) => {
        event.preventDefault();
        window.location.reload();
        //this.props.refreshParentState(Store.getStoreData('prev_view'));
        Store.updateStore('is_register', false);
    }

    goToHome = (event) => {
        event.preventDefault();
        const homeurl = "/";
        window.location.assign(homeurl);
    }

    render()
    {
        // console.log("store date",  Store.getStoreData('cur_view'));
        let is_register = Store.getStoreData('is_register') ? true : false
        // console.log("is register", is_register);
        let config_url = '/config';
        let module_config_url = '/moduleconfig';
        let reporting_url = '/reporting';
        return (
            <Style.HeadContainer>
                <Style.HomeLogo><FaHome /></Style.HomeLogo>
                <Style.HomeText>
                    <span style={{fontWeight: "600", cursor: "pointer"}} onClick={this.goToHome}>{this.props.homeText}</span>
                    {
                        (() => {
                            if (this.state.breadcrumbLastText !== null) {
                                return <span style={{fontWeight: "600", color: "#929292"}}> / {this.state.breadcrumbLastText}</span>
                            }
                        })()
                    }
                </Style.HomeText>
                {
                    (() => {
                        if (!Store.getStoreData('is_admin')) {
                            return(<Style.Categories><a href={config_url}><FaCog /></a></Style.Categories>)
                        }
                    })()
                }
                {
                    (() => {
                        if (!Store.getStoreData('is_admin')) {
                            return (<Style.Categories style={{cursor: "pointer"}} onClick={this.setView}><FaBook style={{color: "#337ab7"}} /></Style.Categories>);
                        }
                    })()
                }
                {
                    (() => {
                        if (is_register) {
                            return(<Style.Categories style={{cursor: "pointer"}} onClick={this.setHomeView}><TiArrowBack style={{color: "#337ab7"}}/></Style.Categories>)
                        }
                    })()
                }
                {
                    (() => {
                        if (!Store.getStoreData('is_admin')) {
                            return(
                                <div>
                                    <Style.Categories><a href={module_config_url}><FaWrench /></a></Style.Categories>
                                    {/* <Style.Categories><a href={reporting_url}><FaRegListAlt /></a></Style.Categories> */}
                                </div>
                            )
                        }
                    })()
                }
                <div style={{clear: "both"}}></div>
            </Style.HeadContainer>
        );
    }
}
export default CSLHeader;
