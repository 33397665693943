import React from 'react';
import './Scoresegment.css'

class Scoresegment extends React.Component
{

    convertHex = (hex,opacity) => {
        hex = hex.replace('#','');
        let r = parseInt(hex.substring(0,2), 16);
        let g = parseInt(hex.substring(2,4), 16);
        let b = parseInt(hex.substring(4,6), 16);
    
        let result = 'rgba('+r+','+g+','+b+','+opacity/100+')';
        return result;
    }

    render()
    {
        const scores = this.props.scores;
        const obtainedscore = this.props.obtainedscore;
        return (
            <div style={{width: "100%"}}>
            {
                (() => {
                    let scoredisplay = [];
                    for (let i=0; i<scores.length; i++) {
                        let pwidth = scores[i].ubound - scores[i].lbound;
                        if (obtainedscore >= scores[i].ubound) {
                            let scdisp = <div className="ht10" style={{width: pwidth+"%", backgroundColor: scores[i].color}}></div>;
                            scoredisplay.push(scdisp);
                        } else {
                            if (obtainedscore > scores[i].lbound) {
                                let awidth = obtainedscore - scores[i].lbound;
                                pwidth = pwidth - awidth;
                                let scdisp = <div className="ht10" style={{width: awidth+"%", backgroundColor: scores[i].color}}></div>;
                                scoredisplay.push(scdisp);
                                scdisp = <div className="ht10" style={{width: pwidth+"%", backgroundColor: this.convertHex(scores[i].color, 20)}}></div>;
                                scoredisplay.push(scdisp);
                            } else {
                                let scdisp = <div className="ht10" style={{width: pwidth+"%", backgroundColor: this.convertHex(scores[i].color, 20)}}></div>;
                                scoredisplay.push(scdisp);
                            }
                        }
                    }
                    return scoredisplay;
                })()
            }
                <div style={{clear: "both"}}></div>
            </div>
        );
    }
}

export default Scoresegment;