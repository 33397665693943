import React from 'react';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import ChildTask from './ChildTask';
import { FaYenSign } from 'react-icons/fa';
import QAOriginalModal from '../Modals/QAOriginalModal';
import QANewModal from '../Modals/QANewModal';
import FutureFollowups from './FutureFollowups';

const HardCoded = styled.div`
	background-color: #ECF1F2;
	border: 1px solid #a4bcc1;
	border-radius: 5px;
	padding: 20px;
`;
const ResultContainer = styled.div`
	margin-top: 20px;
`;
const ResultBox = styled.div`
	background-color: #ffffff;
	padding: 15px;
	border-radius: 4px;
	box-sizing: border-box;
`;
const ResultHeading = styled.div`
	font-size: 14px;
	color: #B3B3B3;
	font-weight: 600;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const HeaderText = styled.div`
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 15px;
    color: #c0c0c0;
    font-weight: 600;
    letter-spacing: 1px;
`;
const QcDrop = styled.select`
	display: block;
    box-sizing: border-box;
    height: 40px;
    width: 30%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
`;

class QAOverview extends React.Component
{
	state = {section_payload: null, payload: null, modal_view: null, question: null, comment: ""};

	componentDidMount()
	{
		// console.log("section_payload:", this.props.task_id);
		// console.log("task_json:", this.props.task_json);
		this.setState({task_json: this.props.task_json, section_payload: this.props.section_payload, payload: this.props.payload, task_id: this.props.task_id, comment: this.props.comment});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			// console.log("section_payload:", this.props.section_payload);
			this.setState({task_json: this.props.task_json, section_payload: this.props.section_payload, payload: this.props.payload, task_id: this.props.task_id, comment: this.props.comment});
		}
	}

	processMyDisputeQuestion = () => {
		let question = [];
		let sections = this.state.section_payload.object_data.headerinfo.review_json.sections;
		// console.log("question ====>", sections);
		for(let i = 0; i < sections.length; i++ ) {
			let k = 0;
			for(let j = 0; j < sections[i].questions.length; j++ ) {
				k = k + 1;
				console.log("let qies", sections[i].questions[j])
				if(sections[i].questions[j].is_qa) {
					let serial_no = "Q"+(k).toString();
					question.push({"sec_name" : sections[i].name, "qno" : serial_no, "question" : sections[i].questions[j].question, "original" : sections[i].questions[j].answer.text, "new" : sections[i].questions[j].qa_data.answer.text, "q_id": sections[i].questions[j].id})
				}
			}
		}
		// console.log("QUESTIONS:", question);
        let ret = {data: [], columns: []};
        ret.columns =[  {Header: 'Section', accessor: 'section', minWidth: 40, headerStyle: {textAlign: 'left'}}, 
                        {Header: '#', accessor: 'questionno', minWidth: 20, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Question', accessor: 'question', minWidth: 225, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Original', accessor: 'original', minWidth: 20, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'New', accessor: 'new', minWidth: 20, headerStyle: {textAlign: 'left'}},
                        {'Header' : 'View', Cell: row => (
                           <div>
                               <MRButton style={{width: '60px'}} onClick={this.selectQuestion(row.original.id, "original")}>Original</MRButton>&nbsp;<MRButton style={{width: '60px'}} onClick={this.selectQuestion(row.original.id, "new")}>New</MRButton>
                           </div>
                        ), width: 140, headerStyle: {textAlign: 'left'}}];
        for(let q of question) {
			let elem = {
				'section' : q.sec_name, 'questionno' : q.qno,
				'question' : <div title={q.question}>{q.question}</div>,
                'original' : q.original,
                'new' : q.new,
                'id' : q.q_id
            };
            ret.data.push(elem);
        }
        return ret;
	}


	handleComment = (event) => {
		event.preventDefault();
		this.props.handleComment(event.target.value);
	}

	selectQuestion = (question_id, modal_view) => (event) => {
		event.preventDefault();
		// console.log("question_id:", question_id);
		let question = {};
		this.state.section_payload.object_data.headerinfo.review_json.sections.forEach((section) => {
			section.questions.forEach((q) => {
				if (q.id === question_id) {
					question = q;
				}
			})
		})
		console.log("selected_question:", question);
		this.setState({question, modal_view});
		// this.toggleModal("original");
	}

	toggleModal = (modal) => (event) => {
		event.preventDefault();
		this.setState({modal_view: modal});
	}

	handleQcAssessmentResult = (event) => {
		event.preventDefault();
		this.props.handleQcAssessmentResult(event.target.value);
	}

	render()
	{
		if (this.state.section_payload === null) {
			return (
				<div>Loading...</div>
			);
		}
		return (
			<div>
				<HardCoded>
					<div style={{color: "#5B5B5B", fontWeight: "600", fontSize: "14px"}}>Finalise your QA Review</div>
					<div style={{color: "#929494", textAlign: "justify", paddingTop: "10px"}}>
						Please review the below questions/responses which you have disputed.
						Completing this QA with the below questions changed will effect the final score, which is also outlined below.
					</div>
				</HardCoded>
				<ResultContainer>
					<div style={{float: "left", width: "50%", paddingRight: "10px", boxSizing: "border-box"}}>
						<ResultBox>
							<ResultHeading>Original Results</ResultHeading>
							<div style={{marginTop: "10px"}}>
								<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>Questions Answered</div>
								<div style={{float: "right", width: "40%", textAlign: "right"}}>
								{
                                    (() => {
                                        let total_questions = 0;
                                        let answered_questions = 0;
                                        this.state.section_payload.object_data.headerinfo.review_json.sections.forEach((section) => {
                                            total_questions = total_questions + section.questions.length;
                                            section.questions.forEach((question) => {
                                                if (question.answer.text !== "") {
                                                    answered_questions = answered_questions + 1;
                                                }
                                            })
                                        })
                                        return answered_questions+"/"+total_questions;
                                    })()
                                }
								</div>
								<div style={{clear: "both"}}></div>
							</div>
							<div style={{marginTop: "10px"}}>
								<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>N/A Questions</div>
								<div style={{float: "right", width: "40%", textAlign: "right"}}>
								{
                                    (() => {
                                        let na_questions = 0;
                                        this.state.section_payload.object_data.headerinfo.review_json.sections.forEach((section) => {
                                            section.questions.forEach((question) => {
                                                if (!question.included) {
                                                    na_questions = na_questions + 1;
                                                }
                                            })
                                        })
                                        return na_questions;
                                    })()
                                }
								</div>
								<div style={{clear: "both"}}></div>
							</div>
							{
								(() => {
									if (this.state.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
										return (
											<div style={{marginTop: "10px"}}>
												<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>Score</div>
												<div style={{float: "right", width: "40%", textAlign: "right"}}>{Math.round(this.state.section_payload.object_data.result.scoresobtained)}%</div>
												<div style={{clear: "both"}}></div>
											</div>
										);
									}
								})()
							}
							<div style={{marginTop: "10px"}}>
								<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>Result</div>
								{
									(() => {
										if (this.state.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
											return <div style={{float: "right", width: "40%", textAlign: "right", fontWeight: "600", color: this.state.section_payload.object_data.result.ragratingcolor}}>{this.state.section_payload.object_data.result.outcome === null ? "":this.state.section_payload.object_data.result.outcome.toUpperCase()}</div>
										} else {
											return <div style={{float: "right", width: "40%", textAlign: "right", fontWeight: "600"}}>{this.state.task_json.object_data.non_score_assessment.result}</div>
										}
									})()
								}
								<div style={{clear: "both"}}></div>
							</div>
						</ResultBox>
					</div>
					<div style={{float: "left", width: "50%", paddingLeft: "10px", boxSizing: "border-box"}}>
						<ResultBox>
							<ResultHeading>New Results</ResultHeading>
							<div style={{marginTop: "10px"}}>
								<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>Questions Changed</div>
								<div style={{float: "right", width: "40%", textAlign: "right"}}>
								{
                                    (() => {
                                        let total_questions = 0;
                                        let qa_questions = 0;
                                        this.state.section_payload.object_data.headerinfo.review_json.sections.forEach((section) => {
                                            total_questions = total_questions + section.questions.length;
                                            section.questions.forEach((question) => {
                                                if (question.is_qa) {
                                                    qa_questions = qa_questions + 1;
                                                }
                                            })
                                        })
                                        return qa_questions+"/"+total_questions;
                                    })()
                                }
								</div>
								<div style={{clear: "both"}}></div>
							</div>
							<div style={{marginTop: "10px"}}>
								<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>N/A Questions</div>
								<div style={{float: "right", width: "40%", textAlign: "right"}}>
								{
                                    (() => {
                                        let na_questions = 0;
                                        this.state.section_payload.object_data.headerinfo.review_json.sections.forEach((section) => {
                                            section.questions.forEach((question) => {
                                                if (!question.qa_na_included) {
                                                    na_questions = na_questions + 1;
                                                }
                                            })
                                        })
                                        return na_questions;
                                    })()
                                }
								</div>
								<div style={{clear: "both"}}></div>
							</div>
							{
								(() => {
									if (this.state.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
										return (
											<div style={{marginTop: "10px"}}>
												<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>Score</div>
												<div style={{float: "right", width: "40%", textAlign: "right"}}>{Math.round(this.state.section_payload.object_data.qc_score_result.scoresobtained)}%</div>
												<div style={{clear: "both"}}></div>
											</div>
										);
									}
								})()
							}
							<div style={{marginTop: "10px"}}>
								<div style={{float: "left", width: "60%", color: "#5D5D5D", fontWeight: "600"}}>Result</div>
								{
									(() => {
										if (this.state.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
											return <div style={{float: "right", width: "40%", textAlign: "right", fontWeight: "600", color: this.state.section_payload.object_data.qc_score_result.ragratingcolor }}>{this.state.section_payload.object_data.qc_score_result.outcome.toUpperCase()}</div>
										} else {
											let color = "#000000";
											if (this.state.task_json.object_data.qc_non_score_assessment !== null) {
												color = "color" in this.state.task_json.object_data.qc_non_score_assessment ? this.state.task_json.object_data.qc_non_score_assessment.color : "#000000";
											}
											return <div style={{float: "right", width: "40%", textAlign: "right", fontWeight: "600", color: color}}>{this.state.task_json.object_data.qc_non_score_assessment.result}</div>
										}
									})()
								}
								<div style={{clear: "both"}}></div>
							</div>
						</ResultBox>
					</div>
					<div style={{clear: "both"}}></div>
				</ResultContainer>

				<CSLTable add={false} processData={this.processMyDisputeQuestion} refreshCallback={this.refreshState} tableRows="10" />
				<div style={{paddingTop: "40px"}}>
					{/*<ChildTask readonly={this.props.readonly} childtask={this.state.payload.child_tasks} contacts={this.state.payload.contacts} parent_task_id={this.state.task_id} reloadChildTasks={this.props.reloadChildTasks} parent_company_id={this.state.payload.company_id} reviewer={this.state.payload.reviewer}/>*/}
					<FutureFollowups
						childtask={this.state.payload.child_tasks}
						tableRows="10"
						contacts={this.state.payload.contacts}
						arrangeFollowupTasks={this.arrangeFollowupTasks}
						parent_task_id={this.state.task_id}
						parent_company_id={this.state.payload.company_id}
						reloadChildTasks={this.props.reloadChildTasks}
						reviewer={this.state.payload.reviewer}
						readonly={this.props.readonly}
						info={"reviewee_acknowledgement" in this.state.task_json.object_data.headerinfo ? this.state.task_json.object_data.headerinfo.reviewee_acknowledgement : null}
						future_followups={this.state.task_json.object_data.future_followups}
						addFutureFollowup={this.props.addFutureFollowup}
						removeFutureFollowup={this.props.removeFutureFollowup}
					/>
				</div>

				<div style={{marginTop: "20px", color: "#B5B5B5", fontWeight: "600", fontSize: "14px"}}>Overall Comments</div>
				<div style={{marginTop: "10px"}}>
					<textarea rows="6" cols="100" onChange={this.handleComment} value={this.state.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled ? this.state.comment : this.state.task_json.object_data.qc_non_score_assessment.qc_comments} style={{backgroundColor: "#ffffff", border: "1px solid #ffffff", borderRadius: "5px", padding: "15px", width: "96%", color: "#B5B5B5"}}></textarea>
				</div>
				{
					(() => {
						if (!this.state.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
							return(<div>
								<div style={{marginTop: "20px", color: "#B5B5B5", fontWeight: "600", fontSize: "14px"}}>Result</div>
								<div style={{marginTop: "10px"}}>
									<QcDrop value={this.state.task_json.object_data.qc_non_score_assessment.result === "-" ? "Select" : this.state.task_json.object_data.qc_non_score_assessment.result} onChange={this.handleQcAssessmentResult}>
										<option value="-">Select</option>
										{
											this.state.task_json.object_data.headerinfo.review_json.scoring.non_scores.map((item) => {
												return (<option value={item.label}>{item.label}</option>);
											})
										}
									</QcDrop>
								</div>
							</div>)
						}
					})()
				}
				{
					(() => {
						switch(this.state.modal_view) {
							case "original":
								return (<QAOriginalModal toggleModal={this.toggleModal} question={this.state.question} task_json={this.state.task_json} />);
								break;
							case "new":
								return (<QANewModal toggleModal={this.toggleModal} question={this.state.question} task_json={this.state.task_json} />);
								break;
							default:
								return (<div></div>);
						}
					})()
				}
			</div>
		);
	}
}

export default QAOverview;