import React from "react";
import Store from "../../Common/Store";
import styled from "styled-components";
import * as Style from "../Common/StyledComponents";
import DatePicker from "react-date-picker";
import Dropzone from "../Common/Dropzone.js";
import APDropdown from "../Common/APDropdown/APDropdown.js";
import APICall from "../../Common/APICall";
import { FiDownload } from "react-icons/fi";
import { FaTimes, FaStarOfLife, FaCalendarAlt } from "react-icons/fa";
import { saveAs } from "file-saver";
import "./Partials.css";
import CSLTextInput from "./CSLTextInput";
import CSLTextArea from "./CSLTextArea";
import moment from "moment";
import HtmlEditor from '../Common/HtmlEditor';
import Utils from '../../Common/Utils.js';

const OverviewHeader = styled.div`
  background-color: #ffffff;
  padding: 20px;
  font-weight: 600;
  color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const OverviewBody = styled.div`
  padding: 30px 20px;
  margin-top: 2px;
  background-color: #ffffff;
`;
const MRModalLabel = styled.div`
  color: ${process.env.DEFAULT_TEXT_COLOR};
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const MRModalSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const MRModalInput = styled.input`
  height: 28px;
  width: 98%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  font-family: "Montserrat", sans-serif;
`;
const CSLDateCoverOld = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 3px;
  border: 1px solid #ffffff;
  box-shadow: 0 0 4px #c4c4c4;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
  color: ${process.env.DEFAULT_TEXT_COLOR};
`;

const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 40%;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #282828;
  background-color: #ffffff;
  height: 25px;
  padding-top: 2px;
  padding-left: 2px;
  // float: left;
  position: relative;
`;

const ModalNameLabelDiv = styled.div`
  height: auto;
  width: 20%;
  padding-top: 7px;
  font-weight: 600;
  color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const ModalNameInputDiv = styled.div`
  height: auto;
  width: 80%;
  padding-top: 7px;
`;
const Tabbutton = styled.div`
  display: block;

  color: #303030;
  padding: 16px 23px;
  width: 65%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  margin: 5px;
  background-color: white;
`;
const Tabbuttonactive = styled.div`
  display: block;
  color: #303030;
  padding: 16px 23px;
  width: 65%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
  margin: 5px;
  background-color: white;
  font-weight: bold;
`;
const ModalTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    font-size: 14px;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;
const RadioBlock = styled.div`
  margin-top: 10px;
`;

class AssignSection extends React.Component {
  state = { assign_data: null, task_id: 0 };

  componentDidMount() {
    console.log("THISPROPS::",this.props)
    this.setState({ assign_data: this.props.assign_data, task_id: this.props.task_id });
  }

  componentDidUpdate(prevProps) {
    console.log("component updated");
    if (this.props !== prevProps) {
      this.setState({ assign_data: this.props.assign_data });
    }
  }

  handleChangeCurGc = (evt) => {
    evt.preventDefault();
    this.props.handleChangeCurGc(evt.target.value);
  };

  handleChange = (event) => {
    event.preventDefault();
    // console.log("change value ", this.state.assign_data.reviewee_designation)
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data[event.target.name] = event.target.value;
    assign_data.date = new Date(assign_data.date);
    assign_data.due_date = new Date(assign_data.due_date);
    assign_data.sale_date = new Date(assign_data.sale_date);
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
    // this.setState({ [event.target.name] : event.target.value })
    // this.props.handleChange(event.target.name, event.target.value);
  };

  switchRadio = (event) => {
    event.preventDefault();
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data.reviewee_acknowledgement = assign_data.reviewee_acknowledgement === true ? false : true;
    assign_data.date = new Date(assign_data.date);
    assign_data.due_date = new Date(assign_data.due_date);
    assign_data.sale_date = new Date(assign_data.sale_date);
    this.props.modifyAssignData(assign_data);
  };

  handleTextChange = (obj) => {
    console.log("obj:", obj);
    // this.setState({[obj.name]: obj.value});
    // this.props.handleChange(obj.name, obj.value);
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data.sale_date = new Date(assign_data.sale_date);
    assign_data.due_date = new Date(assign_data.due_date);
    assign_data.date = new Date(assign_data.date);
    if (obj.entity === null) {
      assign_data[obj.name] = obj.value;
    } else {
      assign_data[obj.entity].forEach((item) => {
        if (item.name === obj.name) item[obj.field] = obj.value;
      });
    }
    //console.log("assign_data::",assign_data)
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
  };

  onSaleDateChange = (date) => {
    console.log("onSaleDateChange",date)
    if (!moment.isDate(date)) {
      return;
    }
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data.sale_date = date;

    for (let item of assign_data.custom_terminology_fields) {
        if (item.name === "sale_date") {
            item.value = date;
        }
    }

    assign_data.due_date = new Date(assign_data.due_date);
    assign_data.date = new Date(assign_data.date);
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
    // this.props.onSaleDateChange(date);
  };
  handleReviewerChange = (event) => {
    event.preventDefault();
    this.setState({ cur_reviewer_id: parseInt(event.target.value) });
  };
  onDateChange = (date) => {
    console.log("onDateChange",date)
    if (!moment.isDate(date)) {
      return;
    }
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data.due_date = date;
    assign_data.date = new Date(assign_data.date);
    assign_data.sale_date = new Date(assign_data.sale_date);
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
    // this.props.onDateChange(date);
  };

  filesLoaded = (files) => {
    console.log("filesLoaded fired");
    this.props.filesLoaded(files);
  };

  handleDownload = (index) => {
    let cur_file = this.state.assign_data.curBinFiles[index];
    let dnld = cur_file.bin_file.replace(/ /g, "+");
    // console.log("binary file before download:", dnld);
    var binaryString = window.atob(dnld);
    // console.log('binaryString', binaryString);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, cur_file.name);
  };

  generateField = (data, data_container) => {
    return (
      <div key={data.name}>
        <MRModalLabel>
          {(() => {
            if (data.required) {
              return (
                <div>
                  {data.nickname}
                  <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
                    <FaStarOfLife title={data.nickname + " required"} />
                  </span>
                </div>
              );
            } else {
              return data.nickname;
            }
          })()}
        </MRModalLabel>
        {(() => {
          let fieldhtml = <div></div>;
          switch (data.type) {
            case "Alpha":
              fieldhtml = this.createAlphaField(data, data_container);
              break;
            case "Numeric":
              fieldhtml = this.createNumericField(data, data_container);
              break;
            case "Calendar":
              fieldhtml = this.createCalendarField(data, data_container);
              break;
            case "Dropdown":
              fieldhtml = this.createDropdownField(data, data_container);
              break;
            case "Auto-Populated Dropdown":
              fieldhtml = this.createAPDropdownField(data, data_container);
              break;
            default:
              fieldhtml = fieldhtml;
          }
          if (data_container === "additional_fields") return fieldhtml;
        })()}
      </div>
    );
  };

  createAPDropdownField = (data, data_container) => {
    // console.log('createAPDropdownField data, data_container', data, data_container)
    // return <div></div>
    return <APDropdown id={"apd-" + data.feed_id} feedId={data.feed_id} selectedText={data.value} selectCallback={this.onAPDropdownSelect} stack={{ name: data.name, data_container: data_container }} />;
  };

  createAlphaField = (data, data_container) => {
    return <CSLTextInput id={"text-" + data.name} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />;
    // return (<MRModalInput type="text" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />);
  };

  createNumericField = (data, data_container) => {
    return <MRModalInput id={"number-" + data.name} type="number" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />;
  };

  createCalendarField = (data, data_container) => {
    console.log("data.value:", data.value);
    return (
      <CSLDateCover>
        <DatePicker id={"date-" + data.name} onChange={(date) => this.handleAddlField(date)} clearIcon={null} calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />} locale={"en-GB"} value={data.value} />
      </CSLDateCover>
    );
  };

  createDropdownField = (data, data_container) => {
    return (
      <div>
        <MRModalSelect id={"select-" + data.name} onChange={this.handleAddlField(data.type, data_container)} name={data.name} value={data.value}>
          <option key="sel" value="">
            Select
          </option>
          {data.dropdown_options.map((opt, index) => {
            return (
              <option key={index} value={opt}>
                {opt}
              </option>
            );
          })}
          {(() => {
            if (data_container === "additional_fields") {
              if ("other" in data) {
                if (data.other === true) {
                  return <option value="other">Other</option>;
                }
              }
            }
          })()}
        </MRModalSelect>
        {(() => {
          if (data_container === "additional_fields") {
            if ("other" in data) {
              if (data.other === true && data.value === "other") {
                return (
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px" }}>
                      <MRModalLabel style={{ margin: "0px" }}>Please give details</MRModalLabel>
                    </div>
                    <div style={{ float: "left", width: "80%", boxSizing: "border-box" }}>
                      {/* <MRModalInput value={data.other_value} type="text" onChange={this.setAddlOtherValue(data.name)} /> */}
                      <CSLTextInput id={"text-" + data.name} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value" />
                    </div>
                    <div style={{ clear: "both" }}></div>
                  </div>
                );
              }
            }
          }
        })()}
      </div>
    );
  };

  setAddlOtherValue = (addl_field_name) => (event) => {
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data.additional_fields.forEach((field) => {
      if (field.name === addl_field_name) {
        field.other_value = event.target.value;
      }
    });
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
    // this.props.modifyCustomAndAdditionalFieldChange(assign_data);
  };

  generateRevieweeDesignationField = (field) => {
    console.log("reviewee_designation::",field)
    return (
      <div>
        <MRModalLabel>
          {(() => {
            if (field.required) {
              return (
                <div>
                  {field.nickname}
                  <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
                    <FaStarOfLife title={field.nickname + " required"} />
                  </span>
                </div>
              );
            } else {
              return field.nickname;
            }
          })()}
        </MRModalLabel>
        {(() => {
          if (field.type === "Alpha") {
            // return (<MRModalInput type="text" name="reviewee_designation" value={this.state.assign_data.reviewee_designation} onChange={this.handleChange}/>)
            return <CSLTextInput id="text-reviewee-designation" entity={null} name="reviewee_designation" value={this.state.assign_data.reviewee_designation} handleTextChange={this.handleTextChange} field={null} />;
          } else if (field.type === "Dropdown") {
            return (
              <MRModalSelect id="select-reviewee-designation" onChange={this.handleChange} name="reviewee_designation" value={this.state.assign_data.reviewee_designation}>
                <option key={0} value="">
                  Select
                </option>
                {field.dropdown_options.map((opt, index) => {
                  return (
                    <option key={index + 1} value={opt}>
                      {opt}
                    </option>
                  );
                })}
                {(() => {
                  if (field.other) {
                    return <option value="other">Other</option>;
                  }
                })()}
              </MRModalSelect>
            );
          } else {
            // console.log('generateRevieweeDesignationField APDropdown field', field)
            return <APDropdown feedId={field.feed_id} selectedText={field.value} selectCallback={this.onAPDropdownSelect} stack={{ name: "reviewee_designation", data_container: "custom_terminology_fields" }} />;
          }
        })()}
      </div>
    );
  };

  handleAddlField = (field_type, data_container) => (event) => {
    event.preventDefault();
    console.log("EVT ", event);
    console.log("event.target.name",event.target.name)
    // if (!moment.isDate(event)) {
    //   return;
    // }
    // console.log("field_type:", field_type);
    // console.log("data_container", data_container);
    // console.log("name", event.target.name);
    // console.log("value", event.target.value);
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    // console.log("assign_data", assign_data);
    if (event.target.name === "sale_date" || event.target.name === "reviewee_designation") {
      assign_data[event.target.name] = event.target.value;
      assign_data[data_container].forEach((item) => {
        if (item.name === event.target.name) {
          item.value = event.target.value;
        }
      });
    } else {
      console.log("HERE")
      assign_data[data_container].forEach((item) => {
        if (item.name === event.target.name) {
          item.value = event.target.value;
        }
      });
    }
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
    // this.props.modifyCustomAndAdditionalFieldChange(assign_data);
  };

  onAPDropdownSelect = (stack) => {
    // console.log('onAPDropdownSelect stack', stack)
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    // console.log("assign_data", assign_data);
    let element = assign_data[stack.data_container].filter(function (item) {
      return item.name === stack.name;
    })[0];
    element.value = stack.value;
    element.option_id = stack.option_id;
    if (stack.name === "reviewee_designation") {
      assign_data.reviewee_designation = stack.value;
    }
    // console.log("assign_data", assign_data);
    // this.props.modifyCustomAndAdditionalFieldChange(assign_data);
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
  };

  setRevDesgOtherValue = (event) => {
    event.preventDefault();
    let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
    assign_data.custom_terminology_fields.forEach((field) => {
      if (field.name === "reviewee_designation") {
        field.other_value = event.target.value;
      }
    });
    // this.setState({assign_data});
    this.props.modifyAssignData(assign_data);
    // this.props.modifyCustomAndAdditionalFieldChange(assign_data);
  };

  render() {
    if (this.state.assign_data === null) {
      return <div>Loading...</div>;
    }

    console.log("assign_data:", this.state.assign_data);
    const module_config = Store.getStoreData("module_config");

    let editcomp = "editcomp" in this.props ? this.props.editcomp : false;
    return (
      <div>
        <OverviewHeader>Assign</OverviewHeader>
        <OverviewBody>
          <MRModalLabel>Choose Group Company
            <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
              <FaStarOfLife title="Group Company required" />
            </span>
          </MRModalLabel>
          <MRModalSelect id="select-gc" onChange={this.handleChangeCurGc} name="cur_gc_id" value={this.state.assign_data.cur_gc_id} disabled={this.props.task_id === 0 ? false : true}>
            <option key="0" value="0">
              Select
            </option>
            {this.state.assign_data.my_companies.map((gc) => {
              return (
                <option key={gc.id} value={gc.id}>
                  {gc.name}
                </option>
              );
            })}
          </MRModalSelect>
          {(() => {
            if (this.props.task_id !== 0) {
              const cur_review = Store.getStoreData("my_reviews").find((item) => parseInt(item.id) === parseInt(this.state.assign_data.cur_review_id));
              return (
                <div>
                  <MRModalLabel>Review
                  <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
                    <FaStarOfLife title="Review required" />
                  </span>
                  </MRModalLabel>
                  <p>{cur_review.nick_name}</p>
                </div>
              );
            } else {
              return (
                <div>
                  <MRModalLabel>Choose a Review
                  <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
                    <FaStarOfLife title="Review required" />
                  </span>
                  </MRModalLabel>
                  <MRModalSelect id="select-review" onChange={this.handleChange} name="cur_review_id" value={this.state.assign_data.cur_review_id}>
                    <option key="0" value="0">
                      Select
                    </option>
                    {this.state.assign_data.my_reviews.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </MRModalSelect>
                </div>
              );
            }
          })()}
          <MRModalLabel>
            Customer Name
            <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
              <FaStarOfLife title="Customer Name required" />
            </span>
          </MRModalLabel>
          {/* <MRModalInput type="text" name="customer_name" value={this.state.assign_data.customer_name} onChange={this.handleChange} /> */}
          <CSLTextInput id="text-customer" entity={null} name="customer_name" value={this.state.assign_data.customer_name} handleTextChange={this.handleTextChange} field={null} disabled={editcomp ? true : false}/>
          {(() => {

          const item = this.state.assign_data.custom_terminology_fields.find((obj) => {
            return obj.name === "sale_date";
          });

          if(!item || !item.is_active){
            return;
          }
            return(<>
              <MRModalLabel>
                {(() => {
                  if (this.state.assign_data.custom_terminology_fields.length !== 0) {
                    if (item.required) {
                      return (
                        <div>
                          {item.nickname}
                          <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
                            <FaStarOfLife title={item.nickname + " required"} />
                          </span>
                        </div>
                      );
                    } else {
                      return item.nickname;
                    }
                  } else {
                    return "Policy Start Date";
                  }
                })()}
              </MRModalLabel>
              <CSLDateCover>
                <DatePicker
                  id="date-policy"
                  onChange={(date) => this.onSaleDateChange(date)}
                  clearIcon={null}
                  calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />}
                  locale={"en-GB"}
                  value={new Date(this.state.assign_data.sale_date)}
                />
              </CSLDateCover>
            </>)
          })()}
          <MRModalLabel>Reviewer
            <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
              <FaStarOfLife title="Reviewer required" />
            </span>
          </MRModalLabel>
          {(() => {
            if (Store.getStoreData("loggedin_user_info").role === "file_checker") {
              return <p>{Store.getStoreData("loggedin_user_info").ContactName}</p>;
            } else {
              return (
                <MRModalSelect id="select-reviewer" onChange={this.handleChange} name="cur_reviewer_id" value={this.state.assign_data.cur_reviewer_id} disabled={ editcomp ? true : false}>
                  {this.state.assign_data.reviewers.map((item) => {
                    if (parseInt(item.id) !== parseInt(this.state.assign_data.cur_reviewee_id)) {
                      return (
                        <option key={"r" + item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    }
                  })}
                </MRModalSelect>
              );
            }
          })()}
          <MRModalLabel>Reviewee
            <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
              <FaStarOfLife title="Reviewee required" />
            </span>
          </MRModalLabel>
          <MRModalSelect id="select-reviewee" onChange={this.handleChange} name="cur_reviewee_id" value={this.state.assign_data.cur_reviewee_id}>
            {this.state.assign_data.reviewees.map((item) => {
              if (parseInt(item.id) !== parseInt(this.state.assign_data.cur_reviewer_id))
                return (
                  <option key={"re" + item.id} value={item.id}>
                    {item.name}
                  </option>
                );
            })}
          </MRModalSelect>
          {
            module_config.general.related_individual === true &&
            <>
              <MRModalLabel>Related Individual</MRModalLabel>
              <MRModalSelect id="select-reviewee" onChange={this.handleChange} name="related_individual_id" value={this.state.assign_data.related_individual_id}>
                <option key={"rel0"} value="0">Select</option>
                {this.state.assign_data.reviewees.map((item) => {
                  if (parseInt(item.id) !== parseInt(this.state.assign_data.cur_reviewer_id) && parseInt(item.id) !== parseInt(this.state.assign_data.cur_reviewee_id))
                    return (
                      <option key={"rel" + item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                })}
              </MRModalSelect>
            </>
          }
          {(() => {
            const field = this.state.assign_data?.custom_terminology_fields?.find((item) => {
              return item.name === "reviewee_designation";
            });
            if(!field?.is_active){
              return;
            }
            if (field) {
              // return this.generateField(field, 'custom_terminology_fields');
              if(field?.is_active){
                return this.generateRevieweeDesignationField(field);
              }
            } else {
              if (Store.getStoreData("module_config").general.reviewee_designation_required) {
                return (
                  <div>
                    <div style={{ fontWeight: "600", margin: "20px 0px 10px 0px" }}>
                      Reviewee Designation
                      <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
                        <FaStarOfLife title="Reviewee Designation required" />
                      </span>
                    </div>
                    <MRModalInput id="text-reviewee-designation" type="text" name="reviewee_designation" value={this.state.reviewee_designation} onChange={this.handleChange} />
                  </div>
                );
              } else {
                return (
                  <div>
                    <MRModalLabel>Reviewee Designation</MRModalLabel>
                    {/* <MRModalInput type="text" name="reviewee_designation" value={this.state.reviewee_designation} onChange={this.handleChange}/> */}
                    <CSLTextInput id="text-reviewee-designation" entity={null} name="reviewee_designation" value={this.state.assign_data.reviewee_designation} handleTextChange={this.handleTextChange} field={null} />
                  </div>
                );
              }
            }
          })()}
          {(() => {
            if (this.state.assign_data.custom_terminology_fields.length !== 0) {
              const field = this.state.assign_data.custom_terminology_fields.find((item) => {
                return item.name === "reviewee_designation";
              });
              console.log("reviewee_designation::field",field)
              if (field !== undefined) {
                if ("other" in field) {
                  if (field.other === true && this.state.assign_data.reviewee_designation === "other") {
                    return (
                      <div style={{ marginTop: "15px" }}>
                        <div style={{ float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px" }}>
                          <MRModalLabel style={{ margin: "0px" }}>Please give details</MRModalLabel>
                        </div>
                        <div style={{ float: "left", width: "80%", boxSizing: "border-box" }}>
                          {/* <MRModalInput value={field.other_value} type="text" onChange={this.setRevDesgOtherValue} /> */}
                          <CSLTextInput
                            id="text-reviewee-designation-other"
                            entity="custom_terminology_fields"
                            name="reviewee_designation"
                            value={field.other_value}
                            handleTextChange={this.handleTextChange}
                            field="other_value"
                          />
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    );
                  }
                }
              }
            }
          })()}
          {(() => {
            if (Store.getStoreData("module_config").general.reviewee_acknowledgement.enabled === true) {
              return (
                <div>
                  <MRModalLabel>Reviewee to read and acknowledge understanding of the review</MRModalLabel>
                  {(() => {
                     if (editcomp) {
                      if (this.state.assign_data.reviewee_acknowledgement === true) {
                        return (
                          <RadioBlock>
                            <SelectedRadio>Yes</SelectedRadio>
                            <UnselectedRadio>No</UnselectedRadio>
                          </RadioBlock>
                        );
                      } else {
                        return (
                          <RadioBlock>
                            <UnselectedRadio>Yes</UnselectedRadio>
                            <SelectedRadio>No</SelectedRadio>
                          </RadioBlock>
                        );
                      }
                    } else {
                      if (this.state.assign_data.reviewee_acknowledgement === true) {
                        return (
                          <RadioBlock>
                            <SelectedRadio>Yes</SelectedRadio>
                            <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                          </RadioBlock>
                        );
                      } else {
                        return (
                          <RadioBlock>
                            <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                            <SelectedRadio>No</SelectedRadio>
                          </RadioBlock>
                        );
                      }
                    }

                  })()}
                </div>
              );
            }
          })()}
          <MRModalLabel>File reference
          <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
          <FaStarOfLife title="File reference required" />
          </span>
          </MRModalLabel>
          {/* <MRModalInput type="text" name="file_reference" value={this.state.assign_data.file_reference} onChange={this.handleChange}/> */}
          <CSLTextInput id="text-file-reference" entity={null} name="file_reference" value={this.state.assign_data.file_reference} handleTextChange={this.handleTextChange} field={null} disabled={editcomp ? true : false}/>
          <MRModalLabel>Scheduled Date
          <span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
          <FaStarOfLife title="Scheduled Date required" />
          </span>
          </MRModalLabel>
          <CSLDateCover>
            {(() => {
              if (editcomp) {
                const cur_review = Store.getStoreData("my_reviews").find((item) => parseInt(item.id) === parseInt(this.state.assign_data.cur_review_id));
                return (
                  <div>{moment(this.state.assign_data.due_date).format("DD/MM/YYYY")}</div>
                );
              } else {
                return(
                  <DatePicker id="date-scheduled-date" onChange={this.onDateChange} clearIcon={null} calendarIcon={<FaCalendarAlt style={{ color: "#243E57" }} />} locale={"en-GB"} value={new Date(this.state.assign_data.due_date)} />

                  )
              }
            })()}
          </CSLDateCover>
          {(() => {
            if (this.state.assign_data.additional_fields.length !== 0) {
              let custom_html = [];
              this.state.assign_data.additional_fields.forEach((item) => {
                console.log("MY FIELDS::", item)
                // if(item.isActive === 1 || (!('isActive' in item)) || item.isActive === null){
                if(item.is_active){
                  const field = this.generateField(item, "additional_fields");
                  custom_html.push(field);
                }
              });
              return custom_html;
            }
          })()}
          <MRModalLabel>Comments</MRModalLabel>
          {
						(() => {
              let assign_comment = this.state.assign_data.assignment_comments;
              if(Utils.isBase64(assign_comment)){
                assign_comment = window.decodeURIComponent(atob(assign_comment));
                //console.log("assign_comment::",assign_comment)
              }
               if (editcomp) {
              const cur_review = Store.getStoreData("my_reviews").find((item) => parseInt(item.id) === parseInt(this.state.assign_data.cur_review_id));
              return (
                <div dangerouslySetInnerHTML={{ __html: assign_comment }}></div>
              );
            } else {
              return(

                <HtmlEditor
                  content={assign_comment}
                  content_type={'assignment_comments'}
                  onChange={this.handleTextChange}
                />

              )
            }
          })()
					}

          {(() => {
            if (this.state.task_id > 0) {
              return (
                <div>
                  <MRModalLabel>Existing Attachments</MRModalLabel>
                  <div style={{ width: "100%", boxSizing: "border-box" }}>
                    {(() => {
                      if (this.state.assign_data.cur_files_json !== null) {
                        return (
                          <div>
                            {this.state.assign_data.curBinFiles.map((f, index) => {
                              console.log("f", f);
                              const file_parts = f.name.split(".");
                              let ext = "PDF";
                              switch (file_parts[file_parts.length - 1]) {
                                case "jpeg":
                                case "jpg":
                                  ext = "JPG";
                                  break;
                                case "png":
                                  ext = "PNG";
                                  break;
                                case "docx":
                                  ext = "DOCX";
                                  break;
                                case "doc":
                                  ext = "DOC";
                                  break;
                                case "msg":
                                  ext = "MSG";
                                  break;
                                case "txt":
                                  ext = "TXT";
                                  break;
                                case "ppt":
                                  ext = "PPT";
                                  break;
                                case "pptx":
                                  ext = "PPTX";
                                  break;
                                case "xls":
                                  ext = "XLS";
                                  break;
                                case "xlsx":
                                  ext = "XLSX";
                                  break;
                                default:
                                  ext = "PDF";
                                  break;
                              }
                              console.log("ext", ext);
                              let file_icon_var = ext === "DOCX" ? "DOC" : ext;
                              file_icon_var = ext === "XLSX" ? "XLS" : ext;
                              const file_icon = `/dropzoneImages/${ext}.png`;
                              return (
                                <div style={{ width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index % 2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px" }}>
                                  <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                                    <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                                      <img src={file_icon} height="100px" width="auto" />
                                      {/*<Style.FileTypeContainer>{ext}</Style.FileTypeContainer>*/}
                                    </div>
                                    <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                                      <div>
                                        <strong title={f.name} style={{ color: "#000000" }}>
                                          {f.name.length > 22 ? f.name.substring(0, 22) : f.name}
                                        </strong>
                                        <br />
                                        <br />
                                      </div>
                                      <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(index)}>
                                        <FiDownload /> Download
                                      </div>
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                  </div>
                                </div>
                              );
                            })}
                            <div style={{ clear: "both" }}></div>
                          </div>
                        );
                      } else {
                        return <div>No existing attachments.</div>;
                      }
                    })()}
                  </div>
                </div>
              );
            }
          })()}
          {/*</Style.ModalNameInputDiv>*/}
            {(() => {
              if (!editcomp) {
                const cur_review = Store.getStoreData("my_reviews").find((item) => parseInt(item.id) === parseInt(this.state.assign_data.cur_review_id));
                return (
                  <div>
                    <Dropzone onFilesAdded={this.filesLoaded} />
                    {/*<ModalNameInputDiv style={{ minHeight:'140px', height: '100px'}}>
                          <Dropzone onFilesAdded={this.filesLoaded} />
                      </ModalNameInputDiv>*/}
                  </div>
                );
              }
            })()}

        </OverviewBody>
      </div>
    );
  }
}

export default AssignSection;
