import React from 'react';
import styled from 'styled-components';
import APICall from '../../Common/APICall'
import Store from '../../Common/Store.js';

const FCThumbnailsContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
`;
const FCThumbnail = styled.div`
    float: left;
    box-sizing: border-box;
    margin-left: 10px;
    width: calc(15% - 10px);
    height: 200px;
    background-color: #ffffff;
    border-radius: 3px;
`;
const FCThumbnailHeader = styled.div`
    padding: 20px 20px 0px 20px;
    font-weight: 600;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const FCThumbnailbody = styled.div`
    padding: 20px;
    font-weight: 600;
    font-size: 56px;
    color: #7a8da1;
    text-align: center;
    padding-top: 15%;
`;

class FCThumbnails extends React.Component
{
    state = {stats: null};
    constructor(props) {
        super(props);
    }
    componentDidMount()
    {
        // const postData = {command: "get_stats"};
        // const api = new APICall();
        // api.command(postData, this.processData);
        this.setState({stats: this.props.stats});
    }

    componentDidUpdate() {
        let thumb1width = "200px";
        let thumb2width = "200px";
        let thumb3width = "200px";
        let thumb4width = "200px";
        if (document.getElementById("thumb1") !== null) {
            thumb1width = document.getElementById("thumb1").offsetWidth+"px";
            document.getElementById("thumb1").style.height = thumb1width;
        }
        if (document.getElementById("thumb2") !== null) {
            thumb2width = document.getElementById("thumb2").offsetWidth+"px";
            document.getElementById("thumb2").style.height = thumb2width;
        }
        if (document.getElementById("thumb3") !== null) {
            thumb3width = document.getElementById("thumb3").offsetWidth+"px";
            document.getElementById("thumb3").style.height = thumb3width;
        }
        if (document.getElementById("thumb4") !== null) {
            thumb4width = document.getElementById("thumb4").offsetWidth+"px";
            document.getElementById("thumb4").style.height = thumb3width;
        }
    }

    processData = (result) => {
        this.setState({stats: result});
    }

    render()
    {
        if (this.state.stats === null) {
            return <div>Loading...</div>;
        }
        return (
            <FCThumbnailsContainer>
                <FCThumbnail id="thumb1" style={{marginLeft: "0px"}}>
                    <FCThumbnailHeader>{this.state.stats[0].header}</FCThumbnailHeader>
                    <FCThumbnailbody>{this.state.stats[0].data}</FCThumbnailbody>
                </FCThumbnail>
                <FCThumbnail id="thumb2">
                    <FCThumbnailHeader>{this.state.stats[1].header}</FCThumbnailHeader>
                    <FCThumbnailbody>{this.state.stats[1].data}</FCThumbnailbody>
                </FCThumbnail>
                <FCThumbnail id="thumb3">
                    <FCThumbnailHeader>{this.state.stats[2].header}</FCThumbnailHeader>
                    <FCThumbnailbody>{this.state.stats[2].data}</FCThumbnailbody>
                </FCThumbnail>
                <FCThumbnail id="thumb4">
                    <FCThumbnailHeader>{this.state.stats[3].header}</FCThumbnailHeader>
                    <FCThumbnailbody>{this.state.stats[3].data}</FCThumbnailbody>
                </FCThumbnail>
                <div style={{clear: "both"}}></div>
            </FCThumbnailsContainer>
        );
    }
}
export default FCThumbnails;