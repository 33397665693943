import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {software_layout} from 'react-icons-kit/linea/software_layout';
import {basic_floppydisk} from 'react-icons-kit/linea/basic_floppydisk';
import {software_pencil} from 'react-icons-kit/linea/software_pencil'
import {arrows_square_check} from 'react-icons-kit/linea/arrows_square_check';
import CslSimpleDropDown from './CslSimpleDropDown';
import {checkboxChecked} from 'react-icons-kit/icomoon/checkboxChecked';
import {checkboxUnchecked} from 'react-icons-kit/icomoon/checkboxUnchecked';
import APDropdownOptions from '../Common/APDropdown/APDropdownOptions'

const FieldContainer = styled.div`
	padding: 20px 0px;
	border-bottom: 2px solid #d2d1d1;
`;
const Section80 = styled.div`
	width: 80%;
	float: left;
	box-sizing: border-box;
`;
const Section20 = styled.div`
	width: 20%;
	float: left;
	box-sizing: border-box;
	text-align: right;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;
const TextBox = styled.input`
	height: 20px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    &:focus {
		outline-width: 0;
	}
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;

class RevieweeDesignationField extends React.Component
{
	state = {custom_fields: null, data: null, dropdown_options: "", editable: false, current_feed_id: null};

	componentDidMount()
	{
		let data = this.props.custom_fields.custom_terminology_fields.find((item) => {
			return item.name === "reviewee_designation"
		})
		let dropdown_options = "";
		if (data.dropdown_options.length !== 0) {
			data.dropdown_options.forEach((item, index) => {
				if (index !== (data.dropdown_options.length - 1)) {
					dropdown_options = dropdown_options + item + "\n";
				} else {
					dropdown_options = dropdown_options + item;
				}
			})
		}
		// this.custom_fields = this.props.custom_fields;
		let current_feed_id = null;
		if('feed_id' in data) {
			current_feed_id = data.feed_id
		}
		this.setState({custom_fields: this.props.custom_fields, data, dropdown_options, current_feed_id});
	}

	addNewFeed = (feed_id) => {
		console.log('feed_id', feed_id)
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		let custom_field = custom_fields.custom_terminology_fields.find((item) => {
			return item.name === "reviewee_designation"
		})

		custom_field.feed_id = feed_id
		this.setState({custom_fields, current_feed_id: feed_id});
		this.props.updateCustomFields(custom_fields);
	}

	selectFeed = (feed_id) => {
		let data = this.state.data
		data.feed_id = feed_id
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		let custom_field = custom_fields.custom_terminology_fields.find((item) => {
			return item.name === "reviewee_designation"
		})

		custom_field.feed_id = feed_id
		this.setState({feed_id, data, current_feed_id: feed_id})
		this.props.updateCustomFields(custom_fields);
	}


	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			if (JSON.stringify(prevProps.custom_fields.additional_fields) !== JSON.stringify(this.props.custom_fields.additional_fields)) {
				this.setState({custom_fields: this.props.custom_fields});
			}
			console.log("data", this.state.data);
		}
	}

	changeFieldNickname = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.custom_terminology_fields.forEach((item) => {
			if (item.name === "reviewee_designation") {
				item.nickname = event.target.value;
			}
		})
		let data = JSON.parse(JSON.stringify(this.state.data));
		data.nickname = event.target.value;
		this.setState({custom_fields, data});
		this.props.updateCustomFields(custom_fields);
	}

	changeFieldType = (type_name) => {
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.custom_terminology_fields.forEach((item) => {
			if (item.name === "reviewee_designation") {
				item.type = type_name;
			}
		})
		let data = JSON.parse(JSON.stringify(this.state.data));
		data.type = type_name;
		this.setState({custom_fields, data});
		this.props.updateCustomFields(custom_fields);

	}

	toggleRequired = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.custom_terminology_fields.forEach((item) => {
			if (item.name === "reviewee_designation") {
				item.required = item.required ? false : true;
			}
		})
		let data = JSON.parse(JSON.stringify(this.state.data));
		data.required = data.required === true ? false : true;
		this.setState({custom_fields, data});
		this.props.updateCustomFields(custom_fields);
	}

	toggleOther = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.custom_terminology_fields.forEach((item) => {
			if (item.name === "reviewee_designation") {
				item.other = item.other ? false : true;
			}
		})
		let data = JSON.parse(JSON.stringify(this.state.data));
		data.other = data.other === true ? false : true;
		this.setState({custom_fields, data});
		this.props.updateCustomFields(custom_fields);
	}

	modifyDropdownOptions = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.custom_terminology_fields.forEach((item) => {
			if (item.name === "reviewee_designation") {
				// const trimedvalue = event.target.value.replace(/^\s+|\s+$/g, '');
				const trimedvalue = event.target.value;
				let optarr = trimedvalue.split("\n");
				if (optarr.length === 1 && optarr[0] === "") {
					optarr = [];
				}
				item.dropdown_options = optarr;
			}
		})
		this.setState({dropdown_options: event.target.value, custom_fields});
		this.props.updateCustomFields(custom_fields);
	}
	
	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}

		return(
			<FieldContainer>
				<FloatingSection>
					{
						(() => {
							if (this.state.editable) {
								return (<TextBox value={this.state.data.nickname} name={this.state.data.name} onChange={this.changeFieldNickname} />)
							} else {
								return (<BoldLabel>{this.state.data.nickname}</BoldLabel>);
							}
						})()
					}
				</FloatingSection>
				<FloatingSection style={{paddingLeft: "7px"}}>
					{
						(() => {
							if (this.state.editable) {
								return (<Icon style={{cursor: "pointer", marginLeft: "20px", paddingTop: "5px"}} size={18} icon={basic_floppydisk} onClick={()=>this.setState({editable: false})} />);
							} else {
								return (<Icon style={{cursor: "pointer"}} icon={software_pencil} onClick={()=>this.setState({editable: true})} />);
							}
						})()
					}
				</FloatingSection>
				<Clearfix></Clearfix>
				<BoldLabel style={{padding: "10px 0px", color: "#595959"}}>Field Type</BoldLabel>
				<div style={{width: "40%"}}>
					<CslSimpleDropDown options={this.state.data.available_field_types} selected={this.state.data.type} changeFieldType={this.changeFieldType} />
				</div>
				{
					(() => {
						if (this.state.data.type === "Dropdown") {
							return (
								<div style={{padding: "10px 0px"}}>
									<SubHeading>Configure the dropdown options. New line separates options.</SubHeading>
									<DropdownOptions rows="7" value={this.state.dropdown_options} onChange={this.modifyDropdownOptions} />
								</div>
							);
						}
						if (this.state.data.type === "Auto-Populated Dropdown") {
							return (
								<div style={{padding: "10px 0px"}}>
									<APDropdownOptions 
										rows="7" 
										feedId={this.state.current_feed_id} 
										selectFeed={this.selectFeed} 
										dropdownOptions={this.state.dropdown_options} 
										onAddNewFeed={this.addNewFeed} 
										updateCustomFields={this.props.updateCustomFields}
									/>
								</div>
							);
						}
					})()
				}
				{
					(() => {
						if (this.state.data.type === "Dropdown") {
							return (
								<div style={{marginTop: "10px"}}>
									<div style={{float: "left", width: "50%", boxSizing: "border-box"}}>
										<FloatingSection>
											{
												(() => {
													if (this.state.data.required) {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleRequired} />)
													} else {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleRequired} />)
													}
												})()
											}
										</FloatingSection>
										<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Required field</BoldLabel></FloatingSection>
										<Clearfix></Clearfix>
									</div>
									<div style={{float: "right", width: "50%", boxSizing: "border-box"}}>
										<FloatingSection>
											{
												(() => {
													if (this.state.data.other) {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleOther} />)
													} else {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleOther} />)
													}
												})()
											}
										</FloatingSection>
										<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Include 'Other'</BoldLabel></FloatingSection>
										<Clearfix></Clearfix>
									</div>
									<div style={{clear: "both"}}></div>
								</div>
							);
						} else {
							return (
								<div style={{marginTop: "10px"}}>
									<FloatingSection>
										{
											(() => {
												if (this.state.data.required) {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleRequired} />)
												} else {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleRequired} />)
												}
											})()
										}
									</FloatingSection>
									<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Required field</BoldLabel></FloatingSection>
									<Clearfix></Clearfix>
								</div>
							);
						}
					})()
				}
			</FieldContainer>
		);
	}
}

export default RevieweeDesignationField;