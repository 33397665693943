import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import BulkPagination from "./BulkPagination";
import './BulkTable.css';

class BulkTable extends React.Component {

	render () {
		let tableData = this.props.processData();
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
        return (
        	<div id="bulk">
	        	<ReactTable
	            	PaginationComponent={BulkPagination}
				    data={tableData.data}
				    columns={tableData.columns}
				    defaultPageSize={parseInt(row_count)}
				/>
			</div>
        )
	}

}

export default BulkTable;