import React from 'react';
import styled from 'styled-components';
import FollowupConfigModal from './FollowupConfigModal';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 70%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const Section50 = styled.div`
	float: left;
	width: 50%
`;
const InactiveDiv = styled.div`
	pointer-events: none;
	opacity: 0.4;
`;
const MRModalSelect = styled.select`
	margin-top: 10px;
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 40%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const ConfigDiv = styled.div`
	color: #0e94c5;
	font-weight: 600;
	cursor: pointer;
	margin-top: 15px;
`;
const DummyOverlay = styled.div`
    display: table;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    width: calc(100vw - 300px);
    background-color: #ffffff;
    position: fixed;
    left: 300px;
    top: 65px;
    z-index: 1000;
    background: transparent;
`;

class General extends React.Component
{
	state = {general: null, show_modal: false};

	componentDidMount()
	{
		this.setState({general: this.props.general});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({general: this.props.general});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		if (key === "quality_assurance_enabled" && general[key] === false) general["own_reviews_qa_view"] = general[key];
		if (key === "own_reviews_qa_view" && general["quality_assurance_enabled"] === false) general["own_reviews_qa_view"] = false;
		this.props.updateGeneral(general);
	}

	// changeSelfNotification = (event) => {
	// 	event.preventDefault();
	// 	let general = JSON.parse(JSON.stringify(this.state.general));
	// 	general.self_notification.selected_option = event.target.value;
	// 	this.props.updateGeneral(general);
	// }

	changeRevieweeAcknowledgement = (event) => {
		event.preventDefault();
		let general = JSON.parse(JSON.stringify(this.state.general));
		general.reviewee_acknowledgement.enabled = general.reviewee_acknowledgement.enabled === true ? false : true;
		this.props.updateGeneral(general);
	}

	showConfigModal = (event) => {
		console.log("showmodal");
		// event.preventDefault();
		this.setState({show_modal: true});
	}

	closeModal = () => {
		this.setState({show_modal: false});
	}

	updateRA = (obj) => {
		console.log("obj:", obj);
		let general = JSON.parse(JSON.stringify(this.state.general));
		general.reviewee_acknowledgement = obj;
		this.props.updateGeneral(general);
		// alert("Followup task configuration has been updated but not saved to the server. Click on the save button at the bottom of the page to save it permanently");
	}

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<GeneralContainer>
				{
					(() => {
						if (this.state.show_modal === true) {
							return (<DummyOverlay><FollowupConfigModal data={this.state.general.reviewee_acknowledgement} updateRA={this.updateRA} closeModal={this.closeModal} /></DummyOverlay>);
						}
					})()
				}
				<GeneralInnerContainer>
					<GeneralLabel>Enable System Templates</GeneralLabel>
					{
						(() => {
							if (this.state.general.sys_templates_enabled) {
								return (
									<RadioBlock>
										<SelectedRadio id="sys_templates_enabled_y">Yes</SelectedRadio>
										<UnselectedRadio id="sys_templates_enabled_n" onClick={this.switchRadio('sys_templates_enabled')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio id="sys_templates_enabled_y" onClick={this.switchRadio('sys_templates_enabled')}>Yes</UnselectedRadio>
										<SelectedRadio id="sys_templates_enabled_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Enable Flagging of Filechecks</GeneralLabel>
					{
						(() => {
							if (this.state.general.flagging_fc_enabled) {
								return (
									<RadioBlock>
										<SelectedRadio  id="flagging_fc_enabled_y">Yes</SelectedRadio>
										<UnselectedRadio id="flagging_fc_enabled_n" onClick={this.switchRadio('flagging_fc_enabled')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio id="flagging_fc_enabled_y" onClick={this.switchRadio('flagging_fc_enabled')}>Yes</UnselectedRadio>
										<SelectedRadio id="flagging_fc_enabled_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Requires Reviewee Designations</GeneralLabel>
					{
						(() => {
							if (this.state.general.reviewee_designation_required) {
								return (
									<RadioBlock>
										<SelectedRadio id="reviewee_designation_required_y">Yes</SelectedRadio>
										<UnselectedRadio id="reviewee_designation_required_n" onClick={this.switchRadio('reviewee_designation_required')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio id="reviewee_designation_required_y" onClick={this.switchRadio('reviewee_designation_required')}>Yes</UnselectedRadio>
										<SelectedRadio  id="reviewee_designation_required_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<div>
						<Section50 style={{width: "40%"}}>
							<GeneralLabel>Enable Quality Assurance</GeneralLabel>
							{
								(() => {
									if (this.state.general.quality_assurance_enabled) {
										return (
											<RadioBlock>
												<SelectedRadio  id="quality_assurance_enabled_y">Yes</SelectedRadio>
												<UnselectedRadio id="quality_assurance_enabled_n" onClick={this.switchRadio('quality_assurance_enabled')}>No</UnselectedRadio>
											</RadioBlock>
										);
									} else {
										return (
											<RadioBlock>
												<UnselectedRadio id="quality_assurance_enabled_y" onClick={this.switchRadio('quality_assurance_enabled')}>Yes</UnselectedRadio>
												<SelectedRadio id="quality_assurance_enabled_n">No</SelectedRadio>
											</RadioBlock>
										);
									}
								})()
							}
						</Section50>
						{
							(() => {
								if (this.state.general.quality_assurance_enabled === true) {
									return (
										<Section50 style={{width: "60%"}}>
											<GeneralLabel>Reviewers can see the QA of their Reviews</GeneralLabel>
											{
												(() => {
													if (this.state.general.own_reviews_qa_view === true) {
														return (
															<RadioBlock>
																<SelectedRadio id="own_reviews_qa_view_y">Yes</SelectedRadio>
																<UnselectedRadio id="own_reviews_qa_view_n" onClick={this.switchRadio('own_reviews_qa_view')}>No</UnselectedRadio>
															</RadioBlock>
														);
													} else {
														return (
															<RadioBlock>
																<UnselectedRadio id="own_reviews_qa_view_y" onClick={this.switchRadio('own_reviews_qa_view')}>Yes</UnselectedRadio>
																<SelectedRadio  id="own_reviews_qa_view_n">No</SelectedRadio>
															</RadioBlock>
														);
													}
												})()
											}
										</Section50>
									);
								} else {
									return (
										<InactiveDiv>
											<Section50 style={{width: "60%"}}>
												<GeneralLabel>Reviewers can see the QA of their Reviews</GeneralLabel>
												{
													(() => {
														if (this.state.general.own_reviews_qa_view === true) {
															return (
																<RadioBlock>
																	<SelectedRadio id="own_reviews_qa_view_y">Yes</SelectedRadio>
																	<UnselectedRadio  id="own_reviews_qa_view_n" onClick={this.switchRadio('own_reviews_qa_view')}>No</UnselectedRadio>
																</RadioBlock>
															);
														} else {
															return (
																<RadioBlock>
																	<UnselectedRadio id="own_reviews_qa_view_y" onClick={this.switchRadio('own_reviews_qa_view')}>Yes</UnselectedRadio>
																	<SelectedRadio  id="own_reviews_qa_view_n">No</SelectedRadio>
																</RadioBlock>
															);
														}
													})()
												}
											</Section50>
										</InactiveDiv>
									);
								}
							})()
						}
						<div style={{clear: "both"}}></div>
					</div>
					<GeneralLabel>Enable Score Progression</GeneralLabel>
					{
						(() => {
							if (this.state.general.score_progression_enabled) {
								return (
									<RadioBlock>
										<SelectedRadio  id="score_progression_enabled_y">Yes</SelectedRadio>
										<UnselectedRadio  id="score_progression_enabled_n" onClick={this.switchRadio('score_progression_enabled')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio   id="score_progression_enabled_y" onClick={this.switchRadio('score_progression_enabled')}>Yes</UnselectedRadio>
										<SelectedRadio  id="score_progression_enabled_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<div>
						<Section50 style={{width: "40%"}}>
							<GeneralLabel>Allow N/A Questions</GeneralLabel>
							{
								(() => {
									if (this.state.general.allow_na_questions_enabled) {
										return (
											<RadioBlock>
												<SelectedRadio  id="allow_na_questions_enabled_y">Yes</SelectedRadio>
												<UnselectedRadio  id="allow_na_questions_enabled_n" onClick={this.switchRadio('allow_na_questions_enabled')}>No</UnselectedRadio>
											</RadioBlock>
										);
									} else {
										return (
											<RadioBlock>
												<UnselectedRadio  id="allow_na_questions_enabled_y" onClick={this.switchRadio('allow_na_questions_enabled')}>Yes</UnselectedRadio>
												<SelectedRadio   id="allow_na_questions_enabled_n">No</SelectedRadio>
											</RadioBlock>
										);
									}
								})()
							}
						</Section50>
						<Section50 style={{width: "40%"}}>
							<GeneralLabel>Allow N/A Section logic</GeneralLabel>
							{
								(() => {
									if (this.state.general.allow_na_section_enabled) {
										return (
											<RadioBlock>
												<SelectedRadio   id="allow_na_section_enabled_y">Yes</SelectedRadio>
												<UnselectedRadio   id="allow_na_section_enabled_n" onClick={this.switchRadio('allow_na_section_enabled')}>No</UnselectedRadio>
											</RadioBlock>
										);
									} else {
										return (
											<RadioBlock>
												<UnselectedRadio   id="allow_na_section_enabled_y" onClick={this.switchRadio('allow_na_section_enabled')}>Yes</UnselectedRadio>
												<SelectedRadio   id="allow_na_section_enabled_n">No</SelectedRadio>
											</RadioBlock>
										);
									}
								})()
							}
						</Section50>
						<div style={{clear: "both"}}></div>
					</div>
					<GeneralLabel>Enable Data Cards</GeneralLabel>
					{
						(() => {
							if (this.state.general.data_cards_enabled) {
								return (
									<RadioBlock>
										<SelectedRadio   id="data_cards_enabled_y">Yes</SelectedRadio>
										<UnselectedRadio   id="data_cards_enabled_n" onClick={this.switchRadio('data_cards_enabled')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio   id="data_cards_enabled_y" onClick={this.switchRadio('data_cards_enabled')}>Yes</UnselectedRadio>
										<SelectedRadio   id="data_cards_enabled_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Enable Approval Process</GeneralLabel>
					{
						(() => {
							if (this.state.general.approval_process_enabled) {
								return (
									<RadioBlock>
										<SelectedRadio   id="approval_process_enabled_y">Yes</SelectedRadio>
										<UnselectedRadio  id="approval_process_enabled_n" onClick={this.switchRadio('approval_process_enabled')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio  id="approval_process_enabled_y" onClick={this.switchRadio('approval_process_enabled')}>Yes</UnselectedRadio>
										<SelectedRadio  id="approval_process_enabled_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Show Parent Company File Checks</GeneralLabel>
					{
						(() => {
							if (this.state.general.show_parent_company) {
								return (
									<RadioBlock>
										<SelectedRadio id="show_parent_company_y">Yes</SelectedRadio>
										<UnselectedRadio id="show_parent_company_n" onClick={this.switchRadio('show_parent_company')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio id="show_parent_company_y" onClick={this.switchRadio('show_parent_company')}>Yes</UnselectedRadio>
										<SelectedRadio id="show_parent_company_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					{/*<GeneralLabel>Send notifications for File Checks that a user assigns to themself</GeneralLabel>
					<MRModalSelect id="self_notification" value={this.state.general.self_notification.selected_option} onChange={this.changeSelfNotification}>
						{
							this.state.general.self_notification.options.map(item => {
								return (<option value={item} key={item}>{item}</option>);
							})
						}
					</MRModalSelect>*/}
					<GeneralLabel>Provide option for the Reviewee to read and acknowledge understanding of the review by follow-up task</GeneralLabel>
					<div style={{width: "30%", float: "left"}}>
					{
						(() => {
							if (this.state.general.reviewee_acknowledgement.enabled === true) {
								return (
									<RadioBlock>
										<SelectedRadio id="changeRevieweeAcknowledgement_y">Yes</SelectedRadio>
										<UnselectedRadio id="changeRevieweeAcknowledgement_n" onClick={this.changeRevieweeAcknowledgement}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio id="changeRevieweeAcknowledgement_y" onClick={this.changeRevieweeAcknowledgement}>Yes</UnselectedRadio>
										<SelectedRadio id="changeRevieweeAcknowledgement_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					</div>
					<div style={{width: "70%", float: "left"}}>
					{
						(() => {
							if (this.state.general.reviewee_acknowledgement.enabled === true) {
								return <ConfigDiv  id="reviewee_acknowledgement" onClick={this.showConfigModal}>Configure follow-up task</ConfigDiv>
							} else {
								return <InactiveDiv><ConfigDiv  id="reviewee_acknowledgement">Configure follow-up task</ConfigDiv></InactiveDiv>
							}
						})()
					}
					</div>
					<div style={{clear: "both"}}></div>
					<GeneralLabel>Allow Bulk Assignment from Spreadsheet</GeneralLabel>
					{
						(() => {
							if (this.state.general.bulk_assign_from_spreadsheet) {
								return (
									<RadioBlock>
										<SelectedRadio  id="score_progression_enabled_y">Yes</SelectedRadio>
										<UnselectedRadio  id="score_progression_enabled_n" onClick={this.switchRadio('bulk_assign_from_spreadsheet')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio   id="score_progression_enabled_y" onClick={this.switchRadio('bulk_assign_from_spreadsheet')}>Yes</UnselectedRadio>
										<SelectedRadio  id="score_progression_enabled_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
					<GeneralLabel>Require Related Individuals for Filechecks</GeneralLabel>
					{
						(() => {
							if (this.state.general.related_individual) {
								return (
									<RadioBlock>
										<SelectedRadio  id="score_progression_enabled_y">Yes</SelectedRadio>
										<UnselectedRadio  id="score_progression_enabled_n" onClick={this.switchRadio('related_individual')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio   id="score_progression_enabled_y" onClick={this.switchRadio('related_individual')}>Yes</UnselectedRadio>
										<SelectedRadio  id="score_progression_enabled_n">No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
				</GeneralInnerContainer>
			</GeneralContainer>
		);
	}
}

export default General;
