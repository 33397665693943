import React from "react";
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import AlertBox from '../Common/AlertBox';

const TitleBar = styled.div`
    background-color: rgb(1, 174, 168);
    width: 100%;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;
const TitleText = styled.div`
    float: left;
    color: #ffffff;
    font-weight: 600;
    margin: 12px 0px 0px 10px;
`;
const CLabel = styled.div`
    font-weight: 600;
    padding-bottom: 5px;
`;
const ModalTextArea = styled.textarea`
    height: 150px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    font-size: 14px;
    font-family: Montserrat,sans-serif;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    box-sizing: border-box;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;

class FollowupConfigModal extends React.Component
{
    state = {data: null, show_confirm: false, alert_param: null};

    componentDidMount() {
        console.log(this.props.data);
        this.setState({data: this.props.data});
    }

    handleChange = (key) => (event) => {
        event.preventDefault();
        let data = JSON.parse(JSON.stringify(this.state.data));
        data[key] = event.target.value;
        this.setState({data});
    }

    alertHandler = (status, stack) => {
        this.setState({show_confirm: false});
        this.props.closeModal();
    }

    close = (event) => {
        console.log("close");
        this.props.closeModal();
    }

    update = (event) => {
        console.log("update");
        let data = JSON.parse(JSON.stringify(this.state.data));
        data.due_in_days = parseInt(data.due_in_days);
        this.props.updateRA(data);
        let alert_param = {
            title: 'Alert',
            message: 'Followup task configuration has been updated but not saved to the server. Click on the save button at the bottom of the page to save it permanently',
            ok_text: 'Ok',
            cancel_text: 'No, thanks',
            confirm: false,
            alertHandler: this.alertHandler,
            stack: {id: 0}
        }
        this.setState({show_confirm: true, alert_param});
    }

    render()
    {
        if (this.state.data === null) return <div>Loading...</div>
        return (
            <div style={{display: "table-cell", verticalAlign: "middle"}}>
                {this.state.show_confirm === true && <AlertBox alertParam = {this.state.alert_param}/>}
                <div style={{width: "50%", marginLeft: "24%"}}>
                    <TitleBar style={{backgroundColor: "#01AEA8", width: "100%", height: "40px"}}>
                        <TitleText style={{float: "left"}}>Configure follow-up task</TitleText>
                        <div style={{float: "right"}}><FaTimes style={{color: "#ffffff", fontSize: "17px", margin: "12px 10px 0px 0px", cursor: "pointer"}} onClick={this.close} /></div>
                        <div style={{clear: "both"}}></div>
                    </TitleBar>
                    <div style={{padding: "20px 10px", backgroundColor: "#ECF0F1"}}>
                        <CLabel>Subject</CLabel>
                        <MRModalInput value={this.state.data.subject} onChange={this.handleChange("subject")} />
                        <CLabel>Note</CLabel>
                        <ModalTextArea value={this.state.data.note} onChange={this.handleChange("note")} />
                        <CLabel>Due in</CLabel>
                        <div style={{float: "left", width: "25%"}}><MRModalInput value={this.state.data.due_in_days.toString()} onChange={this.handleChange("due_in_days")} style={{textAlign: "right"}} /></div>
                        <div style={{float: "left", fontWeight: "600", paddingTop: "10px", paddingLeft: "10px"}}>Days</div>
                        <div style={{clear: "both"}}></div>
                        <div style={{float: "right"}}>
                            <CancelBtn onClick={this.close}>Close</CancelBtn><SaveBtn onClick={this.update}>Update</SaveBtn>
                        </div>
                        <div style={{clear: "both"}}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FollowupConfigModal;