import React from 'react';
import {FaTimes} from 'react-icons/fa';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import './ReportModal.css';
import Store from '../../Common/Store';
import ReportSectionHOC from './ReportSectionHOC';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import APICall from '../../Common/APICall';
import * as jsPDF from 'jspdf';
import {Buffer} from 'buffer';
import moment from "moment";
import FollowupModal from "../Partials/FollowupModal";
import EventBus from '../../Common/EventBus.js';

let btoa = require('btoa');

const marginX = 12;
const marginY = 8;
const maxX = 210 - (2 * marginX);
const maxY = 297 - (2 * marginY);
const footer_height = 15;
const para_gap = 9;
const line_gap = 5;

const ExpoxtPdf = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ADA7;
    color: #FFFEEE;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    width: 100px;
`;
const Sharereportbutton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #122a4a;
    color: #FFFEEE;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #122a4a;
    margin-left: 5px;
    font-size: 11px;
    width: 100px;
`;
const InfoDiv = styled.div`
	float: left;
	width: 25%;
	box-sizing: border-box;
	margin-top: 35px;
`;


const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -5px;
  z-index: 1000;
  opacity: 0.8;
`;
class ReportModal extends React.Component
{
    state = {
        share: false,
        contacts:[],
        rowchecked: false,
        count: 0,
        send_mail_config: false,
        modal_view: null,
        show_spinner: false,
        comp_fc_autofail_result : []
    }
  
    constructor(props) {
		super(props);
        this.myRef = React.createRef();
	}
    componentDidMount()
    {
        document.getElementById("root").scroll(0,0);
        // console.log("report_task:", JSON.parse(Store.getStoreData("report_task").parenttask.task_json));
        this.shareContactobject();
    }
    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.shareContactobject();
        }
    }

    shareContactobject = () => {
        let contacts = Store.getStoreData('reviewee_list');
        // let contacts = Store.getStoreData('reviewee_info');
        let module_config = Store.getStoreData('module_config');
        let send_mail_config = false;
        if(Object.keys(module_config).length > 0) {
            send_mail_config =  module_config.general.email_remainder_enable;
        }
        console.log("contacts", contacts);
        let contact_list = [];
        let gc_id = Store.getStoreData("report_task").parenttask.company_id;
        gc_id = (gc_id > 100000) ? parseInt(gc_id) % 100000 : gc_id;
        const gc_assoc = Store.getStoreData('gc_assoc');
        for(let gc in gc_assoc[gc_id.toString()].users) {
            contact_list.push({ID: gc, ContactName: gc_assoc[gc_id.toString()].users[gc.toString()].ContactName, email: gc_assoc[gc_id.toString()].users[gc.toString()].email, is_share: false})
        }
        let comp_fc_autofail_result = 'comp_fc_autofail_result' in this.props ? this.props.comp_fc_autofail_result : []
        this.setState({contacts: contact_list, send_mail_config: send_mail_config, comp_fc_autofail_result });
        this.executeScroll();
    }

    executeScroll = () => this.myRef.current.scrollIntoView()

    closeModal = (event) => {
        event.preventDefault();
        console.log("is_register", Store.getStoreData('is_register'));
        console.log("Referrer:", document.referrer);
        if (document.referrer.includes("/register") || document.referrer.includes("/tmview") || document.referrer === "http://localhost:3000/" ) {
            window.location.assign(document.referrer);
        } else {
            this.props.closeModal();
        }
    }

    processDate = (date) => {
        let date_str = date.split('T')[0]
        let date_parts = date_str.split('-')
        return `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`
    }
    exportToPdf = (event) =>{
        event.preventDefault();
        Store.updateStore('is_register', true);  
        this.props.openPrintView();
    }

    getMailStrucData = (data) => {
        let contacts = this.state.contacts;
        let contact_list = {}
        for(let c of contacts) {
            contact_list[c.ID.toString()] = c.ContactName
        }
        let report_task = Store.getStoreData('report_task');
        let report_task_json = Store.getStoreData('report_task_json');
        let mail_body = data.body;
        mail_body = mail_body.replace("{{reviewer}}", contact_list[report_task.parenttask.completed_by]);
        mail_body = mail_body.replace("{{reviewee}}", contact_list[report_task_json.object_data.headerinfo.reviewee_id]);
        data.body = mail_body;

        let mail_subject = data.subject;
        mail_subject = mail_subject.replace("{{reviewer}}", contact_list[report_task.parenttask.completed_by]);
        mail_subject = mail_subject.replace("{{reviewee}}", contact_list[report_task_json.object_data.headerinfo.reviewee_id]);
        data.subject = mail_subject;
        return data;
    }

    shareReportUsers = (event) => {
        event.preventDefault();
        let data = [];
        let contacts = this.state.contacts;
        for(let c of this.state.contacts) {
            if(c.is_share) {
                data.push({"id": c.ID, "name": c.ContactName, "email": c.email})
            }
        }

        let module_config = Store.getStoreData('module_config');
        let send_mail_config_data = {};
        let mail_config = {};
        let send_mail = this.state.send_mail_config;
        if(this.state.send_mail_config) {
            if(Object.keys(module_config).length > 0) {
                mail_config = module_config.email.find((obj) => {
                    return obj.id === "SHrp"
                });
            }
            send_mail = mail_config.enabled;
            if(send_mail){
                send_mail_config_data = this.getMailStrucData(mail_config);
            }
            console.log(mail_config);
        }

        let task_id = Store.getStoreData("report_task").parenttask.id 

        let pdf_data = this.generatePDF(); 
        // console.log("pdf_data=>",pdf_data);
        let b64 = Buffer.from(pdf_data).toString('base64')
        let report_task_json = Store.getStoreData('report_task_json');
        let reviewer_id = report_task_json.object_data.headerinfo.reviewer_id;


        //let postData = { command: "share_report", pdf_data: btoa(pdf_data), share_users: data, mail_config: send_mail_config_data, send_mail: send_mail};
        let postData = { command: "share_report", task_id:task_id , reviewer_id:reviewer_id, pdf_data: b64, share_users: data, mail_config: send_mail_config_data, send_mail: send_mail};
        
        console.log("postData", postData);
        

        let api = new APICall();
        api.command(postData, this.ShareReportUser);

    }

    ShareReportUser = (result) => {
        console.log("result", result);
        alert("The report has been shared successfully");
        this.setState({share: false})
    }


// pdf section

companyHeaderSection (){
    let report_task = Store.getStoreData('report_task');
    let gc_assoc = Store.getStoreData('gc_assoc')
    let report_task_json = Store.getStoreData('report_task_json');
    let gc_company = parseInt(report_task.parenttask.company_id) < 100000 && 'my_company' in Store ? Store.getStoreData('my_company').CompanyName : gc_assoc[(parseInt(report_task.parenttask.company_id)%100000).toString()].company_name;
   
    this.fckPDF.setFillColor('#FFFFFF');
    this.fckPDF.rect(0, 0, 210, 12, 'F');
    this.fckPDF.setFontSize(10);
    this.fckPDF.setTextColor('#4C4C4C');
    this.fckPDF.setFontStyle('bold');
    this.fckPDF.text("Filecheck Report for", 63 , this.current_top);
    this.fckPDF.setTextColor('#4C4C4C');
    this.fckPDF.text(gc_company.replace(/'+/g,"'"), 99, this.current_top);

    this.fckPDF.setTextColor('#4C4C4C');
    this.fckPDF.setFontSize(19);
    this.fckPDF.setFontStyle('bold');
    this.fckPDF.text(report_task_json.object_data.headerinfo.file_reference.replace(/'+/g,"'"), 63, this.current_top + 15);
}
getAutofailQuestions = (task) => {
    let score_result = "N/A";
    let result_obj = {};
    let is_autofailed = false;
    let totalscore = 0;
    let obtained_score = 0;
    let question_info = [];

    console.log("task.cur_lane", task.cur_lane)
    if (task.cur_lane === "COMP_FCK_COMPLETE" || task.cur_lane === "COMP_FCK_QC_COMPLETE") {
      const task_json = JSON.parse(task.task_json);
      const scoring = ('scoring' in task_json.object_data.headerinfo.review_json) ? task_json.object_data.headerinfo.review_json.scoring : {};
      const sections = task_json.object_data.headerinfo.review_json.sections;
      let q = 0;
      if ('scoring_enabled' in scoring && scoring.scoring_enabled === true) {
        sections.forEach((section) => {
          section.questions.forEach((question) => {
            q++;
            if (question.included === true) {            
                const autofail_enabled = question.autofail.find(af => af.selected === true);
                obtained_score = obtained_score + parseInt(question.obtained_score);
                if (autofail_enabled.value === "Yes") {
                    if (parseInt(question.obtained_score) === 0) {
                        is_autofailed = true;
                        question_info.push(q);
                    }
                }
            }
          })
        })
      } 
    }
    return question_info;
}
leftSummaryPanel(){
    this.local_top = 239;
    const line_gap = 7;
    let report_task_json = Store.getStoreData('report_task_json');
    var res = this.questionAnswerdCalculation(report_task_json);       
    this.fckPDF.setFillColor('#334761');
    this.fckPDF.rect(0, 0, 55, 297, 'F');
    this.fckPDF.setFontSize(11);
    this.fckPDF.setTextColor('#FFFFFF');
    this.fckPDF.setFontStyle('bold');
    this.fckPDF.text(2, this.local_top, 'Results');
    this.local_top += line_gap;
    let complaint = report_task_json.object_data.result.ragratingname;

    if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled){
        if(complaint === "Compliant"){
            this.fckPDF.setTextColor('#0CABA6');
            this.fckPDF.text(report_task_json.object_data.result.ragratingname, 2, this.local_top);
        }
        else{
            this.fckPDF.setTextColor('#D93025');
            this.fckPDF.text(report_task_json.object_data.result.ragratingname, 2, this.local_top);
        }
        this.local_top += line_gap;
    }
    this.fckPDF.setTextColor('#FFFFFF');
    this.fckPDF.setFontSize(10);
    this.fckPDF.setFontStyle('normal');
    this.fckPDF.text(2, this.local_top, 'Questions Answered');
    this.fckPDF.text(res.qa, 38,this.local_top);
    this.local_top += line_gap;
    this.fckPDF.text(2, this.local_top, 'N/A Questions');
    this.fckPDF.text(res.na_count, 38,this.local_top);
    this.local_top += line_gap;
    if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled){
            this.fckPDF.text(2, this.local_top, 'Score');
            this.fckPDF.setFontStyle('normal');
            this.fckPDF.text(Math.round(report_task_json.object_data.result.scoresobtained).toString(), 38, this.local_top);
            var scroe_width = this.fckPDF.getTextWidth(Math.round(report_task_json.object_data.result.scoresobtained).toString());
            this.fckPDF.text("%", 38 + scroe_width, this.local_top);  
    } 
}
generalFileCheckSummary() {
        this.current_top = 52;
        let report_task = Store.getStoreData('report_task');
        let autofail_list = [];
        autofail_list = this.getAutofailQuestions(report_task.parenttask);
        //autofail_list = [2,5,7,8,10,12,14,18,29,39,40,61,93,101,308,1056]
        let msg_text = '';
        if(autofail_list.length > 0){
            this.current_top +=  6;
            let q_series = '';
            for(let i=0; i<autofail_list.length; i++){
                if(i < (autofail_list.length-1)){ 
                    q_series +=  autofail_list[i] + ', '
                }else{
                    q_series = q_series.replace(/,\s*$/, "");
                    q_series +=  ' and ' +autofail_list[i]
                }
            } 
            if(autofail_list.length == 1){
                msg_text = 'Question  '+autofail_list[0]+' has triggered this review to automatically fail.';           
            }else{
                msg_text = 'Questions   '+q_series+' have triggered this review to automatically fail.'
            }
        }       
        let report_task_json = Store.getStoreData('report_task_json');         
        let summary = '';
        summary = "summary" in report_task_json.object_data.headerinfo.review_json ? report_task_json.object_data.headerinfo.review_json.summary.replace(/'+/g,"'").replace(/(?:\r\n|\r|\n)/g, ' <br> ') : "";      
        var summary_height = this.getboxheight(summary);
        //msg_text = 'Questions  '+autofail_list[0]+' and '+autofail_list[1]+' have triggered this review to automatically fail.'
        var autofail_height = this.getboxheight(msg_text, undefined, 6);
        console.log("autofail_height", autofail_height)
        let due_date = this.processDate(report_task.parenttask.due_date);
        
        let curr_task_json = Store.getStoreData('report_task_json')
        let sale_obj = JSON.parse(JSON.stringify(curr_task_json.object_data.headerinfo.custom_terminology_fields)).find(item => item.name === "sale_date");
        let sd_is_active = sale_obj && "is_active" in sale_obj ? sale_obj.is_active : true
        let sale_date_arr = report_task_json.task_data.sale_date.split("/");
        let sale_date = sd_is_active ? `${sale_date_arr[2]}/${sale_date_arr[1]}/${sale_date_arr[0]}` : "N/A";

        let status = report_task.parenttask.completed_by > 0 ? 'Complete' : 'Pending';
        let assigned_date = this.processDate(report_task.parenttask.created_at);
        let completed_date = report_task.parenttask.completed_date !== null ? this.processDate(report_task.parenttask.completed_date) : '-'    
        //let file_edited_date = report_task.parenttask.confirmed_time !== null ? this.processDate(report_task.parenttask.confirmed_time) : '-'    
        let reviewee_list = Store.getStoreData('reviewee_list');
        let all_users = Store.getStoreData("users");
        let contact_list = {}
        let right_first_col = 65;
        let right_second_col = 85;
        let right_third_col = 120;
        let right_forth_col = 140;
        let right_fifth_col = 170;
        let right_sixth_col = 185;
        this.fckPDF.setFillColor('#FFFFFF');
        this.fckPDF.roundedRect(63, 35, 139, this.current_top + summary_height + autofail_height, 1, 1, 'F');

        if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled){
                this.fckPDF.setFillColor(report_task_json.object_data.result.ragratingcolor);
        }else{
                this.fckPDF.setFillColor("color" in report_task_json.object_data.non_score_assessment ? report_task_json.object_data.non_score_assessment.color : "#b3b2b1");
        }       
        this.fckPDF.roundedRect(63, 35, 139, 11, 1, 1, 'F');        
        for (let key in all_users) {
            contact_list[key.toString()] = all_users[key.toString()].ContactName;
        }    
        if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled){
            let complaint = report_task_json.object_data.result.ragratingname;
            if(complaint === "Compliant"){
                var result_status = report_task_json.object_data.result.ragratingname + ' (' + report_task_json.object_data.result.outcome + ')';
                this.fckPDF.text(result_status, 68, 42); 
            }
            else{
                var result_status = report_task_json.object_data.result.ragratingname + ' (' + report_task_json.object_data.result.outcome + ')';
                this.fckPDF.text(result_status, 68, 42);
            }
            this.fckPDF.setTextColor('#FFFFFF');
            this.fckPDF.text('Score', 176, 42);
            this.fckPDF.text(Math.round(report_task_json.object_data.result.scoresobtained).toString(), 189, 42);
            var scroe_width = this.fckPDF.getTextWidth(Math.round(report_task_json.object_data.result.scoresobtained).toString());
            this.fckPDF.text("%", 189 + scroe_width, 42);
        }else{
            this.fckPDF.setTextColor('#FFFFFF');
            var non_score_res = "non_score_assessment" in report_task_json.object_data ? report_task_json.object_data.non_score_assessment.result.replace(/'+/g,"'") : ""
            var non_score_res_width = this.fckPDF.getTextWidth(non_score_res)
            this.fckPDF.text(non_score_res.toString(), (198 - non_score_res_width), 42);            
        }            
        this.fckPDF.setFontSize(10);
        this.fckPDF.setTextColor('#4C4C4C');
        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Reviewer :', right_first_col,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.fckPDF.text(contact_list[report_task.parenttask.completed_by], right_second_col,this.current_top);

        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Reviewee :', 130,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.fckPDF.text(contact_list[report_task_json.object_data.headerinfo.reviewee_id], 150,this.current_top);
        //draw 2nd row
        this.current_top +=  9;
        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Status :', right_first_col,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.fckPDF.text(status, right_second_col-3,this.current_top);

        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Policy Start Date :', 130,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.fckPDF.text(sale_date, 164 ,this.current_top);

        // draw 3rd row
        this.current_top +=  9;
        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Customer :', right_first_col,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.current_top = this.processLine(report_task_json.object_data.headerinfo.customer_name.replace(/'+/g,"'"),this.current_top,202,right_second_col);
               
        // draw 4th row
        this.current_top +=  9; 
        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Assigned Date :', right_first_col,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.fckPDF.text(assigned_date, right_second_col + 9,this.current_top);

        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Due Date :', right_third_col - 4,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.fckPDF.text(due_date, right_forth_col - 4 ,this.current_top);

        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Review Date :', right_fifth_col - 13,this.current_top);
        this.fckPDF.setFontStyle('normal');
        this.fckPDF.text(completed_date, right_sixth_col - 3, this.current_top);

        // draw 5th row
        this.current_top +=  9; 
        this.fckPDF.setFontStyle('bold');
        this.fckPDF.text('Comments :', right_first_col,this.current_top);
        this.current_top +=  6;
        this.fckPDF.setFontStyle('normal');
        this.current_top = this.processLine(summary, this.current_top);
        this.current_top +=  6;
        let style = {weight: 'bold', color:'#FF0000'}
        this.current_top = this.processLine(msg_text, this.current_top,undefined,undefined,style);

}

associatedTask(){
    let report_task = Store.getStoreData('report_task');

    const childtasks = report_task.childtask;
    let reviewee_list = Store.getStoreData('reviewee_list')
    let all_users = Store.getStoreData("users");
    let contact_list = {};
    for (let key in all_users) {
        contact_list[key.toString()] = all_users[key.toString()].ContactName;
    }
    this.current_top += 25;
    let right_first_col = 65;
    let right_second_col = 120;
    let right_third_col = 160;
    let right_forth_col = 180;
    var rect_hight = 20;
    childtasks.forEach((ctask, index) => {
        const ctask_json = JSON.parse(ctask.task_json);
        var task = ctask_json.object_data.subject === undefined ? '': ctask_json.object_data.subject;           
        rect_hight +=  this.getboxheight(task, right_second_col) * 0.8
    })       
    this.fckPDF.setFillColor('#FFFFFF');
    this.fckPDF.roundedRect(63, this.current_top, 139, rect_hight, 1, 1, 'F');
    this.fckPDF.setFillColor('#37ADA7');
    this.fckPDF.roundedRect(63, this.current_top, 139, 11, 1, 1, 'F');
    this.fckPDF.setTextColor('#FFFFFF');
    var local_top = this.current_top; 
    local_top += 6;
    this.fckPDF.setFontStyle('bold');
    this.fckPDF.text('Task Associated with this Filecheck', right_first_col, local_top);
    local_top += 10;
    this.fckPDF.setTextColor('#767676');
    this.fckPDF.text('Task', right_first_col, local_top);
    this.fckPDF.text('Assigned To', right_second_col, local_top);
    this.fckPDF.text('Status', right_third_col, local_top);
    this.fckPDF.text('Due Date', right_forth_col, local_top);
    local_top += 5;   

    if (childtasks.length !== 0){
        let ctasks_html = [];
        childtasks.forEach((ctask, index) => {
        let assigned_to_id = null;
        let status = null;
        const cur_lanearr = ctask.cur_lane.split("_");
        if (cur_lanearr[cur_lanearr.length - 1] === "ASSIGNMENT") {
           assigned_to_id = ctask.cur_assigned_to;
           status = "Open";
        } 
        else 
        {
           assigned_to_id = ctask.completed_by;
           status = "Completed";
        }
        const assigned_to = contact_list[assigned_to_id] === undefined ? '': contact_list[assigned_to_id];
        const due_date = this.processDate(ctask.due_date);
        const ctask_json = JSON.parse(ctask.task_json);
        const subject = ctask_json.object_data.subject === undefined ? '': ctask_json.object_data.subject;
       
        this.fckPDF.setTextColor('#767676');
        this.fckPDF.setFontStyle('normal');
        //this.fckPDF.text(subject, right_first_col, this.current_top);
        //this.fckPDF.text(assigned_to, right_second_col, local_top);
        this.fckPDF.text(status, right_third_col, local_top);
        this.fckPDF.text(due_date, right_forth_col, local_top);
        console.log("local_top1",local_top)
        var local_top_1 = this.processLine(subject,local_top, right_second_col);
        var local_top_2 = this.processLine(assigned_to,local_top, right_third_col,right_second_col);
        var local_increament = local_top_1 > local_top_2 ? local_top_1 : local_top_2
        local_top = local_increament;
        local_top += 6;
        //this.processLine(assigned_to,local_top, right_third_col)
        
        //local_top = local_line_height;
        console.log("local_top2",local_top)
        })     
        this.current_top += (rect_hight-5);   
    }else{
        this.current_top += 9; 
    } 
}
countTotalScore (question_arr){
    var totalscore_sec = 0;
    for(let i= 0 ; i< question_arr.length; i ++){            
         totalscore_sec += parseInt(question_arr[i].obtained_score);
    }
    return totalscore_sec;
}
questionAnswerdCalculation(report_task_json){
    let sections = report_task_json.object_data.headerinfo.review_json.sections;
    var totalcount = 0;
    var answeredcount = 0;
    var na_count = 0;
    var result = {qa : '', na_count: 0}
    if(sections.length > 0){
        for(let i = 0 ; i < sections.length ; i ++){
            totalcount += sections[i].questions.length;
            for(var j = 0; j< sections[i].questions.length; j++){
                if(sections[i].questions[j].answer.text != ''){
                    answeredcount += 1;
                }
                if(sections[i].questions[j].naallowed == true){
                    na_count += 1;
                }
            }
        }
    }
    result.qa = answeredcount +'/'+totalcount;
    result.na_count = na_count.toString();
    return result;
}
questionSection(){
    this.current_top += 16;
    let report_task_json = Store.getStoreData('report_task_json');
    console.log("report questiom", report_task_json);
    let sections = report_task_json.object_data.headerinfo.review_json.sections;
    var count = 0;
    let comtext = "";
    if (sections.length > 0){
        for(var i = 0 ; i < sections.length ; i ++){

            this.fckPDF.setFillColor('#0B223E');
            this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, 11, 1, 1, 'F');
            this.fckPDF.setTextColor('#FFFFFF');
            this.fckPDF.setFontSize(10);
            this.fckPDF.setFontStyle('bold');
            this.current_top += line_gap;
            this.fckPDF.text(sections[i].name, 68, this.current_top);
            this.fckPDF.setFillColor('#FFFFFF');
            if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled){
                var totalscore_sec = this.countTotalScore(sections[i].questions)
                var score_width = this.fckPDF.getTextWidth(totalscore_sec.toString());
                this.fckPDF.roundedRect(191, (this.current_top - 3), (score_width + 2), 6, 1, 1, 'F');
                this.fckPDF.setTextColor('#4C4C4C')
                this.fckPDF.text(totalscore_sec.toString(), 192, this.current_top +1);
            }            
            this.current_top += line_gap;
            var localtop = 0;
            for(var j = 0; j< sections[i].questions.length; j++){
                count += 1;
                var question_count = 'Q' + count;
                var questionText = sections[i].questions[j].question.replace(/'+/g,"'");
                let is_autofail = false;
                let autofail_enabled = sections[i].questions[j].autofail.find(af => af.selected === true);                  
                let na_enabled = sections[i].questions[j].included;
                let scoring_enabled = report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled;
                let obtained_sc = "obtained_score" in  sections[i].questions[j] ? sections[i].questions[j].obtained_score : -1;
                var answerText = sections[i].questions[j].included === true ? sections[i].questions[j].answer.text.replace(/'+/g,"'") : "N/A";
                localtop = this.getboxheight(questionText);
                localtop += this.getboxheight(answerText);
                if(sections[i].questions[j].comments.enabled){
                    comtext = "text" in sections[i].questions[j].comments ? sections[i].questions[j].comments.text.replace(/'+/g,"'") : "";
                    localtop += this.getboxheight(comtext.replace(/'+/g,"'"));
                }  
                if (this.current_top + localtop >= 280) {
                    this.fckPDF.addPage();
                    this.fckPDF.setFillColor('#F2F2F2');
                    this.fckPDF.rect(55, 0, 210, 297, 'F');
                    this.leftSummaryPanel();
                    // this.footersection();
                    this.current_top = marginY;
                    this.fckPDF.setFillColor('#FFFFFF'); 
                    if(na_enabled == true && scoring_enabled == true && autofail_enabled.value == 'Yes' && obtained_sc == '0')  {
                        this.fckPDF.setDrawColor(255, 0, 0);
                        this.fckPDF.roundedRect(this.page_right_start, this.current_top , 139, localtop, 1, 1, 'FD');
                    }
                    else{
                        this.fckPDF.roundedRect(this.page_right_start, this.current_top , 139, localtop, 1, 1, 'F');
                    }                
                   
                    this.current_top += para_gap;
                    this.fckPDF.setTextColor('#4C4C4C');
                    this.fckPDF.setFontStyle('bold');
                    this.fckPDF.text(question_count, this.page_right_start + 2, this.current_top);

                    if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled){
                        let obtained_score = "obtained_score" in  sections[i].questions[j] ? sections[i].questions[j].obtained_score : "";
                        let obtained_score_width = this.fckPDF.getTextWidth(obtained_score.toString());
                        this.fckPDF.setFillColor('#7A8593'); 
                        this.fckPDF.roundedRect(191, this.current_top-4 , (obtained_score_width +2), 6, 1, 1, 'F');
                        this.fckPDF.setTextColor('#FFFFFF');
                        this.fckPDF.text(obtained_score.toString(), 192, this.current_top);
                    }else{
                        let non_score_res = "";
                        if(sections[i].questions[j].included){
                            non_score_res = "non_scoring_info" in  sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.label : "";
                        }else{
                            non_score_res = "n/a";
                        }
                        let non_score_res_width = this.fckPDF.getTextWidth(non_score_res.toString());
                        var back_gr_col = "non_scoring_info" in  sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.color : "#7A8593";
                        back_gr_col = non_score_res === "n/a" ? "#7A8593" : back_gr_col;
                        this.fckPDF.setFillColor(back_gr_col); 
                        this.fckPDF.roundedRect(197 - non_score_res_width, this.current_top-4 , (non_score_res_width +2), 6, 1, 1, 'F');
                        this.fckPDF.setTextColor('#FFFFFF');                            
                        this.fckPDF.text(non_score_res.toString(), 198 - non_score_res_width, this.current_top);
                    }
                        
                    this.current_top += para_gap;
                    this.current_top = this.processLine(questionText, this.current_top);
                    this.current_top += para_gap;
                    this.fckPDF.setFontStyle('bold');
                    this.fckPDF.text('Answer', this.page_right_start + 2, this.current_top);
                    this.current_top += line_gap;
                    this.current_top = this.processLine(answerText, this.current_top);
                    if(sections[i].questions[j].comments.enabled){
                        this.current_top += para_gap;
                        this.fckPDF.setFontStyle('bold');
                        this.fckPDF.text('Comments', this.page_right_start + 2, this.current_top);
                        this.current_top += para_gap;
                        this.fckPDF.setFontStyle('normal');
                        comtext = "text" in sections[i].questions[j].comments ? sections[i].questions[j].comments.text.replace(/'+/g,"'") : "";
                        this.current_top = this.processLine(comtext, this.current_top);
                    }
                    this.current_top += 15;
                }else{
                    this.fckPDF.setFillColor('#FFFFFF');
                    if(na_enabled == true && scoring_enabled == true && autofail_enabled.value == 'Yes' && obtained_sc == '0')  {
                        this.fckPDF.setDrawColor(255, 0, 0);
                        this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, localtop, 1, 1, 'FD');
                    }
                    else{
                        this.fckPDF.roundedRect(this.page_right_start, this.current_top, 139, localtop, 1, 1, 'F');
                    } 
                    
                    this.current_top += para_gap;
                    this.fckPDF.setFontStyle('bold');
                    this.fckPDF.text(question_count, this.page_right_start +2 ,this.current_top );
                    this.fckPDF.setFillColor('#7A8593'); 

                    if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled){
                        let obtained_score = "obtained_score" in  sections[i].questions[j] ? sections[i].questions[j].obtained_score : "";
                        let obtained_score_width = this.fckPDF.getTextWidth(obtained_score.toString());
                        this.fckPDF.roundedRect(191, this.current_top - 4, (obtained_score_width + 2), 6, 1, 1, 'F');
                        this.fckPDF.setTextColor('#FFFFFF')                        
                        this.fckPDF.text(obtained_score.toString(), 192, this.current_top);
                    }else{
                        let non_score_res = "";
                        if(sections[i].questions[j].included){
                            non_score_res = "non_scoring_info" in  sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.label : "";
                        }else{
                            non_score_res = "n/a"
                            answerText = "N/A";
                        }
                        
                        let non_score_res_width = this.fckPDF.getTextWidth(non_score_res.toString());
                        back_gr_col = "non_scoring_info" in  sections[i].questions[j] ? sections[i].questions[j].non_scoring_info.color : "#7A8593";
                        back_gr_col = non_score_res === "n/a" ? "#7A8593" : back_gr_col;
                        this.fckPDF.setFillColor(back_gr_col); 
                        this.fckPDF.roundedRect(197 - non_score_res_width, this.current_top-4 , (non_score_res_width +2), 6, 1, 1, 'F');
                        this.fckPDF.setTextColor('#FFFFFF');
                        this.fckPDF.text(non_score_res.toString(), 198 - non_score_res_width, this.current_top);
                    }
                    
                    this.current_top += para_gap;
                    this.current_top = this.processLine(questionText, this.current_top);
                    this.current_top += para_gap;
                    this.fckPDF.setFontStyle('bold');
                    this.fckPDF.text('Answer', this.page_right_start + 2, this.current_top);
                    this.current_top += line_gap;
                    this.current_top = this.processLine(answerText, this.current_top);
                    this.fckPDF.setFontStyle('bold');
                    if(sections[i].questions[j].comments.enabled){
                            this.current_top += para_gap;
                            this.fckPDF.text('Comments', this.page_right_start + 2, this.current_top);
                            this.current_top += 7;
                            this.fckPDF.setFontStyle('normal');
                            comtext = "text" in sections[i].questions[j].comments ? sections[i].questions[j].comments.text : "";
                            this.current_top = this.processLine(comtext, this.current_top);
                        }
                    this.current_top += 15;
                }
            }
        }
     }
}

getboxheight(content, ud_line_right, pre_height){
   var line_right = 202;
   if(ud_line_right != undefined){
        line_right = ud_line_right;
   }
   var cur_right = 65;
   const line_height = 4;
   var sentence = '';
   var localtop = 18;
   if(pre_height != undefined){
    localtop = pre_height
   }
   if(content != ''){
       var word_split = content.replace(/\n/g,"") .split(" ");
       for(let i = 0 ; i< word_split.length ; i++){
          var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
          var gapLenght = this.fckPDF.getTextWidth(" ");
          if((cur_right + wordLenght + gapLenght< line_right) &&  word_split[i] != '<br>')
          {
              cur_right += (wordLenght + gapLenght) 
          }
          else
          {
              localtop += line_height;
              cur_right = 65;
              cur_right += (wordLenght + gapLenght);
          }
       }
   }
  
  return localtop;
}


processLine (content, currenttop, ud_line_right, ud_Line_start,style) {
    var line_right = 202;
    if(ud_line_right != undefined){
        line_right = ud_line_right;
    }
    var cur_right = 65;
    if(ud_Line_start != undefined){
        cur_right = ud_Line_start
    }
    const line_height = 4;
    if(content != ''){
        var word_split = content.replace(/\n/g,"") .split(" ");
        for(let i = 0 ; i< word_split.length ; i++){
            var wordLenght = this.fckPDF.getTextWidth(word_split[i]);
            var gapLenght = this.fckPDF.getTextWidth(" ");
            if((cur_right + wordLenght + gapLenght< line_right) && word_split[i] != '<br>')
            {
                this.fckPDF.setTextColor('#4C4C4C');
                this.fckPDF.setFontStyle('normal');
                if(style != undefined){
                    this.fckPDF.setFontStyle(style.weight);
                    this.fckPDF.setTextColor(style.color);
                }
                this.fckPDF.text(word_split[i], cur_right, currenttop);
                cur_right += (wordLenght + gapLenght) 
            }
            else
            {
                currenttop += line_height;
                cur_right = 65;
                if(ud_Line_start != undefined){
                    cur_right = ud_Line_start
                }
                this.fckPDF.setTextColor('#4C4C4C');
                this.fckPDF.setFontStyle('normal');
                if(style != undefined){
                    this.fckPDF.setFontStyle(style.weight);
                    this.fckPDF.setTextColor(style.color);
                }
                if(word_split[i] != '<br>'){
                    this.fckPDF.text(word_split[i], cur_right, currenttop);
                cur_right += (wordLenght + gapLenght);
                }                   
            }
         }
    }
    return currenttop;
}

processPageNumber = function () {
    var totalPages = this.fckPDF.internal.getNumberOfPages();
    for (var i = 1; i <= totalPages; i++) {
        var pageNumber = i.toString();
        this.fckPDF.setPage(i);
        this.fckPDF.text(pageNumber, this.fckPDF.internal.pageSize.getWidth() - 10, this.fckPDF.internal.pageSize.getHeight() - 15);
    }
}
footersection(){
    this.fckPDF.setFillColor('#0CABA6');
    this.fckPDF.rect(0, 283, 210, 297, 'F');
    this.fckPDF.setTextColor('#FFFFFF');
    this.fckPDF.setFontStyle('normal');
    this.fckPDF.text('Copyright © 2020 Compliancy Services Ltd.', 74, 291);
}

bodySection(){
    this.fckPDF.setFillColor('#F2F2F2');
    this.fckPDF.rect(55, 12, 210, 297, 'F');
}  

addPage(){
    // this.footersection();
    this.fckPDF.addPage('a4',Store.getStoreData('orientation'));
}

generatePDF() {
    this.current_top = marginY;
    this.page_right_start = 63;
    this.currentPageNumber = 0;
    this.fckPDF = new jsPDF({
                                orientation: Store.getStoreData('orientation'),
                                unit: 'mm',
                                format: 'a4',
                                putOnlyUsedFonts: true
                            });
    this.bodySection();
    // this.footersection();
    this.companyHeaderSection();
    this.leftSummaryPanel();
    // this.footersection();
    this.generalFileCheckSummary();
    this.associatedTask();
    this.questionSection();
    this.processPageNumber();
    var blob = this.fckPDF.output()
    return blob;
}

//end pdf section


shareReport = (event) => {
    event.preventDefault();
    this.setState({share: true})

}
CloseShare = (event) => {
    event.preventDefault();
    this.setState({share: false})
}
selectAll = (event) => {
    let rowchecked = this.state.rowchecked;
    rowchecked = rowchecked ? false : true;
    let contacts = this.state.contacts;
    let contact_list = [];
    let count = 0;
    if(rowchecked) {
        for(let contact of contacts){
            contact_list.push({"ID" : contact.ID, "ContactName": contact.ContactName, "email": contact.email, "is_share": true })
            count = contacts.length;
        }
    }else{
        for(let contact of contacts){
            contact_list.push({"ID" : contact.ID, "ContactName": contact.ContactName, "email": contact.email, "is_share": false })
        }
    }
    this.setState({rowchecked: rowchecked, contacts: contact_list, count: count})
}
processUserData = () => {
    var sharedata = this.state.contacts; 
    console.log("sharedata",sharedata)       
    let ret = {data: [], columns: []};
    let i = 0;
    ret.columns =[{'Header' : <div><input type="checkbox" checked={this.state.rowchecked} onClick={this.selectAll} /></div>, Cell: row => (
        <div>
            <input type="checkbox" checked={row.original.is_share} value={row.index} onClick={this.selectUser}/>
        </div>
     ), width: 142, headerStyle: {textAlign: 'left'}},         
    {Header: 'Name', accessor: 'name_value', minWidth: 100, headerStyle: {textAlign: 'left'}},
    {Header: 'Email', accessor: 'email_value', minWidth: 100, headerStyle: {textAlign: 'left'}}];

    for(let d of sharedata) {           
        i++;                      
        let elem = {'index' : i,
                'id': d.ID, 
                'name_value' : d.ContactName,
                'email_value' : d.email,
                'is_share' : d.is_share                    
               };
        ret.data.push(elem);
    }
    return ret;
}

selectUser = (event) => {        
        let index = event.target.value;
        let userdata = this.state.contacts;
        userdata[index].is_share =  userdata[index].is_share ? false : true;
        console.log("userdata ====>", userdata);
        let i = [];
        console.log("count", i)
        for(let u in userdata) {
            if(u.is_share) {
                i.push(u)
            }
            
        }
        console.log("count", i)
        
        this.setState({contacts: userdata, count: userdata.length});
}

    // calculateFinalResult = (result_obj, review_json) => {
    //     let is_autofailed = false;
    //     let totalscore = 0;
    //     let obtained_score = 0;
    //     review_json.sections.forEach((section) => {
    //         section.questions.forEach((question) => {
    //             if (question.included === true) {
    //                 // const qweight = question.weight.find(w => w.selected === true);
    //                 // let qhweight = parseInt(qweight.value);

    //                 const q_type = question.type.find(t => t.selected === true);
    //                 let qscore = 0;
    //                 if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
    //                     if (question.auto_scoring.enabled === true) {
    //                         if (q_type.value === "Boolean") {
    //                             qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
    //                             console.log("qscore Boolean:", qscore);
    //                         }
    //                         if (q_type.value === "Dropdown") {
    //                             qscore = Math.max.apply(null, question.preset_answers.map(pa => parseInt(pa.weight)));
    //                             console.log("qscore Dropdown:", qscore);
    //                         }
    //                     } else {
    //                         const qscore_obj = question.weight.find(item => item.selected === true);
    //                         qscore = parseInt(qscore_obj.value);
    //                     }
    //                 } else {
    //                     const qscore_obj = question.weight.find(item => item.selected === true);
    //                     qscore = parseInt(qscore_obj.value);
    //                 }
    //                 // if (question.auto_scoring.enabled === true) qhweight = parseInt(question.auto_scoring.yes);
    //                 totalscore = totalscore + qscore;
    //                 const autofail_enabled = question.autofail.find(af => af.selected === true);
    //                 obtained_score = obtained_score + parseInt(question.obtained_score);
    //                 if (autofail_enabled.value === "Yes") {
    //                     if (parseInt(question.obtained_score) < qscore) {
    //                         is_autofailed = true;
    //                     }
    //                 }
    //             }
    //         })
    //     })
    //     result_obj.scoresobtained = (obtained_score / totalscore) * 100;
    //     result_obj.totalscore = totalscore;
    //     if (is_autofailed === true) {
    //         const fail_obj = review_json.scoring.scores.find(sc => sc.actual_outcome === "Fail");
    //         result_obj.ragratingcolor = fail_obj.color;
    //         result_obj.ragratingname = fail_obj.termname;
    //         result_obj.outcome = fail_obj.actual_outcome;
    //     } else {
    //         review_json.scoring.scores.forEach((single_score) => {
    //             if (result_obj.scoresobtained > single_score.lbound && result_obj.scoresobtained <= single_score.ubound) {
    //                 result_obj.ragratingcolor = single_score.color;
    //                 result_obj.ragratingname = single_score.termname;
    //                 result_obj.outcome = single_score.actual_outcome;
    //             }
    //         })
    //     }
    //     return {score: result_obj, is_autofailed: is_autofailed};
    // }

sanitizeResult = (review_json, result_obj) => {
    let is_autofailed = false;
    let totalscore = 0;
    let obtained_score = 0;
    review_json.sections.forEach((section) => {
        section.questions.forEach((question) => {
            if (question.included === true) {
                // const qweight = question.weight.find(w => w.selected === true);
                // let qhweight = parseInt(qweight.value);

                const q_type = question.type.find(t => t.selected === true);
                let qscore = 0;
                if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
                    if (question.auto_scoring.enabled === true) {
                        if (q_type.value === "Boolean") {
                            qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
                            console.log("qscore Boolean:", qscore);
                        }
                        if (q_type.value === "Dropdown") {
                            qscore = Math.max.apply(null, question.preset_answers.map(pa => parseInt(pa.weight)));
                            console.log("qscore Dropdown:", qscore);
                        }
                    } else {
                        const qscore_obj = question.weight.find(item => item.selected === true);
                        qscore = parseInt(qscore_obj.value);
                    }
                } else {
                    const qscore_obj = question.weight.find(item => item.selected === true);
                    qscore = parseInt(qscore_obj.value);
                }
                // if (question.auto_scoring.enabled === true) qhweight = parseInt(question.auto_scoring.yes);
                totalscore = totalscore + qscore;
                const autofail_enabled = question.autofail.find(af => af.selected === true);
                obtained_score = obtained_score + parseInt(question.obtained_score);
                if (autofail_enabled.value === "Yes") {
                    if (parseInt(question.obtained_score) === 0) {
                        is_autofailed = true;
                    }
                    // if (parseInt(question.obtained_score) < qscore) {
                    //     is_autofailed = true;
                    // }
                }
            }
        })
    })
    result_obj.scoresobtained = (obtained_score / totalscore) * 100;
    result_obj.totalscore = totalscore;
    if (is_autofailed === true) {
        const fail_obj = review_json.scoring.scores.find(sc => sc.actual_outcome === "Fail");
        result_obj.ragratingcolor = fail_obj.color;
        result_obj.ragratingname = fail_obj.termname;
        result_obj.outcome = fail_obj.actual_outcome;
    } else {
        review_json.scoring.scores.forEach((single_score) => {
            if (result_obj.scoresobtained > single_score.lbound && result_obj.scoresobtained <= single_score.ubound) {
                result_obj.ragratingcolor = single_score.color;
                result_obj.ragratingname = single_score.termname;
                result_obj.outcome = single_score.actual_outcome;
            }
        })
    }
    return {score: result_obj, is_autofailed: is_autofailed};
}
showFollowUp = (task_id) => (event) => {
    event.preventDefault();
    this.setState({show_spinner: true})
    let postData = { command: "get_subtask", task_id: task_id };
    let api = new APICall();
    api.command(postData, this.openFollowupModal);
};

openFollowupModal = (result) => {
    console.log("followup_task:", result.result.task.parenttask.cur_lane);
    Store.updateStore("followup_task", result.result.task);
    this.setState({ modal_view: "view_followup_modal",show_spinner: false });
};
 
closeFollowupmodal = () => {
    this.setState({ modal_view: null });
};


closeModalSave = () => {
    // event.preventDefault();
    // alert('At closeModalSave')
    let report_task_id = Store.getStoreData('report_task').parenttask.id
    // alert(report_task_id)

    EventBus.raiseEvent('followup_saved_from_parent', report_task_id) 
    this.props.closeModal();
}

render()
{
        let data = [];
        for(let c of this.state.contacts) {
            if(c.is_share) {
                data.push({"id": c.ID, "name": c.ContactName, "email": c.email})
            }
        }
        const m_custom_fields = Store.getStoreData('module_config').custom_fields;
        let report_task = Store.getStoreData('report_task')
        let report_task_json = Store.getStoreData('report_task_json')


        if (report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled === true) {
            const r_review_json = JSON.parse(JSON.stringify(report_task_json.object_data.headerinfo.review_json));
            const r_result = JSON.parse(JSON.stringify(report_task_json.object_data.result));
            let sanitized_result = this.sanitizeResult(r_review_json, r_result);
            report_task_json.object_data.result = sanitized_result.score;
        }
        
        console.log("report_task", report_task);
        const all_users = Store.getStoreData('users');
        let reviewee_list = Store.getStoreData('reviewee_list')
        let reviewee_info = Store.getStoreData("reviewee_info")
        let contact_list = {}
        for (let key in all_users) {
            contact_list[key.toString()] = all_users[key.toString()].ContactName;
        }

        let status = report_task.parenttask.completed_by > 0 ? 'Complete' : 'Pending'
        let assigned_date = this.processDate(report_task.parenttask.created_at)
        let completed_date = report_task.parenttask.completed_date !== null ? this.processDate(report_task.parenttask.completed_date) : '-'
        let file_edited_date = report_task.parenttask.confirmed_time !== null ? moment.unix(report_task.parenttask.confirmed_time).format("DD/MM/YYYY") : '-'    
       
        let due_date = this.processDate(report_task.parenttask.due_date)
        let sale_date_arr = report_task_json.task_data.sale_date.split("/");
        let sale_date = `${sale_date_arr[2]}/${sale_date_arr[1]}/${sale_date_arr[0]}`;
        const childtasks = report_task.childtask;
        let followup_task = null;
        let followup_task_json = null;
        let followup_assigned_to = null;
        let followup_due_date = null;
        let followup_status = null;
        if (Store.getStoreData('cur_task_id') !== null) {
            if (Store.getStoreData('cur_task_id') !== report_task.parenttask.id) {
                followup_task = report_task.childtask.find((item) => {
                    return item.id === Store.getStoreData('cur_task_id');
                })
                followup_task_json = JSON.parse(followup_task.task_json);
                const followup_cur_lane_arr = followup_task.cur_lane.split("_");
                if (followup_cur_lane_arr[followup_cur_lane_arr.length - 1] === "ASSIGNMENT") {
                    followup_assigned_to = contact_list[followup_task.cur_assigned_to];
                    followup_status = "open";
                } else {
                    followup_assigned_to = contact_list[followup_task.completed_by];
                    followup_status = "completed";
                }
                followup_due_date = this.processDate(followup_task_json.task_data.due_date);
            }
        }
        let related_individual = null;
        // contact_list[report_task_json.object_data.headerinfo.reviewer_id]
        if ("related_individual_id" in report_task_json.object_data.headerinfo === true) {
            if (parseInt(report_task_json.object_data.headerinfo.related_individual_id) !== 0) {
                related_individual = report_task_json.object_data.headerinfo.related_individual_id.toString() in contact_list ? contact_list[report_task_json.object_data.headerinfo.related_individual_id.toString()] : "Inactive User";
            }
        }
        // console.log("followup_task", followup_task);
        // console.log("followup_task_json", followup_task_json);

        let autofail_list = [];
	    autofail_list = this.state.comp_fc_autofail_result;
	    let msg_text = "";
	    if (autofail_list.length > 0) {
	      let q_series = "";
	      for (let i = 0; i < autofail_list.length; i++) {
	        if (i < autofail_list.length - 1) {
	          q_series += autofail_list[i] + ", ";
	        } else {
	          q_series = q_series.replace(/,\s*$/, "");
	          q_series += " and " + autofail_list[i];
	        }
	      }
	      if (autofail_list.length === 1) {
	        msg_text = "Question  " + autofail_list[0] + " has triggered this review to automatically fail.";
	      } else {
	        msg_text = "Questions   " + q_series + " have triggered this review to automatically fail.";
	      }
	    }
	    // console.log('msg_text::',msg_text,this.props,this.state)
        return (

            <div className="ReportModalContainer" ref={ this.myRef }>
                    {(() => {
                    if (this.state.modal_view === "view_followup_modal") {
                      return (
                        <div style={{ position: "absolute", left: "10%", width: "100%", height: "1186px", top: this.props.show_task === true ? "10vh" : "auto" }}>
                          <InactiveOverlay style={{ height: "2384px" }} />
                          <FollowupModal closeModal={this.closeFollowupmodal}  closeModalSave={this.closeModalSave} from_parent={true}/>
                        </div>
                      );
                    }
                  })()}
                <div className="ReportModalHeader">
                    <div className="ReportModalHeaderTxt">{report_task_json.object_data.headerinfo.review_json.general.name.replace(/'+/g,"'")}</div>
                    {
                        // (() => {
                        //     if (followup_task === null) {
                        //         return (<div className="ReportModalHeaderTxt">{report_task_json.object_data.headerinfo.customer_name}</div>);
                        //     } else {
                        //         return (<div className="ReportModalHeaderTxt">{report_task_json.object_data.headerinfo.customer_name}(Followup Task)</div>)
                        //     }
                        // })()
                    }
                    <div className="ReportModalHeaderCloseBtn" onClick={this.closeModal}><FaTimes /></div>
                    <div style={{clear: "both"}}></div>
                </div>
                <div className="ReportModalHRule"></div>
                <div style={{padding: "35px 24px 35px 24px"}}>
                    <div className="ScoreContainer">
                    {
                        (() =>{
                            if(report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled)
                            {

                                return(
                                    <div className="ScoreHeaderContainer" style={{backgroundColor: report_task_json.object_data.result.ragratingcolor, borderColor: report_task_json.object_data.result.ragratingcolor}}>
                                        <div className="ResultTxt" style={{backgroundColor: report_task_json.object_data.result.ragratingcolor, padding: "5px"}}>{report_task_json.object_data.result.ragratingname}&nbsp;({report_task_json.object_data.result.outcome})</div>
                                        <div className="ResultRightDiv">
                                            <div style={{float: "left"}}>
                                                Score&nbsp;&nbsp;{Math.round(report_task_json.object_data.result.scoresobtained)}%
                                            </div>
                                            <div style={{float: "left", fontWeight: "bold", marginLeft: "25px"}}></div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                    )
                            }else{
                                return(
                                    <div className="ScoreHeaderContainer" style={{backgroundColor: "color" in report_task_json.object_data.non_score_assessment ? report_task_json.object_data.non_score_assessment.color : "#b3b2b1", 
                                                                                  borderColor: "color" in report_task_json.object_data.non_score_assessment ? report_task_json.object_data.non_score_assessment.color : "#b3b2b1"}}>
                                    <div className="ResultTxt" style={{backgroundColor: "color" in report_task_json.object_data.non_score_assessment ? report_task_json.object_data.non_score_assessment.color : "#b3b2b1", padding: "5px"}}></div>
                                    <div className="ResultRightDiv">
                                        <div style={{float: "left", fontSize: "18px"}}>
                                            {"non_score_assessment" in report_task_json.object_data ? report_task_json.object_data.non_score_assessment.result.replace(/'+/g,"'") : "" }
                                        </div>
                                    <div style={{float: "left", fontWeight: "bold", marginLeft: "25px"}}></div>
                                    <div style={{clear: "both"}}></div>
                                    </div>
                                    <div style={{clear: "both"}}></div>
                                </div>
                                    )
                            }
                        })()   
                    }


                        
                        <div className="ScoreBodyContainer">
                            {
                                (() => {
                                    let sale_date_label = "Sale Date";
                                    let is_sale_date_active = true;
                                    if ("custom_terminology_fields" in report_task_json.object_data.headerinfo) {
                                        if (report_task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
                                            const sale_obj = report_task_json.object_data.headerinfo.custom_terminology_fields.find(item => item.name === "sale_date");
                                            if (sale_obj !== undefined) {
                                                let m_s_obj = m_custom_fields.custom_terminology_fields.find(item => item.name === sale_obj.name);
                                                if (sale_obj.nickname !== "") {
                                                    sale_date_label = m_s_obj !== undefined ? m_s_obj.nickname : sale_obj.nickname;
                                                } 
                                                is_sale_date_active = "is_active" in sale_obj ? sale_obj.is_active : true;
                                            }
                                        }
                                    }
                                    return (
                                        <>
                                            <div>
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Reviewer</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{report_task_json.object_data.headerinfo.reviewer_id in contact_list ? contact_list[report_task_json.object_data.headerinfo.reviewer_id] : "Inactive User"}</span></InfoDiv>
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Reviewee</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{report_task_json.object_data.headerinfo.reviewee_id in contact_list ? contact_list[report_task_json.object_data.headerinfo.reviewee_id] : "Inactive User"}</span></InfoDiv>
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Status</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{status}</span></InfoDiv>
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Customer Name</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{report_task_json.object_data.headerinfo.customer_name.replace(/'+/g,"'")}</span></InfoDiv>
                                                <div style={{clear: "both"}}></div>
                                            </div>
                                            <div>
                                                {
                                                  (() => {
                                                    if(is_sale_date_active){
                                                        return(<InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{sale_date_label}</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{sale_date}</span></InfoDiv>)
                                                    }
                                                  })()
                                                }                                          
                                                {/* <div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Assigned Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.assigned_date}</span></div> */}
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Assigned Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{assigned_date}</span></InfoDiv>
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Due Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{due_date}</span></InfoDiv>
                                                {/* <div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Review Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>--</span></div> */}
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Review Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{completed_date}</span></InfoDiv>
                                                <div style={{clear: "both"}}></div>
                                            </div>
                                            <div>
                                                <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>File Edited Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{file_edited_date}</span></InfoDiv>
                                                {
                                                    (() => {
                                                        let is_reviewee_designation_active = true;
                                                        let reviewee_designation_label = "Reviewee Designation";
                                                        if ("custom_terminology_fields" in report_task_json.object_data.headerinfo) {
                                                            if (report_task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
                                                                const designation_obj = report_task_json.object_data.headerinfo.custom_terminology_fields.find(item => item.name === "reviewee_designation");
                                                                if (designation_obj !== undefined) {
                                                                    is_reviewee_designation_active = "is_active" in designation_obj ? designation_obj.is_active : true;
                                                                    let m_d_obj = m_custom_fields.custom_terminology_fields.find(item => item.name === designation_obj.name);
                                                                    if (designation_obj.nickname !== "") {
                                                                        reviewee_designation_label = m_d_obj !== undefined ? m_d_obj.nickname : designation_obj.nickname;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        let additional_fields = {};
                                                        if ("additional_fields" in report_task_json.object_data.headerinfo) {
                                                            if (report_task_json.object_data.headerinfo.additional_fields.length !== 0) {
                                                                for (let item of report_task_json.object_data.headerinfo.additional_fields) {
                                                                    if (item.type === "Dropdown") {
                                                                        if (item.value !== "" || item.other_value !== "") {
                                                                            const value = item.value === "other" ? item.other_value : item.value;
                                                                            let m_field = m_custom_fields.additional_fields.find(rec => rec.name === item.name);
                                                                            additional_fields[item.name] = {label: m_field !== undefined ? m_field.nickname : item.nickname, value: value};
                                                                        }
                                                                    } else {
                                                                        let m_field = m_custom_fields.additional_fields.find(rec => rec.name === item.name);
                                                                        if (item.value !== "") additional_fields[item.name] = {label: m_field !== undefined ? m_field.nickname : item.nickname, value: item.value};
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        let addls = [];
                                                        if(is_reviewee_designation_active){
                                                            addls.push(<InfoDiv key={reviewee_designation_label}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{reviewee_designation_label}</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{report_task_json.object_data.headerinfo.reviewee_designation}</span></InfoDiv>);
                                                        }
                                                        
                                                        if (Object.keys(additional_fields).length !== 0) {
                                                            for (let key in additional_fields) {
                                                                addls.push(<InfoDiv key={key}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{additional_fields[key].label}</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{additional_fields[key].value}</span></InfoDiv>)
                                                            }
                                                        }
                                                        return (<div>{addls}<div style={{clear: "both"}}></div></div>)
                                                    })()
                                                }
                                                {
                                                    related_individual !== null &&
                                                    <>
                                                        <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Related Individual</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{related_individual}</span></InfoDiv>
                                                        <div style={{clear: "both"}}></div>
                                                    </>
                                                }

                                                {
                                                    (() => {
                                                        {/*console.log('report_task_json',report_task_json)*/}
                                                        if('admin_confirmation_text' in report_task_json.object_data){
                                                            let edited_questions = "edited_questions" in report_task_json.object_data ? report_task_json.object_data.edited_questions : [];
                                                            let edited_questions_as_text = '';
                                                            if(edited_questions.length > 0){
                                                                for(let i = 0; i < edited_questions.length; i++){
                                                                    if(edited_questions_as_text === ''){
                                                                        edited_questions_as_text = edited_questions[i].s_index;
                                                                    }else{
                                                                        edited_questions_as_text = edited_questions_as_text + ',' + edited_questions[i].s_index;
                                                                    }
                                                                }
                                                            }

                                                            let lastConfirmedAction = null;
                                                            let data = report_task_json.action_data;
                                                            for (let i = data.length - 1; i >= 0; i--) {
                                                              const action = data[i];

                                                              if(action.cur_lane === "COMP_FCK_COMPLETE" && action.hasOwnProperty("confirmed_by")) {
                                                                lastConfirmedAction = action;
                                                                break;
                                                              }
                                                            }

                                                            let confirmation_text = report_task_json.object_data.admin_confirmation_text.replace(/'+/g,"'").replace(/\n/g,"<br/>").replace(/ /g,"&nbsp;")
                                                            let comment_by = lastConfirmedAction !== null && "confirmed_by" in lastConfirmedAction ? contact_list[lastConfirmedAction.confirmed_by] : ""
                                                            {/*console.log("lastConfirmedAction::",lastConfirmedAction,comment_by);*/}
                                                            return(<>
                                                               <div>
                                                                    <InfoDiv style={{width: "100%"}}><span style={{fontWeight: "600", overflowWrap: "break-word", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>File Edit Comments</span>&nbsp;&nbsp;&nbsp;<span className="ReportAnswerText" style={{padding: "10px", color: `${process.env.DEFAULT_TEXT_COLOR}`,overflowWrap: "break-word"}} dangerouslySetInnerHTML={{__html: confirmation_text}}></span></InfoDiv>
                                                                    <div style={{clear: "both"}}></div>
                                                                    <InfoDiv style={{width: "100%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Comments By</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{comment_by}</span></InfoDiv>
                                                                    <div style={{clear: "both"}}></div>
                                                                     {
                                                                        edited_questions_as_text !== "" &&
                                                                        <>
                                                                            <InfoDiv><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Question {edited_questions_as_text} were edited</span></InfoDiv>
                                                                            <div style={{clear: "both"}}></div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </>)
                                                        }


                                                    })()
                                                }
                                            </div>
                                        </>
                                    )
                                })()
                            }
                        </div>
                    </div>
                    <div className="ScoreContainer" style={{backgroundColor: "#ffffff", marginTop: "20px"}}>
                        <div className="ScoreHeaderContainer">
                            <div className="ResultTxt">Comments</div>
                            <div className="ResultRightDiv">
                            </div>
                            <div style={{clear: "both"}}></div>
                        </div>
                        <div className="ScoreBodyContainer" style={{backgroundColor: "#ffffff", padding: "0px 0px 20px 0px"}}>
                            {
                                (() => {
                                    let reviewer_comments = "";
                                    console.log("reviewer comments:", report_task_json.object_data.headerinfo.review_json.summary);
                                    reviewer_comments = "summary" in report_task_json.object_data.headerinfo.review_json ? report_task_json.object_data.headerinfo.review_json.summary.replace(/'+/g,"'").replace(/\n/g,"<br/>").replace(/ /g,"&nbsp;") : "";
                                    if (report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled === false) {
                                        // console.log("reviewer comments:", report_task_json.object_data.non_score_assessment.reviewer_comments);
                                        reviewer_comments = report_task_json.object_data.non_score_assessment.reviewer_comments.replace(/'+/g,"'").replace(/\n/g,"<br/>").replace(/ /g,"&nbsp;");
                                    }
                                    console.log("sanitized reviewer comments:", reviewer_comments);
                                    return (<div className="ReportAnswerText" style={{padding: "10px", overflowWrap: "break-word"}} dangerouslySetInnerHTML={{__html: reviewer_comments}}></div>);
                                })()
                            }
                        {/* <div className="ReportAnswerText" style={{padding: "10px"}}> {"summary" in report_task_json.object_data.headerinfo.review_json ? report_task_json.object_data.headerinfo.review_json.summary.replace(/'+/g,"'") : "" } </div> */}
                        </div>
                    </div>
                    {
                        (() => {
                        	if(msg_text !== ''){
                        		return(
                        			<div className="ScoreContainer" style={{backgroundColor: "#ffffff", marginTop: "20px"}}>
				                        <div className="ScoreBodyContainer" style={{backgroundColor: "#ffffff", padding: "20px 14px 30px 0px"}}>
				                        <InfoDiv style={{width:"100%",marginTop: "0px"}}><span style={{paddingLeft: "14px", fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{msg_text}</span></InfoDiv>				                            
				                        </div>
				                    </div>
                        		)
                        	}
	                    })()
                    }
                    <div className="ScoreContainer" style={{marginTop: "20px"}}>
                        <div className="ScoreHeaderContainer">
                            <div className="ResultTxt" style={{fontWeight: "600"}}>Task Associated with this Filecheck</div>
                            <div className="ResultRightDiv">
                                {/* <div style={{float: "left"}}>Score&nbsp;&nbsp;<strong>88%</strong></div>
                                <div style={{float: "left", fontWeight: "bold", marginLeft: "25px"}}><IoIosArrowUp /></div>
                                <div style={{clear: "both"}}></div> */}
                            </div>
                            <div style={{clear: "both"}}></div>
                        </div>
                        {
                            (() => {
                                if (childtasks.length !== 0) {
                                    return (
                                        <div className="ScoreBodyContainer" style={{backgroundColor: "#ffffff"}}>
                                            <table style={{width: "100%", textAlign: "left"}}>
                                                <thead>
                                                    <tr>
                                                        <th style={{width: "60%", fontWeight: "500", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Task</th>
                                                        <th style={{width: "20%", fontWeight: "500", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Assigned To</th>
                                                        <th style={{width: "10%", fontWeight: "500", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Status</th>
                                                        <th style={{width: "10%", fontWeight: "500", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Due Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (() => {
                                                            let ctasks_html = [];
                                                            childtasks.forEach((ctask, index) => {
                                                                console.log("ctask::",ctask)
                                                                let assigned_to_id = null;
                                                                let status = null;
                                                                const cur_lanearr = ctask.cur_lane.split("_");
                                                                if (cur_lanearr[cur_lanearr.length - 1] === "ASSIGNMENT") {
                                                                    assigned_to_id = ctask.cur_assigned_to;
                                                                    status = "open";
                                                                } else {
                                                                    assigned_to_id = ctask.completed_by;
                                                                    status = "completed";
                                                                }
                                                                const assigned_to = contact_list[assigned_to_id];
                                                                const due_date = this.processDate(ctask.due_date);
                                                                const ctask_json = JSON.parse(ctask.task_json);
                                                                const subject = ctask_json.object_data.subject;
                                                                if (index === 0) {
                                                                    ctasks_html.push(
                                                                        <tr key={ctask.id}>
                                                                            <td style={{width: "60%", paddingTop: "15px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}><a href="#"  onClick={this.showFollowUp(ctask.id)}>{subject}</a></td>
                                                                            <td style={{width: "20%", paddingTop: "15px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{assigned_to}</td>
                                                                            <td style={{width: "10%", paddingTop: "15px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{status}</td>
                                                                            <td style={{width: "10%", paddingTop: "15px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{due_date}</td>
                                                                        </tr>
                                                                    );
                                                                } else {
                                                                    ctasks_html.push(
                                                                        <tr key={ctask.id}>
                                                                            <td style={{width: "60%",  paddingTop: "10px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}><a href="#"  onClick={this.showFollowUp(ctask.id)}>{subject}</a></td>
                                                                            <td style={{width: "20%",  paddingTop: "10px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{assigned_to}</td>
                                                                            <td style={{width: "10%", paddingTop: "10px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{status}</td>
                                                                            <td style={{width: "10%", paddingTop: "10px", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{due_date}</td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            })
                                                            return ctasks_html;
                                                        })()
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                            })()
                        }
                    </div>
                </div>
                <div className="ReportModalHRule"></div>
                <div style={{padding: "35px 24px 35px 24px"}}>
                    <div className="ScoreContainer" style={{backgroundColor: "#ffffff"}}>
                        <div className="ScoreHeaderContainer">
                            <div className="ResultTxt">Sections</div>
                            <div className="ResultRightDiv">
                                {/* <div style={{float: "left"}}>Score&nbsp;&nbsp;<strong>88%</strong></div>
                                <div style={{float: "left", fontWeight: "bold", marginLeft: "25px"}}><IoIosArrowUp /></div>
                                <div style={{clear: "both"}}></div> */}
                            </div>
                            <div style={{clear: "both"}}></div>
                        </div>
                        <div className="ScoreBodyContainer" style={{backgroundColor: "#ffffff", padding: "0px 0px 20px 0px"}}>
                            <ReportSectionHOC sections={report_task_json.object_data.headerinfo.review_json.sections} score_enabled={report_task_json.object_data.headerinfo.review_json.scoring.scoring_enabled}/>
                        </div>
                    </div>
                </div>
                {
                    (() => {
                        if(this.state.share) {
                            return(
                                <div style={{padding: "35px 24px 35px 24px"}}>
                                    <div className="ScoreContainer" style={{backgroundColor: "#ffffff"}}>
                                        <div className="ScoreHeaderContainer">
                                            <div className="ResultTxt">Users</div>
                                            <div className="ResultRightDiv">
                                            <div style={{color: "#ffffff", cursor: "pointer", fontWeight: "600", fontSize: "24px"}}><IoIosArrowUp /></div>
                                            </div>
                                            <div style={{clear: "both"}}></div>
                                        </div>
                                        <div className="ScoreBodyContainer" style={{backgroundColor: "#ffffff"}}>
                                            <CSLTable add={false} processData={this.processUserData} headerText={''} tableRows="10"/>
                                        </div>
                                    </div>
                                    <div className="ReportModalFooter">
                                    <div className="ResultTxt" style={{color:"#28dad1"}}>{data.length} Users Selected</div>
                                        <Sharereportbutton onClick={this.shareReportUsers}>Submit</Sharereportbutton>
                                        <ExpoxtPdf onClick={this.CloseShare}>Close</ExpoxtPdf>
                                    </div>
                                </div>
                                )
                        }
                    })()
                }
                <div className="ReportModalFooter">
                    <Sharereportbutton onClick={this.shareReport}>Share Report</Sharereportbutton>
                    <ExpoxtPdf onClick={this.exportToPdf}>Export</ExpoxtPdf>
                    <button className="ReportModalCloseBtn" onClick={this.closeModal}>Close</button>
                </div>
            </div>
        );
    }
}

export default ReportModal;
