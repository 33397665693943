import React from "react";
import Store from "../../Common/Store";
import Utils from "../../Common/Utils";
import APICall from "../../Common/APICall";
import styled from "styled-components";
import { FaTimes, FaPlusCircle, FaLock } from "react-icons/fa";
import { IoIosArrowForward, IoIosLock } from "react-icons/io";
import AnswerOverviewQa from "../Partials/AnswerOverviewQa";
import AnswerQaSection from "../Partials/AnswerQaSection";
import QAOverview from "../Partials/QAOverview";
import NonScoreResult from "../Partials/NonScoreResult";
import moment from "moment";

const PillBox = styled.div`
  background-color: rgb(235, 235, 235);
  border: 1px solid rgb(223, 223, 223);
  text-align: center;
  display: inline-block;
  padding: 10px 18px;
`;
const PillIcon = styled.div`
  display: inline-block;
`;
const PillText = styled.div`
  display: inline-block;
  padding-left: 25px;
`;
const ReviewHeaderText = styled.div`
  padding-top: 25px;
  font-size: 20px;
  font-weight: 600;
`;
const ReviewBodyContainer = styled.div`
  padding-top: 25px;
  width: 100%;
`;
const ReviewLeftContainer = styled.div`
  float: left;
  padding-right: 20px;
  width: 20%;
  box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
  float: right;
  padding-left: 20px;
  width: 80%;
  box-sizing: border-box;
`;
const ReviewTab = styled.div`
  background-color: #ffffff;
  color: #919191;
  padding: 10px 20px;
  cursor: pointer;
`;
const CloseBtn = styled.div`
  float: right;
  font-size: 20px;
  color: #9c9c9c;
  cursor: pointer;
  padding-top: 10px;
  padding-right: 5px;
`;
const ReviewFooter = styled.div`
  text-align: right;
  padding-top: 30px;
`;
const SaveBtn = styled.button`
  background-color: #37ada7;
  padding: 10px 25px;
  border: 1px solid #37ada7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
`;
const CancelBtn = styled.button`
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #b4b4b4;
  border-radius: 2px;
  color: #666666;
  cursor: pointer;
`;
const AnswerHr = styled.div`
  height: 2px;
  background-color: #d9d9d9;
  margin-top: 15px;
`;
const QStatus = styled.div`
  background-color: #0f2b4b;
  padding: 10px;
  color: #ffffff;
  margin-top: 15px;
`;

class QcView extends React.Component {
  state = {
    task: null,
    task_json: null,
    review_json: null,
    active_section_id: "overview",
    payload: {},
    task_id: null,
    section_payload: null,
    qa_non_score_result: [],
    non_score_assessment: {},
    score_result: {},
    qc_score_result: {},
    qc_autofail_result: [],
    score_progression_enabled: true,
  };

  componentDidMount() {
    const task_id = Store.getStoreData("fc_index");
    console.log("current task id:", task_id);
    const api = new APICall();
    const postData = { command: "get_task", task_id: task_id };
    api.command(postData, this.singleTask);
  }

  singleTask = (result) => {
    console.log("Result:", result);
    let { task, task_json, review_json, payload, task_id, score_result, qc_score_result, score_progression_enabled } = this.state;
    let module_config = Store.getStoreData("module_config");
    if (Object.keys(module_config).length > 0) {
      score_progression_enabled = module_config.general.score_progression_enabled;
    }
    let due_date = result.result.task.parenttask.due_date ? result.result.task.parenttask.due_date : "1970-01-01T00:00:00.000Z";
    let dt = new Date(due_date);
    payload.due_date = moment(dt).format("DD/MM/YYYY");


    task_json = JSON.parse(result.result.task.parenttask.task_json);
    console.log("Single Task Json:", task_json);

    let sale_date = task_json.task_data.sale_date;
    payload.sale_date = moment(sale_date).format("DD/MM/YYYY");
    console.log("payload.sale_date::",payload.sale_date )

    let non_score_assessment = "non_score_assessment" in task_json.object_data ? task_json.object_data.non_score_assessment : { reviewer_comments: "", result: "-" };
    let qc_non_score_assessment = "qc_non_score_assessment" in task_json.object_data ? task_json.object_data.qc_non_score_assessment : { qc_comments: "", result: "-" };
    task_json.object_data.qc_non_score_assessment = qc_non_score_assessment;
    review_json = task_json.object_data.headerinfo.review_json;
    if (review_json.scoring.scoring_enabled === false) {
      review_json.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (!("non_scoring_info" in question)) {
            const non_scoring_info = { label: "Select", color: "#ffffff" };
            question.non_scoring_info = non_scoring_info;
          }
        });
      });
    }
    const contacts = Store.getStoreData("reviewee_list");
    const all_users = Store.getStoreData("users");
    const gc_companies = Store.getStoreData("gc_companies");
    task_id = result.result.task.parenttask.id;
    console.log("task_id:", task_id);
    console.log("Single Task:", result);
    console.log("Single Task Review Json:", review_json);
    console.log("Contacts", contacts);

    // qa_Assigned Date

    const qa_assigned_date = task_json.action_data.filter((d) => d.action === "COMP_FCK_QC_ASSIGNMENT").map((d) => moment(d.actiondate).format("DD/MM/YYYY"));
    payload.qa_assigned_date = qa_assigned_date.length ? qa_assigned_date[qa_assigned_date.length - 1] : "N/A";

    payload.reviewer = task_json.object_data.headerinfo.reviewer_id.toString() in all_users ? all_users[task_json.object_data.headerinfo.reviewer_id.toString()] : { ContactName: "Inactive User" };
    payload.reviewee = task_json.object_data.headerinfo.reviewee_id.toString() in all_users ? all_users[task_json.object_data.headerinfo.reviewee_id.toString()] : { ContactName: "Inactive User" };
    
    if ("related_individual_id" in task_json.object_data.headerinfo === true) {
      if (task_json.object_data.headerinfo.related_individual_id !== 0) {
        payload.related_individual = task_json.object_data.headerinfo.related_individual_id.toString() in all_users ? all_users[task_json.object_data.headerinfo.related_individual_id.toString()] : { ContactName: "Inactive User" };
      }
    }
    
    payload.qa_reviewer = result.result.task.parenttask.cur_assigned_to.toString() in all_users ? all_users[result.result.task.parenttask.cur_assigned_to.toString()] : { ContactName: "Inactive User" };
    payload.name = review_json.general.name;
    payload.description = review_json.general.description;
    payload.status = "Result: " + task_json.object_data.result.ragratingname + " ( " + task_json.object_data.result.outcome + " )";
    payload.score = Math.round(task_json.object_data.result.scoresobtained) + "%";
    payload.comment = task_json.object_data.headerinfo.review_json.summary ? task_json.object_data.headerinfo.review_json.summary : "";
    payload.company_id = result.result.task.parenttask.company_id;
    //payload.company_name = parseInt(task_json.task_data.company_id) < 100000 ? Store.getStoreData('my_company').CompanyName : Store.getStoreData("gc_assoc")[(parseInt(task_json.task_data.company_id)%100000).toString()].company_name;
    payload.company_name = task_json.object_data.headerinfo.customer_name;
    payload.child_tasks = result.result.task.childtask;
    payload.contacts = contacts;
    review_json.sections.forEach((section) => {
      section.questions.forEach((question) => {
        question["qa_data"] = {
          comments: "qa_data" in question ? question.qa_data.comments : "",
          answer: "qa_data" in question ? question.qa_data.answer : question.answer,
          obtained_score: "qa_data" in question ? question.qa_data.obtained_score : question.obtained_score,
        };
        if (review_json.scoring.scoring_enabled === false) {
          question["qa_non_score_data"] =
            "qa_non_score_data" in question ? question["qa_non_score_data"] : { answer: question.answer.text, label: question.non_scoring_info.label, color: question.non_scoring_info.color, comments: "" };
        }
        question["is_qa"] = "is_qa" in question ? question.is_qa : false;
        question["qa_na_included"] = "qa_na_included" in question ? question["qa_na_included"] : question["included"];
      });
    });
    qc_score_result = JSON.parse(JSON.stringify(task_json.object_data.result));
    if ("qc_score_result" in task_json.object_data) {
      qc_score_result = task_json.object_data.qc_score_result;
    }
    task_json.object_data["qc_score_result"] = qc_score_result;
    let qa_non_score_result = [];
    if (review_json.scoring.scoring_enabled === false) {
      review_json.scoring.non_scores.forEach((item) => {
        const record = { color: item.color, label: item.label, count: "00" };
        qa_non_score_result.push(record);
      });
    }

    if (review_json.scoring.scoring_enabled === false) {
      review_json.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.included) {
            review_json.scoring.non_scores.forEach((item) => {
              if (question.qa_non_score_data.label !== "Select" && question.qa_non_score_data.label === item.label) {
                qa_non_score_result.forEach((sitem) => {
                  if (question.qa_non_score_data.label === sitem.label) {
                    let ext_count = parseInt(sitem.count);
                    ext_count = ext_count + 1;
                    ext_count = ext_count.toString();
                    ext_count = ext_count.length === 1 ? "0" + ext_count : ext_count;
                    sitem.count = ext_count;
                  }
                });
              }
            });
          }
        });
      });
    }
    console.log("qa_non_score_result:", qa_non_score_result);

    // let qc_autofail_result = []
    // qc_autofail_result = Utils.getAutofailQuestions(result.result.task.parenttask)
    // console.log('qc_autofail_result::',qc_autofail_result)

    this.setState({ task: result, qa_non_score_result, non_score_assessment, task_json, review_json, payload, task_id, score_result, qc_score_result, score_progression_enabled });
  };

  returnSection = (section_index) => (event) => {
    // console.log("Section Index:", section_index);
    let { active_section_id, qanda, section_payload } = this.state;
    active_section_id = section_index;
    if (section_index !== "overview") {
      section_payload = this.state.review_json.sections.find((section) => {
        return section.id === section_index;
      });
    }
    if (section_index == "qaoverview") {
      section_payload = this.state.task_json;
      section_payload.object_data.qc_score_result = this.state.qc_score_result;
      section_payload.object_data.headerinfo.review_json = this.state.review_json;
      console.log("summary data", section_payload);
    }
    this.setState({ active_section_id, section_payload });
  };

  modifySections = (sections, s_result) => {
    let { review_json, section_payload, qc_score_result } = this.state;
    qc_score_result = s_result;
    review_json.sections = sections;
    section_payload = review_json.sections.find((section) => {
      return section.id === this.state.active_section_id;
    });
    console.log("STATE SCORE:", this.state.qc_score_result);
    console.log("sections:", review_json.sections);
    this.setState({ review_json, section_payload, qc_score_result });
  };

  modifyNonScoreSections = (sections) => {
    let review_json = JSON.parse(JSON.stringify(this.state.review_json));
    review_json.sections = sections;
    let section_payload = review_json.sections.find((section) => {
      return section.id === this.state.active_section_id;
    });
    let qa_non_score_result = this.calculateNonScore(review_json);
    this.setState({ review_json, section_payload, qa_non_score_result });
  };

  calculateNonScore = (review_json_param) => {
    let review_json = JSON.parse(JSON.stringify(review_json_param));
    let qa_non_score_result = [];
    if (review_json.scoring.scoring_enabled === false) {
      review_json.scoring.non_scores.forEach((item) => {
        const record = { color: item.color, label: item.label, count: "00" };
        qa_non_score_result.push(record);
      });
    }

    if (review_json.scoring.scoring_enabled === false) {
      review_json.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.included) {
            review_json.scoring.non_scores.forEach((item) => {
              if (question.qa_non_score_data.label !== "Select" && question.qa_non_score_data.label === item.label) {
                qa_non_score_result.forEach((sitem) => {
                  if (question.qa_non_score_data.label === sitem.label) {
                    let ext_count = parseInt(sitem.count);
                    ext_count = ext_count + 1;
                    ext_count = ext_count.toString();
                    ext_count = ext_count.length === 1 ? "0" + ext_count : ext_count;
                    sitem.count = ext_count;
                  }
                });
              }
            });
          }
        });
      });
    }
    // console.log("qa_non_score_result:", qa_non_score_result);
    return qa_non_score_result;
  };

  calculateFinalResult = (result_obj, review_json) => {
    let is_autofailed = false;
    let totalscore = 0;
    let obtained_score = 0;
    review_json.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.qa_na_included === true) {
          // const qweight = question.weight.find(w => w.selected === true);
          // let qhweight = parseInt(qweight.value);

          const q_type = question.type.find((t) => t.selected === true);
          let qscore = 0;
          if (q_type.value === "Boolean" || q_type.value === "Dropdown") {
            if (question.auto_scoring.enabled === true) {
              if (q_type.value === "Boolean") {
                qscore = Math.max(parseInt(question.auto_scoring.yes), parseInt(question.auto_scoring.no));
                console.log("qscore Boolean:", qscore);
              }
              if (q_type.value === "Dropdown") {
                qscore = Math.max.apply(
                  null,
                  question.preset_answers.map((pa) => parseInt(pa.weight))
                );
                console.log("qscore Dropdown:", qscore);
              }
            } else {
              const qscore_obj = question.weight.find((item) => item.selected === true);
              qscore = parseInt(qscore_obj.value);
            }
          } else {
            const qscore_obj = question.weight.find((item) => item.selected === true);
            qscore = parseInt(qscore_obj.value);
          }
          // if (question.auto_scoring.enabled === true) qhweight = parseInt(question.auto_scoring.yes);
          totalscore = totalscore + qscore;
          const autofail_enabled = question.autofail.find((af) => af.selected === true);
          obtained_score = obtained_score + parseInt(question.qa_data.obtained_score);
          if (autofail_enabled.value === "Yes") {
            if (parseInt(question.qa_data.obtained_score) === 0) {
              is_autofailed = true;
            }
            // if (parseInt(question.obtained_score) < qscore) {
            //     is_autofailed = true;
            // }
          }
        }
      });
    });
    result_obj.scoresobtained = (obtained_score / totalscore) * 100;
    result_obj.totalscore = totalscore;
    if (is_autofailed === true) {
      const fail_obj = review_json.scoring.scores.find((sc) => sc.actual_outcome === "Fail");
      result_obj.ragratingcolor = fail_obj.color;
      result_obj.ragratingname = fail_obj.termname;
      result_obj.outcome = fail_obj.actual_outcome;
    } else {
      review_json.scoring.scores.forEach((single_score) => {
        if (result_obj.scoresobtained > single_score.lbound && result_obj.scoresobtained <= single_score.ubound) {
          result_obj.ragratingcolor = single_score.color;
          result_obj.ragratingname = single_score.termname;
          result_obj.outcome = single_score.actual_outcome;
        }
      });
    }
    return { score: result_obj, is_autofailed: is_autofailed };
  };

  closeView = (event) => {
    event.preventDefault();
    Store.updateStore("fc_index", null);
    if (document.referrer.includes("/register") || document.referrer.includes("/tmview")) {
      window.location.assign(document.referrer);
    }
    console.log("this.props.parentview", this.props.parentview);
    this.props.changeCurView(this.props.parentview);
  };

  saveDisputed = () => {
    let task_json = JSON.parse(JSON.stringify(this.state.task_json));
    task_json["object_data"].headerinfo.review_json = JSON.parse(JSON.stringify(this.state.review_json));
    if (task_json.object_data.headerinfo.review_json.scoring.scoring_enabled === true) {
      let ret = this.calculateFinalResult(this.state.qc_score_result, this.state.review_json);
      task_json.object_data.qc_score_result = ret.score;
    }
    // this.setState({task_json});
    // console.log(task_json);
    // return false;
    task_json.is_sectionsave = "1";
    let postData = { command: "update_filecheck_task", task_json: task_json, task_id: this.state.task_id };
    const api = new APICall();
    api.command(postData, this.afterSave);
  };

  afterSave = (result) => {
    console.log("SAVED:", result);
    // alert("Your responses have been saved");
  };

  handleComment = (text) => {
    if (this.state.task_json.object_data.headerinfo.review_json.scoring.scoring_enabled) {
      let { qc_score_result } = this.state;
      qc_score_result.comment = text;
      this.setState({ qc_score_result });
    } else {
      let task_json = JSON.parse(JSON.stringify(this.state.task_json));
      task_json.object_data.qc_non_score_assessment.qc_comments = text;
      this.setState({ task_json });
    }
  };

  handleQcAssessmentResult = (data) => {
    let task_json = JSON.parse(JSON.stringify(this.state.task_json));
    task_json.object_data.qc_non_score_assessment.result = data;
    const ret = task_json.object_data.headerinfo.review_json.scoring.non_scores.find((item) => item.label === data);
    if (ret !== undefined) {
      task_json.object_data.qc_non_score_assessment.color = ret.color;
    } else {
      delete task_json.object_data.qc_non_score_assessment.color;
    }
    // task_json.object_data.qc_non_score_assessment.result.color = ret.color;
    console.log("task_json:", task_json);
    this.setState({ task_json });
  };

  CompletQC = (event) => {
    event.preventDefault();
    event.preventDefault();
    let task_json = this.state.task_json;
    task_json["task_data"].last_action = "COMP_FCK_QC_COMPLETE";
    task_json["task_data"].cur_lane = "COMP_FCK_QC_COMPLETE";
    task_json["object_data"].headerinfo.review_json = this.state.review_json;
    task_json["action_data"] = { action: "COMP_FCK_QC_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData("loggedin_user_info").ID, cur_lane: "COMP_FCK_QC_COMPLETE", assigned_to: 0 };
    let postData = { command: "update_filecheck_task", task_json: task_json, task_id: this.state.task_id };
    const api = new APICall();
    api.command(postData, this.afterCompleted);
    console.log("postdata", postData);
  };

  afterCompleted = (result) => {
    console.log("Completed:", result);
    alert("The file check has been completed.");
    window.location.reload(true);
  };

  hexToRgb = (hex) => {
    hex = hex.slice(1);
    let bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return r + "," + g + "," + b;
  };

  reloadChildTasks = () => {
    const task_id = Store.getStoreData("fc_index");
    console.log("current task id:", task_id);
    const api = new APICall();
    const postData = { command: "get_task", task_id: task_id };
    api.command(postData, this.singleTask);
  };

  showPage = (page) => (event) => {
    event.preventDefault();
    this.props.showPage(page);
  };

  addFutureFollowup = (obj) => {
    // console.log("obj:", obj);
    let task_json = JSON.parse(JSON.stringify(this.state.task_json));
    if (task_json.object_data.future_followups.length === 0) {
      task_json.object_data.future_followups.push(obj);
    } else {
      const exist = task_json.object_data.future_followups.find((ft) => ft.id === obj.id);
      if (exist === undefined) {
        task_json.object_data.future_followups.push(obj);
      } else {
        task_json.object_data.future_followups.forEach((ft) => {
          if (ft.id === obj.id) ft = obj;
        });
      }
    }
    this.setState({ task_json });
  };

  removeFutureFollowup = (task_id) => {
    let task_json = JSON.parse(JSON.stringify(this.state.task_json));
    let mod_tasks = [];
    for (let task of task_json.object_data.future_followups) {
      if (task.id !== task_id) mod_tasks.push(task);
    }
    task_json.object_data.future_followups = mod_tasks;
    this.setState({ task_json });
  };

  render() {
    console.log("Answerview HERE")
    if (this.state.review_json === null) {
      return <div>Loading...</div>;
    }

    console.log("this.stateQC =====> ", this.state);
    return (
      <div style={{ padding: "10px 10px 20px 10px" }}>
        <div style={{ float: "left" }}>
          <PillBox>
            <PillIcon style={{ opacity: "0.2" }}>
              <IoIosLock />
            </PillIcon>
            <PillText style={{ opacity: "0.2" }}>Setup</PillText>
          </PillBox>
          <PillBox>
            <PillIcon style={{ opacity: "0.2" }}>
              <IoIosLock />
            </PillIcon>
            <PillText style={{ opacity: "0.2" }}>Complete</PillText>
          </PillBox>
          <PillBox style={{ cursor: "pointer", backgroundColor: "#a3c7f1" }} onClick={this.showPage("review")}>
            <PillIcon style={{ opacity: "0.2" }}></PillIcon>
            <PillText style={{ paddingLeft: "0px", color: "#282828" }}>Review</PillText>
          </PillBox>
          <PillBox style={{ backgroundColor: "#0f2b4b", border: "1px solid #0f2b4b" }}>
            <PillIcon style={{ opacity: "0.2" }}>
              <IoIosLock />
            </PillIcon>
            <PillText style={{ paddingLeft: "0px", color: "#ffffff" }}>Quality Assurance</PillText>
          </PillBox>
        </div>
        <CloseBtn onClick={this.props.closeQcView}>
          <FaTimes />
        </CloseBtn>
        <div style={{ clear: "both" }}></div>
        <ReviewHeaderText>{this.state.review_json.general.name}</ReviewHeaderText>
        <ReviewBodyContainer>
          <ReviewLeftContainer style={{ position: "sticky", top: "2px" }}>
            <ReviewTab onClick={this.returnSection("overview")}>
              {(() => {
                if (this.state.active_section_id === "overview") {
                  return <div style={{ float: "left", fontWeight: "600" }}>Overview</div>;
                } else {
                  return <div style={{ float: "left" }}>Overview</div>;
                }
              })()}
              <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                <IoIosArrowForward />
              </div>
              <div style={{ clear: "both" }}></div>
            </ReviewTab>
            {this.state.review_json.sections.map((section, index) => {
              return (
                <ReviewTab key={index} style={{ marginTop: "2px" }} onClick={this.returnSection(section.id)}>
                  {(() => {
                    if (this.state.active_section_id === section.id) {
                      return <div style={{ float: "left", fontWeight: "600" }}>{section.name}</div>;
                    } else {
                      return <div style={{ float: "left" }}>{section.name}</div>;
                    }
                  })()}
                  <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                    <IoIosArrowForward />
                  </div>
                  <div style={{ clear: "both" }}></div>
                </ReviewTab>
              );
            })}
            <ReviewTab style={{ marginTop: "2px" }} onClick={this.returnSection("qaoverview")}>
              {(() => {
                if (this.state.active_section_id === "qaoverview") {
                  return <div style={{ float: "left", fontWeight: "600" }}>QA Summary</div>;
                } else {
                  return <div style={{ float: "left" }}>QA Summary</div>;
                }
              })()}
              <div style={{ float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px" }}>
                <IoIosArrowForward />
              </div>
              <div style={{ clear: "both" }}></div>
            </ReviewTab>
            <AnswerHr />
            <QStatus>
              <div style={{ float: "left", width: "80%" }}>Questions Answered</div>
              <div style={{ float: "left", width: "20%", textAlign: "right" }}>
                {(() => {
                  let total_questions = 0;
                  let answered_questions = 0;
                  this.state.review_json.sections.forEach((section) => {
                    // total_questions = total_questions + section.questions.length;
                    section.questions.forEach((question) => {
                      if (question.answer.text !== "") {
                        answered_questions = answered_questions + 1;
                      }
                      total_questions = question.included ? total_questions + 1 : total_questions;
                    });
                  });
                  return answered_questions + "/" + total_questions;
                })()}
              </div>
              <div style={{ clear: "both" }}></div>
            </QStatus>
            <QStatus style={{ backgroundColor: "#D7686B" }}>
              <div style={{ float: "left", width: "80%" }}>Questions Reviewed</div>
              <div style={{ float: "left", width: "20%", textAlign: "right" }}>
                {(() => {
                  let total_questions = 0;
                  let qa_questions = 0;
                  this.state.review_json.sections.forEach((section) => {
                    // total_questions = total_questions + section.questions.length;
                    section.questions.forEach((question) => {
                      if (question.is_qa) {
                        qa_questions = qa_questions + 1;
                      }
                      // qa_questions = question.qa_data.answer.text !== "" ? qa_questions + 1 : qa_questions;
                      total_questions = total_questions + 1;
                    });
                  });
                  return qa_questions + "/" + total_questions;
                })()}
              </div>
              <div style={{ clear: "both" }}></div>
            </QStatus>
            {(() => {
              if (this.state.review_json.scoring.scoring_enabled === false) {
                return <NonScoreResult data={this.state.qa_non_score_result} />;
              }
            })()}
            {(() => {
              if (this.state.review_json.scoring.scoring_enabled && this.state.score_progression_enabled) {
                return (
                  <div style={{ width: "100%", marginTop: "20px" }}>
                    {(() => {
                      let scores = this.state.review_json.scoring.scores;
                      let proghtml = [];
                      for (let i = 0; i < scores.length; i++) {
                        let rgb = this.hexToRgb(scores[i].color);
                        rgb = "rgb(" + rgb.toString() + ",0.3)";
                        console.log("RGB:", rgb);
                        if (i === 0) {
                          if (this.state.qc_score_result.scoresobtained <= scores[i].lbound) {
                            const fadedwidth = scores[i].ubound - scores[i].lbound;
                            proghtml.push(<div style={{ height: "10px", width: fadedwidth.toString() + "%", backgroundColor: rgb, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", float: "left" }}></div>);
                          } else if (this.state.qc_score_result.scoresobtained >= scores[i].ubound) {
                            const solidwidth = scores[i].ubound - scores[i].lbound;
                            proghtml.push(
                              <div style={{ height: "10px", width: solidwidth.toString() + "%", backgroundColor: scores[i].color, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", float: "left" }}></div>
                            );
                          } else {
                            const solidwidth = this.state.qc_score_result.scoresobtained - scores[i].lbound;
                            const fadedwidth = scores[i].ubound - this.state.qc_score_result.scoresobtained;
                            proghtml.push(
                              <div style={{ height: "10px", width: solidwidth.toString() + "%", backgroundColor: scores[i].color, borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", float: "left" }}></div>
                            );
                            proghtml.push(<div style={{ height: "10px", width: fadedwidth.toString() + "%", backgroundColor: rgb, float: "left" }}></div>);
                          }
                        } else if (i === scores.length - 1) {
                          if (this.state.qc_score_result.scoresobtained > scores[i].lbound) {
                            if (this.state.qc_score_result.scoresobtained === scores[i].ubound) {
                              const solidwidth = scores[i].ubound - scores[i].lbound;
                              proghtml.push(
                                <div
                                  style={{
                                    height: "10px",
                                    width: solidwidth.toString() + "%",
                                    backgroundColor: this.state.qc_score_result.ragratingcolor,
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    float: "left",
                                  }}
                                ></div>
                              );
                            } else {
                              const solidwidth = this.state.qc_score_result.scoresobtained - scores[i].lbound;
                              const fadedwidth = scores[i].ubound - this.state.qc_score_result.scoresobtained;
                              proghtml.push(<div style={{ height: "10px", width: solidwidth.toString() + "%", backgroundColor: this.state.qc_score_result.ragratingcolor, float: "left" }}></div>);
                              proghtml.push(<div style={{ height: "10px", width: fadedwidth.toString() + "%", backgroundColor: rgb, borderTopRightRadius: "10px", borderBottomRightRadius: "10px", float: "left" }}></div>);
                            }
                          } else {
                            const fadedwidth = scores[i].ubound - scores[i].lbound;
                            proghtml.push(<div style={{ height: "10px", width: fadedwidth.toString() + "%", backgroundColor: rgb, borderTopRightRadius: "10px", borderBottomRightRadius: "10px", float: "left" }}></div>);
                          }
                        } else {
                          if (this.state.qc_score_result.scoresobtained < scores[i].lbound) {
                            const fadedwidth = scores[i].ubound - scores[i].lbound;
                            proghtml.push(<div style={{ height: "10px", width: fadedwidth.toString() + "%", backgroundColor: rgb, float: "left" }}></div>);
                          } else if (this.state.qc_score_result.scoresobtained >= scores[i].ubound) {
                            const solidwidth = scores[i].ubound - scores[i].lbound;
                            proghtml.push(<div style={{ height: "10px", width: solidwidth.toString() + "%", backgroundColor: scores[i].color, float: "left" }}></div>);
                          } else {
                            const solidwidth = this.state.qc_score_result.scoresobtained - scores[i].lbound;
                            const fadedwidth = scores[i].ubound - this.state.qc_score_result.scoresobtained;
                            proghtml.push(<div style={{ height: "10px", width: solidwidth.toString() + "%", backgroundColor: scores[i].color, float: "left" }}></div>);
                            proghtml.push(<div style={{ height: "10px", width: fadedwidth.toString() + "%", backgroundColor: rgb, float: "left" }}></div>);
                          }
                        }
                      }
                      return proghtml;
                    })()}
                    <div style={{ clear: "both" }}></div>
                  </div>
                );
              }
            })()}
            <div style={{ marginTop: "15px" }}>
              {this.state.review_json.scoring.scores.map((score) => {
                return (() => {
                  if (this.state.score_progression_enabled && this.state.review_json.scoring.scoring_enabled) {
                    return (
                      <div style={{ width: "100%", marginTop: "10px" }}>
                        <div style={{ width: "10%", float: "left" }}>
                          <div style={{ width: "10px", backgroundColor: score.color, height: "10px", borderRadius: "50%" }}></div>
                        </div>
                        <div style={{ width: "calc(90% - 15px)", float: "left", color: score.color, fontSize: "10px", fontWeight: "600" }}>
                          {score.actual_outcome}&nbsp;({score.ubound}%)
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    );
                  }
                })();
              })}
            </div>
          </ReviewLeftContainer>
          <ReviewRightContainer>
            {(() => {
              if (this.state.active_section_id === "overview") {
                return (
                  <AnswerOverviewQa
                    task_json={this.state.task_json}
                    review_json={this.state.review_json}
                    payload={this.state.payload}
                    task_id={this.state.task_id}
                    reloadChildTasks={this.reloadChildTasks}
                    readonly={this.props.readonly === undefined ? false : this.props.readonly}
                    addFutureFollowup={this.addFutureFollowup}
                    removeFutureFollowup={this.removeFutureFollowup}
                  />
                );
              } else if (this.state.active_section_id === "qaoverview") {
                return (
                  <QAOverview
                    task_json={this.state.task_json}
                    section_payload={this.state.section_payload}
                    payload={this.state.payload}
                    task_id={this.state.task_id}
                    handleComment={this.handleComment}
                    comment={this.state.qc_score_result.comment}
                    reloadChildTasks={this.reloadChildTasks}
                    handleQcAssessmentResult={this.handleQcAssessmentResult}
                    readonly={this.props.readonly === undefined ? false : this.props.readonly}
                    addFutureFollowup={this.addFutureFollowup}
                    removeFutureFollowup={this.removeFutureFollowup}
                  />
                );
              } else {
                return (
                  <AnswerQaSection
                    sections={this.state.review_json.sections}
                    section_payload={this.state.section_payload}
                    score_result={this.state.score_result}
                    qc_score_result={this.state.qc_score_result}
                    qa_non_score_result={this.state.qa_non_score_result}
                    scoring={this.state.review_json.scoring}
                    active_section_id={this.state.active_section_id}
                    review_json={this.state.review_json}
                    modifySections={this.modifySections}
                    modifyNonScoreSections={this.modifyNonScoreSections}
                    saveDisputed={this.saveDisputed}
                    readonly={this.props.readonly === undefined ? false : this.props.readonly}
                  />
                );
              }
            })()}
          </ReviewRightContainer>
          <div style={{ clear: "both" }}></div>
        </ReviewBodyContainer>
        <ReviewFooter>
          <CancelBtn onClick={this.props.closeQcView}>Cancel</CancelBtn>
          {
            // (() => {
            //     if (this.state.active_section_id === "overview"){
            //         return <SaveBtn onClick={this.returnSection(this.state.review_json.sections[0].id)} style={{marginLeft: "10px"}}>Start QA</SaveBtn>

            //     }else if(this.state.active_section_id === "qaoverview") {
            //         return <SaveBtn style={{marginLeft: "10px"}} onClick={this.CompletQC}>Submit QA</SaveBtn>
            //     }else{
            //         return <SaveBtn style={{marginLeft: "10px"}}>Next</SaveBtn>
            //     }

            // })()
            (() => {
              if (this.state.active_section_id === "qaoverview") {
                if ("readonly" in this.props) {
                  if (this.props.readonly === false) {
                    return (
                      <SaveBtn style={{ marginLeft: "10px" }} onClick={this.CompletQC}>
                        Submit QA
                      </SaveBtn>
                    );
                  }
                } else {
                  return (
                    <SaveBtn style={{ marginLeft: "10px" }} onClick={this.CompletQC}>
                      Submit QA
                    </SaveBtn>
                  );
                }
              }
            })()
          }
        </ReviewFooter>
      </div>
    );
  }
}

export default QcView;
