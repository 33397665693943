import React from 'react';
import styled from 'styled-components';

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;
const OkButton = styled.button`
    box-sizing: border-box;
    background-color: #04ACA7;
    color: #ffffff;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    font-size: 11px;
    cursor: pointer;
    font-size: 18px;
    min-width: 100px;
`;
const AlertBoxContainer = styled.div`
	position: fixed;
	top: 40vh;
	left: 40vw;
    width: 450px;
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #F7F7F7;
    color: #2D2D2D;
    border-radius: 10px;
    border-style: solid;
    border-color: #F7F7F7;
    z-index: 2000;
`;
const AlertBoxHeader = styled.div`
    width: calc(100% - 15px);
    background-color: #F7F7F7;
    color: #646A7E;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    height: 35px;
    padding-left: 15px;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 600;
`;
const AlertBoxBody = styled.div`
    width: calc(100% - 30px);
    background-color: #F7F7F7;
    color: #646A7E;
    font-size: 15px;
    min-height: 100px;
    text-align: center;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px
`;
const AlertBoxFooter = styled.div`
    width: calc(100% - 15px);
    background-color: #EDF1F2;
    color: #2D2D2D;
    height: 50px;
    padding-right: 15px;
    padding-top: 10px;
`;
const Section80 = styled.div`
    width: 80%;
    box-sizing: border-box;
    float: left;
    padding-left: 10px;
`;
const Section20 = styled.div`
    width: 20%;
    box-sizing: border-box;
    float: left;
    padding-right: 10px;
`;
const ClearFix = styled.div`
    clear: both;
`;
const CheckContainer = styled.div`
    float: left;
    height: 20px;
    display: table;
`;
const CheckLabel = styled.div`
    display: table-cell;
    vertical-align: middle;
`;
const CheckedRadio = styled.div`
    height: 10px;
    width: 10px;
    background-color: #00B0F0;
    border-radius: 50%;
    border: 2px solid #000000;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
`;
const UnCheckedRadio = styled.div`
    height: 10px;
    width: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid #000000;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
`;

class InfoPrompt extends React.Component {
    
    state = {suppress_own_mail: false, suppress_all_mail: false};
    
    handleCheck = (entity) => (event) => {
        event.preventDefault();
        this.setState({[entity]: this.state[entity] === false ? true : false});
    }
    
    submit = (event) => {
        event.preventDefault();
        this.props.actualSubmit(JSON.parse(JSON.stringify(this.state)));
    }
    
    render () {
        return (
            <div>
                <InactiveOverlay />
                <AlertBoxContainer>
                	<AlertBoxHeader>Bulk Assign {this.props.review_count} new File Reviews</AlertBoxHeader>
                	<AlertBoxBody>
                	    {this.props.review_count} File Reviews will be imported and assigned. This action cannot be reversed.
                	    <br />
                	    Please confirm you would like to assign these file reviews.
                	</AlertBoxBody>
                	<AlertBoxFooter>
                	    <Section80>
                	        {
                	            this.state.suppress_own_mail === true
                	            ?
                	            <CheckContainer><CheckedRadio onClick={this.handleCheck("suppress_own_mail")}></CheckedRadio></CheckContainer>
                	            :
                	            <CheckContainer><UnCheckedRadio onClick={this.handleCheck("suppress_own_mail")}></UnCheckedRadio></CheckContainer>
                	        }
                	        <CheckContainer style={{marginLeft: "5px"}}><CheckLabel>Suppress my email notifications</CheckLabel></CheckContainer>
                	        <ClearFix></ClearFix>
                	        {
                	            this.state.suppress_all_mail === true
                	            ?
                	            <CheckContainer><CheckedRadio onClick={this.handleCheck("suppress_all_mail")}></CheckedRadio></CheckContainer>
                	            :
                	            <CheckContainer><UnCheckedRadio onClick={this.handleCheck("suppress_all_mail")}></UnCheckedRadio></CheckContainer>
                	        }
                	        <CheckContainer style={{marginLeft: "5px"}}><CheckLabel>Suppress all email notifications</CheckLabel></CheckContainer>
                	        <ClearFix></ClearFix>
                	    </Section80>
                	    <Section20><OkButton onClick={this.submit}>Ok</OkButton></Section20>
                	    <ClearFix></ClearFix>
                	</AlertBoxFooter>
                </AlertBoxContainer>
            </div>
        )
    }
}

export default InfoPrompt;