import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";
import Store from '../../Common/Store';
import AdvanceFilter from '../Register/CSLRegister/AdvanceFilter';
import APICall from '../../Common/APICall';

import "./table_styles.css";

const HeaderContainer = styled.div`
    background-color: #1E3E62;
    color: #ffffff;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 15px;
    margin-top: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;
const QaAssignBtn = styled.button`
    background-color: #002060;
    color: #ffffff;
    padding: 10px 46px;
    border: 1px solid #002060;
    border-radius: 5px;
    float: right;
    margin: 15px;
    cursor: pointer;
`

const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
    float: left;
    margin-right: 5px;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;

const TableContainer = styled.div`
    background-color: #ffffff;
`

class CSLTable extends React.Component
{
    state = {toggle_state: true};

    constructor(props) {
        super(props);
        // console.log("props", props);
    }

    componentDidMount() {
        if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state});
    }

    componentDidUpdate(prev_props) {
        if (this.props !== prev_props) {
            if ("toggle_state" in this.props) this.setState({toggle_state: this.props.toggle_state});
        }
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }

    openAssignQcModal = (event) => {
        event.preventDefault();
        this.props.toggleAssignQcModal("assignqcmodal");
    }

    exportWithCustomFields = (result) => {
        // console.log("exportWithCustomFields function fired");
        // console.log(result);
        let tableData = this.props.processData();
        console.log("pending_tasks tableData",tableData)
        let data= tableData.data;
        // console.log("data:", data);
        let fileName = "export";
        if('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }

        let headerCSV = tableData.export_columns;
        if (Object.keys(result.headers).length !== 0) {
            for (let key in result.headers) {
                headerCSV[key.toString()] = result.headers[key.toString()];
            }
            for (let item of data) {
                for (let key in result.data[item.id.toString()]) {
                    item[key.toString()] = result.data[item.id.toString()][key.toString()];
                }
                for (let key in result.headers) {
                    if (key in item === false) item[key.toString()] = "N/A";
                }
            }
        }


        // console.log("headerCSV",headerCSV);
        let columns= tableData.columns;
        let csv_data_arr = [];
        
        csv_data_arr.unshift(headerCSV);
        // console.log("data:", data);
        for(let k = 0; k < data.length; k++) {
            let cont = {};
            // console.log("k:", k);
            // console.log("data[k]:", data[k]);
            // console.log("id:", data[data.length - 1].id);
            Object.entries(headerCSV).forEach(([key, value]) => {
                // console.log("key:", key);
                // console.log("data[k][key]",data[k][key])
                    // cont[key] = data[k][key].replace(/,/g, ' ');
                    cont[key] = data[k][key] === null ? "N/A" : data[k][key].toString().replace(/\"/g, "").replace(/,/g, ' ').replace(/-/g, '-');
            })            
            csv_data_arr.push(cont);
            // console.log("cont:", cont);
        }
        // console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
            var container = [];
            container = Object.values(csv_data_arr[j])
            if(container.length > 0); 
            csv_data_final_arr.push(container)
        }
        console.log("csv_data_final_arr",csv_data_final_arr)
        let csvContent = csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        //link.setAttribute("href", encodedUri);
        link.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(csvContent));
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); 
        link.click();
        // console.log("exportWithCustomFields");
    }

    exportToCSV = () => (event) => {
        event.preventDefault();
        let tableData = this.props.processData();
        let data= tableData.data;
        console.log("data:", data);
        let get_custom_fields = false;
        if ("custom_fields" in this.props) {
            if (this.props.custom_fields === true) {
                get_custom_fields = true;
            }
        }
        console.log("get_custom_fields:", get_custom_fields);
        if (get_custom_fields === true) {
            let task_ids = [];
            for (let item of data) {
                task_ids.push(item.id);
            }
            const api = new APICall();
            const payload = {command: "list_custom_fields", task_ids};
            api.command(payload, this.exportWithCustomFields);
        } else {
            let fileName = "export";
            if('export_file_name' in this.props) {
                fileName = this.props.export_file_name;
            }
            // let tableData = this.props.processData();

            let headerCSV = tableData.export_columns;
            console.log("headerCSV",headerCSV)
            // let data= tableData.data;
            let columns= tableData.columns;
            let csv_data_arr = [];
            
            csv_data_arr.unshift(headerCSV);
            for(let k = 0; k < data.length; k++) {
                let cont = {};
                
                Object.entries(headerCSV).forEach(([key, value]) => {
                    // console.log("data[k][key]",data[k][key])
                        // cont[key] = data[k][key].replace(/,/g, ' ');
                        cont[key] = data[k][key] === null ? "N/A" : data[k][key].replace(/,/g, ' ').replace(/-/g, '-');
                })            
                csv_data_arr.push(cont);
            }
            console.log("csv_data_arr",csv_data_arr) 
            var csv_data_final_arr = [];
            for(let j = 0; j<  csv_data_arr.length; j++){
            var container = [];
            container = Object.values(csv_data_arr[j])
            if(container.length > 0); 
            csv_data_final_arr.push(container)
            }
            console.log("csv_data_final_arr",csv_data_final_arr)
            let csvContent = csv_data_final_arr.map(e => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            //link.setAttribute("href", encodedUri);
            link.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(csvContent));
            link.setAttribute("download", fileName + ".csv");
            document.body.appendChild(link); 
            link.click();
            console.log("exportToCSV:");
        }
    
    }

    goToReport = () => (event) => {
        event.preventDefault();
        this.props.goToReport();
    }


	render() {
        // console.log('this.props', this.props.prefered_columns);
        
		let tableData = this.props.processData();
		// console.log('tableDataCSL', tableData);
        let row_count = this.props.tableRows === null ? 10 : this.props.tableRows;
		return (
			<TableContainer>
                {
                    (() => {
                        if ("show_header" in this.props && this.props.show_header === false) {
                            return (<div></div>);
                        } else {
                            return (
                                <HeaderContainer>
                                    <div style={{float: "left", marginTop: "toggle_text" in this.props ? "4px" : "0px"}}>{this.props.headerText}</div>
                                    {
                                        (() => {
                                            if ("toggle_text" in this.props === true) {
                                                return (
                                                    <div style={{float: "right"}}>
                                                        <div style={{float: "left", marginTop: "4px"}}>{this.props.toggle_text}</div>
                                                        <div style={{float: "right", marginLeft: "5px"}}>
                                                        {
                                                            (() => {
                                                                if (this.state.toggle_state === true) {
                                                                    return (
                                                                        <div>
                                                                            <SelectedRadio>Yes</SelectedRadio>
                                                                            <UnselectedRadio onClick={this.props.toggleCallback}>No</UnselectedRadio>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <div>
                                                                            <UnselectedRadio onClick={this.props.toggleCallback}>Yes</UnselectedRadio>
                                                                            <SelectedRadio>No</SelectedRadio>
                                                                        </div>
                                                                    );
                                                                }
                                                            })()
                                                        }
                                                        </div>
                                                        <div style={{clear: "both"}}></div>
                                                    </div>
                                                );
                                            }
                                        })()
                                    }
                                    <div style={{clear: "both"}}></div>
                                </HeaderContainer>
                            );
                        }
                    })()
                }
                {
                    (() => {
                        if (this.props.add) {
                            return (<TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>);
                        }
                    })()
                }
                {
                    (() => {
                        if ("filters" in this.props === true && this.state.toggle_state === true) {
                            return (
                                <AdvanceFilter 
                                    tableIndex={"tableIndex" in this.props ? this.props.tableIndex : "0" }
                                    filters={this.props.filters} 
                                    applyFilter={this.props.applyFilter} 
                                    clearAdvancedFilter={this.props.clearAdvancedFilter}
                                    show_all={true}
                                />
                            );
                        }
                    })()
                }
                                 
                    <div style={{marginTop: '5px', width:'100%'}}>
	                    <ReactTable
	                    	PaginationComponent={Pagination}
						    data={tableData.data}
						    columns={tableData.columns}
						    defaultPageSize={parseInt(row_count)}
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
                                const id = filter.pivotId || filter.id
                                // console.log('id',id);
                                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                            }}
						/>
                        {
                            (() => {
                                if ('export' in this.props && this.props.export === true) {                            
                                    return (<ExportButton  onClick={this.exportToCSV()}>Export</ExportButton>);                                                        
                                }
                            })()
                        }
                        {
                            (() => {
                                if ('report' in this.props && this.props.report === true) {                            
                                    return (<ExportButton  onClick={this.goToReport()}>Report</ExportButton>);                                                        
                                }
                            })()
                        }
                        <div style={{clear: "both"}}></div>
					</div>
                    {
                        (() => {
                            if(this.props.qcbutton && this.props.qa_enabled) {
                                // console.log("this qc button", this.props.qcbutton);
                                if (Store.getStoreData("role") === "qa_manager" || Store.getStoreData("role") === "senior_manager" || Store.getStoreData('role') === "admin_manager") {
                                    return (<QaAssignBtn onClick={this.openAssignQcModal}>Assign For QA</QaAssignBtn>);
                                }
                            }
                        })()
                    }
			</TableContainer>
			);
	}
}

export default CSLTable;