import React from 'react';
import '../MGRQuestions.css';
import styled from 'styled-components';
import {AiFillQuestionCircle} from 'react-icons/ai'
import { IoIosArrowUp, IoMdMore, IoIosArrowDown, IoIosTrash, IoIosAddCircle } from 'react-icons/io';
import DropdownQuestion from './DropdownQuestion';
import HtmlEditor from '../../../Common/HtmlEditor';
import Utils from '../../../../Common/Utils.js';

const MRModalLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 12px;
`;

const MRModalTextArea = styled.textarea`
    width: 100%;
    height: 90px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;

class Question extends React.Component {

    state = { ready: false, 
        question: {}, 
        current_weight: "0", 
        current_type: "0", 
        current_autofail: "0", 
        add_comments: { enabled: false, required: false },
        auto_scoring: { enabled: true, yes: 10, no: 0, hide_score: false },
        max_value: 10,
        guidance_note: true,
        sections: null,
        section_index: null,
        question_index: null,
        hidescore: false
    };

    constructor(props) {
        super(props);
    }

    handleHideScore = (event) => {
        // event.preventDefault();
        let {sections} = this.state;
        // alert(event.target.checked);
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].auto_scoring.hide_score = event.target.checked;
        //sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].auto_scoring.hide_score = sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].auto_scoring.hide_score ? false : true;
        this.props.modifySections(sections);
    }

    handleWeight = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].weight.forEach((item) => {
            if (item.value === event.target.value) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        })
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].auto_scoring.yes = event.target.value;
        this.props.modifySections(sections);
    }

    handleAutoScoringYes = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].auto_scoring.yes = event.target.value;
        this.props.modifySections(sections);
    }


    handleAutoScoringNo = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].auto_scoring.no = event.target.value;
        this.props.modifySections(sections);
    }


    handleType = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].type.forEach((item) => {
            if (item.value === event.target.value) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        })
        this.props.modifySections(sections);
    }

    handleAutofail = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].autofail.forEach((item) => {
            if (item.value === event.target.value) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        })
        this.props.modifySections(sections);
    }

    componentDidMount() {
        let current_autofail = "0";
        this.props.question.autofail.forEach((a) => {
            if (a.selected) {
                current_autofail = a.value;
            }
        })
        let current_type = "0";
        this.props.question.type.forEach((t) => {
            if (t.selected) {
                current_type = t.value;
            }
        })
        let current_weight = "0";
        this.props.question.weight.forEach((w) => {
            if (w.selected) {
                current_weight = w.value;
            }
        })
        this.setState({
            question : this.props.question,
            sections: this.props.sections,
            section_index: this.props.section_index,
            question_index: this.props.question_index,
            ready: true, current_type, current_weight, current_autofail});
    }
    componentDidUpdate(prevProps) {
        let current_type = "0";
        this.props.question.type.forEach((t) => {
            if (t.selected) {
                current_type = t.value;
            }
        })
        let current_weight = "0";
        this.props.question.weight.forEach((w) => {
            if (w.selected) {
                current_weight = w.value;
            }
        })
        let current_autofail = "0";
        this.props.question.autofail.forEach((a) => {
            if (a.selected) {
                current_autofail = a.value;
            }
        })
        if(this.props !== prevProps) this.setState({
            question : this.props.question,
            sections: this.props.sections,
            section_index: this.props.section_index,
            question_index: this.props.question_index,
            ready: true,
            current_type, current_weight, current_autofail })
    }

    toggleQuestion2 = (collapsed) => (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].collapsed = collapsed;
        this.props.modifySections(sections);
    }

    deleteQuestion = (question_id) => (event) => {
        event.preventDefault();
        const r = window.confirm("Are you sure you want to delete? This action cannot be undone");
        if(r == true){
            let {sections} = this.state;
            let rindex = 0;
            sections[parseInt(this.state.section_index)].questions.forEach((question, index) => {
                if (question.id === question_id) {
                    rindex = index;
                }
            })
            sections[parseInt(this.state.section_index)].questions.splice(rindex, 1);
            this.props.modifySections(sections);
        }
    }

    handleQChange = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].question = event.target.value;
        this.props.modifySections(sections);
    }

    handleDChange = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].guidance_note.text = event.target.value;
        this.props.modifySections(sections);
    }
    
    handleDChange_html = (data) => {
        //event.preventDefault();
        // console.log("handleDChange_html::",data);
        // console.log("handleDChange_b64::",btoa(window.encodeURIComponent(data)));
        // let decode_guidance = btoa(window.encodeURIComponent(data))
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].guidance_note.text = data;
        //console.log("handleDChange_sections::",sections);
        this.props.modifySections(sections);
    }

    saveQuestion = (event) => {
        event.preventDefault();
        alert("Your data has been saved");
        console.log(this.state)
    }

    handleAllowNAChange = (event) => {
        let {sections} = this.state;
        // console.log("event.target.checked", event.target.checked);
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].naallowed = event.target.checked;
        this.props.modifySections(sections);
    }

    handleAddCommentsChange = (event) => {
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].comments.enabled = event.target.checked;
        this.props.modifySections(sections);

    }

    handleAutoScoringChange = (event) => {
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].auto_scoring.enabled = event.target.checked;
        this.props.modifySections(sections);
    }

    handleGuidanceNoteChange = (event) => {
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].guidance_note.enabled = event.target.checked;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].guidance_note.text = event.target.checked ? sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].guidance_note.text : "";
        this.props.modifySections(sections);
    }

    handleCommentChange = (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(this.state.section_index)].questions[parseInt(this.state.question_index)].comments.required = event.target.value;
        this.props.modifySections(sections);
    }

    render() {
        var idx = this.props.idx;
        var index = this.props.index;
        var allowGuidancevalue = this.props.allowGuidancevalue;
        var allowNaquestion = this.props.allowNaquestion;
        if (!this.state.ready) {
            return (<div>Loading..</div>);
        }
        return(<div>
                {
                    (() => {
                        //console.log("idx::",this.props.modifySections)
                        if (this.state.question.collapsed) {
                            return (                                                       
                                <div key={idx} className="sectionpanelQuestion">
                                    <div className="questionSection">
                                    {/*<div className="headerleft">Question {idx + 1}</div>*/}
                                    <div className="headerleft">Question {this.state.question.s_index}</div>
                                        <div className="headerright addsectionright">
                                            <div className="addsectionplus" onClick={this.toggleQuestion2(false)}><IoIosArrowDown /></div>
                                            <div className="addsectionplus" onClick={this.deleteQuestion(this.state.question.id)}><IoIosTrash /></div>
                                            {/*<div className="addsectionplus"><IoMdMore /></div>*/}
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                    </div>                                                                
                                </div> 
                                );
                        }else {
                            return (
                                <div key={idx} className="sectionpanelQuestion">
                                <div className="questionSection">
                                    <div className="headerleft">Question {this.state.question.s_index}</div>
                                    <div className="headerright addsectionright">
                                        <div className="addsectionplus" onClick={this.toggleQuestion2(true)}><IoIosArrowUp /></div>
                                        <div className="addsectionplus" onClick={this.deleteQuestion(this.state.question.id)}><IoIosTrash /></div>
                                        {/*<div className="addsectionplus"><IoMdMore /></div>*/}
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                </div>
                                <div className="questionSectionBody"> 
                                    <div>
                                        <MRModalLabel>Question Text</MRModalLabel>
                                        <MRModalTextArea value={this.state.question.question} name="question" onChange={this.handleQChange}></MRModalTextArea>
                                    </div>
                                    <div style={{marginTop: '18px', width: "100%"}}>
                                        <div style={{float: "left", width: "33%"}}>
                                            <MRModalLabel>Type</MRModalLabel>
                                            <select className="mgrquestionSelect1" style={{width: "90%"}} onChange={this.handleType} defaultValue={this.state.current_type}>                                                                                                                    
                                            {
                                                this.state.question.type.map((opt, index) => {                                               
                                                    return (<option key={index} value={opt.value}>{opt.value}</option>);  
                                                })
                                            }                                                                       
                                            </select>
                                        </div>
                                        {
                                            (() => {
                                                if (this.props.scoring.scoring_enabled) {
                                                    return (
                                                        <div style={{float: "left", width: "33%"}}>
                                                            <MRModalLabel>Weight</MRModalLabel>
                                                            <select className="mgrquestionSelect2" style={{width: "90%"}} onChange={this.handleWeight} defaultValue={this.state.current_weight}>
                                                            {
                                                                this.state.question.weight.map((wt, index) => {
                                                                    return (<option key={index} value={wt.value}>{wt.value}</option>)
                                                                })
                                                            }                                                                      
                                                            </select>
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if (this.props.scoring.scoring_enabled) {
                                                    return (
                                                        <div style={{float: "left", width: "33%"}}>
                                                            <MRModalLabel>Autofail</MRModalLabel>
                                                            <select className="mgrquestionSelect2" style={{width: "90%"}} onChange={this.handleAutofail} defaultValue={this.state.current_autofail}> 
                                                            {
                                                                this.state.question.autofail.map((af, index) => {
                                                                    return (<option key={index} value={af.value}>{af.value}</option>);
                                                                })
                                                            }                                                                          
                                                            </select>
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                        <div style={{clear: "both"}}></div>
                                    </div>
                                    {
                                        (() => {
                                            const selected_question_type = this.state.question.type.find((t) => {
                                                return t.selected;
                                            })
                                            if (selected_question_type.value === "Dropdown") {
                                                return (
                                                    <DropdownQuestion
                                                        sections={this.state.sections}
                                                        question={this.state.question}
                                                        modifySections={this.props.modifySections}
                                                    />
                                                );
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            if(allowNaquestion) {
                                                return(                                    
                                                        <div style={{marginTop: '18px'}}>
                                                        <MRModalLabel>Allow N/A as response<span><AiFillQuestionCircle style={{color : '#BFE2E1', marginLeft: '10px'}}/></span></MRModalLabel>
                                                        <div style={{paddingLeft: '10px', marginTop: '10px'}}>
                                                            <label className="toggle">
                                                                <input type="checkbox" name="naallowed" checked={this.state.question.naallowed} onChange={this.handleAllowNAChange} />
                                                                <span className="slider round"></span>                                                
                                                            </label>
                                                        </div>
                                                    </div>
                                                    )
                                            }

                                        })()
                                    }

                                    {
                                       this.state.question.type.map((opt, tindex) => {
                                            if ((opt.value == 'Boolean' && opt.selected == true) || (opt.value == 'Numeric' && opt.selected == true) || (opt.value == 'Dropdown' && opt.selected == true)) {
                                                return (<div key={tindex}>
                                                            <div><hr style={{borderTop: '2px solid #e6e6e6'}}/></div>
                                                            <div style={{marginTop: '18px'}}>
                                                                <MRModalLabel>Add comments <span><AiFillQuestionCircle style={{color : '#BFE2E1', marginLeft: '10px'}}/></span></MRModalLabel>
                                                                <div style={{paddingLeft: '10px', marginTop: '10px'}}>
                                                                    <label className="toggle">
                                                                        <input type="checkbox" name="enabled" checked={this.state.question.comments.enabled} onChange={this.handleAddCommentsChange} />
                                                                        <span className="slider round" ></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {
                                                                (() => {
                                                                    if(this.state.question.comments.enabled)
                                                                    {
                                                                        return (
                                                                            <div>
                                                                                <select className="mgrquestionSelectSmall" defaultValue={this.state.question.comments.required} onChange={this.handleCommentChange}>                                                                      
                                                                                    <option value="true">Required</option>                                                                           
                                                                                    <option value="false">Optional</option>                                                                           
                                                                                </select>                                                                    
                                                                            </div>
                                                                        );
                                                                    }
                                                                })()
                                                            }
                                                        </div>);
                                            
                                            } 
                                        })
                                    }
                                    {
                                       this.state.question.type.map((opt, tindex) => {
                                        if ((opt.value == 'Boolean' && opt.selected == true && this.props.scoring.scoring_enabled) || (opt.value == 'Dropdown' && opt.selected == true && this.props.scoring.scoring_enabled)) {
                                            return (<div key={tindex}>
                                                <div><hr style={{borderTop: '2px solid #e6e6e6'}}/></div>
                                                <div style={{marginTop: '18px'}}>
                                                    <div style={{float: "left", width: "33%"}}>
                                                        <MRModalLabel>Allow Auto-Scoring? <AiFillQuestionCircle style={{color : '#BFE2E1', marginLeft: '10px'}}/></MRModalLabel>
                                                        <div style={{paddingLeft: '10px', marginTop: '10px'}}>
                                                            <label className="toggle">
                                                                <input type="checkbox" name="autoScoring" checked={this.state.question.auto_scoring.enabled} onChange={this.handleAutoScoringChange} />
                                                                <span className="slider round" ></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        (() => {
                                                            if(this.state.question.auto_scoring.enabled) {
                                                                return(
                                                                    <div style={{float: "left", width: "33%"}}>
                                                                    <MRModalLabel>Hide Scores</MRModalLabel>
                                                                    <div style={{paddingLeft: '10px', marginTop: '10px'}}>
                                                                        <label className="toggle">
                                                                            <input type="checkbox" name="autoScoring" checked={this.state.question.auto_scoring.hide_score} onChange={this.handleHideScore} />
                                                                            <span className="slider round" ></span>
                                                                        </label>
                                                                    </div>
                                                                </div>);
                                                            }

                                                        })()
                                                    }
                                                    <div style={{clear: "both"}}></div>
                                                </div>
                                                {
                                                    (() => {
                                                        const selected_question_type = this.state.question.type.find((qt) => {
                                                            return qt.selected;
                                                        })
                                                        if(this.state.question.auto_scoring.enabled && selected_question_type.value !== "Dropdown")
                                                        {
                                                            return (
                                                                <div>
                                                                    <div>
                                                                        <MRModalLabel style={{marginRight: '50px'}}>If Yes</MRModalLabel>                                                                        
                                                                        <select className="mgrquestionSelectSmall" onChange={this.handleAutoScoringYes} defaultValue={this.state.question.auto_scoring.yes}>
                                                                            {
                                                                                (() => {
                                                                                    let arr=[];
                                                                                    for(let i=0; i <= this.state.max_value; i++){
                                                                                        arr.push(<option key={i} value={i}>{i}</option>);
                                                                                    }
                                                                                    return arr;
                                                                                })()
                                                                            }                                                                  
                                                                        </select>                                                                    
                                                                    </div>
                                                                    <div>
                                                                    <MRModalLabel style={{marginRight: '56px'}}>If No</MRModalLabel>                                                                       
                                                                        <select className="mgrquestionSelectSmall" onChange={this.handleAutoScoringNo} defaultValue={this.state.question.auto_scoring.no}>                                                                          
                                                                        {
                                                                            (() => {
                                                                                let arr=[];
                                                                                for(let i=0; i <= this.state.max_value; i++){
                                                                                    arr.push(<option key={i} value={i}>{i}</option>);
                                                                                }
                                                                                return arr;
                                                                            })()
                                                                        }                                                                         
                                                                        </select>                                                                    
                                                                    </div>                                                                   
                                                                </div>
                                                            );
                                                        }
                                                    })()
                                                }
                                                
                                            </div>);
                                        } 
                                    })
                                            
                                        
                                    }
                                    {
                                        (() => {
                                            if(allowGuidancevalue != '0')
                                            {
                                                return(<div>
                                                    <div><hr style={{borderTop: '2px solid #e6e6e6'}}/></div>
                                                    <div style={{marginTop: '18px'}}>
                                                        <MRModalLabel>Add guidance notes <AiFillQuestionCircle style={{color : '#BFE2E1', marginLeft: '10px'}}/></MRModalLabel>
                                                        <div style={{paddingLeft: '10px', marginTop: '10px'}}>
                                                            <label className="toggle">
                                                                <input type="checkbox" name="guidancenote" checked={this.state.question.guidance_note.enabled} onChange={this.handleGuidanceNoteChange} />
                                                                <span className="slider round" ></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        (() => {
                                                            if(this.state.question.guidance_note.enabled)
                                                            {
                                                                let decoded_guidance_note = this.state.question.guidance_note.text;
                                                                if(Utils.isBase64(this.state.question.guidance_note.text)){
                                                                    decoded_guidance_note = window.decodeURIComponent(atob(decoded_guidance_note));
                                                                }
                                                                return (
                                                                    <div style={{marginTop: '18px'}}>                                                                    
                                                                        {/*
                                                                        <MRModalTextArea value={this.state.question.guidance_note.text} onChange={this.handleDChange} name="description" placeholder="Guidance Note"></MRModalTextArea>                                                                     
                                                                        */}
                                                                        
                                                                        <HtmlEditor 
                                                                        content={decoded_guidance_note} 
                                                                        content_type={'guidance_note'} 
                                                                        sections={this.props.sections} 
                                                                        sectionID={this.props.sectionID} 
                                                                        onChange={this.handleDChange_html} 
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        })()
                                                    }                                                    
                                                    </div>);
                                            }
                                        })()
                                    }
                                </div>
                            </div> 
                            );
                        }
                    })()                    
                }
        </div>);  
    }
}

export default Question;