import React from 'react';
import styled from 'styled-components';
import '../MGRQuestions.css';
import { IoIosArrowUp, IoMdMore, IoIosArrowDown, IoIosTrash, IoIosAddCircle, IoIosCheckboxOutline, IoIosSquareOutline,IoMdCreate } from 'react-icons/io';
import Question from './Question'
import Utils from '../../../../Common/Utils.js';

const NasecLabel = styled.div`
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 600;
    margin-top: 11px;
`;
const CheckboxDiv = styled.div`
    cursor: pointer;
    font-size: 20px;
    float: left;
    margin: 9px 0px 0px 4px;
`;

class Sections extends React.Component {

    state = { ready: false, section: {}, sections: [], allowGuidancevalue: false, can_be_deleted: false, allowNaquestion: false, allowNasection: false };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let can_be_deleted = this.props.sections.length > 1 ? true : false;
        this.setState({ allowGuidancevalue: this.props.allowGuidancevalue, section: this.props.item, sections: this.props.sections, ready: true, can_be_deleted, allowNaquestion: this.props.allowNaquestion,allowNasection: this.props.allowNasection })
    }

    componentDidUpdate(prevProps) {
        // console.log('reviewConfig in MGRScoring componentDidUpdate', this.props.reviewConfig)
        if(this.props !== prevProps) {
            let can_be_deleted = this.props.sections.length > 1 ? true : false;
            this.setState({ section : this.props.item, sections: this.props.sections, allowGuidancevalue: this.props.allowGuidancevalue, can_be_deleted, allowNaquestion: this.props.allowNaquestion,allowNasection: this.props.allowNasection })
        }
    }

    changeDisplay = (index, show) => (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(index)].type = show;
        this.props.modifySections(sections);
    }

    toggleSection = (index, show) => (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(index)].collapsed = show;
        this.props.modifySections(sections);
    }    

    changeSectionName = (index) => (event) => {
        event.preventDefault();
        // var value = event.target.value;
        // this.props.changeSectionName(index, value);
        let {sections} = this.state;
        sections[parseInt(index)].name = event.target.value;
        this.props.modifySections(sections);
    }

    toggleQuestion = (index, idx, show) => {
        //event.preventDefault();
        //console.log('Sourav');
        this.props.toggleQuestion(index, idx, show);        
    }

    handleSectionIncluded = (index) => (event) => {
        event.preventDefault();
        let {sections} = this.state;
        sections[parseInt(index)].sectionna = sections[parseInt(index)].sectionna ? false : true;
        this.props.modifySections(sections);
        // console.log("")
    }

    addQuestionBtnClick  = (index) => (event) => {
        event.preventDefault();
        // this.props.addQuestionBtnClick(index);
        const question = {
            id: Utils.genKey(6),
            collapsed: false,
            question: "",
            type: [
                { value: "Memo", selected: false },
                { value: "Boolean", selected: true },
                { value: "Alpha", selected: false },
                { value: "Numeric", selected: false },
                { value: "Dropdown", selected: false },
            ],                        
            weight: [
                { value: "0", selected: false },
                { value: "1", selected: true },
                { value: "2", selected: false },
                { value: "3", selected: false },
                { value: "4", selected: false },
                { value: "5", selected: false },
                { value: "6", selected: false },
                { value: "7", selected: false },
                { value: "8", selected: false },
                { value: "9", selected: false },
                { value: "10", selected: false }
            ],
            autofail: [
                { value: "Yes", selected: false },
                { value: "No", selected: true }
            ],
            naallowed: false,
            comments: { enabled: false, required: false },
            auto_scoring: { enabled: false, yes: 6, no: 0, hide_score: false },
            guidance_note: { enabled: this.state.allowGuidancevalue ? true : false, text: "" },
            preset_answers: [],
            answer: { text: "", comment: "" },                        
        }
        let {sections} = this.state;
        sections[parseInt(index)].questions.push(question);
        this.props.modifySections(sections);
    }

    deleteSection = (section_id) => (event) => {
        event.preventDefault();
        let {sections} = this.state;
        let dindex = 0;
        let good_to_delete = false;
        sections.forEach((section, index) => {
            if (section.id === section_id) {
                dindex = index;
                good_to_delete = section.questions.length === 0 ? true : false;
            }
        })
        console.log("index:", dindex);
        if (!good_to_delete) {
            alert("Section cannot be removed as there are questions attached to this section. Please remove all questions from this section and then remove the section");
            return false;
        }
        let dsections = [];
        sections.forEach((section, index) => {
            if (index !== dindex) {
                dsections.push(section);
            }
        })
        this.props.modifySections(dsections);
    }

    handleQChange = (qid) => (event) => {
        event.preventDefault();
        console.log(qid);
        console.log("Mandatory:", event.target.value);
    }

    allowNA = (show,idx,index) => (event) => {
        event.preventDefault();
        console.log("Allow NA:", show,idx,index);
        this.props.allowNA(show,idx,index);
    }

    render() {   
        var item = this.props.item;
        var index = this.props.index;
        if (!this.state.ready) {
            return (<div>Loading..</div>);
        }     
        return (<div>
                    {

                        (() => {
                            if (this.state.section.collapsed) {
                                return (
                                    
                                    <div key={index} className="sectionpanel">
                                       <div className="sectionpanelheader">
                                            <div className="headerleft" >
                                            
                                                {
                                                    (() => {
                                                        if(this.state.section.type === "label") {
                                                            return (
                                                                <span onClick={this.changeDisplay(index, "input")}>{this.state.section.name}<IoMdCreate /></span>
                                                            );
                                                        } else {
                                                            return (
                                                                <input type="text" className="sectioninput" value={this.state.section.name} onChange={this.changeSectionName(index)} onBlur={this.changeDisplay(index, "label")} />
                                                            );
                                                        }
                                                        
                                                    })()
                                                }
                                                
                                            </div>
                                            <div className="headercenter">
                                            {
                                                (() => {
                                                    if(this.state.allowNasection) {
                                                        if (this.state.section.sectionna) {
                                                            return (
                                                                <div>
                                                                    <NasecLabel style={{float: "left"}}>Allow NA Section</NasecLabel>
                                                                    <CheckboxDiv onClick={this.handleSectionIncluded(index)}>
                                                                        <IoIosCheckboxOutline />
                                                                    </CheckboxDiv>
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div>
                                                                    <NasecLabel style={{float: "left"}}>Allow NA Section</NasecLabel>
                                                                    <CheckboxDiv onClick={this.handleSectionIncluded(index)}>
                                                                        <IoIosSquareOutline />
                                                                    </CheckboxDiv>
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>
                                                            );
                                                        }
                                                    }

                                                })()
                                            }
                                            </div>
                                            <div className="headerright">
                                                <div style={{ display: "inline-block", fontSize: "20px", cursor: "pointer", padding: "10px" }} onClick={this.toggleSection(index, false)}><IoIosArrowDown /></div>
                                                {
                                                    (() => {
                                                        if (this.state.can_be_deleted) {
                                                            return (
                                                                <div style={{ display: "inline-block", fontSize: "20px", cursor: "pointer", paddingRight: "10px" }} onClick={this.deleteSection(this.state.section.id)}><IoIosTrash /></div>
                                                            );
                                                        }
                                                    })()
                                                }
                                                {/*<div style={{ display: "inline-block", paddingTop: "7px", fontSize: "24px" }}><IoMdMore /></div>*/}
                                            </div>
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index} className="sectionpanel">
                                        <div className="sectionpanelheader">
                                            <div className="headerleft">
                                                {
                                                    (() => {
                                                        if(this.state.section.type === "label") {
                                                            return (
                                                                <span onClick={this.changeDisplay(index, "input")}>{this.state.section.name}<IoMdCreate /></span>
                                                            );
                                                        } else {
                                                            return (
                                                                <input type="text" className="sectioninput" value={this.state.section.name} onChange={this.changeSectionName(index)} onBlur={this.changeDisplay(index, "label")} />
                                                            );
                                                        }
                                                    })()
                                                }
                                            </div>
                                            <div className="headercenter">
                                            {
                                                (() => {
                                                    if(this.state.allowNasection) {
                                                        if (this.state.section.sectionna) {
                                                            return (
                                                                <div>
                                                                    <NasecLabel style={{float: "left"}}>Allow NA Section</NasecLabel>
                                                                    <CheckboxDiv onClick={this.handleSectionIncluded(index)}>
                                                                        <IoIosCheckboxOutline />
                                                                    </CheckboxDiv>
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div>
                                                                    <NasecLabel style={{float: "left"}}>Allow NA Section</NasecLabel>
                                                                    <CheckboxDiv onClick={this.handleSectionIncluded(index)}>
                                                                        <IoIosSquareOutline />
                                                                    </CheckboxDiv>
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>
                                                            );
                                                        }
                                                    }

                                                })()
                                            }
                                            </div>
                                            <div className="headerright">
                                                <div style={{ display: "inline-block", fontSize: "20px", cursor: "pointer", padding: "10px" }} onClick={this.toggleSection(index, true)}><IoIosArrowUp /></div>
                                                {
                                                    (() => {
                                                        if (this.state.can_be_deleted) {
                                                            return (
                                                                <div style={{ display: "inline-block", fontSize: "20px", cursor: "pointer", paddingRight: "10px" }} onClick={this.deleteSection(this.state.section.id)}><IoIosTrash /></div>
                                                            );
                                                        }
                                                    })()
                                                }
                                                {/*<div style={{ display: "inline-block", paddingTop: "7px", fontSize: "24px" }}><IoMdMore /></div>*/}
                                            </div>
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                        <div className="sectionpanelbody">
                                        {
                                                this.state.section.questions.map((question, idx) => {
                                                    return(
                                                        <Question
                                                            key={idx}
                                                            question={question}
                                                            idx={idx}
                                                            index={index}
                                                            scoring={this.props.scoring}
                                                            toggleQuestion={this.toggleQuestion}
                                                            allowGuidancevalue={this.state.allowGuidancevalue}
                                                            allowNaquestion={this.state.allowNaquestion}
                                                            handleQChange={this.handleQChange}
                                                            modifyQuestion={this.props.modifyQuestion}
                                                            allowNA={this.allowNA}
                                                            sections={this.state.sections}
                                                            section_index={index}
                                                            question_index={idx}
                                                            modifySections={this.props.modifySections}>
                                                        </Question>
                                                    );
                                                })
                                        }
                                         <button className="addQuestionButton" onClick={this.addQuestionBtnClick(index)}>Add Question</button>
                                        </div>                                        
                                    </div>
                                );
                            }
                        })()
                    }

                                 
            
        </div>);
    }

}

export default Sections;