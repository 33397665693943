import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import {FaTimes} from 'react-icons/fa';
// import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import Store from '../Common/Store';
import Utils from '../Common/Utils';
import APICall from '../Common/APICall';
import CSLTable from './Common/CSLTable';
import DatePicker from 'react-date-picker';

const HeaderButton = styled.div`
    margin-left:10px;
    color: #ffffff;
    width: 50px;
    height: 50px;
    float: right;
    font-size:30px;
    cursor: pointer;
    margin-top:-5px;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;

const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 11px;
    float: right;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #37ada7;
    margin-left: 5px;
    font-size: 11px;
    margin-left: 10px;
    float: right;
`;
const MRModalSelect = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #b5b5b5;
`;
const FCKCommentDiv = styled.div`
    padding-left: 20px;
    padding-right: 8px;
    color: #a0a0a0;
`;
const FCKTextarea = styled.textarea`
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
    font-weight: 100;
`;

const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
const ScoreContainer = styled.div`
    background-color: #ecf0f1;
    width: 100%;
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
`

const ScoreHeaderContainer = styled.div`
    background-color: #36aba6;
    width: 96%;
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 2px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`

const ScoreBodyContainer = styled.div`
    padding: 15px;
`

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;


class TasksList extends React.Component
{
	state = { tasks: null, ready: null, followup_sub: '', assigned_to: 0, followup_note: '', due_by: new Date(), review_list: [], parent_task_id: 0 }

	processGetTask = (result) => {
		// console.log('this.props.revieweeList', this.props.revieweeList[0].ID)
		let tasks = result.result.task.childtask.result

		this.setState({tasks:tasks, ready:true, review_list: this.props.revieweeList, assigned_to: this.props.revieweeList[0].ID, parent_task_id: this.props.taskId})
	}

	componentDidMount() {
		let postData = {command: 'get_task', task_id: this.props.taskId};
        let api = new APICall();
        api.command(postData, this.processGetTask)

	}

    processTasksData = () => {
        // console.log('data', this.props.data);
        // console.log('tasks', this.state.tasks)
        
        // let pending_tasks = Store.getStoreData('fc_tasks');
        let contacts = Store.getStoreData('reviewee_info');
        // console.log('contacts', contacts)
        // let cardata = Store.getStoreData('cardata').faq_tasks;
        let ret = {data: [], columns: []};
        let i = 0;
        ret.columns =[  {Header: '#', accessor: 'index', width: 90, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Assigned to', accessor: 'assigned_to', width: 400, headerStyle: {textAlign: 'left'}}, 
                        {Header: 'Status', accessor: 'status', minWidth: 180, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}, 
                     ];

        for(let d of this.state.tasks) {
            // console.log('data element->', d);
            // let task_json = JSON.parse(d.task_json);
            // console.log('data json->', task_json);
            i++;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            // console.log('day, month, year', dd,mm,yyyy)
            var due_date = new Date(yyyy, mm-1, dd);
            let today = new Date();

            let text = due_date > today ? 'Pending' : 'Overdue';
            let color = due_date > today ? '#2E8B57' : '#FF0000';

            let elem = {'index' : i,
                    'assigned_to' : contacts[d.cur_assigned_to.toString()],
                    'status' : {text: text, color: color}
                   };
            ret.data.push(elem);
        }
        return ret;
    }

    closeFollowupTaskModal = (event) => {
        // event.preventDefault()
        this.setState({followup_sub: '', assigned_to: 0, followup_note: '', due_by: new Date(), review_list: this.props.revieweeList});
    	this.props.closeFollowupTaskModal()
    }

    processAddTask = (result) => {
        alert("Followup task successfully added to this Filecheck");
        this.setState({followup_sub: '', assigned_to: 0, followup_note: '', due_by: new Date(), review_list: this.props.revieweeList});
        let postData = {command: 'get_task', task_id: this.props.taskId};
        let api = new APICall();
        api.command(postData, this.processGetTask)
    	this.props.closeFollowupTaskModal();
    }

    addTask = () => {
        let ref_id = Utils.genKey(12);
        // alert(Store.getStoreData('current_task_id'));
        let task = {
            "task_data": { "module_id": "filechecker", "last_action": "COMP_FCK_FOLLOWUP", "ref_id" : ref_id,"actiondate": Date.now(), "cur_assigned_to" : this.state.assigned_to, 'task_type' : 'COMP_FCK_FOLLOWUP', 'parent_task_id' : this.state.parent_task_id, 'cur_lane': 'COMP_FCK_FOLLOWUP_ASSIGNMENT', due_date: this.state.due_by},
            "object_data": {subject: this.state.followup_sub, note: this.state.followup_note},
            "action_data": [{ "action": "COMP_FCK_FOLLOWUP", "actiondate": Date.now(), "assigned_to": this.state.assigned_to, "cur_lane":"COMP_FCK_FOLLOWUP_ASSIGNMENT", due_by: this.state.due_by, "note": this.state.followup_note }]
        };
        let postData = { "command": "createfollowuptask", "task": task };
        // console.log("task follow up", task);
        let api = new APICall();
        api.command(postData, this.processAddTask);
    }

    handleChange = (evt) => {
        // const value = evt.target.value;
        this.setState({[evt.target.name]: evt.target.value});
    }

    onDateChangeDueBy = (date) => {
        this.setState({due_by: date});
    }

    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({followup_note: event.target.value});
    }

	render() {
		if(this.state.ready === null) {
			return <div>Loading...</div>
		}
		// console.log('this.props.showModal', this.props.showModal)
		return (
			<div>
				{
					(() => {
						if(this.props.showModal) {
							return (<div>
								<ScoreContainer>
									<ScoreHeaderContainer>Add Followup Task to this Filecheck<HeaderButton onClick={this.closeFollowupTaskModal}><FaTimes /></HeaderButton></ScoreHeaderContainer>
									<ScoreBodyContainer>
                                        <MRModalLabel>Subject</MRModalLabel>
                                        <MRModalInput type="text" name="followup_sub" onChange={this.handleChange} value={this.state.followup_sub}/>
                                        <FCKCommentDiv style={{padding: "0px", marginTop: "10px"}}>
                                            <label>Note</label>
                                            <FCKTextarea rows="6" style={{width: "100%"}} value={this.state.followup_note} onChange={this.changeFollowupNote} />
                                        </FCKCommentDiv>
                                        <MRModalLabel>Assign To</MRModalLabel>
                                        <MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.assigned_to}>
                                            {
                                                (() => {
                                                    if(this.state.review_list.length !== 0) {
                                                        let reviewees = [];
                                                        console.log("his.state.reviewee_id", this.state.reviewee_id);
                                                        for(let item of this.state.review_list){
                                                            reviewees.push(<option key={item.ID} value={item.ID}>{item.ContactName}</option>);
                                                            
                                                        }
                                                        return reviewees;
                                                    }
                    
                                                })()
                                            }
                                        </MRModalSelect>
                                        <MRModalLabel>Due By</MRModalLabel>
                                        <CSLDateCover>
                                            <DatePicker
                                                    onChange={this.onDateChangeDueBy}
                                                    clearIcon={null}
                                                    calendarIcon={null}
                                                    locale={"en-GB"}
                                                    value={this.state.due_by}
                                                    />
                                        </CSLDateCover>
									</ScoreBodyContainer>
									<div style={{height: '60px', width: '100%', paddingRight: "12px"}}>
										<MRModalNextBtn onClick={this.addTask}>Add Task</MRModalNextBtn>
										<MRModalCancelBtn onClick={this.closeFollowupTaskModal}>Close</MRModalCancelBtn>
									</div>
								</ScoreContainer>
							</div>)
						}
					})()
				}
				<CSLTable add={false} processData={this.processTasksData} headerText={''} tableRows={this.props.tableRows} refreshCallback={this.refreshState} />
			</div>
		)
		
	}

}

export default TasksList