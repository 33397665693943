import React from 'react';
import styled from 'styled-components';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import wNumb from 'wnumb'

const InfoBar = styled.div`
	color: ${process.env.DEFAULT_TEXT_COLOR};
    font-weight: 300;
    padding: 5px;
`
const Info = styled.div`
    padding: 5px 0px 5px 5px;
    background-color: #f7f7f7;
`
class MGRScoreThresholds extends React.Component
{
	state = { resultItemList: null, refresh: 0 }

	componentDidMount() {
		this.setState({ resultItemList : this.props.resultItemList, resultItem: this.props.resultItem })
	}

	componentDidUpdate(prevProps) {
		if(this.props !== prevProps) {
			this.setState({ resultItemList : this.props.resultItemList, resultItem: this.props.resultItem })
		}
	}

	onScoreUpdate = (values, handle) => {
		// event.preventDefault()
		if(this.state.resultItemList === null) return
		// console.log('handle', handle)
		let value = Math.floor(parseFloat(values[handle]))

		let changeIndex = 0
		for(let i=0; i < this.state.resultItemList.length; i++) {
			if(this.state.resultItemList[i].id == this.state.resultItem.id) changeIndex = i
		}
		let resultItemList = this.state.resultItemList

		if(changeIndex === 0) {
			if(value > resultItemList[changeIndex+1].ubound - 1) value = resultItemList[changeIndex+1].ubound - 1
			resultItemList[changeIndex].ubound = value
			resultItemList[changeIndex+1].lbound = value
		} else if(changeIndex === this.state.resultItemList.length - 1) {
			if(value < resultItemList[changeIndex-1].lbound + 1) value = resultItemList[changeIndex-1].lbound + 1
			resultItemList[changeIndex].lbound = value
			resultItemList[changeIndex-1].ubound = value
		} else {
			if(handle === 0) {
				if(value < resultItemList[changeIndex-1].lbound + 1) value = resultItemList[changeIndex-1].lbound + 1
				resultItemList[changeIndex-1].ubound = value
				resultItemList[changeIndex].lbound = value
			} else {
				if(value > resultItemList[changeIndex+1].ubound - 1) value = resultItemList[changeIndex+1].ubound - 1
				resultItemList[changeIndex+1].lbound = value
				resultItemList[changeIndex].ubound = value
			}
		}
		this.props.modifyResultItemList(resultItemList)
	}

	render() {
		if(this.state.resultItemList === null) return <div>Loading...</div>

		if(!this.props.allowScoring) return <div></div>

		let connect = []
		let start = []
		let tooltips = []
		let index = 0
		let limit = []

		for(let i=0; i < this.state.resultItemList.length; i++) {
			if(this.state.resultItemList[i].id === this.state.resultItem.id) {
				// console.log('resultItemList', i, this.state.resultItemList, this.state.resultItem)
				if(i === 0) {
					start.push(0)
					start.push(this.state.resultItemList[i].ubound)
					connect = [false,true, false]
					tooltips = [wNumb({decimals: 0, suffix: '%'}), wNumb({decimals: 0, suffix: '%'})]
					limit = [this.state.resultItemList[i].lbound, this.state.resultItemList[i+1].ubound]
					index=10000
				} else if(i > 0 && i < this.state.resultItemList.length - 1){
					// start.push(this.state.resultItemList[i-1].ubound)
					// start.push(this.state.resultItemList[i].ubound)
					start = [this.state.resultItemList[i-1].ubound, this.state.resultItemList[i].ubound]
					connect = [false, true, false]
					tooltips = [wNumb({decimals: 0, suffix: '%'}), wNumb({decimals: 0, suffix: '%'})]
					limit = [this.state.resultItemList[i-1].lbound, this.state.resultItemList[i+1].ubound]
					index = i
				} else {
					start.push(this.state.resultItemList[i-1].ubound)
					start.push(100)
					connect = [false, true, false]
					// tooltips.push(wNumb({decimals: 0, suffix: '%'}))
					tooltips = [wNumb({decimals: 0, suffix: '%'}),wNumb({decimals: 0, suffix: '%'})]
					limit = [this.state.resultItemList[i-1].lbound, this.state.resultItemList[i].ubound]
					index = i
				}
			}
		}
		
		if(index > 0) {
			console.log('index start connect tooltips', index, start, connect, tooltips)
		} else {
			return <div></div>
		}
		

		return (
				<div style={{paddingBottom:"15px"}}>
					<Info><InfoBar><b> Score Threshold -</b> Select a score range for this result.</InfoBar></Info>
					<div style={{paddingTop: '40px'}}>
						<Nouislider range={{ min: 0, max: 100 }} start={start} step = {1} connect={connect} tooltips={tooltips} onChange={this.onScoreUpdate} />
					</div>
				</div>
			)
	}
}

export default MGRScoreThresholds;