import React from 'react';
import MGRScoringItem from './MGRScoringItem'

class MGRScoringItemList extends React.Component
{
	state = { scoring: null }
	// state = { reviewConfig: null }

	componentDidMount() {
		this.setState({scoring: this.props.scoring});
	}

	componentDidUpdate(prevProps) {
		if(this.props !== prevProps) this.setState({ scoring : this.props.scoring })
	}

	render() {
		if(this.state.scoring === null) return <div>Loading...</div>
		return (
				<div>
					{
						this.state.scoring.scores.map((resultItem, index) => {
							return (
								<div key={index}>
									<MGRScoringItem 
										resultItem={resultItem} 
										resultItemList={this.state.scoring.scores} 
										modifyResultItem={this.props.modifyResultItem} 
										modifyResultItemList={this.props.modifyResultItemList}
										allowScoring={this.state.scoring.scoring_enabled}
									/>
								</div>
							)
						})
					}
				</div>
			)
	}
}

export default MGRScoringItemList;