import React from 'react';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {software_layout} from 'react-icons-kit/linea/software_layout';
import {basic_floppydisk} from 'react-icons-kit/linea/basic_floppydisk';
import {software_pencil} from 'react-icons-kit/linea/software_pencil'
import {arrows_square_check} from 'react-icons-kit/linea/arrows_square_check';
import {checkboxChecked} from 'react-icons-kit/icomoon/checkboxChecked';
import {checkboxUnchecked} from 'react-icons-kit/icomoon/checkboxUnchecked';

const FieldContainer = styled.div`
	padding: 20px 0px;
	border-bottom: 2px solid #d2d1d1;
`;
const Section80 = styled.div`
	width: 80%;
	float: left;
	box-sizing: border-box;
`;
const Section20 = styled.div`
	width: 20%;
	float: left;
	box-sizing: border-box;
	text-align: right;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;
const TextBox = styled.input`
	height: 20px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    &:focus {
		outline-width: 0;
	}
`;

class SaleDateField extends React.Component
{
	state = {custom_fields: null, data: null, editable: false};

	componentDidMount()
	{
		const data = this.props.custom_fields.custom_terminology_fields.find((item) => {
			return item.name === "sale_date"
		})
		this.setState({custom_fields: this.props.custom_fields, data});
	}

	// componentDidUpdate(prevProps)
	// {
	// 	if (prevProps !== this.props) {
	// 		const data = this.props.custom_fields.custom_terminology_fields.find((item) => {
	// 			return item.name === "sale_date"
	// 		})
	// 		this.setState({custom_fields: this.props.custom_fields, data});
	// 	}
	// }

	changeFieldNickname = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.custom_terminology_fields.forEach((item) => {
			if (item.name === "sale_date") {
				item.nickname = event.target.value;
			}
		})
		let data = this.state.data;
		data.nickname = event.target.value;
		this.props.updateCustomFields(custom_fields);
	}

	toggleRequired = (event) => {
		event.preventDefault();
		let data = {};
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.custom_terminology_fields.forEach((item) => {
			if (item.name === "sale_date") {
				item.required = item.required === true ? false : true;
				data = item;
			}
		})
		// console.log("custom_fields:", custom_fields);
		// let data = JSON.parse(JSON.stringify(this.state.data));
		// data.required = data.required === true ? false : true;
		this.setState({custom_fields, data});
		this.props.updateCustomFields(custom_fields);
	}

	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}

		return(
			<FieldContainer>
				<FloatingSection>
					{
						(() => {
							if (this.state.editable) {
								return (<TextBox value={this.state.data.nickname} name={this.state.data.name} onChange={this.changeFieldNickname} />)
							} else {
								return (<BoldLabel>{this.state.data.nickname}</BoldLabel>);
							}
						})()
					}
				</FloatingSection>
				<FloatingSection style={{paddingLeft: "7px"}}>
					{
						(() => {
							if (this.state.editable) {
								return (<Icon style={{cursor: "pointer", marginLeft: "20px", paddingTop: "5px"}} size={18} icon={basic_floppydisk} onClick={()=>this.setState({editable: false})} />);
							} else {
								return (<Icon style={{cursor: "pointer"}} icon={software_pencil} onClick={()=>this.setState({editable: true})} />);
							}
						})()
					}
				</FloatingSection>
				<Clearfix></Clearfix>
				<div style={{marginTop: "10px"}}>
					<FloatingSection>
						{
							(() => {
								if (this.state.data.required) {
									return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleRequired} />)
								} else {
									return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleRequired} />)
								}
							})()
						}
					</FloatingSection>
					<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Required field</BoldLabel></FloatingSection>
					<Clearfix></Clearfix>
				</div>
			</FieldContainer>
		);
	}
}

export default SaleDateField;
