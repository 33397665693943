import React from 'react';
import styled from 'styled-components';

const OverviewHeader = styled.div`
	background-color: #ffffff;
	padding: 20px;
	font-weight: 600;
	color: ${process.env.DEFAULT_TEXT_COLOR};
`;
const OverviewBody = styled.div`
	padding: 30px 20px;
	margin-top: 2px;
	background-color: #ffffff;
`;
const AnswerTextArea = styled.textarea`
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 100%;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;

class AnswerSummary extends React.Component
{
	state = {section_payload: null};

	componentDidMount()
	{
		this.setState({section_payload: this.props.section_payload});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			this.setState({section_payload: this.props.section_payload});
		}
	}

	modifySummary = (event) => {
		event.preventDefault();
		this.setState({section_payload: event.target.value});
		this.props.modifySummary(event.target.value);
	}

	modifyAdminConfirmationText = (event) => {
		event.preventDefault();
		this.props.modifyAdminConfirmationText(event.target.value);
	}

	render()
	{
		if (this.state.section_payload === null) {
			return (<div>Loading...</div>);
		}
		// console.log("CURLANE::",this.props.cur_lane)
		return (
			<div>
				<OverviewHeader>Summary</OverviewHeader>
				<OverviewBody>
					<AnswerTextArea disabled={this.props.readonly} value={this.state.section_payload.replace(/'+/g,"'")} onChange={this.modifySummary} style={{marginTop: "5px"}} rows="6" />
				</OverviewBody>
				{
					
					(this.props.cur_lane !== "COMP_FCK_ASSIGNMENT" && this.props.cur_lane !== "COMP_FCK_SAVED") &&
					<>
						<OverviewHeader>Edit Confirmation Text</OverviewHeader>
						<OverviewBody>
							<AnswerTextArea disabled={this.props.readonly} value={this.props.admin_confirmation_text.replace(/'+/g,"'")} onChange={this.modifyAdminConfirmationText} style={{marginTop: "5px"}} rows="6" />
						</OverviewBody>
					</>
				}
			</div>
		);
	}
}

export default AnswerSummary;