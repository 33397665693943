import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import RegisterReporting from './CSLRegister/RegisterReporting'
import CSLPreviewTable from './CSLRegister/CSLPreviewTable'
import PdfReportGenerator from './CSLRegister/PdfReportGenerator'
import reportData from './asData.js';
import filters from './asFilters.js';
import moment from 'moment-business-days'
import CSLLoader from '../Common/CSLLoader';

const SubHeading = styled.div`
  padding: 10px 15px;
    margin: 0px;  
    font-weight: 600;
    font-size: '16px';
`

const TableHead = styled.div`
  float: left;
    background-color: #EDF0F1;
    padding: 10px 0px 10px 20px;
    font-weight: 600;
    font-size: smaller;
    box-sizing: border-box;
`;

const map_month = {
  '01' : 'January',
  '02' : 'February',
  '03' : 'March',
  '04' : 'April',
  '05' : 'May',
  '06' : 'June',
  '07' : 'July',
  '08' : 'August',
  '09' : 'September',
  '10' : 'October',
  '11' : 'November',
  '12' : 'December'
}


class AuditorSummary extends React.Component {

	state = {ready: false,pill_filters: {}, view_count: 0, filters: filters, auditor_summary_data: {}}

	componentDidMount = () => {
		let api = new APICall()
    let payload = {command: "get_auditor_summary"}
    api.command(payload, this.processAuditorSummary)
	}

  processAuditorSummary = (result) => {
    console.log('result as', result)
    let auditor_summary_data = result.result
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let prefered_columns = {
            'reviewer' : 'Reviewer', 
            'last_assigned' : 'Last Assigned',  
            'last_completed' : 'Last Completed', 
            'completed' : 'Completed',
            'cadd' : 'Completed after due date',
            'overdue' : 'Overdue',
            'pending' : 'Pending',
          }
    if(has_gc) {
      prefered_columns['gc'] = 'Group Companies'
    }
    this.setState({prefered_columns, auditor_summary_data, ready: true})
  }

  processListTasks = (result) => {
    console.log('result as', result)
    let tasks = result.result
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let prefered_columns = {
            'status': 'Status', 
            'file_type' : 'File Type', 

            'reviewer' : 'Reviewer', 
            'reviewee' : 'Reviewee', 
            'assigned_date' : 'Assigned Date',  
            'start_date' : 'Date Started', 
            'due_date' : 'Due Date'
          }
    if(has_gc) {
      prefered_columns['gc'] = 'Company'
    }
    this.setState({prefered_columns: prefered_columns, tasks, ready: true})
  }

	refreshCallback = () => {
		this.setState({view_count: this.state.view_count + 1})
	}

  dateNumber = (date) => {
    if(date === '-' || date === 'Never')return 0
    let d = date.split('/')
    return (parseInt(d[2]) * 10000) + (parseInt(d[1]) * 100) + parseInt(d[0])
  }


  getMonthYear = (date) => {
    let parts = date.split('/')
    return `${map_month[parts[1]]}, ${parts[2]}`
  }
  processTableData = () => {
    let ret = {columns: [], data: [], raw_data: [], row_filters: {}}
    let has_gc = Store.getStoreData('parent_has_group_companies')
    let users = Store.getStoreData('users')
    let parent_company = Store.getStoreData('parent_company')
    let gc_companies = Store.getStoreData('gc_companies')
    let companies = {}
    for(let c of gc_companies) {
      companies[c.id] = c
    }
    // console.log('parent_company, gc_companies, users', parent_company, gc_companies, users)
    
    ret.columns.push({Header: 'Reviewer', accessor: 'reviewer', minWidth: 170, headerStyle: {textAlign: 'left'}})
    if(has_gc)ret.columns.push({Header: 'Group Companies', accessor: 'gc', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Last Assigned', accessor: 'last_assigned', minWidth: 170, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
      return (this.dateNumber(a) > this.dateNumber(b))
    }})
    ret.columns.push({Header: 'Last Completed', accessor: 'last_completed', minWidth: 170, headerStyle: {textAlign: 'left'}, sortMethod: (a, b) => {
      return (this.dateNumber(a) > this.dateNumber(b))
    }})
    ret.columns.push({Header: 'Completed', accessor: 'completed', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Completed after due date', accessor: 'cadd', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Pending', accessor: 'pending', minWidth: 170, headerStyle: {textAlign: 'left'}})
    ret.columns.push({Header: 'Overdue', accessor: 'overdue', minWidth: 170, headerStyle: {textAlign: 'left'}})

    console.log('users, gc_companies', users, gc_companies)
    let valid_roles = {file_checker: 1, manager: 1, senior_manager: 1}

    let asrows = this.state.auditor_summary_data
    for(let uid in asrows) {
      if(!(users[uid].role in valid_roles))continue
      let gc_count = 0
      let gc_name = ''
      for(let gc of gc_companies) {
        if(gc.is_gc && uid in gc.users){gc_count++; gc_name = gc.company_name}
      }
      let gcnum = gc_count === 1 ? gc_name : `${gc_count} companies`
      let last_assigned = asrows[uid].last_assigned === 0 ? 'Never' : moment.unix(asrows[uid].last_assigned).format('DD/MM/YYYY')
      let last_completed = asrows[uid].last_completed === 0 ? 'Never' : moment.unix(asrows[uid].last_completed).format('DD/MM/YYYY')
      let la_month = last_assigned === 'Never' ? 'Never' : this.getMonthYear(last_assigned)
      let lc_month = last_completed === 'Never' ? 'Never' : this.getMonthYear(last_completed)
      // console.log('asrows[uid].last_completed', asrows[uid].last_completed, moment.unix(asrows[uid].last_completed).format('DD/MM/YYYY'))
      let elem = {
        id: uid,
        reviewer: users[uid].ContactName,
        gc: gcnum,
        last_assigned: last_assigned,
        last_completed: last_completed,
        completed: asrows[uid].completed,
        cadd: asrows[uid].cadd,
        pending: asrows[uid].pending,
        overdue: asrows[uid].overdue,
        notstarted_overdue: `${asrows[uid].not_started} / ${asrows[uid].overdue}`,
        inprogress_overdue: `${asrows[uid].in_progress} / ${asrows[uid].overdue}`,
        completed_overdue: `${asrows[uid].completed} / ${asrows[uid].cadd}`,
        pending_overdue: `${asrows[uid].pending} / ${asrows[uid].overdue}`,
        total_overdue: `${asrows[uid].assigned} / ${asrows[uid].overdue}`,
        la_month: la_month,
        lc_month: lc_month
      }
      ret.data.push(elem)
      ret.raw_data.push(elem)
      ret.row_filters[uid] = {}
    }

    return ret
  }

  extraReportHTML = (params) => {
    let new_params = JSON.parse(JSON.stringify(params))
    console.log('extraReport params', new_params)

    let total_width = 0
    let widths_assoc = {}
    for(let t of new_params.table_headers) {
      if(t.accessor === 'notstarted_overdue' || t.accessor === 'inprogress_overdue' || t.accessor === 'total_overdue'){
        t.width = 2
        total_width += 2
      } else {
        total_width += t.width
      }
      widths_assoc[t.accessor] = t.width
    }

    for(let w_id in new_params.widths) {
      let width = widths_assoc[w_id] * 100 / total_width
      new_params.widths[w_id] = `${width}%`
    }

    return (
      <div>
      <SubHeading>Section 2: Reviewer Summary</SubHeading>
        {
          new_params.table_headers.map((th, index) => {
            if(!(th.accessor in new_params.groupby_fields) && th.width > 0){
              return (
                <TableHead key={index} style={{width: new_params.widths[th.accessor]}}>{th.nickname}</TableHead>
              )
            }
          })
        }
        <div style={{clear: "both"}}></div>
        <CSLPreviewTable 
            previewData={new_params.preview_data}
            fields={new_params.fields}
            groupbyFields= {new_params.groupby_fields}
            groupbyHeaders={[]}
            cellWidth={new_params.sep_width}
            tableHeaders={new_params.table_headers}
            widths={new_params.widths}
            rowFilter={null}
          />
      </div>
      )
  }

  extraReportPDF = (reportPDF, raw_data, gstruct, table_headers, preview_data, selections_gathered, loggedin_user, filters, pills) => {
    console.log('extraReport raw_data, gstruct, table_headers, preview_data, selections_gathered', raw_data, gstruct, table_headers, preview_data, selections_gathered)
    let new_table_headers = JSON.parse(JSON.stringify(table_headers))
    let total_width = 0
    let widths_assoc = {}
    for(let t of new_table_headers) {
      if(t.accessor === 'notstarted_overdue' || t.accessor === 'inprogress_overdue' || t.accessor === 'total_overdue'){
        t.width = 2
        total_width += 2
      } else {
        total_width += t.width
      }
      widths_assoc[t.accessor] = t.width
    }

    // for(let t of new_table_headers) {
    //   if(t.accessor === 'completed_overdue'){
    //     t.width = 0; 
    //   }
    // }
    let new_raw_data = JSON.parse(JSON.stringify(raw_data))
    new_raw_data.table_headers = new_table_headers
    let report = new PdfReportGenerator(reportPDF, new_raw_data, gstruct, new_table_headers, preview_data, selections_gathered, loggedin_user, filters, pills, null)
    report.generateReport(true, 'Section 2: Reviewer Summary')
  }

  processDate = () => {
    var d = new Date()
    let day = d.getDate() < 10 ? `0${d.getDate().toString()}` : d.getDate().toString();
    let monthIndex = d.getMonth() < 10 ? `0${d.getMonth().toString()}`: d.getMonth().toString();
    let year = d.getFullYear().toString();
    let date = day + '/' + (parseInt(monthIndex)+1) + '/' + year;
    return date;
  }
  
	render() {
		let button_set={}
    if(!this.state.ready)return <div><CSLLoader /></div>
    reportData.company_name = Store.getStoreData('parent_company').company_name
    let filters = this.state.filters 
    let has_gc = Store.getStoreData('parent_has_group_companies')
    if(!has_gc) {
      let i = 0
      for(i = 0; i < reportData.table_headers.length; i++) {
        if(reportData.table_headers.accessor === 'gc')break
      }
      reportData.table_headers.splice(i, 1)
      for(i = 0; i < reportData.sortby.fields.length; i++) {
        if(reportData.sortby.fields.accessor === 'gc')break
      }
      reportData.sortby.fields.splice(i, 1)
      for(i = 0; i < reportData.groupby.fields.length; i++) {
        if(reportData.groupby.fields.accessor === 'gc')break
      }
      reportData.groupby.fields.splice(i, 1)
      for(i = 0; i < reportData.groupby.selections.length; i++) {
        if(reportData.groupby.selections.accessor === 'gc')break
      }
      reportData.groupby.selections.splice(i, 1)
      delete filters.gc
    }

    let extra_header = {title: 'Abbreviations:', lines: ['L Assigned: Last assigned date',
                    'L Completed: Last completed date', 
                    'P: Pending', 'OD: Overdue', 
                    'InP: In Progress', 
                    'COD: Completed while overdue',
                    'C: Completed',
                    'Tot: Total reviews assigned'
                    ]
                  }


		return (<div style={{position: "relative", paddingTop:"10px",paddingLeft:"10px",paddingBottom:"10px"}}>
                                  <RegisterReporting
                                      headerText='Answer Analysis'
                                      moduleId='filechecker'
                                      data={reportData}
                                      processTableData={this.processTableData}
                                      preferedColumns={this.state.prefered_columns}
                                      buttonSet={button_set}
                                      filters={filters}
                                      pillFilters={this.state.pill_filters}
                                      refreshCallback={this.refreshCallback}
                                      viewCount={this.state.view_count}
                                      extraReportHTML={this.extraReportHTML}
                                      extraReportPDF={this.extraReportPDF}
                                      subheading='Section 1: Reviewers with no assigned Audit'
                                      extraHeader={extra_header}
                                      rowFilter={{accessor: 'pending', value: 0}}
                                      titlePDF={'File Check Report_'+ this.processDate()}
                                  />
                        </div>)
	}
}

export default AuditorSummary;