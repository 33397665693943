import React from 'react';
import Store from '../../Common/Store';
import styled from 'styled-components';

const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;

class TeamRegister extends React.Component {

    closePage = (event) => {
        event.preventDefault();
        let url = "/";
        if (this.state.requester !== "") {
            const reqarr = this.state.requester.split("_");
            url = `/${reqarr[0]}/${reqarr[1]}`;
        }
        window.location.replace(url);
    }

    render () {
        return (
            <ErrorBar>
                <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                <div style={{clear: "both"}}></div>
            </ErrorBar>
        );
    }
}

export default TeamRegister;
