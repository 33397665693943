import React from 'react';
import MGRScoreInfo from './MGRScoreInfo'
import MGRScoreOutcome from './MGRScoreOutcome'
import MGRScoreRAG from './MGRScoreRAG'
import MGRScoreThresholds from './MGRScoreThresholds'
import MGRAddResultItem from './MGRAddResultItem'
import { FaTrashAlt } from "react-icons/fa";

class MGRScoringItem extends React.Component
{
	state = { resultItem: null, resultItemList: null, index: null, maxIndex: null }

	componentDidMount() {
		let maxIndex = this.props.resultItemList.length - 1
		let index = 0
		for(let i=0; i <= maxIndex; i++) {
			if(this.props.resultItemList[i].id === this.props.resultItem.id)index = i
		}
		this.setState({ resultItem : this.props.resultItem, resultItemList : this.props.resultItemList, index:index, maxIndex:maxIndex })
	}
	componentDidUpdate(prevProps) {
		if(this.props !== prevProps) {
			let maxIndex = this.props.resultItemList.length - 1
			let index = 0
			for(let i=0; i <= maxIndex; i++) {
				if(this.props.resultItemList[i].id === this.props.resultItem.id)index = i
			}
			this.setState({ resultItem : this.props.resultItem, resultItemList : this.props.resultItemList, index:index, maxIndex:maxIndex })
		}
	}

	removeResult = (resultid) => (event) => {
		event.preventDefault();
		console.log("resultid:", resultid);
		let resultItemList = JSON.parse(JSON.stringify(this.state.resultItemList));
		console.log("resultItemList", resultItemList);
		let to_be_deleted_item_index = 0;
		resultItemList.forEach((item, index) => {
			if (item.id === resultid) {
				to_be_deleted_item_index = index;
			}
		})
		console.log('to_be_deleted_item_index', to_be_deleted_item_index);
		resultItemList[to_be_deleted_item_index + 1].lbound = resultItemList[to_be_deleted_item_index - 1].ubound;
		let newresultItemList = [];
		resultItemList.forEach((item) => {
			if (item.id !== resultid) {
				newresultItemList.push(item);
			}
		})
		this.props.modifyResultItemList(newresultItemList);
	}

	render() {
		if(this.state.resultItem === null) return <div></div>

		// console.log('state in MGRScoringItem', this.state)

		return (
				<div style={{padding: '30px', marginTop: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
					<div style={{display: "inline-block", width: "90%"}}><MGRScoreInfo actualOutcome={this.state.resultItem.actual_outcome} /></div>
					{
						(() => {
							if ("can_be_deleted" in this.state.resultItem && this.state.resultItem.can_be_deleted) {
								return (<div onClick={this.removeResult(this.state.resultItem.id)} style={{display: "inline-block", width: "10%", fontSize: "20px", paddingLeft: "50px", boxSizing: "border-box", cursor: "pointer"}}><FaTrashAlt /></div>);
							}
						})()
					}
					<MGRScoreOutcome resultItem={this.state.resultItem} modifyResultItem={this.props.modifyResultItem} />
					<MGRScoreRAG resultItem={this.state.resultItem} modifyResultItem={this.props.modifyResultItem} />
					<MGRScoreThresholds resultItem={this.state.resultItem} resultItemList={this.state.resultItemList} modifyResultItemList={this.props.modifyResultItemList} allowScoring={this.props.allowScoring} />
					{
						(() => {
							if(this.state.index < this.state.maxIndex) {
								return (<MGRAddResultItem 
											resultItem={this.state.resultItem} 
											index={this.state.index} 
											maxIndex={this.state.maxIndex} 
											resultItemList={this.state.resultItemList} 
											modifyResultItemList={this.props.modifyResultItemList} 
											allowScoring={this.props.allowScoring} 
										/>)
							}
						})()
					}
				</div>
			)
	}
}

export default MGRScoringItem;