		let data = {table_headers: [], data: []};
        data.module = {id: 'filechecker', display: 'Reviews to be completed'}
		data.table_headers = [
            {"accessor": "review_type", "nickname": "File Type", type: 'alpha', width: 3,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "reviewer", "nickname": "Reviewer", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "status", "nickname": "Status Name", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "assigned_date", "nickname": "Assigned", type: 'date', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "start_date", "nickname": "Started", type: 'date', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "due_date", "nickname": "Due Date", type: 'date', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "company_name", "nickname": "Company", type: 'alpha', width: 0,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "reviewee", "nickname": "Reviewee", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
            {"accessor": "customer", "nickname": "Customer", type: 'alpha', width: 2,
                "valign": {'top':false,'vcentred':true,'bottom':false}, 
                "halign":{'left':true,'hcentred':false, 'right':false}},
        ];

        data.sortby = {
            fields: [
                {"accessor": "review_type", "nickname": "File Type", type: 'alpha', width: 3},
                {"accessor": "reviewer", "nickname": "Reviewer", type: 'alpha', width: 2},
                {"accessor": "status", "nickname": "Status", type: 'alpha', width: 2},
                {"accessor": "due_date", "nickname": "Due Date", type: 'date', width: 2},
                {"accessor": "assigned_date", "nickname": "Assigned", type: 'date', width: 2},
                {"accessor": "start_date", "nickname": "Started", type: 'date', width: 2},
                {"accessor": "company_name", "nickname": "Company", type: 'alpha', width: 0},
                {"accessor": "reviewee", "nickname": "Reviewee", type: 'alpha', width: 2},
                {"accessor": "customer", "nickname": "Customer", type: 'alpha', width: 2}
            ],
            selected: {"accessor": "assigned_date", "order": "Ascending"}
        };
        data.groupby = {
            fields: [
                {"accessor": "review_type", "nickname": "File Type", type: 'alpha', width: 3},
                {"accessor": "reviewer", "nickname": "Reviewer", type: 'alpha', width: 2},
                {"accessor": "status", "nickname": "Status", type: 'alpha', width: 2},
                {"accessor": "due_date", "nickname": "Due Date", type: 'date', width: 2},
                {"accessor": "assigned_date", "nickname": "Assigned", type: 'date', width: 2},
                {"accessor": "start_date", "nickname": "Started", type: 'date', width: 2},
                {"accessor": "company_name", "nickname": "Company", type: 'alpha', width: 0},
                {"accessor": "reviewee", "nickname": "Reviewee", type: 'alpha', width: 2},
                {"accessor": "customer", "nickname": "Customer", type: 'alpha', width: 2}
            ],
            selections: [{accessor: 'company_name', sort_order: 'asc'}, {accessor: 'review_type', sort_order: 'asc'},{accessor: 'reviewer', sort_order: 'asc'}]
        };
        data.font_size_map = {
            section_titles :    {default : {selected : false, size : 16}, small : {selected : false, size : 14}, large : {selected : true, size : 18}},
            introductory_text : {default : {selected : false, size : 12}, small : {selected : false, size : 11}, large : {selected : true, size : 14}},
            header_row_text :   {default : {selected : true, size : 12}, small : {selected : false, size : 11}, large : {selected : false, size : 14}},
            table_data_text :   {default : {selected : true, size : 11}, small : {selected : false, size : 10}, large : {selected : false, size : 12}},       
            footer:             {default : {selected : true, size : 10}, small : {selected : false, size : 9}, large : {selected : false, size : 11}}
        }

module.exports = data