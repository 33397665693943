import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import Utils from './../../Common/Utils';
import CslSimpleDropDown from './CslSimpleDropDown';
import AlertBox from './../Common/AlertBox';
import APDropdownOptions from './../Common/APDropdown/APDropdownOptions.js';
import Toggle from 'react-toggle';
import "./ReactToggle.css";

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 60%;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 270px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;    
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;    
    border-left: 9px solid #04ADA8; 
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;  
    color: #143151; 
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalLabel = styled.div`
    color: #030303;
    font-weight: 600;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
    position: relative;
    background-color: white;
    height: 80px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f7fb;
    width: 100%;
    border-top: 1px solid #DFDFDF;
`;
const MRModalNextBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #11ABA6;
    color: #ffffff;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #11ABA6;
    margin-left: 5px;
    font-size: 15px;
    margin-left: 10px;
    float: right;
    cursor: pointer;
`;
const MRModalCancelBtn = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545454;
    border: 2px solid #dadada;
    width: 145px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
    float: right;
    cursor: pointer;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 98%;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    color: #545454;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 3px;
    padding: 6px;
    background-color: #ffffff;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const SpacerS = styled.div`
    height: 5px;
`;

const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #282727;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;

class AddEditCustomField extends React.Component
{
    state = {
        name: "", 
        nickname: "", 
        type: "Alpha", 
        dropdown_options: "", 
        required: "Yes",
        required_options: ["Yes", "No"],
        status_options: ["Active", "Inactive"],
        include_other_options: ["Yes", "No"],
        include_other: "No",
        isDelete: 0,
        alert_param: null,
        ready: false,
        outer_data_key:null,
        feed_id:null,
        is_active:true
    };
    
    componentDidMount()
	{
        if(this.props.curid !== 0) {         
            console.log("content==>",this.props.content) 

            let dropdown_options = "";
            if ("dropdown_options" in this.props.content === true) {
                if (this.props.content.dropdown_options.length !== 0) {
                    this.props.content.dropdown_options.forEach((item, index) => {
                        if (index !== (this.props.content.dropdown_options.length - 1)) {
                            dropdown_options = dropdown_options + item + "\n";
                        } else {
                            dropdown_options = dropdown_options + item;
                        }
                    })
                }
            }

            this.setState({
                ready:true, 
                name: this.props.content.name, 
                nickname: this.props.content.nickname,
                type: "type" in this.props.content === true ? this.props.content.type : null,
                dropdown_options: dropdown_options,
                required: this.props.content.required === true ? "Yes" : "No",
                is_active: this.props.content.is_active,
                include_other: this.props.content.other === true ? "Yes" : "No",
                isDelete: this.props.content.isDelete,
                outer_data_key: this.props.outer_data_key,
                feed_id: "type" in this.props.content === true && this.props.content.type === 'Auto-Populated Dropdown' && 'feed_id' in this.props.content ? this.props.content.feed_id : null

            })   
        }else{
            const randtxt = Utils.genKey(10);
		    let name = randtxt;            
            this.setState({name, ready: true})
        }       
    }   
    
    handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }

    changeFieldType = (selected_type) => {
        console.log("selected_type===>", selected_type)
		this.setState({type: selected_type})
	}
    
    changeFieldRequiredType = (selected_type) => {
		this.setState({required: selected_type});
	}

    changeStatus = (e) => {
        e.stopPropagation();
        // alert(1)
        let is_active = this.state.is_active ? false : true;
        this.setState({is_active});
    }

    changeIncludeOther = (selected_type) => {
        this.setState({include_other: selected_type});
    }

    modifyDropdownOptions = (event) => {
		event.preventDefault();			
		let dropdown_options = this.state.dropdown_options;
		dropdown_options = event.target.value;
		this.setState({dropdown_options});		
	}

    submitCustomField = (event) => {
        event.preventDefault();
        if(this.state.nickname === "")
        {
            // alert("Please provide the field name.");
            let alert_param = {title: 'ERROR', message: 'Please provide the field name.', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        }else if(this.state.type === "Dropdown" && this.state.dropdown_options === ''){
            let alert_param = {title: 'ERROR', message: 'Please provide at least one dropdown option', ok_text: 'Ok', confirm: false, 
                           alertHandler: this.checkvalidation, stack: {}}
            this.setState({alert_param: alert_param}) 
            return;
        }else{            
            const trimedvalue = this.state.dropdown_options;
            let optarr = trimedvalue.split("\n");
            if (optarr.length === 1 && optarr[0] === "") {
                optarr = [];
            }
            let updateedDropdown_options = optarr;
            console.log("updateedDropdown_options::",updateedDropdown_options)
            let customFieldObject = {}
            customFieldObject.name = this.state.name;
            customFieldObject.nickname = this.state.nickname;            
            customFieldObject.type = this.state.type;   
            if(this.state.type === 'Auto-Populated Dropdown'){
                customFieldObject.feed_id = this.state.feed_id
            }         
            customFieldObject.dropdown_options = updateedDropdown_options;            
            customFieldObject.required = this.state.required === "Yes" ? true : false;           
            //customFieldObject.displayFor = this.state.displayFor;            
            customFieldObject.is_active = this.state.is_active;
            customFieldObject.other = this.state.include_other === "Yes" ? true : false;
            //alert("before add")
            console.log("customFieldObject::",customFieldObject)
            if(this.props.curid !== 0) {
                //alert("update")
                this.props.updateAdditionalFieldsValue(customFieldObject, this.props.origin);
            }else{        
                //alert("add")                      
                this.props.saveAdditionalFieldsValue(customFieldObject);
            }
        }
    }

    checkvalidation = (result, stack) => {        
        this.setState({alert_param: null})               
    }
    addNewFeed = (feed_id) => {
        console.log('feed_id', feed_id)
        let custom_fields = this.props.data;
        console.log(custom_fields)
        //let custom_fields = JSON.parse(JSON.stringify(this.state.data));
        custom_fields[this.state.outer_data_key].forEach((item) => {
            if (item.name === this.state.name) {
                item.feed_id = feed_id;
            }
        })
        this.setState({data:custom_fields});
        this.props.updateCustomFields(custom_fields);
    }
    selectFeed = (feed_id) => {
        console.log(feed_id)
        // let inner_data = this.state.inner_data
        // inner_data.feed_id = feed_id
        this.setState({feed_id:feed_id})
    }
    render()
    {    
        console.log("add props::",this.props)
        console.log("add state::",this.state)
        if(!this.state.ready){
            return(<div></div>)
        } 
        let headerText = "Add";
        if(this.props.curid !== 0) {
            headerText = "Edit";
        }           
        return (
            <div>                
                <MRModalContainer> 
                    <MRModalHeader>
                        <MRModalHeaderText>{headerText} Custom Field</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                        <MRModalSubHeaderText>Fill out the below fields to submit your custom field.</MRModalSubHeaderText>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <div style={{clear: "both"}}></div>
                        <div style={{position: "relative",padding: "35px 35px 86px 35px"}}>
                            <MRModalLabel style={{display: "inline-block"}}>Field Name</MRModalLabel><span style={{marginLeft: "5px",color: "red", display: "inline-block"}}>*</span>
                            <MRModalInput style={{backgroundColor: "#ffffff"}} type="text" name="nickname" onChange={this.handleChange} value={this.state.nickname} />
                            <SpacerS/>
                            {
                                (() => {
                                    if (this.state.name !== "sale_date") {
                                        return (
                                            <>
                                                <MRModalLabel>Field Type</MRModalLabel>  
                                                <div>
                                                    <CslSimpleDropDown options={this.state.name === "reviewee_designation" ? this.props.content.available_field_types : this.props.data.available_field_types} selected={this.state.type} changeFieldType={this.changeFieldType} />
                                                </div>   
                                                {
                                                    (() => {
                                                        if (this.state.type === "Dropdown") {
                                                            return (
                                                                <div style={{padding: "10px 0px"}}>
                                                                    <SubHeading>Configure the dropdown options below. New line separates options.</SubHeading>
                                                                    <DropdownOptions rows="7" value={this.state.dropdown_options} onChange={this.modifyDropdownOptions} />
                                                                </div>
                                                            );
                                                        }

                                                        if (this.state.type === "Auto-Populated Dropdown") {
                                                            console.log('Auto-Populated Dropdown', this.state)
                                                            return (
                                                                <div style={{padding: "10px 0px"}}>
                                                                    <APDropdownOptions 
                                                                    rows="7" 
                                                                    feedId={this.state.feed_id} 
                                                                    selectFeed={this.selectFeed} 
                                                                    dropdownOptions={this.state.dropdown_options} 
                                                                    updateCustomFields={this.props.updateCustomFields}
                                                                    onAddNewFeed={this.addNewFeed} />
                                                                </div>
                                                            );
                                                        }                                                       

                                                    })()
                                                }                         
                                                <SpacerS/>
                                            </>
                                        )
                                    }
                                })()
                            }
                            
                            <div style={{boxSizing: "border-box"}}>
                                <div style={{float: "left", width: "33%", boxSizing: "border-box", paddingRight: "10px"}}>
                                    <MRModalLabel>Required?</MRModalLabel>  
                                    <div>
                                        <CslSimpleDropDown options={this.state.required_options} selected={this.state.required} changeFieldType={this.changeFieldRequiredType} />
                                    </div>
                                </div>
                                <div style={{float: "left", width: "33%", boxSizing: "border-box", paddingRight: "10px"}}>
                                    {
                                        this.state.type === "Dropdown" &&
                                        <>
                                            <MRModalLabel>Include Other</MRModalLabel>  
                                            <div>
                                                <CslSimpleDropDown options={this.state.include_other_options} selected={this.state.include_other} changeFieldType={this.changeIncludeOther} />
                                            </div>
                                        </>
                                    }
                                </div>
                                {/* <div style={{float: "left", width: "33%", boxSizing: "border-box"}}>
                                    <MRModalLabel>Status</MRModalLabel>
                                    <div>
                                        <CslSimpleDropDown options={this.state.status_options} selected={this.state.is_active} changeFieldType={this.changeStatus} />
                                    </div>
                                </div> */}
                                <div style={{clear: "both"}}></div>
                            </div>
                            <div style={{boxSizing: "border-box"}}>
                                <div style={{float: "left", width: "33%", boxSizing: "border-box", paddingRight: "10px"}}>
                                    <MRModalLabel>Status</MRModalLabel>  
                                    <div>
                                        <Toggle
                                            style={{ float: 'left' }}
                                            checked={this.state.is_active}
                                            icons={false}
                                            onClick={this.changeStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MRModalBody>
                    <MRModalFooter>
                        <div style={{position: "relative",padding: "17px"}}>                    
                            <MRModalNextBtn onClick={this.submitCustomField}>Submit</MRModalNextBtn>
                            <MRModalCancelBtn onClick={this.closeModal}>Cancel</MRModalCancelBtn>
                        </div>             
                    </MRModalFooter>
                </MRModalContainer>
                <AlertBox alertParam = {this.state.alert_param}/>
            </div>);
    }
}

export default AddEditCustomField;