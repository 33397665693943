import React from 'react';
import {FaTimes} from 'react-icons/fa';
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import { AiOutlineCloudUpload } from "react-icons/ai";
import Store from '../../Common/Store.js';
import XLSX from 'xlsx';
import moment from 'moment';
import BulkHistory from '../Partials/BulkHistory';
import AlertBox from '../Common/AlertBox';

const BulkContainer = styled.div`
	display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #ffffff;
    box-shadow: 0 0 5px #a7a6a6;
    width: 64%;
    top: 2%;
    left: 16%;
    border: 2px solid #bbbaba;
`;
const BulkHeader = styled.div`
    box-sizing: border-box;
    padding: 20px 10px 20px 30px;
    border-left: 8px solid #36ABA6;
`;
const BulkBody = styled.div`
    background-color: #F1F6F7;
    padding: 30px;
    box-sizing: border-box;
`;
const BulkFooter = styled.div`
    padding: 15px 30px;
    box-sizing: border-box;
`;
const UploadBox = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    border: 1px dashed #000000;
    background-color: #ffffff;
    border-radius: 3px;
    display: table;
`;
const UploadInnerBox = styled.div`
    box-sizing: border-box;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
`;
const UploadButton = styled.button`
    background-color: #1A3553;
    color: #ffffff;
    font-style: italic;
    border: 1px solid #1A3553;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 3px;
    box-sizing: border-box;
`;
const ExtOuter = styled.div`
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #cbcbcb;
    border-radius: 2px;
    padding: 5px;
    display: table;
`;
const ExtInner = styled.div`
    box-sizing: border-box;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
`;
const SelNum = styled.input`
    border: 1px solid #cbcbcb;
    border-radius: 2px;
    padding: 8px;
    width: 40%;
`;
const SaveBtn = styled.button`
    background-color: #37ada7;
    padding: 10px 25px;
    border: 1px solid #37ada7;
    border-radius: 2px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
`;
const CancelBtn = styled.button`
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    color: #666666;
    cursor: pointer;
    display: inline-block;
`;

class BulkModal extends React.Component {

    files_to_prepare_count = 0;
    files_prepared = 0;
    workbook = null;
    file = null;

    state = {data: null, num: 1, alertParam: null};

    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
    }

    openFileDialog() {
        this.fileInputRef.current.click();
    }

    onFileDrop = (e) => {
        console.log('onFileDrop', e)
        e.preventDefault();
        e.stopPropagation();
        const file = e.dataTransfer.files[0];
        if (this.file !== null) {
            const alertParam = {
                confirm: true,
                title: "Warning",
                message: "Only one file can be imported at a time. Selecting this file will overwrite the file already selected",
                ok_text: "Ok",
                cancel_text: "Cancel",
                stack: file,
                alertHandler: this.proceedUploading
            };
            this.setState({alertParam});
        } else {
            this.file = file;
            this.files_to_prepare_count = 1;
            setTimeout(this.mycheckFilesUploading, 500);
            this.myreadBinaryFile(file);
        }
    }
    
    onDragOver = (event) => {
        event.preventDefault();
    }
    
    onDragEnter = (e) => {
        e.stopPropagation();
    }

    onFilesAdded = (e) => {
        const file = e.target.files[0];
        if (this.file !== null) {
            const alertParam = {
                confirm: true,
                title: "Warning",
                message: "Only one file can be imported at a time. Selecting this file will overwrite the file already selected",
                ok_text: "Ok",
                cancel_text: "Cancel",
                stack: file,
                alertHandler: this.proceedUploading
            };
            this.setState({alertParam});
        } else {
            this.file = file;
            this.files_to_prepare_count = 1;
            setTimeout(this.mycheckFilesUploading, 500);
            this.myreadBinaryFile(file);
        }
    }
    
    proceedUploading = (decision, file) => {
        console.log("decision:", decision);
        console.log("file:", file);
        if (decision === false) {
            this.files_to_prepare_count = 0;
            this.setState({alertParam: null});
        } else {
            this.file = file;
            this.files_to_prepare_count = 1;
            this.setState({alertParam: null});
            setTimeout(this.mycheckFilesUploading, 500);
            this.myreadBinaryFile(file);
        }
    }

    mycheckFilesUploading = () => {
        if (this.files_prepared < this.files_to_prepare_count) {
            setTimeout(this.mycheckFilesUploading, 500);
        } else {
            // console.log("workbook:", this.workbook);
            let first_worksheet = this.workbook.Sheets[this.workbook.SheetNames[0]];
            let data = XLSX.utils.sheet_to_json(first_worksheet, {header:1});
            // console.log(data);
            this.files_to_prepare_count = 0;
            this.setState({data});
        }
    }

    myreadBinaryFile = (file) => {
        let reader = new FileReader();
        let that = this;
        reader.onprogress = this.updateProgress;
        reader.onload = (function(theFile) {
            return function(e) {
                let data = new Uint8Array(e.target.result);
                that.workbook = XLSX.read(data, {type: 'array'});
                that.files_prepared++;
            };
        })(file);

        reader.readAsArrayBuffer(file);
    }

    changeNum = (event) => {
        event.preventDefault();
        this.setState({num: event.target.value});
    }

    goNext = (event) => {
        event.preventDefault();
        let module_config = Store.getStoreData("module_config");
        let gc_assoc = Store.getStoreData("gc_assoc");
        let my_active_reviews = Store.getStoreData("my_active_reviews");
        let parent_company = Store.getStoreData("parent_company");
        // console.log("module_config:", module_config);
        // console.log("gc_assoc:", gc_assoc);
        // console.log("my_active_reviews:", my_active_reviews);
        // console.log("parent_company:", parent_company);
        let data = JSON.parse(JSON.stringify(this.state.data));
        const data_headers = data[0];
        let headers = {custom_terminology_fields: {}, additional_fields: {}};
        let actual_headers = {}
        let gc_id = 0;
        for (let head of data_headers) {
            if (head.toLowerCase() === "group company") headers.group_company = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "file review type") headers.file_review_type = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "customer name") headers.customer_name = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "file reference") headers.file_reference = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "reviewer") headers.reviewer = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "reviewee") headers.reviewee = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "comments") headers.assignment_comments = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "scheduled date") headers.due_date = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
            if (head.toLowerCase() === "related individual" && module_config.general.related_individual === true) headers.related_individual = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: false};
        }
        for (let ctf of module_config.custom_fields.custom_terminology_fields) {
            for (let head of data_headers) {
                if (head.toLowerCase() === ctf.nickname.toLowerCase()) {
                    headers.custom_terminology_fields[ctf.name] = {nick_name: head, index: data_headers.indexOf(head), addl: false, ctf: true};
                }
            }
        }
        for (let addl of module_config.custom_fields.additional_fields) {
            for (let head of data_headers) {
                if (head.toLowerCase() === addl.nickname.toLowerCase()) {
                    headers.additional_fields[addl.name] = {nick_name: head, index: data_headers.indexOf(head), addl: true, ctf: false};
                }
            }
        }
        // console.log("headers:", headers);
        for (let key in headers) {
            if (key !== "custom_terminology_fields" && key !== "additional_fields") {
                actual_headers[key] = headers[key];
            }
        }
        for (let key in headers.custom_terminology_fields) {
            actual_headers[key] = headers.custom_terminology_fields[key];
        }
        for (let key in headers.additional_fields) {
            actual_headers[key] = headers.additional_fields[key];
        }
           console.log("actual_headers:", actual_headers);
           console.log("actual_data:",data)
       let related_individual_text = "";
       if(module_config.general.related_individual === false && data[0].includes('Related Individual')){
            //console.log("EXITING");
            related_individual_text = "The ‘Related Indidivual’ column will not be processed, as this feature has been turned off."
            //return;
        }
        let rows = [];
        for (let i=1; i<data.length; i++) {
            let read = false;
            for (let rval of data[i]) {
                if (rval === "" || rval === null || rval === undefined) {
                    
                } else {
                    read = true;
                }
            }
            if (read === true) {
                let row = {}
                for (let key in actual_headers) {
                    let item = data[i][actual_headers[key].index];
                    if (key === "group_company") {
                        console.log("item:", item);
                        if (item === "" || item === null || item === undefined) {
                            row[key] = {value: "", content_id: 0, valid: false, reason: "Company cannot be empty"};
                        } else {
                            gc_id = 0;
                            for (let gc_key in gc_assoc) {
                                if (gc_assoc[gc_key].company_name.trim().toLowerCase() === item.trim().toLowerCase()) {
                                    gc_id = parseInt(gc_key);
                                }
                            }
                            row[key] = {value: item.trim(), content_id: gc_id, valid: gc_id === 0 ? false : true, reason: gc_id === 0 ? "Invalid Company" : ""};
                        }
                    }
                    if (key === "file_review_type") {
                        if (item === "" || item === null || item === undefined) {
                            row[key] = {value: "", content_id: 0, valid: false, reason: "File review type cannot be empty"};
                        } else {
                            const review = my_active_reviews.find(r => r.nick_name.trim().toLowerCase() === item.trim().toLowerCase());
                            let r_valid = false;
                            let reason = gc_id === 0 ? "Invalid Company" : "";
                            reason = review === undefined ? "File review type not found" : reason;
                            if (review !== undefined && gc_id !== 0) {
                                const calculated_gc_id = parent_company.id * 100000 + parseInt(gc_id);
                                if (module_config.general.show_parent_company === true) {
                                    if (review.company_id < 100000) {
                                        r_valid = true;
                                    } else {
                                        if (review.company_id === calculated_gc_id) {
                                            r_valid = true;
                                        } else {
                                            r_valid = false;
                                            reason = "File review type does not exists for the company";
                                        }
                                    }
                                } else {
                                    if (review.company_id < 1000) {
                                        r_valid = false;
                                        reason = "Parent Company File review type is not allowed";
                                    } else {
                                        if (review.company_id === calculated_gc_id) {
                                            r_valid = true;
                                        } else {
                                            r_valid = false;
                                            reason = "File review type does not exists for the company";
                                        }
                                    }
                                }
                            }
                            row[key] = {value: item.trim(), content_id: review !== undefined ? review.id : 0, valid: r_valid, reason: reason};
                        }
                    }
                    if (key === "customer_name") {
                        if (item === "" || item === null || item === undefined) {
                            row[key] = {value: "", content_id: 0, valid: false, reason: "Customer name cannot be empty"};
                        } else {
                            row[key] = {value: item.trim(), content_id: 0, valid: true, reason: ""};
                        }
                    }
                    if (key === "file_reference") {
                        console.log("file ref value:", item);
                        row[key] = {value: item === "" || item === null || item === undefined ? "" : item.toString().trim(), content_id: 0, valid: true};
                    }
                    if (key === "reviewer") {
                        if (item === "" || item === null || item === undefined) {
                            row[key] = {value: "", content_id: 0, valid: false, reason: "Reviewer cannot be empty"};
                        } else {
                            let reviewer = undefined;
                            let reason = reviewer === undefined ? "Reviewer not found or Reviewer is not associated with the company" : "";
                            if (gc_id !== 0) {
                                for (let user_id in gc_assoc[gc_id.toString()].users) {
                                    if (gc_assoc[gc_id.toString()].users[user_id].ContactName.trim() === item.trim()) {
                                        reviewer = gc_assoc[gc_id.toString()].users[user_id];
                                        if (gc_assoc[gc_id.toString()].users[user_id].role === "team" || gc_assoc[gc_id.toString()].users[user_id].role === "no_access") {
                                            reviewer = undefined;
                                            reason = "User does not have necessary permission to be a reviewer"
                                        }
                                    }
                                }
                                let rev_value = data[i][actual_headers.reviewer.index];
                                let ree_value = data[i][actual_headers.reviewee.index];
                                if (rev_value === ree_value) {
                                    reviewer = undefined;
                                    reason = "Reviewer and Reviewee cannot be same";
                                }
                            }
                            row[key] = {value: item.trim(), content_id: reviewer === undefined ? 0 : parseInt(reviewer.ID), valid: reviewer === undefined ? false : true, reason: reason};
                        }
                    }
                    if (key === "reviewee") {
                        if (item === "" || item === null || item === undefined) {
                            row[key] = {value: "", content_id: 0, valid: false, reason: "Reviewee cannot be empty"};
                        } else {
                            let reviewee = undefined;
                            let reason = reviewee === undefined ? "Reviewee not found or Reviewer is not associated with the company" : "";
                            
                            if (gc_id !== 0) {
                                for (let user_id in gc_assoc[gc_id.toString()].users) {
                                    if (gc_assoc[gc_id.toString()].users[user_id].ContactName.trim() === item.trim()) {
                                        reviewee = gc_assoc[gc_id.toString()].users[user_id];
                                        reason = "";
                                        if (gc_assoc[gc_id.toString()].users[user_id].role === "no_access") {
                                            reviewee = undefined;
                                            reason = "User does not have necessary permission to be a reviewee"
                                        }
                                    }
                                }
                                let rev_value = data[i][actual_headers.reviewer.index];
                                let ree_value = data[i][actual_headers.reviewee.index];
                                if (rev_value === ree_value) {
                                    reviewee = undefined;
                                    reason = "Reviewer and Reviewee cannot be same";
                                }
                            }
                            row[key] = {value: item, content_id: reviewee === undefined ? 0 : parseInt(reviewee.ID), valid: reviewee === undefined ? false : true, reason: reason};
                        }
                    }
                    if (key === "related_individual") {
                        let related_individual = undefined;
                        if (module_config.general.related_individual === false) {
                            row[key] = {value: "", content_id: 0, valid: true, reason: ""};
                        } else {
                            if (item === "" || item === null || item === undefined) {
                                row[key] = {value: "", content_id: 0, valid: true, reason: ""};
                            } else {
                                let reason = "";
                                
                                
                                reason = related_individual === undefined ? "Related Individual not found or Related Individual is not associated with the company" : "";
                            
                                if (gc_id !== 0) {
                                    for (let user_id in gc_assoc[gc_id.toString()].users) {
                                        if (gc_assoc[gc_id.toString()].users[user_id].ContactName.trim() === item.trim()) {
                                            related_individual = gc_assoc[gc_id.toString()].users[user_id];
                                            reason = "";
                                            if (gc_assoc[gc_id.toString()].users[user_id].role === "no_access") {
                                                related_individual = undefined;
                                                reason = "User does not have necessary permission to be a Related Individual"
                                            }
                                        }
                                    }
                                    let rev_value = data[i][actual_headers.reviewer.index];
                                    let ree_value = data[i][actual_headers.reviewee.index];
                                    let rei_value = data[i][actual_headers.related_individual.index];
                                    if (rei_value === rev_value || rei_value === ree_value) {
                                        related_individual = undefined;
                                        reason = "Related Individual cannot be same as Reviewer or Reviewee";
                                    }
                                }
                                row[key] = {value: item, content_id: related_individual === undefined ? 0 : parseInt(related_individual.ID), valid: related_individual === undefined ? false : true, reason: reason};
                            }
                        }
                    }
                    if (key === "assignment_comments") {
                        row[key] = {value: item === undefined || item === null || item === "" ? "" : item.trim(), content_id: 0, valid: true};
                    }
                    if (key === "due_date") {
                        if (item === "" || item === null || item === undefined) {
                            row[key] = {value: "", content_id: 0, valid: false, reason: "Scheduled date cannot be empty"};
                        } else {
                            const due_date_obj = moment.unix((item - 25569) * 86400);
                            const reason = due_date_obj._isValid === true ? "" : "Date is not in correct format. Please use DD/MM/YYYY format";
                            const due_valid = due_date_obj._isValid === true ? true : false;
                            row[key] = {value: due_date_obj.format('DD/MM/YYYY'), content_id: due_date_obj.unix(), valid: due_valid, reason: reason};
                        }
                    }
                    if (actual_headers[key].ctf === true) {
                        let ctf_field = module_config.custom_fields.custom_terminology_fields.find(c => c.name === key);
                        let ctf_valid = false;
                        let other = false;
                        ctf_valid = ctf_field.required === true && (item === "" || item === undefined || item === null) ? false : true;
                        if ("type" in ctf_field) {
                            if (ctf_field.type === "Dropdown") {
                                if (ctf_field.other === false && ctf_field.dropdown_options.includes(item) === false) {
                                    ctf_valid = false;
                                }
                                if (ctf_field.other === true && ctf_field.dropdown_options.includes(item) === false) other = true;
                            }
                            row[key] = {value: item === undefined || item === null ? "" : item, content_id: 0, valid: ctf_valid, ctf: true, reason: "", other: other};
                        } else {
                            const sale_date_obj = moment.unix((item - 25569) * 86400);
                            row[key] = {value: sale_date_obj.format('DD/MM/YYYY'), content_id: 0, valid: ctf_valid, ctf: true, reason: "", other: other};
                        }
                    }
                    if (actual_headers[key].addl === true) {
                        console.log("addl:", item);
                        // console.log("is_number:", isNaN(parseInt(item)) === true ? false : true);
                        let addl_field = module_config.custom_fields.additional_fields.find(c => c.name === key);
                        // console.log("type:", addl_field.type);
                        let addl_valid = false;
                        let reason = "";
                        let other = false;
                        addl_valid = addl_field.required === true && (item === "" || item === undefined || item === null) ? false : true;
                        reason = addl_field.required === true && (item === "" || item === undefined || item === null) ? "This field is mandatory" : reason;
                        if ("type" in addl_field) {
                            if (addl_field.type === "Dropdown") {
                                if (addl_field.other === false && addl_field.dropdown_options.includes(item) === false) {
                                    addl_valid = false;
                                    reason = "This field is a dropdown. The value given does not matches the list";
                                }
                                if (addl_field.other === true && addl_field.dropdown_options.includes(item) === false) other = true;
                            }
                            if (addl_field.type === "Numeric") {
                                addl_valid = isNaN(parseInt(item)) === true ? false : true;
                                reason = isNaN(parseInt(item)) === true ? "This field must be a number" : "";
                                // console.log(addl_valid, reason);
                            }
                        }
                        row[key] = {value: item === undefined || item === null ? "" : item, content_id: 0, valid: addl_valid, addl: true, reason: reason, other: other};
                        // console.log(row[key]);
                    }
                }
                rows.push(row);
            }
        }
        console.log("rows:", rows);
        // for (let item of this.state.data[0]) {
        //     console.log("item:", item);
        // }
        this.props.goNext({headers: actual_headers, data: rows, file_name: this.file.name, readonly: false, related_individual_text});
    }

	render () {
	   // console.log('this.file', this.file)
		return (
			<BulkContainer>
			    {this.state.alertParam !== null && <AlertBox alertParam={this.state.alertParam} />}
                <BulkHeader>
                    <div style={{float: "left", width: "95%", boxSizing: "border-box"}}>
                        <div style={{fontWeight: "600", paddingBottom: "10px"}}>Bulk Assign</div>
                        <div>Assign File Checks in Bulk</div>
                    </div>
                    <div style={{float: "left", width: "5%", boxSizing: "border-box", fontSize: "19px", color: "#8f8f8f"}}>
                        <FaTimes style={{cursor: "pointer"}} onClick={this.props.closeModal} />
                    </div>
                    <div style={{clear: "both"}}></div>
                </BulkHeader>
                <BulkBody>
                    <UploadBox 
                        onDrop={this.onFileDrop}
                        onDragEnter={this.onDragEnter}
                        onDragOver={this.onDragOver}
                    >
                        <UploadInnerBox>
                            <div style={{fontSize: "50px", color: "#a5a5a5", boxSizing: "border-box"}}><AiOutlineCloudUpload /></div>
                            <div style={{fontWeight: "600", paddingBottom: "20px", boxSizing: "border-box"}}>Drag & Drop files here</div>
                            <div><UploadButton onClick={this.openFileDialog}>Or browse...</UploadButton></div>
                            <input
                                ref={this.fileInputRef}
                                style={{display: "none"}}
                                type="file"
                                accept=".xls,.xlsx"
                                onChange={this.onFilesAdded}
                            />
                        </UploadInnerBox>
                    </UploadBox>
                    {
                        
                        this.state.data !== null &&
                        <div style={{padding: "25px 0px 25px 0px"}}>
                            <div style={{fontWeight: "600", boxSizing: "border-box", paddingBottom: "15px"}}>Uploaded</div>
                            <div style={{float: "left"}}>
                                {
                                    (() => {
                                        const splited_file_name = this.file.name.split(".");
                                        const extension = splited_file_name[splited_file_name.length - 1];
                                        if (extension === "xls") {
                                            return (
                                                <ExtOuter>
                                                    <ExtInner>XLS</ExtInner>
                                                </ExtOuter>
                                            );
                                        } else {
                                            return (
                                                <ExtOuter>
                                                    <ExtInner>XLSX</ExtInner>
                                                </ExtOuter>
                                            );
                                        }
                                    })()
                                }
                            </div>
                            <div style={{float: "left", boxSizing: "border-box", paddingLeft: "25px"}}>
                                <div style={{fontWeight: "600"}}>{this.file.name}</div>
                                {
                                    (() => {
                                        if (this.file.size < 1000) {
                                            return <div style={{color: "#8b8989"}}>{this.file.size}bytes</div>
                                        } else if (this.file.size >= 1000 && this.file.size < 1000000) {
                                            return <div style={{color: "#8b8989"}}>{this.file.size / 1000}kb</div>
                                        } else if (this.file.size >= 1000000 && this.file.size < 1000000000) {
                                            return <div style={{color: "#8b8989"}}>{this.file.size / 1000000}mb</div>
                                        }
                                    })()
                                }
                            </div>
                            <div style={{clear: "both"}}></div>
                            <div style={{boxSizing: "border-box", paddingTop: "25px"}}>
                                <div style={{paddingBottom: "15px", fontWeight: "600"}}>Number of header rows to ignore</div>
                                <SelNum type="number" value={this.state.num} onChange={this.changeNum} />
                            </div>
                        </div>
                    }
                    <BulkHistory goNext={this.props.goNext} />
                </BulkBody>
                <BulkFooter>
                    <div style={{float: "right"}}>
                        <CancelBtn onClick={this.props.closeModal}>CLOSE</CancelBtn>
                        <SaveBtn
                            disabled={this.state.data === null ? true : false}
                            onClick={this.goNext}
                            style={{
                                marginLeft: "10px",
                                filter: this.state.data === null ? "grayscale(1)" : "none",
                                cursor: this.state.data === null ? "not-allowed" : "pointer"
                            }}
                        >NEXT</SaveBtn>
                    </div>
                    <div style={{clear: "both"}}></div>
                </BulkFooter>
            </BulkContainer>
		);
	}
}

export default BulkModal;