import React from 'react';
import styled from 'styled-components';
import ReviewsTobeCompleted from './ReviewsTobeCompleted'
import AuditorSummary from './AuditorSummary'
import RevieweeAssignment from './RevieweeAssignment'


class ReportRightPane extends React.Component {

	render() {
		switch(this.props.reportType) {
			case 'reviews_tobe_completed': return <ReviewsTobeCompleted />; break;
			case 'auditor_summary': return <AuditorSummary />; break;
			case 'reviewee_assignment': return <RevieweeAssignment />; break;
			default: return <div></div>
		}
	}
}

export default ReportRightPane;