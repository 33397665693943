import React from 'react';
import CSLTable from './CSLTable';

class RegisterHoc extends React.Component {

	render () {
		// if(this.state.filters === null) return <div></div>
		return (
			<CSLTable export={true}  goToReport={this.goToReport} export_file_name={this.props.export_file_name}  
				add={false}  
				processData={this.props.processTableData} 
				prefered_columns = {this.props.preferedColumns} 
				export_columns = {this.props.preferedColumns} 
				headerText={this.props.headerText} 
				tableRows={10}
				// qcbutton={this.props.qcbutton} 
				// toggleAssignQcModal={this.props.toggleAssignQcModal} 
				// qa_enabled={this.props.quality_assurance_enabled} 
				buttonSet={this.props.buttonSet}
				filters={this.props.filters}
				applyFilter={this.props.applyFilter}
				clearAdvancedFilter={this.props.clearPendingFilter}
				pill_filters={this.props.pillFilters}
				pillClickHandler={this.props.pillClickHandler}
				toggle_text={this.props.toggle_text}
				toggle_state={this.props.toggle_state}
				toggleCallback={"toggleCallback" in this.props === true ? this.props.toggleCallback : null}
			/>
		);
	}

}

export default RegisterHoc;