import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import Utils from '../../Common/Utils';
import APICall from '../../Common/APICall';
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import DatePicker from 'react-date-picker';
import {FaPlusCircle, FaTimes} from 'react-icons/fa';
import Dropzone from '../Common/Dropzone';
import {FiDownload} from 'react-icons/fi';
import { saveAs } from 'file-saver';
// import {IoIosArrowUp} from 'react-icons/io';
import FCKPrintviewFollowp from '../FCKPrintPreviewFollouwp';
import CSLLoader from "../Common/CSLLoader";

const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-right: 5px;
    `;
const OverviewInfoHeader = styled.div`
	padding: 15px;
	background-color: #04ADA8;
	border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;
const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ecf1f2;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

const ReviewBodyContainer = styled.div`
    background-color: #ecf0f1;
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
    padding: 15px;
    position: absolute;
    z-index: 1001;
    width: 80%;
    `;
const HrLine = styled.div`
    height: 2px;
    background-color: #d9d9d9;
    margin-top: 15px;
`;
const AnswerTextArea = styled.textarea`
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 99%;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-family: 'Montserrat', sans-serif;
`;

const ModalNameLabelDiv = styled.div`
    height: auto;
    width: 30%;
    padding-top: 7px;
    font-weight: 600;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    margin-bottom: 10px;
`;

const ReviewFooter = styled.div`
    text-align: right;
    padding-top: 30px;
`;
const CancelBtn = styled.button`
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    color: #666666;
    cursor: pointer;
`;
const CompleteBtn = styled.button`
    background-color: #37ada7;
    padding: 10px 35px;
    border: 1px solid #37ada7;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:30px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ExpoxtPdf = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ADA7;
    color: #FFFEEE;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
    width: 300px;
`;

class FollowupModal extends React.Component
{

    state = {
        task: null,
        payload: {},
        task_id: null,
        comments: "",
        task_title:"",
        task_note: "",
        ready: false,
        send_mail_config: false,
        binFiles: [],
        curBinFiles: [],
        show_attachments: true,
        cur_files_json: null,
        Is_task_complete: 0,
        Is_ready_state:false,
        can_perform_action:true
    };

    processDate = (date) => {
        let date_str = date.split('T')[0]
        let date_parts = date_str.split('-')
        return `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`
    }

    GetPayloadData = (parent_task) => {
        console.log("parent_task:", parent_task);
        let payload = {};
        let parent_task_json = JSON.parse(parent_task.task_json);
        // console.log("parent_task_json:", parent_task_json);
        // const contacts = Store.getStoreData("reviewee_list");
        const gc_assoc = Store.getStoreData("gc_assoc");
        const all_users = Store.getStoreData("users");
        payload.parent_task_cur_lane = parent_task.cur_lane;
        payload.customer_name = parent_task_json.object_data.headerinfo.customer_name;
        payload.file_reference = parent_task_json.object_data.headerinfo.file_reference;
        payload.reviewer = all_users[(parent_task_json.object_data.headerinfo.reviewer_id).toString()];
        payload.reviewee = all_users[(parent_task_json.object_data.headerinfo.reviewee_id).toString()];

        payload.status = parent_task_json.task_data.cur_lane === "COMP_FCK_COMPLETE" || parent_task_json.task_data.cur_lane === "COMP_FCK_QC_COMPLETE" ? "Completed" : "In Progress";
        payload.company_name = parseInt(parent_task.company_id) < 100000 ? Store.getStoreData('parent_company').company_name : Store.getStoreData("gc_assoc")[(parseInt(parent_task.company_id)%100000).toString()].company_name;
        payload.assigned_date = this.processDate(parent_task.created_at);

        let is_sale_date_active = true;
        if ("custom_terminology_fields" in parent_task_json.object_data.headerinfo && parent_task_json.object_data.headerinfo.custom_terminology_fields.length !== 0) {
            const sale_obj = parent_task_json.object_data.headerinfo.custom_terminology_fields.find((item) => item.name === "sale_date");
            is_sale_date_active = "is_active" in sale_obj ? sale_obj.is_active : true;
        }
        const saledatearr = parent_task_json.task_data.sale_date.split("/");
        payload.sale_date = !is_sale_date_active ? "N/A" : saledatearr[2]+"/"+saledatearr[1]+"/"+saledatearr[0];
        const duedatearr = parent_task_json.task_data.due_date.split("/");
        payload.due_date = duedatearr[2]+"/"+duedatearr[1]+"/"+duedatearr[0];
        payload.completed_date = parent_task.completed_date !== null ? this.processDate(parent_task.completed_date) : '-';
        payload.ragratingcolor = (parent_task.cur_lane === "COMP_FCK_QC_COMPLETE" || parent_task.cur_lane === "COMP_FCK_COMPLETE") ? parent_task_json.object_data.result.ragratingcolor : "#04ADA8";
        payload.ragratingname = parent_task_json.object_data.result.ragratingname;
        payload.outcome = parent_task_json.object_data.result.outcome;
        payload.scoresobtained = parent_task_json.object_data.result.scoresobtained;
        if (!("summary" in parent_task_json.object_data.headerinfo.review_json)) {
            payload.summary = "";
        }else{
            payload.summary = parent_task_json.object_data.headerinfo.review_json.summary;
        }
        // console.log("payload ====>", payload);
        payload.customer_name = parent_task_json.object_data.headerinfo.customer_name;
        payload.file_reference = parent_task_json.object_data.headerinfo.file_reference;
        payload.reviewee_designation = parent_task_json.object_data.headerinfo.reviewee_designation;
        payload.review_name = parent_task_json.object_data.headerinfo.review_json.general.name;
        payload.review_description = parent_task_json.object_data.headerinfo.review_json.general.description;
        return payload;

    }

	componentDidMount()
	{
        let {task, payload, task_id, comments, task_title, task_note, ready, cur_files_json, curBinFiles} = this.state;
        let task_data = Store.getStoreData('followup_task');
        console.log("sale_date_label task_data:", task_data);
        let followup_task = task_data.subtask;
        // console.log("followup_task:", followup_task);
        task_id = followup_task.id;
        Store.updateStore("fc_index", task_id);
        let task_json = JSON.parse(followup_task.task_json);
        task_title = task_json.object_data.subject;
        task_note = task_json.object_data.note;
        comments = followup_task.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? task_json.object_data.commands : comments;
        let parent_task = task_data.parenttask;
        payload = this.GetPayloadData(parent_task);
        // console.log("payload", payload);
        if('bin_files' in task_json.object_data) {
            curBinFiles = task_json.object_data.bin_files;
            cur_files_json = curBinFiles.length === 0 ? null : true;
        }
        let istask_comp = this.state.Is_task_complete;
        console.log("cur_lane:",followup_task.cur_lane)

        let followup_task_parent = task_data.parenttask;
        if(followup_task_parent.cur_lane.includes("COMPLETE")){
             istask_comp = 1;
            // const api = new APICall();
            // const postData = { command: "get_task", task_id: followup_task_parent.id };
            // console.log("postData:", postData);
            // this.setState({Is_task_complete:istask_comp})
            // api.command(postData, this.singleTask);
            let this_task = followup_task_parent;
            let task_json = JSON.parse(this_task.task_json);
            console.log("cur_lane::singleTask::result:1:",task_data);
            console.log("cur_lane::singleTask::this_task:1:",this_task);
            console.log("cur_lane::singleTask::task_json:1:",task_json);
            Store.updateStore("report_task_json", task_json);
            Store.updateStore("report_task", task_data);
            this.setState({Is_ready_state:true,Is_task_complete:istask_comp})
        }else{
            this.setState({Is_ready_state:true})
        }

        // console.log("cur_files_json",cur_files_json)

    	let loggedin_user_info = Store.getStoreData('loggedin_user_info')
    	let role = loggedin_user_info.role;
    	let user_id = loggedin_user_info.ID
    	let cur_assigned_to = followup_task.cur_assigned_to
    	let can_perform_action = true;

    	if(role === 'file_checker' || role === 'team' || role === 'view_only_manager'){
    		can_perform_action = parseInt(cur_assigned_to) === parseInt(user_id) ? true : false;
    	}

        this.setState({task: task_json, can_perform_action, followup_task, comments, payload, task_id, task_title, task_note, cur_files_json, curBinFiles, ready:true});


	}
    singleTask = (result) => {
            let this_task = result.result.task.parenttask;
            let task_json = JSON.parse(this_task.task_json);
            console.log("cur_lane::singleTask::result:2:",result.result.task);
            console.log("cur_lane::singleTask::this_task:2:",this_task);
            console.log("cur_lane::singleTask::task_json:2:",task_json);
            Store.updateStore("report_task_json", task_json);
            Store.updateStore("report_task", result.result.task);
            this.setState({Is_ready_state:true})
    };
	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
            let {task, payload, task_id, comments, task_title, task_note, ready} = this.state;
            let task_data = Store.getStoreData('followup_task');
            let followup_task = task_data.subtask;
            task_id = followup_task.id;
            let task_json = JSON.parse(followup_task.task_json);
            task_title = task_json.object_data.subject;
            task_note = task_json.object_data.note;
            let parent_task = task_data.parenttask;
            payload = this.GetPayloadData(parent_task);
            this.setState({task: task_json,payload,task_id,task_title,task_note,ready:true});
		}
	}

    onDateChangeDueBy = (date) => {
        this.setState({due_by: date});
    }

    handleChange = (evt) => {
        evt.preventDefault();
        // console.log(evt.target.value);
        this.setState({comments: evt.target.value});
    }
    changeFollowupNote = (event) => {
        event.preventDefault();
        this.setState({followup_note: event.target.value});
    }

    getMailStrucData = (data) => {
        let task_json = this.state.task_json;
        let com_roles = Store.getStoreData('com_roles');
        let loggedin_user_info = Store.getStoreData('loggedin_user_info');
        let payload = this.state.payload;
        let date = new Date();

        // console.log("payload", JSON.stringify(payload));

        let mail_body = data.body;
        mail_body = mail_body.replace("{{reviewer}}", payload.reviewer.ContactName);
        mail_body = mail_body.replace("{{reviewee}}", payload.reviewee.ContactName);
        mail_body = mail_body.replace("{{scheduled_date}}", payload.due_date);
        mail_body = mail_body.replace("{{task_name}}", payload.review_name);
        mail_body = mail_body.replace("{{task_description}}", payload.review_description);
        mail_body = mail_body.replace("{{status}}", "Followup Completed");
        mail_body = mail_body.replace("{{review_type}}", com_roles[payload.reviewee.ID.toString()]);
        mail_body = mail_body.replace("{policy_start_date}}", payload.sale_date);
        mail_body = mail_body.replace("{{created_date}}", date);
        mail_body = mail_body.replace("{{company}}", payload.company_name);
        mail_body = mail_body.replace("{{assigner}}", loggedin_user_info.ContactName);
        mail_body = mail_body.replace("{{file_reference}}", payload.file_reference);
        mail_body = mail_body.replace("{{reviewee_designation}}", payload.reviewee_designation);
        mail_body = mail_body.replace("{{customer_name}}", payload.customer_name);
        mail_body = mail_body.replace("{{source_module}} ", "");
        mail_body = mail_body.replace("{{assigned_to}}", "");
        data.body = mail_body;

        let mail_subject = data.subject;
        mail_subject = mail_subject.replace("{{reviewer}}", payload.reviewer.ContactName);
        mail_subject = mail_subject.replace("{{reviewee}}", payload.reviewee.ContactName);
        mail_subject = mail_subject.replace("{{scheduled_date}}", payload.due_date);
        mail_subject = mail_subject.replace("{{task_name}}", payload.review_name);
        mail_subject = mail_subject.replace("{{task_description}}", payload.review_description);
        mail_subject = mail_subject.replace("{{status}}", "Followup Completed");
        mail_subject = mail_subject.replace("{{review_type}}", com_roles[payload.reviewee.ID.toString()]);
        mail_subject = mail_subject.replace("{policy_start_date}}", payload.sale_date);
        mail_subject = mail_subject.replace("{{created_date}}", date);
        mail_subject = mail_subject.replace("{{company}}", payload.company_name);
        mail_subject = mail_subject.replace("{{assigner}}", loggedin_user_info.ContactName);
        mail_subject = mail_subject.replace("{{file_reference}}", payload.file_reference);
        mail_subject = mail_subject.replace("{{reviewee_designation}}", payload.reviewee_designation);
        mail_subject = mail_subject.replace("{{customer_name}}", payload.customer_name);
        mail_subject = mail_subject.replace("{{source_module}} ", "");
        mail_subject = mail_subject.replace("{{assigned_to}}", "");
        data.subject = mail_subject;
        return data;
    }

    UpdateSubTask = (event) => {
        event.preventDefault();
        let task_json = this.state.task;
        console.log(task_json);
        task_json['task_data'].last_action = 'COMP_FCK_FOLLOWUP_COMPLETE';
        task_json['task_data'].cur_lane = 'COMP_FCK_FOLLOWUP_COMPLETE';
        task_json['task_data'].actiondate = Date.now();
        task_json['object_data'].commands = this.state.comments;

        // let module_config = Store.getStoreData('module_config');
        // let mail_config = {};
        // if(this.state.send_mail_config) {
        //     if(Object.keys(module_config).length > 0) {
        //         mail_config = module_config.email.find((obj) => {
        //             return obj.id === "LStk"
        //         });
        //     }
        //     mail_config = this.getMailStrucData(mail_config);
        //     console.log(mail_config);
        // }

        task_json['action_data'] = {action: "COMP_FCK_FOLLOWUP_COMPLETE", actiondate: Date.now(), performed_by: Store.getStoreData('loggedin_user_info').ID, cur_lane: "COMP_FCK_FOLLOWUP_COMPLETE", comments: this.state.comments, assigned_to: 0 };
        let postData = {command: "update_filecheck_task", task_json: task_json, task_id: this.state.task_id };
        console.log(postData);
        const api = new APICall();
        api.command(postData, this.afterCompleted);
    }

    afterCompleted = (result) => {
        // console.log("Completed:", result);
        alert('Follow-up task completed.');
        if('from_parent' in this.props && this.props.from_parent){
            // alert('going tp closeModalSave')
            this.props.closeModalSave();
        }else{
            window.location.reload(true);
        }

    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    togglePanel = (panel) => (event) => {
        event.preventDefault();
        let flag = this.state[panel];
        flag = flag ? false : true;
        this.setState({[panel]: flag});
    }

    filesLoaded = (files) => {
        let contact_id = Store.getStoreData('contact_id');
        for(let i=0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            //console.log('File_name', files[i].name);
            if(!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }

        }
        //console.log('files in Modal', files);
        this.setState({binFiles: files});
    }
    handleDownload = (index) => {
        let cur_file = this.state.curBinFiles[index];
        let dnld = cur_file.bin_file.replace(/ /g, '+');
        // console.log("binary file before download:", dnld);
        var binaryString = window.atob(dnld);
        // console.log('binaryString', binaryString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, cur_file.name);
    }
    // exportToPdf = (event) =>{
    //     event.preventDefault();
    //     Store.updateStore('is_register', true);
    //     this.props.openPrintView();
    // }
    render()
    {
    	console.log("FOLLOWUPSTATE::",this.state)

        if(!this.state.Is_ready_state){
            return (
              <div>
                <CSLLoader />
              </div>
            );
        }
        const readonly = this.state.followup_task.cur_lane === "COMP_FCK_FOLLOWUP_COMPLETE" ? true : false;
        return(
            <ReviewBodyContainer>
                <span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.task_title} </span>
                <CloseBtn onClick={this.closeModal}><FaTimes /></CloseBtn>

                <div style={{clear:"both"}}></div>
                <HrLine style={{width: "100%"}}/>
                    <div style={{paddingTop: "40px"}}>
						<OverviewInfoHeader style={{backgroundColor: this.state.payload.ragratingcolor, borderColor: this.state.payload.ragratingcolor}}>
							{
                                (() => {
                                    if (this.state.payload.parent_task_cur_lane === "COMP_FCK_COMPLETE" || this.state.payload.parent_task_cur_lane === "COMP_FCK_QC_COMPLETE") {
                                        return (
                                            <div>
                                                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>
                                                    {this.state.payload.ragratingname}&nbsp;({this.state.payload.outcome})
                                                </div>
                                                <div style={{float: "right"}}>
                                                    <div style={{float: "left", color: "#ffffff", fontSize: "15px"}}>
                                                        Score&nbsp;&nbsp;{Math.round(this.state.payload.scoresobtained)}%
                                                    </div>
                                                    {/* <div style={{float: "left", color: "#ffffff", fontSize: "15px", marginLeft: "25px"}}><IoIosArrowUp /></div> */}
                                                </div>
                                                <div style={{clear: "both"}}></div>
                                            </div>
                                        );
                                    }
                                })()
                            }
						</OverviewInfoHeader>
						<OverviewInfoBody>
							<div>
								<div style={{display: "inline-block"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Reviewer</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.reviewer.ContactName}</span></div>
								<div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Reviewee</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.reviewee.ContactName}</span></div>
								<div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Status</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.status}</span></div>
								<div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Company</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.company_name}</span></div>
							</div>
                            {
                              (() => {
                                console.log("FWpayload",this.state.payload)
                              })()
                            }
							<div style={{marginTop: "35px"}}>
								<div style={{display: "inline-block"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Sales Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.sale_date}</span></div>
								<div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Assigned Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.assigned_date}</span></div>
								<div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Due Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.due_date}</span></div>
								<div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Review Date</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.completed_date}</span></div>
							</div>
                            <div style={{marginTop: "35px"}}>
                                <div style={{display: "inline-block"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Customer Name</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.customer_name}</span></div>
                                <div style={{display: "inline-block", paddingLeft: "8%"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>File Reference</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.file_reference}</span></div>
                            </div>
                            <div style={{marginTop: "35px"}}>
                                <div style={{display: "inline-block"}}><span style={{fontWeight: "600", color: `${process.env.DEFAULT_TEXT_COLOR}`}}>Comments11</span>&nbsp;&nbsp;&nbsp;<span style={{color: `${process.env.DEFAULT_TEXT_COLOR}`}}>{this.state.payload.summary}</span></div>
                            </div>
                                {
                                    (() => {
                                        if (this.state.Is_task_complete === 1 && Store.getStoreData('report_task_json') !== null && Store.getStoreData('report_task') !== null) {
                                            return (
                                                <div style={{marginTop: "35px"}}>
                                                    <ExpoxtPdf><FCKPrintviewFollowp/></ExpoxtPdf>
                                                </div>
                                                )
                                        }
                                    })()
                                }


						</OverviewInfoBody>
					</div>
                    <HrLine/>
                    <div style={{paddingTop: "20px"}}>
                        <ModalNameLabelDiv>Task Notes</ModalNameLabelDiv>
                        <div>{this.state.task_note}</div>
                    </div>
                    <div style={{paddingTop: "20px"}}>
                        <OverviewInfoHeader>
                            <div style={{float: "left", color: "#ffffff", fontWeight: "600"}}>Attachment</div>
                            <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.togglePanel("show_attachments")}>
                                {
                                    (() => {
                                        if (this.state.show_attachments) {
                                            return <IoIosArrowUp />
                                        } else {
                                            return <IoIosArrowDown />
                                        }
                                    })()
                                }
                            </div>

                            <div style={{clear: "both"}}></div>
                        </OverviewInfoHeader>
                        {
                            (() => {
                                if (this.state.show_attachments) {
                                    return (
                                        <OverviewInfoBody>
                                            <ModalNameLabelDiv>File Assignment - Attachment</ModalNameLabelDiv>
                                            <div style={{width: "100%", boxSizing: "border-box"}}>
                                    {
                                        (() => {
                                            if(this.state.cur_files_json !== null) {
                                                return(
                                                    <div>
                                                    {
                                                        this.state.curBinFiles.map((f, index) => {
                                                            console.log("f", f);
                                                            const file_parts = f.name.split(".");
                                                            let ext = "PDF";
                                                            switch(file_parts[file_parts.length - 1]) {
                                                              case 'jpeg':
                                                              case 'jpg' : ext = 'JPG';break;
                                                              case 'png' : ext = 'PNG'; break;
                                                              case 'docx' : ext = 'DOCX'; break;
                                                              case 'doc' : ext = 'DOC'; break;
                                                              case 'msg' : ext = 'MSG'; break;
                                                              case 'txt' : ext = 'TXT'; break;
                                                              case 'ppt' : ext = 'PPT'; break;
                                                              case 'pptx' : ext = 'PPTX'; break;
                                                              case 'xls' : ext = 'XLS'; break;
                                                              case 'xlsx' : ext = 'XLS'; break;
                                                              default: ext = 'PDF'; break;
                                                            }
                                                            console.log('ext', ext);
                                                            let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
                                                            file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
                                                            const file_icon = `/dropzoneImages/${ext}.png`;
                                                            return (
                                                              <div style={{width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index%2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px"}}>
                                                                <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
                                                                    <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                                                      <img src={file_icon} height="100px" width="auto" />
                                                                      {/*<Style.FileTypeContainer>{ext}</Style.FileTypeContainer>*/}
                                                                    </div>
                                                                    <div style={{float: "left", padding: "10px", boxSizing: "border-box"}}>
                                                                      <div><strong title={f.name} style={{color: '#000000'}}>{f.name.length > 22 ? f.name.substring(0, 22) : f.name}</strong><br /><br /></div>
                                                                      <div style={{cursor: "pointer"}} onClick={() => this.handleDownload(index)}><FiDownload /> Download</div>
                                                                    </div>
                                                                    <div style={{clear: "both"}}></div>
                                                                </div>
                                                              </div>
                                                            );

                                                        })
                                                    }
                                                    <div style={{clear: "both"}}></div>
                                                    </div>
                                                )
                                            } else {
                                                return <div>No existing attachments.</div>
                                            }
                                        })()

                                    }
                                    </div>
                                        </OverviewInfoBody>
                                    );
                                } else {
                                    return (<div></div>);
                                }
                            })()
                        }
                    </div>
                    {
                        (() => {
                        	if(this.state.can_perform_action){
                        		return(
				                    <div style={{paddingTop: "40px"}}>
				                        <ModalNameLabelDiv>Task Response</ModalNameLabelDiv>
				                        <AnswerTextArea disabled={readonly} value={this.state.comments} onChange={this.handleChange} style={{marginTop: "5px"}} rows="6"/>
				                    </div>
                        		);
                        	}
                        })()
                    }
                    <ReviewFooter>
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        {
	                        (() => {
	                        	if(this.state.can_perform_action && this.state.followup_task.cur_lane !== "COMP_FCK_FOLLOWUP_COMPLETE"){
	                        		return(<CompleteBtn onClick={this.UpdateSubTask} style={{marginLeft: "10px"}}>Complete</CompleteBtn>);
	                        	}
	                        })()
	                    }
                        {/*{this.state.followup_task.cur_lane !== "COMP_FCK_FOLLOWUP_COMPLETE" && <CompleteBtn onClick={this.UpdateSubTask} style={{marginLeft: "10px"}}>Complete</CompleteBtn>}*/}
                    </ReviewFooter>
            </ReviewBodyContainer>
        )
    }

}
export default FollowupModal;
