let filters = {
	reviewer: {name: 'reviewer', 'nickname': 'Reviewer', type: 'multi', options: {},selections: {}, size: 2},
	reviewee: {name: 'reviewee', 'nickname': 'Reviewee', type: 'multi', options: {},selections: {}, size: 2},
	status: {name: 'status', 'nickname': 'Status', type: 'multi', options: {},selections: {}, size: 2},
	review_type: {name: 'review_type', 'nickname': 'File Check Type', type: 'multi', options: {},selections: {}, size: 2},
	due_date: {name: 'due_date', 'nickname': 'Due Date', type: 'date_range', options: {},selections: {}, size: 2},
	assigned_date: {name: 'assigned_date', 'nickname': 'Assigned', type: 'date_range', options: {},selections: {}, size: 2},
	company_name: {name: 'company_name', 'nickname': 'Company', type: 'multi', options: {},selections: {}, size: 2},
	customer: {name: 'customer', 'nickname': 'Customer', type: 'multi', options: {},selections: {}, size: 2}
}

module.exports = filters